import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { SettingsListItem } from '../../../../../../components/settings-list-item'
import { Form } from '../../..'
import { DatePickerInput } from '../../../../../../inputs/date-picker-input'
import dayjs from 'dayjs'

export const LastDateField = () => {
  const { t } = useTranslation(['pos_profiles'])
  const { control, getValues } = useFormContext<Form>()

  const returnPolicy = getValues('return_policy')
  const { end_date: endDate, start_date: startDate } = returnPolicy || {}
  const hasAnyOtherDateField = Boolean(endDate || startDate)

  return (
    <SettingsListItem>
      <DatePickerInput
        name="return_policy.last_date"
        control={control}
        label={t('pos_profiles:return_policy_section.last_date')}
        helperText={t(
          'pos_profiles:return_policy_section.last_date_description',
        )}
        rules={{
          required: hasAnyOtherDateField
            ? t('pos_profiles:return_policy_section.date_all_required')
            : false,
        }}
        minDate={dayjs(endDate)}
        shouldValidate={false}
      />
    </SettingsListItem>
  )
}
