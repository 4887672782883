import { makeVar, useReactiveVar } from '@apollo/client'
import { Button, Grid, Tab, Tabs } from '@mui/material'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { Outlet, useLocation, useNavigate } from 'react-router-dom'
import { RootRoute } from '..'
import { FilterProvider } from '../../components/data-grid/context'
import { PageHeader } from '../../components/page-header'
import { useAbsolutePath } from '../../hooks/absolute-path'
import { useAuthorization } from '../../hooks/authorization'
import { usePageTitle } from '../../hooks/title'
import { useTracking } from '../../hooks/tracking'
import { ListFilterPanel } from './list-filter-panel'

export const reportListFilterPanelVar = makeVar({
  isOpen: false,
})

export const ReportJobsPage = () => {
  const { t } = useTranslation(['reports'])
  const navigate = useNavigate()
  const location = useLocation()
  const generatePath = useAbsolutePath()
  usePageTitle(t('reports:page_title'))

  const {
    modules: { reportsFullPermission },
  } = useAuthorization()

  const filterViewPanelState = useReactiveVar(reportListFilterPanelVar)
  const { trackButtonClick } = useTracking()

  const closeFilterPanel = () => {
    trackButtonClick({ name: 'reports-filter-panel-close' })
    reportListFilterPanelVar({ isOpen: false })
  }

  useEffect(() => {
    if (!location.pathname.includes(generatePath(RootRoute.ReportJobsAll))) {
      reportListFilterPanelVar({ isOpen: false })
    }
  }, [generatePath, location])

  const showManageTemplates =
    reportsFullPermission &&
    (location.pathname === generatePath(RootRoute.ReportsManageTemplates) ||
      location.pathname === generatePath(RootRoute.ReportJobs))

  return (
    <FilterProvider>
      <Grid container>
        <Grid size="grow">
          <PageHeader
            title={t('reports:page_title')}
            isFlexible
            rightColumn={
              <>
                {showManageTemplates && (
                  <Button
                    color="secondary"
                    onClick={() =>
                      void navigate(
                        generatePath(RootRoute.ReportsManageTemplates),
                      )
                    }
                  >
                    {t('reports:manage_templates.title')}
                  </Button>
                )}
                <Button
                  data-testid="create-report-job"
                  onClick={() =>
                    void navigate(generatePath(RootRoute.ReportTemplates))
                  }
                >
                  {t('shared:action.create')}
                </Button>
              </>
            }
          >
            <Tabs
              onChange={(_, route) => void navigate(route)}
              value={location.pathname}
              variant="standard"
              sx={{
                '.MuiButtonBase-root': {
                  borderColor: 'transparent',
                },
              }}
            >
              <Tab
                label={t('reports:overview')}
                value={generatePath(RootRoute.ReportJobs)}
              />
              <Tab
                label={t('reports:your_reports')}
                value={generatePath(RootRoute.ReportJobsYour)}
              />
              {reportsFullPermission && (
                <Tab
                  label={t('reports:all_reports')}
                  value={generatePath(RootRoute.ReportJobsAll)}
                />
              )}
            </Tabs>
          </PageHeader>
          <Outlet />
        </Grid>
        <Grid>
          <ListFilterPanel
            isOpen={filterViewPanelState.isOpen}
            onClose={closeFilterPanel}
          />
        </Grid>
      </Grid>
    </FilterProvider>
  )
}
