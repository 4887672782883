import { Button, Menu, MenuItem, Typography } from '@mui/material'
import { ChevronSmallDownIcon } from '@sitoo/mui-components'
import { MouseEventHandler, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useTracking } from '../../../../../hooks/tracking'
import { DEFAULT_PRICELIST_ID } from '../../../../../utils/constants'
import { DeletePrice } from './delete-price'
import { SetPrice } from './set-price'

type Props = {
  pricelistId: number
  productSiteId?: number
  refetch(): unknown
}

export const BulkActions = (props: Props) => {
  const { t } = useTranslation(['shared', 'prices'])
  const { trackButtonClickEvent } = useTracking()
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)
  const isOpen = !!anchorEl
  const handleClick: MouseEventHandler<HTMLButtonElement> = (event) => {
    setAnchorEl(isOpen ? null : event.currentTarget)
  }
  const closeMenu = () => {
    setAnchorEl(null)
  }

  const [showSetPrice, setShowSetPrice] = useState(false)
  const [showDeletePrice, setShowDeletePrice] = useState(false)

  return (
    <>
      <Button
        id="price-list-bulk-action-menu-button"
        aria-controls={isOpen ? 'price-list-bulk-action-menu' : undefined}
        data-testid="price-list-bulk-action-menu-button"
        aria-haspopup="true"
        aria-expanded={isOpen ? 'true' : undefined}
        onClick={handleClick}
        color="secondary"
        endIcon={<ChevronSmallDownIcon />}
        size="small"
      >
        {t('shared:label.actions')}
      </Button>
      <Menu
        id="price-list-bulk-action-menu"
        anchorEl={anchorEl}
        open={isOpen}
        onClose={closeMenu}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        slotProps={{
          list: {
            'aria-labelledby': 'price-list-bulk-action-menu-button',
          },
        }}
      >
        <MenuItem
          onClick={trackButtonClickEvent(
            { name: 'price-list-bulk-action-menu-set-price' },
            () => {
              setShowSetPrice(true)
              closeMenu()
            },
          )}
          data-testid="grid-menu-set-price"
        >
          <Typography variant="body02">{t('prices:set_price')}</Typography>
        </MenuItem>

        {props.pricelistId !== DEFAULT_PRICELIST_ID && (
          <MenuItem
            onClick={trackButtonClickEvent(
              { name: 'price-list-bulk-action-menu-delete-price' },
              () => {
                setShowDeletePrice(true)
                closeMenu()
              },
            )}
            data-testid="grid-menu-delete-price"
          >
            <Typography variant="body02">{t('prices:delete_price')}</Typography>
          </MenuItem>
        )}
      </Menu>

      <SetPrice
        isOpen={showSetPrice}
        refetch={props.refetch}
        pricelistId={props.pricelistId}
        productSiteId={props.productSiteId}
        onClose={() => {
          setShowSetPrice(false)
        }}
      />

      <DeletePrice
        isOpen={showDeletePrice}
        refetch={props.refetch}
        pricelistId={props.pricelistId}
        productSiteId={props.productSiteId}
        onClose={() => {
          setShowDeletePrice(false)
        }}
      />
    </>
  )
}
