import { Alert, Box, Button, Paper, Stack, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { RootRoute } from '..'
import { ReportJobsQuery, ReportResultsQuery } from '../../generated/graphql'
import { useAbsolutePath } from '../../hooks/absolute-path'
import { ArrayElement } from '../../utils/types'
import { FilterChips } from './filter-chips'

type Props = {
  reportJob?: ArrayElement<ReportJobsQuery['reportJobs']['items']>
  reportResults?: ReportResultsQuery['reportResults']
}

export const TopSection = ({ reportJob, reportResults }: Props) => {
  const { t } = useTranslation(['reports'])
  const navigate = useNavigate()
  const generatePath = useAbsolutePath()

  const filters = reportJob?.reportdefinition?.filters

  return (
    <Box component={Paper} elevation={0} sx={{ p: 2 }}>
      <Stack
        direction="row"
        columnGap={2}
        sx={{
          justifyContent: 'space-between',
          alignItems: 'center',
          mb: 0,
          pb: 0,
          columns: 'auto 1fr',
        }}
      >
        <Stack>
          {filters && (
            <>
              <Typography variant="body02">
                {t('reports:report_definition')}
              </Typography>
              <FilterChips filters={filters} />
            </>
          )}
        </Stack>

        <Button
          sx={{ minWidth: 'max-content' }}
          onClick={() => {
            void navigate({
              pathname: generatePath(RootRoute.ReportJobNew, {
                id: reportJob?.jobid,
              }),
            })
          }}
          type="button"
        >
          {t('reports:clone_report')}
        </Button>
      </Stack>
      {reportResults?.rowCountLimitReached && (
        <Alert variant="outlined" severity="error" sx={{ mt: 2 }}>
          {t('reports:row_limit_reached', {
            limit: reportResults.rowCount,
          })}
        </Alert>
      )}
    </Box>
  )
}
