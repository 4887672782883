import { Tooltip, Box } from '@mui/material'
import {
  GridEditInputCell,
  GridRenderCellParams,
  GridRenderEditCellParams,
  GridValidRowModel,
} from '@mui/x-data-grid-pro'
import { useTracking } from '../../../hooks/tracking'

export const StringRenderer = (
  params: GridRenderCellParams<GridValidRowModel, string | null | undefined>,
  showPlaceholder = true,
) => {
  const hasValue =
    params.formattedValue !== null && params.formattedValue !== undefined

  const shouldShowPlaceholder = showPlaceholder && !hasValue

  return (
    <div
      className="MuiDataGrid-cellContent"
      data-testid={`string-field-${params.id}-${params.field}`}
    >
      {shouldShowPlaceholder && (
        <Box fontStyle="italic" color="gray60">
          {params.colDef.headerName}
        </Box>
      )}

      {params.formattedValue}
    </div>
  )
}

export const StringEditRenderer = (
  props: GridRenderEditCellParams & { trackingPrefix: string },
) => {
  const {
    trackingPrefix,
    error,
    selectTextOnFocus = true,
    ...otherProps
  } = props

  const { trackInputFocus, trackInputBlur } = useTracking()

  return (
    <Box sx={{ width: '100%' }}>
      <Box sx={{ '.MuiInputBase-input': (theme) => theme.typography.body01 }}>
        <GridEditInputCell
          {...otherProps}
          error={!!error}
          hasFocus={true}
          onFocus={(event) => {
            if (selectTextOnFocus) {
              event.currentTarget.select()
            }
            trackInputFocus({ name: `${trackingPrefix}-${props.field}` })
          }}
          onBlur={trackInputBlur({ name: `${trackingPrefix}-${props.field}` })}
          data-testid={`string-edit-field-${props.id}-${props.field}`}
        />
      </Box>

      <Box sx={{ width: '100%' }}>
        <Tooltip open={!!error} title={error || ''}>
          <div />
        </Tooltip>
      </Box>
    </Box>
  )
}
