import { RefObject, useContext, useMemo } from 'react'
import { FilterContext } from '../../../components/data-grid/context'
import { TextFilter } from '../../../components/data-grid/filters/text-filter'
import { FranchiseSiteFilter } from '../../../filters/franchise-site-filter'
import { ManufacturerIdFilter } from '../../../filters/manufacturer-id-filter'
import { CategoryIdFilter } from '../../../filters/product-category-id-filter'
import { ProductGroupIdFilter } from '../../../filters/product-group-id-filter'
import {
  NotCountedWithValue,
  WarehouseItemStateFilter,
} from '../../../filters/warehouse-item-state-filter'
import { WarehouseProductStateFilter } from '../../../filters/warehouse-product-state-filter'
import {
  GetConfigVarsDocument,
  GetInventoryBatchesSort,
  InventoryItemBatchState,
  InventoryProductBatchesQueryVariables,
} from '../../../generated/graphql'
import { useQuery } from '@apollo/client'
import { GridApiPro } from '@mui/x-data-grid-pro'
import { SortItem } from '../../../components/data-grid/filters/sort'

type Filter = TextFilter &
  CategoryIdFilter &
  ProductGroupIdFilter &
  ManufacturerIdFilter &
  FranchiseSiteFilter &
  WarehouseProductStateFilter &
  WarehouseItemStateFilter

const PAGE_SIZE = 100

interface Props {
  apiRef?: RefObject<GridApiPro> | null
  warehouseId: number
  warehouseBatchId: number
  sortItems: SortItem<GetInventoryBatchesSort>[]
}

export const useBatchItemsVariables = (props: Props) => {
  const { warehouseId, warehouseBatchId, sortItems, apiRef } = props
  const { filter } = useContext<FilterContext<Filter>>(FilterContext)

  const { data: configVarsData } = useQuery(GetConfigVarsDocument)

  const shouldShowCustomFields =
    configVarsData?.configVars?.stocktakingShowCustom ?? false

  const sortModel = apiRef?.current.state?.sorting.sortModel

  const queryVariables = useMemo(() => {
    const config = {
      pagination: {
        start: 0,
        page: 0,
        pageSize: PAGE_SIZE,
      },
      sorting: sortModel,
    }

    const variables: InventoryProductBatchesQueryVariables = {
      num: config.pagination.pageSize,
      start: config.pagination.page * config.pagination.pageSize,
      warehouseId,
      warehouseBatchId,
      searchText: filter.text?.value,
      categoryIds: filter.categoryIds?.value,
      productGroupIds: filter.productGroupIds?.value,
      manufacturerIds: filter.manufacturerIds?.value,
      productState: filter.productState?.value,
      siteId: filter.siteId?.value,
      includeCustomFields: shouldShowCustomFields,
    }

    // NotCountedWithValue is a combination of two filters
    if (filter.inventoryState?.value === NotCountedWithValue) {
      variables.inventoryState = [InventoryItemBatchState.NotCounted]
      variables.hasAvailable = true
    } else {
      variables.inventoryState = filter.inventoryState?.value
    }

    if (config.sorting) {
      const sortItem = config.sorting[0]

      if (sortItem) {
        variables.sort = sortItems.find(
          (s) => s.field === sortItem.field && s.sort === sortItem.sort,
        )?.value
      }
    }

    return variables
  }, [
    sortModel,
    filter.categoryIds?.value,
    filter.inventoryState?.value,
    filter.manufacturerIds?.value,
    filter.productGroupIds?.value,
    filter.productState?.value,
    filter.siteId?.value,
    filter.text?.value,
    shouldShowCustomFields,
    sortItems,
    warehouseBatchId,
    warehouseId,
  ])

  return queryVariables
}
