import { useTranslation } from 'react-i18next'
import { Settings } from '.'
import { FormProvider, useForm } from 'react-hook-form'
import { PageHeader } from '../../../components/page-header'
import { Button, Container } from '@mui/material'
import { CurrencyAndTimezone } from './sections/currency-and-timezone'
import { getDirtyValues } from '../../../utils/dirty-fields'
import { FormRouteLeavingGuard } from '../../../components/form-route-leaving-guard'
import { PosRequiredFields } from './sections/pos-required-fields'
import { LimitPaymentOptions } from './sections/limit-payment-options'
import { DigitalReceipt } from './sections/digital-receipt'
import { WarehouseSettings } from './sections/warehouse'
import {
  customProductFields,
  PosCustomProductInfo,
} from './sections/pos-custom-product-info'
import { PosDeliveryOptions } from './sections/pos-delivery-options'
import { StoreStaffPermissions } from './sections/permissions'
import { PosSettings } from './sections/pos-settings'
import { PosProductGroups } from './sections/pos-product-groups'
import { useQuery } from '@apollo/client'
import { PosShipments } from './sections/pos-shipments'
import { InStoreReplenishment } from './sections/in-store-replenishment'
import { CreditNotes } from './sections/credit-notes'
import { PinSettings } from './sections/pin-settings'
import { GiftCardsSettings } from './sections/gift-cards'
import { GetConfigVarsDocument } from '../../../generated/graphql'
import { PosProductAttributes } from './sections/pos-product-attributes'
import { InvoiceDownPayment } from './sections/invoice'
import { PosUi } from './sections/pos-ui'
import { LegacyRecommendations } from './sections/legacy-recommendations'
import { PosTheme } from './sections/pos-theme'
import { stripTypename } from '@apollo/client/utilities'
import { useTracking } from '../../../hooks/tracking'

export type GeneralSettingsFormContext = Settings

type Props = {
  onSubmit: (
    fieldsToUpdate: Partial<GeneralSettingsFormContext>,
  ) => Promise<Settings | undefined>

  settings: Settings | undefined
  formId: string
}

export const GeneralSettingsForm = ({ onSubmit, settings, formId }: Props) => {
  const { t } = useTranslation(['shared', 'settings'])

  const { data: configVarsData } = useQuery(GetConfigVarsDocument)
  const { trackFormSuccess, trackFormError } = useTracking()

  const shouldShowPinSettings = configVarsData?.configVars.posUsingAuthV2
  const shouldShowInvoiceDownPayment = configVarsData?.configVars.invoice
  const shouldShowGiftCards = configVarsData?.configVars.giftCard

  const defaultValues = {
    uistrings: Object.fromEntries(
      customProductFields.map((field) => [field, '']),
    ),
    ...stripTypename(settings),
  }
  const formContext = useForm<GeneralSettingsFormContext>({
    defaultValues: defaultValues,
    values: defaultValues,
  })

  const handleSubmit = async (formData: GeneralSettingsFormContext) => {
    try {
      formContext.clearErrors()
      const dirtyFieldsRecord = formContext.formState.dirtyFields
      const {
        giftcardproducts_email,
        giftcardproducts_sms,
        product_downpayment,
        ...fieldsToUpdate
      } = getDirtyValues(dirtyFieldsRecord, formData)

      const data = await onSubmit(fieldsToUpdate)
      formContext.reset(data)
      trackFormSuccess({ name: 'general-settings' })
    } catch (error) {
      trackFormError({ name: 'general-settings' })
    }
  }

  return (
    <FormProvider {...formContext}>
      <FormRouteLeavingGuard />
      <PageHeader
        title={t('shared:menu.settings')}
        isSticky
        rightColumn={
          <Button
            type="submit"
            disabled={!formContext.formState.isDirty}
            form={formId}
          >
            {t('shared:action.save')}
          </Button>
        }
      />

      <form
        id={formId}
        noValidate
        onSubmit={formContext.handleSubmit(handleSubmit)}
      >
        <Container>
          <CurrencyAndTimezone />
          <DigitalReceipt />
          <WarehouseSettings />
          <InStoreReplenishment />
          <PosProductAttributes />
          <PosCustomProductInfo />
          <StoreStaffPermissions role="store_manager" />
          <StoreStaffPermissions role="store_staff" />
          <PosDeliveryOptions />
          <PosTheme />
          <PosSettings />
          <PosUi />
          <PosProductGroups />
          <PosShipments />
          <PosRequiredFields />
          <LimitPaymentOptions />
          {shouldShowPinSettings && <PinSettings />}
          <LegacyRecommendations />
          {shouldShowGiftCards && <GiftCardsSettings />}
          <CreditNotes />
          {shouldShowInvoiceDownPayment && <InvoiceDownPayment />}
        </Container>
      </form>
    </FormProvider>
  )
}
