import { useMutation } from '@apollo/client'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  List,
  ListItem,
  ListItemText,
  Stack,
} from '@mui/material'
import { enqueueSnackbar } from 'notistack'
import { useContext, useEffect } from 'react'
import { FormProvider } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { AddReportDocument, ReportType } from '../../../generated/graphql'
import { SwitchInput } from '../../../inputs/switch-input'
import { TextFieldInput } from '../../../inputs/text-field-input'
import { getErrorMessages } from '../../../utils/error-mapping'
import { stripNullValues } from '../../../utils/strip-null-values'
import { Form } from '../create-report-job-dialog'
import { ReportJobDialogContext } from '../create-report-job-dialog/report-job-dialog-provider'
import { usePopulatedForm } from '../create-report-job-dialog/use-populated-form'
import { useTracking } from '../../../hooks/tracking'

export const SaveAsTemplateDialog = () => {
  const navigate = useNavigate()
  const { t } = useTranslation(['reports', 'shared'])
  const { backTo, dialogData, name } = useContext(ReportJobDialogContext)
  const { formContext } = usePopulatedForm({ dialogData, name })
  const { trackFormSuccess, trackFormError } = useTracking()
  const [addReportTemplate, { loading: isAddingReportTemplate }] =
    useMutation(AddReportDocument)

  const handleClose = () =>
    void navigate({ pathname: backTo }, { replace: true })

  const showForceWarehouseFilter = ![
    ReportType.Creditnote,
    ReportType.GiftCard,
    ReportType.OmniFulfillment,
  ].some((type) => type === dialogData?.reporttype)

  const onSubmit = async (formData: Form) => {
    const {
      reportdefinition,
      reporttype,
      forcewarehousefilter,
      reportname,
      reportdescription,
    } = formData
    if (!reportname) return

    const forceWarehouseFilter = Boolean(forcewarehousefilter)

    try {
      await addReportTemplate({
        variables: {
          report: stripNullValues(
            {
              reportname,
              reportdescription,
              ...(showForceWarehouseFilter
                ? {
                    forcewarehousefilter: forceWarehouseFilter,
                  }
                : {}),
              reportdefinition,
              reporttype,
            },
            { deleteEmptyStrings: true },
          ),
        },
      })
      enqueueSnackbar(t('reports:success_save_template'), {
        variant: 'success',
      })
      handleClose()
      trackFormSuccess({
        name: 'report-save-as-template-dialog',
        forceWarehouseFilter,
      })
    } catch (error) {
      enqueueSnackbar(getErrorMessages(error)[0], { variant: 'error' })
      trackFormError({ name: 'report-save-as-template-dialog' })
    }
  }

  useEffect(() => {
    formContext.setValue('reportname', name)
  }, [formContext, name])

  return (
    <FormProvider {...formContext}>
      <Dialog open onClose={handleClose} fullWidth maxWidth="xs" sx={{ p: 2 }}>
        <DialogTitle>{t('save_as_template_dialog.title')}</DialogTitle>
        <DialogContent sx={{ p: 0, pb: 2 }}>
          <List>
            <Stack gap={1}>
              <ListItem>
                <TextFieldInput
                  label={t('save_as_template_dialog.name')}
                  name="reportname"
                  control={formContext.control}
                  required
                />
              </ListItem>
              <ListItem>
                <TextFieldInput
                  label={t('save_as_template_dialog.description')}
                  name="reportdescription"
                  control={formContext.control}
                />
              </ListItem>
              {showForceWarehouseFilter && (
                <ListItem
                  component="label"
                  secondaryAction={
                    <SwitchInput
                      name="forcewarehousefilter"
                      control={formContext.control}
                    />
                  }
                >
                  <ListItemText
                    primary={t(
                      'save_as_template_dialog.store_manager_warehouse_filtering',
                    )}
                    secondary={t(
                      'save_as_template_dialog.store_manager_warehouse_filtering_description',
                    )}
                  />
                </ListItem>
              )}
            </Stack>
          </List>
        </DialogContent>
        <DialogActions>
          <Button color="secondary" onClick={handleClose}>
            {t('shared:action.cancel')}
          </Button>
          <Button
            onClick={formContext.handleSubmit(onSubmit)}
            loading={isAddingReportTemplate}
          >
            {t('shared:action.save')}
          </Button>
        </DialogActions>
      </Dialog>
    </FormProvider>
  )
}
