import { useApolloClient } from '@apollo/client'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  List,
  ListItemText,
} from '@mui/material'
import { SectionHeader } from '@sitoo/mui-components'
import { useSnackbar } from 'notistack'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { ListItemSkeleton } from '../../../components/list-item-skeleton'
import {
  GetShipmentQuery,
  GetShipmentsDocument,
  ShipmentState,
} from '../../../generated/graphql'
import { useLocalizedDate } from '../../../hooks/localized-date'
import { SetStateDialog } from '../../shipment/set-state-dialog'

type Props = {
  isLoading?: boolean
  shipment?: GetShipmentQuery['shipment']
}

export const Overview = ({ shipment, isLoading }: Props) => {
  const { t } = useTranslation(['shared', 'shipments'])
  const { formatDate } = useLocalizedDate()
  const [showSetStateDialog, setShowSetStateDialog] = useState(false)
  const { enqueueSnackbar } = useSnackbar()

  const client = useApolloClient()

  return (
    <>
      <Accordion defaultExpanded className="MuiAccordionRoot">
        <AccordionSummary aria-controls="overview">
          <SectionHeader sx={{ p: 0 }}>
            {t('shipments:view_panel.overview')}
          </SectionHeader>
        </AccordionSummary>
        <AccordionDetails sx={{ p: 0 }}>
          <List>
            <ListItemSkeleton
              secondaryAction={
                <ListItemText
                  sx={{
                    textAlign: 'end',
                  }}
                  slotProps={{
                    secondary: {
                      sx: {
                        whiteSpace: 'pre-line',
                      },
                    },
                  }}
                >
                  {shipment?.id}
                </ListItemText>
              }
              isLoading={isLoading}
              childrenSkeleton
              divider
              data-testid="shipment-id"
            >
              <ListItemText
                slotProps={{
                  secondary: {
                    sx: {
                      whiteSpace: 'pre-line',
                    },
                  },
                }}
              >
                {t('shipments:view_panel.id')}
              </ListItemText>
            </ListItemSkeleton>

            <ListItemSkeleton
              secondaryAction={
                <>
                  {shipment?.state &&
                  [
                    ShipmentState.Cancelled,
                    ShipmentState.Closed,
                    ShipmentState.ClosedIncomplete,
                  ].includes(shipment?.state) ? (
                    <ListItemText>
                      {t(`shipments:shipment_state.${shipment?.state || ''}`)}
                    </ListItemText>
                  ) : (
                    <>
                      <SetStateDialog
                        open={showSetStateDialog}
                        state={shipment?.state}
                        shipmentId={shipment?.id || ''}
                        shipmentVersion={shipment?.version || 0}
                        allItemsReceived={shipment?.all_items_received}
                        hasUndeclaredItems={!!shipment?.items_undeclared.length}
                        onClose={() => setShowSetStateDialog(false)}
                        onSuccess={() => {
                          void client.refetchQueries({
                            include: [GetShipmentsDocument],
                          })
                          enqueueSnackbar(
                            t('shipments:shipment_message.success_update'),
                          )
                          setShowSetStateDialog(false)
                        }}
                        onError={(errorMessage) => {
                          enqueueSnackbar(errorMessage, { variant: 'error' })
                        }}
                      />
                      <Button
                        size="small"
                        color="secondary"
                        onClick={() => setShowSetStateDialog(true)}
                      >
                        {t('shared:action.set')}
                      </Button>
                    </>
                  )}
                </>
              }
              isLoading={isLoading}
              childrenSkeleton
              divider
              data-testid="shipment-state"
            >
              <ListItemText
                secondary={
                  shipment?.state &&
                  ![
                    ShipmentState.Cancelled,
                    ShipmentState.Closed,
                    ShipmentState.ClosedIncomplete,
                  ].includes(shipment?.state)
                    ? t(`shipments:shipment_state.${shipment?.state || ''}`)
                    : ''
                }
              >
                {t('shipments:view_panel.state')}
              </ListItemText>
            </ListItemSkeleton>

            <ListItemSkeleton
              secondaryAction={
                <ListItemText
                  sx={{
                    textAlign: 'end',
                  }}
                  slotProps={{
                    secondary: {
                      sx: {
                        whiteSpace: 'pre-line',
                      },
                    },
                  }}
                >
                  {shipment?.created_at
                    ? formatDate(shipment?.created_at)
                    : '-'}
                </ListItemText>
              }
              isLoading={isLoading}
              childrenSkeleton
              divider
              data-testid="shipment-created"
            >
              <ListItemText
                slotProps={{
                  secondary: {
                    sx: {
                      whiteSpace: 'pre-line',
                    },
                  },
                }}
              >
                {t('shipments:view_panel.created')}
              </ListItemText>
            </ListItemSkeleton>

            <ListItemSkeleton
              hide={!shipment?.modified_at}
              secondaryAction={
                <ListItemText
                  sx={{
                    textAlign: 'end',
                  }}
                  slotProps={{
                    secondary: {
                      sx: {
                        whiteSpace: 'pre-line',
                      },
                    },
                  }}
                >
                  {shipment?.modified_at
                    ? formatDate(shipment?.modified_at)
                    : '-'}
                </ListItemText>
              }
              isLoading={isLoading}
              childrenSkeleton
              divider
              data-testid="shipment-modified"
            >
              <ListItemText
                slotProps={{
                  secondary: {
                    sx: {
                      whiteSpace: 'pre-line',
                    },
                  },
                }}
              >
                {t('shipments:view_panel.modified')}
              </ListItemText>
            </ListItemSkeleton>

            <ListItemSkeleton
              hide={
                !shipment?.state ||
                [
                  ShipmentState.New,
                  ShipmentState.Packed,
                  ShipmentState.TransportOrdered,
                  ShipmentState.ReadyForPickup,
                  ShipmentState.InTransit,
                ].includes(shipment?.state)
              }
              secondaryAction={
                <ListItemText
                  sx={{
                    textAlign: 'end',
                  }}
                  slotProps={{
                    secondary: {
                      sx: {
                        whiteSpace: 'pre-line',
                      },
                    },
                  }}
                >
                  {t(
                    shipment?.all_packages_arrived
                      ? 'shared:label.yes'
                      : 'shared:label.no',
                  )}
                </ListItemText>
              }
              isLoading={isLoading}
              childrenSkeleton
              divider
              data-testid="shipment-all-packages-arrived"
            >
              <ListItemText
                slotProps={{
                  secondary: {
                    sx: {
                      whiteSpace: 'pre-line',
                    },
                  },
                }}
              >
                {t('shipments:view_panel.all_packages_arrived')}
              </ListItemText>
            </ListItemSkeleton>

            <ListItemSkeleton
              hide={
                !shipment?.state ||
                [
                  ShipmentState.New,
                  ShipmentState.Packed,
                  ShipmentState.TransportOrdered,
                  ShipmentState.ReadyForPickup,
                  ShipmentState.InTransit,
                  ShipmentState.Arrived,
                ].includes(shipment?.state)
              }
              secondaryAction={
                <ListItemText
                  sx={{
                    textAlign: 'end',
                  }}
                  slotProps={{
                    secondary: {
                      sx: {
                        whiteSpace: 'pre-line',
                      },
                    },
                  }}
                >
                  {t(
                    shipment?.all_items_received
                      ? 'shared:label.yes'
                      : 'shared:label.no',
                  )}
                </ListItemText>
              }
              isLoading={isLoading}
              childrenSkeleton
              divider
              data-testid="shipment-all-items-received"
            >
              <ListItemText
                slotProps={{
                  secondary: {
                    sx: {
                      whiteSpace: 'pre-line',
                    },
                  },
                }}
              >
                {t('shipments:view_panel.all_items_received')}
              </ListItemText>
            </ListItemSkeleton>

            <ListItemSkeleton
              hide={
                !shipment?.state ||
                [
                  ShipmentState.New,
                  ShipmentState.Packed,
                  ShipmentState.TransportOrdered,
                  ShipmentState.ReadyForPickup,
                  ShipmentState.InTransit,
                  ShipmentState.Arrived,
                ].includes(shipment?.state)
              }
              secondaryAction={
                <ListItemText
                  sx={{
                    textAlign: 'end',
                  }}
                  slotProps={{
                    secondary: {
                      sx: {
                        whiteSpace: 'pre-line',
                      },
                    },
                  }}
                >
                  {shipment?.items_undeclared.length || 0}
                </ListItemText>
              }
              isLoading={isLoading}
              childrenSkeleton
              divider
              data-testid="shipment-undeclared-items-received"
            >
              <ListItemText
                slotProps={{
                  secondary: {
                    sx: {
                      whiteSpace: 'pre-line',
                    },
                  },
                }}
              >
                {t('shipments:view_panel.undeclared_items_received')}
              </ListItemText>
            </ListItemSkeleton>
          </List>
        </AccordionDetails>
      </Accordion>
    </>
  )
}
