import { Box, Paper, Typography, Container } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { ReportQuery } from '../../../../generated/graphql'
import { FilterChips } from '../../filter-chips'
import { GraphSectionPreview } from './graph-section-preview'
import { ReportResultTablePreview } from './report-result-table-preview'

type Props = {
  data?: ReportQuery
}

export const TemplatePreview = ({ data }: Props) => {
  const { t } = useTranslation('reports')

  if (!data) {
    return null
  }

  return (
    <Container sx={{ mt: 3 }}>
      <Paper elevation={0} sx={{ p: 2 }}>
        <Typography variant="body02">{t('create.preview_header')}</Typography>
        <FilterChips filters={data.report.reportdefinition.filters} />
      </Paper>
      <Box>
        <GraphSectionPreview report={data.report} />
        <ReportResultTablePreview report={data.report} />
      </Box>
    </Container>
  )
}
