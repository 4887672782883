import { ListItemText, MenuItem, TextField } from '@mui/material'
import { useContext, useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { FilterContext } from '../../../components/data-grid/context'
import { useStateParams } from '../../../hooks/state-params'
import { useTracking } from '../../../hooks/tracking'

export enum PaymentValidated {
  Validated = 'Validated',
  NotValidated = 'NotValidated',
}

type QueryParamsState = {
  paymentValidated?: string
}

export const PaymentValidatedFilter = () => {
  const { t } = useTranslation(['shared', 'filter'])
  const {
    hasApply,
    setFilter,
    removeFilter,
    subscribeOnResetFilter,
    registerFilter,
  } = useContext(FilterContext)
  const { trackButtonClickEvent } = useTracking()

  const filterKey = 'paymentValidated'

  const options = useMemo(
    () =>
      Object.values(PaymentValidated).map((x) => ({
        value: x,
        label: t(`filter:payment_validated.${x}`),
      })),
    [t],
  )

  const [queryParams, setQueryParams] = useStateParams<QueryParamsState>()

  const paymentValidated = options.find(
    ({ value }) => value === queryParams[filterKey],
  )
    ? queryParams[filterKey]
    : ''

  useEffect(() => {
    registerFilter({ key: filterKey })
  }, [registerFilter])

  useEffect(() => {
    if (paymentValidated) {
      const status = t(`filter:payment_validated.${paymentValidated}`)

      setFilter(filterKey, {
        label: t('filter:payment_validated.label'),
        labelValues: [status],
        value: paymentValidated,
      })
    } else {
      removeFilter(filterKey)
    }
  }, [options, removeFilter, setFilter, t, paymentValidated])

  useEffect(() => {
    const unsubscribe = subscribeOnResetFilter((key) => {
      if (!key || key === filterKey) {
        setQueryParams({ [filterKey]: undefined })
      }
    })

    return () => unsubscribe()
  }, [setQueryParams, subscribeOnResetFilter])

  return (
    <TextField
      data-testid="validated-refund-field"
      onChange={(event) =>
        setQueryParams(
          { [filterKey]: event.target.value || undefined },
          hasApply !== true,
        )
      }
      value={paymentValidated}
      select
      slotProps={{ select: { displayEmpty: true } }}
      label={t('filter:payment_validated.label')}
    >
      <MenuItem
        value=""
        divider
        onClick={trackButtonClickEvent({
          name: 'payment-validated-option',
          value: '',
        })}
      >
        <ListItemText> {t('label.all')}</ListItemText>
      </MenuItem>

      {options.map((option) => (
        <MenuItem
          data-testid="validated-refund-option"
          divider
          key={String(option.value)}
          value={String(option.value)}
          onClick={trackButtonClickEvent({
            name: 'payment-validated-option',
            value: option.value,
          })}
        >
          <ListItemText>{option.label}</ListItemText>
        </MenuItem>
      ))}
    </TextField>
  )
}
