import { MenuItem, TextField, Typography } from '@mui/material'
import { useContext, useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { FilterContext } from '../../../components/data-grid/context'
import { ShipmentState } from '../../../generated/graphql'
import { useStateParams } from '../../../hooks/state-params'
import { useTracking } from '../../../hooks/tracking'

type QueryParamsState = {
  shipmentState?: string | string[]
}

export const StateFilter = () => {
  const { t } = useTranslation('shipments')
  const { setFilter, removeFilter, subscribeOnResetFilter, registerFilter } =
    useContext(FilterContext)

  const { trackButtonClickEvent } = useTracking()
  const filterKey = 'shipmentState'

  const options = useMemo(
    () => [
      {
        name: t('shipments:state_filter.show_all'),
        value: '',
      },
      {
        name: t('shipments:state_filter.show_all_open'),
        value: [
          ShipmentState.New,
          ShipmentState.Packed,
          ShipmentState.TransportOrdered,
          ShipmentState.ReadyForPickup,
          ShipmentState.InTransit,
          ShipmentState.Arrived,
          ShipmentState.Arrived,
        ],
      },
      {
        name: t('shipments:state_filter.show_all_ready_to_send'),
        value: [ShipmentState.Packed, ShipmentState.TransportOrdered],
      },
      {
        name: t('shipments:state_filter.show_all_in_transport'),
        value: [ShipmentState.ReadyForPickup, ShipmentState.InTransit],
      },
      {
        name: t('shipments:state_filter.show_all_at_destination'),
        value: [ShipmentState.Arrived, ShipmentState.Received],
      },
      {
        name: t('shipments:state_filter.show_all_closed'),
        value: [ShipmentState.Closed, ShipmentState.ClosedIncomplete],
      },
      {
        name: t(`shipments:shipment_state.${ShipmentState.New}`),
        value: ShipmentState.New,
      },
      {
        name: t(`shipments:shipment_state.${ShipmentState.Packed}`),
        value: ShipmentState.Packed,
      },
      {
        name: t(`shipments:shipment_state.${ShipmentState.TransportOrdered}`),
        value: ShipmentState.TransportOrdered,
      },
      {
        name: t(`shipments:shipment_state.${ShipmentState.ReadyForPickup}`),
        value: ShipmentState.ReadyForPickup,
      },
      {
        name: t(`shipments:shipment_state.${ShipmentState.InTransit}`),
        value: ShipmentState.InTransit,
      },
      {
        name: t(`shipments:shipment_state.${ShipmentState.Arrived}`),
        value: ShipmentState.Arrived,
      },
      {
        name: t(`shipments:shipment_state.${ShipmentState.Received}`),
        value: ShipmentState.Received,
      },
      {
        name: t(`shipments:shipment_state.${ShipmentState.Closed}`),
        value: ShipmentState.Closed,
      },
      {
        name: t(`shipments:shipment_state.${ShipmentState.ClosedIncomplete}`),
        value: ShipmentState.ClosedIncomplete,
      },
      {
        name: t(`shipments:shipment_state.${ShipmentState.Cancelled}`),
        value: ShipmentState.Cancelled,
      },
    ],
    [t],
  )

  const [queryParams, setQueryParams] = useStateParams<QueryParamsState>()

  const shipmentState = queryParams[filterKey]
    ? queryParams[filterKey]
    : undefined

  useEffect(() => {
    if (shipmentState) {
      setFilter(filterKey, {
        label: t('shipments:state_filter_tag_label', {
          state: options.find((o) => String(o.value) === String(shipmentState))
            ?.name,
        }),
        value: shipmentState,
      })
    } else {
      setFilter(filterKey, {
        isDefault: true,
        label: t('shipments:state_filter_tag_label', {
          state: t('shipments:state_filter.show_all'),
        }),
        value: '',
      })
    }
  }, [shipmentState, removeFilter, setFilter, options, t])

  useEffect(() => {
    registerFilter({
      key: filterKey,
    })
  }, [registerFilter])

  useEffect(() => {
    const unsubscribe = subscribeOnResetFilter((key) => {
      if (!key || key === filterKey) setQueryParams({ [filterKey]: undefined })
    })

    return () => unsubscribe()
  }, [setQueryParams, subscribeOnResetFilter])

  const selectedValue = useMemo(() => {
    return shipmentState
      ? options.find((o) => String(o.value) === String(shipmentState))?.value
      : ''
  }, [options, shipmentState])

  return (
    <TextField
      onChange={(event) => {
        setQueryParams({ [filterKey]: event.target.value || undefined })
      }}
      value={selectedValue}
      slotProps={{ select: { displayEmpty: true } }}
      data-testid="shipment-state-filter"
      select
      label={t('shipments:state')}
    >
      {options.map(({ name, value }) => (
        <MenuItem
          key={String(value)}
          value={value}
          divider
          onClick={trackButtonClickEvent({
            name: 'shipments-state-filter',
            state: name,
          })}
        >
          <Typography variant="body02">{name}</Typography>
        </MenuItem>
      ))}
    </TextField>
  )
}
