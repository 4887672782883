import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  FormLabel,
  IconButton,
  Grid,
  TextField,
  SxProps,
} from '@mui/material'
import { DeleteIcon, DragHandleIcon, PlusIcon } from '@sitoo/mui-components'
import { Ref, useEffect } from 'react'
import { HTML5Backend } from 'react-dnd-html5-backend'
import { DndProvider } from 'react-dnd/dist/core/DndProvider'
import {
  FieldValues,
  UseControllerProps,
  useController,
  useFieldArray,
  useForm,
  useWatch,
} from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { DraggableItem } from '../../components/draggable-item'

type Props = {
  sx?: SxProps
  innerRef?: Ref<HTMLElement>
  label?: string
}

type Field = { id: string; value: string }

export const ValuesInput = <TFieldValues extends FieldValues>(
  props: Props & UseControllerProps<TFieldValues>,
) => {
  const { name, control, rules, sx, innerRef } = props
  const { t } = useTranslation(['shared'])

  // Original Controller
  const { field, fieldState } = useController({ name, control, rules })

  const errorMessage = fieldState.error?.message

  const formatValues = (values: string[] | undefined) => {
    return values?.map((value) => ({ value })) || []
  }

  // Local form
  const {
    control: localControl,
    reset,
    setValue,
    formState,
  } = useForm<{ root: Field[] }>()

  // Local array handler
  const { fields, append, remove, move } = useFieldArray({
    name: 'root',
    control: localControl,
  })

  // Watch changes for local controller
  const localFields = useWatch({ control: localControl })

  useEffect(() => {
    const values = localFields.root?.map((f) => f?.value)

    if (!values) return

    // Update Original Controller
    if (JSON.stringify(field.value) !== JSON.stringify(values)) {
      field.onChange(values)
    }
  }, [field, localFields])

  // Set defaultValues if field.value is not empty
  useEffect(() => {
    if (!formState.defaultValues?.root && field.value?.length) {
      reset({ root: formatValues(field.value) })
    }
  }, [field.value, formState, reset])

  return (
    <DndProvider backend={HTML5Backend}>
      <Box ref={innerRef} sx={sx}>
        <FormControl fullWidth>
          <FormLabel sx={{ mb: 0.5 }}>{props.label}</FormLabel>

          {fields.map(({ id, value }, index) => (
            <DraggableItem id={id} key={id} index={index} moveField={move}>
              <Grid
                container
                spacing={1}
                sx={{ py: 0.5, alignItems: 'center' }}
              >
                <Grid size="grow">
                  <TextField
                    defaultValue={value}
                    data-testid="value-input"
                    placeholder="Value"
                    error={!!errorMessage}
                    onChange={(event) => {
                      setValue(`root.${index}.value`, event.currentTarget.value)
                    }}
                  />
                </Grid>

                <Grid>
                  <IconButton
                    data-testid="delete-value"
                    onClick={() => remove(index)}
                  >
                    <DeleteIcon />
                  </IconButton>
                </Grid>

                <Grid>
                  <IconButton>
                    <DragHandleIcon />
                  </IconButton>
                </Grid>
              </Grid>
            </DraggableItem>
          ))}

          <FormHelperText error variant="standard" sx={{ mb: 1 }}>
            {errorMessage}
          </FormHelperText>

          <Button
            color="tertiary"
            data-testid="add-value"
            fullWidth
            startIcon={<PlusIcon />}
            onClick={(event: React.SyntheticEvent<HTMLButtonElement>) => {
              append({} as Field)
              const target = event.target as HTMLButtonElement
              setTimeout(() => target.scrollIntoView())
            }}
          >
            {t('shared:action.add_new')}
          </Button>
        </FormControl>
      </Box>
    </DndProvider>
  )
}
