import { useMutation } from '@apollo/client'
import { IconButton } from '@mui/material'
import { useGridApiContext } from '@mui/x-data-grid-pro'
import { ConfirmationDialog, DeleteIcon } from '@sitoo/mui-components'
import { enqueueSnackbar } from 'notistack'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { BulkDeleteReportJobsDocument } from '../../generated/graphql'
import { getErrorMessages } from '../../utils/error-mapping'

type Props = {
  refetch: () => void
}

export const DeleteReportJobsBulkAction = (props: Props) => {
  const [showDeleteDialog, setShowDeleteDialog] = useState(false)
  const apiRef = useGridApiContext()
  const { t } = useTranslation(['shared', 'reports'])
  const selectedIds = Array.from(
    apiRef.current.getSelectedRows().keys(),
  ) as number[]

  const [deleteReportJobs, { loading: isDeletingReportJobs }] = useMutation(
    BulkDeleteReportJobsDocument,
  )

  const handleDelete = async () => {
    try {
      await deleteReportJobs({ variables: { jobIds: selectedIds } })
      enqueueSnackbar({
        message: t('reports:success_delete_report_jobs_snackbar', {
          count: selectedIds.length,
        }),
      })
      setShowDeleteDialog(false)
      props.refetch()
    } catch (error) {
      const errorMessage = getErrorMessages(error)[0]
      enqueueSnackbar({ message: errorMessage, variant: 'error' })
    }
  }

  return (
    <>
      <ConfirmationDialog
        confirmAction={handleDelete}
        title={t('reports:delete_report_jobs_label', {
          count: selectedIds.length,
        })}
        text={t('reports:delete_report_jobs_description', {
          count: selectedIds.length,
        })}
        variant="destructive"
        open={showDeleteDialog}
        dataTestid="your-report-jobs-delete-dialog"
        onClose={() => setShowDeleteDialog(false)}
      />
      <IconButton
        onClick={() => setShowDeleteDialog(true)}
        disabled={isDeletingReportJobs}
      >
        <DeleteIcon />
      </IconButton>
    </>
  )
}
