import { Chip, ChipOwnProps } from '@mui/material'
import { useTranslation } from 'react-i18next'

type Props = {
  type: string | undefined
}

export const EventType = (props: Props) => {
  const { t } = useTranslation(['log_events'])
  const { type } = props

  const color: ChipOwnProps['color'] =
    type === 'login' ? 'gray' : type === 'oidc' ? 'black' : 'gray'

  if (!type) return null

  return (
    <Chip
      label={t(`log_events:event_type.${type}`, { defaultValue: type })}
      size="small"
      color={color}
    />
  )
}
