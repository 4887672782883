import { Box, ListItem, SxProps } from '@mui/material'
import { PropsWithChildren } from 'react'

type Props = PropsWithChildren & {
  sx?: SxProps
}

export const ListItemBorderBox = (props: Props) => {
  return (
    <Box sx={{ p: 2, pt: 0, ...props.sx }}>
      <ListItem
        component="div"
        disablePadding
        sx={{
          borderWidth: '1px',
          borderLeftWidth: (theme) => theme.spacing(0.5),
          borderStyle: 'solid',
          borderColor: 'gray40',
        }}
      >
        {props.children}
      </ListItem>
    </Box>
  )
}
