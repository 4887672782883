import { Chip, ChipProps } from '@mui/material'
import { ReportJobState } from '../../generated/graphql'
import { useTranslation } from 'react-i18next'

type Props = {
  jobState?: ReportJobState
}
export const ReportJobStateChip = ({ jobState }: Props) => {
  const { t } = useTranslation(['reports'])

  const getStateChipColor = (jobState: ReportJobState): ChipProps['color'] => {
    switch (jobState) {
      case ReportJobState.InProgress:
        return 'blue'
      case ReportJobState.Pending:
        return 'gray'
      case ReportJobState.Failed:
        return 'red'
      default:
        return 'gray'
    }
  }

  return (
    jobState &&
    jobState !== ReportJobState.Finished && (
      <Chip
        label={t(`reports:report_states.${jobState}`)}
        color={getStateChipColor(jobState)}
        size="small"
      />
    )
  )
}
