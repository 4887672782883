import { Paper, Typography, TextField, Button, Box } from '@mui/material'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { LoginForm } from '.'
import { useTracking } from '../../hooks/tracking'

type Props = {
  loading: boolean
  loginAction(): Promise<void>
  onBack(): void
}

export const Mfa = (props: Props) => {
  const { t } = useTranslation(['login'])
  const { register, formState, setValue } = useFormContext<LoginForm>()
  const { loading, loginAction, onBack } = props
  const { trackButtonClickEvent, trackInputBlur, trackInputFocus } =
    useTracking()

  return (
    <Paper square elevation={0} sx={{ p: 3, maxWidth: '388px' }}>
      <Typography variant="displayMedium" sx={{ mb: 3 }}>
        {t('login:mfa_title')}
      </Typography>

      <TextField
        {...register('login.mfa', {
          required: t('shared:validation.field_required', {
            field: t('login:mfa_description'),
          }),
          onBlur: trackInputBlur({ name: 'login-mfa' }),
        })}
        error={!!formState.errors.login?.mfa}
        helperText={formState.errors.login?.mfa?.message}
        label={t('login:mfa_description')}
        autoComplete="one-time-code"
        slotProps={{
          htmlInput: { 'data-testid': 'mfa-input' },
        }}
        onFocus={trackInputFocus({ name: 'login-mfa' })}
        sx={{ mb: 2 }}
      />

      <Box sx={{ display: 'flex', justifyContent: 'end' }}>
        <Button
          sx={{ mr: 1 }}
          color="secondary"
          data-testid="back-button"
          size="small"
          onClick={() => {
            onBack()
            setValue('login.mfa', undefined)
          }}
        >
          {t('shared:action.back')}
        </Button>

        <Button
          onClick={trackButtonClickEvent(
            { name: 'login-verify-mfa' },
            loginAction,
          )}
          type="submit"
          disabled={loading}
          loading={loading}
          size="small"
          data-testid="verify-button"
        >
          {t('shared:label.verify')}
        </Button>
      </Box>
    </Paper>
  )
}
