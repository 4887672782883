import { Stack, Typography, FormHelperText } from '@mui/material'
import { SelectInput } from '../../../../inputs/select-input'
import { SettingsListItem } from '../../../../components/settings-list-item'
import { SettingsListSection } from '../../../../components/settings-list-section'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useForm, useFormContext } from 'react-hook-form'
import { GeneralSettingsFormContext } from '../general-settings-form'
import { TextFieldInput } from '../../../../inputs/text-field-input'
import {
  PinRange,
  PinSettingsBlockListEnum,
  PinSettingsPinLengthEnum,
} from '../../../../generated/graphql'
import { formatBlockRanges } from '../utils/format-block-ranges'

export const PinSettings = () => {
  const { control, setValue, getValues } =
    useFormContext<GeneralSettingsFormContext>()
  const { t } = useTranslation(['settings', 'shared'])

  const pinLengthOptions = Object.values(PinSettingsPinLengthEnum).map(
    (value) => ({
      name: t(
        `settings:general.pin_settings.pin_length_${value.toLowerCase()}`,
      ),
      value,
    }),
  )

  const { control: blockRangesControl } = useForm({
    defaultValues: {
      block_ranges: formatBlockRanges(getValues('pin_settings.block_ranges')),
    },
  })

  const predefinedBlocklistOptions = useMemo(
    () =>
      Object.values(PinSettingsBlockListEnum).map((value) => ({
        name: t(
          `settings:general.pin_settings.block_list_${value.toLowerCase()}`,
        ),
        value,
      })),
    [t],
  )

  return (
    <SettingsListSection
      title={t('settings:general.pin_settings.title')}
      information={t('settings:general.pin_settings.information')}
    >
      <SettingsListItem>
        <TextFieldInput
          label={t('settings:general.pin_settings.pin_days_valid_label')}
          name="pin_days_valid"
          control={control}
          type="number"
          rules={{ min: 1 }}
          transform={{
            output: (event) =>
              event.target.value ? Number(event.target.value) : '',
          }}
          helperText={t('settings:general.pin_settings.pin_days_valid_helper')}
        />
      </SettingsListItem>

      <SettingsListItem>
        <TextFieldInput
          label={t(
            'settings:general.pin_settings.pin_days_expiry_warning_label',
          )}
          name="pin_days_warning"
          control={control}
          type="number"
          transform={{
            output: (event) =>
              event.target.value ? Number(event.target.value) : '',
          }}
          helperText={t(
            'settings:general.pin_settings.pin_days_warning_helper',
          )}
          rules={{ min: 0 }}
          required
        />
      </SettingsListItem>

      <SettingsListItem>
        <TextFieldInput
          label={t(
            'settings:general.pin_settings.pin_allowed_hours_offline_label',
          )}
          name="pin_hours_offline"
          control={control}
          type="number"
          transform={{
            output: (event) =>
              event.target.value ? Number(event.target.value) : '',
          }}
          helperText={t(
            'settings:general.pin_settings.pin_allowed_hours_offline_helper',
          )}
          rules={{ min: 0 }}
          required
        />
      </SettingsListItem>

      <SettingsListItem>
        <SelectInput
          label={t('settings:general.pin_settings.pin_length_label')}
          name="pin_settings.pin_length"
          options={pinLengthOptions}
          control={control}
          helperText={t('settings:general.pin_settings.pin_length_helper')}
        />
      </SettingsListItem>

      <SettingsListItem>
        <SelectInput
          label={t('settings:general.pin_settings.predefined_blocklist_label')}
          name="pin_settings.block_list"
          options={predefinedBlocklistOptions}
          control={control}
          helperText={t(
            'settings:general.pin_settings.predefined_blocklist_helper',
          )}
        />
      </SettingsListItem>

      <SettingsListItem>
        <TextFieldInput
          label={t('settings:general.pin_settings.custom_blocklist_label')}
          name="block_ranges"
          control={blockRangesControl}
          multiline
          minRows={4}
          helperText={
            <FormHelperText component="span">
              {t('settings:general.pin_settings.custom_blocklist_helper_text')}
              <Stack mt={1} component="span">
                <Typography variant="caption">
                  {t(
                    'settings:general.pin_settings.custom_blocklist_example_label',
                  )}
                </Typography>
                <Typography variant="caption">1110-1119</Typography>
                <Typography variant="caption">2222</Typography>
              </Stack>
            </FormHelperText>
          }
          onChange={(event) => {
            const inputValue = event.target.value
            const parsedValue = inputValue
              ?.split('\n')
              .map((line) => {
                const [from, to] = line.split('-')
                if (!from) return null
                const range: PinRange = {
                  from: from,
                  to: to || from,
                }
                return range
              })
              .filter((line) => line !== null)

            setValue('pin_settings.block_ranges', parsedValue, {
              shouldDirty: true,
            })
          }}
        />
      </SettingsListItem>
    </SettingsListSection>
  )
}
