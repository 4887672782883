import { useMutation, useQuery } from '@apollo/client'
import { GridCellParams, useGridApiRef } from '@mui/x-data-grid-pro'
import { FolderIcon } from '@sitoo/mui-components'
import { useSnackbar } from 'notistack'
import { useCallback, useContext, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { DataGrid } from '../../../components/data-grid'
import { FilterContext } from '../../../components/data-grid/context'
import { CursorPagination } from '../../../components/data-grid/cursor-pagination'
import { TextFilter } from '../../../components/data-grid/filters/text-filter'
import { FranchiseSiteFilter } from '../../../filters/franchise-site-filter'
import { ManufacturerIdFilter } from '../../../filters/manufacturer-id-filter'
import { CategoryIdFilter } from '../../../filters/product-category-id-filter'
import { ProductGroupIdFilter } from '../../../filters/product-group-id-filter'
import { WarehouseItemStateFilter } from '../../../filters/warehouse-item-state-filter'
import { WarehouseProductStateFilter } from '../../../filters/warehouse-product-state-filter'
import {
  DeleteWarehouseBatchItemsDocument,
  InventoryProductBatchesDocument,
  InventoryProductBatchesQuery,
  UpdateWarehouseBatchItems2Document,
} from '../../../generated/graphql'
import { useTracking } from '../../../hooks/tracking'
import { ArrayElement } from '../../../utils/types'
import { useBatchItemsVariables } from './use-batch-item-variables'
import { useInventoryBatchSortItems } from './use-sort-items'
import { useStocktakingColumns } from './use-stocktaking-columns'

export type Row = ArrayElement<
  InventoryProductBatchesQuery['productWarehouseBatches']['items']
>

type Props = {
  warehouseId: number
  warehouseBatchId: number
  onShowFilter(): void
}

const PAGE_SIZE = 100

type Filter = TextFilter &
  CategoryIdFilter &
  ProductGroupIdFilter &
  ManufacturerIdFilter &
  FranchiseSiteFilter &
  WarehouseProductStateFilter &
  WarehouseItemStateFilter

export const InventoryProductList = (props: Props) => {
  const { warehouseId, warehouseBatchId } = props
  const { t } = useTranslation(['shared', 'stocktaking', 'products'])
  const { enqueueSnackbar } = useSnackbar()

  const { trackFormSuccess, trackFormError } = useTracking()

  const apiRef = useGridApiRef()

  const [updateWarehouseBatchItems] = useMutation(
    UpdateWarehouseBatchItems2Document,
  )
  const [deleteWarehouseBatchItems] = useMutation(
    DeleteWarehouseBatchItemsDocument,
  )

  const { columns } = useStocktakingColumns()

  const { filter, isFilterReady } =
    useContext<FilterContext<Filter>>(FilterContext)

  const sortItems = useInventoryBatchSortItems()

  const queryVariables = useBatchItemsVariables({
    apiRef,
    warehouseId,
    warehouseBatchId,
    sortItems,
  })

  const {
    data,
    loading: fetchLoading,
    fetchMore,
  } = useQuery(InventoryProductBatchesDocument, {
    fetchPolicy: 'cache-and-network',
    notifyOnNetworkStatusChange: true,
    variables: queryVariables,
    skip: !isFilterReady,
  })

  const isLoading = fetchLoading || !data?.productWarehouseBatches

  const rows = useMemo(() => data?.productWarehouseBatches.items || [], [data])

  const fetchMoreItems = useCallback(() => {
    const { pageSize } = apiRef.current.state.pagination.paginationModel

    if (data?.productWarehouseBatches.totalcount) {
      return fetchMore({
        variables: {
          start: (data.productWarehouseBatches?.start || 0) + pageSize,
        },
      })
    }
  }, [apiRef, data, fetchMore])

  const processRowUpdate = useCallback(
    async (newRow: Row, oldRow: Row) => {
      const quantity = newRow.decimalquantity
      const comment = newRow.comment

      if (quantity === oldRow.decimalquantity && comment === oldRow.comment) {
        return newRow
      }

      try {
        if (quantity) {
          const { data: batchItems } = await updateWarehouseBatchItems({
            variables: {
              warehouseId,
              warehouseBatchId,
              siteId: filter.siteId?.value,
              warehouseBatchItems: [
                {
                  sku: newRow.sku,
                  decimalquantity: quantity,
                  comment: comment ?? '',
                },
              ],
            },
          })

          const batchItem = batchItems?.updateWarehouseBatchItems2?.find(
            (x) => x.sku === newRow.sku,
          )

          if (batchItem) {
            newRow.decimalcounteddiff = batchItem.decimalcounteddiff
            newRow.decimalnostockvalue = batchItem.decimalnostockvalue
            newRow.warehousebatchitemstate = batchItem.warehousebatchitemstate
            newRow.datemodified = batchItem.datemodified
            newRow.comment = batchItem.comment
            newRow.emailowner = batchItem.emailowner
          }
        } else {
          await deleteWarehouseBatchItems({
            variables: {
              warehouseId,
              warehouseBatchId,
              skus: [newRow.sku],
            },
          })
          newRow.decimalcounteddiff = undefined
          newRow.decimalnostockvalue = undefined
          newRow.warehousebatchitemstate = undefined
          newRow.datemodified = undefined
          newRow.comment = undefined
          newRow.emailowner = undefined
        }

        trackFormSuccess({ name: 'stocktaking-list-row' })

        return newRow
      } catch (error) {
        trackFormError({ name: 'stocktaking-list-row' })
        enqueueSnackbar(
          t('stocktaking:failure_update_stocktaking_snackbar', { count: 1 }),
          { variant: 'error' },
        )

        return oldRow
      }
    },
    [
      deleteWarehouseBatchItems,
      enqueueSnackbar,
      filter.siteId?.value,
      t,
      trackFormError,
      trackFormSuccess,
      updateWarehouseBatchItems,
      warehouseBatchId,
      warehouseId,
    ],
  )

  const isCellEditable = (params: GridCellParams<Row>) => {
    if (params.field === 'comment') {
      return !!params.row.decimalquantity
    }

    return true
  }

  const columnVisibilityModel = useMemo(
    () => ({
      ['productid']: false,
      ['activestate']: false,
    }),
    [],
  )

  return (
    <DataGrid
      name="stocktaking-session"
      apiRef={apiRef}
      columns={columns}
      processRowUpdate={processRowUpdate}
      rows={rows}
      getRowId={(row) => row.sku}
      rowCount={data?.productWarehouseBatches.totalcount || 0}
      loading={isLoading}
      fetchMore={fetchMoreItems}
      slots={{ pagination: CursorPagination }}
      isCellEditable={isCellEditable}
      noRowsOverlay={{
        icon: <FolderIcon />,
        title: t('products:grid.title'),
        description: t('products:grid.description'),
      }}
      rowHeight={51}
      hasTextFilter
      onShowFilter={props.onShowFilter}
      showMore={
        Number(data?.productWarehouseBatches.items?.length) <
        Number(data?.productWarehouseBatches.totalcount)
      }
      hasPageHeader
      paginationModel={{ page: 0, pageSize: PAGE_SIZE }}
      sorting={sortItems}
      columnVisibilityModel={columnVisibilityModel}
    />
  )
}
