import { Tab, Tabs } from '@mui/material'
import { useAbsolutePath } from '../../../../hooks/absolute-path'
import { RootRoute } from '../../..'
import { Outlet, useLocation, useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { PageHeader } from '../../../../components/page-header'

export const SettingsCtuNorwayPage = () => {
  const generatePath = useAbsolutePath()
  const navigate = useNavigate()
  const location = useLocation()
  const { t } = useTranslation(['shared', 'apps'])

  return (
    <>
      <PageHeader title={t('shared:menu.ctu_norway')}>
        <Tabs
          onChange={(_, route) => void navigate(route)}
          value={location.pathname}
          variant="standard"
          sx={{
            '.MuiButtonBase-root': {
              borderColor: 'transparent',
            },
          }}
        >
          <Tab
            label={t('apps:ctu_norway.report_tab')}
            value={generatePath(RootRoute.SettingsCtuNorway)}
          />
          <Tab
            label={t('apps:ctu_norway.settings_tab')}
            value={generatePath(RootRoute.SettingsCtuNorwaySettings)}
          />
        </Tabs>
      </PageHeader>

      <Outlet />
    </>
  )
}
