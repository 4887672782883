import { Button, Menu, MenuItem, Typography } from '@mui/material'
import { ChevronSmallDownIcon } from '@sitoo/mui-components'
import { useTranslation } from 'react-i18next'
import { useTracking } from '../../../hooks/tracking'
import { MouseEventHandler, useState } from 'react'
import { useAuthorization } from '../../../hooks/authorization'
import { SetPurchasePriceMappingDialog } from './purchase-price-mapping-dialog'
import { useSnackbar } from 'notistack'
import { GraphQLError } from 'graphql'

export const PurchasePriceListsContextMenu = () => {
  const { t } = useTranslation(['shared'])
  const { trackButtonClickEvent } = useTracking()
  const { enqueueSnackbar } = useSnackbar()

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)
  const isOpen = !!anchorEl

  const [showPurchasePriceMappingDialog, setShowPurchasePriceMappingDialog] =
    useState(false)

  const { writeSettingsPurchasePriceList } = useAuthorization().modules

  const handleClick: MouseEventHandler<HTMLButtonElement> = (event) => {
    setAnchorEl(isOpen ? null : event.currentTarget)
  }

  const closeMenu = () => {
    setAnchorEl(null)
  }

  const onMappingSuccess = () => {
    setShowPurchasePriceMappingDialog(false)
    enqueueSnackbar(t('settings:purchase_price_lists.success_mapping'))
  }

  const onMappingError = (error: GraphQLError | null) => {
    const code = error?.extensions?.['code']

    let message = t('settings:purchase_price_lists.error.mapping_generic')

    if (code === 'PURCHASE_PRICE_LIST_MAPPING_INVALID_ID') {
      const priceListId = error?.extensions?.['value'] as string

      message = t('settings:purchase_price_lists.error.invalid_mapping_id', {
        priceListId,
      })
    }

    enqueueSnackbar(message, { variant: 'error' })
  }

  return (
    <>
      <Button
        id="settings-purchase-price-bulk-actions-menu-button"
        aria-controls={
          isOpen ? 'settings-purchase-price-bulk-actions-menu' : undefined
        }
        data-testid="settings-purchase-price-bulk-actions-menu-button"
        aria-haspopup="true"
        aria-expanded={isOpen ? 'true' : undefined}
        onClick={handleClick}
        color="secondary"
        endIcon={<ChevronSmallDownIcon />}
      >
        {t('shared:label.actions')}
      </Button>

      <Menu
        id="settings-purchase-price-bulk-actions-menu"
        anchorEl={anchorEl}
        open={isOpen}
        onClose={closeMenu}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        slotProps={{
          list: {
            'aria-labelledby':
              'settings-purchase-price-bulk-actions-menu-button',
          },
        }}
      >
        <MenuItem
          onClick={trackButtonClickEvent(
            {
              name: 'settings-purchase-price-bulk-actions-menu-set-mapping',
            },
            () => {
              setShowPurchasePriceMappingDialog(true)
              closeMenu()
            },
          )}
          data-testid="purchase-price-bulk-menu-set-mapping"
        >
          <Typography variant="body02">
            {t(
              writeSettingsPurchasePriceList
                ? 'settings:purchase_price_lists.edit_mapping'
                : 'settings:purchase_price_lists.view_mapping',
            )}
          </Typography>
        </MenuItem>
      </Menu>

      <SetPurchasePriceMappingDialog
        onClose={() => setShowPurchasePriceMappingDialog(false)}
        onSuccess={onMappingSuccess}
        onError={onMappingError}
        open={showPurchasePriceMappingDialog}
      />
    </>
  )
}
