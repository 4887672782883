import { useFormContext } from 'react-hook-form'
import { GeneralSettingsFormContext } from '../general-settings-form'
import { useTranslation } from 'react-i18next'
import { AllCustomAttributesDocument } from '../../../../generated/graphql'
import { useQuery } from '@apollo/client'
import { DialogInput } from '../../../../inputs/dialog-input'
import { SettingsListSection } from '../../../../components/settings-list-section'
import { useMemo } from 'react'

export const InStoreReplenishment = () => {
  const { control } = useFormContext<GeneralSettingsFormContext>()
  const { t } = useTranslation(['shared', 'settings'])
  const { data, loading } = useQuery(AllCustomAttributesDocument)

  const formatValue = (id: string): { label: string } => {
    const attribute = data?.allCustomAttributes.find(
      (attribute) => attribute.id === id,
    )

    return {
      label: attribute?.title ?? '',
    }
  }

  const options = useMemo(
    () =>
      [...(data?.allCustomAttributes ?? [])]
        .sort((a, b) => a.title.localeCompare(b.title))
        .map(({ id }) => id),
    [data],
  )

  return (
    <SettingsListSection
      title={t('settings:general.store_replenishment.title')}
    >
      <DialogInput
        control={control}
        isLoading={loading}
        name="customattributes_in_store_replenishment"
        formatValue={formatValue}
        helperText={t(`settings:general.store_replenishment.helper`)}
        itemLabel={t(`settings:general.store_replenishment.title`)}
        addLabel={t('settings:general.store_replenishment.add_label')}
        options={options}
        emptyMessage={t('settings:general.store_replenishment.empty_message')}
      />
    </SettingsListSection>
  )
}
