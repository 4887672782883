import { SettingsDetailDialog } from '../../../../../components/settings-detail-dialog'
import { TextFieldInput } from '../../../../../inputs/text-field-input'
import { useTranslation } from 'react-i18next'
import { useForm } from 'react-hook-form'
import { useNavigate, useParams, generatePath } from 'react-router-dom'
import { useMe } from '../../../../../hooks/me'
import { RootRoute } from '../../../..'
import { useMutation, useQuery } from '@apollo/client'
import { useEffect } from 'react'
import {
  AddUpdateManufacturerDocument,
  DeleteManufacturerDocument,
  GetManufacturerDocument,
  GetManufacturersDetailedDocument,
  ManufacturerFragment,
} from '../../../../../generated/graphql'
import { CountriesInput } from '../../../../../inputs/countries-input'
import { useAuthorization } from '../../../../../hooks/authorization'

export const ProductBrandDialog = () => {
  const name = 'product-brand-dialog'
  const { t } = useTranslation(['shared', 'settings'])
  const { id } = useParams()
  const { me } = useMe()
  const { modules } = useAuthorization()
  const navigate = useNavigate()
  const productBrandId = id ? parseInt(id) : undefined
  const isNewBrand = !productBrandId

  const { loading: isFetchLoading, data } = useQuery(GetManufacturerDocument, {
    variables: { id: productBrandId! },
    fetchPolicy: 'cache-and-network',
    skip: isNewBrand,
  })

  const [addUpdateManufacturer, { loading: isUpdateLoading }] = useMutation(
    AddUpdateManufacturerDocument,
    { refetchQueries: [GetManufacturersDetailedDocument] },
  )

  const [deleteManufacturer, { loading: isDeleteLoading }] = useMutation(
    DeleteManufacturerDocument,
    { refetchQueries: [GetManufacturersDetailedDocument] },
  )

  const isLoading = isFetchLoading || isUpdateLoading || isDeleteLoading

  const deleteItem = async () => {
    if (!isNewBrand) {
      await deleteManufacturer({
        variables: {
          id: productBrandId,
        },
      })
    }
  }

  const onClose = () => {
    void navigate(
      generatePath(RootRoute.SettingsProductsBrands, {
        siteId: me?.siteId,
      }),
      { state: { ignoreLeavingGuard: true } },
    )
  }

  const onSave = async (data: ManufacturerFragment) => {
    if (!isNewBrand) {
      data.id = productBrandId
    }

    await addUpdateManufacturer({
      variables: {
        manufacturer: {
          ...data,
          countryid: data.countryid === '' ? undefined : data.countryid,
        },
      },
    })
  }

  const { control, handleSubmit, formState, getValues, reset } =
    useForm<ManufacturerFragment>({
      defaultValues: {
        name: '',
        email: '',
        phone: '',
        description: '',
        address: '',
        zip: '',
        city: '',
        state: '',
        url1: '',
        url2: '',
      },
    })

  useEffect(() => {
    if (!id) reset()

    if (data?.manufacturer) {
      reset(data.manufacturer)
    }
  }, [data, reset, productBrandId, id])

  const readOnly = !modules.writeSettingsBrands

  return (
    <SettingsDetailDialog
      type={isNewBrand ? 'add' : 'edit'}
      dialogName={name}
      typeLabel={t('settings:brand.item_type')}
      getItemName={() => getValues('name')}
      onClose={onClose}
      isLoading={isLoading}
      formState={formState}
      handleSubmit={handleSubmit}
      onDelete={deleteItem}
      onSave={onSave}
      readOnly={readOnly}
    >
      <TextFieldInput
        name="name"
        control={control}
        label={t('settings:brand.fields.name')}
        required
        disabled={readOnly}
        sx={{ mb: 2 }}
      />
      <TextFieldInput
        name="email"
        control={control}
        label={t('settings:brand.fields.email')}
        sx={{ mb: 2 }}
        disabled={readOnly}
      />
      <TextFieldInput
        name="phone"
        control={control}
        label={t('settings:brand.fields.phone')}
        sx={{ mb: 2 }}
        disabled={readOnly}
      />
      <TextFieldInput
        name="address"
        control={control}
        label={t('settings:brand.fields.address')}
        sx={{ mb: 2 }}
        disabled={readOnly}
      />
      <TextFieldInput
        name="zip"
        control={control}
        label={t('settings:brand.fields.zip')}
        sx={{ mb: 2 }}
        disabled={readOnly}
      />
      <TextFieldInput
        name="city"
        control={control}
        label={t('settings:brand.fields.city')}
        sx={{ mb: 2 }}
        disabled={readOnly}
      />
      <TextFieldInput
        name="state"
        control={control}
        label={t('settings:brand.fields.state')}
        sx={{ mb: 2 }}
        disabled={readOnly}
      />

      <CountriesInput
        control={control}
        name="countryid"
        label={t('shared:label.country')}
        disabled={readOnly}
        emptyValue={null}
        sx={{ mb: 2 }}
      />

      <TextFieldInput
        name="url1"
        control={control}
        label={t('settings:brand.fields.homePage')}
        sx={{ mb: 2 }}
        disabled={readOnly}
      />
      <TextFieldInput
        name="url2"
        control={control}
        label={t('settings:brand.fields.externalLink')}
        sx={{ mb: 2 }}
        disabled={readOnly}
      />
      <TextFieldInput
        name="description"
        control={control}
        label={t('settings:brand.fields.description')}
        sx={{ mb: 2 }}
        disabled={readOnly}
      />
    </SettingsDetailDialog>
  )
}
