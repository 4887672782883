import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Chip,
  List,
  ListItemText,
  Typography,
} from '@mui/material'
import { SectionHeader } from '@sitoo/mui-components'
import { useTranslation } from 'react-i18next'
import { ListItemSkeleton } from '../../../components/list-item-skeleton'
import { GetCashRegisterQuery } from '../../../generated/graphql'
import { useLocalizedDate } from '../../../hooks/localized-date'
import { Indicator } from '../../../components/indicator'
import { useDayJs } from '../../../hooks/day-js'

type Props = {
  isLoading?: boolean
  cashRegister?: GetCashRegisterQuery['cashRegister']
}

export const Details = ({ cashRegister, isLoading }: Props) => {
  const { t } = useTranslation(['shared', 'cash_registers'])
  const { formatRelativeDate } = useLocalizedDate()
  const dayjs = useDayJs()

  return (
    <Accordion defaultExpanded className="MuiAccordionRoot">
      <AccordionSummary aria-controls="detail">
        <SectionHeader sx={{ p: 0 }}>
          {t('cash_registers:view_panel.detail')}
        </SectionHeader>
      </AccordionSummary>
      <AccordionDetails sx={{ p: 0 }}>
        <List>
          <ListItemSkeleton
            isLoading={isLoading}
            childrenSkeleton
            divider
            data-testid="cash-register-last-active"
            secondaryAction={
              <Indicator active={dayjs(cashRegister?.datelastping).isToday()}>
                {cashRegister?.datelastping ? (
                  formatRelativeDate(cashRegister?.datelastping)
                ) : (
                  <Typography color="gray60">
                    {t('cash_registers:last_updated_unavailable')}
                  </Typography>
                )}
              </Indicator>
            }
          >
            <ListItemText>{t('cash_registers:last_updated')}</ListItemText>
          </ListItemSkeleton>

          <ListItemSkeleton
            isLoading={isLoading}
            childrenSkeleton
            divider
            data-testid="cash-register-warehouse-name"
          >
            <ListItemText
              secondary={
                <Chip label={cashRegister?.warehouse?.name} size="small" />
              }
              slotProps={{ secondary: { component: 'div' } }}
            >
              {t('cash_registers:warehouse_name')}
            </ListItemText>
          </ListItemSkeleton>

          <ListItemSkeleton
            isLoading={isLoading}
            childrenSkeleton
            divider
            data-testid="cash-register-log-level"
            secondaryAction={t(
              `cash_registers:log_level.${cashRegister?.loglevel || ''}`,
            )}
          >
            <ListItemText>{t('cash_registers:log_level_label')}</ListItemText>
          </ListItemSkeleton>

          <ListItemSkeleton
            isLoading={isLoading}
            childrenSkeleton
            divider
            data-testid="cash-register-key"
          >
            <ListItemText secondary={cashRegister?.registerkey}>
              {t('cash_registers:register_key')}
            </ListItemText>
          </ListItemSkeleton>

          <ListItemSkeleton
            isLoading={isLoading}
            childrenSkeleton
            divider
            data-testid="cash-register-currency-code"
            secondaryAction={cashRegister?.currencycode}
          >
            <ListItemText>{t('cash_registers:currency_code')}</ListItemText>
          </ListItemSkeleton>

          <ListItemSkeleton
            isLoading={isLoading}
            childrenSkeleton
            divider
            data-testid="cash-register-country-id"
            secondaryAction={cashRegister?.countryid?.toUpperCase()}
          >
            <ListItemText>{t('cash_registers:country_id')}</ListItemText>
          </ListItemSkeleton>

          <ListItemSkeleton
            isLoading={isLoading}
            childrenSkeleton
            divider
            data-testid="cash-register-receipt-language"
            secondaryAction={cashRegister?.receiptlanguagetag}
          >
            <ListItemText>{t('cash_registers:receipt_language')}</ListItemText>
          </ListItemSkeleton>

          <ListItemSkeleton
            isLoading={isLoading}
            childrenSkeleton
            divider
            data-testid="cash-register-sales-tax-group-id"
            secondaryAction={cashRegister?.salestaxgroupid || '-'}
          >
            <ListItemText>
              {t('cash_registers:sales_tax_group_id')}
            </ListItemText>
          </ListItemSkeleton>

          <ListItemSkeleton
            isLoading={isLoading}
            childrenSkeleton
            divider
            data-testid="cash-register-id"
          >
            <ListItemText secondary={cashRegister?.registerid}>
              {t('cash_registers:view_panel.id')}
            </ListItemText>
          </ListItemSkeleton>

          <ListItemSkeleton
            isLoading={isLoading}
            childrenSkeleton
            divider
            data-testid="cash-register-manufacture-id"
          >
            <ListItemText secondary={cashRegister?.manufacturerid}>
              {t('cash_registers:manufacturer_id')}
            </ListItemText>
          </ListItemSkeleton>
        </List>
      </AccordionDetails>
    </Accordion>
  )
}
