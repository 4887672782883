import { useMutation, useQuery } from '@apollo/client'
import { useEffect, useMemo } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import { RootRoute } from '../..'
import { SettingsDetailDialog } from '../../../components/settings-detail-dialog'
import { TextFieldInput } from '../../../inputs/text-field-input'
import {
  AddReasonCodeDocument,
  AllReasonCodesDocument,
  DeleteReasonCodeDocument,
  ReasonCode,
  ReasonCodeDocument,
  ReasonCodeType,
  UpdateReasonCodeDocument,
} from '../../../generated/graphql'
import { useAbsolutePath } from '../../../hooks/absolute-path'
import { SelectInput } from '../../../inputs/select-input'

export const ReasonCodeDialog = () => {
  const { t } = useTranslation(['shared', 'settings'])

  const { id } = useParams()
  const reasonCodeId = Number(id)
  const isNewReasonCode = !reasonCodeId

  const navigate = useNavigate()
  const generatePath = useAbsolutePath()

  const { data, loading: fetchLoading } = useQuery(ReasonCodeDocument, {
    variables: { id: reasonCodeId },
    fetchPolicy: 'cache-and-network',
    skip: isNewReasonCode,
  })

  const { reset, control, getValues, handleSubmit, formState } =
    useForm<ReasonCode>({
      defaultValues: {
        type: ReasonCodeType.CashIn,
        reasoncode: '',
        name: '',
      },
    })

  const reasonCodeType = useMemo(() => {
    return Object.values(ReasonCodeType).map((value) => ({
      value,
      name: t(`settings:reason_codes.reason_code_types.${value}`),
    }))
  }, [t])

  const [addReasonCode, { loading: addLoading }] = useMutation(
    AddReasonCodeDocument,
    { refetchQueries: [AllReasonCodesDocument] },
  )

  const [updateReasonCode, { loading: updateLoading }] = useMutation(
    UpdateReasonCodeDocument,
    { refetchQueries: [AllReasonCodesDocument] },
  )

  const [deleteReasonCode, { loading: deleteLoading }] = useMutation(
    DeleteReasonCodeDocument,
    { refetchQueries: [AllReasonCodesDocument] },
  )

  const isLoading = fetchLoading || addLoading || updateLoading || deleteLoading

  const onSubmit = async (data: ReasonCode) => {
    const { type, reasoncode, name } = data
    const reasonCode = { type, reasoncode, name }

    if (isNewReasonCode) {
      await addReasonCode({ variables: { reasonCode } })
    } else {
      await updateReasonCode({ variables: { reasonCode, id: reasonCodeId } })
    }
  }

  const deleteItem = async () => {
    if (reasonCodeId) {
      await deleteReasonCode({ variables: { id: reasonCodeId } })
    }
  }

  useEffect(() => {
    if (!reasonCodeId) reset()

    if (data?.reasonCode) {
      reset(data.reasonCode)
    }
  }, [data, reset, reasonCodeId])

  const onClose = () => {
    void navigate(generatePath(RootRoute.SettingsReasonCodes), {
      state: { ignoreLeavingGuard: true },
    })
  }

  return (
    <SettingsDetailDialog
      type={isNewReasonCode ? 'add' : 'edit'}
      dialogName="reason-code"
      typeLabel={t('settings:reason_codes.item_type')}
      getItemName={() =>
        getValues('name') || t('settings:reason_codes.item_type')
      }
      onSave={onSubmit}
      onDelete={deleteItem}
      onClose={onClose}
      isLoading={isLoading}
      handleSubmit={handleSubmit}
      formState={formState}
    >
      <SelectInput
        name="type"
        control={control}
        options={reasonCodeType}
        label={t('settings:reason_codes.type')}
        dataTestid="reason-code-type"
        sx={{ mb: 2 }}
      />

      <TextFieldInput
        name="reasoncode"
        control={control}
        label={t('settings:reason_codes.reason_code')}
        slotProps={{ htmlInput: { maxLength: 32 } }}
        placeholder={t('settings:reason_codes.reason_code_placeholder')}
        sx={{ mb: 2 }}
      />

      <TextFieldInput
        name="name"
        control={control}
        label={t('settings:reason_codes.name')}
        slotProps={{ htmlInput: { maxLength: 64 } }}
        placeholder={t('settings:reason_codes.name_placeholder')}
        sx={{ mb: 2 }}
      />
    </SettingsDetailDialog>
  )
}
