import { useFormContext } from 'react-hook-form'
import { GeneralSettingsFormContext } from '../general-settings-form'
import { Trans, useTranslation } from 'react-i18next'
import { PosOrderField, SettingsDocument } from '../../../../generated/graphql'
import { useQuery } from '@apollo/client'
import { DialogInput } from '../../../../inputs/dialog-input'
import { SettingsListSection } from '../../../../components/settings-list-section'

const fields = [
  'required_fields_booking',
  'required_fields_pickup',
  'required_fields_delivery',
] as const

const sortOrder = Object.values(PosOrderField)

export const PosRequiredFields = () => {
  const { control } = useFormContext<GeneralSettingsFormContext>()
  const { t } = useTranslation(['shared', 'settings'])
  const { loading: isLoading } = useQuery(SettingsDocument)

  const formatValue = (value: PosOrderField) => ({
    label: t(`settings:pos_required_field.${value}`),
  })

  const validatePosOrderFields = (
    values: PosOrderField[] | null | undefined,
  ): boolean | string => {
    if (!Array.isArray(values)) return true

    const hasPhoneOrEmail = values.includes(PosOrderField.PhoneOrEmail)
    const hasPhone = values.includes(PosOrderField.Phone)
    const hasEmail = values.includes(PosOrderField.Email)

    return hasPhoneOrEmail && (hasPhone || hasEmail)
      ? t('settings:error.phone_email_conflict')
      : true
  }

  return (
    <SettingsListSection
      title={t(`settings:general.pos_required_fields_title`)}
      description={t(`settings:general.pos_required_fields_description`)}
    >
      {fields.map((field) => {
        return (
          <DialogInput
            key={field}
            control={control}
            itemLabel={t(`settings:general.pos_${field}_label`)}
            addLabel={t('settings:general.pos_required_add_label')}
            formatValue={formatValue}
            options={Object.values(PosOrderField)}
            emptyMessage={t(`settings:general.pos_required_fields_empty_state`)}
            name={field}
            isLoading={isLoading}
            isDraggable={false}
            rules={{ validate: validatePosOrderFields }}
            transform={{
              output: (values) =>
                values.sort(
                  (a, b) => sortOrder.indexOf(a) - sortOrder.indexOf(b),
                ),
            }}
            helperText={
              field === 'required_fields_delivery' && (
                <Trans i18nKey={`settings:general.pos_${field}_helper`} />
              )
            }
          />
        )
      })}
    </SettingsListSection>
  )
}
