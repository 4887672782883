import { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { PageHeader } from '../../../components/page-header'
import { useAuthorization } from '../../../hooks/authorization'
import { usePageTitle } from '../../../hooks/title'
import { ImportList, ImportListItem } from './import-list'
import { ImportPricesDialog } from './import-prices-dialog'
import { ImportPurchasePricesDialog } from './import-purchase-prices-dialog'
import { Paper, Container } from '@mui/material'
import { ImportStoresDialog } from './import-stores'
import { ImportWarehousesDialog } from './import-warehouses'
import { ImportUsersDialog } from './import-users'
import { ImportInfluencerCodesDialog } from './import-influencer-codes'

export const SettingsImportDataPage = () => {
  const { t } = useTranslation(['shared'])
  usePageTitle(t('shared:menu.import_data'))
  const { modules } = useAuthorization()
  const [isPriceDialogOpen, setPriceDialogOpen] = useState(false)
  const [isPurchaseDialogOpen, setPurchaseDialogOpen] = useState(false)
  const [isStoresDialogOpen, setStoresDialogOpen] = useState(false)
  const [isWarehousesDialogOpen, setWarehousesDialogOpen] = useState(false)
  const [isUsersDialogOpen, setUsersDialogOpen] = useState(false)
  const [isInfluencerCodesDialogOpen, setInfluencerCodesDialogOpen] =
    useState(false)

  const importListItems: ImportListItem[] = useMemo(
    () => [
      {
        active: modules.settingsImportPrices,
        title: t('shared:menu.prices'),
        dataTestId: 'import-prices',
        onImport: () => setPriceDialogOpen(true),
      },
      {
        active: modules.settingsImportPurchasePrices,
        title: t('shared:menu.purchase_prices'),
        dataTestId: 'import-purchase-prices',
        onImport: () => setPurchaseDialogOpen(true),
      },
      {
        active: modules.settingsImportStores,
        title: t('shared:menu.stores'),
        dataTestId: 'import-stores',
        onImport: () => setStoresDialogOpen(true),
      },
      {
        active: modules.settingsImportWarehouses,
        title: t('shared:menu.warehouses'),
        dataTestId: 'import-warehouses',
        onImport: () => setWarehousesDialogOpen(true),
      },
      {
        active: modules.settingsImportUsers,
        title: t('shared:menu.users'),
        dataTestId: 'import-users',
        onImport: () => setUsersDialogOpen(true),
      },
      {
        active: modules.settingsImportInfluencerCodes,
        title: t('shared:menu.influencer_codes'),
        dataTestId: 'import-influencer-codes',
        onImport: () => setInfluencerCodesDialogOpen(true),
      },
    ],
    [
      modules.settingsImportInfluencerCodes,
      modules.settingsImportPrices,
      modules.settingsImportPurchasePrices,
      modules.settingsImportStores,
      modules.settingsImportUsers,
      modules.settingsImportWarehouses,
      t,
    ],
  )

  return (
    <>
      <PageHeader title={t('shared:menu.import_data')} />
      <Container>
        <Paper elevation={0}>
          <ImportList items={importListItems} />
        </Paper>
      </Container>
      <ImportPricesDialog
        open={isPriceDialogOpen}
        onClose={() => setPriceDialogOpen(false)}
      />
      <ImportPurchasePricesDialog
        open={isPurchaseDialogOpen}
        onClose={() => setPurchaseDialogOpen(false)}
      />
      <ImportStoresDialog
        open={isStoresDialogOpen}
        onClose={() => setStoresDialogOpen(false)}
      />
      <ImportWarehousesDialog
        open={isWarehousesDialogOpen}
        onClose={() => setWarehousesDialogOpen(false)}
      />
      <ImportUsersDialog
        open={isUsersDialogOpen}
        onClose={() => setUsersDialogOpen(false)}
      />
      <ImportInfluencerCodesDialog
        open={isInfluencerCodesDialogOpen}
        onClose={() => setInfluencerCodesDialogOpen(false)}
      />
    </>
  )
}
