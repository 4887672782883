import { useMutation } from '@apollo/client'
import { enqueueSnackbar } from 'notistack'
import { useContext, useState } from 'react'
import { FormProvider } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import { RootRoute } from '../..'
import {
  AddReportJobDocument,
  LatestReportJobsDocument,
  Report,
  ReportJob,
  ReportJobLangidEnum,
} from '../../../generated/graphql'
import { useAbsolutePath } from '../../../hooks/absolute-path'
import { useMe } from '../../../hooks/me'
import { getErrorMessages } from '../../../utils/error-mapping'
import { stripNullValues } from '../../../utils/strip-null-values'
import { NullToStringRecursive } from '../../../utils/types'
import { CompactDialog } from './compact-dialog'
import { ExtendedDialog } from './extended-dialog'
import { ReportJobDialogContext } from './report-job-dialog-provider'
import { usePopulatedForm } from './use-populated-form'
import { useTracking } from '../../../hooks/tracking'

type AdditionalFields = Partial<
  Pick<ReportJob, 'jobname' | 'reportid'> &
    Pick<
      Report,
      'reportdescription' | 'reportname' | 'datemode' | 'forcewarehousefilter'
    >
>

type ReportAndReportJobOverlap = Omit<
  { [K in keyof Report & keyof ReportJob]: Report[K] | ReportJob[K] },
  '__typename' | 'reportid' | 'datemode' | 'forcewarehousefilter'
>

const dialogName = 'create-report-job-dialog'

export type DialogData = ReportAndReportJobOverlap & AdditionalFields

export type DialogMode = 'extended' | 'compact'

export type Form = NullToStringRecursive<DialogData>

export const CreateReportJobDialog = () => {
  const [addReportJobMutation] = useMutation(AddReportJobDocument, {
    refetchQueries: [LatestReportJobsDocument],
  })
  const { backTo, name, dialogData, defaultDialogMode } = useContext(
    ReportJobDialogContext,
  )
  const navigate = useNavigate()
  const generatePath = useAbsolutePath()
  const { formContext } = usePopulatedForm({ dialogData, name })
  const [dialogMode, setDialogMode] = useState(defaultDialogMode)
  const { me } = useMe()
  const { trackFormSuccess, trackFormError, trackButtonClick } = useTracking()

  const handleClose = () =>
    void navigate({ pathname: backTo }, { replace: true })

  const onSubmit = (formData: Form) => {
    addReportJobMutation({
      variables: {
        reportJob: stripNullValues(
          {
            reportid: formData.reportid,
            reporttype: formData.reporttype,
            reportdefinition: formData.reportdefinition,
            datemode: formData.datemode,
            forcewarehousefilter: formData.forcewarehousefilter,
            langid: ReportJobLangidEnum.En,
            timezone: me?.timeZone ?? null,
            jobname: formData.jobname ?? null,
          },
          {
            deleteEmptyStrings: true,
          },
        ),
      },
    })
      .then((result) => result.data?.addReportJob?.jobid)
      .then((jobId) => {
        void navigate(
          generatePath(RootRoute.ReportJob, {
            id: jobId,
          }),
        )
        trackFormSuccess({
          name: dialogName,
          dateMode: formData.datemode,
          reportType: formData.reporttype,
          systemTemplate:
            formData.reportid && formData.reportid < 0
              ? formData.reportname
              : undefined,
        })
      })
      .catch((error) => {
        const errorMessages = getErrorMessages(error)
        enqueueSnackbar({ message: errorMessages[0], variant: 'error' })
        trackFormError({ name: dialogName })
      })
  }

  const onExpandDialog = () => {
    setDialogMode('extended')
    trackButtonClick({ name: `${dialogName}-expand` })
  }

  if (!dialogData) return null

  return (
    <FormProvider {...formContext}>
      <form>
        {dialogMode === 'extended' ? (
          <ExtendedDialog
            onCreate={formContext.handleSubmit(onSubmit)}
            onClose={handleClose}
          />
        ) : (
          <CompactDialog
            onCreate={formContext.handleSubmit(onSubmit)}
            onClose={handleClose}
            onExpandDialog={onExpandDialog}
          />
        )}
      </form>
    </FormProvider>
  )
}
