import { ListItem, ListItemProps, Skeleton, ListItemText } from '@mui/material'
import { forwardRef } from 'react'

type Props = ListItemProps & {
  isLoading?: boolean
  hide?: boolean
  childrenSkeleton?: boolean
  hasSecondaryText?: boolean
}

export const ListItemSkeleton = forwardRef<HTMLLIElement, Props>(
  function ListItemSkeleton(props, ref) {
    const {
      isLoading,
      hide,
      sx,
      childrenSkeleton,
      hasSecondaryText,
      ...listItemProps
    } = props

    if (hide) return null

    return (
      <ListItem
        ref={ref}
        {...listItemProps}
        secondaryAction={
          isLoading && listItemProps.secondaryAction ? (
            <Skeleton
              width="min(10vw, 60px)"
              variant="rectangular"
              height={32}
            />
          ) : (
            listItemProps.secondaryAction
          )
        }
        sx={{
          // display: hide ? 'none' : 'flex',
          display: 'flex',
          justifyContent:
            childrenSkeleton && props.isLoading
              ? 'space-between'
              : 'flex-start',
          ...sx,
        }}
      >
        {childrenSkeleton && props.isLoading ? (
          <ListItemText
            primary={<Skeleton width="min(10vw, 100px)" />}
            secondary={
              hasSecondaryText ? (
                <Skeleton width="min(10vw, 60px)" />
              ) : undefined
            }
          />
        ) : (
          listItemProps.children
        )}
      </ListItem>
    )
  },
)
