import { SettingsListItem } from '../../../../../../components/settings-list-item'
import { SelectInput, Option } from '../../../../../../inputs/select-input'
import { useQuery } from '@apollo/client'
import { useMemo } from 'react'
import { PosProfileAssociatedDataDocument } from '../../../../../../generated/graphql'
import { useFormContext } from 'react-hook-form'
import { Form } from '../../..'
import { useTranslation } from 'react-i18next'

export const DashboardField = () => {
  const { data: associatedData } = useQuery(PosProfileAssociatedDataDocument)
  const { control } = useFormContext<Form>()
  const { t } = useTranslation(['pos_profiles'])

  const dashboardOptions: Option[] = useMemo(() => {
    const dashboards =
      associatedData?.dashboards
        .map((dashboard) => ({
          name: dashboard.name,
          value: dashboard.id,
        }))
        .sort((a, b) => a.name.localeCompare(b.name)) || []

    return [
      {
        name: t('pos_profiles:pos_options_section.dashboard_default'),
        value: null,
      },
      ...dashboards,
    ]
  }, [associatedData?.dashboards, t])

  return (
    <SettingsListItem>
      <SelectInput
        label={t('pos_profiles:pos_options_section.dashboard_label')}
        helperText={t('pos_profiles:pos_options_section.dashboard_description')}
        name={'dashboardid'}
        control={control}
        options={dashboardOptions}
        slotProps={{ select: { displayEmpty: true } }}
      />
    </SettingsListItem>
  )
}
