import { ListItemProps } from '@mui/material'
import { ListItemSkeleton } from '../list-item-skeleton'
type Props = ListItemProps & { loading?: boolean }

export const SettingsListItem = (props: Props) => {
  const { dense = false, children, loading, ...rest } = props
  return (
    <ListItemSkeleton
      isLoading={loading}
      {...rest}
      sx={{
        ...(dense ? {} : { pt: 0, pb: 2, '&:first-of-type': { pt: 2 } }),
        ...rest.sx,
      }}
      childrenSkeleton={!!children}
      hasSecondaryText
    >
      {children}
    </ListItemSkeleton>
  )
}
