import { useMutation } from '@apollo/client'
import { useSnackbar } from 'notistack'
import { useCallback, useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { usePrevious } from 'react-use'
import { AuthenticateSiteDocument } from '../../generated/graphql'
import { RootRoute } from '../../pages'
import { getErrorMessages } from '../../utils/error-mapping'
import { extractGraphqlErrors } from '../../utils/extract-graphql-errors'
import { useMe } from '../me'

export const useSiteSelector = () => {
  const navigate = useNavigate()
  const { enqueueSnackbar } = useSnackbar()

  const params = useParams<{ siteId: string }>()
  const siteId = Number(params.siteId)
  const prevSiteId = usePrevious(siteId)

  const { me } = useMe()
  const [authenticateSite, { loading: siteLoading }] = useMutation(
    AuthenticateSiteDocument,
    { refetchQueries: 'active' },
  )

  const switchSite = useCallback(
    async (siteId: number) => {
      return authenticateSite({ variables: { siteId } }).catch((error) => {
        const errorMessage = getErrorMessages(error)[0]

        enqueueSnackbar(errorMessage, { variant: 'error' })

        const allErrors = extractGraphqlErrors(error)

        const isInvalidSite = allErrors.some(
          (x) => x.extensions?.['code'] === 'INVALID_SITE_ID',
        )

        void navigate(
          { pathname: RootRoute.Logout },
          { state: { clean: isInvalidSite } },
        )
      })
    },
    [authenticateSite, enqueueSnackbar, navigate],
  )

  useEffect(() => {
    if (siteLoading) return

    if (siteId !== prevSiteId && siteId !== me?.siteId) {
      void switchSite(siteId)
    }
  }, [me?.siteId, prevSiteId, siteId, siteLoading, switchSite])

  return { switchSite }
}
