import { useTranslation } from 'react-i18next'
import { DialogInput } from '../../../../inputs/dialog-input'
import { SettingsListSection } from '../../../../components/settings-list-section'
import { useFormContext } from 'react-hook-form'
import { GeneralSettingsFormContext } from '../general-settings-form'
import { useQuery } from '@apollo/client'
import {
  AllCustomAttributesDocument,
  CustomAttributeFragmentFragment,
} from '../../../../generated/graphql'
import { SettingsKeySpecifier } from '../../../../generated/apollo-helpers'
import { useCallback, useMemo } from 'react'

const inputNames = [
  'customattributes_list',
  'customattributes_info',
  'customattributes_details',
  'customattributes_receipt',
] as const satisfies SettingsKeySpecifier

type CustomAttribute = {
  id: string
  show_title: boolean
}

const formatAdditionalName = (id: string): string => {
  let name = id
  name = name.replace(/[^\p{L}0-9]+/gu, '-')
  name = name.replace(/-[-]+/g, '-')

  return name.toLowerCase().replace(/^-+|-+$/g, '')
}

export const PosProductAttributes = () => {
  const { t } = useTranslation(['shared', 'settings'])
  const { control, getValues } = useFormContext<GeneralSettingsFormContext>()
  const { data, loading } = useQuery(AllCustomAttributesDocument)

  const toId = ({ id, show_title }: CustomAttribute) =>
    JSON.stringify({ id, show_title })

  const fromId = (id: string) => JSON.parse(id) as CustomAttribute

  const getOptions = useCallback(
    (attributes: Array<{ id: string }> | null | undefined) =>
      attributes?.flatMap(({ id }) => [
        toId({ id, show_title: true }),
        toId({ id, show_title: false }),
      ]) || [],
    [],
  )

  const options = useMemo(() => {
    const customAttributes = [...(data?.allCustomAttributes || [])].sort(
      (a, b) => a.title.localeCompare(b.title),
    )

    return getOptions(customAttributes)
  }, [data?.allCustomAttributes, getOptions])

  const formatValue = (formattedId: string): { label: string } => {
    const { id, show_title } = fromId(formattedId)
    const attribute = data?.allCustomAttributes.find(
      (attribute) => attribute.id === id,
    )
    const title = attribute?.title || ''

    return {
      label: show_title
        ? t('settings:general.pos_product_attributes.attribute_label', {
            property: title,
          })
        : title,
    }
  }

  const getCustomAttribute =
    (name: (typeof inputNames)[number]) =>
    (ids: string[]): CustomAttributeFragmentFragment[] => {
      const additionalValues = getValues(name)
      return ids.map((formattedId) => {
        const { id, show_title } = fromId(formattedId)
        const data = additionalValues?.find((v) => v.id === id)
        let additionaldata_name = undefined

        if (name === 'customattributes_receipt') {
          additionaldata_name =
            data?.additionaldata_name ?? formatAdditionalName(id)
        }

        return {
          ...data,
          additionaldata_name,
          id,
          show_title,
        }
      })
    }

  return (
    <SettingsListSection
      title={t('settings:general.pos_product_attributes.title')}
    >
      {inputNames.map((name) => (
        <DialogInput
          key={name}
          name={name}
          control={control}
          isLoading={loading}
          formatValue={formatValue}
          itemLabel={t(`settings:general.pos_product_attributes.${name}_title`)}
          helperText={t(
            `settings:general.pos_product_attributes.${name}_helper`,
          )}
          addLabel={t('settings:general.pos_product_attributes.add_label')}
          options={options}
          emptyMessage={t(
            'settings:general.pos_product_attributes.empty_message',
          )}
          rules={{
            validate: (values) => {
              if (name === 'customattributes_receipt' && values.length > 3) {
                return t(
                  'settings:general.pos_product_attributes.error_max_attributes',
                )
              }
              return true
            },
          }}
          transform={{
            input: (values) => values?.map(toId) || [],
            output: getCustomAttribute(name),
          }}
        />
      ))}
    </SettingsListSection>
  )
}
