import { useFormContext } from 'react-hook-form'
import { SettingsListSection } from '../../../../../components/settings-list-section'
import { DialogInput } from '../../../../../inputs/dialog-input'
import { Form } from '../..'
import { useTranslation } from 'react-i18next'
import { useQuery } from '@apollo/client'
import { AllPaymentTypesDocument } from '../../../../../generated/graphql'
import { useMemo } from 'react'
import { getPaymentTypesFromIds } from '../../../payment-options/utils'

export const OverridePaymentTypes = () => {
  const { control } = useFormContext<Form>()
  const { t } = useTranslation(['pos_profiles', 'payment_options'])
  const { data: paymentTypesData, loading: isLoading } = useQuery(
    AllPaymentTypesDocument,
  )

  const options = useMemo(() => {
    return [...(paymentTypesData?.allPaymentTypes ?? [])]
      .sort((a, b) => a.name.localeCompare(b.name))
      .map((p) => Number(p.paymenttypeid))
  }, [paymentTypesData])

  return (
    <SettingsListSection
      title={t('pos_profiles:override_payment_types_section.title')}
    >
      <DialogInput
        name="override_paymenttypes"
        control={control}
        itemLabel={t(
          'pos_profiles:override_payment_types_section.override_paymenttypes_label',
        )}
        formatValue={(value) => {
          const option = getPaymentTypesFromIds(
            [value],
            paymentTypesData?.allPaymentTypes,
          )[0]

          return {
            label: option?.name || String(option?.paymenttypeid),
            description: t(
              `payment_options:payment_method.${option?.paymentmethodid}`,
            ),
          }
        }}
        emptyMessage={t(
          'pos_profiles:override_payment_types_section.override_paymenttypes_empty_state',
        )}
        helperText={t(
          'pos_profiles:override_payment_types_section.override_paymenttypes_description',
        )}
        options={options}
        isLoading={isLoading}
      />
    </SettingsListSection>
  )
}
