import { Chip } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { ReportJobState } from '../../generated/graphql'
import { useLocalizedDate } from '../../hooks/localized-date'
import { ReportJobStateChip } from '../reports/report-job-state-chip'

type Props = {
  dateExecuted?: string | null
  jobState?: ReportJobState
}

export const HeaderChips = ({ dateExecuted, jobState }: Props) => {
  const { t } = useTranslation(['reports'])
  const { formatRelativeDate } = useLocalizedDate()

  return (
    <>
      {dateExecuted && (
        <Chip
          label={t('reports:generated_at', {
            timestamp: formatRelativeDate(dateExecuted),
          })}
          color="gray"
          size="small"
        />
      )}

      {jobState && <ReportJobStateChip jobState={jobState} />}
    </>
  )
}
