import {
  Checkbox,
  CheckboxProps,
  FormControl,
  FormControlLabel,
  FormControlLabelProps,
  FormHelperText,
  SxProps,
  Theme,
} from '@mui/material'
import { FieldValues, useController, UseControllerProps } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

type Props = CheckboxProps & {
  label?: string
  slotProps?: FormControlLabelProps['slotProps']
  sx?: SxProps<Theme>
}

export const CheckboxInput = <TFieldValues extends FieldValues>(
  props: Props & UseControllerProps<TFieldValues>,
) => {
  const { t } = useTranslation('shared')

  const {
    control,
    name,
    disabled,
    label = '',
    sx,
    slotProps,
    rules,
    required,
    ...checkboxProps
  } = props

  const isRequired = rules?.required || required

  const validationRules: UseControllerProps<TFieldValues>['rules'] = {
    required: required
      ? t('shared:validation.field_required', { field: label ?? name })
      : undefined,
    ...rules,
  }

  const { field, formState, fieldState } = useController({
    control,
    name,
    disabled,
    rules: validationRules,
  })

  const { error } = fieldState

  const isDisabled = disabled || field.disabled || formState.isSubmitting

  return (
    <FormControl variant="standard" sx={sx}>
      <FormControlLabel
        label={label}
        slotProps={slotProps}
        control={
          <Checkbox
            {...checkboxProps}
            {...field}
            required={!!isRequired}
            onChange={(event, value) => {
              props.onChange?.(event, value)
              field.onChange(event, value)
            }}
            checked={!!field.value}
            disabled={isDisabled}
            data-testid="checkbox-input"
          />
        }
      />
      {error && <FormHelperText error>{error?.message}</FormHelperText>}
    </FormControl>
  )
}
