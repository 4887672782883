import { useFormContext } from 'react-hook-form'
import { BaseUserContext } from '../shared'
import { useTranslation } from 'react-i18next'
import { FormFieldset } from '@sitoo/mui-components'
import { TextField } from '@mui/material'
import { CountriesInput } from '../../../inputs/countries-input'
import { UserPermissionsByIdQuery } from '../../../generated/graphql'

type Props = {
  permissions: UserPermissionsByIdQuery['userPermissionsById'] | undefined
}

export const ContactField = (props: Props) => {
  const { permissions } = props
  const { register, formState, control } = useFormContext<BaseUserContext>()
  const { t } = useTranslation(['shared', 'users', 'countries'])

  return (
    <FormFieldset label={t('users:user_form.contact_fieldset')}>
      <TextField
        error={!!formState?.errors.namefirst}
        helperText={formState?.errors.namefirst?.message}
        label={t('users:first_name')}
        data-testid="user-first-name-input"
        {...register('namefirst')}
        disabled={!permissions?.editUser}
        sx={{ mb: 2 }}
      />

      <TextField
        error={!!formState?.errors.namelast}
        helperText={formState?.errors.namelast?.message}
        label={t('users:last_name')}
        data-testid="user-last-name-input"
        {...register('namelast')}
        disabled={!permissions?.editUser}
        sx={{ mb: 2 }}
      />

      <TextField
        error={!!formState?.errors.personalid}
        helperText={formState?.errors.personalid?.message}
        label={t('users:personal_id')}
        data-testid="user-personal-id-input"
        {...register('personalid')}
        disabled={!permissions?.editUser}
        sx={{ mb: 2 }}
      />

      <TextField
        error={!!formState?.errors.company}
        helperText={formState?.errors.company?.message}
        label={t('users:company')}
        data-testid="user-company-input"
        {...register('company')}
        disabled={!permissions?.editUser}
        sx={{ mb: 2 }}
      />

      <TextField
        error={!!formState?.errors.companyid}
        helperText={formState?.errors.companyid?.message}
        label={t('users:company_id')}
        data-testid="user-company-id-input"
        {...register('companyid')}
        disabled={!permissions?.editUser}
        sx={{ mb: 2 }}
      />

      <TextField
        error={!!formState?.errors.department}
        helperText={formState?.errors.department?.message}
        label={t('users:department')}
        data-testid="user-department-input"
        {...register('department')}
        disabled={!permissions?.editUser}
        sx={{ mb: 2 }}
      />

      <TextField
        error={!!formState?.errors.mobile}
        helperText={formState?.errors.mobile?.message}
        label={t('users:mobile')}
        data-testid="user-mobile-input"
        {...register('mobile')}
        disabled={!permissions?.editUser}
        sx={{ mb: 2 }}
      />

      <TextField
        error={!!formState?.errors.phone}
        helperText={formState?.errors.phone?.message}
        label={t('users:phone')}
        data-testid="user-phone-input"
        {...register('phone')}
        disabled={!permissions?.editUser}
        sx={{ mb: 2 }}
      />

      <TextField
        error={!!formState?.errors.address}
        helperText={formState?.errors.address?.message}
        label={t('users:address')}
        data-testid="user-address-input"
        {...register('address')}
        disabled={!permissions?.editUser}
        sx={{ mb: 2 }}
      />

      <TextField
        error={!!formState?.errors.address2}
        helperText={formState?.errors.address2?.message}
        label={t('users:address2')}
        data-testid="user-address2-input"
        {...register('address2')}
        disabled={!permissions?.editUser}
        sx={{ mb: 2 }}
      />

      <TextField
        error={!!formState?.errors.zip}
        helperText={formState?.errors.zip?.message}
        label={t('users:postal_code')}
        data-testid="user-zip-input"
        {...register('zip')}
        disabled={!permissions?.editUser}
        sx={{ mb: 2 }}
      />

      <TextField
        error={!!formState?.errors.city}
        helperText={formState?.errors.city?.message}
        label={t('users:city')}
        data-testid="user-city-input"
        {...register('city')}
        disabled={!permissions?.editUser}
        sx={{ mb: 2 }}
      />

      <TextField
        error={!!formState?.errors.state}
        helperText={formState?.errors.state?.message}
        label={t('users:state')}
        data-testid="user-state-input"
        {...register('state')}
        disabled={!permissions?.editUser}
        sx={{ mb: 2 }}
      />

      <CountriesInput
        name="countryid"
        control={control}
        label={t('shared:label.country')}
        sx={{ mb: 2 }}
        disabled={!permissions?.editUser}
      />

      <TextField
        error={!!formState?.errors.notes}
        multiline
        helperText={formState?.errors.notes?.message}
        label={t('users:note')}
        data-testid="user-note-input"
        {...register('notes')}
        disabled={!permissions?.editUser}
        sx={{ mb: 2 }}
        rows={4}
      />
    </FormFieldset>
  )
}
