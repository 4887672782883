import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { DataGridFilterValue } from '../types'
import {
  Filter,
  FilterContext,
  FilterEventEmitter,
  ResetFilterFunction,
} from '../context'

type FilterContextProps = {
  hasApply?: boolean
}

export const useFilterContext = <T = Record<string, unknown>,>(
  props?: FilterContextProps,
): FilterContext<T> => {
  const [filter, setFilter] = useState({} as FilterContext<T>['filter'])
  const preApplyFilter = useRef({} as FilterContext<T>['filter'])
  const [registeredFilters, setRegisteredFilters] = useState<Filter<T>[]>([])
  const registeredFiltersRef = useRef<Filter<T>[]>([])
  const [initialized, setInitialized] = useState(false)

  const resetFilterEventEmitter = useRef<
    FilterEventEmitter<ResetFilterFunction<T>>
  >({
    events: [],
    subscribe: (callback) => {
      resetFilterEventEmitter.current.events.push(callback)

      // unsubscribe
      return () => {
        resetFilterEventEmitter.current.events =
          resetFilterEventEmitter.current.events.filter((c) => c !== callback)
      }
    },
    dispatch: async (key) => {
      for (const event of resetFilterEventEmitter.current.events) {
        await event(key)
      }
    },
  })

  const externalSetFilter = useCallback(
    (key: keyof T, value: DataGridFilterValue<T[keyof T]>) => {
      if (!registeredFiltersRef.current.some((x) => x.key === key)) return

      if (props?.hasApply) {
        preApplyFilter.current = {
          ...preApplyFilter.current,
          [key]: value,
        }
      } else {
        setFilter((p) => {
          if (p[key] === value) return p

          if (
            value.value !== undefined &&
            JSON.stringify(p[key]?.value) === JSON.stringify(value.value)
          ) {
            return p
          }

          return { ...p, [key]: value } as FilterContext<T>['filter']
        })
      }
    },
    [props?.hasApply],
  )

  const removeFilter = useCallback(
    (key: keyof T) => {
      if (!registeredFiltersRef.current.some((x) => x.key === key)) return

      if (props?.hasApply) {
        delete preApplyFilter.current[key]

        preApplyFilter.current = { ...preApplyFilter.current }
      } else {
        setFilter((filter) => {
          if (filter && filter[key]) {
            const newFilter = { ...filter }
            delete newFilter[key]

            return Object.keys(newFilter).length === 0
              ? ({} as FilterContext<T>['filter'])
              : newFilter
          }

          return filter
        })
      }
    },
    [props?.hasApply],
  )

  const applyFilter = useCallback(() => {
    if (!props?.hasApply) return

    setFilter(preApplyFilter.current)
  }, [props?.hasApply])

  const resetFilter = useCallback<ResetFilterFunction<T>>(
    async (key) => {
      await resetFilterEventEmitter.current.dispatch(key)

      if (props?.hasApply) applyFilter()
    },
    [applyFilter, props?.hasApply],
  )

  const refetch = useCallback(() => {
    setFilter((filter) => ({ ...filter }))
  }, [])

  const registerFilter = useCallback<FilterContext<T>['registerFilter']>(
    (filters) => {
      const newFilters = Array.isArray(filters) ? filters : [filters]

      for (const newFilter of newFilters) {
        const filterIndex = registeredFiltersRef.current.findIndex(
          (x) => x.key === newFilter.key,
        )

        if (filterIndex === -1) {
          registeredFiltersRef.current.push(newFilter)
        } else {
          registeredFiltersRef.current[filterIndex] = newFilter
        }
      }

      setRegisteredFilters(Array.from(registeredFiltersRef.current))
    },
    [],
  )

  const isFilterReady = useMemo(() => {
    return (
      registeredFilters.length > 0 &&
      registeredFilters.every(
        (x) => x.isReady === undefined || x.isReady === true,
      )
    )
  }, [registeredFilters])

  useEffect(() => {
    if (props?.hasApply && isFilterReady) {
      void applyFilter()
    }
    setInitialized(isFilterReady)
  }, [applyFilter, isFilterReady, props?.hasApply])

  return {
    filter,
    hasApply: props?.hasApply || false,
    isFilterReady: initialized,
    registerFilter,
    resetFilter,
    setFilter: externalSetFilter,
    applyFilter,
    subscribeOnResetFilter: resetFilterEventEmitter.current.subscribe,
    removeFilter,
    refetch,
  }
}
