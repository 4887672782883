import { createContext, PropsWithChildren } from 'react'
import { DialogData, DialogMode } from '.'

type ReportJobContextProps = {
  backTo?: string
  dialogData?: DialogData
  name?: string
  defaultDialogMode?: DialogMode
}

export const ReportJobDialogContext = createContext<ReportJobContextProps>({})

export const ReportJobDialogProvider = ({
  children,
  backTo,
  dialogData,
  name,
  defaultDialogMode,
}: PropsWithChildren & ReportJobContextProps) => {
  return (
    <ReportJobDialogContext.Provider
      value={{
        backTo,
        dialogData,
        name,
        defaultDialogMode,
      }}
    >
      {children}
    </ReportJobDialogContext.Provider>
  )
}
