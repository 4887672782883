import { useMutation, useQuery } from '@apollo/client'
import { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { generatePath, useNavigate, useParams } from 'react-router-dom'
import { RootRoute } from '../../../..'
import { SettingsDetailDialog } from '../../../../../components/settings-detail-dialog'
import {
  AddUpdateProductGroupDocument,
  DeleteProductGroupDocument,
  GetProductGroupDocument,
  GetProductGroupsDocument,
  ProductGroup,
  ProductGroupType,
  SingleProductGroupInput,
} from '../../../../../generated/graphql'
import { useMe } from '../../../../../hooks/me'
import { ProductGroupTypeInput } from '../../../../../inputs/product-group-type-input'
import { TextFieldInput } from '../../../../../inputs/text-field-input'

type Form = Omit<ProductGroup, 'value'> & {
  value: string
}

export const ProductGroupDialog = () => {
  const { t } = useTranslation(['shared', 'settings'])
  const { id } = useParams()
  const { me } = useMe()
  const navigate = useNavigate()

  const productGroupId = id ? parseInt(id) : undefined
  const isNewProductGroup = !productGroupId

  const { reset, control, handleSubmit, formState, getValues } = useForm<Form>({
    defaultValues: {
      comment: '',
      value: '',
      type: ProductGroupType.Product,
      name: '',
    },
  })

  const [addUpdateProductGroup, { loading: addUpdateLoading }] = useMutation(
    AddUpdateProductGroupDocument,
    { refetchQueries: [GetProductGroupsDocument] },
  )

  const [deleteProductGroup, { loading: deleteLoading }] = useMutation(
    DeleteProductGroupDocument,
    { refetchQueries: [GetProductGroupsDocument] },
  )

  const { data, loading: fetchLoading } = useQuery(GetProductGroupDocument, {
    variables: { id: productGroupId! },
    fetchPolicy: 'cache-and-network',
    skip: isNewProductGroup,
  })

  const isLoading = fetchLoading || addUpdateLoading || deleteLoading

  const onSubmit = async (data: Form) => {
    const productGroup: SingleProductGroupInput = {
      comment: data.comment,
      value: Number(data.value ?? ''),
      productgroupname: data.name,
      productgrouptype: data.type,
    }
    if (!isNewProductGroup) {
      productGroup.vatid = Number(data['id'])
    }
    await addUpdateProductGroup({
      variables: {
        productGroup,
      },
    })
  }

  const deleteItem = async () => {
    if (!isNewProductGroup) {
      await deleteProductGroup({
        variables: {
          id: productGroupId,
        },
      })
    }
  }

  useEffect(() => {
    if (!productGroupId) reset()

    if (data?.productGroup) {
      reset({
        ...data.productGroup,
        value: String(data.productGroup.value),
      })
    }
  }, [data, reset, productGroupId])

  const onClose = () => {
    void navigate(
      generatePath(RootRoute.SettingsProductsGroups, {
        siteId: me?.siteId,
      }),
      {
        state: { ignoreLeavingGuard: true },
      },
    )
  }

  return (
    <SettingsDetailDialog
      type={isNewProductGroup ? 'add' : 'edit'}
      dialogName="product-group"
      typeLabel={t('settings:product_groups.item_type')}
      getItemName={() => getValues('name')}
      onSave={onSubmit}
      onClose={onClose}
      isLoading={isLoading}
      formState={formState}
      handleSubmit={handleSubmit}
      onDelete={deleteItem}
    >
      <TextFieldInput
        name="name"
        control={control}
        label={t('settings:product_groups.name')}
        required
        sx={{ mb: 2 }}
      />

      <TextFieldInput
        name="comment"
        control={control}
        label={t('settings:product_groups.internal_comment')}
        sx={{ mb: 2 }}
      />

      <ProductGroupTypeInput
        name="type"
        control={control}
        label={t('settings:product_groups.type')}
        sx={{ mb: 2 }}
        helperText={t('settings:product_groups.type_description')}
      />

      {/* TODO: Use number valdation here */}
      <TextFieldInput
        name="value"
        control={control}
        label={t('settings:product_groups.vat')}
        required
        sx={{ mb: 2 }}
        helperText={t('settings:product_groups.vat_description')}
      />
    </SettingsDetailDialog>
  )
}
