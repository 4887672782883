import { Box, capitalize } from '@mui/material'
import { GridColDef, GridPinnedRowsProp } from '@mui/x-data-grid-pro'
import { useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { PAGE_SIZE } from '..'
import { DataGrid } from '../../../components/data-grid'
import { CursorPagination } from '../../../components/data-grid/cursor-pagination'
import {
  PaginatedReportResultsItemsResponse,
  ReportJobsQuery,
  ReportJobState,
  ReportResultColumnsInner,
  ReportResultItemValueDisplayType,
  ReportResultsQuery,
} from '../../../generated/graphql'
import { ArrayElement } from '../../../utils/types'
import { StatusOverlay } from '../status-overlay'
import { useFormatReportResultValue } from './use-format-report-result-value'
import { useCalculateGridHeight } from '../calculate-grid-height'

export const FOOTER_ROW_ID = -1

type Props = {
  reportResults?: ReportResultsQuery['reportResults']
  reportResultItems?: PaginatedReportResultsItemsResponse
  reportJob?: ArrayElement<ReportJobsQuery['reportJobs']['items']>
  fetchMoreResultItems: () => Promise<unknown> | undefined
  isLoading?: boolean
  onDelete: () => void
}
type Row = Record<string, unknown> & { id?: number }

export const ReportResultTable = (props: Props) => {
  const { t } = useTranslation(['reports'])
  const formatReportResultValue = useFormatReportResultValue()
  const { gridHeight, placeholderHeight } = useCalculateGridHeight()

  const columnMapper = useCallback(
    (column: ReportResultColumnsInner): GridColDef<Row> => {
      if (!column.key) return { field: '', flex: 1, headerName: '' }

      const isNumeric = [
        ReportResultItemValueDisplayType.Money,
        ReportResultItemValueDisplayType.MoneyFixed,
        ReportResultItemValueDisplayType.DecimalQuantity,
        ReportResultItemValueDisplayType.Double,
        ReportResultItemValueDisplayType.DoublePercentage,
        ReportResultItemValueDisplayType.Integer,
      ].some((item) => item === column.type)

      const columnAlignment = isNumeric ? 'right' : 'left'

      return {
        field: column.key,
        flex: 1,
        disableColumnMenu: true,
        headerAlign: columnAlignment,
        align: columnAlignment,
        cellClassName: isNumeric ? 'NumericValue' : '',
        headerName: t(`reports:report_keys.${capitalize(column.key)}`),
        minWidth: 150,
        valueGetter: (value, row) => {
          if (row.id === FOOTER_ROW_ID && !value) return ''
          return formatReportResultValue(value, row.id, column.type)
        },
      }
    },
    [formatReportResultValue, t],
  )

  const dataGridColumns: GridColDef<Row>[] = useMemo(() => {
    if (!props.reportResults?.columns) return []

    const columns =
      props.reportResults?.columns?.map((column) => columnMapper(column)) || []

    if (columns.length > 0) {
      columns.unshift({
        field: 'id',
        headerName: '#',
        disableColumnMenu: true,
        flex: 0.5,
        valueGetter: (value) => {
          if (value === -1) return ''
          return typeof value === 'number' ? value + 1 : value
        },
      })
    }

    return columns
  }, [columnMapper, props.reportResults?.columns])

  const finishedWithoutResults = useMemo(
    () =>
      props.reportJob?.jobstate === ReportJobState.Finished &&
      props.reportResults?.totals &&
      !Object.values(props.reportResults.totals || {}).some((value) => !!value),

    [props.reportJob?.jobstate, props.reportResults?.totals],
  )

  const pinnedRows: GridPinnedRowsProp = useMemo(
    () => ({
      bottom: [{ ...props.reportResults?.totals, id: -1 }],
    }),
    [props.reportResults?.totals],
  )

  const rows = useMemo(() => {
    return (
      props.reportResultItems?.items?.map((row, index) => ({
        ...row,
        id: index,
      })) || []
    )
  }, [props.reportResultItems?.items])

  return (
    <Box>
      <StatusOverlay
        sx={{ height: `calc(100vh - ${placeholderHeight}px)` }}
        jobState={props.reportJob?.jobstate}
        onDelete={props.onDelete}
        noResults={finishedWithoutResults}
      />

      {props.reportJob?.jobstate === ReportJobState.Finished &&
        !finishedWithoutResults && (
          <DataGrid
            name="report-table"
            rows={rows}
            pinnedRows={pinnedRows}
            columns={dataGridColumns}
            loading={props.isLoading}
            sx={{
              '.MuiDataGrid-row--lastVisible': {
                fontWeight: 'bold',
              },
              mb: 1,
              height: `calc(100vh - ${gridHeight}px)`,
            }}
            disableAllFilters
            rowCount={props.reportResultItems?.totalcount ?? 0}
            slots={{ pagination: CursorPagination }}
            paginationModel={{ page: 0, pageSize: PAGE_SIZE }}
            fetchMore={props.fetchMoreResultItems}
            showMore={
              Number(props.reportResultItems?.items?.length) <
              Number(props.reportResultItems?.totalcount)
            }
          />
        )}
    </Box>
  )
}
