import { useFormContext } from 'react-hook-form'
import { Form } from '..'
import { useTranslation } from 'react-i18next'
import { TextFieldInput } from '../../../../inputs/text-field-input'
import { ListItem } from '@mui/material'

export const CampaignCodeInput = () => {
  const { control } = useFormContext<Form>()
  const { t } = useTranslation('reports')
  return (
    <ListItem>
      <TextFieldInput
        control={control}
        label={t('create_dialog.campaign_code')}
        name="reportdefinition.filters.orderVoucherCode"
      />
    </ListItem>
  )
}
