import { DateRangePickerInput } from '../report-inputs/date-range-picker-input'
import { ManufacturerInput } from '../report-inputs/manufacturer-input'
import { ProductGroupInput } from '../report-inputs/product-group-input'
import { ReportSortInput } from '../report-inputs/report-sort-input'
import { SalesTaxRegionInput } from '../report-inputs/sales-tax-region-input'
import { SalesTaxTransactionTypeInput } from '../report-inputs/sales-tax-transaction-type-input'

export const SalesTaxReportFields = () => {
  return (
    <>
      <ReportSortInput />
      <DateRangePickerInput
        dateStartKey="salesTaxDateStart"
        dateEndKey="salesTaxDateEnd"
      />
      <ManufacturerInput />
      <ProductGroupInput />
      <SalesTaxTransactionTypeInput />
      <SalesTaxRegionInput />
    </>
  )
}
