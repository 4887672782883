import { makeVar, useQuery, useReactiveVar } from '@apollo/client'
import { Button, Container, Grid, Stack } from '@mui/material'
import { GridColDef, GridRowParams, useGridApiRef } from '@mui/x-data-grid-pro'
import { useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { PageHeader } from '../../../components/page-header'
import {
  SalesTaxesDocument,
  SalesTaxesQuery,
  SalesTaxesQueryVariables,
} from '../../../generated/graphql'
import { useAbsolutePath } from '../../../hooks/absolute-path'
import { usePageTitle } from '../../../hooks/title'
import { useTracking } from '../../../hooks/tracking'

import { ErrorIcon, SalesTaxIcon } from '@sitoo/mui-components'
import { RootRoute } from '../..'
import { DataGrid } from '../../../components/data-grid'
import { CursorPagination } from '../../../components/data-grid/cursor-pagination'
import { ArrayElement } from '../../../utils/types'
import { containsInvalidRule } from '../sales-tax/shared'
import { SalesTaxRulesRenderer } from './sales-tax-rules-renderer'
import { SalesTaxViewPanel } from './sales-tax-view-panel'
import { useSalesTaxFormatter } from './useSalesTaxFormatter'

type Row = ArrayElement<SalesTaxesQuery['salesTaxes']['items']>

type ViewPanelState = {
  isOpen: boolean
  salesTaxId?: number
}

const PAGE_SIZE = 100

export const salesTaxViewPanelVar = makeVar<ViewPanelState>({
  isOpen: false,
  salesTaxId: undefined,
})

export const SettingsSalesTaxesPage = () => {
  const name = 'salesTaxes'
  const { t } = useTranslation(['shared', 'settings'])
  usePageTitle(t('shared:menu.sales_taxes'))
  const apiRef = useGridApiRef()
  const { trackButtonClick } = useTracking()
  const viewPanelState = useReactiveVar(salesTaxViewPanelVar)
  const navigate = useNavigate()
  const generatePath = useAbsolutePath()

  const { formatTaxValue } = useSalesTaxFormatter()

  const queryVariables = useMemo(() => {
    const config = {
      pagination: {
        page: 0,
        start: 0,
        pageSize: PAGE_SIZE,
      },
    }
    const variables: SalesTaxesQueryVariables = {
      num: config.pagination?.pageSize,
      start:
        (config.pagination?.page || 0) * (config.pagination?.pageSize || 0),
    }

    return variables
  }, [])

  const {
    loading: isLoadingSalesTaxes,
    data,
    fetchMore,
  } = useQuery(SalesTaxesDocument, {
    fetchPolicy: 'cache-and-network',
    variables: queryVariables,
  })

  const onAdd = () => {
    trackButtonClick({ name: `sales-tax-add-sales-tax` })
    void navigate(generatePath(RootRoute.SettingsSalesTaxNew))
  }
  const isLoading = isLoadingSalesTaxes

  const columns = useMemo<GridColDef<Row>[]>(
    () => [
      {
        field: 'name',
        minWidth: 240,
        headerName: t('settings:sales_tax.name'),
        renderCell: (params) => {
          return containsInvalidRule(params.row) ? (
            <Stack direction="row" alignItems="center">
              <ErrorIcon sx={{ mr: 0.5 }} />
              {params.row.name}
            </Stack>
          ) : (
            <>{params.row.name}</>
          )
        },
      },
      {
        field: 'code',
        minWidth: 240,
        headerName: t('settings:sales_tax.code'),
      },
      {
        field: 'decimalvaluedefault',
        minWidth: 80,
        headerName: t('settings:sales_tax.tax'),
        valueFormatter: (_value, row) =>
          formatTaxValue(row.decimalvaluedefault),
      },
      {
        field: 'productgrouprules',
        minWidth: 320,
        headerName: t('settings:sales_tax.rules'),
        renderCell: SalesTaxRulesRenderer,
      },
    ],
    [formatTaxValue, t],
  )

  const openViewPanel = (salesTaxId: number) => {
    trackButtonClick({ name: 'sales-tax-view-panel-open', salesTaxId })
    salesTaxViewPanelVar({ isOpen: true, salesTaxId })
  }

  const closeViewPanel = () => {
    trackButtonClick({ name: 'sales-tax-view-panel-close' })
    salesTaxViewPanelVar({ isOpen: false, salesTaxId: undefined })
  }

  const fetchMoreItems = useCallback(() => {
    const { pageSize } = apiRef.current.state.pagination.paginationModel

    if (data?.salesTaxes.totalcount) {
      return fetchMore({
        variables: {
          start: (data.salesTaxes?.start || 0) + pageSize,
        },
      })
    }
  }, [apiRef, data, fetchMore])

  return (
    <Grid container flexWrap="nowrap">
      <Grid size="grow">
        <PageHeader
          title={t('shared:menu.sales_taxes')}
          isFlexible
          rightColumn={
            <Button data-testid="add-sales-tax-button" onClick={onAdd}>
              {t('settings:sales_tax.add_sales_tax')}
            </Button>
          }
        />
        <Container maxWidth={false}>
          <DataGrid
            name={name}
            apiRef={apiRef}
            rows={data?.salesTaxes.items}
            rowCount={data?.salesTaxes.totalcount || 0}
            columns={columns}
            loading={isLoading}
            onRowClick={({ row }: GridRowParams<Row>) => openViewPanel(row.id)}
            paginationModel={{ page: 0, pageSize: PAGE_SIZE }}
            fetchMore={fetchMoreItems}
            showMore={
              !isLoading &&
              Number(data?.salesTaxes.items?.length) <
                Number(data?.salesTaxes.totalcount)
            }
            noRowsOverlay={{
              icon: <SalesTaxIcon />,
              title: t('settings:sales_tax.empty_title'),
              description: t('settings:sales_tax.empty_description'),
            }}
            slots={{
              pagination: CursorPagination,
            }}
            sx={{
              '.MuiDataGrid-row': {
                '&.Mui-error': { color: 'redBase' },
              },
            }}
            getRowClassName={(params) => {
              const classes = []
              const containsInvalidRules = containsInvalidRule(params.row)
              if (params.id === viewPanelState.salesTaxId) {
                classes.push('Mui-selected')
              }
              if (containsInvalidRules) {
                classes.push('Mui-error')
              }

              return classes.join(' ')
            }}
            columnVisibilityModel={{
              name: true,
              code: true,
              decimalvaluedefault: true,
              productgrouprules: true,
            }}
          />
        </Container>
      </Grid>
      <Grid>
        <SalesTaxViewPanel
          open={viewPanelState.isOpen}
          salesTaxId={viewPanelState.salesTaxId}
          onClose={closeViewPanel}
        />
      </Grid>
    </Grid>
  )
}
