import { MenuItem, TextField, Typography } from '@mui/material'
import { useContext, useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { FilterContext } from '../../../components/data-grid/context'
import { JournalEntryType } from '../../../generated/graphql'
import { useStateParams } from '../../../hooks/state-params'
import { useTracking } from '../../../hooks/tracking'

type QueryParamsState = {
  entryType?: string
}

export const EntryTypeFilter = () => {
  const { t } = useTranslation(['journals'])
  const { removeFilter, setFilter, subscribeOnResetFilter, registerFilter } =
    useContext(FilterContext)
  const { trackButtonClickEvent } = useTracking()
  const filterKey = 'entryType'
  const entryTypes = useMemo(
    () =>
      Object.values(JournalEntryType)
        .map((x) => ({
          value: x,
          label: t(`journals:entry_type_enum.${x}`),
        }))
        .sort((a, b) => a.label.localeCompare(b.label)),
    [t],
  )

  const [queryParams, setQueryParams] = useStateParams<QueryParamsState>()

  const selectedEntryType = entryTypes.find(
    ({ value }) => value === queryParams.entryType,
  )
    ? queryParams.entryType
    : ''

  useEffect(() => {
    registerFilter({
      key: filterKey,
    })
  }, [registerFilter])

  useEffect(() => {
    const unsubscribe = subscribeOnResetFilter((key) => {
      if (!key || key === filterKey) setQueryParams({ [filterKey]: undefined })
    })
    return () => {
      unsubscribe()
    }
  }, [setQueryParams, subscribeOnResetFilter])

  useEffect(() => {
    const entryType = entryTypes?.find(
      ({ value }) => value === selectedEntryType,
    )

    if (entryType) {
      setFilter(filterKey, {
        label: t('journals:entry_type'),
        labelValues: [entryType.label],
        value: entryType.value,
      })
    } else {
      removeFilter(filterKey)
    }
  }, [removeFilter, setFilter, selectedEntryType, entryTypes, t])

  return (
    <TextField
      onChange={(event) =>
        setQueryParams({
          [filterKey]: event.target.value || undefined,
        })
      }
      value={selectedEntryType || ''}
      slotProps={{ select: { displayEmpty: true } }}
      select // tell TextField to render select
      label={t('journals:entry_type')}
    >
      <MenuItem
        value={''}
        divider
        onClick={trackButtonClickEvent({
          name: 'journal-filter-select-entry-type',
          entryType: 'all',
        })}
      >
        <Typography variant="body02">{t('shared:label.all')}</Typography>
      </MenuItem>
      {entryTypes?.map((entryType) => (
        <MenuItem
          key={entryType.value}
          value={entryType.value || ''}
          divider
          onClick={trackButtonClickEvent({
            name: 'journal-filter-select-entry-type',
            entryType: entryType.value,
          })}
        >
          <Typography variant="body02">{entryType.label}</Typography>
        </MenuItem>
      ))}
    </TextField>
  )
}
