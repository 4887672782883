import { DateRangePickerInput } from '../report-inputs/date-range-picker-input'
import { OmnifulfillmentStoresInput } from '../report-inputs/omnifulfillment-stores-input'

export const OmniFulfillmentReportFields = () => {
  return (
    <>
      <DateRangePickerInput
        dateStartKey="omniFulfillmentDateStart"
        dateEndKey="omniFulfillmentDateEnd"
      />
      <OmnifulfillmentStoresInput />
    </>
  )
}
