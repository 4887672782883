import { ListItem, MenuItem, TextField, Typography } from '@mui/material'
import { useContext, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { FilterContext } from '../../../components/data-grid/context'
import { useStateParams } from '../../../hooks/state-params'
import { useTracking } from '../../../hooks/tracking'
import { useDebounce } from 'react-use'

export enum CustomerDetailTypeEnum {
  PersonalId = 'PersonalId',
  Email = 'Email',
  Mobile = 'Mobile',
}

type QueryParamsState = {
  customer?: [type: CustomerDetailTypeEnum, value?: string]
}

export const CustomerFilter = () => {
  const { t } = useTranslation(['users', 'shared'])
  const {
    hasApply,
    removeFilter,
    setFilter,
    subscribeOnResetFilter,
    registerFilter,
  } = useContext(FilterContext)
  const { trackButtonClickEvent } = useTracking()
  const filterKey = 'customer'

  const customerDetailTypes = useMemo(
    () =>
      Object.values(CustomerDetailTypeEnum)
        .map((x) => ({
          value: x,
          label: t(`users:customer_detail_type_enum.${x}`),
        }))
        .sort((a, b) => a.label.localeCompare(b.label)),
    [t],
  )

  const [queryParams, setQueryParams] =
    useStateParams<QueryParamsState>(undefined)

  const selectedCustomerDetailType =
    queryParams.customer?.[0] &&
    customerDetailTypes.find(({ value }) => value === queryParams.customer?.[0])
      ? queryParams.customer?.[0]
      : CustomerDetailTypeEnum.Email

  const selectedCustomerDetailValue = queryParams.customer?.[1] || ''

  const [debouncedSearchText, setDebouncedSearchText] = useState<string>(
    selectedCustomerDetailValue,
  )

  useDebounce(
    () => {
      setDebouncedSearchText(selectedCustomerDetailValue)
    },
    300,
    [selectedCustomerDetailValue],
  )

  useEffect(() => {
    registerFilter({ key: filterKey })
  }, [registerFilter])

  useEffect(() => {
    const unsubscribe = subscribeOnResetFilter((key) => {
      if (!key || key === filterKey) {
        setQueryParams({ [filterKey]: undefined })
      }
    })
    return () => {
      unsubscribe()
    }
  }, [setQueryParams, subscribeOnResetFilter])

  const filterText = hasApply
    ? selectedCustomerDetailValue
    : debouncedSearchText

  useEffect(() => {
    const customerDetailType = customerDetailTypes?.find(
      ({ value }) => value === selectedCustomerDetailType,
    )

    if (customerDetailType && filterText) {
      setFilter(filterKey, {
        label: `${customerDetailType.label}: ${filterText}`,
        value: [customerDetailType.value, filterText],
      })
    } else {
      removeFilter(filterKey)
    }
  }, [
    removeFilter,
    setFilter,
    selectedCustomerDetailType,
    filterText,
    customerDetailTypes,
    t,
  ])

  return (
    <ListItem sx={{ gap: 1 }}>
      <TextField
        data-testid="filter-input"
        onChange={(event) => {
          setQueryParams(
            {
              [filterKey]: [
                selectedCustomerDetailType,
                event.target.value || undefined,
              ],
            },
            hasApply !== true,
          )
        }}
        value={selectedCustomerDetailValue}
        placeholder={
          customerDetailTypes.find(
            (x) => x.value === selectedCustomerDetailType,
          )?.label || CustomerDetailTypeEnum.Email
        }
        label={t('users:user_filter_label')}
      />
      <TextField
        select
        onChange={(event) =>
          setQueryParams(
            {
              [filterKey]: [
                event.target.value as CustomerDetailTypeEnum,
                selectedCustomerDetailValue,
              ],
            },
            hasApply !== true,
          )
        }
        value={selectedCustomerDetailType}
        slotProps={{ select: { displayEmpty: true } }}
        label="&#8203;" // zero width space
      >
        {customerDetailTypes?.map((customerDetailType) => (
          <MenuItem
            key={customerDetailType.value}
            value={customerDetailType.value || ''}
            divider
            onClick={trackButtonClickEvent({
              name: 'user-search-filter-select-customer-detail-type',
              customerDetailType: customerDetailType.value,
            })}
          >
            <Typography variant="body02">{customerDetailType.label}</Typography>
          </MenuItem>
        ))}
      </TextField>
    </ListItem>
  )
}
