import { useMemo } from 'react'
import {
  AppsIcon,
  DashboardIcon,
  DiscountIcon,
  FolderIcon,
  HomeIcon,
  JournalIcon,
  OrderIcon,
  OrdersIcon,
  RegisterCashIcon,
  ReportIcon,
  ServiceOrdersIcon,
  SettingsIcon,
  ShipmentIcon,
  StarIcon,
  UserDefaultIcon,
  WarehouseIcon,
} from '@sitoo/mui-components'
import { List, ListItemButton, ListItemIcon, ListItemText } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { RootRoute } from '../../pages'
import { useAuthorization } from '../../hooks/authorization'
import { SidebarList, MenuSection } from '../sidebar-list'
import { FeedbackDialog, feedbackDialogOpenVar } from '../feedback-dialog'
import { SidebarListItem } from '../sidebar-list-item'

export const Sidebar = () => {
  const { t } = useTranslation(['shared'])
  const { modules } = useAuthorization()

  const menuSections: MenuSection[] = useMemo(
    () => [
      {
        name: t('shared:menu.productivity'),
        items: [
          {
            name: t('shared:menu.home'),
            icon: <HomeIcon />,
            to: RootRoute.Home,
            active: true,
          },
          {
            name: t('shared:menu.orders'),
            icon: <OrdersIcon />,
            items: [
              {
                name: t('shared:menu.orders'),
                to: RootRoute.Orders,
                active: modules.orders,
              },
              {
                name: t('shared:menu.advanced_search'),
                to: RootRoute.OrderSearch,
                active: modules.orderLookup,
              },
            ],
          },
          {
            name: t('shared:menu.shipments'),
            icon: <ShipmentIcon />,
            to: RootRoute.Shipments,
            active: modules.shipments || modules.shipmentsV2,
          },
          {
            name: t('shared:menu.service_orders'),
            icon: <ServiceOrdersIcon />,
            to: RootRoute.ServiceOrders,
            active: modules.serviceOrders,
          },
          {
            name: t('shared:menu.stock'),
            icon: <WarehouseIcon />,
            items: [
              {
                name: t('shared:menu.stock_levels'),
                to: RootRoute.StockLevels,
                active: modules.stock,
              },
              {
                name: t('shared:menu.delivery_in'),
                to: RootRoute.DeliveriesIn,
                active: modules.deliveryIn,
              },
              {
                name: t('shared:menu.delivery_out'),
                to: RootRoute.DeliveriesOut,
                active: modules.deliveryOut,
              },
              {
                name: t('shared:menu.stocktaking'),
                to: RootRoute.Stocktakings,
                active: modules.stocktaking,
              },

              {
                name: t('shared:menu.in_store_replenishment'),
                to: RootRoute.InStoreReplenishment,
                active: modules.inStoreReplenishment,
              },
            ],
          },
          {
            name: t('shared:menu.journals'),
            icon: <JournalIcon />,
            to: RootRoute.Journals,
            active: modules.journals,
          },
          {
            name: t('shared:menu.reports'),
            icon: <ReportIcon />,
            showBetaTag: true,
            to: RootRoute.ReportJobs,
            active: modules.reports,
          },
          {
            name: t('shared:menu.store_dashboards'),
            icon: <DashboardIcon />,
            to: RootRoute.StoreDashboards,
            active: modules.storeDashboards,
          },
        ],
      },
      {
        name: t('shared:menu.platform'),
        items: [
          {
            name: t('shared:menu.products'),
            icon: <FolderIcon />,
            to: RootRoute.Products,
            active: modules.products,
          },
          {
            name: t('shared:menu.prices'),
            icon: <OrderIcon />,
            to: RootRoute.Prices,
            active: modules.prices,
          },
          {
            name: t('shared:menu.purchase_prices'),
            icon: <OrderIcon />,
            to: RootRoute.PurchasePrices,
            active: modules.purchasePrices,
          },
          {
            name: t('shared:menu.campaigns'),
            icon: <DiscountIcon />,
            to: RootRoute.Campaigns,
            active: modules.campaigns,
          },
          {
            name: t('shared:menu.influencer_codes'),
            icon: <StarIcon />,
            to: RootRoute.InfluencerCodes,
            active: modules.influencerCodes,
          },
          {
            name: t('shared:menu.statistics'),
            icon: <DashboardIcon />,
            to: RootRoute.Statistics,
            active: modules.statistics,
          },
          {
            name: t('shared:menu.users'),
            icon: <UserDefaultIcon />,
            to: RootRoute.Users,
            active: modules.users,
          },
          {
            name: t('shared:menu.cash_registers'),
            icon: <RegisterCashIcon />,
            to: RootRoute.CashRegisters,
            active: modules.cashRegisters,
          },
          {
            name: t('shared:menu.apps'),
            icon: <AppsIcon />,
            to: RootRoute.Apps,
            active: modules.apps,
          },
        ],
      },
    ],
    [
      modules.apps,
      modules.campaigns,
      modules.cashRegisters,
      modules.deliveryIn,
      modules.deliveryOut,
      modules.inStoreReplenishment,
      modules.influencerCodes,
      modules.journals,
      modules.reports,
      modules.orderLookup,
      modules.orders,
      modules.prices,
      modules.products,
      modules.purchasePrices,
      modules.serviceOrders,
      modules.shipments,
      modules.shipmentsV2,
      modules.statistics,
      modules.stock,
      modules.stocktaking,
      modules.users,
      modules.storeDashboards,
      t,
    ],
  )

  return (
    <SidebarList
      menuSections={menuSections}
      footer={
        <List sx={{ mb: 2, mt: 'auto' }}>
          {modules.directFeedback && (
            <>
              <FeedbackDialog />

              <ListItemButton
                onClick={() => feedbackDialogOpenVar(true)}
                sx={{ pl: [2, 3], bgcolor: 'transparent' }}
              >
                <ListItemIcon sx={{ color: 'inherit' }}>
                  <StarIcon />
                </ListItemIcon>
                <ListItemText>
                  {t('shared:feedback_dialog.leave_feedback')}
                </ListItemText>
              </ListItemButton>
            </>
          )}
          <SidebarListItem
            name={t('shared:menu.settings')}
            icon={<SettingsIcon />}
            to={RootRoute.Settings}
          />
        </List>
      }
    />
  )
}
