/* eslint-disable react-hooks/rules-of-hooks */
import { use } from 'i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import HttpBackend from 'i18next-http-backend'
import { initReactI18next } from 'react-i18next'

export const defaultNS = 'shared'

export const SupportedLanguages = ['en', 'en-GB', 'sv'] as const

export const FALLBACK_LANGUAGE = 'en'

export const getI18nLoadPath = () => {
  const version = import.meta.env.VITE_APP_VERSION
  const baseUrl = import.meta.env.BASE_URL

  return `${baseUrl}locales/{{lng}}/{{ns}}.json?${version}`
}

export const initI18next = () => {
  void use(LanguageDetector)
    .use(HttpBackend)
    .use(initReactI18next)
    .init({
      defaultNS,
      ns: ['shared', 'filter'],

      fallbackLng: FALLBACK_LANGUAGE,
      supportedLngs: SupportedLanguages,

      detection: {
        order: ['querystring', 'localStorage', 'navigator'],
      },

      react: {
        useSuspense: true,
      },

      interpolation: {
        escapeValue: false,
      },

      backend: {
        loadPath: getI18nLoadPath(),
      },
    })
}
