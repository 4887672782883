import { useQuery } from '@apollo/client'
import { ListItem } from '@mui/material'
import { useMemo } from 'react'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { Form } from '..'
import { ReportCashRegistersDocument } from '../../../../generated/graphql'
import { AutocompleteInput } from '../../../../inputs/autocomplete-input'

export const PosInput = () => {
  const { control } = useFormContext<Form>()
  const { t } = useTranslation('reports')

  const { data: cashRegisters } = useQuery(ReportCashRegistersDocument, {
    fetchPolicy: 'cache-and-network',
  })

  const allCashRegisters = cashRegisters?.allCashRegisters

  const cashRegisterIds = useMemo(() => {
    return (
      allCashRegisters?.map((cashRegister) =>
        String(cashRegister.registerid),
      ) || []
    )
  }, [allCashRegisters])
  if (!allCashRegisters) return null

  return (
    <ListItem>
      <AutocompleteInput
        control={control}
        label={t('create_dialog.pos')}
        name="reportdefinition.filters.orderRegisterId"
        options={cashRegisterIds}
        autocompleteProps={{
          getOptionLabel: (value) => {
            const cashRegister = allCashRegisters?.find(
              (cashRegister) => cashRegister.registerid === value,
            )

            return t('create_dialog.pos_list_item', {
              number: cashRegister?.registernumber ?? '',
              key: cashRegister?.registerkey ?? '',
            })
          },
        }}
      />
    </ListItem>
  )
}
