import { Box } from '@mui/material'
import { SectionHeader } from '@sitoo/mui-components'
import { useTranslation } from 'react-i18next'

export const HeaderSection = () => {
  const { t } = useTranslation('reports')

  return (
    <Box
      sx={{
        position: 'sticky',
        top: 0,
        zIndex: 1,
        background: (theme) => theme.palette.background.paper,
        padding: (theme) => theme.spacing(1),
      }}
    >
      <SectionHeader sx={{ backgroundColor: 'transparent' }}>
        <Box
          sx={{
            display: 'flex',
            gap: (theme) => theme.spacing(0.5),
          }}
        >
          {t('create.new_report_based_on')}
        </Box>
      </SectionHeader>
    </Box>
  )
}
