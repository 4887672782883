import { IconButton, TextField } from '@mui/material'
import { useCallback, useContext, useEffect, useState } from 'react'
import { FilterContext } from '../../../components/data-grid/context'
import { useStateParams } from '../../../hooks/state-params'
import { useTracking } from '../../../hooks/tracking'
import { useDebounce } from 'react-use'
import { CloseIcon } from '@sitoo/mui-components'

type ViewPanelTextFilterProps = {
  filterKey: string
  label: string
  placeholder?: string
}

export const ViewPanelTextFilter = (props: ViewPanelTextFilterProps) => {
  const {
    removeFilter,
    setFilter,
    subscribeOnResetFilter,
    registerFilter,
    hasApply,
  } = useContext(FilterContext)
  const { trackInputFocus, trackInputBlur } = useTracking()
  const { filterKey, placeholder, label } = props

  const [queryState, setQueryState] =
    useStateParams<Record<string, string | undefined>>()

  const searchText = queryState[filterKey] || ''
  const [inputText, setInputText] = useState(searchText)

  const [debouncedSearchText, setDebouncedSearchText] =
    useState<string>(searchText)

  const onChange = useCallback(
    (value?: string) => {
      setInputText(value || '')
      setQueryState({ [filterKey]: value || undefined })
    },
    [filterKey, setQueryState],
  )

  useDebounce(
    () => {
      setDebouncedSearchText(searchText)
    },
    300,
    [searchText],
  )

  useEffect(() => {
    registerFilter({
      key: filterKey,
    })
  }, [filterKey, registerFilter])

  useEffect(() => {
    const unsubscribe = subscribeOnResetFilter((key) => {
      if (!key || key === filterKey) {
        onChange(undefined)
      }
    })
    return () => {
      unsubscribe()
    }
  }, [filterKey, onChange, setQueryState, subscribeOnResetFilter])

  const filterText = hasApply ? searchText : debouncedSearchText
  useEffect(() => {
    if (filterText) {
      setFilter(filterKey, {
        label: `${label}: ${filterText}`,
        value: filterText,
      })
    } else {
      removeFilter(filterKey)
    }
  }, [filterKey, filterText, label, removeFilter, setFilter])

  return (
    <TextField
      type="text"
      onChange={(e) => onChange(e.target.value)}
      onFocus={trackInputFocus({ name: `view-panel-text-filter-${filterKey}` })}
      onBlur={trackInputBlur({ name: `view-panel-text-filter-${filterKey}` })}
      value={inputText}
      label={label}
      placeholder={placeholder}
      slotProps={{
        input: {
          endAdornment: searchText && (
            <IconButton
              sx={{ marginRight: 0.5 }}
              onClick={() => onChange(undefined)}
            >
              <CloseIcon
                sx={{
                  margin: '0 !important',
                }}
              />
            </IconButton>
          ),
        },

        htmlInput: {
          'data-testid': `search-text-field-${filterKey}`,
        },
      }}
    />
  )
}
