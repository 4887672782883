import {
  Box,
  Button,
  Divider,
  Grid,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/material'
import { ChevronSmallRightIcon, QuestionIcon, Tag } from '@sitoo/mui-components'
import { useMemo } from 'react'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { GetProductQuery } from '../../../../../generated/graphql'

type VariantsSummaryProps = {
  navigateToVariantsTab(): void
}

export const VariantsSummary = (props: VariantsSummaryProps) => {
  const theme = useTheme()
  const { t } = useTranslation('products')
  const { getValues } = useFormContext<GetProductQuery>()

  const [title, sku, images] = getValues([
    'product.title',
    'product.sku',
    'product.productImages',
  ])

  const mainImage = useMemo(() => {
    if (images?.length && images.length > 0) {
      const firstImage = images[0]!
      return firstImage.fileUrl
    }

    return ''
  }, [images])

  return (
    <>
      <Grid container gap={theme.spacing(2)} direction="column">
        <Grid container columns={12} gap={theme.spacing(2)}>
          <Grid>
            {mainImage ? (
              <Box
                component="img"
                sx={{ width: '40px', height: '100%', objectFit: 'contain' }}
                src={mainImage}
              />
            ) : (
              <Box
                sx={{
                  background: (t) => t.palette.gray20,
                  minHeight: '40px',
                  height: '100%',
                  width: '40px',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <QuestionIcon
                  sx={{
                    color: (t) => t.palette.gray50,
                  }}
                />
              </Box>
            )}
          </Grid>
          <Grid overflow={'hidden'} textOverflow={'ellipsis'}>
            <Tooltip
              data-testid="variant-title-label"
              title={title}
              placement="top-start"
            >
              <Typography variant="body02" display="block" noWrap>
                {title}
              </Typography>
            </Tooltip>
            <Tooltip
              data-testid="variant-sku-label"
              title={sku}
              placement="bottom-start"
            >
              <Typography variant="caption" noWrap>
                {sku}
              </Typography>
            </Tooltip>
          </Grid>
          <Grid alignSelf="center">
            <Tag variant="black">{t('products:product_form.main_label')}</Tag>
          </Grid>
        </Grid>

        <Grid>
          <Divider />
        </Grid>

        <Grid>
          <Button
            data-testid="view-variants-button"
            color="secondary"
            sx={{ width: '100%' }}
            onClick={props.navigateToVariantsTab}
          >
            {t('product_form.view_all_variants')}
            <ChevronSmallRightIcon fontSize="medium" />
          </Button>
        </Grid>

        <Grid>
          <Typography variant="caption" color="gray60">
            {t('product_form.view_all_variants_description')}
          </Typography>
        </Grid>
      </Grid>
    </>
  )
}
