import { Stack, Typography } from '@mui/material'
import { GridRenderCellParams } from '@mui/x-data-grid-pro'
import { ReportJobsQuery, ReportType } from '../../generated/graphql'
import { ArrayElement } from '../../utils/types'
import { useLocalizedDate } from '../../hooks/localized-date'

export const DateRangeRenderer = (
  params: GridRenderCellParams<
    ArrayElement<ReportJobsQuery['reportJobs']['items']>
  >,
) => {
  const { formatDate } = useLocalizedDate()
  const { row } = params ?? {}
  const {
    reporttype,
    reportdefinition: { filters },
  } = row

  const getDates = () => {
    switch (reporttype) {
      case ReportType.Creditnote:
        return [filters?.creditNoteDateStart, filters?.creditNoteDateEnd]
      case ReportType.GiftCard:
        return [filters?.giftCardDateStart, filters?.giftCardDateEnd]
      case ReportType.OmniFulfillment:
        return [
          filters?.omniFulfillmentDateStart,
          filters?.omniFulfillmentDateEnd,
        ]
      case ReportType.Product:
      case ReportType.Order:
        return [filters?.orderDateStart, filters?.orderDateEnd]
      case ReportType.SalesTax:
        return [filters?.salesTaxDateStart, filters?.salesTaxDateEnd]
      case ReportType.Stock:
        return [null, filters?.stockDateEnd]
      case ReportType.Warehouse:
        return [filters?.warehouseDateStart, filters?.warehouseDateEnd]
      default:
        return [null, null]
    }
  }

  const [startDate, endDate] = getDates()
  return (
    <Stack direction="row" spacing={0.5} alignItems="center" height="100%">
      {startDate && (
        <Typography>{formatDate(startDate, { dateStyle: 'short' })}</Typography>
      )}
      {startDate && endDate && <Typography>-</Typography>}
      {endDate && (
        <Typography>{formatDate(endDate, { dateStyle: 'short' })}</Typography>
      )}
    </Stack>
  )
}
