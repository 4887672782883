import { useReactiveVar } from '@apollo/client'
import { Container, Grid, Tab, Tabs } from '@mui/material'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { FilterProvider } from '../../components/data-grid/context'
import { PageHeader } from '../../components/page-header'
import { OrderState } from '../../generated/graphql'
import { useTabs } from '../../hooks/tab'
import { usePageTitle } from '../../hooks/title'
import { useTracking } from '../../hooks/tracking'
import { DEFAULT_ORDER_ID } from '../../utils/constants'
import { OrderFilterViewPanel } from './order-filter-view-panel'
import { OrderViewPanel } from './order-view-panel'
import { OrderList } from './orders-list'
import { orderFilterViewPanelVar, orderViewPanelVar } from './util'

enum TabLabel {
  Open = 1,
  Closed,
  All,
}

export const OrdersPage = () => {
  const { t } = useTranslation(['orders', 'journals'])
  usePageTitle(t('orders:page_title'))
  const [activeTab, setActiveTab] = useTabs(TabLabel, TabLabel.Open)
  const viewPanelState = useReactiveVar(orderViewPanelVar)
  const { isOpen } = useReactiveVar(orderFilterViewPanelVar)
  const { trackButtonClick } = useTracking()

  const openViewPanel = (orderId: number) => {
    if (isOpen) {
      closeFilterViewPanel()
    }

    trackButtonClick({ name: 'orders-view-panel-open', orderId })
    orderViewPanelVar({ isOpen: true, orderId: Number(orderId) })
  }
  const closeViewPanel = () => {
    trackButtonClick({ name: 'orders-view-panel-close' })
    orderViewPanelVar({ isOpen: false, orderId: DEFAULT_ORDER_ID })
  }

  const closeFilterViewPanel = () => {
    trackButtonClick({ name: 'orders-filter-view-panel-close' })
    orderFilterViewPanelVar({ isOpen: false })
  }

  const openFilterViewPanel = () => {
    trackButtonClick({ name: 'orders-filter-view-panel-open' })
    orderFilterViewPanelVar({ isOpen: true })
  }

  const toggleFilterViewPanel = () => {
    if (isOpen) {
      closeFilterViewPanel()
    } else {
      openFilterViewPanel()
    }
  }

  const orderState = useMemo(() => {
    if (activeTab === TabLabel.Open) return OrderState.Open
    if (activeTab === TabLabel.Closed) return OrderState.Closed
    return undefined
  }, [activeTab])

  return (
    <FilterProvider>
      <Grid container flexWrap="nowrap">
        <Grid size="grow">
          <PageHeader title={t('orders:page_title')} isFlexible>
            <Tabs
              value={activeTab}
              onChange={(_e, value: TabLabel) => {
                trackButtonClick({
                  name: 'orders-tab',
                  value,
                })
                setActiveTab(value)
              }}
              variant="standard"
              sx={{
                '.MuiButtonBase-root': {
                  borderColor: 'transparent',
                },
              }}
            >
              <Tab
                label={t('orders:order_tabs.open')}
                value={TabLabel.Open}
                data-testid="tab-open"
              />
              <Tab
                label={t('orders:order_tabs.closed')}
                value={TabLabel.Closed}
                data-testid="tab-closed"
              />
              <Tab
                label={t('orders:order_tabs.all')}
                value={TabLabel.All}
                data-testid="tab-all"
              />
            </Tabs>
          </PageHeader>
          <Container maxWidth={false}>
            <OrderList
              orderState={orderState}
              onDetailOrder={openViewPanel}
              currentDetailedOrderId={viewPanelState.orderId}
              onShowFilter={toggleFilterViewPanel}
            />
          </Container>
        </Grid>
        <Grid>
          <OrderViewPanel
            viewPanelVar={orderViewPanelVar}
            onClose={closeViewPanel}
          />
          <OrderFilterViewPanel
            isOpen={isOpen}
            onClose={closeFilterViewPanel}
          />
        </Grid>
      </Grid>
    </FilterProvider>
  )
}
