import { FormFieldset } from '@sitoo/mui-components'
import { useMemo } from 'react'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { SupportedLanguages } from '../../../i18n'
import { SelectInput, Option } from '../../../inputs/select-input'
import { BaseUserContext } from '../shared'

export const LanguageAndLocale = () => {
  const { t } = useTranslation(['shared', 'users'])
  const { control } = useFormContext<BaseUserContext>()

  const languages = useMemo<Option[]>(
    () =>
      SupportedLanguages.map((language) => ({
        value: language,
        name: t(`shared:languages.${language}`),
      })).sort((a, b) => a.name.localeCompare(b.name)),
    [t],
  )

  return (
    <FormFieldset label={t('users:user_form.language_and_locale_fieldset')}>
      <SelectInput
        name="language"
        control={control}
        label={t('users:language_label')}
        options={languages}
        helperText={t('users:language_helper_text')}
        sx={{ mb: 2 }}
      />
    </FormFieldset>
  )
}
