import { useLocalStorage } from 'react-use'
import { useScopeSpecificKey } from '../../../hooks/persisted-values'
import { useCallback, useMemo } from 'react'

export const useFavoriteReportJobs = () => {
  const key = useScopeSpecificKey('favoriteReportJobs')
  const [localStorage, setLocalStorage] = useLocalStorage<number[]>(key, [])

  const favorites = useMemo(() => localStorage || [], [localStorage])

  const checkIfFavorite = useCallback(
    (id: number) => favorites.includes(id),
    [favorites],
  )

  const addFavorite = useCallback(
    (id: number) => setLocalStorage([...favorites, id]),
    [favorites, setLocalStorage],
  )

  const removeFavorite = useCallback(
    (id: number) =>
      setLocalStorage(favorites.filter((favoriteId) => favoriteId !== id)),
    [favorites, setLocalStorage],
  )

  return {
    favorites,
    addFavorite,
    checkIfFavorite,
    removeFavorite,
  }
}
