import { Radio, RadioGroup, Stack } from '@mui/material'
import { DateTimePicker } from '@mui/x-date-pickers-pro'
import { FormFieldset, RadioGroupLabel } from '@sitoo/mui-components'
import { Dayjs } from 'dayjs'
import { useCallback, useState } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useDayJs } from '../../../hooks/day-js'
import { useLocalizedDate } from '../../../hooks/localized-date'
import { InfluencerCodeContext } from '..'

type Selection = 'AllTime' | 'CustomRange'

export const ValidWhen = () => {
  const { getValues, setValue, control } =
    useFormContext<InfluencerCodeContext>()
  const dayJs = useDayJs()
  const { getDateTimeFormat } = useLocalizedDate()

  const { t } = useTranslation(['shared'])

  const [selection, setSelection] = useState<Selection>(
    getValues(['datestart', 'dateend']).filter(Boolean).length
      ? 'CustomRange'
      : 'AllTime',
  )

  const onSelectionChange = useCallback(
    (_event: React.SyntheticEvent<HTMLInputElement>, value: string) => {
      if (value === 'AllTime') {
        setSelection('AllTime')
        setValue('datestart', null)
        setValue('dateend', null)
      } else {
        setSelection('CustomRange')
      }
    },
    [setValue],
  )

  const prepareDate = (date: Dayjs | null) => {
    if (date?.isValid()) {
      return date.set('second', 0).set('millisecond', 0)
    }
  }

  return (
    <FormFieldset
      label={t('influencer_codes:valid_range_field.valid_when')}
      sx={{ '.MuiFormFieldset-Paper': { p: 0 } }}
    >
      <RadioGroup value={selection} onChange={onSelectionChange}>
        <RadioGroupLabel
          label={t('influencer_codes:valid_range_field.all_time')}
          sx={{ px: 2 }}
          divider
          secondaryAction={
            <Radio value="AllTime" data-testid="valid-when-all-field" />
          }
        />

        <RadioGroupLabel
          label={t('influencer_codes:valid_range_field.custom_range')}
          sx={{ px: 2 }}
          secondaryAction={
            <Radio value="CustomRange" data-testid="valid-when-custom-field" />
          }
        >
          <Stack direction="row" spacing={2} sx={{ mb: 2 }}>
            <Controller
              control={control}
              name="datestart"
              render={({ field, fieldState: { error } }) => (
                <DateTimePicker
                  slotProps={{
                    textField: {
                      label: t('influencer_codes:valid_range_field.start_date'),
                      error: !!error?.message,
                      helperText: error?.message,
                      inputProps: {
                        'data-testid': 'datestart-field',
                      },
                    },
                    openPickerButton: {
                      edge: 'start',
                    },
                  }}
                  value={field.value ? dayJs(field.value) : null}
                  format={getDateTimeFormat()}
                  onChange={(newValue) => field.onChange(prepareDate(newValue))}
                />
              )}
            />

            <Controller
              control={control}
              name="dateend"
              render={({ field, fieldState: { error } }) => (
                <DateTimePicker
                  slotProps={{
                    textField: {
                      label: t('influencer_codes:valid_range_field.end_date'),
                      error: !!error?.message,
                      helperText: error?.message,
                      inputProps: {
                        'data-testid': 'dateend-field',
                      },
                    },
                    openPickerButton: {
                      edge: 'start',
                    },
                  }}
                  value={field.value ? dayJs(field.value) : null}
                  format={getDateTimeFormat()}
                  onChange={(newValue) => field.onChange(prepareDate(newValue))}
                />
              )}
            />
          </Stack>
        </RadioGroupLabel>
      </RadioGroup>
    </FormFieldset>
  )
}
