import { useQuery } from '@apollo/client'
import { MenuItem, TextField, Typography } from '@mui/material'
import { useContext, useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { FilterContext } from '../../../components/data-grid/context'
import { GetStoresDocument } from '../../../generated/graphql'
import { useStateParams } from '../../../hooks/state-params'
import { useTracking } from '../../../hooks/tracking'

const DEFAULT_STORE = ''

type QueryParamsState = {
  storeId?: string
}

export const StoreFilter = () => {
  const { t } = useTranslation(['filter', 'shared'])
  const { removeFilter, setFilter, subscribeOnResetFilter, registerFilter } =
    useContext(FilterContext)
  const { trackButtonClickEvent } = useTracking()
  const filterKey = 'storeId'
  const filterWarehouseKey = 'warehouseIds'
  const { data, loading: isLoading } = useQuery(GetStoresDocument)
  const stores = useMemo(
    () => data?.stores?.filter((x) => (x.warehouses?.length || 0) > 0),
    [data?.stores],
  )

  const [queryParams, setQueryParams] = useStateParams<QueryParamsState>()

  const storeId = useMemo(
    () =>
      stores?.find(({ id }) => String(id) === String(queryParams.storeId))
        ? String(queryParams.storeId)
        : DEFAULT_STORE,
    [queryParams.storeId, stores],
  )

  useEffect(() => {
    registerFilter({
      key: filterWarehouseKey,
      isReady: !isLoading,
    })
  }, [isLoading, registerFilter])

  useEffect(() => {
    const unsubscribe = subscribeOnResetFilter((key) => {
      if (!key || key === filterWarehouseKey)
        setQueryParams({ [filterKey]: undefined })
    })
    return () => {
      unsubscribe()
    }
  }, [setQueryParams, subscribeOnResetFilter])

  useEffect(() => {
    const store = stores?.find(({ id }) => String(id) === storeId)
    const storeWarehouses = store?.warehouses?.map(({ id }) => id)

    if (storeWarehouses) {
      setFilter(filterWarehouseKey, {
        label: t('filter:store_filter.label'),
        labelValues: [store?.name || ''],
        value: storeWarehouses,
      })
    } else {
      setFilter(filterWarehouseKey, {
        isDefault: true,
        label: t('filter:store_filter.label'),
        labelValues: [t('shared:label.all')],
        value: '',
      })
    }
  }, [removeFilter, setFilter, storeId, stores, t])

  return (
    <TextField
      onChange={(event) =>
        setQueryParams({
          [filterKey]: event.target.value || undefined,
        })
      }
      disabled={isLoading}
      value={storeId}
      slotProps={{ select: { displayEmpty: true } }}
      select
      label={t('filter:store_filter.label')}
    >
      <MenuItem
        value={DEFAULT_STORE}
        divider
        onClick={trackButtonClickEvent({
          name: 'store-filter-select-category',
          store: 'All stores',
        })}
      >
        <Typography variant="body02">{t('shared:label.all')}</Typography>
      </MenuItem>
      {stores?.map((store) => (
        <MenuItem
          key={store.id}
          value={store.id}
          divider
          onClick={trackButtonClickEvent({
            name: 'store-filter-select-category',
            store: store.name,
          })}
        >
          <Typography variant="body02">{store.name}</Typography>
        </MenuItem>
      ))}
    </TextField>
  )
}
