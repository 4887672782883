import { useQuery } from '@apollo/client'
import { ListItemText } from '@mui/material'
import { useFormContext, useWatch } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { Form } from '../..'
import { SettingsListItem } from '../../../../../components/settings-list-item'
import { SettingsListSection } from '../../../../../components/settings-list-section'
import {
  GetConfigVarsDocument,
  ThemesDocument,
} from '../../../../../generated/graphql'
import { SwitchInput } from '../../../../../inputs/switch-input'
import { ThemeConfigurationField } from './fields/theme-configuration-field'
import { ThemeField } from './fields/theme-field'

export const PosTheme = () => {
  const { t } = useTranslation(['pos_profiles'])
  const { control } = useFormContext<Form>()
  const { data: configVarsData } = useQuery(GetConfigVarsDocument)
  const showThemeFields = useWatch({
    control: control,
    name: 'overrideDefaultPosTheme',
  })

  const { data, loading: isLoadingThemes } = useQuery(ThemesDocument)

  const hasPosThemes = data?.themes && data?.themes.length

  if (configVarsData?.configVars?.posThemes === false || !hasPosThemes) {
    return
  }

  return (
    <SettingsListSection title={t('pos_profiles:pos_theme_section.title')}>
      <SettingsListItem
        divider
        dense
        secondaryAction={
          <SwitchInput name="overrideDefaultPosTheme" control={control} />
        }
      >
        <ListItemText
          primary={t(
            'pos_profiles:pos_theme_section.override_default_theme_label',
          )}
          secondary={t(
            'pos_profiles:pos_theme_section.override_default_theme_description',
          )}
        />
      </SettingsListItem>

      {showThemeFields && (
        <>
          <ThemeField themes={data?.themes} loading={isLoadingThemes} />
          <ThemeConfigurationField
            themes={data?.themes}
            loading={isLoadingThemes}
          />
        </>
      )}
    </SettingsListSection>
  )
}
