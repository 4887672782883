import { useTranslation } from 'react-i18next'
import { useMutation } from '@apollo/client'
import { enqueueSnackbar } from 'notistack'
import {
  ExportProductWarehouseBatchItemsDocument,
  FileType,
} from '../../../../generated/graphql'
import { ExportDialog } from '../../../../components/export-dialog'
import { useInventoryBatchSortItems } from '../use-sort-items'
import { useBatchItemsVariables } from '../use-batch-item-variables'

interface Props {
  warehouseId: number
  warehouseBatchId: number
  open: boolean
  onClose: () => void
}

export const ExportStockDialog = (props: Props) => {
  const { warehouseId, warehouseBatchId, open, onClose } = props
  const { t } = useTranslation(['orders'])
  const [exportStockMutation, { loading: isExportingStock }] = useMutation(
    ExportProductWarehouseBatchItemsDocument,
  )

  const sortItems = useInventoryBatchSortItems()

  const queryVariables = useBatchItemsVariables({
    warehouseId,
    warehouseBatchId,
    sortItems,
  })

  const handleExport = async (fileType: FileType) => {
    try {
      const exportStockResult = await exportStockMutation({
        variables: {
          ...queryVariables,
          fileType,
          warehouseBatchId,
          warehouseId,
        },
      })

      const fileLink = exportStockResult.data?.exportProductWarehouseBatchItems

      if (fileLink) {
        window.location.href = fileLink
      }

      enqueueSnackbar(t('orders:export_success'), { variant: 'success' })
    } catch (error) {
      enqueueSnackbar(t('orders:export_error'), { variant: 'error' })
    } finally {
      onClose()
    }
  }

  return (
    <ExportDialog
      label={t('stocktaking:export_dialog_title')}
      open={open}
      onClose={onClose}
      onSuccess={handleExport}
      isLoading={isExportingStock}
    />
  )
}
