import {
  Button,
  CircularProgress,
  Divider,
  Paper,
  SxProps,
  Theme,
} from '@mui/material'
import { ErrorIcon, FilterIcon, VariantsIcon } from '@sitoo/mui-components'
import { useTranslation } from 'react-i18next'
import {
  CustomOverlay,
  CustomOverlayProps,
} from '../../components/custom-overlay'
import { ReportJobState } from '../../generated/graphql'

type Props = {
  onDelete: () => void
  jobState?: ReportJobState
  noResults?: boolean
  sx?: SxProps<Theme>
}

export const StatusOverlay = (props: Props) => {
  const { t } = useTranslation(['reports'])

  if (props.jobState === ReportJobState.Finished && !props.noResults)
    return null

  const getReportOverlayProps = (): CustomOverlayProps => {
    switch (props.jobState) {
      case ReportJobState.Pending:
        return {
          title: t('reports:report_queued_title'),
          description: t('reports:report_queued_description'),
          icon: <VariantsIcon fontSize="small" />,
          action: (
            <Button color="error" onClick={props.onDelete}>
              {t('reports:delete_report')}
            </Button>
          ),
        }
      case ReportJobState.InProgress:
        return {
          title: t('reports:report_in_progress_title'),
          description: t('reports:report_in_progress_description'),
          icon: <CircularProgress />,
        }
      case ReportJobState.Failed:
        return {
          title: t('reports:report_failed_title'),
          description: t('reports:report_failed_description'),
          icon: <ErrorIcon fontSize="small" />,
        }
      case ReportJobState.Finished:
        return {
          title: t('reports:report_no_results_title'),
          description: t('reports:report_no_results_description'),
          icon: <FilterIcon fontSize="small" />,
        }
      default:
        return {}
    }
  }

  return (
    <Paper elevation={0} sx={{ width: '100%', height: '100%', ...props.sx }}>
      <Divider />
      <CustomOverlay {...getReportOverlayProps()} />
    </Paper>
  )
}
