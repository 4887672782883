import { useState } from 'react'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormHelperText,
  ListItemText,
  Stack,
} from '@mui/material'
import { useForm, useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import yaml from 'js-yaml'
import { stripTypename } from '@apollo/client/utilities'

import { SettingsListItem } from '../../../../components/settings-list-item'
import { SettingsListSection } from '../../../../components/settings-list-section'
import { GeneralSettingsFormContext } from '../general-settings-form'
import { PosUi as PosUiConfig } from '../../../../generated/graphql'
import { enqueueSnackbar } from 'notistack'
import { YamlInput } from '../../../../inputs/yaml-input'
import { useTracking } from '../../../../hooks/tracking'

const isValidConfig = (value: unknown): value is PosUiConfig => {
  if (!value) {
    return true
  }
  if (typeof value !== 'object') {
    return false
  }

  return 'order_detail_tags' in value && 'order_list_tags' in value
}

const YAML_EXAMPLE = `order_list_tags:
  - additional_data_field: delivery-type
    color: black
order_detail_tags:
  - title: Delivery Type
    additional_data_field: delivery-type
    color: black
  - title: Pack Type
    additional_data_field: delivery-pack-type
    color: gray
`

export const PosUi = () => {
  const settingsFormContext = useFormContext<GeneralSettingsFormContext>()
  const { t } = useTranslation(['settings', 'shared'])
  const [open, setOpen] = useState(false)
  const { trackDialogOpen, trackDialogClose } = useTracking()

  const convertToYaml = () => {
    const currentValue = settingsFormContext.getValues('ui')
    if (currentValue) {
      try {
        const sanitizedValue = stripTypename(currentValue)
        const yamlString = yaml.dump(sanitizedValue)

        return yamlString
      } catch (error) {
        enqueueSnackbar(t('settings:general.ui.failed_to_convert'), {
          variant: 'error',
        })
        return
      }
    }
  }

  const {
    control: posUiControl,
    handleSubmit,
    reset,
    clearErrors,
  } = useForm<{
    rawYaml: string
  }>({
    defaultValues: {
      rawYaml: convertToYaml() || '',
    },
  })

  const handleOpenDialog = () => {
    const currentYaml = convertToYaml() || ''

    reset({
      rawYaml: currentYaml,
    })
    clearErrors()

    setOpen(true)
    trackDialogOpen({ name: 'settings-ui-dialog' })
  }

  const updatePosUi = (values: { rawYaml: string }) => {
    const parsedYaml = yaml.load(values.rawYaml)
    if (isValidConfig(parsedYaml)) {
      settingsFormContext.setValue('ui', parsedYaml || null, {
        shouldDirty: true,
      })
    }
    setOpen(false)
    trackDialogClose({
      name: 'settings-ui-dialog',
      reason: 'save',
      value: values.rawYaml,
    })
  }

  return (
    <SettingsListSection title={t('settings:general.ui.title')}>
      <SettingsListItem
        dense
        secondaryAction={
          <Button
            color="secondary"
            size="small"
            onClick={handleOpenDialog}
            aria-label={`${t('shared:action.edit')} ${t('settings:general.ui.tags_label')}`}
          >
            {t('shared:action.edit')}
          </Button>
        }
      >
        <ListItemText
          primary={t('settings:general.ui.tags_label')}
          secondary={t('settings:general.ui.tags_helper')}
        />
        <Dialog
          open={open}
          onClose={(_event, reason) => {
            if (reason === 'backdropClick') return
            setOpen(false)
          }}
          fullWidth
          maxWidth="xs"
        >
          <DialogTitle>{t('settings:general.ui.tags_label')}</DialogTitle>
          <DialogContent>
            <YamlInput
              control={posUiControl}
              name="rawYaml"
              rules={{
                validateParsed: (parsedYaml) => isValidConfig(parsedYaml),
              }}
              helperText={t('settings:general.ui.tags_helper')}
            />
            <FormHelperText component="span">
              <Stack>
                <span>{t('settings:general.ui.example_configuration')}</span>
                <pre>{YAML_EXAMPLE}</pre>
              </Stack>
            </FormHelperText>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => setOpen(false)}
              color="secondary"
              size="small"
            >
              {t('shared:action.cancel')}
            </Button>
            <Button size="small" onClick={handleSubmit(updatePosUi)}>
              {t('shared:action.ok')}
            </Button>
          </DialogActions>
        </Dialog>
      </SettingsListItem>
    </SettingsListSection>
  )
}
