import sv from 'dayjs/locale/sv'
import enGB from 'dayjs/locale/en-gb'
import dayjs from 'dayjs'

const { months, monthsShort, weekdays, weekdaysMin, weekdaysShort } = enGB

/**
 * Create a swedish locale with all the english strings
 */
const locale = {
  ...sv,
  months,
  weekdays,
  weekdaysShort,
  weekdaysMin,
  monthsShort,
}

dayjs.locale('sv', locale, true)

export default locale
