import { ListItemText } from '@mui/material'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { Form } from '../..'
import { SettingsListItem } from '../../../../../components/settings-list-item'
import { SettingsListSection } from '../../../../../components/settings-list-section'
import { SwitchInput } from '../../../../../inputs/switch-input'
import { TextFieldInput } from '../../../../../inputs/text-field-input'
import { GiftPrintingModeField } from './fields/gift-printing-mode-field'
import { ItemRenderingModeField } from './fields/item-rendering-mode-field'
import { ReceiptPrintingModeField } from './fields/receipt-printing-mode-field'
import { SalesTaxRenderingModeField } from './fields/sales-tax-rendering-mode-field'
import { ExtraSignatureLinesField } from './fields/extra-signature-lines-field'
import { useAuthorization } from '../../../../../hooks/authorization'

export const Receipt = () => {
  const { t } = useTranslation(['pos_profiles'])
  const { control } = useFormContext<Form>()
  const {
    modules: { settingsSalesTaxes },
  } = useAuthorization()

  return (
    <SettingsListSection title={t('pos_profiles:receipt_section.title')}>
      {/* TODO: Add logotype input */}
      <SettingsListItem>
        <TextFieldInput
          control={control}
          name="receipt_pre"
          multiline
          minRows={3}
          label={t('pos_profiles:receipt_section.receipt_text_top_label')}
          helperText={t(
            'pos_profiles:receipt_section.receipt_text_top_description',
          )}
        />
      </SettingsListItem>
      <SettingsListItem divider>
        <TextFieldInput
          control={control}
          name="receipt_post"
          multiline
          minRows={3}
          label={t('pos_profiles:receipt_section.receipt_text_bottom_label')}
          helperText={t(
            'pos_profiles:receipt_section.receipt_text_bottom_description',
          )}
        />
      </SettingsListItem>
      <SettingsListItem
        divider
        dense
        secondaryAction={
          <SwitchInput name="receipt_showsku" control={control} />
        }
      >
        <ListItemText
          primary={t('pos_profiles:receipt_section.sku_on_receipt_label')}
          secondary={t(
            'pos_profiles:receipt_section.sku_on_receipt_description',
          )}
        />
      </SettingsListItem>
      <SettingsListItem
        divider
        dense
        secondaryAction={
          <SwitchInput name="receipt_addbarcodeid" control={control} />
        }
      >
        <ListItemText
          primary={t('pos_profiles:receipt_section.barcode_on_receipt_label')}
          secondary={t(
            'pos_profiles:receipt_section.barcode_on_receipt_description',
          )}
        />
      </SettingsListItem>
      <ItemRenderingModeField />
      {settingsSalesTaxes && <SalesTaxRenderingModeField />}
      <ReceiptPrintingModeField />
      <GiftPrintingModeField />
      <ExtraSignatureLinesField />
    </SettingsListSection>
  )
}
