import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  ModalProps,
} from '@mui/material'
import { useTranslation } from 'react-i18next'
import { Control, Path, useForm } from 'react-hook-form'
import { DialogForm } from '../dialog-input'

type Props<TValue, TName extends Path<DialogForm<TValue>>> = {
  open: boolean
  defaultValue: TValue[]
  label: string
  onClose: () => void
  onSave: (values: TValue[]) => void
  renderInput: (props: {
    name: TName
    control: Control<DialogForm<TValue>, TName>
  }) => React.ReactNode
}

export const DialogInputDialog = <
  TValue,
  TName extends Path<DialogForm<TValue>>,
>(
  props: Props<TValue, TName>,
) => {
  const { open, onClose, onSave, defaultValue, label, renderInput } = props
  const { t } = useTranslation(['shared'])

  const { control, handleSubmit, reset } = useForm<DialogForm<TValue>>({
    values: { values: defaultValue },
  })

  const handleSave = (data: DialogForm<TValue>) => {
    onSave(data.values)
    onClose()
  }

  const close = () => {
    onClose()
    reset()
  }

  const handleClose: ModalProps['onClose'] = (_event, reason) => {
    if (reason === 'backdropClick') return
    close()
  }

  return (
    <Dialog open={open} onClose={handleClose} fullWidth maxWidth="xs">
      <DialogTitle>{label}</DialogTitle>

      <DialogContent>
        {renderInput({ name: 'values' as TName, control })}
      </DialogContent>

      <DialogActions>
        <Button onClick={close} color="secondary" size="small">
          {t('shared:action.cancel')}
        </Button>
        <Button onClick={() => handleSubmit(handleSave)()} size="small">
          {t('shared:action.ok')}
        </Button>
      </DialogActions>
    </Dialog>
  )
}
