import { ManufacturerInput } from '../report-inputs/manufacturer-input'
import { ProductGroupInput } from '../report-inputs/product-group-input'
import { ReportSortInput } from '../report-inputs/report-sort-input'
import { DateEndPickerInput } from '../report-inputs/date-end-picker-input'
import { WarehouseInput } from '../report-inputs/warehouse-input'

export const StockReportFields = () => {
  return (
    <>
      <ReportSortInput />
      <DateEndPickerInput dateEndKey="stockDateEnd" required />
      <WarehouseInput warehouseFilterKey="warehouseId" />
      <ManufacturerInput />
      <ProductGroupInput />
    </>
  )
}
