import { useContext, useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { FilterContext } from '../components/data-grid/context'
import { InventoryItemBatchState } from '../generated/graphql'
import { useFormFilter } from '../hooks/form-filter'
import { SelectInput } from '../inputs/select-input'
import { SxProps, Theme } from '@mui/material'

export const NotCountedWithValue = 'NotCountedWithValue'

export type WarehouseItemStateFilter = {
  inventoryState?: [InventoryItemBatchState] | typeof NotCountedWithValue
}

type Form = {
  inventoryState?: [InventoryItemBatchState] | typeof NotCountedWithValue | ''
}

type Props = {
  type: 'stocktaking' | 'manual-in' | 'manual-out'
}

const getBadgeStyles = (label: string): SxProps<Theme> => ({
  [`& [data-value=${NotCountedWithValue}]::after`]: {
    content: `"${label}"`,
    display: 'block',
    ml: 1,
    px: 1,
    fontSize: (theme) => theme.typography.caption.fontSize,
    color: (theme) => theme.palette.white,
    background: (theme) => theme.palette.primary.main,
    borderRadius: (theme) => theme.spacing(2),
  },
})

export const WarehouseItemStateFilter = (props: Props) => {
  const { type } = props
  const { t } = useTranslation('filter')

  const filterKey = 'inventoryState'

  const { setFilter, subscribeOnResetFilter, registerFilter } =
    useContext<FilterContext<WarehouseItemStateFilter>>(FilterContext)

  const options = useMemo(() => {
    const options = [
      {
        name: t('warehouse_item_state.AllStatus'),
        value: '',
      },
    ]

    if (type === 'stocktaking') {
      options.push(
        ...[
          {
            name: t('warehouse_item_state.NotCounted'),
            value: JSON.stringify([InventoryItemBatchState.NotCounted]),
          },
          {
            name: t('warehouse_item_state.NotCountedAndStock'),
            value: NotCountedWithValue,
          },
          {
            name: t('warehouse_item_state.CountedOk'),
            value: JSON.stringify([InventoryItemBatchState.CountedOk]),
          },
          {
            name: t('warehouse_item_state.CountedDiff'),
            value: JSON.stringify([InventoryItemBatchState.CountedDiff]),
          },
          {
            name: t('warehouse_item_state.CountedModified'),
            value: JSON.stringify([InventoryItemBatchState.CountedModified]),
          },
          {
            name: t('warehouse_item_state.DiffAndModified'),
            value: JSON.stringify([
              InventoryItemBatchState.CountedDiff,
              InventoryItemBatchState.CountedModified,
            ]),
          },
          {
            name: t('warehouse_item_state.AllCounted'),
            value: JSON.stringify([
              InventoryItemBatchState.CountedOk,
              InventoryItemBatchState.CountedDiff,
              InventoryItemBatchState.CountedModified,
            ]),
          },
        ],
      )
    }

    if (type === 'manual-in') {
      options.push(
        ...[
          {
            name: t('warehouse_item_state.ManualIn'),
            value: InventoryItemBatchState.QuantityAdded,
          },
          {
            name: t('warehouse_item_state.NotIncluded'),
            value: InventoryItemBatchState.QuantityNotAdded,
          },
        ],
      )
    }

    if (type === 'manual-out') {
      options.push(
        ...[
          {
            name: t('warehouse_item_state.ManualOut'),
            value: InventoryItemBatchState.QuantityAdded,
          },
          {
            name: t('warehouse_item_state.NotIncluded'),
            value: InventoryItemBatchState.QuantityNotAdded,
          },
        ],
      )
    }

    return options
  }, [t, type])

  const { queryParams, formContext, resetForm } = useFormFilter<Form>({
    formProps: {
      defaultValues: { inventoryState: '' },
    },
    parseOptions: {
      types: {
        inventoryState: (value) => {
          const states = value.split(',')

          if (value === NotCountedWithValue) {
            return NotCountedWithValue
          }

          return states.find((state) =>
            Object.values(InventoryItemBatchState).some((s) => s === state),
          )
        },
      },
    },
  })

  const { inventoryState } = queryParams

  useEffect(() => {
    registerFilter({ key: filterKey })
  }, [registerFilter])

  useEffect(() => {
    if (!inventoryState) {
      setFilter(filterKey, {
        label: t('warehouse_item_state.label'),
        value: undefined,
        labelValues: [t(`warehouse_item_state.AllStatus`)],
        isDefault: true,
      })
    } else {
      const label =
        options.find(
          ({ value }) =>
            inventoryState === value ||
            value === JSON.stringify(inventoryState),
        )?.name || ''

      setFilter(filterKey, {
        label: t('warehouse_item_state.label'),
        value: inventoryState,
        labelValues: [label],
      })
    }
  }, [options, inventoryState, setFilter, t])

  useEffect(() => {
    const unsubscribe = subscribeOnResetFilter((key) => {
      if (!key || key === filterKey) {
        resetForm()
      }
    })

    return () => unsubscribe()
  }, [resetForm, subscribeOnResetFilter])

  return (
    <SelectInput
      name="inventoryState"
      control={formContext.control}
      options={options}
      label={t('warehouse_item_state.label')}
      slotProps={{
        select: {
          displayEmpty: true,
          MenuProps: {
            MenuListProps: { sx: getBadgeStyles(t('stocktaking:new_badge')) },
          },
        },
      }}
      transform={{
        input: (value) => {
          if (Array.isArray(value) && value.length) {
            return JSON.stringify(value)
          }

          if (value) return value

          return ''
        },
        output: (event) => {
          const value = event.target.value

          if (value === NotCountedWithValue) {
            return value
          }

          if (value) {
            return JSON.parse(event.target.value)
          }

          return value
        },
      }}
    />
  )
}
