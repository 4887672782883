import { Menu, MenuItem, Typography } from '@mui/material'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { RootRoute } from '../..'
import { useFilterContext } from '../../../components/data-grid/hooks/use-filter-context'
import { useAbsolutePath } from '../../../hooks/absolute-path'
import { CancelStocktakingDialog } from './cancel-stocktaking-dialog'
import { ImportStocktakingDialog } from './import-stocktaking-dialog'
import { ExportStockDialog } from './export-stocktaking-dialog'
import { AdjustTransactionsDialog } from './adjust-transactions-dialog'

type Props = {
  anchorEl: Element | null
  isOpen: boolean
  onClose(): void
  warehouseId: number
  warehouseBatchId: number
}

export const StocktakingContextMenu = (props: Props) => {
  const { warehouseId, warehouseBatchId, anchorEl, onClose, isOpen } = props
  const [openCancelDialog, setOpenCancelDialog] = useState(false)
  const [openImportDialog, setOpenImportDialog] = useState(false)
  const [openExportDialog, setOpenExportDialog] = useState(false)
  const [openAdjustDialog, setOpenAdjustDialog] = useState(false)

  const navigate = useNavigate()
  const generatePath = useAbsolutePath()
  const { t } = useTranslation(['shared', 'stocktaking', 'products'])
  const filterContext = useFilterContext()

  return (
    <>
      <Menu
        id="stocktaking-more-menu"
        anchorEl={anchorEl}
        open={isOpen}
        onClose={onClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        slotProps={{
          list: {
            'aria-labelledby': 'stocktaking-more-menu-button',
          },
        }}
      >
        <MenuItem
          onClick={() => {
            setOpenImportDialog(true)
            onClose()
          }}
          data-testid="stocktaking-more-menu-import-items"
        >
          <Typography variant="body02">
            {t('stocktaking:import_items')}
          </Typography>
        </MenuItem>

        <MenuItem
          onClick={() => {
            setOpenExportDialog(true)
            onClose()
          }}
          data-testid="stocktaking-more-menu-import-items"
        >
          <Typography variant="body02">
            {t('stocktaking:export_filtered')}
          </Typography>
        </MenuItem>

        <MenuItem
          onClick={() => {
            setOpenAdjustDialog(true)
            onClose()
          }}
        >
          <Typography variant="body02">
            {t('stocktaking:adjust_transactions')}
          </Typography>
        </MenuItem>

        <MenuItem
          onClick={() => {
            setOpenCancelDialog(true)
            onClose()
          }}
          data-testid="stocktaking-more-menu-cancel"
        >
          <Typography variant="body02">
            {t('stocktaking:cancel_stocktaking')}
          </Typography>
        </MenuItem>
      </Menu>

      <ImportStocktakingDialog
        warehouseId={warehouseId}
        warehouseBatchId={warehouseBatchId}
        open={openImportDialog}
        onClose={() => setOpenImportDialog(false)}
        onSuccess={() => filterContext.refetch()}
      />

      <CancelStocktakingDialog
        warehouseId={warehouseId}
        warehouseBatchId={warehouseBatchId}
        open={openCancelDialog}
        onClose={() => setOpenCancelDialog(false)}
        onSuccess={() => {
          void navigate(generatePath(RootRoute.Stocktakings))
          setOpenCancelDialog(false)
        }}
      />

      <ExportStockDialog
        warehouseId={warehouseId}
        warehouseBatchId={warehouseBatchId}
        open={openExportDialog}
        onClose={() => setOpenExportDialog(false)}
      />

      <AdjustTransactionsDialog
        open={openAdjustDialog}
        onClose={() => setOpenAdjustDialog(false)}
        warehouseId={warehouseId}
        warehouseBatchId={warehouseBatchId}
      />
    </>
  )
}
