import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Divider,
  List,
  ListItem,
  ListItemAvatar,
  ListItemIcon,
  ListItemText,
} from '@mui/material'
import {
  PackageIcon,
  PackageWarningIcon,
  SectionHeader,
} from '@sitoo/mui-components'
import { useSnackbar } from 'notistack'
import { Fragment, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { DataGridImage } from '../../../components/data-grid-image'
import { ListItemAccordion } from '../../../components/list-item-accordion'
import { ListItemSkeleton } from '../../../components/list-item-skeleton'
import { GetShipmentV2Query, ShipmentStateV2 } from '../../../generated/graphql'
import { SetReceivedPackageItemsDialog } from '../../shipment-v2/set-received-package-items-dialog'
import { ReceivedItemChip } from './received-item-chip'
import { ShipmentV2Package } from '../../shipment-v2/shared'

type PackageIconProps = {
  shipmentPackage: ShipmentV2Package
  shipmentState: GetShipmentV2Query['getShipmentV2']['shipmentstate']
}

export const PackageItemIcon = (props: PackageIconProps) => {
  const { totalReceivedQuantity, totalQuantity } = props.shipmentPackage

  if (
    Number(totalReceivedQuantity) &&
    Number(totalReceivedQuantity) < Number(totalQuantity)
  ) {
    return <PackageWarningIcon color="disabled" />
  }
  return <PackageIcon />
}

type Props = {
  isLoading?: boolean
  shipment?: GetShipmentV2Query['getShipmentV2']
  allItemsReceived: boolean
}

export const Packages = (props: Props) => {
  const { t } = useTranslation(['shared', 'shipments_v2'])
  const { shipment, isLoading } = props
  const { enqueueSnackbar } = useSnackbar()

  const shipmentPackages = shipment?.packages || []

  const [isDialogOpen, setDialogOpen] = useState(false)

  const [selectedPackageId, setSelectedPackageId] = useState<number>()

  if (!shipment?.packages?.length) {
    return null
  }

  return (
    <Accordion
      defaultExpanded
      className="MuiAccordionRoot"
      data-testid="view-panel-packages-section"
    >
      <AccordionSummary aria-controls="information">
        <SectionHeader sx={{ p: 0 }}>
          {t('shipments_v2:packages.title')}
        </SectionHeader>
      </AccordionSummary>
      <AccordionDetails sx={{ p: 0 }}>
        <List>
          {props.isLoading && (
            <ListItemSkeleton
              isLoading={isLoading}
              secondaryAction="-"
              childrenSkeleton
            />
          )}
          {shipmentPackages.map((shipmentPackage, index) => {
            return (
              <Fragment key={shipmentPackage.shipmentpackageid}>
                <ListItemAccordion
                  data-id={shipmentPackage.shipmentpackageid}
                  aria-controls="package"
                  summary={
                    <>
                      <ListItemSkeleton
                        secondaryAction={
                          !!shipmentPackage.totalReceivedQuantity && (
                            <ListItemText>
                              <ReceivedItemChip
                                label={t(
                                  'shipments_v2:packages.received_title',
                                )}
                                quantity={shipmentPackage.totalQuantity || 0}
                                quantityReceived={
                                  shipmentPackage.totalReceivedQuantity || 0
                                }
                              />
                            </ListItemText>
                          )
                        }
                        isLoading={props.isLoading}
                      >
                        <ListItemIcon>
                          <PackageItemIcon
                            shipmentPackage={shipmentPackage}
                            shipmentState={shipment.shipmentstate}
                          />
                        </ListItemIcon>
                        <ListItemText
                          primary={t('shipments_v2:packages.package_title', {
                            property: shipmentPackage.shipmentpackageid,
                          })}
                          slotProps={{
                            secondary: { component: Box },
                          }}
                          secondary={
                            <Box>
                              {t('shipments_v2:package_items', {
                                count: shipmentPackage.totalQuantity || 0,
                              })}
                            </Box>
                          }
                        />
                      </ListItemSkeleton>
                    </>
                  }
                >
                  <List>
                    {shipmentPackage.items?.map((item) => {
                      const itemQty = Number(item.quantity)
                      const itemQtyReceived = Number(item.quantityreceived)
                      return (
                        <ListItem
                          key={item.shipmentitemid}
                          secondaryAction={
                            <ReceivedItemChip
                              label={t(
                                'shipments_v2:packages.package_received_item',
                                {
                                  count: itemQtyReceived,
                                  total: itemQty,
                                },
                              )}
                              quantity={itemQty}
                              quantityReceived={itemQtyReceived}
                            />
                          }
                        >
                          <ListItemAvatar>
                            <DataGridImage src={item.product?.images?.at(0)} />
                          </ListItemAvatar>
                          <ListItemText
                            primary={item.productname}
                            secondary={item.sku}
                          />
                        </ListItem>
                      )
                    })}
                  </List>
                </ListItemAccordion>

                {ShipmentStateV2.InTransit === shipment.shipmentstate &&
                  shipmentPackage.totalReceivedQuantity !==
                    shipmentPackage.totalQuantity && (
                    <ListItemSkeleton sx={{ justifyContent: 'end' }}>
                      <Button
                        size="small"
                        color="secondary"
                        fullWidth
                        onClick={() => {
                          setSelectedPackageId(
                            Number(shipmentPackage.shipmentpackageid),
                          )
                          setDialogOpen(true)
                        }}
                      >
                        {t('shipments_v2:view_panel.receive_items')}
                      </Button>
                    </ListItemSkeleton>
                  )}

                {shipment.packages && index + 1 < shipment.packages?.length && (
                  <Divider />
                )}
              </Fragment>
            )
          })}
        </List>

        <SetReceivedPackageItemsDialog
          open={isDialogOpen}
          shipment={shipment}
          packageId={selectedPackageId}
          onClose={() => setDialogOpen(false)}
          onSuccess={() => {
            enqueueSnackbar(t('shipments_v2:shipment_message.success_update'))
            setDialogOpen(false)
          }}
          onError={(errorMessage) => {
            enqueueSnackbar(errorMessage, { variant: 'error' })
            setDialogOpen(false)
          }}
        />
      </AccordionDetails>
    </Accordion>
  )
}
