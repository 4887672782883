import { useMemo, useState } from 'react'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  ListItemText,
} from '@mui/material'
import { useForm, useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import yaml from 'js-yaml'
import { stripTypename } from '@apollo/client/utilities'

import { SettingsListItem } from '../../../../components/settings-list-item'
import { SettingsListSection } from '../../../../components/settings-list-section'
import { GeneralSettingsFormContext } from '../general-settings-form'
import { useQuery } from '@apollo/client'
import { ThemesDocument } from '../../../../generated/graphql'
import { enqueueSnackbar } from 'notistack'
import { SelectInput } from '../../../../inputs/select-input'
import { YamlInput } from '../../../../inputs/yaml-input'
import { useTracking } from '../../../../hooks/tracking'

const isValidConfig = (value: unknown): value is Record<string, unknown> => {
  return typeof value === 'object' || value === null || value === undefined
}

export const PosTheme = () => {
  const settingsFormContext = useFormContext<GeneralSettingsFormContext>()
  const { t } = useTranslation(['settings', 'shared'])
  const [open, setOpen] = useState(false)
  const { data: themesData } = useQuery(ThemesDocument)
  const { trackDialogOpen, trackDialogClose } = useTracking()

  const defaultValues = useMemo(() => {
    const params =
      themesData?.themes?.find(
        (theme) => theme.id === settingsFormContext.getValues('pos_theme_id'),
      )?.params || []

    return params.reduce<Record<string, string>>((acc, param) => {
      if (param.type === 'Sku') {
        acc[param.id] = ''
      }
      return acc
    }, {})
  }, [settingsFormContext, themesData?.themes])

  const convertToYaml = () => {
    const currentValue = settingsFormContext.getValues('pos_theme_data')
    try {
      const sanitizedValue = currentValue ? stripTypename(currentValue) : {}
      const mergedThemeData = { ...defaultValues, ...sanitizedValue }
      const hasValues = Object.keys(mergedThemeData).length > 0
      const yamlString = hasValues ? yaml.dump(mergedThemeData) : ''

      return yamlString
    } catch (error) {
      enqueueSnackbar(t('settings:general.theme.failed_to_convert'), {
        variant: 'error',
      })
      return
    }
  }

  const {
    control: posUiControl,
    handleSubmit,
    reset,
    clearErrors,
  } = useForm<{
    rawYaml: string
  }>({
    defaultValues: {
      rawYaml: convertToYaml() || '',
    },
  })

  const handleOpenDialog = () => {
    const currentYaml = convertToYaml() || ''

    reset({
      rawYaml: currentYaml,
    })
    clearErrors()

    setOpen(true)
    trackDialogOpen({ name: 'setting-theme-dialog' })
  }

  const updatePosTheme = (values: { rawYaml: string }) => {
    const parsedYaml = yaml.load(values.rawYaml)
    if (isValidConfig(parsedYaml)) {
      settingsFormContext.setValue('pos_theme_data', parsedYaml || null, {
        shouldDirty: true,
      })
    }
    setOpen(false)
    trackDialogClose({
      name: 'settings-theme-dialog',
      reason: 'save',
      value: values.rawYaml,
    })
  }

  const themeOptions = [
    {
      value: null,
      name: t('settings:general.theme.select_default_theme'),
    },
    ...(themesData?.themes?.map((theme) => ({
      value: theme.id,
      name: theme.name,
    })) || []),
  ]

  return (
    <SettingsListSection title={t('settings:general.theme.title')}>
      <SettingsListItem>
        <SelectInput
          name="pos_theme_id"
          control={settingsFormContext.control}
          options={themeOptions}
          label={t('settings:general.theme.pos_theme_id_label')}
          helperText={t('settings:general.theme.pos_theme_id_helper')}
          slotProps={{ select: { displayEmpty: true } }}
        />
      </SettingsListItem>
      <SettingsListItem
        dense
        secondaryAction={
          <Button
            color="secondary"
            size="small"
            onClick={handleOpenDialog}
            aria-label={`${t('shared:action.edit')} ${t('settings:general.theme.pos_theme_data_label')}`}
          >
            {t('shared:action.edit')}
          </Button>
        }
      >
        <ListItemText
          primary={t('settings:general.theme.pos_theme_data_label')}
          secondary={t('settings:general.theme.pos_theme_data_helper')}
        />
        <Dialog
          open={open}
          onClose={(_event, reason) => {
            if (reason === 'backdropClick') return
            setOpen(false)
          }}
          fullWidth
          maxWidth="xs"
        >
          <form
            onSubmit={(e) => {
              e.stopPropagation()
              e.preventDefault()

              return handleSubmit(updatePosTheme)()
            }}
          >
            <DialogTitle>
              {t('settings:general.theme.pos_theme_data_label')}
            </DialogTitle>
            <DialogContent>
              <YamlInput
                control={posUiControl}
                name="rawYaml"
                rules={{
                  validateParsed: (parsedYaml) => isValidConfig(parsedYaml),
                }}
                helperText={t('settings:general.theme.pos_theme_data_helper')}
              />
            </DialogContent>
            <DialogActions>
              <Button
                onClick={() => setOpen(false)}
                color="secondary"
                size="small"
              >
                {t('shared:action.cancel')}
              </Button>
              <Button size="small" type="submit">
                {t('shared:action.ok')}
              </Button>
            </DialogActions>
          </form>
        </Dialog>
      </SettingsListItem>
    </SettingsListSection>
  )
}
