import { useQuery } from '@apollo/client'
import { t } from 'i18next'
import { useFormContext, useWatch } from 'react-hook-form'
import { Form } from '../..'
import { SettingsListItem } from '../../../../../components/settings-list-item'
import { SettingsListSection } from '../../../../../components/settings-list-section'
import {
  GetConfigVarsDocument,
  PosProfileTaxFreeOptionsEnum,
} from '../../../../../generated/graphql'
import { DialogInput } from '../../../../../inputs/dialog-input'
import { SelectInput } from '../../../../../inputs/select-input'
import { ListItemBorderBox } from '../../../../../components/list-item-border-box'

export const TaxFree = () => {
  const { control, setValue, getValues } = useFormContext<Form>()
  const { data } = useQuery(GetConfigVarsDocument)

  const limitTaxFreeOptions = useWatch({
    control,
    name: 'limitTaxFreeOptions',
  })

  const { taxFreeExport, taxFreeDutyFree, taxFreeTaxRefund } =
    data?.configVars || {}

  const limitTaxFreeFeatureOptions = Object.entries({
    [PosProfileTaxFreeOptionsEnum.TaxFreeExport]: taxFreeExport,
    [PosProfileTaxFreeOptionsEnum.DutyFree]: taxFreeDutyFree,
    [PosProfileTaxFreeOptionsEnum.TaxRefund]: taxFreeTaxRefund,
  })
    .filter(([_, isEnabled]) => Boolean(isEnabled))
    .map(([option]) => option)

  if (!taxFreeExport && !taxFreeDutyFree && !taxFreeTaxRefund) return null

  return (
    <SettingsListSection title={t('pos_profiles:tax_free_section.title')}>
      <SettingsListItem>
        <SelectInput
          label={t('pos_profiles:tax_free_section.tax_free_options_label')}
          helperText={t(
            'pos_profiles:tax_free_section.tax_free_options_description',
          )}
          name="limitTaxFreeOptions"
          control={control}
          options={[
            {
              name: t('pos_profiles:tax_free_section.allow_all'),
              value: false,
            },
            {
              name: t('pos_profiles:tax_free_section.limit'),
              value: true,
            },
          ]}
          onChange={(event) => {
            if (event.target.value) {
              setValue(
                'tax_free_options',
                getValues('tax_free_options') || [],
                {
                  shouldDirty: true,
                },
              )
            }
          }}
        />
      </SettingsListItem>

      {limitTaxFreeOptions && (
        <ListItemBorderBox>
          <DialogInput
            name="tax_free_options"
            control={control}
            options={limitTaxFreeFeatureOptions}
            formatValue={(value) => ({
              value: value,
              label: t(
                `pos_profiles:tax_free_section.tax_free_feature_options.${value}`,
              ),
            })}
            addLabel={t('pos_profiles:tax_free_section.add_option')}
            itemLabel={t(
              'pos_profiles:tax_free_section.tax_free_selection_title',
            )}
            emptyMessage={t(
              'pos_profiles:tax_free_section.no_options_selected_text',
            )}
          />
        </ListItemBorderBox>
      )}
    </SettingsListSection>
  )
}
