import { useMemo } from 'react'
import { FieldValues, FieldPath, UseControllerProps } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { AutocompleteInput } from '../autocomplete-input'
import { SxProps, TextFieldProps, Theme } from '@mui/material'
import { getValidTimeZones } from '../../utils/timezones'

type Props<
  TFieldValues extends FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
> = UseControllerProps<TFieldValues, TName> & {
  label?: string
  helperText?: string
  sx?: SxProps<Theme>
  textFieldProps?: Omit<TextFieldProps, 'name' | 'required' | 'label'>
  rules?: UseControllerProps<TFieldValues, TName>['rules']
  required?: boolean
}

export const TimeZonesInput = <TFieldValues extends FieldValues>(
  props: Props<TFieldValues>,
) => {
  const { name, control, textFieldProps, ...autocompleteProps } = props
  const { t } = useTranslation('filter')

  const options = useMemo(() => {
    const validTimeZones = getValidTimeZones().map((timeZone) => ({
      label: timeZone,
      value: timeZone,
    }))

    return validTimeZones
  }, [])

  return (
    <AutocompleteInput
      name={name}
      control={control}
      {...autocompleteProps}
      options={options.map((option) => option.value)}
      textFieldProps={{
        helperText: t('shared:time_zones.time_zone_description'),
        ...textFieldProps,
      }}
      autocompleteProps={{
        getOptionKey: (option) => option,
        getOptionLabel: (id) => {
          return options.find((option) => option.value === id)?.label || ''
        },
      }}
    />
  )
}
