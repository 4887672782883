import type { TextFieldProps } from '@mui/material'
import { IconButton, TextField } from '@mui/material'
import { EyeIcon, EyeSlashIcon } from '@sitoo/mui-components'
import { forwardRef, useState } from 'react'
import { useTranslation } from 'react-i18next'

export const PasswordField = forwardRef<HTMLInputElement, TextFieldProps>(
  function PasswordField(props: TextFieldProps, ref) {
    const [showPassword, setShowPassword] = useState(false)
    const { t } = useTranslation(['shared'])

    return (
      <TextField
        type={showPassword ? 'text' : 'password'}
        label={t('shared:label.password')}
        slotProps={{
          input: {
            endAdornment: (
              <IconButton
                type="button"
                sx={{
                  pointerEvents: 'auto',
                  color: 'inherit',
                }}
                onClick={() => setShowPassword(!showPassword)}
              >
                {showPassword ? (
                  <EyeSlashIcon fontSize="medium" />
                ) : (
                  <EyeIcon fontSize="medium" />
                )}
              </IconButton>
            ),
          },
        }}
        {...props}
        inputRef={ref}
      />
    )
  },
)
