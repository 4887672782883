import {
  Box,
  IconButton,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material'
import { DeleteIcon, DragHandleIcon } from '@sitoo/mui-components'
import { enqueueSnackbar } from 'notistack'
import { FieldPath, useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { DataGridImage } from '../../../components/data-grid-image'
import { SearchProductField } from '../../../components/search-product-field'
import {
  GetProductQuery,
  ProductFieldsFragment,
} from '../../../generated/graphql'
import { useAuthorization } from '../../../hooks/authorization'
import { useDrag } from '../../../hooks/drag'
import { useTracking } from '../../../hooks/tracking'

type Props = {
  isLoading?: boolean
  field: 'related' | 'accessories' | 'similar'
}

export const LinkedProducts = (props: Props) => {
  const productForm = useFormContext<GetProductQuery>()
  const { trackButtonClick, trackDragEnd, trackDragStart } = useTracking()
  const { t } = useTranslation(['products', 'shared'])
  const { onDragEnd, onDragOver, onDragStart, dragId } = useDrag()

  const {
    modules: { writeProducts },
  } = useAuthorization()

  const productsField: FieldPath<GetProductQuery> = `product.${props.field}Products`
  const productIdsField: FieldPath<GetProductQuery> = `product.${props.field}`
  const currentProducts = productForm.getValues(productsField) || []

  const onReOrderProduct = (newList: typeof currentProducts) => {
    productForm.setValue(productsField, newList)
    productForm.setValue(
      productIdsField,
      newList.map((x) => x.id),
      {
        shouldDirty: true,
      },
    )
  }

  const onDeleteProduct = (id: number) => {
    trackButtonClick({
      name: `related-products-delete-${props.field}-product`,

      productId: id,
      productSku: currentProducts.find((x) => x.id === id)?.sku,
    })

    const newProduct = currentProducts.filter((x) => x.id !== id)
    productForm.setValue(productsField, newProduct)
    productForm.setValue(
      productIdsField,
      newProduct.map((x) => x.id),
      {
        shouldDirty: true,
      },
    )
  }

  const handleOnChange = (product: ProductFieldsFragment) => {
    if (
      currentProducts.find((x) => x.sku.toLocaleUpperCase() === product.sku)
    ) {
      enqueueSnackbar(t('products:error.sku_already_added'), {
        variant: 'error',
      })

      return
    }

    trackButtonClick({
      name: `related-products-add-${props.field}-product`,
      productSku: product.sku,
      productId: product.id,
    })
    currentProducts.push(product)
    productForm.setValue(productsField, currentProducts)
    productForm.setValue(
      productIdsField,
      currentProducts.map((x) => x.id),
      {
        shouldDirty: true,
      },
    )
  }

  return (
    <>
      {writeProducts && (
        <Box
          sx={{
            display: 'flex',
            gap: (theme) => theme.spacing(1),
            marginBottom: (theme) => theme.spacing(2),
          }}
        >
          <SearchProductField
            sx={{ width: '100%' }}
            onChange={handleOnChange}
            data-testid={`${props.field}-search-product-input`}
          />
        </Box>
      )}
      <Box
        sx={{
          margin: (theme) =>
            !writeProducts ? theme.spacing(-2) : theme.spacing(0, -2, -2, -2),
        }}
      >
        {currentProducts.map((product, index) => (
          <ListItem
            key={product.id}
            divider={index + 1 !== currentProducts.length}
            data-testid={`${props.field}-product-item-${product.sku}`}
            data-index={index}
            onDragStart={(ev) => {
              trackDragStart({
                name: `related-products-${props.field}-product`,

                position: index,
                productId: product.id,
                productSku: product.sku,
              })
              const target = ev.currentTarget
              target.classList.add('show-helper')
              setTimeout(() => {
                target.classList.remove('show-helper')
                onDragStart(product.id)
              })
            }}
            onDragOver={() =>
              onDragOver(index, currentProducts, 'id', onReOrderProduct)
            }
            onDragEnd={() => {
              trackDragEnd({
                name: `related-products-${props.field}-product`,

                position: index,
                productId: product.id,
                productSku: product.sku,
              })
              onDragEnd()
            }}
            className={dragId === product.id ? 'dragging' : ''}
            sx={{
              '&.dragging': {
                backgroundColor: (theme) => theme.palette.blue20,
              },
              '&.show-helper': {
                '.drag-helper': {
                  display: 'block',
                  marginRight: (theme) => theme.spacing(1),
                },
              },

              '.drag-helper': {
                display: 'none',
              },
            }}
            secondaryAction={
              <>
                {writeProducts && (
                  <Stack direction={'row'} gap={1}>
                    <IconButton
                      onClick={() => onDeleteProduct(product.id)}
                      data-testid={`${props.field}-product-delete-${product.sku}`}
                    >
                      <DeleteIcon />
                    </IconButton>
                    <Box
                      sx={{
                        color: (theme) => theme.palette.gray60,
                        cursor: 'move',
                        display: 'flex',
                        alignItems: 'center',
                      }}
                      draggable
                      data-testid={`${props.field}-product-drag-${product.sku}`}
                    >
                      <DragHandleIcon />
                      <Typography variant="body02" className="drag-helper">
                        {product.title}
                      </Typography>
                    </Box>
                  </Stack>
                )}
              </>
            }
          >
            <ListItemAvatar>
              <DataGridImage
                src={
                  product.productImages?.[0]
                    ? product.productImages[0].fileUrl
                    : ''
                }
              />
            </ListItemAvatar>
            <ListItemText
              data-testid={`${props.field}-product-text-${product.sku}`}
              secondary={
                <Tooltip placement="bottom-start" title={product?.sku}>
                  <span>{product?.sku}</span>
                </Tooltip>
              }
              slotProps={{
                primary: {
                  sx: {
                    display: '-webkit-box',
                    WebkitLineClamp: 2,
                    WebkitBoxOrient: 'vertical',
                  },
                },

                secondary: {
                  whiteSpace: 'nowrap',
                },
              }}
            >
              <Tooltip title={product?.title} placement="top-start">
                <span>{product?.title}</span>
              </Tooltip>
            </ListItemText>
          </ListItem>
        ))}

        {!currentProducts.length && !writeProducts && (
          <ListItem sx={{ justifyContent: 'center' }}>
            <Typography color="textSecondary">
              {t(`products:product_form.no_${props.field}_products_added`)}
            </Typography>
          </ListItem>
        )}
      </Box>
    </>
  )
}
