import { useMutation, useQuery } from '@apollo/client'
import { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import { RootRoute } from '../..'
import { CurrencyInput } from '../../../inputs/currency-input'
import { PriceListTagsInput } from '../../../inputs/price-list-tags-input'
import { SettingsDetailDialog } from '../../../components/settings-detail-dialog'
import { SwitchInput } from '../../../inputs/switch-input'
import { TextFieldInput } from '../../../inputs/text-field-input'
import {
  AllPriceListTagsDocument,
  DeletePriceListDocument,
  GetPriceListDocument,
  GetPriceListsDocument,
  PriceList,
  UpdatePriceListDocument,
} from '../../../generated/graphql'
import { useAbsolutePath } from '../../../hooks/absolute-path'
import { useAuthorization } from '../../../hooks/authorization'
import { DEFAULT_PRICELIST_ID } from '../../../utils/constants'

export const PriceListDialog = () => {
  const { t } = useTranslation(['shared', 'settings'])

  const { id } = useParams()
  const priceListId = Number(id)
  const isNewPriceList = !priceListId
  const isDefaultPriceList = priceListId === DEFAULT_PRICELIST_ID

  const navigate = useNavigate()
  const generatePath = useAbsolutePath()

  const { writeSettingsPriceList } = useAuthorization().modules

  const isCurrencyFieldDisabled = !writeSettingsPriceList || !isNewPriceList

  const { reset, control, getValues, handleSubmit, formState } =
    useForm<PriceList>({
      defaultValues: {
        active: true,
        pricelistname: '',
        externalid: '',
        pricelistdescription: '',
        currencycode: 'SEK',
        tags: [],
      },
    })

  const [updatePriceList, { loading: updateLoading }] = useMutation(
    UpdatePriceListDocument,
    { refetchQueries: [AllPriceListTagsDocument, GetPriceListsDocument] },
  )

  const { data, loading: fetchLoading } = useQuery(GetPriceListDocument, {
    variables: { id: Number(priceListId) },
    fetchPolicy: 'cache-and-network',
    skip: isNewPriceList,
  })

  const [deletePriceList, { loading: deleteLoading }] = useMutation(
    DeletePriceListDocument,
    { refetchQueries: [GetPriceListsDocument] },
  )

  const isLoading = fetchLoading || updateLoading || deleteLoading

  const onSubmit = async (data: PriceList) => {
    const { id, active, ...fields } = data

    await updatePriceList({
      variables: {
        priceList: {
          pricelistid: id,
          active: isDefaultPriceList ? undefined : active,
          ...fields,
        },
      },
    })
  }

  const deleteItem = async () => {
    if (priceListId) {
      await deletePriceList({ variables: { id: priceListId } })
    }
  }

  useEffect(() => {
    if (!priceListId) reset()

    if (data?.priceList) {
      reset({ ...data.priceList, externalid: data.priceList.externalid || '' })
    }
  }, [data, reset, priceListId])

  const onClose = () => {
    void navigate(generatePath(RootRoute.SettingsPriceLists), {
      state: { ignoreLeavingGuard: true },
    })
  }

  return (
    <SettingsDetailDialog
      type={isNewPriceList ? 'add' : 'edit'}
      dialogName="price-list"
      typeLabel={t('settings:price_lists.item_type')}
      getItemName={() => getValues('pricelistname')}
      onSave={onSubmit}
      onDelete={deleteItem}
      onClose={onClose}
      isLoading={isLoading}
      handleSubmit={handleSubmit}
      formState={formState}
    >
      <SwitchInput
        name="active"
        control={control}
        data-testid="pricelist-active"
        label={t('settings:price_lists.active')}
        slotProps={{ typography: { fontWeight: 'medium' } }}
        sx={{
          width: '100%',
          justifyContent: 'space-between',
          m: (theme) => theme.spacing(2, 0),
        }}
        disabled={!writeSettingsPriceList || isDefaultPriceList}
      />

      <TextFieldInput
        name="pricelistname"
        control={control}
        required
        label={t('settings:price_lists.name')}
        disabled={!writeSettingsPriceList}
        sx={{ mb: 2 }}
        slotProps={{
          htmlInput: { 'data-testid': 'pricelist-name' },
        }}
      />

      <TextFieldInput
        name="externalid"
        control={control}
        label={t('settings:price_lists.external_id')}
        disabled={!writeSettingsPriceList}
        sx={{ mb: 2 }}
        slotProps={{
          htmlInput: { 'data-testid': 'external-id' },
        }}
      />

      <TextFieldInput
        name="pricelistdescription"
        control={control}
        multiline
        rows={4}
        label={t('settings:price_lists.description')}
        disabled={!writeSettingsPriceList}
        sx={{ mb: 2 }}
        slotProps={{
          htmlInput: { 'data-testid': 'description' },
        }}
      />

      <CurrencyInput
        name="currencycode"
        control={control}
        label={t('settings:price_lists.currency')}
        disabled={isCurrencyFieldDisabled}
        sx={{ mb: 2 }}
      />

      <PriceListTagsInput
        name="tags"
        control={control}
        label={t('settings:price_lists.tags')}
        sx={{ mb: 2 }}
      />
    </SettingsDetailDialog>
  )
}
