import { Menu, MenuItem } from '@mui/material'
import { t } from 'i18next'
import { useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { RootRoute } from '../..'
import { MoreButton } from '../../../components/more-button'
import { useAbsolutePath } from '../../../hooks/absolute-path'
import { useAuthorization } from '../../../hooks/authorization'
type Props = {
  showDeleteDialog: () => void
  showExportDialog: () => void
  showRenameDialog: () => void
}
export const MoreMenu = (props: Props) => {
  const navigate = useNavigate()
  const generatePath = useAbsolutePath()
  const params = useParams<{ id: string }>()
  const reportJobId = Number(params.id)

  const {
    modules: { reportsFullPermission },
  } = useAuthorization()

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)

  const handleMoreButtonClick = (
    event: React.MouseEvent<HTMLButtonElement>,
  ) => {
    setAnchorEl(event.currentTarget)
  }

  const handleMoreMenuClose = () => setAnchorEl(null)

  return (
    <>
      <MoreButton data-testid="more-button" onClick={handleMoreButtonClick} />

      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClick={handleMoreMenuClose}
        onClose={handleMoreMenuClose}
      >
        <MenuItem onClick={props.showRenameDialog}>
          {t('reports:rename')}
        </MenuItem>

        {reportsFullPermission && (
          <MenuItem
            onClick={() => {
              void navigate(
                generatePath(RootRoute.ReportTemplateSave, { id: reportJobId }),
              )
            }}
          >
            {t('reports:save_as_template')}
          </MenuItem>
        )}

        <MenuItem onClick={props.showExportDialog}>
          {t('reports:export')}
        </MenuItem>

        <MenuItem
          onClick={props.showDeleteDialog}
          data-testid="delete-report-button"
        >
          {t('reports:delete_report')}
        </MenuItem>
      </Menu>
    </>
  )
}
