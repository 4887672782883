import { useCallback, useContext, useMemo } from 'react'
import {
  GridColDef,
  GridRenderCellParams,
  useGridApiRef,
} from '@mui/x-data-grid-pro'
import { useSnackbar } from 'notistack'
import { useTranslation } from 'react-i18next'
import {
  GetWarehouseBatchItemsQueryVariables,
  DeleteWarehouseBatchItemsDocument,
  GetWarehouseBatchItemsDocument,
  UpdateWarehouseBatchItemsDocument,
  GetWarehouseBatchItemsQuery,
} from '../../../../generated/graphql'
import { useTracking } from '../../../../hooks/tracking'
import { ArrayElement } from '../../../../utils/types'
import { DataGridImageRenderer } from '../../../../components/data-grid-image'
import { ProductStateRenderer } from '../../../products/product-list/product-state'
import { StockRenderer } from '../../../../components/data-grid/utils/stock-renderer'
import {
  StringEditRenderer,
  StringRenderer,
} from '../../../../components/data-grid/utils/string-renderer'
import { DataGrid } from '../../../../components/data-grid'
import { FolderIcon } from '@sitoo/mui-components'
import { useAuthorization } from '../../../../hooks/authorization'
import {
  CaptionRenderer,
  CaptionRendererValue,
} from '../../../../components/data-grid/utils/caption-renderer'
import { ColumnProps } from '../../../../components/data-grid/utils/column-props'
import { useMutation, useQuery } from '@apollo/client'
import { FilterContext } from '../../../../components/data-grid/context'
import { CursorPagination } from '../../../../components/data-grid/cursor-pagination'

type Row = ArrayElement<
  GetWarehouseBatchItemsQuery['warehouseBatchItems']['items']
>

type Props = {
  warehouseId: number
  warehouseBatchId: number
}

const PAGE_SIZE = 100
export const OpenDeliveryOutList = (props: Props) => {
  const { t } = useTranslation(['shared', 'delivery_out', 'products'])
  const { warehouseId, warehouseBatchId } = props
  const { trackFormSuccess, trackFormError } = useTracking()
  const { enqueueSnackbar } = useSnackbar()
  const {
    modules: { writeDeliveryOut },
  } = useAuthorization()

  const apiRef = useGridApiRef()

  const [updateWarehouseBatchItems] = useMutation(
    UpdateWarehouseBatchItemsDocument,
  )
  const [deleteWarehouseBatchItems] = useMutation(
    DeleteWarehouseBatchItemsDocument,
  )

  const dataGridColumns: GridColDef<Row>[] = [
    {
      field: 'product-images',
      ...ColumnProps.image,
      headerName: '',
      valueGetter: (_value, row): string => row.product?.images?.[0] || '',
      cellClassName: 'image-column',
      headerClassName: 'image-column',
      renderCell: DataGridImageRenderer,
    },
    {
      field: 'product-id',
      ...ColumnProps.productId,
      headerName: t('delivery_out:product_id'),
      valueGetter: (_value, row) => row.product?.id || '',
    },
    {
      field: 'sku',
      ...ColumnProps.sku,
      headerName: t('delivery_out:sku'),
    },
    {
      field: 'product-title',
      ...ColumnProps.productTitle,
      headerName: t('delivery_out:product'),
      valueGetter: (_value, row): CaptionRendererValue => ({
        title: row.product?.title,
        caption: row.product?.variant?.[0]?.value,
      }),
      renderCell: CaptionRenderer,
    },
    {
      field: 'product-activepos',
      ...ColumnProps.productActive,
      headerName: t('products:status'),
      renderCell: (params) =>
        ProductStateRenderer({
          row: { activepos: params.row.product?.activepos || null },
        }),
    },
    {
      field: 'decimaltotal',
      minWidth: 120,
      headerName: t('delivery_out:stock_label'),
      renderCell: StockRenderer,
      valueGetter: (_value, row) => {
        const total = row.product?.warehouseItems?.find(
          (x) => x.warehouse?.id === warehouseId,
        )?.decimaltotal
        return Number(total || 0)
      },
    },
    {
      field: 'decimalquantity',
      minWidth: 120,
      headerName: t('delivery_out:quantity_label'),
      editable: writeDeliveryOut,
      valueGetter: (_value, row): string | undefined => {
        const quantity = row.decimalquantity
        return quantity ? String(Number(quantity)) : undefined
      },
      valueSetter: (value, row) => {
        const decimalquantity = value ? Number(value).toFixed(3) : undefined

        return { ...row, decimalquantity }
      },
      renderCell: (params: GridRenderCellParams<Row, string>) =>
        StringRenderer({ ...params, value: params.formattedValue }),
      renderEditCell: (props) => (
        <StringEditRenderer {...props} trackingPrefix="delivery-out-quantity" />
      ),
    },
  ]

  const { filter, isFilterReady } = useContext(FilterContext)

  const queryVariables = useMemo(() => {
    const config = {
      filter,
      pagination: {
        start: 0,
        page: 0,
        pageSize: PAGE_SIZE,
      },
      sorting: apiRef.current.state?.sorting.sortModel,
    }

    const variables: GetWarehouseBatchItemsQueryVariables = {
      num: config.pagination?.pageSize,
      start:
        (config.pagination?.page || 0) * (config.pagination?.pageSize || 0),
      warehouseid: warehouseId,
      warehousebatchid: warehouseBatchId,
    }

    return variables
  }, [apiRef, filter, warehouseBatchId, warehouseId])

  const {
    data,
    loading: fetchLoading,
    fetchMore,
    refetch: refetchQuery,
  } = useQuery(GetWarehouseBatchItemsDocument, {
    fetchPolicy: 'cache-and-network',
    variables: queryVariables,
    notifyOnNetworkStatusChange: true,
    skip: !isFilterReady,
  })

  const isLoading = fetchLoading

  const fetchMoreItems = useCallback(() => {
    const { pageSize } = apiRef.current.state.pagination.paginationModel

    if (data?.warehouseBatchItems.totalcount) {
      return fetchMore({
        variables: {
          start: (data.warehouseBatchItems?.start || 0) + pageSize,
        },
      })
    }
  }, [apiRef, data, fetchMore])

  const processRowUpdate = useCallback(
    async (newRow: Row, oldRow: Row) => {
      const newQuantity = newRow.decimalquantity
      const oldQuantity = oldRow.decimalquantity

      if (newQuantity === oldQuantity) return newRow

      try {
        if (newQuantity) {
          await updateWarehouseBatchItems({
            variables: {
              warehouseId,
              warehouseBatchId,
              warehouseBatchItems: [
                {
                  sku: newRow.sku,
                  decimalquantity: newQuantity,
                },
              ],
            },
          })
        } else {
          await deleteWarehouseBatchItems({
            variables: {
              warehouseId,
              warehouseBatchId,
              skus: [newRow.sku],
            },
          })

          void refetchQuery()
        }

        trackFormSuccess({ name: 'delivery-out-list-row' })
        enqueueSnackbar(
          t('delivery_out:success_update_delivery_out_snackbar', {
            count: 1,
          }),
        )

        return newRow
      } catch (error) {
        trackFormError({ name: 'delivery-out-list-row' })
        enqueueSnackbar(
          t('delivery_out:failure_update_delivery_out_snackbar', {
            count: 1,
          }),
          { variant: 'error' },
        )

        return oldRow
      }
    },
    [
      deleteWarehouseBatchItems,
      enqueueSnackbar,
      refetchQuery,
      t,
      trackFormError,
      trackFormSuccess,
      updateWarehouseBatchItems,
      warehouseBatchId,
      warehouseId,
    ],
  )

  const rows = useMemo(() => {
    return data?.warehouseBatchItems.items?.map((item) => {
      return { ...item, id: item.warehousebatchitemid }
    })
  }, [data])

  return (
    <DataGrid
      name="open-delivery-out-list"
      apiRef={apiRef}
      columns={dataGridColumns}
      processRowUpdate={processRowUpdate}
      rows={rows}
      rowCount={data?.warehouseBatchItems?.totalcount || 0}
      loading={isLoading}
      fetchMore={fetchMoreItems}
      slots={{ pagination: CursorPagination }}
      noRowsOverlay={{
        icon: <FolderIcon />,
        title: t('delivery_out:open_delivery_out_grid.title'),
        description: t('delivery_out:open_delivery_out_grid.description'),
      }}
      rowHeight={50}
      sx={{
        '.image-column': {
          padding: '0 !important',
        },
      }}
      disableColumnFilter
      hasPageHeader
      paginationModel={{ page: 0, pageSize: PAGE_SIZE }}
      updateSearchParams
      columnVisibilityModel={{
        ['product-id']: false,
        ['product-activepos']: false,
      }}
      showMore={
        Number(data?.warehouseBatchItems.items?.length) <
        Number(data?.warehouseBatchItems.totalcount)
      }
    />
  )
}
