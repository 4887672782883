import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  List,
  ListItem,
} from '@mui/material'
import { SectionHeader } from '@sitoo/mui-components'
import { useTranslation } from 'react-i18next'
import { FilterViewPanel } from '../../../components/filter-view-panel'
import { ViewPanelTextFilter } from '../../../components/data-grid/filters/view-panel-text-filter'
import { StatusFilter } from './status-filter'
import { DateFilter, OptionType } from '../../../components/date-filter'

type Props = {
  isOpen: boolean
  onClose(): void
}

export enum DateFilterKey {
  Start = 'date-start',
  End = 'date-end',
  LastUsed = 'date-last-used',
}

export const InfluencerCodesFilterPanel = (props: Props) => {
  const { t } = useTranslation(['influencer_codes', 'shared'])

  return (
    <FilterViewPanel open={props.isOpen} onClose={props.onClose}>
      <Accordion defaultExpanded className="MuiAccordionRoot">
        <AccordionSummary aria-controls="filter">
          <SectionHeader sx={{ p: 0 }}>
            {t('shared:label.details')}
          </SectionHeader>
        </AccordionSummary>

        <AccordionDetails sx={{ px: 0, py: 1.5 }}>
          <List sx={{ '.MuiListItem-root': { mb: 0.5 } }}>
            <ListItem>
              <DateFilter
                filterKey={DateFilterKey.Start}
                defaultOptionType={OptionType.AllTime}
                label={t('influencer_codes:date_start')}
              />
            </ListItem>

            <ListItem>
              <DateFilter
                filterKey={DateFilterKey.End}
                defaultOptionType={OptionType.AllTime}
                label={t('influencer_codes:date_end')}
              />
            </ListItem>

            <ListItem>
              <DateFilter
                filterKey={DateFilterKey.LastUsed}
                defaultOptionType={OptionType.AllTime}
                label={t('influencer_codes:date_last_used')}
              />
            </ListItem>

            <ListItem>
              <ViewPanelTextFilter
                filterKey="influencer_name"
                label={t('influencer_codes:influencer_name')}
              />
            </ListItem>

            <ListItem>
              <ViewPanelTextFilter
                filterKey="influencer_code"
                label={t('influencer_codes:influencer_code')}
              />
            </ListItem>

            <ListItem>
              <ViewPanelTextFilter
                filterKey="campaign_name"
                label={t('influencer_codes:campaign_name')}
              />
            </ListItem>

            <ListItem>
              <ViewPanelTextFilter
                filterKey="campaign_code"
                label={t('influencer_codes:campaign_code')}
              />
            </ListItem>

            <ListItem>
              <StatusFilter />
            </ListItem>
          </List>
        </AccordionDetails>
      </Accordion>
    </FilterViewPanel>
  )
}
