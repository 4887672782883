import {
  IconButton,
  Dialog,
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material'
import { ImportIcon } from '@sitoo/mui-components'
import { useCallback, useState, useEffect, useMemo } from 'react'
import { useFieldArray, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { SearchProductField } from '../../../components/search-product-field'
import { useTracking } from '../../../hooks/tracking'
import { ArrayElement } from '../../../utils/types'
import { ShipmentPackage } from '../fields/packages'
import {
  ImportPackageItems,
  ShipmentPackageItems,
} from './import-package-items'
import { PackageItems } from './package-items'
import { nanoid } from 'nanoid'
import { useGridApiRef } from '@mui/x-data-grid-pro'
import { usePrevious } from 'react-use'
import { ShipmentState } from '../../../generated/graphql'
import { getShipmentsErrorMessage } from '../shared'

type Props = {
  open: boolean
  shipmentState?: ShipmentState
  packageId?: string
  packageItems: ShipmentPackage['items']
  onClose(): void
  onError?(error: unknown): void
  onSubmit?: (
    packageItems: ShipmentPackage['items'],
    packageId?: string,
  ) => void | Promise<void>
  onSuccess?: () => void
  dataTestid?: string
  readOnly?: boolean
  isNewShipment: boolean
}

export type ShipmentPackageItem = ArrayElement<ShipmentPackage['items']>

export const SetPackageItemsDialog = (props: Props) => {
  const { t } = useTranslation(['shipments', 'shared', 'settings'])
  const dialogName = 'set-package-items'
  const [dialogState, setDialogState] = useState<'items' | 'import'>('items')

  const {
    trackButtonClickEvent,
    trackDialogClose,
    trackDialogOpen,
    trackFormError,
    trackFormSuccess,
  } = useTracking()
  const [loading, setLoading] = useState(false)

  const { getValues, control } = useForm<Pick<ShipmentPackage, 'items'>>({
    defaultValues: { items: [] },
  })

  const prevOpen = usePrevious(props.open)

  const apiRef = useGridApiRef()

  const { fields, append, replace, remove, update } = useFieldArray({
    control,
    name: 'items',
    keyName: 'rowId',
  })

  const onSubmit = useCallback(async () => {
    try {
      setLoading(true)
      await props.onSubmit?.(
        getValues('items')?.filter((p) => p.quantity > 0),
        props.packageId,
      )
      trackFormSuccess({ name: `${dialogName}-dialog` })
      props.onSuccess?.()
    } catch (error) {
      const errorMessage = getShipmentsErrorMessage(error)
      trackFormError({ name: `${dialogName}-dialog`, errorMessage })
      props.onError?.(error)
    } finally {
      setLoading(false)
    }
  }, [getValues, props, trackFormError, trackFormSuccess])

  const onImportItems = useCallback(
    (importedItems: ShipmentPackageItems) => {
      for (const item of importedItems) {
        append({
          id: nanoid(),
          product_name:
            [item.product_name, item.product?.variant?.at(0)?.value]
              .filter(Boolean)
              .join(', ') || '',
          sku: item.sku || '',
          quantity: item.quantity || 1,
          quantity_received: 0,
          product: {
            id: 0,
            sku: item.sku || '',
            images: item.product?.images || [],
            barcode: item.barcode,
          },
        })
      }

      setDialogState('items')
    },
    [append],
  )

  useEffect(() => {
    if (props.open === prevOpen) return

    if (props.open) {
      replace(props.packageItems || [])
      trackDialogOpen({ name: dialogName })
    } else {
      replace([])
      trackDialogClose({ name: dialogName })
    }
  }, [
    prevOpen,
    props.open,
    props.packageItems,
    replace,
    trackDialogClose,
    trackDialogOpen,
  ])

  const isReadonly = useMemo(
    () =>
      props.readOnly ||
      (props.shipmentState &&
        [
          ShipmentState.Cancelled,
          ShipmentState.Closed,
          ShipmentState.ClosedIncomplete,
        ].includes(props.shipmentState)),
    [props.readOnly, props.shipmentState],
  )

  return (
    <Dialog
      open={props.open}
      onClose={props.onClose}
      maxWidth="sm"
      fullWidth
      data-testid="add-package-items-dialog"
    >
      {dialogState === 'items' ? (
        <>
          <DialogTitle
            type="extended"
            sx={{ pb: 2, justifyContent: 'space-between' }}
          >
            {isReadonly
              ? t('shipments:packages.view_items')
              : t('shipments:packages.edit_items')}
            {!isReadonly && (
              <IconButton onClick={() => setDialogState('import')}>
                <ImportIcon />
              </IconButton>
            )}
          </DialogTitle>

          <DialogContent sx={{ p: 0 }}>
            {!isReadonly && (
              <SearchProductField
                data-testid="search-products"
                sx={{ mb: 2, px: 3 }}
                onChange={(product) => {
                  append({
                    id: nanoid(),
                    product_name:
                      [product.title, product.variant?.at(0)?.value]
                        .filter(Boolean)
                        .join(', ') || '',
                    sku: product.sku,
                    quantity: 1,
                    quantity_received: 0,
                    product: {
                      id: product.id,
                      sku: 'productsku',
                      barcode: product.barcode,
                      images: product.productImages?.map(
                        (image) => image.fileUrl,
                      ),
                    },
                  })

                  setTimeout(() => {
                    apiRef.current.scrollToIndexes({ rowIndex: fields.length })
                  }, 100)
                }}
              />
            )}

            <PackageItems
              items={getValues('items')}
              updateItem={update}
              removeItem={remove}
              apiRef={apiRef}
              readOnly={isReadonly}
              shipmentState={props.shipmentState}
            />
          </DialogContent>
          <DialogActions>
            <Button
              onClick={trackButtonClickEvent(
                { name: `${dialogName}-dialog-cancel` },
                props.onClose,
              )}
              color="secondary"
              size="small"
              type="button"
              disabled={loading}
            >
              {!isReadonly
                ? t('shared:action.cancel')
                : t('shared:action.close')}
            </Button>
            {!isReadonly && (
              <Button
                type="submit"
                size="small"
                onClick={trackButtonClickEvent(
                  { name: `${dialogName}-dialog-save` },
                  onSubmit,
                )}
                data-testid="dialog-submit"
                loading={loading}
              >
                {props.isNewShipment
                  ? t('shared:action.ok')
                  : t('shared:action.save')}
              </Button>
            )}
          </DialogActions>
        </>
      ) : (
        <ImportPackageItems
          onClose={() => setDialogState('items')}
          onSubmit={onImportItems}
        />
      )}
    </Dialog>
  )
}
