import { Box, Grid, IconButton, Skeleton, SxProps, Theme } from '@mui/material'
import { CloseIcon, FolderIcon, SectionHeader } from '@sitoo/mui-components'
import { JSX } from 'react'

type FilterViewPanelHeaderProps = {
  section: string
  sectionIcon?: JSX.Element
  onClose: () => void

  isLoading?: boolean

  hideActionsSkeleton?: boolean
  sx?: SxProps<Theme>
}

export const FilterViewPanelHeader = (props: FilterViewPanelHeaderProps) => {
  return (
    <Box
      sx={{
        position: 'sticky',
        top: 0,
        zIndex: 10,
        background: (theme) => theme.palette.background.paper,
        padding: (theme) => theme.spacing(1, 1, 1, 2),
        borderBottom: (theme) => `1px solid ${theme.palette.gray10}`,

        ...props.sx,
      }}
    >
      <Grid container sx={{ width: '100%' }}>
        <Grid size="grow" sx={{ display: 'flex', alignItems: 'center' }}>
          <SectionHeader sx={{ padding: 0, backgroundColor: 'transparent' }}>
            {props.isLoading ? (
              <Skeleton width="30%" />
            ) : (
              <Box
                sx={{
                  display: 'flex',
                  gap: (theme) => theme.spacing(0.5),
                }}
              >
                {props.sectionIcon || <FolderIcon />}{' '}
                {props.section.toUpperCase()}
              </Box>
            )}
          </SectionHeader>
        </Grid>

        <Grid>
          <IconButton onClick={props.onClose}>
            <CloseIcon fontSize="medium" />
          </IconButton>
        </Grid>
      </Grid>
    </Box>
  )
}
