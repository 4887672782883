/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material'
import { Location } from 'history'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useBlocker } from 'react-router-dom'
import { ContentContainer } from './styled'

type RouteLeavingGuardProps = {
  title?: string
  text?: string
  cancelLabel?: string
  confirmLabel?: string
  when?: boolean | undefined
  shouldBlockNavigation?: (location: Location) => boolean
  onStateChange?: (isBlocked: boolean) => void
}

export const RouteLeavingGuard = ({
  when = true,
  shouldBlockNavigation,
  title,
  text,
  cancelLabel,
  confirmLabel,
  onStateChange,
}: RouteLeavingGuardProps) => {
  const { t } = useTranslation('shared')

  const blocker = useBlocker(when)

  const ignoreLeavingGuard: boolean =
    blocker.location?.state?.ignoreLeavingGuard ?? false

  const isExternallyBlocked =
    shouldBlockNavigation && blocker.location
      ? shouldBlockNavigation(blocker.location)
      : true

  const isBlocked =
    when &&
    blocker.state === 'blocked' &&
    isExternallyBlocked &&
    !ignoreLeavingGuard

  useEffect(() => {
    onStateChange?.(isBlocked)
  }, [isBlocked, onStateChange])

  useEffect(() => {
    if (blocker.state === 'blocked' && ignoreLeavingGuard) {
      blocker.proceed()
    }
  }, [blocker, ignoreLeavingGuard])

  useEffect(() => {
    if (blocker.state === 'blocked' && !when) {
      blocker.reset()
    }
  }, [blocker, when])

  const onProceed = () => {
    blocker.proceed?.()
  }

  const onStay = () => {
    blocker.reset?.()
  }

  return (
    <Dialog open={isBlocked} maxWidth="xs" fullWidth>
      <DialogTitle data-testid="route-guard-dialog-title">
        {title || t('unsaved_form_dialog.title')}
      </DialogTitle>
      <DialogContent>
        <ContentContainer>
          {text || t('unsaved_form_dialog.description')}
        </ContentContainer>
      </DialogContent>
      <DialogActions>
        <Button color="secondary" onClick={onStay} data-testid="stay-button">
          {cancelLabel || t('unsaved_form_dialog.stay')}
        </Button>
        <Button color="primary" onClick={onProceed} data-testid="leave-button">
          {confirmLabel || t('unsaved_form_dialog.leave')}
        </Button>
      </DialogActions>
    </Dialog>
  )
}
