import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  capitalize,
  Divider,
  Paper,
  Typography,
} from '@mui/material'
import { useTheme } from '@mui/material/styles'
import { axisClasses, BarChart } from '@mui/x-charts'
import { ReportIcon } from '@sitoo/mui-components'
import { useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import {
  PaginatedReportResultsItemsResponse,
  ReportResultsQuery,
} from '../../generated/graphql'
import { makeVar } from '@apollo/client'

type Props = {
  reportResults?: ReportResultsQuery['reportResults']
  reportResultItems?: PaginatedReportResultsItemsResponse
  previewMode?: boolean
  defaultExpanded?: boolean
}

export const REPORTS_GRAPH_CLOSED_HEIGHT = 49 + 16
export const REPORTS_GRAPH_OPEN_HEIGHT = REPORTS_GRAPH_CLOSED_HEIGHT + 300

export const reportsGraphHeight = makeVar<number>(REPORTS_GRAPH_CLOSED_HEIGHT)

export const GraphSection = (props: Props) => {
  const yAxisValue = useMemo(() => {
    if (props.reportResults?.columns) {
      return props.reportResults?.columns?.find((column) =>
        column.key?.includes('measure'),
      )?.key
    }
  }, [props.reportResults?.columns])

  const { t } = useTranslation(['reports'])

  useEffect(() => {
    reportsGraphHeight(
      props.defaultExpanded
        ? REPORTS_GRAPH_OPEN_HEIGHT
        : REPORTS_GRAPH_CLOSED_HEIGHT,
    )
  }, [props.defaultExpanded])

  const groupingVariables = useMemo(() => {
    if (props.reportResults?.columns) {
      return props.reportResults?.columns?.filter((column) =>
        column.key?.includes('group'),
      )
    }
    return []
  }, [props.reportResults])

  const numberOfRows = props.reportResultItems?.totalcount ?? 0

  const theme = useTheme()

  const baseColor = props.previewMode
    ? theme.palette.gray20
    : theme.palette.blueBase

  const canRenderGraph = numberOfRows < 100

  if (!props.reportResults || !yAxisValue || !canRenderGraph) return null

  const getColumnLabel = (key: string, value?: any) => {
    if (value != undefined && key.includes('group')) {
      if (key.toLowerCase().includes('year')) {
        return new Date(value).toLocaleDateString(undefined, {
          month: 'long',
          year: 'numeric',
        })
      }
      return String(value === '' ? '-' : value)
    }
  }

  return (
    <Box component={Paper} elevation={0} sx={{ mb: 2 }}>
      <Divider />
      <Accordion
        sx={{ p: 0 }}
        slotProps={{ transition: { unmountOnExit: true } }}
        defaultExpanded={props.defaultExpanded}
        onChange={(_event, expanded) => {
          reportsGraphHeight(
            expanded ? REPORTS_GRAPH_OPEN_HEIGHT : REPORTS_GRAPH_CLOSED_HEIGHT,
          )
        }}
      >
        <AccordionSummary>
          <ReportIcon sx={{ mr: 1 }} />
          <Typography variant="body02" sx={{ p: 0 }}>
            {t('reports:graph')}
          </Typography>
        </AccordionSummary>
        <AccordionDetails sx={{ p: 0 }}>
          <BarChart
            grid={{ horizontal: true }}
            margin={{
              left: 120,
              right: 40,
              top: 40,
              bottom: 40,
            }}
            series={[
              {
                data:
                  props.reportResultItems?.items?.map((row) =>
                    Number(row[yAxisValue] ?? 0),
                  ) || [],
                color: baseColor,
              },
            ]}
            height={300}
            xAxis={[
              {
                data: props.reportResultItems?.items?.map((row, index) => {
                  if (numberOfRows > 10) return index + 1
                  if (groupingVariables.length > 3) return index
                  const columnLabels: string[] = []
                  Object.entries(row).forEach(([key, value]) => {
                    const label = getColumnLabel(key, value)
                    if (label) columnLabels.push(label)
                  })
                  return columnLabels.join(', ')
                }),
                scaleType: 'band',
                tickLabelStyle: { maxWidth: '100px' },
              },
            ]}
            yAxis={[
              {
                label: t(`reports:report_keys.${capitalize(yAxisValue)}`),
              },
            ]}
            sx={{
              [`& .${axisClasses.left} .${axisClasses.label}`]: {
                transform: 'translateX(-60px)',
              },
            }}
          />
        </AccordionDetails>
      </Accordion>
    </Box>
  )
}
