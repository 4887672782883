import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { SettingsListItem } from '../../../../../../components/settings-list-item'
import { TextFieldInput } from '../../../../../../inputs/text-field-input'
import { Form } from '../../..'

export const NumberOfDaysField = () => {
  const { t } = useTranslation(['pos_profiles'])
  const { control, getValues } = useFormContext<Form>()

  const startDate = getValues('return_policy.start_date')
  const endDate = getValues('return_policy.end_date')
  const lastDate = getValues('return_policy.last_date')

  const hasDates = startDate || endDate || lastDate

  return (
    <SettingsListItem>
      <TextFieldInput
        name="return_policy.default_days"
        control={control}
        label={t('pos_profiles:return_policy_section.default_days')}
        helperText={t(
          'pos_profiles:return_policy_section.default_days_description',
        )}
        type="number"
        rules={{
          min: 0,
          max: 9999,
          validate: (value) => {
            if (!hasDates) return true
            if (typeof value !== 'number' || value < 0) {
              return t(
                'pos_profiles:return_policy_section.default_days_required_if_dates',
              )
            }
          },
        }}
        transform={{
          output: (event) =>
            event.target.value ? Number(event.target.value) : null,
        }}
      />
    </SettingsListItem>
  )
}
