import { IconButton } from '@mui/material'
import { GridRowModel, useGridApiContext } from '@mui/x-data-grid-pro'
import { DeleteIcon } from '@sitoo/mui-components'
import { InfluencerCodesDeleteDialog } from '../influencer-codes-delete-dialog'
import { useState } from 'react'
import { useTracking } from '../../../hooks/tracking'
import { Row } from '..'

export const InfluencerCodesBulkActions = () => {
  const apiRef = useGridApiContext()
  const [isDialogOpen, setDialogOpen] = useState(false)
  const [codes, setCodes] = useState<string[]>([])

  const { trackButtonClick } = useTracking()

  const onClick = () => {
    const ids = (
      Array.from(
        apiRef.current.getSelectedRows?.().values(),
      ) as GridRowModel<Row>[]
    ).map(({ code }) => code)

    setCodes(ids)
    setDialogOpen(true)
    trackButtonClick({ name: 'influencer-code-delete-bulk-action' })
  }

  const onClose = () => {
    setDialogOpen(false)
  }

  const onSuccess = () => {
    setDialogOpen(false)
  }

  return (
    <>
      <InfluencerCodesDeleteDialog
        open={isDialogOpen}
        onClose={onClose}
        ids={codes}
        onSuccess={onSuccess}
        dataTestid="influencer-code-delete-dialog"
      />

      <IconButton onClick={onClick}>
        <DeleteIcon />
      </IconButton>
    </>
  )
}
