import { Button, Container } from '@mui/material'
import { FormProvider, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { RootRoute } from '../..'
import { PageHeader } from '../../../components/page-header'
import { ShipmentInput } from '../../../generated/graphql'
import { usePageTitle } from '../../../hooks/title'
import { useAbsolutePath } from '../../../hooks/absolute-path'
import { Information } from '../information'
import { useNewShipmentFormSubmit } from './submit'
import { BaseShipmentFormContext } from '../shared'
import { FormRouteLeavingGuard } from '../../../components/form-route-leaving-guard'

export type NewShipmentFormContext = Omit<
  BaseShipmentFormContext & ShipmentInput,
  | 'created_at'
  | 'modified_at'
  | 'state'
  | 'all_packages_arrived'
  | 'all_items_received'
  | 'items_undeclared'
>

export const NewShipmentPage = () => {
  const { t } = useTranslation(['shipments', 'shared'])
  usePageTitle(t('shipments:page_title_add_shipment'))

  const defaultValues: NewShipmentFormContext = {
    packages: [],
    info: {
      origin_warehouse_id: 0,
      destination_warehouse_id: 0,
      additional_data: {},
      reference_number: '',
      barcode: '',
      note: '',
      carrier: {
        name: '',
        service: '',
        reference: '',
        tracking_url: '',
        tracking_url_title: '',
      },
      origin: {
        address: {
          address: '',
          address2: '',
          city: '',
          country_id: '',
          name: '',
          state: '',
          zip: '',
        },
        contact: {
          title: '',
          first_name: '',
          last_name: '',
          email: '',
          mobile: '',
          note: '',
        },
      },
      destination: {
        address: {
          address: '',
          address2: '',
          city: '',
          country_id: '',
          name: '',
          state: '',
          zip: '',
        },
        contact: {
          title: '',
          first_name: '',
          last_name: '',
          email: '',
          mobile: '',
          note: '',
        },
      },
    },
  }

  const formContext = useForm<NewShipmentFormContext>({
    defaultValues,
  })
  const { onSubmit, isSubmitting, onError } = useNewShipmentFormSubmit({
    setError: formContext.setError,
  })
  const generatePath = useAbsolutePath()

  const isLoading = isSubmitting

  return (
    <>
      <PageHeader
        title={t('shipments:page_title_add_shipment')}
        backTo={generatePath(RootRoute.Shipments)}
        backText={t('shared:menu.shipments')}
        showBackButton={true}
        isSticky
        rightColumn={
          <>
            <Button
              loading={isLoading}
              data-testid="shipment-submit-button"
              onClick={formContext.handleSubmit(onSubmit, onError)}
            >
              {t('shared:action.save')}
            </Button>
          </>
        }
      />

      <Container>
        <FormProvider {...formContext}>
          <FormRouteLeavingGuard />
          <Information />
        </FormProvider>
      </Container>
    </>
  )
}
