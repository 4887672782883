import { ListItemText, SxProps } from '@mui/material'
import { ListItemSkeleton } from '../../../components/list-item-skeleton'
import { useMoney } from '../../../hooks/money'
import { GetOrderQuery } from '../../../generated/graphql'
import { useTranslation } from 'react-i18next'

type OrderMoneySummary = {
  order?: GetOrderQuery['order']
  showSalesTax?: boolean
  isLoading?: boolean
  sx?: SxProps
}

export const OrderMoneySummary = ({
  order,
  showSalesTax,
  isLoading,
  sx,
}: OrderMoneySummary) => {
  const { formatCurrency: baseFormatCurrency } = useMoney()
  const { t } = useTranslation(['shared', 'orders'])
  const formatCurrency = (cash: string | number) =>
    baseFormatCurrency(cash, { forceDecimals: true })

  const orderCampaigns = Array.from(
    (order?.orderitems || [])
      .reduce((acc, item) => {
        const campaignName = item.vouchername || item.vouchercode
        if (!campaignName || item.quantity !== 0) return acc

        const campaign = acc.get(campaignName) || { totalDiscount: 0 }

        campaign.totalDiscount +=
          Number(item.moneyitemtotal_net || 0) +
          Number(item.moneyitemtotal_vat || 0)

        acc.set(campaignName, campaign)

        return acc
      }, new Map<string, { totalDiscount: number }>())
      .entries(),
  )

  const orderVats = Array.from(
    (order?.orderitems || [])
      .reduce((acc, item) => {
        if (typeof item.vatvalue !== 'number') return acc

        const vat = acc.get(item.vatvalue) || {
          totalVatValue: 0,
          totalNetValue: 0,
        }
        vat.totalNetValue += Number(item.moneyitemtotal_net || 0)
        vat.totalVatValue += Number(item.moneyitemtotal_vat || 0)
        acc.set(item.vatvalue, vat)

        return acc
      }, new Map<number, { totalVatValue: number; totalNetValue: number }>())
      .entries(),
  )

  return (
    <ListItemSkeleton
      divider
      sx={{ ...sx }}
      secondaryAction={
        <ListItemText
          sx={{
            textAlign: 'end',
          }}
          slotProps={{
            secondary: {
              sx: {
                whiteSpace: 'pre-line',
              },
            },
          }}
          secondary={[
            order?.moneytotal_gross_roundoff &&
            Number(order.moneytotal_gross_roundoff || 0) !== 0
              ? formatCurrency(order.moneytotal_gross_roundoff)
              : '',
            ...orderCampaigns.map(([, { totalDiscount }]) =>
              formatCurrency(totalDiscount),
            ),

            ...orderVats
              .filter(() => !showSalesTax)
              .map(([, { totalVatValue }]) => formatCurrency(totalVatValue)),
          ]
            .filter(Boolean)
            .join('\n')}
        >
          {order?.moneytotal_gross_all
            ? formatCurrency(order.moneytotal_gross_all)
            : '-'}
        </ListItemText>
      }
      isLoading={isLoading}
      childrenSkeleton
      data-testid="order-total"
    >
      <ListItemText
        sx={{
          '.MuiListItemText-secondary': {
            color: (theme) => theme.palette.gray70,
          },
        }}
        slotProps={{
          secondary: {
            sx: {
              whiteSpace: 'pre-line',
            },
          },
        }}
        secondary={[
          order?.moneytotal_gross_roundoff &&
          Number(order.moneytotal_gross_roundoff) !== 0
            ? t('orders:view_panel.roundoff')
            : '',
          ...orderCampaigns.map(([campaign]) => campaign),

          ...orderVats
            .filter(() => !showSalesTax)
            .map(
              ([vat, { totalNetValue }]) =>
                `${t('orders:view_panel.vat_percentage', {
                  value: vat,
                })} (${formatCurrency(totalNetValue)})`,
            ),
        ]
          .filter(Boolean)
          .join('\n')}
      >
        {t('orders:view_panel.total')}
      </ListItemText>
    </ListItemSkeleton>
  )
}
