import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { GeneralSettingsFormContext } from '../general-settings-form'
import { SettingsListSection } from '../../../../components/settings-list-section'
import { TextFieldInput } from '../../../../inputs/text-field-input'
import { validateEmail } from '../../../../utils/validate'
import { SettingsListItem } from '../../../../components/settings-list-item'

export const DigitalReceipt = () => {
  const { control } = useFormContext<GeneralSettingsFormContext>()
  const { t } = useTranslation(['shared', 'settings'])

  return (
    <SettingsListSection title={t('settings:general.digital_receipt_title')}>
      <SettingsListItem>
        <TextFieldInput
          name="emailfromdisplay"
          label={t('settings:general.sender_name')}
          control={control}
          helperText={t('settings:general.sender_name_helper_text')}
          placeholder={t('settings:general.sender_name_placeholder')}
        />
      </SettingsListItem>

      <SettingsListItem>
        <TextFieldInput
          name="emailfrom"
          label={t('settings:general.sender_email_address')}
          control={control}
          helperText={t('settings:general.sender_email_helper_text')}
          placeholder={t('settings:general.sender_email_placeholder')}
          rules={{
            validate: (value) =>
              value && !validateEmail(value)
                ? t('shared:validation.field_invalid', {
                    field: t('shared:label.email'),
                  })
                : true,
          }}
        />
      </SettingsListItem>
    </SettingsListSection>
  )
}
