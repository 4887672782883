import { useReactiveVar } from '@apollo/client'
import { Box, useTheme } from '@mui/material'
import { forwardRef, useEffect, useRef } from 'react'
import { globalViewPanelIds, navbarHeightReactiveVar } from '../../cache'
import { nanoid } from 'nanoid'
export { ViewPanelHeader } from './view-panel-header'
export { ViewPanelImage } from './view-panel-image'

type ViewPanelProps = {
  open: boolean
  children: React.ReactNode
}

export const VIEW_PANEL_WIDTH = 377

export const ViewPanel = forwardRef<HTMLDivElement, ViewPanelProps>(
  function ViewPanel(props, ref) {
    const theme = useTheme()
    const navbarHeight = useReactiveVar(navbarHeightReactiveVar)
    const panelWidth = `${VIEW_PANEL_WIDTH}px`

    const panelIdRef = useRef(nanoid())

    // Detect open panels
    useEffect(() => {
      const ids = globalViewPanelIds()
      const panelId = panelIdRef.current

      globalViewPanelIds(
        props.open ? [...ids, panelId] : ids.filter((id) => id !== panelId),
      )

      return () => {
        globalViewPanelIds([])
      }
    }, [panelIdRef, props.open])

    return (
      <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
        <Box
          sx={{
            position: 'relative',
            width: props.open ? panelWidth : 0,

            [theme.breakpoints.down('sm')]: {
              position: 'fixed',
              width: '100vw',
              height: '100vh',
              left: 0,
              zIndex: (theme) => theme.zIndex.pageHeader,
            },
          }}
        >
          <Box
            sx={{
              background: (theme) => theme.palette.background.default,
              overflow: 'hidden',
              position: 'absolute',
              right: 0,

              height: props.open ? `calc(100vh - ${navbarHeight || 0}px)` : 0,
              width: props.open ? panelWidth : 0,

              [theme.breakpoints.down('sm')]: {
                width: '100vw',
                maxWidth: '100vw',
              },
            }}
          >
            <Box
              ref={ref}
              sx={{
                background: (theme) => theme.palette.background.default,
                position: 'fixed',
                width: panelWidth,
                maxWidth: panelWidth,
                overflowY: 'scroll',
                height: `calc(100vh - ${navbarHeight || 0}px)`,
                borderLeft: (theme) => `1px solid ${theme.palette.divider}`,

                [theme.breakpoints.down('sm')]: {
                  width: '100vw',
                  maxWidth: '100vw',
                },
              }}
            >
              {props.children}
            </Box>
          </Box>
        </Box>
      </Box>
    )
  },
)
