import i18next from 'i18next'

type Options = Pick<Intl.NumberFormatOptions, 'useGrouping'> & {
  locale?: string
  minDecimals?: number
  maxDecimals?: number
}

/**
 * Converts a number to a decimal with a fixed point
 *
 * @example
 *   formatDecimal(0) => "0"
 *   formatDecimal(1) => "1"
 *   formatDecimal(1.0) => "1"
 *   formatDecimal(1.1) => "1.100"
 *   formatDecimal(1.12345) => "1.123"
 *   formatValue('abc') => undefined
 */
export const formatDecimal = (
  decimal: string | number | undefined | null,
  options?: Options,
): string | undefined => {
  const locale = options?.locale ?? i18next.language
  let minDecimals = options?.minDecimals
  let maxDecimals = options?.maxDecimals

  if (minDecimals === undefined && maxDecimals === undefined) {
    minDecimals = 3
    maxDecimals = 3
  }

  if (decimal === null || decimal === undefined) {
    return undefined
  }

  if (typeof decimal === 'string' && decimal.trim() === '') {
    return undefined
  }

  const number = Number(decimal)

  if (Number.isNaN(number) || !Number.isFinite(number)) {
    return undefined
  }

  if (Number.isInteger(number) && !options?.minDecimals) {
    return String(number)
  }

  const formatOptions: Intl.NumberFormatOptions = {
    minimumFractionDigits: minDecimals,
    maximumFractionDigits: maxDecimals,
    useGrouping: options?.useGrouping,
  }

  return new Intl.NumberFormat(locale, formatOptions).format(number)
}

/**
 * Formats a user's input by replacing all the commas with dots
 * It is suitable when comma is used as a decimal separator
 * It does not handle thousand separators
 */
export const formatDecimalInput = <T>(value: T) => {
  if (typeof value === 'string') {
    return value.replaceAll(',', '.')
  }

  return value
}
