import { useEffect, useMemo } from 'react'
import { useForm } from 'react-hook-form'
import { DialogData, Form } from '.'
import {
  ReportDateMode,
  ReportDefinition,
  ReportOrderState,
  ReportType,
} from '../../../generated/graphql'
import { stripNullValues } from '../../../utils/strip-null-values'
import { useCalculateDateSpan } from '../calculate-date-span'

type Props = {
  dialogData?: DialogData
  name?: string
}

type DatesFromDatemode = Partial<ReportDefinition['filters']>

export const usePopulatedForm = ({ dialogData, name }: Props) => {
  const formContext = useForm<Form>({
    defaultValues: {
      datemode: ReportDateMode.Default,
      jobname: '',
      reportname: '',
      reportdescription: '',
      forcewarehousefilter: false,
      reportdefinition: {
        sort: [],
        groups: [],
        measures: [],
        filters: {
          orderDateStart: '',
          orderDateEnd: '',
          orderPaymentState: '',
          orderSearchText: '',
          orderRegisterId: '',
          orderVoucherCode: '',
          orderVoucherName: '',
          orderHasRegisterId: '',
          orderIsNegative: '',
          stockDateEnd: '',
          warehouseDateStart: '',
          warehouseDateEnd: '',
          warehouseTransactionType: '',
          warehouseId: '',
          orderWarehouseId: '',
          omniFulfillmentDateStart: '',
          omniFulfillmentDateEnd: '',
          omniFulfillmentStoreSales: '',
          omniFulfillmentStoreDelivery: '',
          creditNoteDateStart: '',
          creditNoteDateEnd: '',
          giftCardDateStart: '',
          giftCardDateEnd: '',
          giftCardType: '',
          productProductGroupType: '',
          salesTaxDateStart: '',
          salesTaxDateEnd: '',
          salesTaxIsRefund: true,
          salesTaxGroupRegion: '',
          orderState: [ReportOrderState.Open, ReportOrderState.Closed],
        },
      },
    },
  })

  const { reset } = formContext
  const calculateDateSpan = useCalculateDateSpan()

  const datesFromDatemode = useMemo((): DatesFromDatemode => {
    if (
      !dialogData ||
      !dialogData.datemode ||
      dialogData.datemode === ReportDateMode.Default
    ) {
      return {}
    }

    const [startDate, endDate] = calculateDateSpan(dialogData.datemode)

    const dateFields: Partial<Record<ReportType, DatesFromDatemode>> = {
      [ReportType.Creditnote]: {
        creditNoteDateStart: startDate,
        creditNoteDateEnd: endDate,
      },
      [ReportType.GiftCard]: {
        giftCardDateStart: startDate,
        giftCardDateEnd: endDate,
      },
      [ReportType.OmniFulfillment]: {
        omniFulfillmentDateStart: startDate,
        omniFulfillmentDateEnd: endDate,
      },
      [ReportType.Warehouse]: {
        warehouseDateStart: startDate,
        warehouseDateEnd: endDate,
      },
      [ReportType.SalesTax]: {
        salesTaxDateStart: startDate,
        salesTaxDateEnd: endDate,
      },
      [ReportType.Stock]: { stockDateEnd: endDate },
      [ReportType.Order]: { orderDateStart: startDate, orderDateEnd: endDate },
      [ReportType.Product]: {
        orderDateStart: startDate,
        orderDateEnd: endDate,
      },
    }

    return dateFields[dialogData.reporttype] || {}
  }, [dialogData, calculateDateSpan])

  useEffect(() => {
    if (!dialogData) return
    const overrides: Partial<Form> = {
      jobname: name,
      datemode: dialogData.datemode ?? ReportDateMode.Default,
    }
    reset(
      stripNullValues({
        ...dialogData,
        reportdefinition: {
          ...dialogData.reportdefinition,
          filters: {
            ...dialogData.reportdefinition.filters,
            ...datesFromDatemode,
          },
        },
        ...overrides,
      }),
      {
        keepDefaultValues: true,
      },
    )
  }, [dialogData, name, datesFromDatemode, reset])

  return { formContext }
}
