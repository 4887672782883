import { useMutation } from '@apollo/client'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  List,
  ListItem,
  Stack,
} from '@mui/material'
import { useSnackbar } from 'notistack'
import { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import {
  ReportJobFragment,
  UpdateReportJobDocument,
} from '../../../generated/graphql'
import { useTracking } from '../../../hooks/tracking'
import { TextFieldInput } from '../../../inputs/text-field-input'

const dialogName = 'rename-report-job-dialog'

type Props = {
  open: boolean
  onClose: () => void
  reportJob: ReportJobFragment | undefined
}

export const RenameDialog = ({ open, onClose, reportJob }: Props) => {
  const { t } = useTranslation('reports')
  const { trackFormError, trackFormSuccess } = useTracking()
  const { control, handleSubmit, reset } = useForm<ReportJobFragment>()
  const { enqueueSnackbar } = useSnackbar()
  const [updateReportJob] = useMutation(UpdateReportJobDocument)

  useEffect(() => {
    reset(reportJob)
  }, [reportJob, reset])

  const onSubmit = async (reportJob: ReportJobFragment) => {
    try {
      await updateReportJob({
        variables: {
          reportJob: {
            jobname: reportJob.jobname,
            reportJobId: reportJob.jobid,
          },
        },
      })
      enqueueSnackbar(t('rename_dialog.success'), { variant: 'success' })
      trackFormSuccess({ name: `${dialogName}-dialog` })
      onClose()
    } catch (error) {
      trackFormError({ name: `${dialogName}-dialog` })
      enqueueSnackbar(t('rename_dialog.error'), { variant: 'error' })
    }
  }

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="xs" sx={{ p: 2 }}>
      <DialogTitle>{t('rename_dialog.title')}</DialogTitle>
      <DialogContent sx={{ p: 0, pb: 2 }}>
        <List>
          <Stack gap={1}>
            <ListItem>
              <TextFieldInput
                label={t('rename_dialog.name')}
                name="jobname"
                control={control}
                required
              />
            </ListItem>
          </Stack>
        </List>
      </DialogContent>
      <DialogActions>
        <Button color="secondary" onClick={onClose}>
          {t('shared:action.cancel')}
        </Button>
        <Button onClick={handleSubmit(onSubmit)}>
          {t('shared:action.save')}
        </Button>
      </DialogActions>
    </Dialog>
  )
}
