import { DataGrid } from '../../../../components/data-grid'
import {
  GridColDef,
  GridColumnVisibilityModel,
  GridPreProcessEditCellProps,
  GridRenderCellParams,
  useGridApiRef,
} from '@mui/x-data-grid-pro'
import { WarehouseIcon } from '@sitoo/mui-components'
import { useTranslation } from 'react-i18next'
import { DataGridImageRenderer } from '../../../../components/data-grid-image'
import { SortItem } from '../../../../components/data-grid/filters/sort'
import {
  GetProductsSort,
  GetProductStockLevelsDocument,
  GetProductStockLevelsQuery,
  GetProductStockLevelsQueryVariables,
  UpdateWarehouseItemsDocument,
} from '../../../../generated/graphql'
import { useMoney } from '../../../../hooks/money'
import { useTracking } from '../../../../hooks/tracking'
import { ArrayElement } from '../../../../utils/types'
import {
  StringRenderer,
  StringEditRenderer,
} from '../../../../components/data-grid/utils/string-renderer'
import { StockRenderer } from '../../../../components/data-grid/utils/stock-renderer'
import { useCallback, useContext, useMemo } from 'react'
import { useSnackbar } from 'notistack'
import {
  DEFAULT_PRODUCT_STATE,
  FRANCHISE_SITE_ID_PARAM,
} from '../../../../utils/constants'
import { ProductStateRenderer } from '../../../products/product-list/product-state'
import { useAuthorization } from '../../../../hooks/authorization'
import {
  CaptionRenderer,
  CaptionRendererValue,
} from '../../../../components/data-grid/utils/caption-renderer'
import { ColumnProps } from '../../../../components/data-grid/utils/column-props'
import { RelativeDateRenderer } from '../../../../components/data-grid/utils/relative-date-renderer'
import { useLocalizedDate } from '../../../../hooks/localized-date'
import { useMutation, useQuery } from '@apollo/client'
import { FilterContext } from '../../../../components/data-grid/context'
import { CursorPagination } from '../../../../components/data-grid/cursor-pagination'
import { formatDecimal } from '../../../../utils/format/number'
import { useMe } from '../../../../hooks/me'

type Row = ArrayElement<GetProductStockLevelsQuery['products']['items']>

type Props = {
  warehouseId: number | undefined
  onShowFilter(): void
}

const PAGE_SIZE = 100

export const StockLevelsList = (props: Props) => {
  const { t } = useTranslation(['stock', 'prices', 'products'])
  const { trackFormError, trackFormSuccess } = useTracking()
  const {
    modules: { writeStock },
  } = useAuthorization()
  const { me } = useMe()
  const { enqueueSnackbar } = useSnackbar()

  const apiRef = useGridApiRef()
  const { formatRelativeDate, formatDate } = useLocalizedDate()

  const [updateWarehouseItems, { loading: updateLoading }] = useMutation(
    UpdateWarehouseItemsDocument,
  )

  const { formatCurrency } = useMoney()

  const dataGridColumns: GridColDef<Row>[] = useMemo(
    () => [
      {
        field: 'images',
        ...ColumnProps.image,
        headerName: '',
        valueGetter: (_value, row): string => row.images?.[0] || '',
        cellClassName: 'image-column',
        headerClassName: 'image-column',
        renderCell: DataGridImageRenderer,
      },
      {
        field: 'id',
        ...ColumnProps.productId,
        headerName: t('stock:product_id'),
      },
      {
        field: 'sku',
        ...ColumnProps.sku,
        headerName: t('stock:sku'),
      },
      {
        field: 'title',
        ...ColumnProps.productTitle,
        headerName: t('stock:product'),
        valueGetter: (_value, row): CaptionRendererValue => ({
          title: row.title,
          caption: row.variant?.[0]?.value,
        }),
        renderCell: CaptionRenderer,
      },
      {
        field: 'activepos',
        ...ColumnProps.productActive,
        headerName: t('products:status'),
        renderCell: ProductStateRenderer,
      },
      {
        field: 'stock',
        minWidth: 80,
        headerName: t('stock:in_stock'),
        renderCell: StockRenderer,
        valueGetter: (_value, row) => {
          const value = row.warehouseItems?.find(
            (warehouse) => warehouse.warehouse?.id === props.warehouseId,
          )?.decimaltotal

          return value ? Number(value) : 0
        },
      },

      {
        field: 'reserved',
        minWidth: 80,
        headerName: t('stock:reserved'),
        valueGetter: (_value, row) => {
          const value = row.warehouseItems?.find(
            (warehouse) => warehouse.warehouse?.id === props.warehouseId,
          )?.decimalreserved

          return value ? Number(value) : 0
        },
      },

      {
        field: 'available',
        minWidth: 80,
        headerName: t('stock:available'),
        valueGetter: (_value, row) => {
          const value = row.warehouseItems?.find(
            (warehouse) => warehouse.warehouse?.id === props.warehouseId,
          )?.decimalavailable

          return value ? Number(value) : 0
        },
      },

      {
        field: 'stockValue',
        ...ColumnProps.price,
        minWidth: 160,
        headerName: `${t('stock:stock_value')} (${me?.currentSite.currencycode})`,
        valueGetter: (_value, row) => {
          const value = row.warehouseItems?.find(
            (warehouse) => warehouse.warehouse?.id === props.warehouseId,
          )?.moneytotal

          return formatCurrency(value || '', {
            forceDecimals: true,
            hideCurrency: true,
          })
        },
      },

      {
        headerName: t('stock:modified'),
        ...ColumnProps.date,
        field: 'datelastmodified',
        valueGetter: (_value, row) => {
          return row.warehouseItems?.find(
            (warehouse) => warehouse.warehouse?.id === props.warehouseId,
          )?.datelastmodified
        },
        renderCell: (params) => (
          <RelativeDateRenderer
            {...params}
            formatDate={formatDate}
            formatRelativeDate={formatRelativeDate}
          />
        ),
      },

      {
        headerName: t('stock:bin_location'),
        width: 160,
        field: 'binlocation',
        editable: writeStock,

        valueGetter: (_value, row) => {
          const value = row.warehouseItems?.find(
            (warehouse) => warehouse.warehouse?.id === props.warehouseId,
          )?.binlocation

          return value
        },
        valueSetter: (value, row) => {
          if (!props.warehouseId) {
            return row
          }
          const warehouseItem = row.warehouseItems?.find(
            (warehouse) => warehouse.warehouse?.id === props.warehouseId,
          )

          const formattedValue = typeof value === 'string' ? value : undefined

          return {
            ...row,
            warehouseItems: [
              {
                ...warehouseItem,
                sku: row.sku,
                binlocation: formattedValue,
                warehouse: { id: props.warehouseId, name: '' },
              },
            ],
          }
        },

        renderCell: (params: GridRenderCellParams<Row, string>) =>
          StringRenderer({ ...params, value: params.formattedValue }),

        renderEditCell: (props) => (
          <StringEditRenderer {...props} trackingPrefix="stock-levels" />
        ),
      },

      {
        headerName: t('stock:reorder_point'),
        width: 160,
        field: 'decimalthreshold',
        editable: writeStock,
        valueGetter: (_value, row): string | undefined => {
          const decimalValue = row.warehouseItems?.find(
            (warehouse) => warehouse.warehouse?.id === props.warehouseId,
          )?.decimalthreshold

          return formatDecimal(decimalValue) || '0'
        },
        valueSetter: (value, row) => {
          if (!props.warehouseId) {
            return row
          }

          const warehouseItem = row.warehouseItems?.find(
            (warehouse) => warehouse.warehouse?.id === props.warehouseId,
          )

          return {
            ...row,
            warehouseItems: [
              {
                ...warehouseItem,
                sku: row.sku,
                decimalthreshold: Number(value)
                  ? Number(value).toFixed(3)
                  : Number(0).toFixed(3),
                warehouse: { id: props.warehouseId, name: '' },
              },
            ],
          }
        },
        preProcessEditCellProps: (
          params: GridPreProcessEditCellProps<Row>,
        ) => ({
          ...params.props,
          error: Number.isNaN(Number(params.props.value))
            ? t('stock:decimalthreshold_validation_error').toString()
            : false,
        }),
        renderCell: (params: GridRenderCellParams<Row, string>) =>
          StringRenderer({ ...params, value: params.formattedValue }),
        renderEditCell: (props) => (
          <StringEditRenderer {...props} trackingPrefix="stock-levels" />
        ),
      },
    ],
    [
      formatCurrency,
      formatDate,
      formatRelativeDate,
      me?.currentSite.currencycode,
      props.warehouseId,
      t,
      writeStock,
    ],
  )

  const sortItems = useMemo<SortItem<GetProductsSort>[]>(
    () => [
      {
        field: 'id',
        sort: 'asc',
        title: t('prices:product_id'),
        type: 'number',
        value: GetProductsSort.productid_asc,
      },
      {
        field: 'id',
        sort: 'desc',
        title: t('prices:product_id'),
        type: 'number',
        value: GetProductsSort.productid_desc,
        isDefault: true,
      },
      {
        field: 'sku',
        sort: 'asc',
        title: t('prices:sku'),
        type: 'text',
        value: GetProductsSort.sku_asc,
      },
      {
        field: 'sku',
        sort: 'desc',
        title: t('prices:sku'),
        type: 'text',
        value: GetProductsSort.sku_desc,
      },
      {
        field: 'title',
        sort: 'asc',
        title: t('prices:product'),
        type: 'text',
        value: GetProductsSort.title_asc,
      },
      {
        field: 'title',
        sort: 'desc',
        title: t('prices:product'),
        type: 'text',
        value: GetProductsSort.title_desc,
      },
    ],
    [t],
  )

  const processRowUpdate = async (newRow: Row, oldRow: Row) => {
    if (!props.warehouseId) return oldRow

    try {
      const oldWarehouseItem = oldRow.warehouseItems?.find(
        (item) => item.warehouse?.id === props.warehouseId,
      )
      const newWarehouseItem = newRow.warehouseItems?.find(
        (item) => item.warehouse?.id === props.warehouseId,
      )

      if (
        oldWarehouseItem?.binlocation === newWarehouseItem?.binlocation &&
        oldWarehouseItem?.decimalthreshold ===
          newWarehouseItem?.decimalthreshold
      ) {
        return newRow
      }

      await updateWarehouseItems({
        variables: {
          warehouseId: props.warehouseId,
          items: [
            {
              sku: newRow.sku,
              binlocation: newWarehouseItem?.binlocation,
              decimalthreshold: newWarehouseItem?.decimalthreshold,
            },
          ],
        },
      })

      trackFormSuccess({ name: 'stock-levels-list-row' })
      enqueueSnackbar(
        t('stock:success_update_stock_levels_snackbar', { count: 1 }),
      )

      return newRow
    } catch (error) {
      trackFormError({ name: 'stock-levels-list-row' })
      enqueueSnackbar(
        t('stock:failure_update_stock_levels_snackbar', { count: 1 }),
        { variant: 'error' },
      )

      return oldRow
    }
  }

  const { filter, isFilterReady } = useContext(FilterContext)

  const queryVariables = useMemo(() => {
    const config = {
      filter,
      pagination: {
        start: 0,
        page: 0,
        pageSize: PAGE_SIZE,
      },
      sorting: apiRef.current.state?.sorting.sortModel,
    }
    if (!props.warehouseId) return

    const variables: GetProductStockLevelsQueryVariables = {
      num: config.pagination?.pageSize,
      start:
        (config.pagination?.page || 0) * (config.pagination?.pageSize || 0),
      includeVariants: true,
      warehouseId: props.warehouseId,
    }

    if (typeof config.filter?.['text']?.value === 'string') {
      variables.search = config.filter?.['text']?.value
    }

    const categoryIds = config.filter?.['categoryIds']?.value
    if (categoryIds) {
      variables.categoryIds = Array.isArray(categoryIds)
        ? categoryIds
        : [categoryIds]
    }

    if (config.sorting) {
      const sortItem = config.sorting[0]

      if (sortItem) {
        variables.sort = sortItems.find(
          (s) => s.field === sortItem.field && s.sort === sortItem.sort,
        )?.value
      }
    }

    if (config.filter?.['state']?.value) {
      variables.includeInactive = config.filter?.['state']?.value === 'all'
    } else {
      variables.includeInactive = DEFAULT_PRODUCT_STATE === 'all'
    }

    if (config.filter?.[FRANCHISE_SITE_ID_PARAM]?.value) {
      variables.productSiteId = Number(
        config.filter?.[FRANCHISE_SITE_ID_PARAM]?.value,
      )
    }

    return variables
  }, [apiRef, filter, props.warehouseId, sortItems])

  const columnVisibilityModel: GridColumnVisibilityModel = useMemo(
    () => ({
      id: false,
      activepos: false,
      binlocation: false,
    }),
    [],
  )

  const {
    data,
    loading: fetchLoading,
    fetchMore,
  } = useQuery(GetProductStockLevelsDocument, {
    fetchPolicy: 'cache-and-network',
    variables: queryVariables,
    notifyOnNetworkStatusChange: true,
    skip: !isFilterReady || !props.warehouseId,
  })

  const fetchMoreItems = useCallback(() => {
    const { pageSize } = apiRef.current.state.pagination.paginationModel

    if (data?.products.totalcount) {
      return fetchMore({
        variables: {
          start: (data.products?.start || 0) + pageSize,
        },
      })
    }
  }, [apiRef, data, fetchMore])

  const isLoading = fetchLoading || updateLoading

  return (
    <DataGrid
      name="stock-levels"
      apiRef={apiRef}
      columns={dataGridColumns}
      rows={props.warehouseId ? data?.products.items : []}
      rowCount={data?.products.totalcount || 0}
      processRowUpdate={processRowUpdate}
      loading={isLoading}
      fetchMore={fetchMoreItems}
      slots={{ pagination: CursorPagination }}
      noRowsOverlay={{
        icon: <WarehouseIcon />,
        title: t('stock:stock_levels.empty_title'),
        description: t('stock:stock_levels.empty_description'),
      }}
      onShowFilter={props.onShowFilter}
      hasTextFilter
      sx={{
        '.image-column': {
          padding: '0 !important',
        },
      }}
      disableColumnFilter
      rowHeight={50}
      hasPageHeader
      paginationModel={{ page: 0, pageSize: PAGE_SIZE }}
      updateSearchParams
      sorting={sortItems}
      columnVisibilityModel={columnVisibilityModel}
      showMore={
        Number(data?.products.items?.length) < Number(data?.products.totalcount)
      }
    />
  )
}
