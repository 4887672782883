import {
  Box,
  FormHelperText,
  ListItemAvatar,
  Paper,
  SxProps,
  Typography,
} from '@mui/material'
import { IconButton, List, ListItem, ListItemText, Stack } from '@mui/material'
import { DeleteIcon, DragHandleIcon, SearchIcon } from '@sitoo/mui-components'
import { HTML5Backend } from 'react-dnd-html5-backend'
import { DndProvider } from 'react-dnd/dist/core/DndProvider'
import {
  Control,
  FieldValues,
  Path,
  RegisterOptions,
  useController,
  useFieldArray,
  useForm,
} from 'react-hook-form'
import { DraggableItem } from '../../components/draggable-item'
import {
  ProductResult,
  SearchProductField,
} from '../../components/search-product-field'
import { DataGridImage } from '../../components/data-grid-image'
import { useTranslation } from 'react-i18next'
import { enqueueSnackbar } from 'notistack'
import { useEffect } from 'react'
import { getProductDetails } from '../../utils/format/product'
import { useTracking } from '../../hooks/tracking'

type Props<
  TFieldValues extends FieldValues,
  TName extends Path<TFieldValues>,
> = {
  isDraggable?: boolean
  name: TName
  control: Control<TFieldValues>
  sx?: SxProps
  rules?: Omit<
    RegisterOptions<TFieldValues, TName>,
    'valueAsNumber' | 'valueAsDate' | 'setValueAs'
  >
  transform?: {
    input?: (value: unknown[]) => ProductResult[]
    output?: (value: ProductResult[]) => unknown[]
  }
}

const isProductDuplicate = (
  products: ProductResult[],
  newProduct: ProductResult,
): boolean => products.some((product) => product.id === newProduct.id)

export const ProductsInput = <
  TFieldValues extends FieldValues,
  TName extends Path<TFieldValues>,
>(
  props: Props<TFieldValues, TName>,
) => {
  const { control, isDraggable = true, name, sx, rules, transform } = props
  const { t } = useTranslation('filter')
  const {
    field: { value: parentValue, onChange },
    fieldState,
  } = useController<TFieldValues, TName>({ name, control, rules })

  const { control: localControl, getValues } = useForm<{
    root: ProductResult[]
  }>({
    defaultValues: {
      root: transform?.input?.(parentValue) ?? parentValue,
    },
  })

  const { fields, append, remove, move } = useFieldArray({
    name: 'root',
    control: localControl,
  })
  const { trackButtonClick } = useTracking()

  const errorMessage = fieldState.error?.message

  const handleProductSelect = (product: ProductResult) => {
    if (isProductDuplicate(getValues('root'), product)) {
      enqueueSnackbar(t('filter:products_input.already_selected'), {
        variant: 'info',
      })
      return
    }
    trackButtonClick({ name: 'add-product', category: 'products-input' })

    append(product)
    handleChange()
  }

  useEffect(() => {}, [fields, onChange])

  const handleChange = () => {
    const values = getValues('root')
    onChange(values)
  }

  return (
    <Paper elevation={0} sx={{ my: 1, ...sx }}>
      <SearchProductField onChange={handleProductSelect} sx={{ mb: 2 }} />

      <Box
        sx={{
          marginLeft: -3,
          marginRight: -3,
          borderBottom: (theme) => `1px solid ${theme.palette.divider}`,
          padding: (theme) => theme.spacing(1, 2),
        }}
      >
        <Typography variant="body02">
          {t('filter:products_input.products')}
        </Typography>
      </Box>

      <DndProvider backend={HTML5Backend}>
        {fields.length > 0 ? (
          <List
            sx={{
              height: '300px',
              overflow: 'auto',
              '.MuiListItem-root.MuiListItem-root': { p: 0 },
            }}
          >
            {fields.map((field, index) => (
              <DraggableItem
                key={field.id}
                id={field.id}
                index={index}
                divider
                moveField={(itemA, itemB) => {
                  move(itemA, itemB)
                  handleChange()
                }}
                component={ListItem}
                disabled={!isDraggable}
                secondaryAction={
                  <Stack direction="row" gap={1} alignItems={'center'}>
                    <IconButton
                      aria-label={`${t('shared:action.remove')} ${field.title}`}
                      onClick={() => {
                        remove(index)
                        handleChange()

                        trackButtonClick({
                          name: 'remove-product',
                          category: 'products-input',
                        })
                      }}
                    >
                      <DeleteIcon />
                    </IconButton>
                    {isDraggable && (
                      <Box
                        sx={{
                          color: 'theme.palette.gray60',
                          cursor: 'move',
                          display: 'flex',
                          alignItems: 'center',
                        }}
                      >
                        <DragHandleIcon />
                      </Box>
                    )}
                  </Stack>
                }
              >
                <ListItemAvatar>
                  <DataGridImage src={field.productImages?.at(0)?.fileUrl} />
                </ListItemAvatar>
                <ListItemText
                  primary={field.title}
                  secondary={getProductDetails(field)}
                  slotProps={errorMessage ? { primary: { color: 'red' } } : {}}
                />
              </DraggableItem>
            ))}
          </List>
        ) : (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              height: '300px',
              flexDirection: 'column',
              alignItems: 'center',
              gap: 1,
              color: 'gray60',
            }}
          >
            <SearchIcon />
            <Typography variant="body01">
              {t('filter:products_input.placeholder')}
            </Typography>
          </Box>
        )}
      </DndProvider>
      <FormHelperText error variant="standard" sx={{ mb: 1 }}>
        {errorMessage}
      </FormHelperText>
    </Paper>
  )
}
