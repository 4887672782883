import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import {
  SettingsShipmentAutoGenerateBarcodesEnum,
  SettingsShipmentPrintConfirmationReceiptEnum,
} from '../../../../generated/graphql'
import { SelectInput } from '../../../../inputs/select-input'
import { SettingsListSection } from '../../../../components/settings-list-section'
import { GeneralSettingsFormContext } from '../general-settings-form'
import { SettingsListItem } from '../../../../components/settings-list-item'
import { SwitchInput } from '../../../../inputs/switch-input'
import { ListItemText } from '@mui/material'

export const PosShipments = () => {
  const { control } = useFormContext<GeneralSettingsFormContext>()
  const { t } = useTranslation(['shared', 'settings'])

  const options = Object.values(
    SettingsShipmentPrintConfirmationReceiptEnum,
  ).map((value) => ({
    value: value,
    name: t(
      `settings:general.pos_shipments.shipment_print_confirmation_receipt_${value}`,
    ),
  }))

  return (
    <SettingsListSection title={t('settings:general.pos_shipments.title')}>
      <SettingsListItem>
        <SelectInput
          name="shipment_print_confirmation_receipt"
          label={t(
            `settings:general.pos_shipments.shipment_print_confirmation_receipt_title`,
          )}
          helperText={t(
            `settings:general.pos_shipments.shipment_print_confirmation_receipt_helper`,
          )}
          control={control}
          options={options}
        />
      </SettingsListItem>

      <SettingsListItem divider>
        <SelectInput
          name="shipment_print_delivery_note"
          label={t(
            `settings:general.pos_shipments.shipment_print_delivery_note_title`,
          )}
          helperText={t(
            `settings:general.pos_shipments.shipment_print_delivery_note_helper`,
          )}
          control={control}
          options={options}
        />
      </SettingsListItem>

      <SettingsListItem
        divider
        dense
        secondaryAction={
          <SwitchInput
            aria-label={t(
              'settings:general.pos_shipments.shipment_auto_generate_barcodes_title',
            )}
            name="shipment_auto_generate_barcodes"
            control={control}
            transform={{
              output: (event: React.ChangeEvent<HTMLInputElement>) => {
                if (event.target.checked) {
                  return SettingsShipmentAutoGenerateBarcodesEnum.Active
                }
                return SettingsShipmentAutoGenerateBarcodesEnum.Disabled
              },
              input: (value) => {
                return value === SettingsShipmentAutoGenerateBarcodesEnum.Active
              },
            }}
          />
        }
      >
        <ListItemText
          primary={t(
            'settings:general.pos_shipments.shipment_auto_generate_barcodes_title',
          )}
          secondary={t(
            'settings:general.pos_shipments.shipment_auto_generate_barcodes_helper',
          )}
        />
      </SettingsListItem>
    </SettingsListSection>
  )
}
