import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { Form } from '../../..'
import { SettingsListItem } from '../../../../../../components/settings-list-item'
import { PosProfileReceiptItemRenderingEnum } from '../../../../../../generated/graphql'
import { SelectInput } from '../../../../../../inputs/select-input'

export const ItemRenderingModeField = () => {
  const { t } = useTranslation(['pos_profiles'])
  const { control } = useFormContext<Form>()

  const receiptItemRenderingModeOptions = [
    {
      value: null,
      name: t(
        'pos_profiles:receipt_section.receipt_item_rendering_mode_options.Default',
      ),
    },
    ...Object.values(PosProfileReceiptItemRenderingEnum).map((mode) => ({
      value: mode,
      name: t(
        `pos_profiles:receipt_section.receipt_item_rendering_mode_options.${mode}`,
      ),
    })),
  ]

  return (
    <SettingsListItem sx={{ mt: 2 }}>
      <SelectInput
        label={t(
          'pos_profiles:receipt_section.receipt_item_rendering_mode_label',
        )}
        helperText={t(
          'pos_profiles:receipt_section.receipt_item_rendering_mode_description',
        )}
        name={'receipt_item_rendering'}
        control={control}
        options={receiptItemRenderingModeOptions}
        slotProps={{ select: { displayEmpty: true } }}
      />
    </SettingsListItem>
  )
}
