import { MenuItem, TextField, Typography } from '@mui/material'
import { useContext, useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { FilterContext } from '../../../components/data-grid/context'
import { OrderLookupFilterOrderTypeEnum } from '../../../generated/graphql'
import { useStateParams } from '../../../hooks/state-params'
import { useTracking } from '../../../hooks/tracking'

type QueryParamsState = {
  type?: string
}

export const OrderTypeFilter = () => {
  const { t } = useTranslation(['orders', 'shared'])
  const {
    hasApply,
    removeFilter,
    setFilter,
    subscribeOnResetFilter,
    registerFilter,
  } = useContext(FilterContext)
  const { trackButtonClickEvent } = useTracking()
  const filterKey = 'type'
  const types = useMemo(
    () =>
      Object.values(OrderLookupFilterOrderTypeEnum)
        .map((x) => ({
          value: x,
          label: t(`orders:order_lookup_filter_order_type.${x}`),
        }))
        .sort((a, b) => a.label.localeCompare(b.label)),
    [t],
  )

  const [queryParams, setQueryParams] = useStateParams<QueryParamsState>()

  const selectedTransactionType = types.find(
    ({ value }) => value === queryParams.type,
  )
    ? queryParams.type
    : ''

  useEffect(() => {
    registerFilter({
      key: filterKey,
    })
  }, [registerFilter])

  useEffect(() => {
    const unsubscribe = subscribeOnResetFilter((key) => {
      if (!key || key === filterKey) setQueryParams({ [filterKey]: undefined })
    })
    return () => {
      unsubscribe()
    }
  }, [setQueryParams, subscribeOnResetFilter])

  useEffect(() => {
    const type = types?.find(({ value }) => value === selectedTransactionType)

    if (type) {
      setFilter(filterKey, {
        label: t('orders:type_filter_tag', {
          type: type.label,
        }),
        value: type.value,
      })
    } else {
      removeFilter(filterKey)
    }
  }, [removeFilter, setFilter, selectedTransactionType, types, t])

  return (
    <>
      <TextField
        data-testid="order-type-filter-field"
        onChange={(event) =>
          setQueryParams(
            { [filterKey]: event.target.value || undefined },
            hasApply !== true,
          )
        }
        value={selectedTransactionType || ''}
        slotProps={{ select: { displayEmpty: true } }}
        select // tell TextField to render select
        label={t('orders:type')}
      >
        <MenuItem
          data-testid="order-type-selector"
          value={''}
          divider
          onClick={trackButtonClickEvent({
            name: 'order-search-filter-select-order-type',
            type: 'all',
          })}
        >
          <Typography variant="body02">{t('shared:label.all')}</Typography>
        </MenuItem>
        {types?.map((type) => (
          <MenuItem
            data-testid="order-type-option"
            key={type.value}
            value={type.value || ''}
            divider
            onClick={trackButtonClickEvent({
              name: 'order-search-filter-select-order-type',
              type: type.value,
            })}
          >
            <Typography variant="body02">{type.label}</Typography>
          </MenuItem>
        ))}
      </TextField>
    </>
  )
}
