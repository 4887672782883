import { CampaignCodeInput } from '../report-inputs/campaign-code-input'
import { CampaignNameInput } from '../report-inputs/campaign-name-input'
import { DateRangePickerInput } from '../report-inputs/date-range-picker-input'
import { OrderStateInput } from '../report-inputs/order-state-input'
import { PaymentStateInput } from '../report-inputs/payment-state-input'
import { PosInput } from '../report-inputs/pos-input'
import { PurchaseTypeInput } from '../report-inputs/purchase-type-input'
import { ReportSortInput } from '../report-inputs/report-sort-input'
import { SalesChannelInput } from '../report-inputs/sales-channel-input'
import { SearchTextInput } from '../report-inputs/search-text-input'
import { WarehouseInput } from '../report-inputs/warehouse-input'

export const OrderReportFields = () => {
  return (
    <>
      <ReportSortInput />
      <SearchTextInput searchTextFilterKey="orderSearchText" />
      <DateRangePickerInput
        dateStartKey="orderDateStart"
        dateEndKey="orderDateEnd"
      />
      <OrderStateInput />
      <PaymentStateInput />
      <PurchaseTypeInput />
      <SalesChannelInput />
      <PosInput />
      <WarehouseInput warehouseFilterKey="orderWarehouseId" />
      <CampaignNameInput />
      <CampaignCodeInput />
    </>
  )
}
