import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { GeneralSettingsFormContext } from '../general-settings-form'
import { SettingsListSection } from '../../../../components/settings-list-section'
import { ListItemText, InputLabel } from '@mui/material'
import { SelectInput } from '../../../../inputs/select-input'
import { SwitchInput } from '../../../../inputs/switch-input'
import { useMemo } from 'react'
import {
  SwitchboardGiftCards,
  Product,
  SettingsGiftcardsalestypeEnum,
  GetConfigVarsDocument,
} from '../../../../generated/graphql'
import { DialogInput } from '../../../../inputs/dialog-input'
import { SettingsListItem } from '../../../../components/settings-list-item'
import { ProductsInput } from '../../../../inputs/products-input'
import { useQuery } from '@apollo/client'

export const GiftCardsSettings = () => {
  const { control, getValues, setValue } =
    useFormContext<GeneralSettingsFormContext>()
  const { t } = useTranslation(['shared', 'settings'])
  const { data: configVarsData } = useQuery(GetConfigVarsDocument)
  const { givex, retain24, customGiftCard } = configVarsData?.configVars || {}

  const formatProduct = (value: Product) => ({
    label: `${value.title} ${value.sku}`,
    key: value.id,
  })

  const giftCardValidityOptions = useMemo(() => {
    const initialValue = getValues('giftcardvalidmonths')
    const monthValues = [0, 1, 2, 3, 6, 12, 24, 36, 60]

    if (initialValue && !monthValues.includes(initialValue)) {
      monthValues.push(initialValue)
    }

    return monthValues
      .sort((a, b) => a - b)
      .map((value) => ({
        name:
          value === 0
            ? t('settings:general.gift_cards_settings.unlimited_validity')
            : t('shared:date.months', { count: value }),
        value,
      }))
  }, [getValues, t])

  const giftCardTypeOptions = useMemo(() => {
    return Object.values(SettingsGiftcardsalestypeEnum).map((type) => {
      return {
        name: `${t(
          `settings:general.gift_cards_settings.gift_card_type_${type.toLowerCase()}`,
        )} (${t(`settings:general.gift_cards_settings.gift_card_type_${type.toLowerCase()}_description`)})`,
        value: type,
      }
    })
  }, [t])

  const giftCardServiceOptions = useMemo(() => {
    const serviceConfigMapping = {
      [SwitchboardGiftCards.Givex]: givex,
      [SwitchboardGiftCards.Retain24]: retain24,
      [SwitchboardGiftCards.Customgiftcard]: customGiftCard,
      [SwitchboardGiftCards.Giftcardsitoo]: true,
    }
    const filteredServices = Object.values(SwitchboardGiftCards).filter(
      (service) => serviceConfigMapping[service],
    )

    return [
      {
        name: t('settings:general.gift_cards_settings.gift_card_service_none'),
        value: null,
      },
      ...filteredServices.map((service) => {
        return {
          name: `${t(`settings:general.gift_cards_settings.gift_card_service_${service}`)}`,
          value: service,
        }
      }),
    ]
  }, [customGiftCard, givex, retain24, t])

  const isLoading = !getValues('giftcardproductids_email')

  return (
    <SettingsListSection
      title={t('settings:general.gift_cards_settings.title')}
    >
      {giftCardServiceOptions.length > 0 && (
        <SettingsListItem>
          <SelectInput
            label={t(
              'settings:general.gift_cards_settings.gift_card_service_label',
            )}
            name="giftcards"
            control={control}
            options={giftCardServiceOptions}
            helperText={t(
              'settings:general.gift_cards_settings.gift_card_service_helper',
            )}
            slotProps={{ select: { displayEmpty: true } }}
          />
        </SettingsListItem>
      )}
      <SettingsListItem>
        <SelectInput
          label={t('settings:general.gift_cards_settings.gift_card_type_label')}
          name="giftcardsalestype"
          control={control}
          options={giftCardTypeOptions}
          helperText={t(
            'settings:general.gift_cards_settings.gift_card_type_description',
          )}
        />
      </SettingsListItem>

      <SettingsListItem>
        <SelectInput
          name="giftcardvalidmonths"
          control={control}
          options={giftCardValidityOptions}
          helperText={t(
            'settings:general.gift_cards_settings.validity_description',
          )}
          label={t('settings:general.gift_cards_settings.validity_label')}
          transform={{
            input: (value) => value ?? 0,
            output: (event) =>
              event.target.value ? Number(event.target.value) : null,
          }}
        />
      </SettingsListItem>

      <DialogInput
        control={control}
        itemLabel={t(
          'settings:general.gift_cards_settings.giftcardproductids_email_label',
        )}
        formatValue={formatProduct}
        emptyMessage={t(
          'settings:general.gift_cards_settings.no_product_selected',
        )}
        name="giftcardproducts_email"
        isLoading={isLoading}
        helperText={t(
          'settings:general.gift_cards_settings.giftcardproductids_email_helper',
        )}
        slots={{ Input: ProductsInput }}
        onChange={(products) => {
          setValue(
            'giftcardproductids_email',
            products?.map(({ id }) => id),
            { shouldDirty: true },
          )
        }}
      />

      <DialogInput
        control={control}
        itemLabel={t(
          'settings:general.gift_cards_settings.giftcardproductids_sms_label',
        )}
        formatValue={formatProduct}
        emptyMessage={t(
          'settings:general.gift_cards_settings.no_product_selected',
        )}
        name="giftcardproducts_sms"
        isLoading={isLoading}
        helperText={t(
          'settings:general.gift_cards_settings.giftcardproductids_sms_helper',
        )}
        slots={{ Input: ProductsInput }}
        onChange={(products) => {
          setValue(
            'giftcardproductids_sms',
            products?.map(({ id }) => id),
            { shouldDirty: true },
          )
        }}
      />

      <SettingsListItem dense>
        <ListItemText
          primary={
            <InputLabel>
              {t('settings:general.gift_cards_settings.require_pin_label')}
            </InputLabel>
          }
          secondary={t(
            'settings:general.gift_cards_settings.require_pin_helper',
          )}
        />
        <SwitchInput name="giftcardrequirespin" control={control} />
      </SettingsListItem>
    </SettingsListSection>
  )
}
