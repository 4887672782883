import { useQuery } from '@apollo/client'
import { useMemo } from 'react'
import { FieldValues, UseControllerProps } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import {
  GetCashRegisterQuery,
  GetCashRegistersDocument,
  GetCashRegistersQueryVariables,
} from '../../generated/graphql'
import { AutocompleteInput } from '../../inputs/autocomplete-input'
import { MAX_NUM_REQUEST } from '../../utils/constants'

type Props = {
  variables?: GetCashRegistersQueryVariables
  required?: boolean
}

const formatCashRegisterTitle = (
  cashRegister: GetCashRegisterQuery['cashRegister'],
) => {
  const key = cashRegister?.registerkey || ''
  const posNumber = cashRegister?.registernumber || ''
  const store = cashRegister?.store
  const storeName = store?.name || ''
  const externalId = store?.externalid ? `(${store.externalid})` : ''

  const storeDetails = [storeName, externalId].filter(Boolean).join(' ')

  return [storeDetails, `${key} #${posNumber}`].filter(Boolean).join(' - ')
}

export const CashRegisterInput = <TFieldValues extends FieldValues>(
  props: Props & UseControllerProps<TFieldValues>,
) => {
  const { t } = useTranslation(['shared', 'filter'])
  const { name, control, required, variables } = props

  const { data, loading: isLoading } = useQuery(GetCashRegistersDocument, {
    variables: { ...variables, num: MAX_NUM_REQUEST },
    fetchPolicy: 'cache-and-network',
  })

  const options = useMemo(
    () =>
      [...(data?.cashRegisters.items || [])].sort((a, b) =>
        String(a.registerkey)?.localeCompare(String(b.registerkey)),
      ),
    [data?.cashRegisters.items],
  )

  return (
    <AutocompleteInput
      name={name}
      control={control}
      required={required}
      options={options.map(({ registerid }) => registerid)}
      disabled={isLoading}
      label={t('filter:register_filter.label')}
      textFieldProps={{
        slotProps: {
          htmlInput: { ['data-testid']: 'register-id-input' },
        },
      }}
      autocompleteProps={{
        noOptionsText: t('filter:register_filter.no_options'),
        limitTags: 5,
        getOptionKey: (option) => option,
        getOptionLabel: (id) => {
          const option = options.find((x) => x.registerid === id)
          return option ? formatCashRegisterTitle(option) : ''
        },
      }}
    />
  )
}
