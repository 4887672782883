import { ReportDateMode } from '../../generated/graphql'
import { Dayjs } from 'dayjs'
import { useDayJs } from '../../hooks/day-js'
import { useCallback } from 'react'

export type ReportDateModeWithoutDefault = Exclude<
  ReportDateMode,
  ReportDateMode.Default
>

export const useCalculateDateSpan = () => {
  const dayJs = useDayJs()

  const calculateDateSpan = useCallback(
    (dateMode: ReportDateModeWithoutDefault) => {
      let from: Dayjs
      let until: Dayjs

      switch (dateMode) {
        case ReportDateMode.Month:
          from = dayJs().startOf('month')
          until = dayJs().endOf('month')
          break
        case ReportDateMode.MonthPrev:
          from = dayJs().subtract(1, 'month').startOf('month')
          until = dayJs().subtract(1, 'month').endOf('month')
          break
        case ReportDateMode.Today:
          from = dayJs().startOf('day')
          until = dayJs().endOf('day')
          break
        case ReportDateMode.Week:
          from = dayJs().startOf('week')
          until = dayJs().endOf('week')
          break
        case ReportDateMode.WeekPrev:
          from = dayJs().subtract(1, 'week').startOf('week')
          until = dayJs().subtract(1, 'week').endOf('week')
          break
        case ReportDateMode.Year:
          from = dayJs().startOf('year')
          until = dayJs().endOf('year')
          break
        case ReportDateMode.YearPrev:
          from = dayJs().subtract(1, 'year').startOf('year')
          until = dayJs().subtract(1, 'year').endOf('year')
          break
        case ReportDateMode.Yesterday:
          from = dayJs().subtract(1, 'day').startOf('day')
          until = dayJs().subtract(1, 'day').endOf('day')
          break
      }
      return [from.toISOString(), until.toISOString()]
    },
    [dayJs],
  )

  return calculateDateSpan
}
