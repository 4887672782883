import { useApolloClient } from '@apollo/client'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Divider,
  List,
  ListItem,
  ListItemAvatar,
  ListItemIcon,
  ListItemText,
} from '@mui/material'
import {
  PackageWarningIcon,
  PackageIcon,
  SectionHeader,
} from '@sitoo/mui-components'
import { useSnackbar } from 'notistack'
import { Fragment, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { DataGridImage } from '../../../components/data-grid-image'
import { ListItemAccordion } from '../../../components/list-item-accordion'
import { ListItemSkeleton } from '../../../components/list-item-skeleton'
import {
  GetShipmentQuery,
  GetShipmentsDocument,
  ShipmentState,
} from '../../../generated/graphql'
import { useLocalizedDate } from '../../../hooks/localized-date'
import { useMoney } from '../../../hooks/money'
import { ArrayElement } from '../../../utils/types'
import { SetPackageArrivedDialog } from '../../shipment/set-package-arrived-dialog'
import { SetReceivedPackageItemsDialog } from '../../shipment/set-received-package-items-dialog'
import { ReceivedItemChip } from './received-item-chip'

type PackageIconProps = {
  shipmentPackage: ArrayElement<GetShipmentQuery['shipment']['packages']>
  shipmentState: GetShipmentQuery['shipment']['state']
}

export const PackageItemIcon = (props: PackageIconProps) => {
  const { shipmentPackage } = props

  if (
    !!shipmentPackage.arrived_at &&
    shipmentPackage.total_quantity_received > 0 &&
    shipmentPackage.total_quantity_received < shipmentPackage.total_quantity
  ) {
    return (
      <PackageWarningIcon
        sx={{ color: (theme) => theme.palette.warning.main }}
      />
    )
  }
  return <PackageIcon />
}

type Props = {
  isLoading?: boolean
  shipment?: GetShipmentQuery['shipment']
}

export const Packages = (props: Props) => {
  const { t } = useTranslation(['shared', 'shipments'])
  const { formatCurrency } = useMoney()
  const { formatDate } = useLocalizedDate()
  const { shipment, isLoading } = props
  const { enqueueSnackbar } = useSnackbar()

  const [isSetPackageArrivedDialogOpen, setSetPackageArrivedDialogOpen] =
    useState(false)
  const [selectedSetPackageArrived, setSelectedSetPackageArrived] = useState<
    string[]
  >([])

  const [
    isSetPackageItemsReceivedDialogOpen,
    setSetPackageItemsReceivedDialogOpen,
  ] = useState(false)
  const [selectedSetPackageReceivedItems, setSelectedSetPackageReceivedItems] =
    useState<ArrayElement<GetShipmentQuery['shipment']['packages']> | null>(
      null,
    )

  const client = useApolloClient()

  if (!shipment?.packages.length) {
    return null
  }

  return (
    <Accordion defaultExpanded className="MuiAccordionRoot">
      <AccordionSummary aria-controls="information">
        <SectionHeader sx={{ p: 0 }}>
          {t('shipments:packages.title')}
        </SectionHeader>
      </AccordionSummary>
      <AccordionDetails sx={{ p: 0 }}>
        <List>
          {props.isLoading && (
            <ListItemSkeleton
              isLoading={isLoading}
              secondaryAction="-"
              childrenSkeleton
            />
          )}
          {shipment?.packages.map((shipmentPackage, index) => {
            return (
              <Fragment key={shipmentPackage.id}>
                <ListItemAccordion
                  data-id={shipmentPackage.id}
                  data-testid="shipment-package"
                  aria-controls="package"
                  summary={
                    <>
                      <ListItemSkeleton
                        secondaryAction={
                          !!shipmentPackage.total_quantity_received && (
                            <ListItemText>
                              <ReceivedItemChip
                                label={t('shipments:packages.package_received')}
                                quantity={shipmentPackage.total_quantity}
                                quantityReceived={
                                  shipmentPackage.total_quantity_received
                                }
                              />
                            </ListItemText>
                          )
                        }
                        isLoading={props.isLoading}
                        data-testid={`package-${index}`}
                      >
                        <ListItemIcon>
                          <PackageItemIcon
                            shipmentPackage={shipmentPackage}
                            shipmentState={shipment.state}
                          />
                        </ListItemIcon>
                        <ListItemText
                          primary={t('shipments:packages.package_title', {
                            property: index + 1,
                          })}
                          slotProps={{
                            secondary: { component: Box },
                          }}
                          secondary={
                            <>
                              <Box data-testid="shipment-package-arrived_at">
                                {t('shipments:packages.package_arrived', {
                                  property: shipmentPackage?.arrived_at
                                    ? formatDate(
                                        new Date(shipmentPackage.arrived_at),
                                      )
                                    : '-',
                                })}
                              </Box>
                              <Box data-testid="shipment-package-package_value">
                                {t('shipments:packages.package_value', {
                                  property:
                                    typeof shipmentPackage.value === 'number'
                                      ? formatCurrency(shipmentPackage.value)
                                      : '-',
                                })}
                              </Box>
                              <Box data-testid="shipment-package-package_amount">
                                {t('shipments:packages.package_amount', {
                                  count: shipmentPackage.total_quantity,
                                })}
                              </Box>
                            </>
                          }
                        />
                      </ListItemSkeleton>
                    </>
                  }
                >
                  <List>
                    {shipmentPackage.items?.map((item) => (
                      <ListItem
                        key={item.id}
                        data-testid="shipment-package-item"
                        secondaryAction={
                          <ReceivedItemChip
                            label={t(
                              'shipments:packages.package_received_item',
                              {
                                count: item.quantity_received,
                                total: item.quantity,
                              },
                            )}
                            quantity={item.quantity}
                            quantityReceived={item.quantity_received}
                          />
                        }
                      >
                        <ListItemAvatar>
                          <DataGridImage src={item.product?.images?.at(0)} />
                        </ListItemAvatar>
                        <ListItemText
                          primary={item.product_name}
                          secondary={item.sku}
                        />
                      </ListItem>
                    ))}
                  </List>
                </ListItemAccordion>

                {[
                  ShipmentState.InTransit,
                  ShipmentState.Arrived,
                  ShipmentState.Received,
                ].includes(shipment?.state) &&
                  !shipmentPackage.arrived_at && (
                    <ListItemSkeleton sx={{ justifyContent: 'end' }}>
                      <Button
                        data-testid="set-package-arrived-button"
                        size="small"
                        color="secondary"
                        onClick={() => {
                          setSelectedSetPackageArrived([shipmentPackage.id])
                          setSetPackageArrivedDialogOpen(true)
                        }}
                        fullWidth
                      >
                        {t('shipments:view_panel.set_as_arrived')}
                      </Button>
                    </ListItemSkeleton>
                  )}

                {[ShipmentState.Arrived, ShipmentState.Received].includes(
                  shipment?.state,
                ) &&
                  shipmentPackage.arrived_at &&
                  shipmentPackage.total_quantity !==
                    shipmentPackage.total_quantity_received && (
                    <ListItemSkeleton sx={{ justifyContent: 'end' }}>
                      <Button
                        data-testid="receive-items-button"
                        size="small"
                        color="secondary"
                        onClick={() => {
                          setSelectedSetPackageReceivedItems(shipmentPackage)
                          setSetPackageItemsReceivedDialogOpen(true)
                        }}
                        fullWidth
                      >
                        {t('shipments:view_panel.receive_items')}
                      </Button>
                    </ListItemSkeleton>
                  )}

                {index + 1 < shipment.packages?.length && <Divider />}
              </Fragment>
            )
          })}
        </List>

        {[
          ShipmentState.InTransit,
          ShipmentState.Arrived,
          ShipmentState.Received,
        ].includes(shipment?.state) &&
          shipment?.packages.filter((x) => !x.arrived_at).length > 1 && (
            <>
              <Divider />
              <ListItemSkeleton sx={{ justifyContent: 'end' }}>
                <Button
                  data-testid="set-all-arrived-button"
                  size="small"
                  color="secondary"
                  onClick={() => {
                    setSelectedSetPackageArrived(
                      shipment?.packages
                        .filter((x) => !x.arrived_at)
                        .map((x) => x.id),
                    )
                    setSetPackageArrivedDialogOpen(true)
                  }}
                  fullWidth
                >
                  {t('shipments:view_panel.set_all_to_arrived')}
                </Button>
              </ListItemSkeleton>
            </>
          )}

        <SetPackageArrivedDialog
          open={isSetPackageArrivedDialogOpen}
          shipmentId={shipment.id}
          shipmentVersion={shipment.version}
          packageIds={selectedSetPackageArrived}
          onClose={() => setSetPackageArrivedDialogOpen(false)}
          onSuccess={() => {
            void client.refetchQueries({
              include: [GetShipmentsDocument],
            })
            enqueueSnackbar(t('shipments:shipment_message.success_update'))
            setSetPackageArrivedDialogOpen(false)
          }}
          onError={(errorMessage) => {
            enqueueSnackbar(errorMessage, { variant: 'error' })
            setSetPackageArrivedDialogOpen(false)
          }}
        />
        <SetReceivedPackageItemsDialog
          open={isSetPackageItemsReceivedDialogOpen}
          shipmentId={shipment.id}
          shipmentVersion={shipment.version}
          warehouseId={shipment.info?.origin_warehouse_id}
          package={selectedSetPackageReceivedItems || undefined}
          onClose={() => setSetPackageItemsReceivedDialogOpen(false)}
          onSuccess={() => {
            enqueueSnackbar(t('shipments:shipment_message.success_update'))
            setSetPackageItemsReceivedDialogOpen(false)
          }}
          onError={(errorMessage) => {
            enqueueSnackbar(errorMessage, { variant: 'error' })
            setSetPackageItemsReceivedDialogOpen(false)
          }}
        />
      </AccordionDetails>
    </Accordion>
  )
}
