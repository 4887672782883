import { useQuery } from '@apollo/client'
import { Chip, Stack } from '@mui/material'
import {
  ReportCashRegistersDocument,
  ReportDefinitionFilter,
  ReportWarehousesDocument,
} from '../../../generated/graphql'
import { PlusMoreChip } from './plus-more-chip'
import { useFormattedFilterChips } from './use-formatted-filter-chips'

type Props = {
  filters: ReportDefinitionFilter
}

export const FilterChips = ({ filters }: Props) => {
  const { data: cashRegistersData, loading: isLoadingCashRegisters } = useQuery(
    ReportCashRegistersDocument,
  )
  const { data: warehousesData, loading: isLoadingWarehouses } = useQuery(
    ReportWarehousesDocument,
  )

  const { formattedFilters = [] } = useFormattedFilterChips({
    filters,
    allCashRegisters: cashRegistersData?.allCashRegisters || [],
    allWarehouses: warehousesData?.allWarehouses || [],
    loading: isLoadingCashRegisters || isLoadingWarehouses,
  })

  if (formattedFilters.length === 0) {
    return null
  }

  return (
    <Stack
      direction="row"
      gap={1}
      sx={{
        flexWrap: 'wrap',
        py: 1,
        ':hover': {
          cursor: 'default',
        },
      }}
    >
      {formattedFilters.map(([filterKey, value]) =>
        Array.isArray(value) && value.length > 1 ? (
          <PlusMoreChip key={filterKey} filterKey={filterKey} values={value} />
        ) : (
          <Chip
            key={filterKey}
            size="small"
            label={`${filterKey}: ${value}`}
            color="gray"
          />
        ),
      )}
    </Stack>
  )
}
