import { IconButton } from '@mui/material'
import {
  GridRowId,
  GridRowModel,
  useGridApiContext,
} from '@mui/x-data-grid-pro'
import { DeleteIcon } from '@sitoo/mui-components'
import { useEffect, useState } from 'react'
import { useTracking } from '../../../hooks/tracking'
import { DeleteVariantDialog } from './delete-variant-dialog'
import { Row } from '.'

export const DeleteVariant = () => {
  const [isDialogOpen, setIsDialogOpen] = useState(false)
  const { trackButtonClickEvent } = useTracking()

  const apiRef = useGridApiContext()
  const [selectedRows, setSelectedRows] = useState<
    Map<GridRowId, GridRowModel>
  >(apiRef.current.getSelectedRows())

  useEffect(() => {
    const unsubscribe = apiRef.current.subscribeEvent(
      'rowSelectionChange',
      () => setSelectedRows(apiRef.current.getSelectedRows()),
    )

    return () => unsubscribe()
  }, [apiRef])

  const allSelectedVariants = Array.from(
    selectedRows.values(),
  ) as GridRowModel<Row>[]

  const selectedExistingVariantsIds = allSelectedVariants
    .filter((x) => !x.isMainVariant)
    .map((x) => ({ id: x.id, exists: !!x.__typename }))

  const onClose = () => {
    setIsDialogOpen(false)
  }

  return (
    <>
      <DeleteVariantDialog
        data-testid="delete-variant-dialog"
        variantIds={selectedExistingVariantsIds}
        open={isDialogOpen}
        onClose={onClose}
      />
      <IconButton
        data-testid="delete-variant-button"
        onClick={trackButtonClickEvent(
          { name: 'product-variants-bulk-actions-menu-delete-variant' },
          () => setIsDialogOpen(true),
        )}
        disabled={selectedExistingVariantsIds.length === 0}
      >
        <DeleteIcon />
      </IconButton>
    </>
  )
}
