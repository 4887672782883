import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Chip,
  Divider,
  IconButton,
  List,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
} from '@mui/material'
import {
  MoreVerticalIcon,
  SectionHeader,
  ShipmentIcon,
} from '@sitoo/mui-components'
import { Fragment, MouseEventHandler, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { ListItemAccordion } from '../../../components/list-item-accordion'
import { ListItemProductImage } from '../../../components/list-item-product-image'
import { ListItemSkeleton } from '../../../components/list-item-skeleton'
import {
  GetOrderQuery,
  OrderDeliveryItemState,
  OrderState,
} from '../../../generated/graphql'
import { useLocalizedDate } from '../../../hooks/localized-date'
import { ArrayElement } from '../../../utils/types'
import { OrderDeliveryStateTag } from '../orders-list/delivery-state'
import { DeliveryCancelDialog } from './delivery-cancel-dialog'
import { formatAdditionalData } from '../../../utils/format/additional-data'

type Order = GetOrderQuery['order']

type Delivery = ArrayElement<GetOrderQuery['order']['orderDelivery']>

type Props = {
  isLoading?: boolean
  order?: Order
}

export const Deliveries = (props: Props) => {
  const { t } = useTranslation(['shared', 'orders'])
  const { formatDate } = useLocalizedDate()

  const [deliveryItemId, setDeliveryItemId] = useState<number | undefined>(
    undefined,
  )

  const [isDialogOpen, setDialogOpen] = useState(false)

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)

  const deliveries = props.order?.orderDelivery || []

  const orderItems = props.order?.orderitems || []

  const canUpdate = (
    orderState: Order['orderstateid'] | undefined,
    deliveryState: Delivery['state'],
  ) => {
    return (
      orderState === OrderState.Open &&
      deliveryState !== OrderDeliveryItemState.Cancelled
    )
  }

  const handleContextMenu = (
    event: React.MouseEvent<HTMLButtonElement>,
    deliveryId: number | undefined | null,
  ) => {
    event.stopPropagation()

    if (deliveryId) {
      setAnchorEl(anchorEl ? null : event.currentTarget)
      setDeliveryItemId(deliveryId)
    }
  }

  const closeMenu: MouseEventHandler<HTMLButtonElement> = (event) => {
    event.stopPropagation()
    setAnchorEl(null)
  }

  const handleDeliveryCancel = (
    event: React.MouseEvent<HTMLLIElement, MouseEvent>,
  ) => {
    event.stopPropagation()

    setAnchorEl(null)
    setDialogOpen(true)
  }

  const getDeliveryAdditionalData = (
    delivery: ArrayElement<GetOrderQuery['order']['orderDelivery']>,
  ): Record<string, string> => {
    const additionalDataObject: Record<string, string> = {}

    if (delivery.orderdeliveryid) {
      additionalDataObject[t('orders:view_panel.delivery_id')] =
        `${delivery.orderdeliveryid}`
    }

    if (delivery.datecancelled) {
      additionalDataObject[t('orders:view_panel.cancelled_date')] = formatDate(
        new Date(delivery.datecancelled),
      )
    }

    if (delivery.orderdeliveryref) {
      additionalDataObject[t('orders:view_panel.delivery_reference')] =
        delivery.orderdeliveryref
    }

    if (delivery.warehouseid) {
      additionalDataObject[t('orders:view_panel.warehouse_id')] =
        `${delivery.warehouseid}`
    }

    if (delivery.warehouse?.name) {
      additionalDataObject[t('orders:view_panel.warehouse_label')] =
        delivery.warehouse?.name
    }

    return additionalDataObject
  }

  if (!props.isLoading && deliveries.length === 0) {
    return null
  }

  return (
    <>
      <DeliveryCancelDialog
        open={isDialogOpen}
        orderId={props.order?.orderid}
        orderDeliveryId={deliveryItemId}
        onClose={() => setDialogOpen(false)}
      />

      <Accordion defaultExpanded className="MuiAccordionRoot">
        <AccordionSummary aria-controls="deliveries">
          <SectionHeader sx={{ p: 0 }}>{t('orders:delivery')}</SectionHeader>
        </AccordionSummary>

        <AccordionDetails sx={{ p: 0 }}>
          {props.isLoading && (
            <ListItemSkeleton
              isLoading={props.isLoading}
              secondaryAction="-"
              childrenSkeleton
            />
          )}

          {deliveries.map((delivery, index) => {
            const totalItems = delivery.orderdeliveryitems.reduce(
              (p, c) => p + c.quantity,
              0,
            )

            const additionalData = formatAdditionalData(
              getDeliveryAdditionalData(delivery),
            )

            return (
              <Fragment key={delivery.orderdeliveryid}>
                <ListItemAccordion
                  aria-controls={`delivery-${delivery.orderdeliveryid || 0}`}
                  summary={
                    <ListItemSkeleton
                      isLoading={props.isLoading}
                      sx={{
                        '.MuiListItemSecondaryAction-root': {
                          overflow: 'unset',
                        },
                      }}
                      secondaryAction={
                        <>
                          <IconButton
                            edge="start"
                            onClick={(event) =>
                              handleContextMenu(event, delivery.orderdeliveryid)
                            }
                            disabled={
                              !canUpdate(
                                props.order?.orderstateid,
                                delivery.state,
                              )
                            }
                          >
                            <MoreVerticalIcon />
                          </IconButton>

                          <Menu
                            open={Boolean(
                              anchorEl &&
                                deliveryItemId === delivery.orderdeliveryid,
                            )}
                            anchorEl={anchorEl}
                            onClose={closeMenu}
                            anchorOrigin={{
                              vertical: 'bottom',
                              horizontal: 'right',
                            }}
                            transformOrigin={{
                              vertical: 'top',
                              horizontal: 'right',
                            }}
                            slotProps={{
                              list: { 'aria-labelledby': 'user-menu-button' },
                            }}
                          >
                            <MenuItem onClick={handleDeliveryCancel}>
                              {t('orders:cancel_delivery')}
                            </MenuItem>
                          </Menu>
                        </>
                      }
                      data-testid={`item-order-delivery-${
                        delivery.orderdeliveryid || 0
                      }`}
                    >
                      <ListItemIcon>
                        <ShipmentIcon
                          sx={{
                            color: (theme) =>
                              delivery.datecancelled
                                ? theme.palette.gray60
                                : 'inherit',
                          }}
                        />
                      </ListItemIcon>

                      <ListItemText
                        primary={delivery.pluginname || t('orders:delivery')}
                        slotProps={{
                          secondary: {
                            sx: {
                              whiteSpace: 'pre-line',
                              color: (theme) =>
                                delivery.datecancelled
                                  ? `${theme.palette.gray60} !important`
                                  : 'inherit',
                            },
                            component: 'div',
                          },
                        }}
                        secondary={
                          <Box>
                            {delivery.datedelivery && (
                              <Box>{formatDate(delivery.datedelivery)}</Box>
                            )}
                            {delivery.datecancelled && (
                              <Box>{formatDate(delivery.datecancelled)}</Box>
                            )}
                            {delivery.binlocation && (
                              <Box>{delivery.binlocation}</Box>
                            )}
                            {delivery.orderdeliveryref && (
                              <Box>{delivery.orderdeliveryref}</Box>
                            )}
                            <Box>
                              {t('orders:view_panel.x_item', {
                                count: totalItems,
                              })}
                            </Box>

                            {delivery.state && (
                              <OrderDeliveryStateTag state={delivery.state} />
                            )}
                          </Box>
                        }
                      />
                    </ListItemSkeleton>
                  }
                >
                  <List>
                    <ListItemSkeleton
                      hide={!props.isLoading && additionalData.length === 0}
                      secondaryAction={additionalData.length ? null : '-'}
                      isLoading={props.isLoading}
                      data-testid={`order-delivery-additional-data-${
                        delivery.orderdeliveryid || 0
                      }`}
                    >
                      <ListItemText
                        primary={t('orders:view_panel.additional_data_label')}
                        secondary={additionalData.map(([key, value]) => (
                          <Box
                            key={key}
                            component="span"
                            sx={{ display: 'block' }}
                          >
                            {key}: {value}
                          </Box>
                        ))}
                      />
                    </ListItemSkeleton>
                    {delivery.orderdeliveryitems.map((orderDeliveryItem) => {
                      const productInformation = orderItems.find(
                        (x) => x.orderitemid === orderDeliveryItem.orderitemid,
                      )

                      const totalDeliveredItems = orderDeliveryItem.quantity
                      const totalItems = productInformation?.quantity || 0

                      return (
                        <ListItemSkeleton
                          key={orderDeliveryItem.orderdeliveryitemid}
                          secondaryAction={
                            <Chip
                              data-testid="item-delivery-chip"
                              label={t('orders:view_panel.x_of_y_delivered', {
                                delivered: totalDeliveredItems,
                                total: totalItems,
                              })}
                              size="small"
                              color={
                                totalDeliveredItems === totalItems
                                  ? 'green'
                                  : 'orange'
                              }
                            />
                          }
                          isLoading={props.isLoading}
                          data-testid={`order-delivery-item-${
                            delivery.orderdeliveryid || 0
                          }-${orderDeliveryItem.orderdeliveryitemid || ''}`}
                        >
                          <ListItemProductImage
                            url={productInformation?.product?.images?.[0]}
                          />
                          <ListItemText
                            data-testid="delivery-item-details"
                            primary={
                              productInformation?.product?.title ||
                              productInformation?.productname ||
                              productInformation?.sku
                            }
                            slotProps={{
                              secondary: {
                                sx: {
                                  whiteSpace: 'pre-line',
                                },
                              },
                            }}
                            secondary={[
                              productInformation?.productattributes,
                              productInformation?.sku,
                            ]
                              .filter(Boolean)
                              .join('\n')}
                          />
                        </ListItemSkeleton>
                      )
                    })}
                  </List>
                </ListItemAccordion>
                {index + 1 < deliveries.length && <Divider />}
              </Fragment>
            )
          })}
        </AccordionDetails>
      </Accordion>
    </>
  )
}
