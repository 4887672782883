import { Paper, Typography, Divider, Box } from '@mui/material'
import { Link, SitooLogoSymbol } from '@sitoo/mui-components'
import { useTranslation } from 'react-i18next'

export const Footer = () => {
  const { t } = useTranslation(['login'])

  return (
    <Paper
      square
      elevation={0}
      sx={{
        px: 4,
        py: 3.5,
        backgroundColor: (theme) => theme.palette.yellow.main,
        color: (theme) => theme.palette.darkMode60,
        display: 'grid',
        grid: `"links divider logo" "copyright divider logo"/1fr 1px 40px`,
        columnGap: (theme) => theme.spacing(1),
      }}
    >
      <Box
        sx={{
          display: 'grid',
          gridAutoFlow: 'column',
          columnGap: (theme) => theme.spacing(1),
          gridArea: 'links',
          justifyContent: 'end',
        }}
      >
        <Link
          href="https://www.sitoo.com/terms-of-service/"
          underline="none"
          color="inherit"
        >
          {t('shared:label.terms_of_use')}
        </Link>
        |
        <Link
          href="https://support.sitoo.se/hc/en-us/requests/new"
          underline="none"
          color="inherit"
        >
          {t('shared:label.contact_us')}
        </Link>
      </Box>
      <Box
        sx={{ gridArea: 'copyright', display: 'grid', justifyContent: 'end' }}
      >
        <Typography variant="caption">
          {t('shared:label.copyright', {
            year: new Date().getFullYear(),
          })}
        </Typography>
      </Box>
      <Box sx={{ gridArea: 'divider' }}>
        <Divider
          orientation="vertical"
          sx={{ borderColor: (theme) => theme.palette.darkMode60 }}
        />
      </Box>
      <Box
        sx={{
          gridArea: 'logo',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <SitooLogoSymbol />
      </Box>
    </Paper>
  )
}
