import { useQuery } from '@apollo/client'
import { Tab, Tabs } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { ReportsDocument } from '../../../../generated/graphql'
import { useTabs } from '../../../../hooks/tab'
import { ReportsPanel } from './reports-panel'
import { SystemReportsPanel } from './system-reports-panel'
import { useTracking } from '../../../../hooks/tracking'

enum TabLabels {
  SystemReports,
  Reports,
}

type Props = { selectedReportId: number; onSelect: (reportId: number) => void }

export const TabsWithPanels = ({ selectedReportId, onSelect }: Props) => {
  const { t } = useTranslation('reports')
  const [activeTab, setActiveTab] = useTabs(TabLabels, TabLabels.SystemReports)
  const { data } = useQuery(ReportsDocument, {
    fetchPolicy: 'cache-and-network',
    variables: { num: 0 },
  })
  const { trackButtonClick } = useTracking()

  return (
    <>
      <Tabs
        value={activeTab}
        onChange={(_, value: number) => {
          setActiveTab(value)
          trackButtonClick({
            name: 'create-report-job-tab-change',
            value: TabLabels[value],
          })
        }}
        variant="fullWidth"
      >
        <Tab
          value={TabLabels.SystemReports}
          label={t('create.system_templates')}
        />
        <Tab
          value={TabLabels.Reports}
          label={t('create.your_reports', {
            count: data?.reports.totalcount ?? 0,
          })}
        />
      </Tabs>
      {activeTab === TabLabels.SystemReports && (
        <SystemReportsPanel
          onSelect={onSelect}
          selectedReportId={selectedReportId}
        />
      )}
      {activeTab === TabLabels.Reports && (
        <ReportsPanel onSelect={onSelect} selectedReportId={selectedReportId} />
      )}
    </>
  )
}
