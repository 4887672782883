import { Box, ListItemProps } from '@mui/material'
import { ElementType, PropsWithChildren, useRef } from 'react'
import { useDrag, useDrop } from 'react-dnd/dist/hooks'
import { XYCoord } from 'react-dnd/dist/types/monitors'

type DraggableFieldProps<T extends ElementType> = PropsWithChildren &
  ListItemProps<T> & {
    id: string
    index: number
    moveField: (fromIndex: number, toIndex: number) => void
    disabled?: boolean
  }

const ItemType = 'FIELD'

/**
 * The code is from Simple Sortable Example
 * [react-dnd](https://react-dnd.github.io/react-dnd/examples/sortable/simple)
 */
export const DraggableItem = <T extends ElementType>(
  props: DraggableFieldProps<T>,
) => {
  const {
    id,
    index,
    moveField,
    disabled,
    children,
    component,
    ...componentProps
  } = props
  const Component = component || Box

  const ref = useRef<HTMLDivElement>(null)

  const [{ isDragging }, drag] = useDrag({
    type: ItemType,
    item: { id, index },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
    canDrag: !disabled,
  })

  const [{ handlerId }, drop] = useDrop({
    accept: ItemType,
    hover: (item: { id: string; index: number }, monitor) => {
      if (!ref.current) {
        return
      }
      const dragIndex = item.index
      const hoverIndex = index

      if (dragIndex === hoverIndex) {
        return
      }

      const hoverBoundingRect = ref.current?.getBoundingClientRect()

      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2

      const clientOffset = monitor.getClientOffset()

      const hoverClientY = (clientOffset as XYCoord).y - hoverBoundingRect.top

      // Dragging downwards
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return
      }

      // Dragging upwards
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return
      }

      moveField(dragIndex, hoverIndex)

      item.index = hoverIndex
    },
    collect: (monitor) => ({
      handlerId: monitor.getHandlerId(),
    }),
  })

  // eslint-disable-next-line react-compiler/react-compiler
  drag(drop(ref))

  return (
    <Component
      ref={ref}
      data-handler-id={handlerId}
      sx={{ opacity: isDragging ? 0 : 1 }}
      {...componentProps}
    >
      {children}
    </Component>
  )
}
