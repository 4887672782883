import { ListItem } from '@mui/material'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { Form } from '..'
import { ManufacturersInput } from '../../../../inputs/manufacturers-input'

export const ManufacturerInput = () => {
  const { control } = useFormContext<Form>()
  const { t } = useTranslation('reports')

  return (
    <ListItem>
      <ManufacturersInput
        name={`reportdefinition.filters.productManufacturerId`}
        control={control}
        label={t('create_dialog.manufacturer')}
        textFieldProps={{ placeholder: t('create_dialog.include_all') }}
      />
    </ListItem>
  )
}
