import { ListItemText, Stack } from '@mui/material'
import { SelectInput } from '../../../../inputs/select-input'
import { SwitchInput } from '../../../../inputs/switch-input'
import { SettingsListItem } from '../../../../components/settings-list-item'
import { SettingsListSection } from '../../../../components/settings-list-section'
import { SettingsCreditnotesalestypeEnum } from '../../../../generated/graphql'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useFormContext } from 'react-hook-form'
import { GeneralSettingsFormContext } from '../general-settings-form'

export const CreditNotes = () => {
  const { control, getValues } = useFormContext<GeneralSettingsFormContext>()
  const { t } = useTranslation(['settings', 'shared'])

  const creditNoteSaleTypesOptions = useMemo(
    () =>
      Object.values(SettingsCreditnotesalestypeEnum).map((value) => {
        const lowercaseValue = value.toLowerCase()
        return {
          name: `${t(
            `settings:general.credit_notes.creditnotesalestype_${lowercaseValue}`,
          )} (${t(`settings:general.credit_notes.creditnotesalestype_${lowercaseValue}_description`)})`,
          value,
        }
      }),
    [t],
  )

  const creditNoteSaleValidityOptions = useMemo(() => {
    const initialValue = getValues('creditnotevalidmonths')
    const monthValues = [1, 2, 3, 6, 12, 24, 36, 60]

    if (initialValue && !monthValues.includes(initialValue)) {
      monthValues.push(initialValue)
    }

    return [
      {
        name: t('settings:general.credit_notes.unlimited_validity'),
        value: null,
      },
      ...monthValues
        .sort((a, b) => a - b)
        .map((value) => ({
          name: t('shared:date.months', { count: value }),
          value,
        })),
    ]
  }, [t, getValues])

  return (
    <SettingsListSection title={t('settings:general.credit_notes.title')}>
      <SettingsListItem>
        <Stack sx={{ width: '100%' }}>
          <SelectInput
            label={t('settings:general.credit_notes.creditnotesalestype_label')}
            name="creditnotesalestype"
            options={creditNoteSaleTypesOptions}
            control={control}
            helperText={t(
              'settings:general.credit_notes.creditnotesalestype_helper',
            )}
          />
        </Stack>
      </SettingsListItem>
      <SettingsListItem divider>
        <SelectInput
          label={t('settings:general.credit_notes.creditnotevalidmonths_label')}
          name="creditnotevalidmonths"
          options={creditNoteSaleValidityOptions}
          helperText={t(
            'settings:general.credit_notes.creditnotevalidmonths_helper',
          )}
          control={control}
          slotProps={{ select: { displayEmpty: true } }}
        />
      </SettingsListItem>
      <SettingsListItem
        dense
        secondaryAction={
          <SwitchInput
            name="creditnoterequirespin"
            control={control}
            aria-label={t(
              'settings:general.credit_notes.creditnoterequirespin_label',
            )}
          />
        }
      >
        <ListItemText
          primary={t(
            'settings:general.credit_notes.creditnoterequirespin_label',
          )}
          secondary={t(
            'settings:general.credit_notes.creditnoterequirespin_helper',
          )}
        />
      </SettingsListItem>
    </SettingsListSection>
  )
}
