import { useApolloClient, useMutation, useQuery } from '@apollo/client'
import {
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material'
import { useEffect, useMemo, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { usePrevious } from 'react-use'
import {
  ActionType,
  AllShipmentActionsDocument,
  GetShipmentDocument,
  ReasonCodeType,
  ShipmentState,
  AddShipmentActionDocument,
  AllReasonCodesDocument,
} from '../../../generated/graphql'
import { useTracking } from '../../../hooks/tracking'
import { getShipmentsErrorMessage } from '../shared'

export type NewShipmentStates = { state: ShipmentState; reasonCode?: string }[]

export type StateDialogProps = {
  open: boolean
  state?: ShipmentState
  shipmentVersion: number
  shipmentId: string
  allItemsReceived?: boolean
  hasUndeclaredItems?: boolean
  onClose(): void
  onSuccess?(): void
  onError?(errorMessage: string): void
  dataTestid?: string
}

type Form = {
  state: ShipmentState
  reasonCode?: string
}

export const SetStateDialog = (props: StateDialogProps) => {
  const { t } = useTranslation(['shipments', 'shared'])
  const dialogName = 'set-state'
  const {
    trackButtonClickEvent,
    trackDialogClose,
    trackDialogOpen,
    trackFormError,
    trackFormSuccess,
  } = useTracking()
  const prevOpen = usePrevious(props.open)
  const [loading, setLoading] = useState(false)
  const { formState, reset, handleSubmit, control, watch } = useForm<Form>({
    mode: 'onChange',
  })
  const client = useApolloClient()
  const [addShipmentAction] = useMutation(AddShipmentActionDocument)

  const { data: reasonCodesData } = useQuery(AllReasonCodesDocument, {
    variables: {
      type: ReasonCodeType.ShipmentIn,
    },
  })
  const reasonCodes = reasonCodesData?.allReasonCodes || []

  const shipmentStateOrder = [
    ShipmentState.New,
    ShipmentState.Packed,
    ShipmentState.TransportOrdered,
    ShipmentState.ReadyForPickup,
    ShipmentState.InTransit,
  ]

  const shipmentStates = useMemo(() => {
    switch (props.state) {
      case ShipmentState.New:
        return [
          ShipmentState.Packed,
          ShipmentState.TransportOrdered,
          ShipmentState.ReadyForPickup,
          ShipmentState.InTransit,
          ShipmentState.Cancelled,
        ]
      case ShipmentState.Packed:
        return [
          ShipmentState.TransportOrdered,
          ShipmentState.ReadyForPickup,
          ShipmentState.InTransit,
          ShipmentState.Cancelled,
        ]
      case ShipmentState.TransportOrdered:
        return [
          ShipmentState.ReadyForPickup,
          ShipmentState.InTransit,
          ShipmentState.Cancelled,
        ]
      case ShipmentState.ReadyForPickup:
        return [ShipmentState.InTransit, ShipmentState.Cancelled]
      case ShipmentState.InTransit:
        return [ShipmentState.Arrived, ShipmentState.Cancelled]
      case ShipmentState.Arrived:
        return [ShipmentState.Received, ShipmentState.Cancelled]
      case ShipmentState.Received:
        return props.allItemsReceived && !props.hasUndeclaredItems
          ? [ShipmentState.Closed, ShipmentState.Cancelled]
          : [ShipmentState.ClosedIncomplete, ShipmentState.Cancelled]

      default:
        return []
    }
  }, [props.allItemsReceived, props.hasUndeclaredItems, props.state])

  const onSubmit = async (formData: Form) => {
    try {
      setLoading(true)
      const newStates = [formData]

      const currentStateIndex = shipmentStateOrder.findIndex(
        (x) => x === props.state,
      )
      if (currentStateIndex !== -1) {
        const destinationStateIndex = shipmentStateOrder.findIndex(
          (x) => x === formData.state,
        )
        newStates.unshift(
          ...shipmentStateOrder
            .slice(
              currentStateIndex + 1,
              destinationStateIndex === -1
                ? shipmentStateOrder.length
                : destinationStateIndex,
            )
            .map((x) => ({ state: x })),
        )
      }

      for (const [i, newState] of newStates.entries()) {
        await addShipmentAction({
          variables: {
            shipmentId: props.shipmentId,
            data: {
              shipment_version: props.shipmentVersion + i,
              action: ActionType.SetState,
              state: newState.state,
              state_reason_code: newState.reasonCode,
            },
          },
        })
      }

      trackFormSuccess({ name: `${dialogName}-dialog` })

      await client.refetchQueries({
        include: [GetShipmentDocument, AllShipmentActionsDocument],
      })

      props.onSuccess?.()
    } catch (error) {
      const errorMessage = getShipmentsErrorMessage(error)

      trackFormError({ name: `${dialogName}-dialog`, errorMessage })
      if (errorMessage) props.onError?.(errorMessage)
    } finally {
      setLoading(false)
    }
  }

  const onClose = () => {
    trackDialogClose({ name: dialogName })
    if (props.onClose) {
      props.onClose()
    }
  }

  useEffect(() => {
    if (props.open && !prevOpen) {
      trackDialogOpen({ name: dialogName })
      reset({ state: shipmentStates?.[0] }, { keepDefaultValues: false })
    }
  }, [
    props.open,
    prevOpen,
    trackDialogOpen,
    props.state,
    reset,
    shipmentStates,
  ])

  const state = watch('state')

  return (
    <Dialog
      open={props.open}
      onClose={onClose}
      maxWidth="sm"
      fullWidth
      data-testid="set-state-dialog"
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogTitle
          type="extended"
          sx={{
            paddingBottom: (theme) => theme.spacing(2),
          }}
        >
          {t('shipments:set_state_dialog.title')}
        </DialogTitle>
        <DialogContent>
          <Controller
            control={control}
            name="state"
            render={({ field, fieldState: { error } }) => (
              <>
                <InputLabel>{t('shipments:set_state_dialog.state')}</InputLabel>
                <Select
                  data-testid="select-shipment-state"
                  value={field.value || ''}
                  onChange={(event) => {
                    field.onChange(event.target.value)
                  }}
                  inputProps={{ 'data-testid': 'shipment-state-field' }}
                >
                  {shipmentStates.map((state) => (
                    <MenuItem
                      data-testid="shipment-state-option"
                      value={state}
                      key={state}
                    >
                      {t(`shipments:shipment_state.${state}`)}
                    </MenuItem>
                  ))}
                </Select>
                <FormHelperText error={!!error?.message}>
                  {error?.message}
                </FormHelperText>
              </>
            )}
          />
          {[ShipmentState.Cancelled, ShipmentState.ClosedIncomplete].includes(
            state,
          ) &&
            reasonCodes.length > 0 && (
              <Controller
                control={control}
                name="reasonCode"
                rules={{
                  validate: (v) =>
                    [
                      ShipmentState.Cancelled,
                      ShipmentState.ClosedIncomplete,
                    ].includes(state) && !v
                      ? t('shared:validation.field_required', {
                          field: t('shipments:set_state_dialog.reason_code'),
                        })
                      : true,
                }}
                render={({ field, fieldState: { error } }) => (
                  <>
                    <InputLabel sx={{ mt: 2 }}>
                      {t('shipments:set_state_dialog.reason_code')}
                    </InputLabel>
                    <Select
                      data-testid="select-reason-code"
                      value={field.value || ''}
                      onChange={(event) => {
                        field.onChange(event.target.value)
                      }}
                      inputProps={{
                        'data-testid': 'shipment-reason-code-field',
                      }}
                      displayEmpty
                      error={!!error?.message}
                    >
                      <MenuItem value={''} disabled>
                        {t('shared:label.select')}
                      </MenuItem>
                      {reasonCodes.map(({ reasoncode, reasoncodeid, name }) => (
                        <MenuItem
                          value={reasoncode}
                          key={reasoncodeid}
                          data-testid={`button-reason-code-${reasoncodeid}`}
                        >
                          {name || reasoncode}
                        </MenuItem>
                      ))}
                    </Select>
                    <FormHelperText error={!!error?.message}>
                      {error?.message}
                    </FormHelperText>
                  </>
                )}
              />
            )}
        </DialogContent>
        <DialogActions>
          <Button
            onClick={trackButtonClickEvent(
              {
                name: `${dialogName}-dialog-cancel`,
              },
              onClose,
            )}
            data-testid="set-state-canel"
            color="secondary"
            size="small"
            type="button"
          >
            {t('shared:action.cancel')}
          </Button>
          <Button
            type="submit"
            size="small"
            onClick={trackButtonClickEvent({
              name: `${dialogName}-dialog-set`,
            })}
            disabled={!formState.isValid}
            data-testid="set-state"
            loading={loading}
          >
            {t('shared:action.set')}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  )
}
