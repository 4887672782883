import { useState } from 'react'
import { Box, FormHelperText, Paper, SxProps } from '@mui/material'
import {
  Button,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Menu,
  MenuItem,
  Stack,
} from '@mui/material'
import { DeleteIcon, DragHandleIcon } from '@sitoo/mui-components'
import { HTML5Backend } from 'react-dnd-html5-backend'
import { DndProvider } from 'react-dnd/dist/core/DndProvider'
import {
  Control,
  FieldValues,
  Path,
  RegisterOptions,
  useController,
  useFieldArray,
  useForm,
} from 'react-hook-form'
import { DraggableItem } from '../../components/draggable-item'
import { useTranslation } from 'react-i18next'
import { useTracking } from '../../hooks/tracking'

export type ListOption<TValue> = {
  value: TValue
  label: string
  description?: string | null
}

type Props<
  TFieldValues extends FieldValues,
  TName extends Path<TFieldValues>,
  TValue,
> = {
  isDraggable?: boolean
  options: ListOption<TValue>[]
  name: TName
  control: Control<TFieldValues>
  sx?: SxProps
  rules?: Omit<
    RegisterOptions<TFieldValues, TName>,
    'valueAsNumber' | 'valueAsDate' | 'setValueAs'
  >
  addLabel?: string
}

export const PredefinedValuesInput = <
  TFieldValues extends FieldValues,
  TValue,
  TName extends Path<TFieldValues>,
>({
  control,
  options,
  isDraggable = true,
  name,
  sx,
  rules,
  addLabel,
}: Props<TFieldValues, TName, TValue>) => {
  const { t } = useTranslation(['filter', 'shared'])
  const {
    field: { value: parentValue, onChange },
    fieldState,
  } = useController<TFieldValues, TName>({ name, control, rules })

  const { control: localControl, getValues } = useForm<{
    root: { value: TValue }[]
  }>({
    defaultValues: {
      root: Array.isArray(parentValue)
        ? (parentValue as TValue[]).map((value) => ({ value }))
        : [],
    },
  })

  const { fields, append, insert, remove, move } = useFieldArray({
    name: 'root',
    control: localControl,
  })
  const { trackInputChange, trackButtonClick } = useTracking()

  const errorMessage = fieldState.error?.message

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const menuOpen = Boolean(anchorEl)

  const handleAddClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleSelect = (option: ListOption<TValue>) => {
    if (isDraggable) {
      append({ value: option.value })
    } else {
      // Preserve order when is not draggable
      const index =
        options.findIndex(({ value }) => value === option.value) || 0
      insert(index, { value: option.value })
    }

    handleChange()
    setAnchorEl(null)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleChange = () => {
    onChange(getValues('root').map(({ value }) => value))
  }

  const nonSelectedOptions = options.filter(
    (option) => !fields.some((field) => field.value === option.value),
  )

  const getOption = (value: TValue) =>
    options.find((option) => option.value === value)

  return (
    <Paper elevation={0} sx={{ my: 1, ...sx }}>
      <DndProvider backend={HTML5Backend}>
        <List sx={{ '.MuiListItem-root.MuiListItem-root': { p: 0 } }}>
          {fields.map((field, index) => (
            <DraggableItem
              key={field.id}
              id={field.id}
              index={index}
              divider
              moveField={(itemA, itemB) => {
                move(itemA, itemB)
                handleChange()
              }}
              component={ListItem}
              disabled={!isDraggable}
              secondaryAction={
                <Stack direction="row" gap={1} alignItems={'center'}>
                  <IconButton
                    aria-label={`${t('shared:action.remove')} ${getOption(field.value)?.label}`}
                    onClick={() => {
                      remove(index)
                      handleChange()
                      trackButtonClick({
                        name: 'remove-value',
                        category: 'predefined-values-input',
                      })
                    }}
                  >
                    <DeleteIcon />
                  </IconButton>
                  {isDraggable && (
                    <Box
                      sx={{
                        color: (theme) => theme.palette.gray60,
                        cursor: 'move',
                        display: 'flex',
                        alignItems: 'center',
                      }}
                    >
                      <DragHandleIcon />
                    </Box>
                  )}
                </Stack>
              }
            >
              <ListItemText
                primary={getOption(field.value)?.label}
                secondary={getOption(field.value)?.description}
              />
            </DraggableItem>
          ))}
        </List>
      </DndProvider>
      <FormHelperText error variant="standard" sx={{ mb: 1 }}>
        {errorMessage}
      </FormHelperText>
      <Box sx={{ mt: 2 }}>
        <Button
          fullWidth
          onClick={handleAddClick}
          color="tertiary"
          disabled={nonSelectedOptions.length === 0}
        >
          {addLabel || t('filter:predefined_values.add_field')}
        </Button>
      </Box>
      <Menu
        anchorEl={anchorEl}
        open={menuOpen}
        onClose={handleClose}
        slotProps={{
          paper: {
            sx: {
              width: anchorEl?.clientWidth,
            },
          },
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        {nonSelectedOptions.map((option, index) => (
          <MenuItem
            key={`${option.label}-${index}`}
            onClick={(event) => {
              handleSelect(option)
              trackInputChange({
                name: 'add-value',
                value: option.label,
              })(event)
            }}
          >
            {option.label}
          </MenuItem>
        ))}
      </Menu>
    </Paper>
  )
}
