import { Container } from '@mui/material'
import { useEffect, useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import { RootRoute } from '../..'
import { PageHeader } from '../../../components/page-header'
import {
  GetShipmentQuery,
  GetShipmentDocument,
} from '../../../generated/graphql'
import { usePageTitle } from '../../../hooks/title'
import { useAbsolutePath } from '../../../hooks/absolute-path'
import { useTracking } from '../../../hooks/tracking'
import { extractGraphqlErrors } from '../../../utils/extract-graphql-errors'
import { useSnackbar } from 'notistack'
import { Information } from '../information'
import { BaseShipmentFormContext, getShipmentsErrorMessage } from '../shared'
import { RouteLeavingGuard } from '../../../components/route-leaving-guard'
import { ApolloError, useLazyQuery } from '@apollo/client'
import { NewShipmentStates } from '../set-state-dialog'
import { stripNullValues } from '../../../utils/strip-null-values'
import { FormPagePlaceholder } from '../../../components/form-page-placeholder'
import { containsDirtyFields } from '../../../utils/contains-dirty-fields'

type UndeclaredItemsData = {
  reasonCode?: string
  warehouseId?: number
  note?: string
  items?: GetShipmentQuery['shipment']['items_undeclared']
}

export type EditShipmentFormContext = BaseShipmentFormContext &
  GetShipmentQuery['shipment'] & {
    newStates?: NewShipmentStates
    undeclaredItemsData: UndeclaredItemsData
  }

export const EditShipmentPage = () => {
  const { id: shipmentId } = useParams()
  const { t } = useTranslation(['shipments', 'shared'])
  usePageTitle(t('shipments:page_title_edit_shipment'))
  const navigate = useNavigate()
  const { track } = useTracking()
  const { enqueueSnackbar } = useSnackbar()
  const [isInitialized, setInitialized] = useState(false)

  const onGetShipmentsError = (error: ApolloError) => {
    track('ShipmentFetchError', {
      category: 'Shipment',
      name: 'ShipmentFetchError',
      ...extractGraphqlErrors(error)
        .map((error) => ({
          code: (error.extensions?.['code'] || error.extensions?.['code']) as
            | string
            | undefined,
          value: error.extensions?.['value'] as string | undefined,
          path: error.path?.join(' - '),
        }))
        .reduce(
          (p, c, i) => ({
            ...p,
            [`error.${i}.code`]: c.code,
            [`error.${i}.value`]: c.value,
            [`error.${i}.path`]: c.path,
          }),
          {},
        ),
    })

    const errorMessage = getShipmentsErrorMessage(error)
    enqueueSnackbar(errorMessage, { variant: 'error' })
    void navigate(generatePath(RootRoute.Shipments))
  }

  const [fetchShipment, { loading, data: shipmentData }] = useLazyQuery(
    GetShipmentDocument,
    {
      fetchPolicy: 'cache-and-network',
      onError: onGetShipmentsError,
    },
  )

  const formContext = useForm<EditShipmentFormContext>()
  const { reset } = formContext

  const generatePath = useAbsolutePath()

  const isLoading = loading

  useEffect(() => {
    if (!shipmentId) {
      void navigate(generatePath(RootRoute.Shipments))
      return
    }

    void fetchShipment({ variables: { shipmentId } }).then(({ data }) => {
      if (!data?.shipment) {
        void navigate(generatePath(RootRoute.Shipments), {
          state: { ignoreLeavingGuard: true },
        })
        return
      }

      reset(
        {
          ...stripNullValues(data?.shipment),
          newStates: [],
          undeclaredItemsData: { items: [] },
        },
        { keepDefaultValues: false },
      )
      setInitialized(true)
    })
  }, [fetchShipment, reset, generatePath, navigate, shipmentId])

  useEffect(() => {
    if (shipmentData) {
      reset(
        {
          ...stripNullValues(shipmentData.shipment),
          newStates: [],
          undeclaredItemsData: { items: [] },
        },
        {
          keepDefaultValues: false,
        },
      )
    }
  }, [reset, shipmentData])

  return (
    <>
      <RouteLeavingGuard
        when={
          !isLoading &&
          containsDirtyFields(formContext.formState.dirtyFields) &&
          !formContext.formState.isSubmitSuccessful
        }
      />
      <PageHeader
        title={shipmentId || t('shipments:page_title_edit_shipment_title')}
        backTo={generatePath(RootRoute.Shipments)}
        backText={t('shared:menu.shipments')}
        showBackButton={true}
        isSticky
      />

      <Container>
        <FormProvider {...formContext}>
          {!isInitialized ? <FormPagePlaceholder /> : <Information />}
        </FormProvider>
      </Container>
    </>
  )
}
