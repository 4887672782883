import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { GeneralSettingsFormContext } from '../general-settings-form'
import { SettingsListSection } from '../../../../components/settings-list-section'
import { SelectInput } from '../../../../inputs/select-input'
import { OtherDeliveryOption } from '../../../../generated/graphql'
import { SettingsListItem } from '../../../../components/settings-list-item'

export const PosDeliveryOptions = () => {
  const { control } = useFormContext<GeneralSettingsFormContext>()
  const { t } = useTranslation(['shared', 'settings'])

  const permissionOptions = Object.values(OtherDeliveryOption).map((value) => ({
    value: value,
    name: t(`settings:general.pos_delivery_options.${value}`),
  }))

  return (
    <SettingsListSection
      title={t(`settings:general.pos_delivery_options.title`)}
    >
      <SettingsListItem>
        <SelectInput
          name="other_delivery_online"
          label={t(`settings:general.pos_delivery_options.online_stores_label`)}
          helperText={t(
            `settings:general.pos_delivery_options.online_stores_helper`,
          )}
          control={control}
          options={permissionOptions}
        />
      </SettingsListItem>
      <SettingsListItem>
        <SelectInput
          name="other_delivery_stores"
          label={t(
            `settings:general.pos_delivery_options.physical_stores_label`,
          )}
          helperText={t(
            `settings:general.pos_delivery_options.physical_stores_helper`,
          )}
          control={control}
          options={permissionOptions}
        />
      </SettingsListItem>
    </SettingsListSection>
  )
}
