import { useCallback } from 'react'
import { ReportResultItemValueDisplayType } from '../../../generated/graphql'
import { formatDecimal } from '../../../utils/format/number'
import { useTranslation } from 'react-i18next'
import { useLocalizedDate } from '../../../hooks/localized-date'
import { useMoney } from '../../../hooks/money'
import { FOOTER_ROW_ID } from '.'

export const useFormatReportResultValue = () => {
  const { t } = useTranslation(['reports'])
  const { formatCurrency } = useMoney()
  const { formatDate } = useLocalizedDate()

  const formatReportResultValue = useCallback(
    (
      value: string | number,
      rowId: number | undefined,
      type?: ReportResultItemValueDisplayType | null,
    ) => {
      if (value == null) return '-'
      if (!type) return value

      if (typeof value === 'string') {
        switch (type) {
          case ReportResultItemValueDisplayType.TimestampYear:
            return formatDate(value, { year: 'numeric' })
          case ReportResultItemValueDisplayType.TimestampYearMonth:
            return formatDate(value, {
              year: 'numeric',
              month: 'long',
            })
          case ReportResultItemValueDisplayType.TimestampYearMonthDay:
            return formatDate(value, {
              year: 'numeric',
              month: 'long',
              day: 'numeric',
            })
          case ReportResultItemValueDisplayType.Money:
            return formatCurrency(value, {
              hideCurrency: rowId !== FOOTER_ROW_ID,
            })
          case ReportResultItemValueDisplayType.MoneyFixed: {
            return formatCurrency(value, {
              forceDecimals: true,
              hideCurrency: rowId !== FOOTER_ROW_ID,
            })
          }
          case ReportResultItemValueDisplayType.DecimalQuantity:
            return formatDecimal(value, { minDecimals: 0 })
          default:
            return value
        }
      }
      if (typeof value === 'number') {
        switch (type) {
          case ReportResultItemValueDisplayType.IntegerYearWeek: {
            const valueAsString = String(value)
            const year = valueAsString.substring(0, 4)
            let week = valueAsString.substring(4)
            if (week.startsWith('0')) {
              week = week.substring(1)
            }
            return t('reports:formats.integer_year_week', { year, week })
          }
          case ReportResultItemValueDisplayType.Double:
            return formatDecimal(value, { maxDecimals: 2 })
          case ReportResultItemValueDisplayType.DoublePercentage:
            return `${formatDecimal(value * 100, { maxDecimals: 1 })}%`
          default:
            return value
        }
      }

      return value
    },
    [formatCurrency, formatDate, t],
  )

  return formatReportResultValue
}
