import { Box, Button } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { RootRoute } from '../../..'
import { ViewPanel } from '../../../../components/view-panel'
import { useAbsolutePath } from '../../../../hooks/absolute-path'
import { useAuthorization } from '../../../../hooks/authorization'
import { HeaderSection } from './header-section'
import { ReportsPanel } from './reports-panel'
import { TabsWithPanels } from './tabs-with-panels'
import { useTracking } from '../../../../hooks/tracking'

type Props = { selectedReportId: number }

export const SelectionViewPanel = ({ selectedReportId }: Props) => {
  const { t } = useTranslation('reports')

  const {
    modules: { reportsFullPermission },
  } = useAuthorization()

  const navigate = useNavigate()
  const generatePath = useAbsolutePath()
  const { trackButtonClick } = useTracking()

  const handleSelect = (reportId: number) => {
    void navigate(generatePath(RootRoute.ReportTemplates, { id: reportId }), {
      replace: true,
    })
    trackButtonClick({ name: 'create-report-job-select-template' })
  }

  const handleUseAsTemplate = () => {
    void navigate({
      pathname: generatePath(RootRoute.ReportTemplatesNewJob, {
        id: selectedReportId,
      }),
    })
  }

  return (
    <ViewPanel open={true}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
        }}
      >
        <HeaderSection />
        <Box
          sx={{
            backgroundColor: (theme) => theme.palette.background.paper,
            marginTop: 1,
            flexGrow: 1,
          }}
        >
          {reportsFullPermission ? (
            <TabsWithPanels
              onSelect={handleSelect}
              selectedReportId={selectedReportId}
            />
          ) : (
            <ReportsPanel
              onSelect={handleSelect}
              selectedReportId={selectedReportId}
            />
          )}
        </Box>
        <Box
          sx={{
            position: 'sticky',
            bottom: 0,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'end',
            padding: 1,
            background: (theme) => theme.palette.background.paper,
            borderTop: (theme) => `1px solid ${theme.palette.divider}`,
          }}
        >
          <Button
            disabled={!selectedReportId}
            size="small"
            onClick={handleUseAsTemplate}
            data-testid="use-as-template-button"
          >
            {t('create.use_as_template')}
          </Button>
        </Box>
      </Box>
    </ViewPanel>
  )
}
