const TIME_ZONES = {
  'Africa/Abidjan': false,
  'Africa/Accra': true,
  'Africa/Addis_Ababa': true,
  'Africa/Algiers': true,
  'Africa/Asmara': false,
  'Africa/Asmera': false,
  'Africa/Bamako': false,
  'Africa/Bangui': false,
  'Africa/Banjul': false,
  'Africa/Bissau': false,
  'Africa/Blantyre': false,
  'Africa/Brazzaville': false,
  'Africa/Bujumbura': false,
  'Africa/Cairo': true,
  'Africa/Casablanca': true,
  'Africa/Ceuta': false,
  'Africa/Conakry': false,
  'Africa/Dakar': false,
  'Africa/Dar_es_Salaam': true,
  'Africa/Djibouti': false,
  'Africa/Douala': false,
  'Africa/El_Aaiun': false,
  'Africa/Freetown': false,
  'Africa/Gaborone': false,
  'Africa/Harare': true,
  'Africa/Johannesburg': true,
  'Africa/Juba': false,
  'Africa/Kampala': false,
  'Africa/Khartoum': true,
  'Africa/Kigali': false,
  'Africa/Kinshasa': true,
  'Africa/Lagos': true,
  'Africa/Libreville': false,
  'Africa/Lome': false,
  'Africa/Luanda': false,
  'Africa/Lubumbashi': false,
  'Africa/Lusaka': false,
  'Africa/Malabo': false,
  'Africa/Maputo': false,
  'Africa/Maseru': false,
  'Africa/Mbabane': false,
  'Africa/Mogadishu': false,
  'Africa/Monrovia': false,
  'Africa/Nairobi': true,
  'Africa/Ndjamena': false,
  'Africa/Niamey': false,
  'Africa/Nouakchott': false,
  'Africa/Ouagadougou': false,
  'Africa/Porto-Novo': false,
  'Africa/Sao_Tome': false,
  'Africa/Timbuktu': false,
  'Africa/Tripoli': false,
  'Africa/Tunis': false,
  'Africa/Windhoek': false,
  'America/Adak': false,
  'America/Anchorage': true,
  'America/Anguilla': false,
  'America/Antigua': false,
  'America/Araguaina': false,
  'America/Argentina/Buenos_Aires': true,
  'America/Argentina/Catamarca': false,
  'America/Argentina/ComodRivadavia': false,
  'America/Argentina/Cordoba': false,
  'America/Argentina/Jujuy': false,
  'America/Argentina/La_Rioja': false,
  'America/Argentina/Mendoza': false,
  'America/Argentina/Rio_Gallegos': false,
  'America/Argentina/Salta': false,
  'America/Argentina/San_Juan': true,
  'America/Argentina/San_Luis': false,
  'America/Argentina/Tucuman': false,
  'America/Argentina/Ushuaia': false,
  'America/Aruba': false,
  'America/Asuncion': true,
  'America/Atikokan': false,
  'America/Atka': false,
  'America/Bahia': false,
  'America/Bahia_Banderas': false,
  'America/Barbados': false,
  'America/Belem': false,
  'America/Belize': false,
  'America/Blanc-Sablon': false,
  'America/Boa_Vista': false,
  'America/Bogota': true,
  'America/Boise': false,
  'America/Buenos_Aires': false,
  'America/Cambridge_Bay': false,
  'America/Campo_Grande': false,
  'America/Cancun': false,
  'America/Caracas': true,
  'America/Catamarca': false,
  'America/Cayenne': false,
  'America/Cayman': false,
  'America/Chicago': true,
  'America/Chihuahua': false,
  'America/Coral_Harbour': false,
  'America/Cordoba': false,
  'America/Costa_Rica': false,
  'America/Creston': false,
  'America/Cuiaba': false,
  'America/Curacao': false,
  'America/Danmarkshavn': false,
  'America/Dawson': false,
  'America/Dawson_Creek': false,
  'America/Denver': true,
  'America/Detroit': true,
  'America/Dominica': false,
  'America/Edmonton': true,
  'America/Eirunepe': false,
  'America/El_Salvador': false,
  'America/Ensenada': false,
  'America/Fort_Wayne': false,
  'America/Fortaleza': false,
  'America/Glace_Bay': false,
  'America/Godthab': false,
  'America/Goose_Bay': false,
  'America/Grand_Turk': false,
  'America/Grenada': false,
  'America/Guadeloupe': false,
  'America/Guatemala': true,
  'America/Guayaquil': false,
  'America/Guyana': false,
  'America/Halifax': true,
  'America/Havana': true,
  'America/Hermosillo': false,
  'America/Indiana/Indianapolis': true,
  'America/Indiana/Knox': false,
  'America/Indiana/Marengo': false,
  'America/Indiana/Petersburg': false,
  'America/Indiana/Tell_City': false,
  'America/Indiana/Vevay': false,
  'America/Indiana/Vincennes': false,
  'America/Indiana/Winamac': false,
  'America/Indianapolis': false,
  'America/Inuvik': false,
  'America/Iqaluit': false,
  'America/Jamaica': false,
  'America/Jujuy': false,
  'America/Juneau': false,
  'America/Kentucky/Louisville': false,
  'America/Kentucky/Monticello': false,
  'America/Knox_IN': false,
  'America/Kralendijk': false,
  'America/La_Paz': true,
  'America/Lima': true,
  'America/Los_Angeles': true,
  'America/Louisville': false,
  'America/Lower_Princes': false,
  'America/Maceio': false,
  'America/Managua': true,
  'America/Manaus': false,
  'America/Marigot': false,
  'America/Martinique': false,
  'America/Matamoros': false,
  'America/Mazatlan': false,
  'America/Mendoza': false,
  'America/Menominee': false,
  'America/Merida': false,
  'America/Metlakatla': false,
  'America/Mexico_City': true,
  'America/Miquelon': false,
  'America/Moncton': false,
  'America/Monterrey': false,
  'America/Montevideo': true,
  'America/Montreal': true,
  'America/Montserrat': false,
  'America/Nassau': true,
  'America/New_York': true,
  'America/Nipigon': false,
  'America/Nome': false,
  'America/Noronha': false,
  'America/North_Dakota/Beulah': false,
  'America/North_Dakota/Center': false,
  'America/North_Dakota/New_Salem': false,
  'America/Ojinaga': false,
  'America/Panama': false,
  'America/Pangnirtung': false,
  'America/Paramaribo': false,
  'America/Phoenix': true,
  'America/Port-au-Prince': false,
  'America/Port_of_Spain': false,
  'America/Porto_Acre': false,
  'America/Porto_Velho': false,
  'America/Puerto_Rico': false,
  'America/Rainy_River': false,
  'America/Rankin_Inlet': false,
  'America/Recife': false,
  'America/Regina': false,
  'America/Resolute': false,
  'America/Rio_Branco': false,
  'America/Rosario': false,
  'America/Santa_Isabel': false,
  'America/Santarem': false,
  'America/Santiago': true,
  'America/Santo_Domingo': true,
  'America/Sao_Paulo': true,
  'America/Scoresbysund': false,
  'America/Shiprock': false,
  'America/Sitka': false,
  'America/St_Barthelemy': false,
  'America/St_Johns': false,
  'America/St_Kitts': false,
  'America/St_Lucia': false,
  'America/St_Thomas': false,
  'America/St_Vincent': false,
  'America/Swift_Current': false,
  'America/Tegucigalpa': true,
  'America/Thule': false,
  'America/Thunder_Bay': false,
  'America/Tijuana': false,
  'America/Toronto': true,
  'America/Tortola': false,
  'America/Vancouver': true,
  'America/Virgin': false,
  'America/Whitehorse': false,
  'America/Winnipeg': true,
  'America/Yakutat': false,
  'America/Yellowknife': false,
  'Antarctica/Casey': false,
  'Antarctica/Davis': false,
  'Antarctica/DumontDUrville': false,
  'Antarctica/Macquarie': false,
  'Antarctica/Mawson': false,
  'Antarctica/McMurdo': false,
  'Antarctica/Palmer': false,
  'Antarctica/Rothera': false,
  'Antarctica/South_Pole': false,
  'Antarctica/Syowa': false,
  'Antarctica/Troll': false,
  'Antarctica/Vostok': false,
  'Arctic/Longyearbyen': false,
  'Asia/Aden': false,
  'Asia/Almaty': true,
  'Asia/Amman': true,
  'Asia/Anadyr': true,
  'Asia/Aqtau': false,
  'Asia/Aqtobe': false,
  'Asia/Ashgabat': false,
  'Asia/Ashkhabad': false,
  'Asia/Baghdad': true,
  'Asia/Bahrain': false,
  'Asia/Baku': false,
  'Asia/Bangkok': true,
  'Asia/Beirut': true,
  'Asia/Bishkek': false,
  'Asia/Brunei': false,
  'Asia/Calcutta': false,
  'Asia/Chita': false,
  'Asia/Choibalsan': false,
  'Asia/Chongqing': false,
  'Asia/Chungking': false,
  'Asia/Colombo': false,
  'Asia/Dacca': false,
  'Asia/Damascus': false,
  'Asia/Dhaka': true,
  'Asia/Dili': false,
  'Asia/Dubai': true,
  'Asia/Dushanbe': false,
  'Asia/Gaza': false,
  'Asia/Harbin': false,
  'Asia/Hebron': false,
  'Asia/Ho_Chi_Minh': false,
  'Asia/Hong_Kong': true,
  'Asia/Hovd': false,
  'Asia/Irkutsk': false,
  'Asia/Istanbul': false,
  'Asia/Jakarta': true,
  'Asia/Jayapura': false,
  'Asia/Jerusalem': true,
  'Asia/Kabul': true,
  'Asia/Kamchatka': false,
  'Asia/Karachi': true,
  'Asia/Kashgar': false,
  'Asia/Kathmandu': true,
  'Asia/Katmandu': false,
  'Asia/Khandyga': false,
  'Asia/Kolkata': false,
  'Asia/Krasnoyarsk': false,
  'Asia/Kuala_Lumpur': true,
  'Asia/Kuching': false,
  'Asia/Kuwait': true,
  'Asia/Macao': false,
  'Asia/Macau': false,
  'Asia/Magadan': false,
  'Asia/Makassar': false,
  'Asia/Manila': true,
  'Asia/Muscat': false,
  'Asia/Nicosia': false,
  'Asia/Novokuznetsk': false,
  'Asia/Novosibirsk': false,
  'Asia/Omsk': false,
  'Asia/Oral': false,
  'Asia/Phnom_Penh': false,
  'Asia/Pontianak': false,
  'Asia/Pyongyang': false,
  'Asia/Qatar': false,
  'Asia/Qyzylorda': false,
  'Asia/Rangoon': false,
  'Asia/Riyadh': true,
  'Asia/Saigon': false,
  'Asia/Sakhalin': false,
  'Asia/Samarkand': false,
  'Asia/Seoul': true,
  'Asia/Shanghai': true,
  'Asia/Singapore': true,
  'Asia/Srednekolymsk': false,
  'Asia/Taipei': true,
  'Asia/Tashkent': true,
  'Asia/Tbilisi': false,
  'Asia/Tehran': true,
  'Asia/Tel_Aviv': false,
  'Asia/Thimbu': false,
  'Asia/Thimphu': false,
  'Asia/Tokyo': true,
  'Asia/Ujung_Pandang': false,
  'Asia/Ulaanbaatar': false,
  'Asia/Ulan_Bator': false,
  'Asia/Urumqi': false,
  'Asia/Ust-Nera': false,
  'Asia/Vientiane': false,
  'Asia/Vladivostok': false,
  'Asia/Yakutsk': false,
  'Asia/Yekaterinburg': false,
  'Asia/Yerevan': false,
  'Atlantic/Azores': false,
  'Atlantic/Bermuda': false,
  'Atlantic/Canary': false,
  'Atlantic/Cape_Verde': false,
  'Atlantic/Faeroe': false,
  'Atlantic/Faroe': false,
  'Atlantic/Jan_Mayen': false,
  'Atlantic/Madeira': false,
  'Atlantic/Reykjavik': true,
  'Atlantic/South_Georgia': false,
  'Atlantic/St_Helena': false,
  'Atlantic/Stanley': false,
  'Australia/ACT': false,
  'Australia/Adelaide': true,
  'Australia/Brisbane': true,
  'Australia/Broken_Hill': false,
  'Australia/Canberra': false,
  'Australia/Currie': false,
  'Australia/Darwin': true,
  'Australia/Eucla': false,
  'Australia/Hobart': false,
  'Australia/LHI': false,
  'Australia/Lindeman': false,
  'Australia/Lord_Howe': false,
  'Australia/Melbourne': true,
  'Australia/NSW': false,
  'Australia/North': false,
  'Australia/Perth': true,
  'Australia/Queensland': false,
  'Australia/South': false,
  'Australia/Sydney': true,
  'Australia/Tasmania': false,
  'Australia/Victoria': false,
  'Australia/West': false,
  'Australia/Yancowinna': false,
  'Brazil/Acre': false,
  'Brazil/DeNoronha': false,
  'Brazil/East': false,
  'Brazil/West': false,
  CET: false,
  CST6CDT: false,
  'Canada/Atlantic': false,
  'Canada/Central': false,
  'Canada/Eastern': false,
  'Canada/Mountain': false,
  'Canada/Newfoundland': false,
  'Canada/Pacific': false,
  'Canada/Saskatchewan': false,
  'Canada/Yukon': false,
  'Chile/Continental': false,
  'Chile/EasterIsland': false,
  Cuba: false,
  EET: false,
  EST: false,
  EST5EDT: false,
  Egypt: false,
  Eire: false,
  'Etc/GMT': false,
  'Etc/GMT+0': false,
  'Etc/GMT+1': false,
  'Etc/GMT+10': false,
  'Etc/GMT+11': false,
  'Etc/GMT+12': false,
  'Etc/GMT+2': false,
  'Etc/GMT+3': false,
  'Etc/GMT+4': false,
  'Etc/GMT+5': false,
  'Etc/GMT+6': false,
  'Etc/GMT+7': false,
  'Etc/GMT+8': false,
  'Etc/GMT+9': false,
  'Etc/GMT-0': false,
  'Etc/GMT-1': false,
  'Etc/GMT-10': false,
  'Etc/GMT-11': false,
  'Etc/GMT-12': false,
  'Etc/GMT-13': false,
  'Etc/GMT-14': false,
  'Etc/GMT-2': false,
  'Etc/GMT-3': false,
  'Etc/GMT-4': false,
  'Etc/GMT-5': false,
  'Etc/GMT-6': false,
  'Etc/GMT-7': false,
  'Etc/GMT-8': false,
  'Etc/GMT-9': false,
  'Etc/GMT0': false,
  'Etc/Greenwich': false,
  'Etc/UCT': false,
  'Etc/UTC': false,
  'Etc/Universal': false,
  'Etc/Zulu': false,
  'Europe/Amsterdam': true,
  'Europe/Andorra': false,
  'Europe/Athens': true,
  'Europe/Belfast': false,
  'Europe/Belgrade': true,
  'Europe/Berlin': true,
  'Europe/Bratislava': false,
  'Europe/Brussels': true,
  'Europe/Bucharest': true,
  'Europe/Budapest': true,
  'Europe/Busingen': false,
  'Europe/Chisinau': false,
  'Europe/Copenhagen': true,
  'Europe/Dublin': true,
  'Europe/Gibraltar': false,
  'Europe/Guernsey': false,
  'Europe/Helsinki': true,
  'Europe/Isle_of_Man': false,
  'Europe/Istanbul': true,
  'Europe/Jersey': false,
  'Europe/Kaliningrad': false,
  'Europe/Kiev': false,
  'Europe/Lisbon': true,
  'Europe/Ljubljana': false,
  'Europe/London': true,
  'Europe/Luxembourg': false,
  'Europe/Madrid': true,
  'Europe/Malta': false,
  'Europe/Mariehamn': false,
  'Europe/Minsk': true,
  'Europe/Monaco': false,
  'Europe/Moscow': true,
  'Europe/Nicosia': false,
  'Europe/Oslo': true,
  'Europe/Paris': true,
  'Europe/Podgorica': false,
  'Europe/Prague': true,
  'Europe/Riga': false,
  'Europe/Rome': true,
  'Europe/Samara': false,
  'Europe/San_Marino': false,
  'Europe/Sarajevo': false,
  'Europe/Simferopol': false,
  'Europe/Skopje': false,
  'Europe/Sofia': true,
  'Europe/Stockholm': true,
  'Europe/Tallinn': true,
  'Europe/Tirane': false,
  'Europe/Tiraspol': false,
  'Europe/Uzhgorod': false,
  'Europe/Vaduz': false,
  'Europe/Vatican': false,
  'Europe/Vienna': true,
  'Europe/Vilnius': false,
  'Europe/Volgograd': false,
  'Europe/Warsaw': true,
  'Europe/Zagreb': true,
  'Europe/Zaporozhye': false,
  'Europe/Zurich': true,
  GB: false,
  'GB-Eire': false,
  GMT: true,
  'GMT+0': false,
  'GMT-0': false,
  GMT0: false,
  Greenwich: false,
  HST: false,
  Hongkong: false,
  Iceland: false,
  'Indian/Antananarivo': true,
  'Indian/Chagos': false,
  'Indian/Christmas': false,
  'Indian/Cocos': false,
  'Indian/Comoro': false,
  'Indian/Kerguelen': false,
  'Indian/Mahe': false,
  'Indian/Maldives': false,
  'Indian/Mauritius': false,
  'Indian/Mayotte': false,
  'Indian/Reunion': false,
  Iran: false,
  Israel: false,
  Jamaica: false,
  Japan: false,
  Kwajalein: false,
  Libya: false,
  MET: false,
  MST: false,
  MST7MDT: false,
  'Mexico/BajaNorte': false,
  'Mexico/BajaSur': false,
  'Mexico/General': false,
  NZ: false,
  'NZ-CHAT': false,
  Navajo: false,
  PRC: false,
  PST8PDT: false,
  'Pacific/Apia': false,
  'Pacific/Auckland': true,
  'Pacific/Bougainville': false,
  'Pacific/Chatham': false,
  'Pacific/Chuuk': false,
  'Pacific/Easter': false,
  'Pacific/Efate': false,
  'Pacific/Enderbury': false,
  'Pacific/Fakaofo': false,
  'Pacific/Fiji': false,
  'Pacific/Funafuti': false,
  'Pacific/Galapagos': false,
  'Pacific/Gambier': false,
  'Pacific/Guadalcanal': false,
  'Pacific/Guam': false,
  'Pacific/Honolulu': true,
  'Pacific/Johnston': false,
  'Pacific/Kiritimati': true,
  'Pacific/Kosrae': false,
  'Pacific/Kwajalein': false,
  'Pacific/Majuro': false,
  'Pacific/Marquesas': false,
  'Pacific/Midway': false,
  'Pacific/Nauru': false,
  'Pacific/Niue': false,
  'Pacific/Norfolk': false,
  'Pacific/Noumea': false,
  'Pacific/Pago_Pago': false,
  'Pacific/Palau': false,
  'Pacific/Pitcairn': false,
  'Pacific/Pohnpei': false,
  'Pacific/Ponape': false,
  'Pacific/Port_Moresby': false,
  'Pacific/Rarotonga': false,
  'Pacific/Saipan': false,
  'Pacific/Samoa': false,
  'Pacific/Tahiti': false,
  'Pacific/Tarawa': false,
  'Pacific/Tongatapu': false,
  'Pacific/Truk': false,
  'Pacific/Wake': false,
  'Pacific/Wallis': false,
  'Pacific/Yap': false,
  Poland: false,
  Portugal: false,
  ROC: false,
  ROK: false,
  Singapore: false,
  Turkey: false,
  UCT: false,
  'US/Alaska': false,
  'US/Aleutian': false,
  'US/Arizona': false,
  'US/Central': false,
  'US/East-Indiana': false,
  'US/Eastern': false,
  'US/Hawaii': false,
  'US/Indiana-Starke': false,
  'US/Michigan': false,
  'US/Mountain': false,
  'US/Pacific': false,
  'US/Pacific-New': false,
  'US/Samoa': false,
  UTC: false,
  Universal: false,
  'W-SU': false,
  WET: false,
  Zulu: false,
} as const

export type TimeZone = keyof typeof TIME_ZONES
export type ValidTimezone = {
  [K in keyof typeof TIME_ZONES]: (typeof TIME_ZONES)[K] extends true
    ? K
    : never
}[keyof typeof TIME_ZONES]

export const getValidTimeZones = (): ValidTimezone[] => {
  return Object.entries(TIME_ZONES)
    .filter(([_, isValid]) => isValid)
    .map(([timezone]) => timezone) as Array<ValidTimezone>
}
