import { Button } from '@mui/material'
import { Paper, TextField, Typography, Box } from '@mui/material'
import { Link } from '@sitoo/mui-components'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { LoginForm } from '.'
import { RootRoute } from '..'
import { PasswordField } from '../../components/password-field'
import { useTracking } from '../../hooks/tracking'
import { validateEmail } from '../../utils/validate'
import { Link as RouterLink } from 'react-router-dom'

type Props = {
  loading: boolean
  navigateToAccount(accountId?: number): void
  loginAction(): Promise<void>
}

export const SitooLoginForm = (props: Props) => {
  const { t } = useTranslation(['shared'])
  const { loading, navigateToAccount, loginAction } = props
  const { register, formState, watch } = useFormContext<LoginForm>()
  const { trackButtonClickEvent, trackInputBlur, trackInputFocus } =
    useTracking()

  return (
    <Paper square elevation={0} sx={{ px: 6, py: 5 }}>
      <Typography variant="displayMedium" sx={{ mb: 3 }}>
        {t('login:title')}
      </Typography>

      <TextField
        label={t('shared:label.email')}
        type="email"
        error={!!formState.errors.login?.email}
        helperText={formState.errors.login?.email?.message}
        {...register('login.email', {
          required: t('shared:validation.field_required', {
            field: t('shared:label.email'),
          }),
          validate: (value) =>
            value && !validateEmail(value)
              ? t('shared:validation.field_invalid', {
                  field: t('shared:label.email'),
                })
              : true,
          onBlur: trackInputBlur({ name: 'login-email' }),
        })}
        slotProps={{
          htmlInput: { 'data-testid': 'email-input' },
        }}
        autoComplete="username"
        onFocus={trackInputFocus({ name: 'login-email' })}
        sx={{ mb: 2 }}
      />

      <PasswordField
        error={!!formState.errors.login?.password}
        helperText={formState.errors.login?.password?.message}
        {...register('login.password', {
          required: t('shared:validation.field_required', {
            field: t('shared:label.password'),
          }),
          onBlur: trackInputBlur({ name: 'login-password' }),
        })}
        inputProps={{ 'data-testid': 'password-input' }}
        autoComplete="current-password"
        onFocus={trackInputFocus({ name: 'login-password' })}
        sx={{ mb: 2 }}
      />

      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
        }}
      >
        <Button
          onClick={trackButtonClickEvent({ name: 'login-submit' }, loginAction)}
          type="submit"
          disabled={loading}
          loading={loading}
          size="large"
          data-testid="login-button"
        >
          {t('shared:label.sign_in')}
        </Button>

        <Box
          sx={{
            mt: 1,
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <Link
            onClick={trackButtonClickEvent(
              { name: 'login-change-account' },
              () => navigateToAccount(),
            )}
            sx={{
              cursor: 'pointer',
            }}
            data-testid="login-change-account"
            underline="none"
          >
            {t('login:change_account')}
          </Link>

          <Link
            to={RootRoute.ResetPassword}
            state={{ email: watch('login.email') }}
            component={RouterLink}
            data-testid="forgot-password"
          >
            {t('login:forgot_password')}
          </Link>
        </Box>
      </Box>
    </Paper>
  )
}
