import {
  Box,
  Button,
  FormHelperText,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Paper,
  Stack,
  TextField,
} from '@mui/material'
import { DeleteIcon, DragHandleIcon } from '@sitoo/mui-components'
import { useMemo, useState } from 'react'
import { HTML5Backend } from 'react-dnd-html5-backend'
import { DndProvider } from 'react-dnd/dist/core/DndProvider'
import {
  useController,
  UseControllerProps,
  useFieldArray,
  useForm,
} from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { DraggableItem } from '../../../../components/draggable-item'

export const StringListInput = ({ control, name }: UseControllerProps) => {
  const { t } = useTranslation('shared')
  const [textInputValue, setTextInputValue] = useState('')

  const {
    field: { value: parentValue, onChange },
    fieldState,
  } = useController({ name, control })

  const { control: localControl, getValues } = useForm<{
    root: { value: string }[]
  }>({
    defaultValues: {
      root: parentValue
        ? (parentValue as string[]).map((value) => ({ value }))
        : [],
    },
  })

  const { fields, append, remove, move } = useFieldArray({
    name: 'root',
    control: localControl,
  })

  const errorMessage = fieldState.error?.message

  const valueAlreadyInList = useMemo(() => {
    const lowerCaseValues = getValues('root').map(({ value }) =>
      value.toLowerCase(),
    )
    return lowerCaseValues.includes(textInputValue.toLowerCase())
  }, [getValues, textInputValue])

  const handleAddClick = () => {
    append({ value: textInputValue })
    updateParentValue()
    setTextInputValue('')
  }

  const updateParentValue = () => {
    onChange(getValues('root').map(({ value }) => value))
  }

  const handleRemove = (index: number) => {
    remove(index)
    updateParentValue()
  }

  const handleMove = (fromIndex: number, toIndex: number) => {
    move(fromIndex, toIndex)
    updateParentValue()
  }

  const handleKeyPress = (event: React.KeyboardEvent) => {
    if (event.key === 'Enter' && textInputValue && !valueAlreadyInList) {
      event.preventDefault()
      handleAddClick()
    }
  }

  return (
    <Paper elevation={0} sx={{ my: 1 }}>
      <DndProvider backend={HTML5Backend}>
        <List sx={{ '.MuiListItem-root.MuiListItem-root': { p: 0 } }}>
          {fields.map((field, index) => (
            <DraggableItem
              key={field.id}
              id={field.id}
              index={index}
              divider
              moveField={handleMove}
              component={ListItem}
              secondaryAction={
                <Stack direction="row" gap={1} alignItems="center">
                  <IconButton onClick={() => handleRemove(index)}>
                    <DeleteIcon />
                  </IconButton>
                  <Box
                    sx={{
                      color: 'theme.palette.gray60',
                      cursor: 'move',
                      display: 'flex',
                      alignItems: 'center',
                    }}
                  >
                    <DragHandleIcon />
                  </Box>
                </Stack>
              }
            >
              <ListItemText primary={field.value} />
            </DraggableItem>
          ))}
        </List>
      </DndProvider>
      <FormHelperText error variant="standard" sx={{ mb: 1 }}>
        {errorMessage}
      </FormHelperText>
      <Stack direction="row" gap={1} sx={{ mt: 2 }}>
        <TextField
          slotProps={{ input: { onKeyPress: handleKeyPress } }}
          value={textInputValue}
          onChange={(e) => setTextInputValue(e.target.value)}
        />
        <Button
          onClick={handleAddClick}
          color="tertiary"
          disabled={!textInputValue || valueAlreadyInList}
        >
          {t('shared:action.add')}
        </Button>
      </Stack>
    </Paper>
  )
}
