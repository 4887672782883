import {
  Button,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material'
import { useEffect, useMemo, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { usePrevious } from 'react-use'
import {
  ShipmentPackageDimensionsUnitEnum as Dimensions,
  ShipmentPackageWeightUnitEnum as Weight,
  ShipmentState,
} from '../../../generated/graphql'
import { useTracking } from '../../../hooks/tracking'
import { ShipmentPackage } from '../fields/packages'
import { useServerValidation } from '../../../hooks/server-validation'
import { getShipmentsErrorMessage } from '../shared'

type Props = {
  open: boolean
  package?: ShipmentPackage
  shipmentState?: ShipmentState

  onClose(): void
  onUpdate?(data: ShipmentPackage): void | Promise<void>
  onUpdateSuccess?(): void
  onUpdateError?(error: unknown): void

  onDelete(packageId: ShipmentPackage): void | Promise<void>
  onDeleteSuccess?(): void
  onDeleteError?(error: unknown): void
  isNewShipment: boolean
  dataTestid?: string
}

type Form = ShipmentPackage

export const SetPackageInfoDialog = (props: Props) => {
  const { t } = useTranslation(['shipments', 'shared'])
  const dialogName = 'set-package-info'
  const {
    trackButtonClickEvent,
    trackDialogClose,
    trackDialogOpen,
    trackFormError,
    trackFormSuccess,
  } = useTracking()

  const prevOpen = usePrevious(props.open)

  const [loadingDelete, setLoadingDelete] = useState(false)
  const [loadingUpdate, setLoadingUpdate] = useState(false)
  const isLoading = loadingDelete || loadingUpdate

  const {
    formState,
    control,
    handleSubmit,
    register,
    reset,
    getValues,
    setError,
  } = useForm<Form>({
    mode: 'onSubmit',
  })

  const setFormError = useServerValidation<Form>('package', setError, {
    resolveFieldFromProperty: (property) => {
      return property.replace('data.package', '')
    },
  })

  const weightUnits = useMemo(
    () => [
      { value: Weight.Kg, name: t(`shared:weight.${Weight.Kg}`) },
      { value: Weight.Lb, name: t(`shared:weight.${Weight.Lb}`) },
      { value: Weight.Oz, name: t(`shared:weight.${Weight.Oz}`) },
    ],
    [t],
  )

  const dimensionUnits = useMemo(
    () => [
      { value: Dimensions.Cm, name: t(`shared:dimensions.${Dimensions.Cm}`) },
      { value: Dimensions.In, name: t(`shared:dimensions.${Dimensions.In}`) },
    ],
    [t],
  )

  useEffect(() => {
    if (props.open === prevOpen) return

    if (props.open) {
      reset(props.package, { keepDefaultValues: false })
      trackDialogOpen({ name: dialogName })
    } else {
      reset(undefined)
      trackDialogClose({ name: dialogName })
    }
  }, [
    props.open,
    props.package,
    prevOpen,
    trackDialogOpen,
    trackDialogClose,
    reset,
  ])

  const validate = (value: number | null) => {
    if (value !== null && Number.isNaN(value))
      return t('shipments:error.number_is_invalid')

    const dimensions = [
      getValues('dimensions.height'),
      getValues('dimensions.length'),
      getValues('dimensions.width'),
    ]

    if (dimensions.some((d) => d !== null)) {
      return value !== null
    }

    return true
  }

  const onSubmit = async (formData: Form) => {
    try {
      setLoadingUpdate(true)
      await props.onUpdate?.(formData)
      trackFormSuccess({ name: `${dialogName}-dialog` })
      props.onUpdateSuccess?.()
    } catch (error) {
      setFormError(error)

      const errorMessage = getShipmentsErrorMessage(error)
      trackFormError({ name: `${dialogName}-dialog`, errorMessage })
      props.onUpdateError?.(error)
    } finally {
      setLoadingUpdate(false)
    }
  }

  const onDelete = async () => {
    try {
      setLoadingDelete(true)
      await props.onDelete(props.package as ShipmentPackage)
      trackFormSuccess({ name: `${dialogName}-dialog` })
      props.onDeleteSuccess?.()
    } catch (error) {
      const errorMessage = getShipmentsErrorMessage(error)
      trackFormError({ name: `${dialogName}-dialog`, errorMessage })
      props.onDeleteError?.(error)
    } finally {
      setLoadingDelete(false)
    }
  }

  return (
    <Dialog
      open={props.open}
      onClose={props.onClose}
      maxWidth="sm"
      fullWidth
      data-testid="edit-package-info-dialog"
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogTitle type="extended" sx={{ pb: 2 }}>
          {props.package?.id
            ? t('shipments:packages.edit_package')
            : t('shipments:packages.add_package')}
        </DialogTitle>

        <DialogContent>
          <TextField
            data-testid="set-barcode"
            error={!!formState?.errors?.barcode}
            fullWidth
            label={t('shipments:shipment_form.barcode')}
            {...register('barcode')}
            sx={{ mb: 2 }}
          />

          <Controller
            control={control}
            name="weight.unit"
            render={({ field, fieldState: { error } }) => (
              <>
                <InputLabel>
                  {t('shipments:shipment_form.weight_unit')}
                </InputLabel>
                <Select
                  value={field.value || Weight.Kg}
                  onChange={(event) => {
                    field.onChange(event.target.value)
                  }}
                  data-testid="weight-select"
                  displayEmpty
                  error={!!error?.message}
                >
                  {weightUnits.map(({ name, value }) => (
                    <MenuItem value={value} key={name}>
                      {name}
                    </MenuItem>
                  ))}
                </Select>

                <FormHelperText error={!!error?.message} sx={{ mb: 2 }}>
                  {error?.message}
                </FormHelperText>
              </>
            )}
          />

          <TextField
            error={!!formState?.errors?.weight?.weight}
            helperText={formState?.errors?.weight?.weight?.message}
            fullWidth
            label={t('shipments:shipment_form.weight')}
            {...register('weight.weight', {
              setValueAs: (v) => (v ? Number(v) : null),
              validate: (v) =>
                v !== null && Number.isNaN(v)
                  ? t('shipments:error.number_is_invalid')
                  : true,
            })}
            sx={{ mb: 2 }}
          />

          <Controller
            control={control}
            name="dimensions.unit"
            render={({ field, fieldState: { error } }) => (
              <>
                <InputLabel>
                  {t('shipments:shipment_form.dimensions_unit')}
                </InputLabel>
                <Select
                  value={field.value || Dimensions.Cm}
                  onChange={(event) => {
                    field.onChange(event.target.value)
                  }}
                  data-testid="dimensions-unit-select"
                  displayEmpty
                  error={!!error?.message}
                >
                  {dimensionUnits.map(({ name, value }) => (
                    <MenuItem value={value} key={name}>
                      {name}
                    </MenuItem>
                  ))}
                </Select>

                <FormHelperText error={!!error?.message} sx={{ mb: 2 }}>
                  {error?.message}
                </FormHelperText>
              </>
            )}
          />

          <TextField
            error={!!formState?.errors?.dimensions?.length}
            helperText={formState?.errors?.dimensions?.length?.message}
            fullWidth
            label={t('shipments:shipment_form.length')}
            {...register('dimensions.length', {
              validate,
              setValueAs: (v) => (v ? Number(v) : null),
            })}
            sx={{ mb: 2 }}
          />

          <TextField
            error={!!formState?.errors?.dimensions?.width}
            helperText={formState?.errors?.dimensions?.width?.message}
            fullWidth
            label={t('shipments:shipment_form.width')}
            {...register('dimensions.width', {
              validate,
              setValueAs: (v) => (v ? Number(v) : null),
            })}
            sx={{ mb: 2 }}
          />

          <TextField
            error={!!formState?.errors?.dimensions?.height}
            helperText={formState?.errors?.dimensions?.height?.message}
            fullWidth
            label={t('shipments:shipment_form.height')}
            {...register('dimensions.height', {
              validate,

              setValueAs: (v) => (v ? Number(v) : null),
            })}
            sx={{ mb: 2 }}
          />

          {props.package?.id && props.shipmentState === ShipmentState.New && (
            <Button
              color="error"
              fullWidth
              sx={{ mt: 2 }}
              onClick={onDelete}
              disabled={isLoading}
              loading={loadingDelete}
            >
              {t('shipments:packages.delete_package')}
            </Button>
          )}
        </DialogContent>
        <DialogActions>
          <Button
            onClick={trackButtonClickEvent(
              { name: `${dialogName}-dialog-cancel` },
              props.onClose,
            )}
            color="secondary"
            size="small"
            type="button"
            disabled={isLoading}
          >
            {t('shared:action.cancel')}
          </Button>
          <Button
            type="submit"
            size="small"
            onClick={trackButtonClickEvent({
              name: `${dialogName}-dialog-save`,
            })}
            disabled={isLoading}
            data-testid="dialog-submit"
            loading={loadingUpdate}
          >
            {props.isNewShipment
              ? t('shared:action.ok')
              : t('shared:action.save')}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  )
}
