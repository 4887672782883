import { useQuery } from '@apollo/client'
import { Grid } from '@mui/material'
import { Outlet, useParams } from 'react-router-dom'
import { RootRoute } from '../..'
import { ReportDocument } from '../../../generated/graphql'
import { useAbsolutePath } from '../../../hooks/absolute-path'
import { useAuthorization } from '../../../hooks/authorization'
import { ReportJobDialogProvider } from '../create-report-job-dialog/report-job-dialog-provider'
import { NoReportSelected } from './no-report-selected'
import { SelectionViewPanel } from './selection-view-panel'
import { TemplatePreview } from './template-preview'
import { PageHeader } from '../../../components/page-header'
import { useTranslation } from 'react-i18next'

export const ReportTemplates = () => {
  const { id } = useParams<{ id: string }>()
  const generatePath = useAbsolutePath()
  const { t } = useTranslation(['reports'])

  const {
    modules: { reportsFullPermission },
  } = useAuthorization()

  const selectedReportId = Number(id)

  const { data } = useQuery(ReportDocument, {
    variables: {
      reportId: selectedReportId,
    },
    skip: !selectedReportId,
    fetchPolicy: 'cache-and-network',
  })

  return (
    <ReportJobDialogProvider
      backTo={generatePath(RootRoute.ReportTemplates, { id: selectedReportId })}
      dialogData={data?.report}
      name={data?.report?.reportname}
      defaultDialogMode={reportsFullPermission ? 'extended' : 'compact'}
    >
      <Outlet />
      <Grid container flexWrap="nowrap">
        <Grid size="grow" key={selectedReportId}>
          <PageHeader
            title={
              (data?.report?.reportname
                ? t('create.create_report_with_name', {
                    name: data?.report?.reportname,
                  })
                : t('create.create_report')) ?? ''
            }
            backTo={generatePath(RootRoute.ReportJobs)}
            showBackButton={true}
          />
          {selectedReportId ? (
            <TemplatePreview data={data} />
          ) : (
            <NoReportSelected />
          )}
        </Grid>
        <Grid>
          <SelectionViewPanel selectedReportId={selectedReportId} />
        </Grid>
      </Grid>
    </ReportJobDialogProvider>
  )
}
