import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { Form } from '../../..'
import { SettingsListItem } from '../../../../../../components/settings-list-item'
import { PosProfileProductDisplayModeEnum } from '../../../../../../generated/graphql'
import { SelectInput } from '../../../../../../inputs/select-input'

export const ProductDisplayModeField = () => {
  const { t } = useTranslation(['pos_profiles'])
  const { control } = useFormContext<Form>()

  const productDisplayModeOptions = [
    ...Object.values(PosProfileProductDisplayModeEnum).map((mode) => ({
      value: mode,
      name: t(`pos_profiles:product_display_mode.${mode}`),
    })),
  ]
  return (
    <SettingsListItem>
      <SelectInput
        label={t('pos_profiles:pos_options_section.product_display_mode_label')}
        helperText={t(
          'pos_profiles:pos_options_section.product_display_mode_description',
        )}
        name={'productdisplaymode'}
        control={control}
        options={productDisplayModeOptions}
      />
    </SettingsListItem>
  )
}
