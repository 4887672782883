import { useSuspenseQuery } from '@apollo/client'
import { useSnackbar } from 'notistack'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { RootRoute } from '..'
import {
  AuthenticateOidcDocument,
  OidcAccountIdDocument,
} from '../../generated/graphql'
import { REDIRECT_QUERY_PARAM } from '../../utils/constants'
import { ACCOUNT_ID_REGEX, getHostName } from '../login/util'
import { useLocalStorage } from 'react-use'
import { getErrorMessages } from '../../utils/error-mapping'

export const OidcCallbackPage = () => {
  const [searchParams] = useSearchParams()
  const [redirectPage] = useLocalStorage(REDIRECT_QUERY_PARAM)
  const { t } = useTranslation(['shared', 'login'])
  const navigate = useNavigate()
  const state = searchParams.get('state')
  const code = searchParams.get('code')
  const hasAccountId = ACCOUNT_ID_REGEX.test(location.host)
  const hasOidcError = searchParams.has('error')

  const { enqueueSnackbar } = useSnackbar()

  const { data: accountIdData, error: fetchError } = useSuspenseQuery(
    OidcAccountIdDocument,
    {
      variables: { state: String(state) },
      skip: hasAccountId || !state,
      errorPolicy: 'all',
    },
  )

  const { data: authenticateData, error: authError } = useSuspenseQuery(
    AuthenticateOidcDocument,
    {
      variables: { code: String(code), state: String(state) },
      skip: !hasAccountId || !state || !code,
      errorPolicy: 'all',
    },
  )

  const hasError =
    fetchError ||
    authError ||
    hasOidcError ||
    authenticateData?.authenticateOidc === false

  // Redirect to the Logout page in case of error
  useEffect(() => {
    if (!hasError) return

    if (authError) {
      const errorMessage = getErrorMessages(authError, {
        format: (code, property) =>
          t(`login:error.${code}`, {
            property,
            host: getHostName(),
            defaultValue: t('login:error.fallback_error'),
          }),
      })[0]

      enqueueSnackbar(errorMessage, {
        variant: 'error',
        preventDuplicate: true,
      })
    }

    void navigate({ pathname: RootRoute.Logout })
  }, [authError, enqueueSnackbar, hasError, navigate, t])

  // Replace accountId in the URL
  useEffect(() => {
    const accountId = accountIdData?.oidcAccountId

    if (accountId) {
      return window.location.assign(
        window.location.href.replace('https://', `https://${accountId}.`),
      )
    }
  }, [accountIdData])

  // Redirect to the login page if there are no OIDC parameters
  useEffect(() => {
    if (!hasError && !state && !code) {
      void navigate(RootRoute.Login)
    }
  }, [code, hasError, navigate, state])

  // Redirect to the login page after the successful OIDC authentication
  // Login page would check if session exists and redirect to the home page
  useEffect(() => {
    if (authenticateData?.authenticateOidc) {
      void navigate(redirectPage || RootRoute.Login)
    }
  }, [authenticateData?.authenticateOidc, navigate, redirectPage])

  return null
}
