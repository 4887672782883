import { useQuery } from '@apollo/client'
import { List } from '@mui/material'
import { SystemReportsDocument } from '../../../../generated/graphql'
import { ReportListItem } from './report-list-item'

type Props = {
  selectedReportId: number
  onSelect: (reportId: number) => void
}

export const SystemReportsPanel = ({ selectedReportId, onSelect }: Props) => {
  const { data } = useQuery(SystemReportsDocument, {
    fetchPolicy: 'cache-and-network',
  })

  return (
    <List>
      {data?.systemReports.map((report) => (
        <ReportListItem
          onSelect={onSelect}
          key={report.reportid}
          report={report}
          isSelected={selectedReportId === report.reportid}
        />
      ))}
    </List>
  )
}
