import { Box, Button, Link, Paper, Stack, Container } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { PageHeader } from '../../components/page-header'
import { Dashboard } from './dashboard'
import { DashboardIdFilter } from './dashboard-id-filter'
import {
  NAVBAR_HEIGHT,
  PAGE_BOTTOM_OFFSET,
  PAGE_HEADER_HEIGHT,
} from '../../utils/constants'
import { usePageTitle } from '../../hooks/title'
import { ExternalStoreIdFilter } from './store-id-filter'
import { useSearchParams } from 'react-router-dom'
import { CustomOverlay } from '../../components/custom-overlay'
import { DashboardIcon } from '@sitoo/mui-components'
import { DashboardsDocument } from '../../generated/graphql'
import { useQuery } from '@apollo/client'

export const StoreDashboardsPage = () => {
  const { t } = useTranslation('store_dashboards')
  const { data, loading: isLoading } = useQuery(DashboardsDocument)

  const [searchParams] = useSearchParams()

  const dashboardId = searchParams.get('dashboardId')
  const storeId = searchParams.get('storeId')

  const headerHeight =
    PAGE_HEADER_HEIGHT + NAVBAR_HEIGHT + PAGE_BOTTOM_OFFSET * 2
  const hasNoDashboards = !isLoading && (!data || data.dashboards.length === 0)

  usePageTitle(t('shared:menu.store_dashboards'))

  return (
    <Box>
      <PageHeader title={t('store_dashboards:page_title')} isFlexible />

      <Container maxWidth={false}>
        <Paper
          elevation={0}
          sx={{
            p: 2,
            display: 'grid',
            gridTemplateRows: 'auto 1fr',
            minHeight: `calc(100vh - ${headerHeight}px)`,
          }}
        >
          <Stack sx={{ mb: 2 }} direction="row" gap={2}>
            <DashboardIdFilter />
            <ExternalStoreIdFilter />
          </Stack>

          {!hasNoDashboards && dashboardId && storeId && (
            <Dashboard dashboardId={dashboardId} storeId={storeId} />
          )}
          {hasNoDashboards && (
            <CustomOverlay
              icon={<DashboardIcon />}
              title={t('store_dashboards:empty_title')}
              description={t('store_dashboards:empty_description')}
              action={
                <Button
                  component={Link}
                  href="https://support.sitoo.com/hc/en-us/articles/360017264119-Sitoo-Dashboard"
                  target="_blank"
                >
                  {t('store_dashboards:empty_action')}
                </Button>
              }
            />
          )}
        </Paper>
      </Container>
    </Box>
  )
}
