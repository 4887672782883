import { useQuery } from '@apollo/client'
import { Box, Button, Chip, IconButton, Menu, MenuItem } from '@mui/material'
import { MoreVerticalIcon, ShipmentIcon } from '@sitoo/mui-components'
import { MouseEventHandler, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { RootRoute } from '../..'
import { ViewPanel, ViewPanelHeader } from '../../../components/view-panel'
import { GetShipmentV2Document } from '../../../generated/graphql'
import { useAbsolutePath } from '../../../hooks/absolute-path'
import { useTracking } from '../../../hooks/tracking'
import { ShipmentStateChip } from '../shipment-state-chip/shipment-state-chip'
import { ArchiveShipmentAction } from './archive-shipment-action'
import { Carrier } from './carrier'
import { Information } from './information'
import { Packages } from './packages'

type Props = {
  open: boolean
  onClose: () => void
  shipmentId: number
}

export const ShipmentViewPanel = (props: Props) => {
  const { t } = useTranslation(['shared', 'shipments_v2'])
  const panelRef = useRef<HTMLDivElement>(null)
  const { trackButtonClick } = useTracking()
  const generatePath = useAbsolutePath()
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)
  const isOpen = !!anchorEl
  const handleClick: MouseEventHandler<HTMLButtonElement> = (event) => {
    setAnchorEl(isOpen ? null : event.currentTarget)
  }
  const closeMenu = () => {
    setAnchorEl(null)
  }

  const { data, loading: isLoading } = useQuery(GetShipmentV2Document, {
    variables: { shipmentId: props.shipmentId },
    skip: !props.shipmentId,
    fetchPolicy: 'cache-and-network',
  })
  const shipment = data?.getShipmentV2

  if (!shipment?.shipmentstate || !shipment.shipmentid) return
  const totalItemQuantity = shipment.packages?.reduce(
    (total, current) => total + Number(current.totalQuantity) || 0,
    0,
  )

  const totalReceivedQuantity = shipment.packages?.reduce(
    (total, current) => total + Number(current.totalReceivedQuantity) || 0,
    0,
  )

  const allItemsReceived = totalItemQuantity === totalReceivedQuantity

  return (
    <>
      <ViewPanel open={props.open} ref={panelRef}>
        <ViewPanelHeader
          isLoading={isLoading}
          title={String(shipment?.shipmentid) || ''}
          section={t('shipments_v2:page_title')}
          onClose={props.onClose}
          tags={
            <>
              <ShipmentStateChip
                label={t(
                  `shipments_v2:shipment_state.${shipment.shipmentstate}`,
                )}
                state={shipment.shipmentstate}
              />
              {shipment.archived && (
                <Chip
                  label={t(`shipments_v2:view_panel.archived`)}
                  size="small"
                  color="red"
                />
              )}
            </>
          }
          hideActionsSkeleton
          onBack={() => {
            trackButtonClick({ name: 'shipment-panel-go-back' })
          }}
          sectionIcon={<ShipmentIcon />}
          actions={
            <>
              {props.shipmentId && (
                <Button
                  component={Link}
                  to={generatePath(RootRoute.Shipment, {
                    id: props.shipmentId,
                  })}
                  color="secondary"
                  size="small"
                  data-testid="edit-shipment-button"
                  onClick={() => {
                    trackButtonClick({
                      name: 'shipment-panel-edit-shipment',
                      shipmentId: props.shipmentId,
                    })
                  }}
                >
                  {t('shared:action.edit')}
                </Button>
              )}
              <IconButton onClick={handleClick}>
                <MoreVerticalIcon />
              </IconButton>
              <Menu
                anchorEl={anchorEl}
                open={isOpen}
                onClose={closeMenu}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'right',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                slotProps={{
                  list: {
                    'aria-labelledby': 'user-menu-button',
                  },
                }}
              >
                <MenuItem sx={{ p: 0 }}>
                  <ArchiveShipmentAction shipment={shipment} />
                </MenuItem>
              </Menu>
            </>
          }
        />
        <Box sx={{ background: (theme) => theme.palette.background.paper }}>
          <Information
            shipment={shipment}
            isLoading={isLoading}
            allItemsReceived={allItemsReceived}
          />

          <Packages
            shipment={shipment}
            isLoading={isLoading}
            allItemsReceived={allItemsReceived}
          />
          <Carrier shipment={shipment} isLoading={isLoading} />
        </Box>
      </ViewPanel>
    </>
  )
}
