import { useReactiveVar } from '@apollo/client'
import { Container, Grid } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { FilterProvider } from '../../components/data-grid/context'
import { PageHeader } from '../../components/page-header'
import { usePageTitle } from '../../hooks/title'
import { useTracking } from '../../hooks/tracking'
import { CashRegisterList } from './cash-registers-list'
import { CashRegisterViewPanel } from './cash-register-view-panel'
import { cashRegisterViewPanelVar } from './variables'

export type ViewPanelState = {
  isOpen: boolean
  selectedId: string | undefined
}

export const CashRegistersPage = () => {
  const { t } = useTranslation(['cash_registers'])
  usePageTitle(t('cash_registers:page_title'))
  const viewPanelState = useReactiveVar(cashRegisterViewPanelVar)
  const { trackButtonClick } = useTracking()

  const openViewPanel = (selectedId: string) => {
    trackButtonClick({ name: 'cash-registers-view-panel-open', selectedId })
    cashRegisterViewPanelVar({ isOpen: true, selectedId })
  }

  const closeViewPanel = () => {
    trackButtonClick({ name: 'cash-registers-view-panel-close' })
    cashRegisterViewPanelVar({ isOpen: false, selectedId: undefined })
  }

  return (
    <FilterProvider>
      <Grid container flexWrap="nowrap">
        <Grid size="grow">
          <PageHeader title={t('cash_registers:page_title')} isFlexible />
          <Container maxWidth={false}>
            <CashRegisterList
              onDetail={openViewPanel}
              selectedId={viewPanelState.selectedId}
            />
          </Container>
        </Grid>
        <Grid>
          <CashRegisterViewPanel
            open={viewPanelState.isOpen}
            selectedId={viewPanelState.selectedId}
            onClose={closeViewPanel}
          />
        </Grid>
      </Grid>
    </FilterProvider>
  )
}
