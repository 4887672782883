type Options = {
  deleteEmptyProperties?: boolean
  deleteEmptyStrings?: boolean
}

type StripNullValues<T, TOptions extends Options> = {
  [K in keyof T]: T[K] extends object
    ? StripNullValues<T[K], TOptions>
    : TOptions['deleteEmptyStrings'] extends true
      ? Exclude<T[K], '' | null>
      : Exclude<T[K], null>
}

/**
 * Recursively replace null object fields to undefined
 */
export const stripNullValues = <T extends object, TOptions extends Options>(
  args: T,
  opts?: TOptions,
): StripNullValues<T, TOptions> => {
  const result = {} as Record<string, unknown>

  Object.entries(args).forEach(([key, value]) => {
    if (value && typeof value === 'object' && !Array.isArray(value)) {
      const objectValue = stripNullValues(value, opts) as object
      if (
        opts?.deleteEmptyProperties &&
        !Object.values(objectValue).some((x) => Boolean(x))
      ) {
        delete result[key]
      } else {
        result[key] = objectValue
      }
    } else if (value === '' && opts?.deleteEmptyStrings) {
      delete result[key]
    } else if (value === null) {
      delete result[key]
    } else {
      result[key] = value
    }
  })

  return result as StripNullValues<T, TOptions>
}
