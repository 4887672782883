import { useFormContext } from 'react-hook-form'
import { GeneralSettingsFormContext } from '../general-settings-form'
import { useTranslation } from 'react-i18next'
import { useQuery } from '@apollo/client'
import { AllPaymentTypesDocument } from '../../../../generated/graphql'
import { getPaymentTypesFromIds } from '../../payment-options/utils'
import { DialogInput } from '../../../../inputs/dialog-input'
import { SettingsListSection } from '../../../../components/settings-list-section'
import { Typography } from '@mui/material'
import { useMemo } from 'react'

const paymentSettingsFields = [
  'payments_refund_manual',
  'payments_refund_validated',
  'payments_refund_validated_omni',
  'payments_sales_gift_card',
  'payments_sales_non_delivered',
] as const

export const LimitPaymentOptions = () => {
  const { control } = useFormContext<GeneralSettingsFormContext>()
  const { t } = useTranslation(['shared', 'payment_options', 'settings'])
  const { data: paymentTypesData, loading: isLoading } = useQuery(
    AllPaymentTypesDocument,
  )

  const options = useMemo(() => {
    return [...(paymentTypesData?.allPaymentTypes ?? [])]
      .sort((a, b) => a.name.localeCompare(b.name))
      .map((p) => Number(p.paymenttypeid))
  }, [paymentTypesData])

  return (
    <SettingsListSection
      title={t('settings:general.limit_payment_options.title')}
      description={t('settings:general.limit_payment_options.description')}
      information={
        <Typography variant="body01">
          {t('settings:general.limit_payment_options.information1')}
        </Typography>
      }
    >
      {paymentSettingsFields.map((key) => {
        return (
          <DialogInput
            key={key}
            control={control}
            itemLabel={t(`settings:general.limit_payment_options.${key}`)}
            addLabel={t(
              'settings:general.limit_payment_options.add_payment_option',
            )}
            formatValue={(value) => {
              const option = getPaymentTypesFromIds(
                [value],
                paymentTypesData?.allPaymentTypes,
              )[0]

              return {
                label: option?.name ?? '',
                description: t(
                  `payment_options:payment_method.${option?.paymentmethodid}`,
                ),
              }
            }}
            options={options}
            emptyMessage={t('settings:general.limit_payment_options.no_limit')}
            name={key}
            isLoading={isLoading}
            helperText={t(
              `settings:general.limit_payment_options.${key}_helper_text`,
            )}
          />
        )
      })}
    </SettingsListSection>
  )
}
