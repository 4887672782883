import { useFormContext, useWatch } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { GeneralSettingsFormContext } from '../general-settings-form'
import { SettingsListSection } from '../../../../components/settings-list-section'
import { useEffect } from 'react'
import { SettingsListItem } from '../../../../components/settings-list-item'
import { SingleProductInput } from '../../../../inputs/single-product-input'

export const InvoiceDownPayment = () => {
  const { control, setValue } = useFormContext<GeneralSettingsFormContext>()
  const { t } = useTranslation(['shared', 'settings'])

  const productDownPayment = useWatch({ control, name: 'product_downpayment' })

  useEffect(() => {
    setValue('productid_downpayment', productDownPayment?.id ?? null, {
      shouldDirty: true,
    })
  }, [productDownPayment, setValue])

  return (
    <SettingsListSection
      title={t('settings:general.invoice_and_downpayment.title')}
    >
      <SettingsListItem>
        <SingleProductInput
          name="product_downpayment"
          control={control}
          label={t(
            'settings:general.invoice_and_downpayment.product_downpayment_label',
          )}
          helperText={t(
            'settings:general.invoice_and_downpayment.product_downpayment_helper',
          )}
          transform={{
            output: (value) => {
              const { id, title, sku, productImages, variant, __typename } =
                value
              return { id, title, sku, productImages, variant, __typename }
            },
          }}
        />
      </SettingsListItem>
    </SettingsListSection>
  )
}
