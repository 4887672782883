import { useFormContext, useWatch } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { Form } from '../../..'
import { DialogInput } from '../../../../../../inputs/dialog-input'
import { StringListInput } from '../../../components/string-list-input'

export const ExtraSignatureLinesField = () => {
  const { t } = useTranslation(['pos_profiles'])
  const { control } = useFormContext<Form>()

  const extraSignatureLines = useWatch({
    control,
    name: 'extra_sign_return_receipt',
  })
  return (
    <DialogInput
      name="extra_sign_return_receipt"
      control={control}
      options={extraSignatureLines ?? []}
      formatValue={(value) => ({
        label: value,
        value,
      })}
      itemLabel={t(
        'pos_profiles:receipt_section.extra_signature_lines_for_return_label',
      )}
      helperText={t(
        'pos_profiles:receipt_section.extra_signature_lines_for_return_description',
      )}
      emptyMessage={t(
        'pos_profiles:receipt_section.extra_signature_lines_for_return_empty',
      )}
      slots={{ Input: StringListInput }}
    />
  )
}
