import { useQuery } from '@apollo/client'
import {
  Box,
  Button,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
} from '@mui/material'
import { useEffect, useMemo } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import {
  ProductGroupsDocument,
  SalesTaxProductGroupRule,
  SalesTaxProductGroupRuleType,
  SalesTaxQuery,
} from '../../../../generated/graphql'
import { useTracking } from '../../../../hooks/tracking'
import { ArrayElement } from '../../../../utils/types'
import { BaseSalesTaxRuleContext } from '../shared'
import { DECIMAL_REGEX } from '../../../../utils/constants'

type Props = {
  open: boolean
  onClose(): void
  dataTestid?: string
  onAdd(rule: SalesTaxProductGroupRule): void
  onSave(rule: SalesTaxProductGroupRule): void
  rule?: ArrayElement<SalesTaxQuery['salesTax']['productgrouprules']> | null
}

const defaultValues = {
  decimalvalue: '0.000',
  type: SalesTaxProductGroupRuleType.TOTAL,
}

export const SetRuleDialog = (props: Props) => {
  const { t } = useTranslation(['settings', 'shared'])
  const dialogName = 'set-sales-tax-rule'
  const { id: salesTaxId } = useParams()

  const { data: productGroupsData, loading: isLoadingProductGroups } = useQuery(
    ProductGroupsDocument,
    { fetchPolicy: 'cache-and-network' },
  )

  const productGroups = useMemo(
    () => productGroupsData?.productGroups.items,
    [productGroupsData],
  )

  const { trackButtonClickEvent, trackDialogClose, trackDialogOpen } =
    useTracking()

  const {
    register,
    formState,
    setValue,
    reset,
    watch,
    control,
    trigger,
    clearErrors,
  } = useForm<BaseSalesTaxRuleContext>()

  useEffect(() => {
    if (props.rule) {
      reset(props.rule, { keepDefaultValues: false })
      trackDialogOpen({ name: dialogName })
    } else {
      reset(defaultValues)
      trackDialogClose({ name: dialogName })
    }
  }, [props.open, props.rule, trackDialogOpen, trackDialogClose, reset])

  const values = watch()

  const handleSubmit = async () => {
    const isFormInputValid = await trigger()

    if (!isFormInputValid) return
    trackButtonClickEvent(
      salesTaxId
        ? { name: `${dialogName}-dialog-save` }
        : { name: `${dialogName}-dialog-add` },
      props.onClose,
    )
    if (props.rule) {
      props.onSave({ ...values })
    } else {
      props.onAdd({ ...values })
    }

    props.onClose()
  }

  const handleOnProductGroupChange = (vatId: number) => {
    clearErrors('vatid')
    const productGroupName = productGroups?.find((group) => group.id === vatId)
      ?.name as string

    setValue('vatid', vatId)
    setValue('groupName', productGroupName)
  }

  return (
    <Dialog
      open={props.open}
      onClose={props.onClose}
      data-testid="set-rule-dialog"
      maxWidth="xs"
      fullWidth
      sx={{ p: 4 }}
    >
      <DialogTitle
        data-testid="set-rule-dialog-heading"
        type="extended"
        sx={{ pb: 2, justifyContent: 'space-between' }}
      >
        {props.rule
          ? t('settings:sales_tax.edit_rule')
          : t('settings:sales_tax.add_rule')}
      </DialogTitle>

      <DialogContent>
        <Stack gap={1}>
          <Controller
            control={control}
            name="vatid"
            rules={{
              required: t('shared:validation.field_required', {
                field: t('settings:sales_tax.product_group'),
              }),
            }}
            render={({ field, fieldState: { error } }) => (
              <>
                <InputLabel>{t('settings:sales_tax.product_group')}</InputLabel>
                <Select
                  data-testid="product-group-field"
                  value={field.value || ''}
                  displayEmpty
                  error={!!error?.message}
                  onChange={(event) => {
                    handleOnProductGroupChange(Number(event.target.value))
                  }}
                >
                  <MenuItem
                    value=""
                    disabled
                    data-testid={'sales-tax-rule-product-group-none'}
                  >
                    {t('settings:sales_tax.select')}
                  </MenuItem>
                  {(productGroups || []).map(({ id, name }) => (
                    <MenuItem
                      value={id}
                      key={id}
                      data-testid={`sales-tax-rule-product-group-${name}`}
                    >
                      {name}
                    </MenuItem>
                  ))}
                </Select>
                <FormHelperText error={!!error?.message} sx={{ mt: -1 }}>
                  {error?.message}
                </FormHelperText>
              </>
            )}
          />
          <Controller
            control={control}
            name="type"
            render={({ field }) => (
              <>
                <InputLabel>{t('settings:sales_tax.type')}</InputLabel>
                <Select
                  data-testid="tax-type-field"
                  value={field.value}
                  onChange={(event) => {
                    field.onChange(event.target.value)
                  }}
                >
                  {Object.keys(SalesTaxProductGroupRuleType).map((ruleType) => (
                    <MenuItem
                      value={ruleType}
                      key={ruleType}
                      data-testid={`order-payment-state-button-${ruleType}`}
                    >
                      {t(
                        `settings:sales_tax_product_group_rule_type.${ruleType}`,
                      )}
                    </MenuItem>
                  ))}
                </Select>
              </>
            )}
          />

          <TextField
            error={!!formState?.errors.decimalvalue}
            fullWidth
            helperText={formState?.errors.decimalvalue?.message}
            label={`${t('settings:sales_tax.tax')} %`}
            data-testid="sales-tax-tax-field"
            {...register('decimalvalue', {
              pattern: {
                value: DECIMAL_REGEX,
                message: t('settings:sales_tax.validation.decimal_pattern'),
              },
              required: t('shared:validation.field_required', {
                field: t('settings:sales_tax.tax'),
              }),
            })}
            sx={{ mb: 2 }}
          />
          <Stack sx={{ mb: 2 }} gap={1} direction={'row'}>
            <TextField
              error={!!formState?.errors.moneymin}
              fullWidth
              helperText={formState?.errors.moneymin?.message}
              label={t('settings:sales_tax.min_value')}
              data-testid="sales-tax-rule-min-value-field"
              {...register('moneymin', {
                pattern: {
                  value: DECIMAL_REGEX,
                  message: t('settings:sales_tax.validation.decimal_pattern'),
                },
              })}
            />
            <TextField
              error={!!formState?.errors.moneymax}
              fullWidth
              helperText={formState?.errors.moneymax?.message}
              label={t('settings:sales_tax.max_value')}
              data-testid="sales-tax-rule-max-value-field"
              {...register('moneymax', {
                pattern: {
                  value: DECIMAL_REGEX,
                  message: t('settings:sales_tax.validation.decimal_pattern'),
                },
              })}
            />
          </Stack>
          <TextField
            error={!!formState?.errors.externalid}
            fullWidth
            helperText={formState?.errors.externalid?.message}
            label={t('settings:sales_tax.external_id')}
            data-testid="sales-tax-rule-external-id-field"
            {...register('externalid')}
            sx={{ mb: 2 }}
          />
        </Stack>
      </DialogContent>
      <DialogActions sx={{ p: 3 }}>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
            width: '100%',
          }}
        >
          <Button
            onClick={trackButtonClickEvent(
              { name: `${dialogName}-dialog-cancel` },
              props.onClose,
            )}
            color="secondary"
            size="small"
            type="button"
            data-testid="set-sales-tax-cancel-button"
          >
            {t('shared:action.cancel')}
          </Button>
          <Button
            onClick={handleSubmit}
            color="primary"
            size="small"
            type="button"
            disabled={isLoadingProductGroups}
            sx={{ ml: 2 }}
            data-testid="set-sales-tax-submit-button"
          >
            {t('shared:action.ok')}
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  )
}
