import { t } from 'i18next'
import { useFormContext, useWatch } from 'react-hook-form'
import { Form } from '../../..'
import { SettingsListItem } from '../../../../../../components/settings-list-item'
import { PosProfilePosInventoryFeaturesEnum } from '../../../../../../generated/graphql'
import { DialogInput } from '../../../../../../inputs/dialog-input'
import { Option, SelectInput } from '../../../../../../inputs/select-input'
import { ListItemBorderBox } from '../../../../../../components/list-item-border-box'

export const LimitPosInventoryFeaturesField = () => {
  const { control, setValue } = useFormContext<Form>()

  const allowAllInventoryFeatures = useWatch({
    control,
    name: 'allowAllInventoryFeatures',
  })

  const limitInventoryFeaturesOptions: Option[] = [
    {
      name: t(
        'pos_profiles:pos_options_section.limit_inventory_features_options.allow_all',
      ),
      value: true,
    },
    {
      name: t(
        'pos_profiles:pos_options_section.limit_inventory_features_options.limit',
      ),
      value: false,
    },
  ]

  const currentPosInventoryFeatures = useWatch({
    control,
    name: 'pos_inventory_features',
  })

  return (
    <>
      <SettingsListItem>
        <SelectInput
          label={t(
            'pos_profiles:pos_options_section.pos_inventory_features_label',
          )}
          name={'allowAllInventoryFeatures'}
          control={control}
          options={limitInventoryFeaturesOptions}
          onChange={(event) => {
            if (!event.target.value) {
              setValue(
                'pos_inventory_features',
                currentPosInventoryFeatures || [],
                { shouldDirty: true },
              )
            }
          }}
        />
      </SettingsListItem>

      {!allowAllInventoryFeatures && (
        <ListItemBorderBox>
          <DialogInput
            name="pos_inventory_features"
            control={control}
            options={Object.keys(PosProfilePosInventoryFeaturesEnum)}
            formatValue={(value) => ({
              value: value,
              label: t(
                `pos_profiles:pos_options_section.inventory_features_options.${value}`,
              ),
            })}
            addLabel={t('pos_profiles:pos_options_section.add_feature')}
            itemLabel={t(
              'pos_profiles:pos_options_section.limit_pos_inventory_features_label',
            )}
            emptyMessage={t(
              'pos_profiles:pos_options_section.pos_inventory_features_empty',
            )}
          />
        </ListItemBorderBox>
      )}
    </>
  )
}
