import { Settings } from '../../../../generated/graphql'
import { formatDecimal } from '../../../../utils/format/number'

/**
 * Format currency rates for the textarea
 */
export const formatCurrencyRates = (rates?: Settings['currency_rates']) => {
  const formatRate = (rate: string) =>
    formatDecimal(rate, {
      maxDecimals: 8,
      minDecimals: 0,
      useGrouping: false,
    })

  const lines = rates
    ?.map((item) => `${item.code} ${formatRate(item.rate)}`)
    .join('\n')

  return lines || ''
}
