import { useMutation, useQuery } from '@apollo/client'
import {
  Button,
  Divider,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
} from '@mui/material'
import { FormFieldset } from '@sitoo/mui-components'
import { useSnackbar } from 'notistack'
import { useCallback, useEffect, useRef, useState } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import {
  GetWarehousesDocument,
  ShipmentStateV2,
  UpdateShipmentV2Document,
} from '../../../../generated/graphql'
import { useScrollToError } from '../../../../hooks/scroll-to-error'
import { getErrorMessages } from '../../../../utils/error-mapping'
import { SetAddressDialog } from '../../set-address-dialog'
import { SetContactDialog } from '../../set-contact-dialog'
import {
  BaseShipmentFormContext,
  GenericShipmentAddressFields,
  GenericShipmentContactFields,
} from '../../shared'
import {
  formatFormAddressDataToPayload,
  formatFormContactDataToPayload,
} from '../../utils'
import { AddressListItem } from './address-list-item'
import { ContactListItem } from './contact-list-item'

export const SenderField = () => {
  const { id: shipmentId } = useParams()
  const isNewShipment = shipmentId === undefined

  const { formState, control, setValue, watch, clearErrors } =
    useFormContext<BaseShipmentFormContext>()

  const [updateShipment] = useMutation(UpdateShipmentV2Document)
  const { t } = useTranslation(['shipments_v2', 'shared'])

  const { enqueueSnackbar } = useSnackbar()

  const { data } = useQuery(GetWarehousesDocument)
  const warehouses = data?.allWarehouses

  const [showSetAddressDialog, setShowSetAddressDialog] = useState(false)
  const [showSetContactDialog, setShowSetContactDialog] = useState(false)

  const shipment = watch()

  const address = shipment?.senderAddress
  const contact = shipment?.senderContact

  const state = formState.defaultValues?.shipmentstate || ShipmentStateV2.New

  const canEdit = state === ShipmentStateV2.New

  const fillWarehouseInfo = useCallback(
    (warehouseId: number | string, shouldDirty = true) => {
      const warehouse = warehouses?.find(({ id }) => id === warehouseId)
      setValue('senderAddress.warehouseid', warehouse?.id, {
        shouldDirty,
      })
      setValue('senderAddress.name', warehouse?.name || '', {
        shouldDirty,
      })
      setValue('senderAddress.address', warehouse?.address || '', {
        shouldDirty,
      })
      setValue('senderAddress.address2', warehouse?.address2 || '', {
        shouldDirty,
      })
      setValue('senderAddress.city', warehouse?.city || '', {
        shouldDirty,
      })
      setValue('senderAddress.zip', warehouse?.zip || '', {
        shouldDirty,
      })
      setValue('senderAddress.state', warehouse?.state || '', {
        shouldDirty,
      })
      setValue(
        'senderAddress.countryid',
        (warehouse?.countryid || '').toUpperCase(),
        {
          shouldDirty,
        },
      )
    },
    [setValue, warehouses],
  )

  useEffect(() => {
    if (!isNewShipment) return
    const warehouseId = warehouses?.[0]?.id

    if (warehouseId) {
      fillWarehouseInfo(warehouseId, false)
    }
  }, [fillWarehouseInfo, isNewShipment, warehouses])

  const hasAddress = (address?: GenericShipmentAddressFields | null) =>
    Object.values(address ?? []).length > 0

  const hasContact = (contact?: GenericShipmentContactFields | null) =>
    Object.values(contact ?? []).length > 0

  const contactRef = useRef<HTMLLIElement>(null)
  const addressRef = useRef<HTMLLIElement>(null)

  useScrollToError({ ref: contactRef, name: 'senderContact', control })
  useScrollToError({ ref: addressRef, name: 'senderAddress', control })

  const submitAddressNewShipment = (
    newAddress: GenericShipmentAddressFields,
  ) => {
    setValue('senderAddress', newAddress, {
      shouldDirty: true,
    })
    clearErrors('senderAddress')
    setShowSetAddressDialog(false)
  }

  const submitAddressExistingShipment = async (
    newAddress: GenericShipmentAddressFields,
  ) => {
    if (shipmentId) {
      await updateShipment({
        variables: {
          shipment: {
            shipmentid: Number(shipmentId),
            ...formatFormAddressDataToPayload('sender', newAddress),
          },
        },
      })
    }

    enqueueSnackbar(t('shipments_v2:shipment_message.success_update'))
    setShowSetAddressDialog(false)
  }

  const submitContactNewShipment = (
    newContact: GenericShipmentContactFields,
  ) => {
    setValue('senderContact', newContact, {
      shouldDirty: true,
    })

    clearErrors('senderContact')
    setShowSetContactDialog(false)
  }

  const submitContactExistingShipment = async (
    newContact: GenericShipmentContactFields,
  ) => {
    if (shipmentId) {
      await updateShipment({
        variables: {
          shipment: {
            shipmentid: Number(shipmentId),
            ...formatFormContactDataToPayload('sender', newContact),
          },
        },
      })
    }

    enqueueSnackbar(t('shipments_v2:shipment_message.success_update'))
    setShowSetContactDialog(false)
  }

  return (
    <FormFieldset label={t('shipments_v2:shipment_form.sender_fieldset')}>
      {isNewShipment && (
        <Controller
          control={control}
          name="senderAddress.warehouseid"
          render={({ field }) => (
            <>
              <InputLabel>
                {t('shipments_v2:shipment_form.warehouse')}
              </InputLabel>
              <Select<number | string>
                value={field.value || ''}
                onChange={(event) => {
                  fillWarehouseInfo(event.target.value)
                }}
                displayEmpty
              >
                <MenuItem value="">{t('shared:label.not_selected')}</MenuItem>
                {warehouses?.map(({ id, name }) => (
                  <MenuItem value={id} key={id}>
                    {name}
                  </MenuItem>
                ))}
              </Select>
              <FormHelperText sx={{ mb: 2 }}>
                {formState?.errors.senderAddress?.warehouseid?.message ||
                  t('shipments_v2:shipment_form.sender_warehouse_description')}
              </FormHelperText>
            </>
          )}
        />
      )}
      <Grid container sx={{ mb: -2, mt: isNewShipment ? 0 : -2 }}>
        <Grid size={{ xs: 12 }}>
          <SetAddressDialog
            action={hasAddress(address) ? 'edit' : 'add'}
            address={address}
            open={showSetAddressDialog}
            onClose={() => setShowSetAddressDialog(false)}
            onSubmit={
              isNewShipment
                ? submitAddressNewShipment
                : submitAddressExistingShipment
            }
            onError={
              isNewShipment
                ? undefined
                : (error) => {
                    const errorMessage = getErrorMessages(error)[0]

                    enqueueSnackbar(errorMessage, { variant: 'error' })
                  }
            }
            isNewShipment={isNewShipment}
          />
          <AddressListItem
            ref={addressRef}
            address={address}
            sx={{
              px: 0,
            }}
            disabled={!hasAddress(address)}
            error={!!formState.errors.senderAddress}
            fallbackTitle={
              hasAddress(address)
                ? t('shipments_v2:shipment_form.address')
                : t('shipments_v2:shipment_form.no_address_added')
            }
            secondaryAction={
              <>
                {canEdit && (
                  <Button
                    size="small"
                    color="secondary"
                    onClick={() => {
                      setShowSetAddressDialog(true)
                    }}
                  >
                    {hasAddress(address)
                      ? t('shared:action.edit')
                      : t('shipments_v2:shipment_form.add_address')}
                  </Button>
                )}
              </>
            }
          />
        </Grid>
        <Grid size={{ xs: 12 }}>
          <Divider sx={{ ml: -2, mr: -2 }} />
        </Grid>

        <Grid size={{ xs: 12 }}>
          <SetContactDialog
            action={hasContact(contact) ? 'edit' : 'add'}
            contact={contact}
            open={showSetContactDialog}
            onClose={() => setShowSetContactDialog(false)}
            onSubmit={
              isNewShipment
                ? submitContactNewShipment
                : submitContactExistingShipment
            }
            onError={
              isNewShipment
                ? undefined
                : (error) => {
                    const errorMessage = getErrorMessages(error)[0]

                    enqueueSnackbar(errorMessage, { variant: 'error' })
                  }
            }
            isNewShipment={isNewShipment}
          />
          <ContactListItem
            ref={contactRef}
            sx={{
              px: 0,
            }}
            contact={contact}
            disabled={!hasContact(contact)}
            error={!!formState.errors.senderContact}
            fallbackTitle={
              hasContact(contact)
                ? t('shipments_v2:shipment_form.contact')
                : t('shipments_v2:shipment_form.no_contact_added')
            }
            secondaryAction={
              <>
                {canEdit && (
                  <Button
                    size="small"
                    color="secondary"
                    onClick={() => {
                      setShowSetContactDialog(true)
                    }}
                  >
                    {hasContact(contact)
                      ? t('shared:action.edit')
                      : t('shipments_v2:shipment_form.add_contact')}
                  </Button>
                )}
              </>
            }
          />
        </Grid>
      </Grid>
    </FormFieldset>
  )
}
