import { useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { usePrevious } from 'react-use'
import { YamlEditor } from '../../../components/yaml-editor'
import {
  PurchasePriceListMappingInput,
  GetPurchasePriceListMappingDocument,
  UpdatePurchasePriceListMappingDocument,
} from '../../../generated/graphql'
import { useTracking } from '../../../hooks/tracking'
import yaml from 'js-yaml'
import { extractGraphqlErrors } from '../../../utils/extract-graphql-errors'
import { GraphQLFormattedError } from 'graphql'
import { useAuthorization } from '../../../hooks/authorization'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material'
import { useMutation, useQuery } from '@apollo/client'
import { stripTypename } from '@apollo/client/utilities'

export type SetPurchasePriceMappingDialogProps = {
  open: boolean
  dataTestid?: string
  onClose(): void
  onSuccess?(): void
  onError?(errors: GraphQLFormattedError | null): void
}

type Form = {
  mapping: PurchasePriceListMappingInput
}

const sanitizeValues = (
  mapping: PurchasePriceListMappingInput | null | undefined,
) => {
  if (mapping?.assigned === null) delete mapping.assigned
  if (mapping?.assigned?.stores === null) delete mapping.assigned.stores

  if (mapping?.shared === null) delete mapping.shared

  return mapping
}

export const SetPurchasePriceMappingDialog = (
  props: SetPurchasePriceMappingDialogProps,
) => {
  const { t } = useTranslation(['settings', 'shared'])
  const {
    modules: { writeSettingsPurchasePriceList },
  } = useAuthorization()

  const dialogName = 'set-purchase-price-mapping'
  const { trackButtonClickEvent, trackDialogClose, trackDialogOpen } =
    useTracking()
  const prevOpen = usePrevious(props.open)
  const [updatePurchasePriceMapping, { loading: loadingUpdate }] = useMutation(
    UpdatePurchasePriceListMappingDocument,
  )

  const formContext = useForm<Form>({
    mode: 'onChange',
  })

  const { loading } = useQuery(GetPurchasePriceListMappingDocument, {
    onCompleted: (data) => {
      const mapping = {
        assigned: data.purchasePriceListMapping.assigned,
        shared: data.purchasePriceListMapping.shared,
      }

      formContext.reset({
        mapping,
      })

      const sanitizedValue = sanitizeValues(
        stripTypename(mapping) as PurchasePriceListMappingInput,
      )

      setYamlOptions(
        sanitizedValue && Object.keys(sanitizedValue).length > 0
          ? yaml.dump(sanitizedValue)
          : '',
      )
    },
  })
  const isLoading = loading || loadingUpdate

  const [yamlOptions, setYamlOptions] = useState('')

  const onSubmit = async (formData: Form) => {
    try {
      const formatPriceListId = (
        obj: Record<string, unknown> | null | undefined,
      ) => {
        for (const key in obj) {
          if (
            key === 'price_list_id' &&
            obj[key] &&
            typeof obj[key] !== 'string'
          ) {
            // TODO: fix this
            // eslint-disable-next-line @typescript-eslint/no-base-to-string
            obj[key] = String(obj[key])
            continue
          }

          if (obj[key] && typeof obj[key] === 'object')
            formatPriceListId(obj[key] as Record<string, unknown>)
        }

        return obj
      }

      await updatePurchasePriceMapping({
        variables: {
          mapping: formatPriceListId(
            formData.mapping,
          ) as PurchasePriceListMappingInput,
        },
      })
      if (props.onSuccess) {
        props.onSuccess()
      }
    } catch (ex) {
      if (props.onError) {
        const errors = extractGraphqlErrors(ex)
        props.onError(errors.length > 0 && errors[0] ? errors[0] : null)
      }
    }
  }

  const onClose = () => {
    if (!isLoading) {
      trackDialogClose({ name: dialogName })
      if (props.onClose) {
        props.onClose()
      }
    }
  }

  useEffect(() => {
    if (props.open && !prevOpen) {
      trackDialogOpen({ name: dialogName })
    }
  }, [props.open, prevOpen, trackDialogOpen])

  return (
    <Dialog
      open={props.open}
      onClose={onClose}
      maxWidth="lg"
      fullWidth
      data-testid={props.dataTestid}
    >
      <form onSubmit={formContext.handleSubmit(onSubmit)}>
        <DialogTitle
          type="extended"
          sx={{
            paddingBottom: (theme) => theme.spacing(2),
          }}
        >
          {t(
            writeSettingsPurchasePriceList
              ? 'settings:purchase_price_lists.edit_mapping'
              : 'settings:purchase_price_lists.view_mapping',
          )}
        </DialogTitle>
        <DialogContent>
          <Controller
            control={formContext.control}
            name="mapping"
            render={({ field, fieldState: { error } }) => (
              <>
                <YamlEditor
                  {...field}
                  ref={(ref) => {
                    field.ref({
                      focus: () => {
                        ref?.refEditor.scrollIntoView({ block: 'center' })
                      },
                    })
                  }}
                  value={yamlOptions}
                  error={!!error}
                  helperText={error?.message ?? ''}
                  minLines={15}
                  readOnly={!writeSettingsPurchasePriceList}
                  onChange={(value) => {
                    try {
                      setYamlOptions(value)

                      const converted = value ? yaml.load(value) : {}
                      field.onChange(converted)
                      formContext.clearErrors('mapping')
                    } catch {
                      formContext.setError('mapping', {
                        message: t(
                          'settings:purchase_price_lists.error.invalid_yaml',
                        ),
                        type: 'validate',
                      })
                    }
                  }}
                  placeholder={`assigned:
  price_list_id: ID
shared:
  2:
    price_list_id: ID_2
    stores:
      100:
        price_list_id: ID_3
                `}
                />
              </>
            )}
          />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={trackButtonClickEvent(
              {
                name: `${dialogName}-dialog-cancel`,
              },
              onClose,
            )}
            color="secondary"
            size="small"
            disabled={isLoading}
            type="button"
          >
            {t(
              writeSettingsPurchasePriceList
                ? 'shared:action.cancel'
                : 'shared:action.close',
            )}
          </Button>
          {writeSettingsPurchasePriceList && (
            <Button
              type="submit"
              size="small"
              loading={isLoading}
              onClick={trackButtonClickEvent({
                name: `${dialogName}-dialog-set`,
              })}
              disabled={!formContext.formState.isValid}
              data-testid="set-purchase-price-mapping"
            >
              {t('shared:action.set')}
            </Button>
          )}
        </DialogActions>
      </form>
    </Dialog>
  )
}
