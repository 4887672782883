import { BaseUserContext } from './shared'

export const formatUserPayload = (formData: BaseUserContext) => {
  const {
    datecreated,
    datemodified,
    allWarehouses,
    id,
    role,
    customernumber,
    tags,
    resolvedTags,
    hasmfa,
    language,
    ...userFields
  } = formData

  return {
    ...userFields,
    role: role || null,
    userid: id,
    customernumber: customernumber || '',
    tags: tags?.map((tag) => tag.id),
  }
}
