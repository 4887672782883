import { NetworkStatus, useQuery } from '@apollo/client'
import { Box, CircularProgress, Divider, List, Grid } from '@mui/material'
import { Suspense } from 'react'
import { Navigate, useParams } from 'react-router-dom'
import { useSessionStorage } from 'react-use'
import { RootRoute } from '../..'
import {
  GetOrdersByIdsDocument,
  GetOrdersQueryVariables,
} from '../../../generated/graphql'
import { useAbsolutePath } from '../../../hooks/absolute-path'
import { useWindowPrint } from '../../../hooks/window-print'
import { MAX_NUM_REQUEST } from '../../../utils/constants'
import { OrderMoneySummary } from '../order-money-summary'
import { SalesTaxes } from '../order-view-panel/sales-taxes'
import { CompanyInformationSection } from './company-information-section'
import { CustomerSection } from './customer-section'
import { DetailsSection } from './details-section'
import { OrderTable } from './order-table'
import { PaymentSection } from './payment-section'
import { OrderHeader } from './order-header'

type PrintData = {
  selectedIds: number[]
}

export const OrdersPrinter = () => {
  const { id } = useParams<{ id: string }>()
  const [printData] = useSessionStorage<PrintData | undefined>(String(id))
  const generatePath = useAbsolutePath()

  const queryVariables: GetOrdersQueryVariables = {
    orderid: printData?.selectedIds,
    num: MAX_NUM_REQUEST,
  }

  const {
    data,
    loading: isLoading,
    networkStatus,
  } = useQuery(GetOrdersByIdsDocument, {
    fetchPolicy: 'cache-and-network',
    variables: queryVariables,
    skip: !printData?.selectedIds.length,
  })

  useWindowPrint({ isReady: networkStatus === NetworkStatus.ready })

  const orders = data?.orders.items

  if (!printData) {
    return <Navigate to={generatePath(RootRoute.Orders)} />
  }

  if (isLoading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        minHeight="100vh"
      >
        <CircularProgress />
      </Box>
    )
  }

  return (
    <Suspense>
      {orders?.map((order, index) => {
        const mainItems = order.orderitems.filter((x) => x.quantity !== 0)
        const isReturn = mainItems.some((x) => x.quantity < 0)

        const showSalesTax = order?.orderitems.some(
          (orderItem) => orderItem.salestaxes?.length,
        )

        return (
          <Box
            key={order.orderid}
            sx={{
              display: 'flex',
              flexDirection: 'column',
              pt: index !== 0 ? 4 : 0,
            }}
          >
            <OrderHeader order={order} isReturn={isReturn} />
            <Grid
              data-testid="order-print-information-row"
              container
              my={6}
              rowGap={4}
              sx={{
                justifyContent: 'space-between',
              }}
            >
              <Grid>
                <DetailsSection order={order} isReturn={isReturn} />
              </Grid>
              <Grid>
                <CompanyInformationSection order={order} />
              </Grid>
              <Grid>
                <CustomerSection order={order} />
              </Grid>
              <Grid>
                <PaymentSection order={order} />
              </Grid>
            </Grid>

            <OrderTable order={order} />

            <List
              sx={{
                alignSelf: 'flex-end',
                maxWidth: '300px',
                minWidth: '250px',
              }}
            >
              {showSalesTax && (
                <>
                  <SalesTaxes
                    order={order}
                    sx={{ mt: 3, '& :nth-of-type(1)': { p: 0 } }}
                    onlyShowTotal={true}
                  />
                  <Divider />
                </>
              )}
              <OrderMoneySummary
                order={order}
                showSalesTax={showSalesTax}
                sx={{ p: 0, mt: showSalesTax ? 0 : 3, pb: 1 }}
              />
            </List>

            <Box
              sx={{
                '@media print': {
                  pageBreakAfter: 'always',
                },
              }}
            />
          </Box>
        )
      })}
    </Suspense>
  )
}
