import { Stack, Chip } from '@mui/material'
import { GridRenderCellParams } from '@mui/x-data-grid-pro'
import { t } from 'i18next'
import { Row } from '.'
import { isLocalVoucherCode } from '../../campaign/utils'

export const VoucherNameRenderer = (
  params: GridRenderCellParams<Row, string>,
) => {
  const showLocalTag = isLocalVoucherCode(params.row?.vouchercode)
  const formattedValue = params.formattedValue

  return (
    <Stack direction="row" alignItems="center" spacing={1}>
      {showLocalTag && (
        <Chip
          size="small"
          color="gray"
          label={t('campaigns:local_label').toLocaleUpperCase()}
        />
      )}
      <span>{formattedValue}</span>
    </Stack>
  )
}
