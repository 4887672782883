import { Button, Menu, MenuItem } from '@mui/material'
import { PlusIcon } from '@sitoo/mui-components'
import { MouseEventHandler, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { AllCustomAttributesQuery } from '../../../../generated/graphql'
import { useMeasure } from 'react-use'

type AttributesMenuProps = {
  customAttributes: AllCustomAttributesQuery['allCustomAttributes']
  onSelectCustomAttribute: (attributeId: string) => void
  disabled?: boolean
}

export const AttributesMenu = (props: AttributesMenuProps) => {
  const { t } = useTranslation(['campaigns', 'shared'])
  const [buttonRef, { width }] = useMeasure<HTMLButtonElement>()
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)

  const isOpen = !!anchorEl
  const handleClick: MouseEventHandler<HTMLButtonElement> = (event) => {
    setAnchorEl(isOpen ? null : event.currentTarget)
  }
  const closeMenu = () => {
    setAnchorEl(null)
  }

  return (
    <>
      <Button
        startIcon={<PlusIcon fontSize="medium" />}
        color="secondary"
        fullWidth
        data-testid="attribute-menu-button"
        aria-controls={isOpen ? 'attribute-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={isOpen ? 'true' : undefined}
        onClick={handleClick}
        ref={buttonRef}
        disabled={props.disabled}
      >
        {t('campaigns:campaign_form.add_attributes')}
      </Button>

      <Menu
        id="attribute-menu"
        anchorEl={anchorEl}
        open={isOpen}
        onClose={closeMenu}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        slotProps={{
          list: {
            'aria-labelledby': 'attribute-menu-button',
          },
        }}
        sx={{
          '.MuiPaper-root': {
            maxWidth: 'initial',
            minWidth: width,
          },
        }}
      >
        {props.customAttributes.length === 0 && (
          <MenuItem disabled>
            {t('campaigns:campaign_form.add_attributes_no_options')}
          </MenuItem>
        )}

        {props.customAttributes.map((c) => (
          <MenuItem
            key={c.id}
            data-value={c.id}
            onClick={() => {
              props.onSelectCustomAttribute(c.id)
              closeMenu()
            }}
          >
            {c.title}
          </MenuItem>
        ))}
      </Menu>
    </>
  )
}
