import { useQuery } from '@apollo/client'
import {
  Autocomplete,
  Chip,
  ListItemText,
  MenuItem,
  TextField,
} from '@mui/material'
import { useContext, useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { FilterContext } from '../../../components/data-grid/context'
import { StaffUsersDocument, User } from '../../../generated/graphql'
import { useStateParams } from '../../../hooks/state-params'
import { useTracking } from '../../../hooks/tracking'
import { MAX_ITEMS_ORDER_API } from '.'

type QueryParamsState = {
  staffId?: string | string[]
}

export const StaffUsersFilter = () => {
  const { t } = useTranslation(['shared', 'filter'])
  const {
    hasApply,
    setFilter,
    removeFilter,
    subscribeOnResetFilter,
    registerFilter,
  } = useContext(FilterContext)

  const { trackInputFocus, trackInputBlur } = useTracking()
  const filterKey = 'staffId'
  const { data, loading: isLoading } = useQuery(StaffUsersDocument)

  const getUserName = (
    user: Pick<User, 'namefirst' | 'namelast' | 'externalid'>,
    hideExternalId = false,
  ) => {
    return [
      user.namefirst,
      user.namelast,
      user.externalid && !hideExternalId ? `(${user.externalid})` : '',
    ]
      .filter(Boolean)
      .join(' ')
  }

  const users = useMemo(() => {
    return (
      data?.staffUsers
        .filter((user, index, users) => {
          const currentIndex = users.findIndex(
            ({ externalid }) => externalid === user.externalid,
          )
          const isUnique = index === currentIndex

          return user.externalid && isUnique
        })
        .sort((a, b) => getUserName(a).localeCompare(getUserName(b))) || []
    )
  }, [data?.staffUsers])

  const [queryParams, setQueryParams] = useStateParams<QueryParamsState>()

  const selectedExternalUserIds = useMemo(
    () =>
      typeof queryParams[filterKey] === 'string'
        ? [queryParams[filterKey]]
        : queryParams[filterKey] || [],
    [queryParams],
  )

  useEffect(() => {
    registerFilter({
      key: filterKey,
      isReady: !isLoading,
    })
  }, [isLoading, registerFilter])

  useEffect(() => {
    const validUsers = selectedExternalUserIds.map(
      (externalId) => users.find((u) => u.id === externalId) || externalId,
    )

    if (validUsers.length) {
      setFilter(filterKey, {
        label: t('filter:staff_filter.label'),
        labelValues: validUsers.map((externalId) =>
          typeof externalId === 'string' ? externalId : getUserName(externalId),
        ),
        value: validUsers.map((externalId) =>
          typeof externalId === 'string' ? externalId : externalId.externalid,
        ),
      })
    } else {
      removeFilter(filterKey)
    }
  }, [users, selectedExternalUserIds, removeFilter, setFilter, t])

  useEffect(() => {
    const unsubscribe = subscribeOnResetFilter((key) => {
      if (!key || key === filterKey) {
        setQueryParams({ [filterKey]: undefined })
      }
    })

    return () => unsubscribe()
  }, [setQueryParams, subscribeOnResetFilter])

  return (
    <Autocomplete
      autoHighlight
      multiple
      limitTags={3}
      fullWidth
      options={users}
      getOptionLabel={(option) => getUserName(option)}
      getOptionDisabled={(option) =>
        selectedExternalUserIds.length >= MAX_ITEMS_ORDER_API &&
        !selectedExternalUserIds.includes(String(option.id))
      }
      disabled={isLoading}
      onChange={(_event, value) => {
        setQueryParams(
          {
            [filterKey]:
              (
                value.map((user) => user.externalid).filter(Boolean) as string[]
              ).slice(0, MAX_ITEMS_ORDER_API) || undefined,
          },
          hasApply !== true,
        )
      }}
      value={users.filter(
        (user) =>
          user.externalid && selectedExternalUserIds.includes(user.externalid),
      )}
      data-testid="staff-field"
      renderInput={(params) => (
        <TextField
          label={t('filter:staff_filter.label')}
          type="text"
          {...params}
          onFocus={trackInputFocus({ name: `staff-${filterKey}` })}
          onBlur={trackInputBlur({ name: `staff-${filterKey}` })}
          slotProps={{
            htmlInput: {
              ...params.inputProps,
              ['data-testid']: 'staff-input',
            },
          }}
        />
      )}
      renderOption={(props, user) => {
        return (
          <MenuItem {...props} key={user.id}>
            <ListItemText
              secondary={
                user.externalid
                  ? `${t('shared:label.external_id')}: (${String(
                      user.externalid,
                    )})`
                  : undefined
              }
            >
              {getUserName(user, true)}
            </ListItemText>
          </MenuItem>
        )
      }}
      renderTags={(values, getTagProps) =>
        values.map((user, index) => {
          return (
            <Chip
              label={getUserName(user)}
              size="medium"
              color="blue"
              {...getTagProps({ index })}
              key={user.id}
            />
          )
        })
      }
    />
  )
}
