import { useFormContext } from 'react-hook-form'
import { GeneralSettingsFormContext } from '../general-settings-form'
import { useTranslation } from 'react-i18next'
import { AllProductGroupsDocument } from '../../../../generated/graphql'
import { useQuery } from '@apollo/client'
import { DialogInput } from '../../../../inputs/dialog-input'
import { SettingsListSection } from '../../../../components/settings-list-section'
import { useMemo } from 'react'

const fieldNames = [
  'campaigns_exclude_product_groups',
  'dashboard_ignore_product_groups',
] as const

export const PosProductGroups = () => {
  const { control } = useFormContext<GeneralSettingsFormContext>()
  const { t } = useTranslation(['shared', 'settings'])
  const { data, loading } = useQuery(AllProductGroupsDocument)

  const getProductGroupFromId = (id: number) => {
    return data?.allProductGroups.find((group) => group.id === id)
  }

  const formatValue = (value: number) => {
    const group = getProductGroupFromId(value)

    return {
      label: group ? `${group.name} (${group.value}%)` : '',
    }
  }

  const options = useMemo(
    () =>
      [...(data?.allProductGroups ?? [])]
        .sort((a, b) => a.name.localeCompare(b.name))
        .map(({ id }) => id),
    [data],
  )

  return (
    <SettingsListSection title={t('settings:general.pos_product_groups.title')}>
      {fieldNames.map((name) => {
        return (
          <DialogInput
            key={name}
            control={control}
            isLoading={loading}
            name={name}
            formatValue={formatValue}
            helperText={t(`settings:general.pos_product_groups.${name}_helper`)}
            itemLabel={t(`settings:general.pos_product_groups.${name}_title`)}
            addLabel={t('settings:general.pos_product_groups.add_label')}
            options={options}
            emptyMessage={t(
              'settings:general.pos_product_groups.empty_message',
            )}
          />
        )
      })}
    </SettingsListSection>
  )
}
