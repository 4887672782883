import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  List,
  ListItem,
} from '@mui/material'
import { SectionHeader } from '@sitoo/mui-components'
import { useTranslation } from 'react-i18next'
import { FilterViewPanel } from '../../../../components/filter-view-panel'
import { ProductCategoryIdFilter } from '../../../../filters/product-category-id-filter'
import { FranchiseSiteFilter } from '../../../products/product-list/franchise-site-filter'
import { ProductStateFilter } from '../../../products/product-list/product-state-filter'
import { PricelistFilter } from '../prices-list/pricelist-filter'

type PricesFilterViewPanelProps = {
  isOpen: boolean
  onClose(): void
}

export const PricesFilterViewPanel = (props: PricesFilterViewPanelProps) => {
  const { t } = useTranslation(['prices'])

  return (
    <FilterViewPanel open={props.isOpen} onClose={props.onClose}>
      <Accordion defaultExpanded className="MuiAccordionRoot">
        <AccordionSummary aria-controls="filter">
          <SectionHeader sx={{ p: 0 }}>{t('prices:page_title')}</SectionHeader>
        </AccordionSummary>
        <AccordionDetails sx={{ px: 0, py: 1.5 }}>
          <List sx={{ '.MuiListItem-root': { mb: 0.5 } }}>
            <ListItem sx={{ '&:empty': { display: 'none' } }}>
              <FranchiseSiteFilter />
            </ListItem>

            <ListItem>
              <ProductCategoryIdFilter />
            </ListItem>

            <ListItem>
              <ProductStateFilter />
            </ListItem>

            <ListItem>
              <PricelistFilter />
            </ListItem>
          </List>
        </AccordionDetails>
      </Accordion>
    </FilterViewPanel>
  )
}
