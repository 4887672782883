import { useTranslation } from 'react-i18next'
import { PosProfilesList } from './pos-profiles-list'
import { PageHeader } from '../../../components/page-header'
import { Button, Grid } from '@mui/material'
import { FilterProvider } from '../../../components/data-grid/context'
import { usePageTitle } from '../../../hooks/title'
import { useNavigate } from 'react-router-dom'
import { useAbsolutePath } from '../../../hooks/absolute-path'
import { RootRoute } from '../..'

export const SettingsPosProfilesPage = () => {
  const { t } = useTranslation('pos_profiles')
  usePageTitle(t('pos_profiles:page_title'))
  const navigate = useNavigate()
  const generatePath = useAbsolutePath()
  return (
    <FilterProvider>
      <Grid container>
        <Grid size="grow">
          <PageHeader
            title={t('pos_profiles:page_title')}
            isSticky
            isFlexible={true}
            rightColumn={
              <Button
                data-testid="add-pos-profile-button"
                onClick={() =>
                  navigate(generatePath(RootRoute.SettingsPosProfileNew))
                }
              >
                {t('pos_profiles:add_pos_profile')}
              </Button>
            }
          />

          <PosProfilesList />
        </Grid>
      </Grid>
    </FilterProvider>
  )
}
