import { ProductState } from '../../pages/products/product-list/product-state-filter'

export const MAX_NUM_REQUEST = 10000

export const DEFAULT_PRODUCT_STATE: ProductState = 'active'

export const DEFAULT_PRODUCT_GROUP_ID = 2

export const DEFAULT_PRICELIST_ID = 1

export const DEFAULT_SITE_ID = 1

export const FRANCHISE_SITE_ID_PARAM = 'siteId'

export const DEFAULT_ORDER_ID = -1

export const ALL_PRODUCTS_ATTR = '_PRODUCTS_'

export const REDIRECT_QUERY_PARAM = 'r'

export const DECIMAL_REGEX = /^([0-9]+)(\.)?([0-9]*)$/

// Container size
export const PAGE_HEADER_HEIGHT = 64
export const NAVBAR_HEIGHT = 56
export const PAGE_BOTTOM_OFFSET = 24
