import { Alert, Box, List, Paper, Typography } from '@mui/material'
import { SectionHeader } from '@sitoo/mui-components'

type Props = {
  title?: string
  children: React.ReactNode
  description?: string
  information?: React.ReactNode
}

export const SettingsListSection = ({
  title,
  children,
  description,
  information,
}: Props) => {
  return (
    <Paper elevation={0} sx={{ mb: 2 }} component="section" aria-label={title}>
      <Box
        sx={{
          py: title ? 1.5 : 0,
          display: 'flex',
          flexDirection: 'column',
          gap: 0.5,
        }}
      >
        {title && (
          <SectionHeader
            sx={{
              px: 2,
              display: 'flex',
              flexDirection: 'column',
              gap: 0.5,
              mb: 0,
              py: 0,
            }}
            variant="transparent"
          >
            {title}
          </SectionHeader>
        )}

        {description && (
          <Box
            sx={{
              mx: 2,
              display: 'flex',
              flexDirection: 'column',
              gap: 1,
            }}
          >
            {description && (
              <Typography variant="body01" color="textPrimary">
                {description}
              </Typography>
            )}
          </Box>
        )}
      </Box>
      {information && (
        <Box sx={{ px: 2, mb: 1 }}>
          <Alert variant="outlined" severity="muted">
            <Typography variant="body01">{information}</Typography>
          </Alert>
        </Box>
      )}

      <List>{children}</List>
    </Paper>
  )
}
