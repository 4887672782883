import { List, ListItem, ListItemText } from '@mui/material'
import { SectionHeader } from '@sitoo/mui-components'
import { useTranslation } from 'react-i18next'
import { ListItemSkeleton } from '../../../../components/list-item-skeleton'
import { PaymentType } from '../../../../generated/graphql'
import { PaymentMethodIcon } from '../payment-method-icon'

type Props = {
  isLoading?: boolean
  paymentOption?: PaymentType
}

export const Method = (props: Props) => {
  const { t } = useTranslation(['payment_options'])
  const { isLoading, paymentOption } = props

  return (
    <List>
      <SectionHeader>{t('payment_options:method')}</SectionHeader>

      {isLoading && (
        <ListItemSkeleton
          isLoading={isLoading}
          secondaryAction="-"
          childrenSkeleton
        />
      )}

      {!isLoading && paymentOption && (
        <ListItem>
          <PaymentMethodIcon paymentOption={paymentOption} fontSize="large" />

          <ListItemText
            primary={t(
              `payment_options:payment_method.${paymentOption.paymentmethodid}`,
            )}
            slotProps={{
              primary: {
                sx: {
                  display: '-webkit-box',
                  WebkitLineClamp: 2,
                  WebkitBoxOrient: 'vertical',
                },
              },

              secondary: {
                whiteSpace: 'nowrap',
              },
            }}
          />
        </ListItem>
      )}
    </List>
  )
}
