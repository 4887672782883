import { useMutation } from '@apollo/client'
import { ListItem, ListItemText } from '@mui/material'
import { FormFieldset } from '@sitoo/mui-components'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { UpdateArchiveStateShipmentsV2Document } from '../../../../generated/graphql'
import { BaseShipmentFormContext } from '../../shared'
import { enqueueSnackbar } from 'notistack'
import { getErrorMessages } from '../../../../utils/error-mapping'
import { SwitchInput } from '../../../../inputs/switch-input'

type ArchiveShipmentProps = {
  shipmentId: number
}

export const ArchiveShipment = ({ shipmentId }: ArchiveShipmentProps) => {
  const { control, getValues } = useFormContext<BaseShipmentFormContext>()
  const { t } = useTranslation(['shipments_v2'])

  const [archiveShipment, { loading }] = useMutation(
    UpdateArchiveStateShipmentsV2Document,
  )

  const handleArchive = async () => {
    const archived = getValues('archived')
    if (typeof archived === 'boolean') {
      try {
        await archiveShipment({
          variables: {
            shipments: [{ shipmentid: shipmentId, archived: !archived }],
          },
        })

        enqueueSnackbar(t('shipments_v2:shipment_message.success_update'))
      } catch (error) {
        const errorMessage = getErrorMessages(error)[0]
        enqueueSnackbar(errorMessage, { variant: 'error' })
      }
    }
  }

  return (
    <FormFieldset
      sx={{
        '.MuiFormFieldset-Paper': {
          p: 0,
        },
      }}
    >
      <ListItem
        secondaryAction={
          <SwitchInput
            disabled={loading}
            name="archived"
            control={control}
            onChange={handleArchive}
          />
        }
      >
        <ListItemText primary={t(`shipments_v2:shipment_form.archived`)} />
      </ListItem>
    </FormFieldset>
  )
}
