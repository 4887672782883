import {
  AdyenIcon,
  CoineyIcon,
  CurrencySwitchIcon,
  FreedomPayIcon,
  IZettleIcon,
  KlarnaIcon,
  KlarnaOldIcon,
  MobilePayIcon,
  NetsIcon,
  OrderIcon,
  PaymentCashIcon,
  PaymentCreditCardIcon,
  PaymentCustomIcon,
  PaymentGiftcardIcon,
  PaymentUnknownIcon,
  ReceiptIcon,
  SitooPaymentsIcon,
  SwishIcon,
  VerifoneIcon,
  VippsIcon,
  VippsMobilePayIcon,
} from '@sitoo/mui-components'
import { ElementType } from 'react'
import { PaymentType, PosPaymentMethod } from '../../../generated/graphql'

/**
 * Retrieves an array of payment types based on the provided keys and respects the order of the key array
 *
 * @param {number[]} keys - An array of payment type IDs to search for.
 * @param {PaymentType[]} [allPaymentTypes] - An array of all available payment types.
 * @returns {PaymentType[]} An array of payment types that match the provided keys.
 *
 */
export const getPaymentTypesFromIds = (
  keys: number[],
  allPaymentTypes?: PaymentType[],
): PaymentType[] => {
  if (!allPaymentTypes) return []

  return keys
    .map((key) =>
      allPaymentTypes.find((paymentType) => paymentType.paymenttypeid === key),
    )
    .filter(
      (paymentType): paymentType is PaymentType => paymentType !== undefined,
    )
}

export const paymentMethodIconMap: Record<PosPaymentMethod, ElementType> = {
  [PosPaymentMethod.CARD_ADYEN_SDK]: AdyenIcon,
  [PosPaymentMethod.CARD_ADYEN_TAP_TO_PAY]: AdyenIcon,
  [PosPaymentMethod.CARD_ADYEN_TERMINAL_API]: AdyenIcon,
  [PosPaymentMethod.CARD_COINEY]: CoineyIcon,
  [PosPaymentMethod.CARD_EXTERNAL]: PaymentCreditCardIcon,
  [PosPaymentMethod.CARD_FREEDOMPAY]: FreedomPayIcon,
  [PosPaymentMethod.CARD_HOST2T]: PaymentCreditCardIcon,
  [PosPaymentMethod.CARD_IZETTLE]: IZettleIcon,
  [PosPaymentMethod.CARD_NETS]: NetsIcon,
  [PosPaymentMethod.CARD_VERIFONE_AIRPAY]: VerifoneIcon,
  [PosPaymentMethod.CARD_VERIFONE_LPP]: VerifoneIcon,
  [PosPaymentMethod.CASH]: PaymentCashIcon,
  [PosPaymentMethod.CASH_ON_DELIVERY]: PaymentCashIcon,
  [PosPaymentMethod.CREDIT_NOTE]: ReceiptIcon,
  [PosPaymentMethod.CREDIT_NOTE_AS_GIFT_CARD]: PaymentGiftcardIcon,
  [PosPaymentMethod.CUSTOM_INVOICE]: PaymentCustomIcon,
  [PosPaymentMethod.CUSTOM_METHOD_1]: PaymentCustomIcon,
  [PosPaymentMethod.CUSTOM_METHOD_2]: PaymentCustomIcon,
  [PosPaymentMethod.CUSTOM_METHOD_3]: PaymentCustomIcon,
  [PosPaymentMethod.CUSTOM_METHOD_4]: PaymentCustomIcon,
  [PosPaymentMethod.CUSTOM_METHOD_5]: PaymentCustomIcon,
  [PosPaymentMethod.CUSTOM_PAYMENT_API]: PaymentUnknownIcon,
  [PosPaymentMethod.FOREIGN_CURRENCY_CASH]: CurrencySwitchIcon,
  [PosPaymentMethod.GIFT_CARD]: PaymentGiftcardIcon,
  [PosPaymentMethod.INVOICE]: OrderIcon,
  [PosPaymentMethod.KLARNA]: KlarnaIcon,
  [PosPaymentMethod.KLARNA_LEGACY]: KlarnaOldIcon,
  [PosPaymentMethod.MOBILEPAY]: MobilePayIcon,
  [PosPaymentMethod.SWISH]: SwishIcon,
  [PosPaymentMethod.SWISH_WITH_QR]: SwishIcon,
  [PosPaymentMethod.VIPPS]: VippsIcon,
  [PosPaymentMethod.VIPPS_MOBILEPAY]: VippsMobilePayIcon,
  [PosPaymentMethod.SITOO_PAYMENTS]: SitooPaymentsIcon,
}
