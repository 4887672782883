import { useTranslation } from 'react-i18next'
import { SettingsListItem } from '../../../../components/settings-list-item'
import { SettingsListSection } from '../../../../components/settings-list-section'
import { useFormContext } from 'react-hook-form'
import { GeneralSettingsFormContext } from '../general-settings-form'
import { useMemo } from 'react'
import { SelectInput } from '../../../../inputs/select-input'
import {
  GetConfigVarsDocument,
  SwitchboardRecommendations,
} from '../../../../generated/graphql'
import { useQuery } from '@apollo/client'

export const LegacyRecommendations = () => {
  const { t } = useTranslation(['shared', 'settings'])
  const { control } = useFormContext<GeneralSettingsFormContext>()

  const { data: configVarsData } = useQuery(GetConfigVarsDocument)

  const legacyModule = configVarsData?.configVars.recommendations
  const newModule = configVarsData?.configVars.productRecommendations
  const nosto = configVarsData?.configVars.nosto

  const recommendations = useMemo(() => {
    const options = [
      {
        name: t('settings:general.legacy_recommendations.not_activated'),
        value: null,
      },
      {
        name: t(
          'settings:general.legacy_recommendations.sitoo_recommendations',
        ),
        value: SwitchboardRecommendations.Recommendationssitoo,
      },
    ]

    if (nosto) {
      options.push({
        name: t('settings:general.legacy_recommendations.nosto'),
        value: SwitchboardRecommendations.Nosto,
      })
    }

    return options
  }, [nosto, t])

  if (!legacyModule || newModule) {
    return
  }

  return (
    <SettingsListSection
      title={t('settings:general.legacy_recommendations.title')}
    >
      <SettingsListItem>
        <SelectInput
          label={t('settings:general.legacy_recommendations.label')}
          name="recommendations"
          control={control}
          options={recommendations}
          helperText={t('settings:general.legacy_recommendations.helper_text')}
          slotProps={{ select: { displayEmpty: true } }}
        />
      </SettingsListItem>
    </SettingsListSection>
  )
}
