import { useQuery } from '@apollo/client'
import { ListItem } from '@mui/material'
import { useMemo } from 'react'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { Form } from '..'
import { GetStoresDocument } from '../../../../generated/graphql'
import { SelectInput } from '../../../../inputs/select-input'

export const OmnifulfillmentStoresInput = () => {
  const { control } = useFormContext<Form>()
  const { t } = useTranslation('reports')

  const { data } = useQuery(GetStoresDocument)

  const stores = useMemo(() => {
    return [
      { name: t('create_dialog.include_all'), value: '' },
      ...(data?.stores
        .filter((store) => store.externalid)
        .map((store) => ({
          name: `${store.name} (${store.externalid})`,
          value: String(store.externalid),
        })) || []),
    ]
  }, [data?.stores, t])

  return (
    <>
      <ListItem>
        <SelectInput
          control={control}
          label={t('create_dialog.selling_store')}
          name="reportdefinition.filters.omniFulfillmentStoreSales"
          options={stores}
          slotProps={{ select: { displayEmpty: true } }}
        />
      </ListItem>
      <ListItem>
        <SelectInput
          control={control}
          label={t('create_dialog.delivering_store')}
          name="reportdefinition.filters.omniFulfillmentStoreDelivery"
          options={stores}
          slotProps={{ select: { displayEmpty: true } }}
        />
      </ListItem>
    </>
  )
}
