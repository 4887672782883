import { Button } from '@mui/material'
import {
  Box,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Stack,
} from '@mui/material'
import {
  DeleteIcon,
  EditIcon,
  FormFieldset,
  PlusIcon,
} from '@sitoo/mui-components'
import { useState } from 'react'
import {
  FieldArrayWithId,
  useFieldArray,
  useFormContext,
} from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { SalesTaxProductGroupRule } from '../../../../generated/graphql'
import { SalesTaxInvalidRuleBanner } from '../../sales-taxes/sales-tax-invalid-rule-banner'
import { useSalesTaxFormatter } from '../../sales-taxes/useSalesTaxFormatter'
import { SetRuleDialog } from '../set-rule-dialog'
import { BaseSalesTaxContext } from '../shared'

export const RulesField = () => {
  const { t } = useTranslation(['settings'])

  const { control } = useFormContext<BaseSalesTaxContext>()
  const { fields, append, update, remove } = useFieldArray({
    control,
    name: 'productgrouprules',
  })
  const { formatNameAndTax, formatRuleTypeAndAmounts } = useSalesTaxFormatter()
  const [isRuleDialogOpen, setRuleDialogOpen] = useState(false)
  const [selectedRule, setSelectedRule] =
    useState<SalesTaxProductGroupRule | null>()
  const [selectedIndex, setSelectedIndex] = useState<number>(fields.length)

  const resetState = () => {
    setSelectedRule(null)
    setSelectedIndex(fields.length)
  }

  const handleCloseDialog = () => {
    resetState()
    setRuleDialogOpen(false)
  }

  const onEditRule = (
    rule: FieldArrayWithId<SalesTaxProductGroupRule>,
    index: number,
  ) => {
    const { id, ...rest } = rule
    setSelectedRule(rest as SalesTaxProductGroupRule)
    setSelectedIndex(index)
    setRuleDialogOpen(true)
  }

  const onDeleteRule = (index: number) => {
    remove(index)
  }

  return (
    <>
      <SetRuleDialog
        rule={selectedRule}
        open={isRuleDialogOpen}
        onClose={handleCloseDialog}
        onSave={(rule: SalesTaxProductGroupRule) => update(selectedIndex, rule)}
        onAdd={(rule: SalesTaxProductGroupRule) => append(rule)}
      />

      <FormFieldset
        label={t('sales_tax.rules_fieldset')}
        sx={{ '.MuiFormFieldset-Paper': { p: 0 } }}
      >
        <List>
          {fields.map((field, index) => {
            const isRuleInvalid = !field.groupName
            return (
              <ListItem
                divider
                key={field.id}
                data-testid={`sales-tax-rule-row-${index}`}
                sx={{
                  width: '100%',
                  display: 'flex',
                  alignItems: 'center',
                  p: 0,
                }}
              >
                <Stack direction="column" width="100%">
                  <Box sx={{ display: 'flex', py: 1, px: 2 }}>
                    <ListItemText
                      sx={{
                        color: isRuleInvalid ? 'redBase' : '',
                        '& .MuiListItemText-secondary': {
                          color: isRuleInvalid ? 'redBase' : '',
                        },
                      }}
                      primary={formatNameAndTax(
                        field?.groupName,
                        field.decimalvalue,
                      )}
                      slotProps={{
                        secondary: {
                          component: 'div',
                          sx: {
                            whiteSpace: 'pre-line',
                          },
                        },
                      }}
                      secondary={
                        <>
                          {formatRuleTypeAndAmounts(field)}
                          {field.externalid && (
                            <>
                              <br />
                              {`${t('sales_tax.external_id')}: ${field.externalid}`}
                            </>
                          )}
                        </>
                      }
                    />

                    <Box>
                      <IconButton
                        onClick={() => onEditRule(field, index)}
                        data-testid="edit-rule-button"
                      >
                        <EditIcon color="primary" />
                      </IconButton>
                      <IconButton
                        onClick={() => onDeleteRule(index)}
                        data-testid="delete-rule-button"
                      >
                        <DeleteIcon color="primary" />
                      </IconButton>
                    </Box>
                  </Box>
                  {isRuleInvalid && <SalesTaxInvalidRuleBanner />}
                </Stack>
              </ListItem>
            )
          })}
        </List>
        <ListItem sx={{ p: 0, minHeight: 'auto' }}>
          <Button
            fullWidth
            color="tertiary"
            onClick={() => {
              resetState()
              setRuleDialogOpen(true)
            }}
            data-testid="sales-tax-add-rule-button"
          >
            <PlusIcon sx={{ mr: 1 }} />
            {t('sales_tax.add_rule')}
          </Button>
        </ListItem>
      </FormFieldset>
    </>
  )
}
