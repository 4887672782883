import { ListItem, Stack } from '@mui/material'
import { useFormContext } from 'react-hook-form'
import { SelectInput, Option } from '../../../../inputs/select-input'
import { useTranslation } from 'react-i18next'
import { useMemo } from 'react'
import {
  ReportDateMode,
  ReportDefinitionFilter,
} from '../../../../generated/graphql'
import { Form } from '..'
import { DatePickerInput } from '../../../../inputs/date-picker-input'
import { useCalculateDateSpan } from '../../calculate-date-span'
import { parseEnum } from '../../../../utils/parse-enum'
type Props = {
  dateStartKey: keyof Pick<
    ReportDefinitionFilter,
    | 'orderDateStart'
    | 'creditNoteDateStart'
    | 'giftCardDateStart'
    | 'omniFulfillmentDateStart'
    | 'warehouseDateStart'
    | 'salesTaxDateStart'
  >
  dateEndKey: keyof Pick<
    ReportDefinitionFilter,
    | 'orderDateEnd'
    | 'creditNoteDateEnd'
    | 'giftCardDateEnd'
    | 'omniFulfillmentDateEnd'
    | 'warehouseDateEnd'
    | 'stockDateEnd'
    | 'salesTaxDateEnd'
  >
}
export const DateRangePickerInput = (props: Props) => {
  const { control, setValue } = useFormContext<Form>()
  const { t } = useTranslation('reports')

  const dateModeOptions: Option[] = useMemo(
    () =>
      Object.values(ReportDateMode).map((value) => ({
        name: t(`date_mode_keys.${value}`),
        value,
      })),
    [t],
  )

  const calculateDateSpan = useCalculateDateSpan()

  const handleSetDates = (datemode: ReportDateMode) => {
    if (datemode === ReportDateMode.Default) return
    const [startDate, endDate] = calculateDateSpan(datemode)
    setValue(`reportdefinition.filters.${props.dateStartKey}`, startDate)
    setValue(`reportdefinition.filters.${props.dateEndKey}`, endDate)
  }

  const handleDatePickerInputChange = () =>
    setValue('datemode', ReportDateMode.Default)

  return (
    <>
      <ListItem>
        <SelectInput
          control={control}
          label={t('create_dialog.date_range')}
          name="datemode"
          options={dateModeOptions}
          key="datemode"
          onChange={(e) =>
            handleSetDates(
              parseEnum(ReportDateMode, e.target.value) ??
                ReportDateMode.Default,
            )
          }
        />
      </ListItem>
      <ListItem>
        <Stack direction="row" spacing={2} width="100%">
          <DatePickerInput
            control={control}
            label={t('create_dialog.date_start')}
            name={`reportdefinition.filters.${props.dateStartKey}`}
            onChange={handleDatePickerInputChange}
            transform={{ output: (value) => value?.startOf('day').toJSON() }}
          />
          <DatePickerInput
            control={control}
            label={t('create_dialog.date_end')}
            name={`reportdefinition.filters.${props.dateEndKey}`}
            onChange={handleDatePickerInputChange}
            transform={{ output: (value) => value?.endOf('day').toJSON() }}
          />
        </Stack>
      </ListItem>
    </>
  )
}
