import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  ListItemText,
} from '@mui/material'
import yaml from 'js-yaml'
import { useCallback, useState } from 'react'
import { useForm, useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { SettingsListItem } from '../../../../../../components/settings-list-item'
import { enqueueSnackbar } from 'notistack'
import { Form } from '../../..'
import { ThemesQuery } from '../../../../../../generated/graphql'
import { YamlInput } from '../../../../../../inputs/yaml-input'

const isValidConfig = (value: unknown): value is Record<string, unknown> => {
  return typeof value === 'object' || value === null || value === undefined
}

type Props = {
  themes: ThemesQuery['themes']
  loading?: boolean
}

export const ThemeConfigurationField = ({ themes, loading }: Props) => {
  const formContext = useFormContext<Form>()
  const { t } = useTranslation(['pos_profiles', 'shared'])
  const [open, setOpen] = useState(false)

  const currentThemeId = formContext.watch('pos_theme_id')

  const getParamsFromThemeId = useCallback(
    (themeId?: string | null) => {
      if (!themeId) return {}
      const params = themes?.find((theme) => theme.id === themeId)?.params || []

      return params.reduce<Record<string, string>>((acc, param) => {
        if (param.type === 'Sku') {
          acc[param.id] = ''
        }
        return acc
      }, {})
    },
    [themes],
  )

  const convertToYaml = useCallback(() => {
    try {
      const posProfileThemeData = formContext.getValues('pos_theme_data')
      const themeSpecificData = getParamsFromThemeId(currentThemeId)
      const mergedThemeData = { ...themeSpecificData, ...posProfileThemeData }
      const hasValues = Object.keys(mergedThemeData).length > 0
      const yamlString = hasValues ? yaml.dump(mergedThemeData) : ''

      return yamlString
    } catch (error) {
      enqueueSnackbar(t('pos_profiles:pos_theme_section.failed_to_convert'), {
        variant: 'error',
      })
      return
    }
  }, [currentThemeId, formContext, getParamsFromThemeId, t])

  const {
    control: posThemeControl,
    handleSubmit,
    reset,
  } = useForm<{
    rawYaml: string
  }>({
    defaultValues: {
      rawYaml: convertToYaml() || '',
    },
  })

  const handleOpenDialog = () => {
    const currentYaml = convertToYaml() || ''

    reset({
      rawYaml: currentYaml,
    })
    setOpen(true)
  }

  const updatePosTheme = (values: { rawYaml: string }) => {
    const parsedYaml = yaml.load(values.rawYaml)

    if (isValidConfig(parsedYaml)) {
      formContext.setValue('pos_theme_data', parsedYaml || null, {
        shouldDirty: true,
      })
    }
    setOpen(false)
  }

  return (
    <SettingsListItem
      loading={loading}
      dense
      secondaryAction={
        <Button
          color="secondary"
          size="small"
          onClick={handleOpenDialog}
          disabled={!currentThemeId}
          aria-label={`${t('shared:action.edit')} ${t('pos_profiles:pos_theme_section.theme_configuration_label')}`}
        >
          {t('shared:action.edit')}
        </Button>
      }
    >
      <ListItemText
        primary={t('pos_profiles:pos_theme_section.theme_configuration_label')}
        secondary={t(
          'pos_profiles:pos_theme_section.theme_configuration_description',
        )}
      />
      <Dialog
        open={open}
        onClose={(_event, reason) => {
          if (reason === 'backdropClick') return
          setOpen(false)
        }}
        fullWidth
        maxWidth="xs"
      >
        <form
          onSubmit={(e) => {
            e.stopPropagation()
            e.preventDefault()

            return handleSubmit(updatePosTheme)()
          }}
        >
          <DialogTitle>
            {t('pos_profiles:pos_theme_section.theme_configuration_label')}
          </DialogTitle>
          <DialogContent>
            <YamlInput
              control={posThemeControl}
              name="rawYaml"
              rules={{
                validateParsed: (parsedYaml) => isValidConfig(parsedYaml),
              }}
              helperText={t(
                'pos_profiles:pos_theme_section.theme_configuration_description',
              )}
            />
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => setOpen(false)}
              color="secondary"
              size="small"
            >
              {t('shared:action.cancel')}
            </Button>
            <Button size="small" type="submit">
              {t('shared:action.ok')}
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </SettingsListItem>
  )
}
