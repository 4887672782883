import { useQuery } from '@apollo/client'
import { useContext, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { FilterContext } from '../../../components/data-grid/context'
import { GetReportJobOwnersDocument } from '../../../generated/graphql'
import { useAuthorization } from '../../../hooks/authorization'
import { useFormFilter } from '../../../hooks/form-filter'
import { AutocompleteInput } from '../../../inputs/autocomplete-input'

export type ReportJobOwnerFilter = {
  owner?: string[]
}

export const OwnerFilter = () => {
  const { t } = useTranslation(['shared', 'filter'])
  const { setFilter, removeFilter, subscribeOnResetFilter, registerFilter } =
    useContext(FilterContext)

  const filterKey = 'owner'

  const {
    modules: { reportsFullPermission },
  } = useAuthorization()

  const { data } = useQuery(GetReportJobOwnersDocument, {
    skip: !reportsFullPermission,
  })

  const { queryParams, formContext, resetForm } =
    useFormFilter<ReportJobOwnerFilter>({
      formProps: {
        defaultValues: { owner: [] },
      },
      parseOptions: {
        types: { owner: (value) => value.split(',').map(String) },
      },
    })

  const { owner } = queryParams

  useEffect(() => {
    registerFilter({ key: filterKey })
  }, [registerFilter])

  useEffect(() => {
    if (owner?.length) {
      setFilter(filterKey, {
        label: t('reports:columns.owner'),
        labelValues: owner,
        value: owner,
      })
    } else {
      removeFilter(filterKey)
    }
  }, [owner, removeFilter, setFilter, t])

  useEffect(() => {
    const unsubscribe = subscribeOnResetFilter((key) => {
      if (!key || key === filterKey) {
        resetForm()
      }
    })

    return () => unsubscribe()
  }, [resetForm, subscribeOnResetFilter])

  const options = data?.reportJobOwners || []

  return (
    <AutocompleteInput
      multiple
      name="owner"
      control={formContext.control}
      options={options}
      label={t('reports:owner')}
      textFieldProps={{
        slotProps: {
          htmlInput: { ['data-testid']: 'report-jobs-owner-input' },
        },
      }}
      autocompleteProps={{
        limitTags: 5,
        getOptionKey: (option) => option,
      }}
    />
  )
}
