import { useQuery } from '@apollo/client'
import { Button, Container } from '@mui/material'
import { GridColDef, useGridApiRef } from '@mui/x-data-grid-pro'
import { PhoneMobileIcon } from '@sitoo/mui-components'
import { useCallback, useContext, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { DataGrid } from '../../../components/data-grid'
import { DataGridImageRenderer } from '../../../components/data-grid-image'
import { FilterContext } from '../../../components/data-grid/context'
import { CursorPagination } from '../../../components/data-grid/cursor-pagination'
import { SortItem } from '../../../components/data-grid/filters/sort'
import { TextFilter } from '../../../components/data-grid/filters/text-filter'
import { ColumnProps } from '../../../components/data-grid/utils/column-props'
import {
  GetPosProfilesSort,
  PosProfileAssociatedDataDocument,
  PosProfilesDocument,
  PosProfilesQuery,
  PosProfilesQueryVariables,
} from '../../../generated/graphql'
import { ArrayElement } from '../../../utils/types'
import { useNavigate } from 'react-router-dom'
import { useAbsolutePath } from '../../../hooks/absolute-path'
import { RootRoute } from '../..'
import useGetTimerString from './use-get-timer-string'

const PAGE_SIZE = 100

type Row = ArrayElement<PosProfilesQuery['posProfiles']['items']>
type Filter = TextFilter

export const PosProfilesList = () => {
  const { t } = useTranslation('pos_profiles')

  const apiRef = useGridApiRef()
  const navigate = useNavigate()
  const generatePath = useAbsolutePath()
  const getTimerString = useGetTimerString()

  const { filter, isFilterReady } =
    useContext<FilterContext<Filter>>(FilterContext)

  const sortModel = apiRef.current.state?.sorting.sortModel

  const sortItems = useMemo<SortItem<GetPosProfilesSort>[]>(
    () => [
      {
        field: 'name',
        sort: 'asc',
        title: t('pos_profiles:columns.name'),
        value: GetPosProfilesSort.NameAsc,
        type: 'text',
        isDefault: true,
      },
      {
        field: 'name',
        sort: 'desc',
        title: t('pos_profiles:columns.name'),
        value: GetPosProfilesSort.NameDesc,
        type: 'text',
      },
      {
        field: 'autologouttimer',
        sort: 'asc',
        title: t('pos_profiles:columns.auto_logout_timer'),
        value: GetPosProfilesSort.AutoLogoutTimerAsc,
        type: 'number',
      },
      {
        field: 'autologouttimer',
        sort: 'desc',
        title: t('pos_profiles:columns.auto_logout_timer'),
        value: GetPosProfilesSort.AutoLogoutTimerDesc,
        type: 'number',
      },
      {
        field: 'num_registers',
        sort: 'asc',
        title: t('pos_profiles:columns.num_registers'),
        value: GetPosProfilesSort.NumRegistersAsc,
        type: 'number',
      },
      {
        field: 'num_registers',
        sort: 'desc',
        title: t('pos_profiles:columns.num_registers'),
        value: GetPosProfilesSort.NumRegistersDesc,
        type: 'number',
      },
    ],
    [t],
  )

  const queryVariables = useMemo(() => {
    const config = {
      pagination: {
        start: 0,
        pageSize: PAGE_SIZE,
      },
      filter,
      sorting: sortModel,
    }

    const variables: PosProfilesQueryVariables = {
      num: config.pagination?.pageSize,
      start: 0,
      searchtext: config.filter.text?.value,
    }

    if (config.sorting) {
      const sortItem = config.sorting[0]

      if (sortItem) {
        variables.sort = sortItems.find(
          (s) => s.field === sortItem.field && s.sort === sortItem.sort,
        )?.value
      }
    }

    return variables
  }, [filter, sortItems, sortModel])

  const {
    data,
    loading: isLoadingPosProfiles,
    fetchMore,
  } = useQuery(PosProfilesDocument, {
    variables: queryVariables,
    fetchPolicy: 'cache-and-network',
    notifyOnNetworkStatusChange: true,
    skip: !isFilterReady,
  })

  const { data: associatedData, loading: isLoadingAssociatedData } = useQuery(
    PosProfileAssociatedDataDocument,
  )

  const dataGridColumns = useMemo<GridColDef<Row>[]>(
    () => [
      {
        field: 'logotype',
        ...ColumnProps.image,
        headerName: '',
        valueGetter: (_value, row) => {
          return row.logotype
        },
        cellClassName: 'image-column',
        headerClassName: 'image-column',
        renderCell: DataGridImageRenderer,
      },

      {
        field: 'name',
        headerName: t('pos_profiles:columns.name'),
        minWidth: 200,
        hideable: false,
      },
      {
        field: 'autologouttimer',
        headerName: t('pos_profiles:columns.auto_logout_timer'),
        renderCell: ({ value }) => getTimerString(value),
        minWidth: 180,
      },
      {
        field: 'dashboardid',
        headerName: t('pos_profiles:columns.dashboard'),
        valueGetter: (value) => {
          const dashboard = associatedData?.dashboards?.find(
            (item) => item.id === value,
          )
          return dashboard ? dashboard.name : ''
        },
        minWidth: 200,
      },
      {
        field: 'num_registers',
        headerName: t('pos_profiles:columns.num_registers'),
        renderCell: ({ value }) =>
          value === 0 ? t('pos_profiles:grid.not_used') : value,
        minWidth: 160,
        align: 'right',
      },
      {
        field: 'productdisplaymode',
        headerName: t('pos_profiles:columns.product_display'),
        minWidth: 140,

        renderCell: ({ value }) =>
          t(`pos_profiles:product_display_mode.${value}`),
      },
      {
        field: 'override_paymenttypes',
        headerName: t('pos_profiles:columns.payment_types'),
        minWidth: 200,

        valueGetter: (value: number[]) => {
          const paymentTypes = associatedData?.allPaymentTypes
            ?.filter(
              (item) =>
                item.paymenttypeid && value.includes(item.paymenttypeid),
            )
            .map((item) => item.name)
            .join(', ')
          if (!paymentTypes || paymentTypes.length === 0)
            return t('pos_profiles:grid.all_payment_types')

          return paymentTypes
        },
      },
    ],
    [
      t,
      getTimerString,
      associatedData?.dashboards,
      associatedData?.allPaymentTypes,
    ],
  )

  const fetchMoreItems = useCallback(() => {
    const { pageSize } = apiRef.current.state.pagination.paginationModel

    if (data?.posProfiles.totalcount) {
      return fetchMore({
        variables: {
          start: (data.posProfiles?.start || 0) + pageSize,
        },
      })
    }
  }, [apiRef, data, fetchMore])

  const isLoading = isLoadingPosProfiles || isLoadingAssociatedData
  return (
    <Container maxWidth={false}>
      <DataGrid
        apiRef={apiRef}
        name="pos-profiles-list"
        columns={dataGridColumns}
        rows={data?.posProfiles?.items || []}
        rowCount={data?.posProfiles.totalcount || 0}
        loading={isLoading}
        fetchMore={fetchMoreItems}
        onRowClick={({ id }) =>
          navigate(generatePath(RootRoute.SettingsPosProfile, { id }))
        }
        noRowsOverlay={{
          icon: <PhoneMobileIcon />,
          title: t('pos_profiles:grid.title'),
          description: t('pos_profiles:grid.description'),
          action: (
            <Button
              onClick={() =>
                navigate(generatePath(RootRoute.SettingsPosProfileNew))
              }
            >
              {t('pos_profiles:add_pos_profile')}
            </Button>
          ),
        }}
        rowHeight={50}
        sx={{
          '.image-column': {
            padding: '0 !important',
          },
        }}
        slots={{ pagination: CursorPagination }}
        hasTextFilter
        textFilterPlaceHolder={t('pos_profiles:search_placeholder')}
        disableColumnFilter
        hasPageHeader
        paginationModel={{ page: 0, pageSize: PAGE_SIZE }}
        updateSearchParams
        sorting={sortItems}
        columnVisibilityModel={{
          logotype: true,
          name: true,
          autologouttimer: true,
          dashboard: true,
          num_registers: true,
          productdisplaymode: false,
          override_paymenttypes: false,
        }}
        showMore={
          Number(data?.posProfiles.items?.length) <
          Number(data?.posProfiles.totalcount)
        }
      />
    </Container>
  )
}
