import { Container, List, ListItem, Paper } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { FormProvider, useForm } from 'react-hook-form'
import {
  GetPluginsDocument,
  PluginId,
  PluginCtuNorway,
  UpdateCtuNorwayDocument,
} from '../../../../generated/graphql'
import { PluginsForm } from '../../plugins/plugins-form'
import { useMutation, useQuery } from '@apollo/client'
import { usePageTitle } from '../../../../hooks/title'
import { CurrencyInput } from '../../../../inputs/currency-input'
import { CountriesInput } from '../../../../inputs/countries-input'
import { DatePickerInput } from '../../../../inputs/date-picker-input'
import { TextFieldInput } from '../../../../inputs/text-field-input'

type Form = PluginCtuNorway

export const validateCtuSettings = (plugin: PluginCtuNorway | undefined) => {
  return plugin && Object.values(plugin).every(Boolean)
}

export const CtuNorwaySettings = () => {
  const { t } = useTranslation(['shared', 'apps'])
  usePageTitle(t('shared:menu.ctu_norway'))

  const defaultValues: Form = {
    companyId: null,
    companyName: null,
    currencyCode: null,
    taxRegistrationNumber: null,
    taxRegistrationCountry: 'SE',
    dateFiscalFirst: null,
    dateFiscalSecond: null,
  }

  const formContext = useForm<Form>({ defaultValues })
  const { control, reset, formState } = formContext
  const { isSubmitting } = formState

  useQuery(GetPluginsDocument, {
    fetchPolicy: 'cache-and-network',
    variables: { pluginId: PluginId.Ctunorway },
    onCompleted: (data) => {
      if (data.plugin.__typename === 'PluginCtuNorway') {
        reset(data.plugin, { keepDefaultValues: false })
      }
    },
  })

  const [updateSettings] = useMutation(UpdateCtuNorwayDocument)

  const onSubmit = async (data: Form) => {
    await updateSettings({ variables: { data } })
  }

  return (
    <Container>
      <Paper elevation={0} sx={{ pt: 1 }}>
        <FormProvider {...formContext}>
          <PluginsForm onSubmit={onSubmit}>
            <List sx={{ '.MuiListItem-root': { pb: 2 } }}>
              <ListItem>
                <TextFieldInput
                  name="companyId"
                  control={control}
                  required
                  label={t('apps:ctu_norway.company_id_label')}
                  helperText={t('apps:ctu_norway.company_id_helper')}
                  disabled={isSubmitting}
                />
              </ListItem>

              <ListItem>
                <TextFieldInput
                  name="taxRegistrationNumber"
                  control={control}
                  required
                  label={t('apps:ctu_norway.tax_registration_number_label')}
                  helperText={t(
                    'apps:ctu_norway.tax_registration_number_helper',
                  )}
                  disabled={isSubmitting}
                />
              </ListItem>

              <ListItem>
                <TextFieldInput
                  name="companyName"
                  control={control}
                  required
                  label={t('apps:ctu_norway.company_name_label')}
                  helperText={t('apps:ctu_norway.company_name_helper')}
                  disabled={isSubmitting}
                />
              </ListItem>

              <ListItem>
                <CurrencyInput
                  name="currencyCode"
                  control={control}
                  required
                  label={t('apps:ctu_norway.currency_code_label')}
                  helperText={t('apps:ctu_norway.currency_code_helper')}
                />
              </ListItem>

              <ListItem>
                <CountriesInput
                  name="taxRegistrationCountry"
                  control={control}
                  required
                  label={t('apps:ctu_norway.tax_country_code_label')}
                />
              </ListItem>

              <ListItem>
                <DatePickerInput
                  control={control}
                  name="dateFiscalFirst"
                  required
                  label={t('apps:ctu_norway.date_fiscal_first_label')}
                  helperText={t('apps:ctu_norway.date_fiscal_first_helper')}
                  transform={{
                    output: (value) => value?.startOf('day').toJSON(),
                  }}
                />
              </ListItem>

              <ListItem>
                <DatePickerInput
                  control={control}
                  name="dateFiscalSecond"
                  required
                  label={t('apps:ctu_norway.date_fiscal_second_label')}
                  helperText={t('apps:ctu_norway.date_fiscal_second_helper')}
                  transform={{
                    output: (value) => value?.endOf('day').toJSON(),
                  }}
                />
              </ListItem>
            </List>
          </PluginsForm>
        </FormProvider>
      </Paper>
    </Container>
  )
}
