import { useQuery } from '@apollo/client'
import {
  Paper,
  Typography,
  useTheme,
  Container,
  Stack,
  Box,
  Divider,
  useMediaQuery,
  List,
  ListItem,
  ListItemText,
} from '@mui/material'
import { SectionHeader, ThemeProvider } from '@sitoo/mui-components'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { NumActiveCashRegistersDocument } from '../../generated/graphql'
import { useLocalizedDate } from '../../hooks/localized-date'
import { useMe } from '../../hooks/me'

export const Summary = () => {
  const theme = useTheme()
  const { t } = useTranslation(['home'])
  const { formatDateToParts, formatDate } = useLocalizedDate()
  const { me } = useMe()
  const [currentTime, setCurrentTime] = useState(new Date())

  const isMd = useMediaQuery(theme.breakpoints.down('md'))

  const siteName = me?.currentSite.eshopname
    ? `(${me?.currentSite.eshopname})`
    : ''

  const { data } = useQuery(NumActiveCashRegistersDocument)

  const numActiveRegisters = data?.numActiveCashRegisters

  const date = formatDate(currentTime, {
    weekday: 'long',
    month: 'short',
    day: '2-digit',
    year: 'numeric',
  })

  const time = formatDate(currentTime, {
    hour: '2-digit',
    minute: '2-digit',
    hour12: false,
  })

  const offset = formatDateToParts(currentTime, {
    timeZoneName: 'longOffset',
  }).find(({ type }) => type === 'timeZoneName')?.value

  const timezoneOffset = offset ? `(${offset})` : ''

  const timeZone = formatDateToParts(currentTime, {
    timeZoneName: 'longGeneric',
  }).find(({ type }) => type === 'timeZoneName')?.value

  useEffect(() => {
    setInterval(() => {
      setCurrentTime(new Date())
    }, 1000)
  }, [])

  return (
    <ThemeProvider mode="dark">
      <Paper elevation={0} sx={{ mb: 5 }} data-testid="summary-section">
        <Container sx={{ py: 4.5 }}>
          <Stack
            direction={{ sm: 'column', md: 'row' }}
            divider={
              <Divider
                flexItem
                orientation={isMd ? 'horizontal' : 'vertical'}
              />
            }
            spacing={2}
            justifyContent="space-between"
          >
            <Box sx={{ width: '100%', px: 2 }}>
              <SectionHeader variant="transparent" sx={{ pl: 0, mb: 1.5 }}>
                {t('home:active_pos')}
              </SectionHeader>
              <Typography variant="displayLarge">
                {numActiveRegisters}
              </Typography>
            </Box>

            <Box sx={{ width: '100%', px: 2 }}>
              <SectionHeader variant="transparent" sx={{ pl: 0, mb: 0.5 }}>
                {t('home:system')}
              </SectionHeader>

              <List>
                <ListItem
                  secondaryAction={me?.accountId}
                  disablePadding
                  divider
                >
                  <ListItemText
                    primary={t('home:account')}
                    slotProps={{
                      primary: { variant: 'body01' },
                      secondary: { variant: 'body01' },
                    }}
                  />
                </ListItem>
                <ListItem
                  data-testid="site-id"
                  secondaryAction={`${me?.siteId || 0} ${siteName}`}
                  disablePadding
                >
                  <ListItemText
                    primary={t('home:site')}
                    slotProps={{
                      primary: { variant: 'body01' },
                      secondary: { variant: 'body01' },
                    }}
                  />
                </ListItem>
              </List>
            </Box>

            <Box sx={{ width: '100%', px: 2 }}>
              <SectionHeader variant="transparent" sx={{ pl: 0 }}>
                {t('home:time_and_date')}
              </SectionHeader>

              <Stack spacing={1}>
                <Typography variant="body02">{date}</Typography>
                <Typography variant="displayMedium">{time}</Typography>
                <Typography variant="caption">
                  {timeZone} {timezoneOffset}
                </Typography>
              </Stack>
            </Box>
          </Stack>
        </Container>
      </Paper>
    </ThemeProvider>
  )
}
