import { useApolloClient, useMutation } from '@apollo/client'
import { Box, Button, Divider, ListItemText } from '@mui/material'
import { FormFieldset, Link } from '@sitoo/mui-components'
import { useSnackbar } from 'notistack'
import { useRef, useState } from 'react'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { ListItemSkeleton } from '../../../../components/list-item-skeleton'
import {
  ActionType,
  AllShipmentActionsDocument,
  GetShipmentDocument,
  ShipmentInfoCarrier,
  ShipmentState,
  AddShipmentActionDocument,
} from '../../../../generated/graphql'
import { useLocalizedDate } from '../../../../hooks/localized-date'
import { stripNullValues } from '../../../../utils/strip-null-values'
import { EditShipmentFormContext } from '../../edit-shipment'
import { SetCarrierDialog } from '../../set-carrier-dialog'
import { SetStateDialog } from '../../set-state-dialog'
import { stripEmptyProperties } from '../../../../utils/strip-empty-properties'
import { useScrollToError } from '../../../../hooks/scroll-to-error'
import { getShipmentsErrorMessage } from '../../shared'

export const EditInformation = () => {
  const { formState, watch, control } =
    useFormContext<EditShipmentFormContext>()
  const { t } = useTranslation(['shipments', 'shared'])
  const [showCarrierDialog, setShowCarrierDialog] = useState(false)
  const [showSetStateDialog, setShowSetStateDialog] = useState(false)
  const { enqueueSnackbar } = useSnackbar()
  const client = useApolloClient()
  const [addShipmentAction] = useMutation(AddShipmentActionDocument)
  const { formatDate } = useLocalizedDate()

  const shipment = watch()

  const carrier = watch('info.carrier')
  const hasCarrier = (carrier?: ShipmentInfoCarrier | null) => {
    return (
      [
        carrier?.name,
        carrier?.service,
        carrier?.reference,
        carrier?.tracking_url,
        carrier?.tracking_url_title,
        carrier?.estimated_pickup_at,
        carrier?.estimated_delivery_at,
      ].filter(Boolean).length > 0
    )
  }

  const carrierRef = useRef<HTMLLIElement>(null)

  useScrollToError({ ref: carrierRef, name: 'info.carrier', control })

  const submitCarrier = async (newCarrier: ShipmentInfoCarrier) => {
    await addShipmentAction({
      variables: {
        shipmentId: shipment.id || '',
        data: {
          shipment_version: shipment.version || 0,
          action: ActionType.SetInfo,
          info: stripNullValues(
            stripEmptyProperties({
              ...shipment.info,
              carrier: newCarrier,
            }),
          ),
        },
      },
    })

    await client.refetchQueries({
      include: [GetShipmentDocument, AllShipmentActionsDocument],
    })

    enqueueSnackbar(t('shipments:shipment_message.success_update'))
    setShowCarrierDialog(false)
  }

  return (
    <FormFieldset label={t('shipments:shipment_form.shipment_info_fieldset')}>
      <Box sx={{ my: -2 }}>
        <ListItemSkeleton
          childrenSkeleton
          sx={{
            px: 0,
          }}
          secondaryAction={
            <>
              {shipment?.state &&
              [
                ShipmentState.Cancelled,
                ShipmentState.Closed,
                ShipmentState.ClosedIncomplete,
              ].includes(shipment.state) ? (
                <ListItemText
                  primary={t(`shipments:shipment_state.${shipment.state}`)}
                />
              ) : (
                <>
                  <SetStateDialog
                    open={showSetStateDialog}
                    state={shipment?.state}
                    shipmentId={shipment?.id || ''}
                    shipmentVersion={shipment?.version || 0}
                    allItemsReceived={shipment?.all_items_received}
                    hasUndeclaredItems={!!shipment?.items_undeclared?.length}
                    onClose={() => setShowSetStateDialog(false)}
                    onSuccess={() => {
                      enqueueSnackbar(
                        t('shipments:shipment_message.success_update'),
                      )
                      setShowSetStateDialog(false)
                    }}
                    onError={(errorMessage) => {
                      enqueueSnackbar(errorMessage, { variant: 'error' })
                    }}
                  />
                  <Button
                    data-testid="set-shipment-state-button"
                    size="small"
                    color="secondary"
                    onClick={() => setShowSetStateDialog(true)}
                  >
                    {t('shared:action.edit')}
                  </Button>
                </>
              )}
            </>
          }
        >
          <ListItemText
            primary={t(`shipments:shipment_form.shipment_state`)}
            secondary={
              shipment?.state &&
              ![
                ShipmentState.Cancelled,
                ShipmentState.Closed,
                ShipmentState.ClosedIncomplete,
              ].includes(shipment.state)
                ? t(`shipments:shipment_state.${shipment.state || ''}`)
                : ''
            }
          />
        </ListItemSkeleton>

        <Divider sx={{ mx: -2 }} />

        <ListItemSkeleton
          childrenSkeleton
          sx={{
            px: 0,
          }}
          secondaryAction={
            <ListItemText
              primary={
                shipment.created_at ? formatDate(shipment.created_at) : ''
              }
            />
          }
        >
          <ListItemText primary={t(`shipments:shipment_form.created`)} />
        </ListItemSkeleton>

        <Divider sx={{ mx: -2 }} />

        <ListItemSkeleton
          childrenSkeleton
          sx={{
            px: 0,
          }}
          secondaryAction={
            <ListItemText
              primary={
                shipment.modified_at ? formatDate(shipment.modified_at) : ''
              }
            />
          }
        >
          <ListItemText primary={t(`shipments:shipment_form.modified`)} />
        </ListItemSkeleton>

        <Divider sx={{ mx: -2 }} />

        <ListItemSkeleton
          childrenSkeleton
          sx={{
            px: 0,
          }}
          secondaryAction={
            <ListItemText
              primary={t(
                shipment?.all_packages_arrived
                  ? 'shared:label.yes'
                  : 'shared:label.no',
              )}
            />
          }
        >
          <ListItemText
            primary={t(`shipments:shipment_form.all_packages_arrived`)}
          />
        </ListItemSkeleton>

        <Divider sx={{ mx: -2 }} />

        <ListItemSkeleton
          childrenSkeleton
          sx={{
            px: 0,
          }}
          secondaryAction={
            <ListItemText
              primary={t(
                shipment?.all_items_received
                  ? 'shared:label.yes'
                  : 'shared:label.no',
              )}
            />
          }
        >
          <ListItemText
            primary={t(`shipments:shipment_form.all_items_received`)}
          />
        </ListItemSkeleton>

        <Divider sx={{ mx: -2 }} />

        <ListItemSkeleton
          childrenSkeleton
          sx={{
            px: 0,
          }}
          secondaryAction={
            <ListItemText primary={shipment?.items_undeclared?.length || 0} />
          }
        >
          <ListItemText
            primary={t(`shipments:shipment_form.undeclared_items_received`)}
          />
        </ListItemSkeleton>

        {shipment?.info?.currency && <Divider sx={{ mx: -2 }} />}

        <ListItemSkeleton
          childrenSkeleton
          hide={!shipment?.info?.currency}
          sx={{
            px: 0,
          }}
          secondaryAction={<ListItemText primary={shipment?.info?.currency} />}
        >
          <ListItemText primary={t(`shipments:shipment_form.currency`)} />
        </ListItemSkeleton>

        <Divider sx={{ mx: -2 }} />

        <ListItemSkeleton
          childrenSkeleton
          sx={{
            px: 0,
            whiteSpace: 'pre-line',
          }}
          secondaryAction={
            <>
              <SetCarrierDialog
                action={hasCarrier(carrier) ? 'edit' : 'add'}
                carrier={carrier}
                open={showCarrierDialog}
                onClose={() => setShowCarrierDialog(false)}
                onSuccess={() => setShowCarrierDialog(false)}
                onSubmit={submitCarrier}
                onError={(error) => {
                  const errorMessage = getShipmentsErrorMessage(error)

                  enqueueSnackbar(errorMessage, { variant: 'error' })
                }}
              />
              {shipment.state &&
                ![
                  ShipmentState.Cancelled,
                  ShipmentState.Closed,
                  ShipmentState.ClosedIncomplete,
                ].includes(shipment.state) && (
                  <Button
                    data-testid="edit-carrier-button"
                    size="small"
                    color="secondary"
                    onClick={() => {
                      setShowCarrierDialog(true)
                    }}
                  >
                    {hasCarrier(carrier)
                      ? t('shared:action.edit')
                      : t('shipments:shipment_form.add_carrier')}
                  </Button>
                )}
            </>
          }
        >
          <ListItemText
            ref={carrierRef}
            primary={t(`shipments:shipment_form.carrier`)}
            sx={{
              ...(formState.errors.info?.carrier
                ? {
                    color: (theme) => theme.palette.error.main,
                  }
                : {}),
            }}
            slotProps={{
              secondary: {
                sx: {
                  '&.MuiListItemText-secondary': {
                    ...(formState.errors.info?.carrier
                      ? {
                          color: (theme) => theme.palette.error.main,
                        }
                      : {}),
                  },
                },
              },
            }}
            secondary={
              <>
                {[
                  [
                    carrier?.name,
                    carrier?.service ? `(${carrier?.service})` : '',
                  ]
                    .filter(Boolean)
                    .join(' '),
                  carrier?.reference,
                ]
                  .filter(Boolean)
                  .join('\n')}
                {carrier?.tracking_url && (
                  <>
                    <br />
                    <Link href={carrier?.tracking_url || ''} target="_blank">
                      {carrier?.tracking_url_title || carrier?.tracking_url}
                    </Link>
                    <br />
                  </>
                )}
                {[
                  carrier?.estimated_pickup_at
                    ? `${t(
                        'shipments:shipment_form.estimated_pickup_at',
                      )}: ${formatDate(carrier.estimated_pickup_at)}`
                    : '',
                  carrier?.estimated_delivery_at
                    ? `${t(
                        'shipments:shipment_form.estimated_delivery_at',
                      )}: ${formatDate(carrier.estimated_delivery_at)}`
                    : '',
                ]
                  .filter(Boolean)
                  .join('\n')}
              </>
            }
          />
        </ListItemSkeleton>
      </Box>
    </FormFieldset>
  )
}
