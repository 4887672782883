import { Navigate } from 'react-router-dom'
import { useMe } from '../../../hooks/me'
import { RootRoute } from '../..'
import { useAbsolutePath } from '../../../hooks/absolute-path'
import { PageLoader } from '../../../components/page-loader'
import { CustomOverlay } from '../../../components/custom-overlay'
import { SettingsIcon } from '@sitoo/mui-components'
import { useTranslation } from 'react-i18next'
import { Box } from '@mui/material'
import { useReactiveVar } from '@apollo/client'
import { navbarHeightReactiveVar } from '../../../cache'
import { PAGE_BOTTOM_OFFSET } from '../../../utils/constants'

export const SettingsPage = () => {
  const { isAdmin } = useMe()
  const { t } = useTranslation('settings')
  const generatePath = useAbsolutePath()
  const navbarHeight = useReactiveVar(navbarHeightReactiveVar)

  if (isAdmin) {
    return (
      <>
        <PageLoader />
        <Navigate to={generatePath(RootRoute.SettingsGeneral)} />
      </>
    )
  }

  return (
    <Box
      sx={{
        height: `calc(100vh - ${navbarHeight}px - ${PAGE_BOTTOM_OFFSET}px)`,
      }}
    >
      <CustomOverlay
        icon={<SettingsIcon />}
        title={t('landing.title')}
        description={t('landing.description')}
        sx={{
          backgroundColor: (theme) => theme.palette.background.page,
        }}
      />
    </Box>
  )
}
