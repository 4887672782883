import { Intercom } from '@intercom/messenger-js-sdk'
import { useEffect } from 'react'
import { useMe } from '../me'
import { GetConfigVarsDocument } from '../../generated/graphql'
import { useQuery, useReactiveVar } from '@apollo/client'
import { globalViewPanelIds } from '../../cache'
import { VIEW_PANEL_WIDTH } from '../../components/view-panel'

const INTERCOM_APP_ID = 'hxv736c4'

/**
 * TODO: Re-implement this as a Analytics Plugin
 * See ./src/providers/analytics/index.tsx
 */
export const useIntercom = () => {
  const { me, displayName } = useMe()

  const { data: configVarsData } = useQuery(GetConfigVarsDocument, {
    skip: !me?.user,
  })

  const isViewPanelOpen = !!useReactiveVar(globalViewPanelIds).length

  const defaultPadding = 20
  const paddingLeft = isViewPanelOpen ? VIEW_PANEL_WIDTH + defaultPadding : 0
  const paddingBottom = defaultPadding

  const isEnabled = configVarsData?.configVars?.intercom
  const email = me?.user?.email
  const accountId = me?.accountId
  const siteId = me?.siteId
  const userId = me?.user?.id
  const intercomHash = me?.intercomHash

  useEffect(() => {
    if (!isEnabled || !email || !userId || !intercomHash) {
      return
    }

    Intercom({
      app_id: INTERCOM_APP_ID,
      region: 'eu',
      email,
      name: displayName,
      user_id: userId,
      user_hash: intercomHash,
      horizontal_padding: paddingLeft,
      vertical_padding: paddingBottom,
      company: {
        id: accountId,
        eshop_id: siteId,
      },
    })
  }, [
    accountId,
    displayName,
    email,
    intercomHash,
    isEnabled,
    paddingBottom,
    paddingLeft,
    siteId,
    userId,
  ])
}
