import { Button } from '@mui/material'
import { SettingsListSection } from '../../../../../components/settings-list-section'
import { useTranslation } from 'react-i18next'
import { SettingsListItem } from '../../../../../components/settings-list-item'
import { useState } from 'react'
import { useMutation } from '@apollo/client'
import { DeletePosProfileDocument } from '../../../../../generated/graphql'
import { useNavigate, useParams } from 'react-router-dom'
import { ConfirmationDialog } from '@sitoo/mui-components'
import { RootRoute } from '../../../..'
import { useAbsolutePath } from '../../../../../hooks/absolute-path'
import { enqueueSnackbar } from 'notistack'

export const DeletePosProfile = () => {
  const { id: posProfileId } = useParams()
  const [open, setOpen] = useState(false)
  const navigate = useNavigate()
  const { t } = useTranslation(['pos_profiles'])
  const [deletePosProfile] = useMutation(DeletePosProfileDocument, {
    variables: {
      posProfileId: Number(posProfileId),
    },
  })
  const generatePath = useAbsolutePath()

  const handleDelete = async () => {
    try {
      const { data } = await deletePosProfile()
      if (!data?.deletePosProfile)
        throw new Error('Failed to delete pos profile')
      setOpen(false)
      enqueueSnackbar(t('pos_profiles:delete_pos_profile.success'), {
        variant: 'success',
      })
      void navigate(generatePath(RootRoute.SettingsPosProfiles), {
        replace: true,
      })
    } catch (error) {
      enqueueSnackbar(t('pos_profiles:delete_pos_profile.error'), {
        variant: 'error',
      })
    }
  }

  return (
    <SettingsListSection title={t('pos_profiles:delete_pos_profile.title')}>
      <SettingsListItem>
        <Button
          variant="contained"
          color="error"
          fullWidth
          onClick={() => setOpen(true)}
        >
          {t('pos_profiles:delete_pos_profile.title')}
        </Button>
      </SettingsListItem>
      <ConfirmationDialog
        open={open}
        onClose={() => setOpen(false)}
        title={t('pos_profiles:delete_pos_profile.title')}
        text={t('pos_profiles:delete_pos_profile.message')}
        confirmAction={handleDelete}
        variant="destructive"
      />
    </SettingsListSection>
  )
}
