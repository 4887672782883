import { useMutation, useQuery } from '@apollo/client'
import { Button } from '@mui/material'
import { Divider, Container } from '@mui/material'
import { useSnackbar } from 'notistack'
import { useCallback, useEffect, useMemo } from 'react'
import { FormProvider, SubmitErrorHandler, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import { RootRoute } from '../..'
import { PageHeader } from '../../../components/page-header'
import {
  AddSalesTaxDocument,
  SalesTaxDocument,
  SalesTaxProductGroupRule,
  UpdateSalesTaxDocument,
} from '../../../generated/graphql'
import { useAbsolutePath } from '../../../hooks/absolute-path'
import { useServerValidation } from '../../../hooks/server-validation'
import { useTracking } from '../../../hooks/tracking'
import { getErrorMessages } from '../../../utils/error-mapping'
import { salesTaxViewPanelVar } from '../sales-taxes'
import { DeleteSalesTaxField } from './fields/delete-sales-tax-field'
import { InformationField } from './fields/information-field'
import { RulesField } from './fields/rules-field'
import { BaseSalesTaxContext } from './shared'
import { FormRouteLeavingGuard } from '../../../components/form-route-leaving-guard'

export const SettingsSalesTaxPage = () => {
  const { id: salesTaxId, siteId } = useParams()
  const isNewSalesTax = !salesTaxId
  const { t } = useTranslation(['shared', 'settings'])
  const { trackButtonClick, trackFormSuccess, trackFormError } = useTracking()
  const { enqueueSnackbar } = useSnackbar()
  const navigate = useNavigate()
  const generatePath = useAbsolutePath()

  const { data: salesTaxData, loading: isSalesTaxDataLoading } = useQuery(
    SalesTaxDocument,
    {
      variables: { salesTaxId: Number(salesTaxId) },
      skip: !salesTaxId,
    },
  )

  const [updateSalesTax, { loading: isUpdatingSalesTax }] = useMutation(
    UpdateSalesTaxDocument,
  )

  const [addSalesTax, { loading: isAddingSalesTax }] =
    useMutation(AddSalesTaxDocument)

  const formContext = useForm<BaseSalesTaxContext>({
    defaultValues: { decimalvaluedefault: '0' },
  })

  const setFormError = useServerValidation<BaseSalesTaxContext>(
    'settings:sales_tax',
    formContext.setError,
    { resolveFieldFromProperty: (property) => property },
  )
  const onSubmit = async (submittedData: BaseSalesTaxContext) => {
    try {
      let salesTaxIdToOpen = salesTaxId
      const { id, productgrouprules, ...rest } = submittedData

      const formattedProductGroupRules =
        productgrouprules && productgrouprules.length > 0
          ? productgrouprules?.map(
              ({ groupName, moneymax, moneymin, ...rest }) => {
                return {
                  ...rest,
                  moneymax: moneymax || null,
                  moneymin: moneymin || null,
                }
              },
            )
          : []

      if (isNewSalesTax) {
        const { data } = await addSalesTax({
          variables: {
            data: { ...rest, productgrouprules: formattedProductGroupRules },
          },
        })

        salesTaxIdToOpen = String(data?.addSalesTax.id)
        trackButtonClick({ name: 'sales-tax-add' })
      } else {
        await updateSalesTax({
          variables: {
            data: { ...rest, productgrouprules: formattedProductGroupRules },
            updateSalesTaxId: id,
          },
        })
        trackButtonClick({ name: 'sales-tax-save' })
      }

      trackFormSuccess({ name: 'sales-tax' })

      salesTaxViewPanelVar({
        isOpen: true,
        salesTaxId: Number(salesTaxIdToOpen),
      })

      enqueueSnackbar(
        t(
          isNewSalesTax
            ? 'settings:sales_tax.sales_tax_message.success_add'
            : 'settings:sales_tax.sales_tax_message.success_update',
          { count: 1 },
        ),
      )

      void navigate(generatePath(RootRoute.SettingsSalesTaxes))
    } catch (error) {
      setFormError(error)

      const errorMessage = getErrorMessages(error, {
        format: (errorCode) =>
          t(`settings:sales_tax.error.${errorCode}`, {
            defaultValue: t(
              isNewSalesTax
                ? 'settings:sales_tax.sales_tax_message.failure_add'
                : 'settings:sales_tax.sales_tax_message.failure_update',
            ),
          }),
      }).join(', ')

      trackFormError({
        name: 'sales_tax',
        errorMessage,
      })

      enqueueSnackbar(errorMessage, { variant: 'error' })
    }
  }

  const onError: SubmitErrorHandler<BaseSalesTaxContext> = (_errors) => {
    enqueueSnackbar(t('settings:sales_tax.error.generic'), {
      variant: 'error',
    })
  }

  const formatRulesWithInvalidProductGroups = useCallback(
    (rules: SalesTaxProductGroupRule[]) => {
      return rules.map((rule) => {
        const { groupName, ...rest } = rule
        if (!groupName) {
          return { ...rest, vatid: undefined, groupName: undefined }
        }
        return rule
      })
    },
    [],
  )

  useEffect(() => {
    if (salesTaxData) {
      const { productgrouprules: rules } = salesTaxData.salesTax

      if (rules) {
        formContext.reset({
          ...salesTaxData.salesTax,
          productgrouprules: formatRulesWithInvalidProductGroups(rules),
        })
      } else {
        formContext.reset(salesTaxData.salesTax)
      }
    }
  }, [formatRulesWithInvalidProductGroups, formContext, salesTaxData])

  const productGroupRules = formContext.watch('productgrouprules')

  const containsInvalidProductGroups = useMemo(() => {
    return productGroupRules?.some((rule) => !rule.groupName)
  }, [productGroupRules])

  const isLoading =
    isSalesTaxDataLoading || isAddingSalesTax || isUpdatingSalesTax

  return (
    <>
      <PageHeader
        title={t(
          isNewSalesTax
            ? 'settings:sales_tax.add_sales_tax'
            : 'settings:sales_tax.edit_sales_tax',
        )}
        backTo={generatePath(RootRoute.SettingsSalesTaxes, { siteId: siteId })}
        backText={t('settings:sales_tax.page_title')}
        showBackButton={true}
        isSticky
        rightColumn={
          <Button
            loading={isLoading}
            onClick={formContext.handleSubmit(onSubmit, onError)}
            data-testid="submit-sales-tax-form-button"
            disabled={containsInvalidProductGroups}
          >
            {t(isNewSalesTax ? 'shared:action.add' : 'shared:action.save')}
          </Button>
        }
      />

      <Container data-testid="sales-tax-page">
        <FormProvider {...formContext}>
          <FormRouteLeavingGuard />
          <InformationField />
          <Divider />
          <RulesField />
          <Divider />
          <DeleteSalesTaxField salesTaxId={salesTaxId} />
        </FormProvider>
      </Container>
    </>
  )
}
