import {
  FormHelperText,
  FormLabel,
  IconButton,
  ListItemAvatar,
  ListItemText,
  Stack,
} from '@mui/material'
import { DeleteIcon } from '@sitoo/mui-components'
import {
  FieldValues,
  Path,
  PathValue,
  useController,
  UseControllerProps,
} from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { DataGridImage } from '../../components/data-grid-image'
import { ListItemBorderBox } from '../../components/list-item-border-box'
import {
  ProductResult,
  SearchProductField,
} from '../../components/search-product-field'
import { useTracking } from '../../hooks/tracking'
import { getProductDetails } from '../../utils/format/product'

type Props<
  TFieldValues extends FieldValues,
  TName extends Path<TFieldValues>,
> = UseControllerProps<TFieldValues, TName> & {
  label?: string
  transform?: {
    input?: (value: PathValue<TFieldValues, TName>) => ProductResult
    output?: (values: ProductResult) => PathValue<TFieldValues, TName>
  }
  helperText?: string
}

const isProductResult = (value: unknown): value is ProductResult => {
  return (
    value !== null &&
    typeof value === 'object' &&
    'id' in value &&
    'title' in value
  )
}

export const SingleProductInput = <
  TFieldValues extends FieldValues,
  TName extends Path<TFieldValues>,
>(
  props: Props<TFieldValues, TName>,
) => {
  const { name, control, label, helperText } = props
  const {
    field: { value, onChange },
  } = useController({
    name,
    control,
  })
  const { t } = useTranslation(['shared'])
  const { trackButtonClick } = useTracking()

  const fieldValue = props.transform?.input
    ? props.transform.input(value)
    : value

  const handleProductSelect = (selectedProduct: ProductResult) => {
    const transformedProduct = props.transform?.output
      ? props.transform.output(selectedProduct)
      : selectedProduct
    onChange(transformedProduct)

    trackButtonClick({ name, category: 'single-product-input' })
  }

  const handleRemoveProduct = () => {
    onChange(null)

    trackButtonClick({ name, category: 'single-product-input' })
  }

  const product = isProductResult(fieldValue) ? fieldValue : null

  return (
    <Stack sx={{ width: '100%', gap: 0.5 }}>
      {label && <FormLabel>{label}</FormLabel>}
      {product ? (
        <ListItemBorderBox sx={{ p: 0, '.MuiListItem-root': { px: 2, py: 1 } }}>
          <ListItemAvatar>
            <DataGridImage src={product.productImages?.at(0)?.fileUrl} />
          </ListItemAvatar>

          <ListItemText
            primary={product.title}
            secondary={getProductDetails(product)}
            sx={{ '&.MuiListItemText-multiline': { pb: 0 } }}
          />

          <IconButton
            onClick={handleRemoveProduct}
            aria-label={`${t('shared:action.remove')} ${product.title}`}
          >
            <DeleteIcon />
          </IconButton>
        </ListItemBorderBox>
      ) : (
        <SearchProductField
          onChange={handleProductSelect}
          sx={{ width: '100%' }}
        />
      )}
      <FormHelperText sx={{ m: 0 }}>{helperText}</FormHelperText>
    </Stack>
  )
}
