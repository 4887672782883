import { useMutation, useQuery } from '@apollo/client'
import { useSnackbar } from 'notistack'
import { useCallback, useEffect, useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useCookie } from 'react-use'
import {
  Authenticate,
  AuthenticateDocument,
  GetConfigVarsDocument,
  LoginOptionTypeEnum,
  MeDocument,
  PublicLoginOptionsDocument,
  PublicLoginOptionsQuery,
} from '../../generated/graphql'
import { useServerValidation } from '../../hooks/server-validation'
import { usePageTitle } from '../../hooks/title'
import { useTracking } from '../../hooks/tracking'
import { getErrorMessages } from '../../utils/error-mapping'
import { extractGraphqlErrors } from '../../utils/extract-graphql-errors'
import { AccountId } from './account-id'
import { Footer } from './footer'
import { Mfa } from './mfa'
import { OidcOptions } from './oidc-options'
import { SitooLoginForm } from './sitoo-login-form'
import { ACCOUNT_ID_COOKIE, ACCOUNT_ID_REGEX, getHostName } from './util'
import { MfaConfigure } from '../../components/mfa-configure'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { useAbsolutePath } from '../../hooks/absolute-path'
import { RootRoute } from '..'

export type LoginForm = {
  login: Authenticate
  mfaId: string | undefined
}

type LoginOptions = PublicLoginOptionsQuery['publicLoginOptions']

enum Step {
  Account,
  Login,
  MfaConfirm,
  MfaConfigure,
}

export const LoginPage = () => {
  const { t } = useTranslation(['login', 'shared'])
  const [value, updateCookie] = useCookie(ACCOUNT_ID_COOKIE)
  usePageTitle(t('login:page_title'))

  const accountId = Number(location.host.match(ACCOUNT_ID_REGEX)?.[1])

  const formContext = useForm<LoginForm>({
    defaultValues: {
      login: {
        accountId: accountId || Number(value) || undefined,
      },
    },
  })
  const { handleSubmit, setError, setValue, setFocus, getValues } = formContext

  const setFormError = useServerValidation<LoginForm>('login', setError)

  const [step, setStep] = useState(accountId ? Step.Login : Step.Account)

  const { enqueueSnackbar } = useSnackbar()

  const [searchParams] = useSearchParams()

  const navigate = useNavigate()

  const generatePath = useAbsolutePath()

  const [loginOptions, setLoginOptions] = useState<LoginOptions>([
    { id: '', type: LoginOptionTypeEnum.SitooLogin },
  ])

  const { data: loginOptionsQuery, loading: optionsLoading } = useQuery(
    PublicLoginOptionsDocument,
    {
      variables: { accountId },
      skip: !accountId,
    },
  )

  const hasSitooLogin = loginOptions.some(
    (x) => x.type === LoginOptionTypeEnum.SitooLogin,
  )

  useEffect(() => {
    if (!optionsLoading) {
      const publicLoginOptions = loginOptionsQuery?.publicLoginOptions || []

      if (publicLoginOptions.length) {
        setLoginOptions(publicLoginOptions)
      }
    }
  }, [loginOptionsQuery?.publicLoginOptions, optionsLoading])

  useEffect(() => {
    const forceShowLogin = searchParams.get('show_login') !== null

    if (!hasSitooLogin && forceShowLogin) {
      setLoginOptions((state) => [
        ...state,
        { id: '', type: LoginOptionTypeEnum.SitooLogin },
      ])
    }
  }, [hasSitooLogin, searchParams])

  const { trackFormError, trackFormSuccess } = useTracking()

  const [authenticateMutation, { loading: authLoading }] = useMutation(
    AuthenticateDocument,
    {
      awaitRefetchQueries: true,
      refetchQueries: [{ query: MeDocument }, { query: GetConfigVarsDocument }],
    },
  )

  const loading = authLoading || optionsLoading

  const navigateToAccount = useCallback(
    (_newAccountId?: number) => {
      const newAccountId = _newAccountId ? `${_newAccountId}.` : ''
      let host = location.host

      if (accountId) {
        host = location.host.replace(ACCOUNT_ID_REGEX, newAccountId)
      } else {
        host = `${newAccountId}${location.host.split('.').slice(-2).join('.')}`
      }

      location.assign(
        `${location.protocol}//` + host + location.pathname + location.search,
      )
    },
    [accountId],
  )

  const loginAction = handleSubmit(async (data) => {
    try {
      const { data: authData } = await authenticateMutation({
        variables: { data: data.login },
      })

      if (authData?.authenticate) {
        trackFormSuccess({ name: 'login' })
      }
    } catch (error) {
      const errors = extractGraphqlErrors(error)

      for (const { extensions } of errors) {
        const code = extensions?.['code']
        if (code === 'MFA_REQUIRED' && step !== Step.MfaConfirm) {
          setStep(Step.MfaConfirm)
          continue
        } else if (code === 'MFA_CONFIGURE') {
          setValue('mfaId', String(extensions?.['property']))
          setStep(Step.MfaConfigure)
          continue
        } else if (code === 'ACCOUNT_ID_NOT_FOUND') {
          navigateToAccount()
        } else if (code === 'INVALID_CREDENTIALS' && step === Step.MfaConfirm) {
          setStep(Step.Login)
          setValue('login.mfa', undefined)
        } else if (code === 'INVALID_ACCOUNT_ID') {
          setValue('login.mfa', undefined)
          setStep(Step.Login)
        }

        const errorMessage = getErrorMessages(error, {
          format: (code, property) =>
            t(`login:error.${code}`, {
              property,
              host: getHostName(),
              defaultValue: t('login:error.fallback_error'),
            }),
        })[0]

        trackFormError({ name: 'login', errorMessage })
        setFormError(error)
        enqueueSnackbar(errorMessage, { variant: 'error' })
      }
    }
  })

  useEffect(() => {
    if (step === Step.MfaConfirm) {
      setFocus('login.mfa')
    }
  }, [setFocus, step])

  useEffect(() => {
    if (accountId) {
      updateCookie(String(accountId), {
        domain: getHostName(),
        expires: 30,
      })
    }
  }, [accountId, updateCookie])

  return (
    <>
      <FormProvider {...formContext}>
        <form>
          {step === Step.Account && (
            <AccountId
              navigateToAccount={navigateToAccount}
              loading={loading}
            />
          )}

          {step === Step.MfaConfirm && (
            <Mfa
              loginAction={loginAction}
              loading={loading}
              onBack={() => setStep(Step.Login)}
            />
          )}

          {step === Step.MfaConfigure && (
            <MfaConfigure
              accountId={accountId}
              email={getValues('login.email')}
              mfaId={getValues('mfaId')}
              backTitle={t('shared:action.back')}
              onBack={() => setStep(Step.Login)}
              onSuccess={() => void navigate(generatePath(RootRoute.Home))}
            />
          )}

          {step === Step.Login && (
            <>
              {hasSitooLogin && (
                <SitooLoginForm
                  navigateToAccount={navigateToAccount}
                  loading={loading}
                  loginAction={loginAction}
                />
              )}

              <OidcOptions loginOptions={loginOptions} />
            </>
          )}
        </form>
      </FormProvider>

      {(step === Step.Account || step === Step.Login) && <Footer />}
    </>
  )
}
