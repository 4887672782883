import { ListItemText } from '@mui/material'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { Form } from '../..'
import { SettingsListItem } from '../../../../../components/settings-list-item'
import { SettingsListSection } from '../../../../../components/settings-list-section'
import { SwitchInput } from '../../../../../inputs/switch-input'
import { AutoLogoutTimerField } from './fields/auto-logout-timer-field'
import { DashboardField } from './fields/dashboard-field'
import { LimitPosInventoryFeaturesField } from './fields/limit-pos-inventory-features-field'
import { ProductDisplayModeField } from './fields/product-display-mode-field'
import { ReasonCodeField } from './fields/reason-code-field'
import { SalesAssociateModeField } from './fields/sales-associate-mode-field'
import { StockStatusDisplayModeField } from './fields/stock-status-display-mode-field'
import { useAuthorization } from '../../../../../hooks/authorization'

export const PosOptions = () => {
  const { t } = useTranslation(['pos_profiles'])
  const { control } = useFormContext<Form>()
  const {
    modules: { shipments },
  } = useAuthorization()

  return (
    <SettingsListSection title={t('pos_profiles:pos_options_section.title')}>
      {shipments && (
        <SettingsListItem
          divider
          dense
          secondaryAction={
            <SwitchInput name="shipments_show_costprice" control={control} />
          }
        >
          <ListItemText
            primary={t(
              'pos_profiles:pos_options_section.shipments_show_costprice_label',
            )}
            secondary={t(
              'pos_profiles:pos_options_section.shipments_show_costprice_description',
            )}
          />
        </SettingsListItem>
      )}
      <SettingsListItem
        divider
        dense
        secondaryAction={
          <SwitchInput name="auto_logout_after_commit" control={control} />
        }
      >
        <ListItemText
          primary={t(
            'pos_profiles:pos_options_section.auto_logout_after_commit_label',
          )}
          secondary={t(
            'pos_profiles:pos_options_section.auto_logout_after_commit_description',
          )}
        />
      </SettingsListItem>

      <AutoLogoutTimerField />
      <SalesAssociateModeField />
      <ProductDisplayModeField />
      <StockStatusDisplayModeField />
      <ReasonCodeField />
      <DashboardField />
      <LimitPosInventoryFeaturesField />
    </SettingsListSection>
  )
}
