import { Stack, Typography } from '@mui/material'
import { GridRenderCellParams, GridValidRowModel } from '@mui/x-data-grid-pro'
import { useTranslation, Trans } from 'react-i18next'
import { ReportDefinition } from '../../generated/graphql'

export const ReportJobDetailsRenderer = (
  params: GridRenderCellParams<GridValidRowModel, ReportDefinition>,
) => {
  const { t } = useTranslation(['reports'])
  const { measures, groups } = params.value ?? {}

  return (
    <Stack direction="column" spacing={0} justifyContent="center" height="100%">
      <Typography variant="caption">
        <Trans
          i18nKey="reports:measures"
          t={t}
          components={{ bold: <strong /> }}
          values={{
            val: measures?.map((m) => t(`reports:report_keys.${m}`)),
          }}
        />
      </Typography>
      <Typography variant="caption">
        <Trans
          i18nKey="reports:groups"
          t={t}
          components={{ bold: <strong /> }}
          values={{
            val: groups?.map((g) => t(`reports:report_keys.${g}`)),
          }}
        />
      </Typography>
    </Stack>
  )
}
