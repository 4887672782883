import { useCallback, useState, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useGridApiRef } from '@mui/x-data-grid-pro'
import { SearchProductField } from '../../../../components/search-product-field'
import { useTracking } from '../../../../hooks/tracking'
import { CampaignProducts } from './products'
import { Product, SearchProductsDocument } from '../../../../generated/graphql'
import { usePrevious } from 'react-use'
import { useQuery } from '@apollo/client'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material'

export type CampaignProduct = Pick<
  Product,
  'id' | 'sku' | 'productImages' | 'title'
>

type Props = {
  open: boolean
  onClose(): void
  items?: number[]
  onSubmit: (items: number[]) => void | Promise<void>
  onError?(error: unknown): void
  onSuccess?: () => void
}

export const MAX_CAMPAIGN_PRODUCTS = 300

export const SetProductsDialog = (props: Props) => {
  const { t } = useTranslation(['shared', 'campaigns'])
  const dialogName = 'set-campaign-products'
  const [dialogState] = useState<'items' | 'import'>('items')

  const {
    trackButtonClickEvent,
    trackDialogClose,
    trackDialogOpen,
    trackFormSuccess,
  } = useTracking()

  const [submitLoading, setSubmitLoading] = useState(false)

  const { getValues, setValue } = useForm<{ products: number[] }>({
    defaultValues: { products: props.items },
  })

  const prevOpen = usePrevious(props.open)

  const [products, setProducts] = useState<CampaignProduct[]>([])

  const productIds = props.items?.slice(0, MAX_CAMPAIGN_PRODUCTS) || []

  const { loading: fetchLoading } = useQuery(SearchProductsDocument, {
    variables: {
      productIds,
      num: productIds.length,
    },
    fetchPolicy: 'cache-and-network',
    skip: !props.open,
    onCompleted: ({ searchProducts }) => setProducts(searchProducts || []),
  })

  const loading = fetchLoading || submitLoading

  const apiRef = useGridApiRef()

  const onSubmit = useCallback(async () => {
    try {
      setSubmitLoading(true)

      await props.onSubmit?.(getValues('products'))
      trackFormSuccess({ name: `${dialogName}-dialog` })
      props.onSuccess?.()
    } catch (error) {
      props.onError?.(error)
    } finally {
      setSubmitLoading(false)
    }
  }, [getValues, props, trackFormSuccess])

  useEffect(() => {
    if (props.open === prevOpen) return

    if (props.open && props.items) {
      setValue('products', props.items)

      trackDialogOpen({ name: dialogName })
    } else {
      setProducts([])

      trackDialogClose({ name: dialogName })
    }
  }, [
    prevOpen,
    props.open,
    props.items,
    trackDialogClose,
    trackDialogOpen,
    setValue,
  ])

  return (
    <Dialog open={props.open} onClose={props.onClose} maxWidth="sm" fullWidth>
      {dialogState === 'items' ? (
        <>
          <DialogTitle
            type="extended"
            sx={{ pb: 2, justifyContent: 'space-between' }}
          >
            {t('campaigns:campaign_form.add_items')}
            {/* <IconButton onClick={() => setDialogState('import')}> */}
            {/*   <ImportIcon /> */}
            {/* </IconButton> */}
          </DialogTitle>
          <DialogContent sx={{ p: 0 }}>
            <SearchProductField
              sx={{ mb: 2, px: 3 }}
              data-testid="product-picker-search"
              onChange={(product) => {
                const index = getValues('products')?.findIndex(
                  (id) => id === product.id,
                )

                if (index !== -1) {
                  apiRef.current.scrollToIndexes({ rowIndex: index })
                  return
                }

                setValue('products', [product.id, ...getValues('products')])

                setProducts((data) => [
                  {
                    id: product.id,
                    sku: product.sku,
                    title: product.title,
                    variant: product.variant,
                    productImages: product.productImages,
                  },
                  ...data,
                ])

                apiRef.current.scrollToIndexes({ rowIndex: 0 })
              }}
            />
            <CampaignProducts
              apiRef={apiRef}
              items={products}
              rowCount={props.items?.length || 0}
              loading={loading}
              removeItem={(deletedId: number) => {
                setValue(
                  'products',
                  getValues('products').filter((id) => id !== deletedId),
                )

                setProducts((data) =>
                  data.filter((product) => product.id !== deletedId),
                )
              }}
            />
          </DialogContent>
          <DialogActions>
            <Button
              onClick={trackButtonClickEvent(
                { name: `${dialogName}-dialog-cancel` },
                props.onClose,
              )}
              color="secondary"
              size="small"
              type="button"
              disabled={loading}
            >
              {t('shared:action.cancel')}
            </Button>
            <Button
              type="submit"
              size="small"
              onClick={trackButtonClickEvent(
                { name: `${dialogName}-dialog-save` },
                onSubmit,
              )}
              data-testid="dialog-submit"
              loading={loading}
            >
              {t('shared:action.set')}
            </Button>
          </DialogActions>
        </>
      ) : null}
    </Dialog>
  )
}
