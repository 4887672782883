import { Button, Chip, Container, Grid } from '@mui/material'
import { MouseEventHandler, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import { RootRoute } from '../..'
import { FilterProvider } from '../../../components/data-grid/context'
import { PageHeader } from '../../../components/page-header'
import { WarehouseBatchTransaction } from '../../../generated/graphql'
import { useAbsolutePath } from '../../../hooks/absolute-path'
import { useAuthorization } from '../../../hooks/authorization'
import { usePageTitle } from '../../../hooks/title'
import { useWarehouseBatch } from '../use-warehouse-batch'
import { StockTakingFilterPanel } from './filter-panel'
import { InventoryProductList } from './list'
import { FinishStocktakingDialog } from './finish-stocktaking-dialog'
import { StocktakingContextMenu } from './stocktaking-context-menu'
import { useTracking } from '../../../hooks/tracking'
import { MoreButton } from '../../../components/more-button'

type Params = {
  warehouseId: string
  warehouseBatchId: string
}

export const StocktakingPage = () => {
  const { t } = useTranslation([
    'shared',
    'stocktaking',
    'products',
    'settings',
  ])
  usePageTitle(t('stocktaking:page_title'))

  const { trackButtonClick } = useTracking()

  const params = useParams<Params>()
  const warehouseId = Number(params.warehouseId)
  const warehouseBatchId = Number(params.warehouseBatchId)

  const navigate = useNavigate()
  const generatePath = useAbsolutePath()

  const [openFinishDialog, setOpenFinishDialog] = useState(false)
  const [isFilterPanelOpen, setFilterPanelOpen] = useState(true)

  const { writeStocktaking } = useAuthorization().modules

  const { data: warehouseBatchData } = useWarehouseBatch({
    warehouseId,
    warehouseBatchId,
    transactionType: WarehouseBatchTransaction.Stocktaking,
    listRoute: RootRoute.Stocktakings,
  })

  const storeName = warehouseBatchData?.warehouseBatch?.warehouse?.store?.name
  const warehouseName = warehouseBatchData?.warehouseBatch?.warehouse?.name

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)
  const isOpen = !!anchorEl

  const handleClick: MouseEventHandler<HTMLButtonElement> = (event) => {
    setAnchorEl(isOpen ? null : event.currentTarget)
  }

  const closeMenu = () => setAnchorEl(null)

  const openFilterPanel = () => {
    setFilterPanelOpen(true)
    trackButtonClick({ name: 'stocktaking-filer-panel-open' })
  }

  const closeFilterPanel = () => {
    setFilterPanelOpen(false)
    trackButtonClick({ name: 'stocktaking-filer-panel-close' })
  }

  const toggleFilterPanel = () => {
    return isFilterPanelOpen ? closeFilterPanel() : openFilterPanel()
  }

  return (
    <FilterProvider>
      <Grid container flexWrap="nowrap">
        <Grid size="grow">
          <PageHeader
            title={t('stocktaking:page_title')}
            rightColumn={
              <>
                {writeStocktaking && (
                  <>
                    <MoreButton onClick={handleClick} />

                    <Button
                      variant="contained"
                      onClick={() => setOpenFinishDialog(true)}
                      data-testid="finish-stocktaking"
                    >
                      {t('shared:action.finish')}
                    </Button>

                    <FinishStocktakingDialog
                      warehouseId={warehouseId}
                      warehouseBatchId={warehouseBatchId}
                      open={openFinishDialog}
                      onClose={() => setOpenFinishDialog(false)}
                      onSuccess={() => {
                        void navigate(generatePath(RootRoute.Stocktakings))
                        setOpenFinishDialog(false)
                      }}
                    />

                    <StocktakingContextMenu
                      onClose={closeMenu}
                      anchorEl={anchorEl}
                      isOpen={isOpen}
                      warehouseId={warehouseId}
                      warehouseBatchId={warehouseBatchId}
                    />
                  </>
                )}
              </>
            }
            showBackButton
            isFlexible
            backText={t('shared:menu.stocktaking')}
            backTo={generatePath(RootRoute.Stocktakings)}
            subTitle={
              <>
                {storeName && (
                  <Chip label={storeName} color="gray" size="small" />
                )}
                {warehouseName && (
                  <Chip label={warehouseName} color="gray" size="small" />
                )}
              </>
            }
          />

          <Container maxWidth={false}>
            <InventoryProductList
              onShowFilter={toggleFilterPanel}
              warehouseId={warehouseId}
              warehouseBatchId={warehouseBatchId}
            />
          </Container>
        </Grid>

        <Grid>
          <StockTakingFilterPanel
            isOpen={isFilterPanelOpen}
            onClose={closeFilterPanel}
          />
        </Grid>
      </Grid>
    </FilterProvider>
  )
}
