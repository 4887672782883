import { makeVar, useReactiveVar } from '@apollo/client'
import { Button, Container, Grid } from '@mui/material'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { FilterProvider } from '../../components/data-grid/context'
import { PageHeader } from '../../components/page-header'
import { usePageTitle } from '../../hooks/title'
import { useTracking } from '../../hooks/tracking'
import { CampaignViewPanel } from './campaign-view-panel'
import { CampaignsFilterViewPanel } from './campaigns-filter-view-panel'
import { CampaignsList } from './campaigns-list'

export type CampaignViewPanelState = {
  campaignId?: number
  siteId?: number
  isOpen: boolean
}

export const reactiveCampaignViewPanelVar = makeVar<CampaignViewPanelState>({
  isOpen: false,
  campaignId: undefined,
})

const campaignsFilterViewPanelVar = makeVar({
  isOpen: false,
})

export const CampaignsPage = () => {
  const { t } = useTranslation(['campaigns'])
  usePageTitle(t('campaigns:page_title'))

  const { trackButtonClickEvent, trackButtonClick } = useTracking()
  const filterViewPanelState = useReactiveVar(campaignsFilterViewPanelVar)
  const [isAddCampaignDialogOpen, setIsAddCampaignDialogOpen] =
    useState<boolean>(false)

  const addCampaignsClick = () => {
    setIsAddCampaignDialogOpen(true)
  }

  const closeAddCampaignsDialog = () => {
    setIsAddCampaignDialogOpen(false)
  }

  const viewPanelState = useReactiveVar(reactiveCampaignViewPanelVar)
  const detailCampaign = (campaignId: number, siteId?: number) => {
    if (filterViewPanelState.isOpen) {
      closeFilterViewPanel()
    }

    trackButtonClick({ name: 'campaigns-detail-campaign', campaignId })
    reactiveCampaignViewPanelVar({ isOpen: true, campaignId, siteId })
  }

  const closeDetailCampaign = () => {
    trackButtonClick({ name: 'campaigns-close-detail-campaign' })
    reactiveCampaignViewPanelVar({ isOpen: false, campaignId: undefined })
  }

  const closeFilterViewPanel = () => {
    trackButtonClick({ name: 'campaigns-filter-view-panel-close' })
    campaignsFilterViewPanelVar({ isOpen: false })
  }

  const openFilterViewPanel = () => {
    trackButtonClick({ name: 'campaigns-filter-view-panel-open' })
    campaignsFilterViewPanelVar({ isOpen: true })
  }

  const toggleFilterViewPanel = () => {
    if (filterViewPanelState.isOpen) {
      closeFilterViewPanel()
    } else {
      openFilterViewPanel()
    }
  }

  return (
    <FilterProvider>
      <Grid container flexWrap="nowrap">
        <Grid size="grow">
          <PageHeader
            title={t('campaigns:title')}
            isFlexible
            rightColumn={
              <Button
                onClick={trackButtonClickEvent(
                  { name: 'campaigns-add-campaign' },
                  addCampaignsClick,
                )}
                data-testid="add-campaigns-button"
              >
                {t('campaigns:add_campaign')}
              </Button>
            }
          />
          <Container maxWidth={false}>
            <CampaignsList
              isAddCampaignDialogOpen={isAddCampaignDialogOpen}
              onAddCampaignDialogClose={closeAddCampaignsDialog}
              showAddCampaign={addCampaignsClick}
              onCloseDetailCampaign={closeDetailCampaign}
              onDetailCampaign={detailCampaign}
              currentDetailedCampaignId={viewPanelState.campaignId}
              onShowFilter={toggleFilterViewPanel}
            />
          </Container>
        </Grid>
        <Grid>
          <CampaignViewPanel
            onClose={closeDetailCampaign}
            open={viewPanelState.isOpen}
            selectedCampaign={viewPanelState}
          />
          <CampaignsFilterViewPanel
            isOpen={filterViewPanelState.isOpen}
            onClose={closeFilterViewPanel}
          />
        </Grid>
      </Grid>
    </FilterProvider>
  )
}
