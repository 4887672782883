import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  IconButton,
  Link,
  List,
  ListItemText,
} from '@mui/material'
import { LinkIcon, SectionHeader } from '@sitoo/mui-components'
import { useTranslation } from 'react-i18next'
import { ListItemSkeleton } from '../../../components/list-item-skeleton'
import { GetShipmentV2Query } from '../../../generated/graphql'

type CarrierProps = {
  shipment?: GetShipmentV2Query['getShipmentV2']
  isLoading?: boolean
}

export const Carrier = ({ shipment, isLoading }: CarrierProps) => {
  const { t } = useTranslation(['shipments_v2'])

  return (
    <Accordion
      defaultExpanded
      className="MuiAccordionRoot"
      data-testid="view-panel-carrier-section"
    >
      <AccordionSummary aria-controls="carrier">
        <SectionHeader sx={{ p: 0 }}>{t('view_panel.carrier')}</SectionHeader>
      </AccordionSummary>
      <AccordionDetails sx={{ p: 0 }}>
        <List>
          <ListItemSkeleton
            hide={!isLoading && !shipment?.carrier_name}
            secondaryAction={
              <ListItemText
                sx={{
                  textAlign: 'end',
                }}
                slotProps={{
                  secondary: {
                    sx: {
                      whiteSpace: 'pre-line',
                    },
                  },
                }}
              >
                {shipment?.carrier_name}
              </ListItemText>
            }
            isLoading={isLoading}
            childrenSkeleton
            divider
          >
            <ListItemText
              slotProps={{
                secondary: {
                  sx: {
                    whiteSpace: 'pre-line',
                  },
                },
              }}
            >
              {t('view_panel.carrier')}
            </ListItemText>
          </ListItemSkeleton>

          <ListItemSkeleton
            hide={!isLoading && !shipment?.carrier_reference}
            secondaryAction={
              <ListItemText
                sx={{
                  textAlign: 'end',
                }}
                slotProps={{
                  secondary: {
                    sx: {
                      whiteSpace: 'pre-line',
                    },
                  },
                }}
              >
                {shipment?.carrier_reference}
              </ListItemText>
            }
            isLoading={isLoading}
            childrenSkeleton
            divider
          >
            <ListItemText
              slotProps={{
                secondary: {
                  sx: {
                    whiteSpace: 'pre-line',
                  },
                },
              }}
            >
              {t('view_panel.carrier_reference')}
            </ListItemText>
          </ListItemSkeleton>

          <ListItemSkeleton
            hide={!isLoading && !shipment?.barcode}
            secondaryAction={
              <ListItemText
                sx={{
                  textAlign: 'end',
                }}
                slotProps={{
                  secondary: {
                    sx: {
                      whiteSpace: 'pre-line',
                    },
                  },
                }}
              >
                {shipment?.barcode}
              </ListItemText>
            }
            isLoading={isLoading}
            childrenSkeleton
            divider
          >
            <ListItemText
              slotProps={{
                secondary: {
                  sx: {
                    whiteSpace: 'pre-line',
                  },
                },
              }}
            >
              {t('view_panel.barcode')}
            </ListItemText>
          </ListItemSkeleton>

          <ListItemSkeleton
            hide={!isLoading && !shipment?.carrier_trackingurl}
            isLoading={isLoading}
            childrenSkeleton
            divider
            secondaryAction={
              <IconButton
                href={shipment?.carrier_trackingurl || ''}
                target="_blank"
              >
                <LinkIcon />
              </IconButton>
            }
          >
            <ListItemText
              slotProps={{
                secondary: {
                  sx: {
                    whiteSpace: 'pre-line',
                  },
                },
              }}
              secondary={
                <Link
                  href={shipment?.carrier_trackingurl || ''}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {shipment?.carrier_trackingurl}
                </Link>
              }
            >
              {t('track_shipment')}
            </ListItemText>
          </ListItemSkeleton>

          <ListItemSkeleton
            hide={!isLoading && !shipment?.externalid}
            secondaryAction={
              <ListItemText
                sx={{
                  textAlign: 'end',
                }}
                slotProps={{
                  secondary: {
                    sx: {
                      whiteSpace: 'pre-line',
                    },
                  },
                }}
              >
                {shipment?.externalid}
              </ListItemText>
            }
            isLoading={isLoading}
            childrenSkeleton
            divider
          >
            <ListItemText
              slotProps={{
                secondary: {
                  sx: {
                    whiteSpace: 'pre-line',
                  },
                },
              }}
            >
              {t('view_panel.external_id')}
            </ListItemText>
          </ListItemSkeleton>

          <ListItemSkeleton
            hide={!isLoading && !shipment?.comment}
            isLoading={isLoading}
            childrenSkeleton
            divider
          >
            <ListItemText
              slotProps={{
                secondary: {
                  sx: {
                    whiteSpace: 'pre-line',
                  },
                },
              }}
              secondary={shipment?.comment}
            >
              {t('view_panel.comment')}
            </ListItemText>
          </ListItemSkeleton>
        </List>
      </AccordionDetails>
    </Accordion>
  )
}
