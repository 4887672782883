import {
  Alert,
  AlertTitle,
  Button,
  Container,
  List,
  ListItem,
  Paper,
} from '@mui/material'
import { DatePickerInput } from '../../../../inputs/date-picker-input'
import { useTranslation } from 'react-i18next'
import { useForm } from 'react-hook-form'
import { Stack } from '@mui/system'
import { CashRegisterInput } from '../../../../inputs/cash-register-input'
import {
  CashRegisterCtu,
  GenerateSaftReportDocument,
  GetPluginsDocument,
  PluginCtuNorway,
  PluginId,
} from '../../../../generated/graphql'
import { useMutation, useQuery } from '@apollo/client'
import { useDayJs } from '../../../../hooks/day-js'
import { validateCtuSettings } from './settings'
import { Dayjs } from 'dayjs'
import { getErrorMessages } from '../../../../utils/error-mapping'
import { useSnackbar } from 'notistack'
import { RootRoute } from '../../..'
import { Link } from 'react-router-dom'
import { useAbsolutePath } from '../../../../hooks/absolute-path'

type Form = {
  registerId: string
  date: string | undefined
}

export const getFiscalYear = (
  dateFrom: Dayjs,
  dateTo: Dayjs,
  dateFiscalFirst: Dayjs,
  dateFiscalSecond: Dayjs,
) => {
  let fiscalYearStart = dateFrom

  if (dateFrom.isBefore(dateFiscalSecond)) {
    // Set to same year as the first fiscal year
    fiscalYearStart = dateFiscalFirst
  } else {
    const fiscalYearCheck = dateFiscalSecond.year(dateFrom.year())

    if (dateFrom.isBefore(fiscalYearCheck)) {
      fiscalYearStart = dateFrom.subtract(1, 'year')
    }
  }

  const startYear = fiscalYearStart.year()
  const endYear = dateTo.year()

  return startYear === endYear ? `${startYear}` : `${startYear}-${endYear}`
}

export const CtuNorwayReport = () => {
  const { t } = useTranslation(['shared', 'apps'])
  const { control, handleSubmit } = useForm<Form>()
  const formId = 'ctu-norway-report'
  const generatePath = useAbsolutePath()
  const dayJs = useDayJs()

  const { data: pluginData, loading } = useQuery(GetPluginsDocument, {
    fetchPolicy: 'cache-and-network',
    variables: { pluginId: PluginId.Ctunorway },
  })

  const plugin = pluginData?.plugin as PluginCtuNorway

  const isSettingsValid = loading || validateCtuSettings(plugin)

  const [generateReport] = useMutation(GenerateSaftReportDocument)
  const { enqueueSnackbar } = useSnackbar()

  const validateDate = (
    date: Dayjs,
    dateFiscalFirst: Dayjs,
    dateFiscalSecond: Dayjs,
  ) => {
    if (date.isBefore(dateFiscalFirst)) {
      enqueueSnackbar({
        message: t('apps:ctu_norway.error_tax_year_1'),
        variant: 'error',
      })
      return false
    }

    if (dateFiscalFirst.isAfter(dateFiscalSecond)) {
      enqueueSnackbar({
        message: t('apps:ctu_norway.error_tax_year_2'),
        variant: 'error',
      })
      return false
    }

    return true
  }

  const onSubmit = async (form: Form) => {
    try {
      const companyName = plugin.companyName as NonNullable<string>
      const companyId = plugin.companyId as NonNullable<string>
      const taxRegistrationCountry =
        plugin.taxRegistrationCountry as NonNullable<string>
      const currencyCode = plugin.currencyCode as NonNullable<string>
      const dateFrom = dayJs(form.date).startOf('day')
      const dateTo = dayJs().endOf('day')
      const dateFiscalFirst = dayJs(plugin.dateFiscalFirst)
      const dateFiscalSecond = dayJs(plugin.dateFiscalSecond)

      const fiscalYear = getFiscalYear(
        dateFrom,
        dateTo,
        dateFiscalFirst,
        dateFiscalSecond,
      )

      const isValid = validateDate(dateFrom, dateFiscalFirst, dateFiscalSecond)

      if (!isValid) return

      const { data } = await generateReport({
        variables: {
          dateFrom: dateFrom.toJSON(),
          dateTo: dateTo.toJSON(),
          fiscalYear,
          companyName,
          companyId,
          taxRegistrationCountry,
          currencyCode,
          registerIds: [form.registerId],
        },
      })

      window.open(data?.generateSaftReport, '_blank')
    } catch (error) {
      const errorMessages = getErrorMessages(error)
      enqueueSnackbar({ message: errorMessages[0], variant: 'error' })
    }
  }

  return (
    <Container>
      <Paper elevation={0} sx={{ py: 2 }}>
        <form id={formId} noValidate onSubmit={handleSubmit(onSubmit)}>
          {!isSettingsValid && (
            <Alert variant="outlined" severity="error" sx={{ mx: 2, mb: 2 }}>
              <AlertTitle>{t('apps:ctu_norway.report_alert_title')}</AlertTitle>
              {t('apps:ctu_norway.report_alert_text')}

              <Button
                color="secondary"
                size="small"
                to={generatePath(RootRoute.SettingsCtuNorwaySettings)}
                component={Link}
              >
                {t('apps:ctu_norway.report_alert_action')}
              </Button>
            </Alert>
          )}
          <List sx={{ '.MuiListItem-root': { pb: 2 } }}>
            <ListItem>
              <CashRegisterInput
                name="registerId"
                control={control}
                required
                variables={{ ctu: CashRegisterCtu.SafT }}
              />
            </ListItem>

            <ListItem>
              <DatePickerInput
                control={control}
                name="date"
                label={t('apps:ctu_norway.date_report_label')}
                helperText={t('apps:ctu_norway.date_report_helper')}
                disableFuture
                required
                transform={{
                  output: (value) => value?.endOf('day').toJSON(),
                }}
              />
            </ListItem>
          </List>

          <Stack alignItems="end" sx={{ mx: 2 }}>
            <Button type="submit" form={formId} disabled={!isSettingsValid}>
              {t('apps:ctu_norway.generate_report_button')}
            </Button>
          </Stack>
        </form>
      </Paper>
    </Container>
  )
}
