import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { Form } from '../../..'
import { SettingsListItem } from '../../../../../../components/settings-list-item'
import { PosProfilePrintReceiptEnum } from '../../../../../../generated/graphql'
import { SelectInput } from '../../../../../../inputs/select-input'

export const ReceiptPrintingModeField = () => {
  const { t } = useTranslation(['pos_profiles'])
  const { control } = useFormContext<Form>()

  const receiptPrintingModeOptions = [
    ...Object.values(PosProfilePrintReceiptEnum).map((mode) => ({
      value: mode,
      name: t(
        `pos_profiles:receipt_section.receipt_printing_mode_options.${mode}`,
      ),
    })),
  ]
  return (
    <SettingsListItem>
      <SelectInput
        label={t('pos_profiles:receipt_section.receipt_printing_mode_label')}
        helperText={t(
          'pos_profiles:receipt_section.receipt_printing_mode_description',
        )}
        name={'printreceipt'}
        control={control}
        options={receiptPrintingModeOptions}
        slotProps={{ select: { displayEmpty: true } }}
      />
    </SettingsListItem>
  )
}
