import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'

const useGetTimerString = () => {
  const { t } = useTranslation(['pos_profiles'])

  const getTimerString = useCallback(
    (value: number): string => {
      if (!value) return '-'
      const minutes = Math.floor(value / 60)
      const seconds = value % 60

      if (minutes === 0 && seconds > 0)
        return t('pos_profiles:grid.seconds', { count: seconds })
      if (seconds === 0 && minutes > 0)
        return t('pos_profiles:grid.minutes', { count: minutes })
      if (seconds > 0 && minutes > 0)
        return `${t('pos_profiles:grid.minutes', { count: minutes })} 
          ${t('pos_profiles:grid.seconds', { count: seconds })}`
      return String(value)
    },
    [t],
  )

  return getTimerString
}

export default useGetTimerString
