import { Divider, Grid, ListItem, ListItemText, TextField } from '@mui/material'
import { FormFieldset, FormLabelField } from '@sitoo/mui-components'
import { useFormContext } from 'react-hook-form'
import { translateCampaignTypeTitle } from '../../../campaigns/i18n'
import { PricelistTagsField } from '../pricelist-tags'
import { DiscountOptionsField } from '../discount-options'
import { useTranslation } from 'react-i18next'
import { useMoney } from '../../../../hooks/money'
import { CampaignFormContext } from '../..'
import { getFormRules } from './get-form-rules'
import { useQuery } from '@apollo/client'
import { GetConfigVarsDocument } from '../../../../generated/graphql'
import { CAMPAIGN_TYPE_PRODUCT } from '../../../../utils/campaigns'
import { ProductOptions } from './product-options'
import { SwitchInput } from '../../../../inputs/switch-input'

export const CampaignRulesField = () => {
  const { register, getValues, formState, setValue, control } =
    useFormContext<CampaignFormContext>()
  const type = getValues('campaign.vouchertype')
  const offerTitle = translateCampaignTypeTitle(type)
  const rules = getFormRules(type)
  const { t } = useTranslation(['campaigns'])
  const { formatCurrency, formatStringCurrencyToNumber } = useMoney()

  const { data } = useQuery(GetConfigVarsDocument)
  const showDiscountPriceToggle =
    !!data?.configVars.campaignPriceListDiscount &&
    CAMPAIGN_TYPE_PRODUCT.types.includes(getValues('campaign.vouchertype'))

  if (!type) return null

  return (
    <>
      <FormFieldset
        label={t('campaigns:rules')}
        sx={{
          '.MuiFormFieldset-Paper': {
            p: 0,
          },
        }}
      >
        <FormLabelField
          divider
          label={offerTitle}
          sx={{ mb: 2, px: 2, cursor: 'default' }}
        />

        <Grid container>
          {rules?.value_x && (
            <Grid size={{ xs: 12 }}>
              <TextField
                label={rules.value_x}
                error={!!formState.errors.campaign?.value_x}
                helperText={formState.errors.campaign?.value_x?.message}
                sx={{ mb: 2, px: 2 }}
                {...register('campaign.value_x', {
                  validate: (v) => {
                    return Number.isNaN(v)
                      ? t('shared:validation.field_invalid', {
                          field: rules.value_x,
                        })
                      : true
                  },
                  valueAsNumber: true,
                })}
                data-testid="campaign-value-x-field"
              />
            </Grid>
          )}

          {rules?.value_y && (
            <Grid size={{ xs: 12 }}>
              <TextField
                label={rules.value_y}
                error={!!formState.errors.campaign?.value_y}
                helperText={formState.errors.campaign?.value_y?.message}
                sx={{ mb: 2, px: 2 }}
                {...register('campaign.value_y', {
                  validate: (v) => {
                    return Number.isNaN(v)
                      ? t('shared:validation.field_invalid', {
                          field: rules.value_y,
                        })
                      : true
                  },
                  valueAsNumber: true,
                })}
                data-testid="campaign-value-y-field"
              />
            </Grid>
          )}

          {rules?.value_z && (
            <Grid size={{ xs: 12 }}>
              <TextField
                label={rules.value_z}
                error={!!formState.errors.campaign?.value_z}
                helperText={formState.errors.campaign?.value_z?.message}
                sx={{ mb: 2, px: 2 }}
                {...register('campaign.value_z', {
                  validate: (v) => {
                    return Number.isNaN(v)
                      ? t('shared:validation.field_invalid', {
                          field: rules.value_z,
                        })
                      : true
                  },
                  valueAsNumber: true,
                })}
                data-testid="campaign-value-z-field"
              />
            </Grid>
          )}

          {rules?.money_m && (
            <Grid size={{ xs: 12 }}>
              <TextField
                label={rules.money_m}
                placeholder={formatCurrency(0, {
                  hideCurrency: true,
                  useGrouping: false,
                })}
                error={!!formState.errors.campaign?.money_m}
                helperText={formState.errors.campaign?.money_m?.message}
                sx={{ mb: 2, px: 2 }}
                data-testid="campaign-money-m-field"
                {...register('campaign.money_m', {
                  validate: (v) =>
                    v && Number.isNaN(formatStringCurrencyToNumber(v))
                      ? t('shared:validation.field_invalid', {
                          field: rules.money_m,
                        })
                      : true,
                })}
                onBlur={() => {
                  const money_m = getValues('campaign.money_m')
                  if (!money_m) return

                  const money = formatStringCurrencyToNumber(money_m)

                  setValue(
                    'campaign.money_m',
                    Number.isNaN(money)
                      ? '0'
                      : formatCurrency(money, {
                          hideCurrency: true,
                          useGrouping: false,
                        }),
                  )
                }}
              />
            </Grid>
          )}

          {rules?.money_n && (
            <Grid size={{ xs: 12 }}>
              <TextField
                label={rules.money_n}
                placeholder={formatCurrency(0, {
                  hideCurrency: true,
                  useGrouping: false,
                })}
                error={!!formState.errors.campaign?.money_n}
                helperText={formState.errors.campaign?.money_n?.message}
                sx={{ mb: 2, px: 2 }}
                data-testid="campaign-money-n-field"
                {...register('campaign.money_n', {
                  validate: (v) =>
                    v && Number.isNaN(formatStringCurrencyToNumber(v))
                      ? t('shared:validation.field_invalid', {
                          field: rules.money_n,
                        })
                      : true,
                })}
                onBlur={() => {
                  const money_n = getValues('campaign.money_n')
                  if (!money_n) return

                  const money = formatStringCurrencyToNumber(money_n)

                  setValue(
                    'campaign.money_n',
                    Number.isNaN(money)
                      ? '0'
                      : formatCurrency(money, {
                          hideCurrency: true,
                          useGrouping: false,
                        }),
                  )
                }}
              />
            </Grid>
          )}

          {rules?.vouchername1 && (
            <Grid size={{ xs: 12, md: 6 }}>
              <TextField
                label={rules.vouchername1}
                placeholder={t('campaigns:campaign_form.x_for_y', {
                  x: 3,
                  y: 2,
                })}
                error={!!formState.errors.campaign?.vouchername1}
                helperText={formState.errors.campaign?.vouchername1?.message}
                sx={{ mb: 2, px: 2 }}
                {...register('campaign.vouchername1')}
                data-testid="campaign-vouchername1-field"
              />
            </Grid>
          )}

          {rules?.vouchercode1 && (
            <Grid size={{ xs: 12, md: 6 }}>
              <TextField
                label={rules.vouchercode1}
                error={!!formState.errors.campaign?.vouchercode1}
                helperText={formState.errors.campaign?.vouchercode1?.message}
                sx={{ mb: 2, px: 2 }}
                {...register('campaign.vouchercode1')}
                data-testid="campaign-vouchercode1-field"
              />
            </Grid>
          )}

          {rules?.vouchername2 && (
            <Grid size={{ xs: 12, md: 6 }}>
              <TextField
                label={rules.vouchername2}
                placeholder={t('campaigns:campaign_form.x_for_y', {
                  x: 2,
                  y: '1.5',
                })}
                error={!!formState.errors.campaign?.vouchername2}
                helperText={formState.errors.campaign?.vouchername2?.message}
                sx={{ mb: 2, px: 2 }}
                {...register('campaign.vouchername2')}
                data-testid="campaign-vouchername2-field"
              />
            </Grid>
          )}

          {rules?.vouchercode2 && (
            <Grid size={{ xs: 12, md: 6 }}>
              <TextField
                label={rules.vouchercode2}
                error={!!formState.errors.campaign?.vouchercode2}
                helperText={formState.errors.campaign?.vouchercode2?.message}
                sx={{ mb: 2, px: 2 }}
                {...register('campaign.vouchercode2')}
                data-testid="campaign-vouchercode2-field"
              />
            </Grid>
          )}

          {rules?.maxuses && (
            <Grid size={{ xs: 12 }}>
              <TextField
                label={rules.maxuses}
                error={!!formState.errors.campaign?.maxuses}
                helperText={
                  formState.errors.campaign?.maxuses?.message ||
                  t('campaigns:campaign_form.max_use_rule_helper_text')
                }
                sx={{ mb: 2, px: 2 }}
                {...register('campaign.maxuses', {
                  validate: (v) => {
                    return v && Number.isNaN(v)
                      ? t('shared:validation.field_invalid', {
                          field: rules.maxuses,
                        })
                      : true
                  },
                  valueAsNumber: true,
                })}
                data-testid="campaign-maxuses-field"
              />
            </Grid>
          )}

          {rules?.discountoptions && (
            <Grid size={{ xs: 12 }}>
              <DiscountOptionsField />
            </Grid>
          )}

          {rules?.pricelisttags && (
            <Grid size={{ xs: 12 }}>
              <PricelistTagsField label={rules.pricelisttags} />
            </Grid>
          )}

          {showDiscountPriceToggle && (
            <>
              <Grid size={{ xs: 12 }}>
                <Divider />
              </Grid>
              <Grid size={{ xs: 12 }}>
                <ListItem
                  component="div"
                  secondaryAction={
                    <SwitchInput
                      name="campaign.use_discounted_price"
                      control={control}
                      data-testid="campaign-use-discounted-price-field"
                    />
                  }
                >
                  <ListItemText
                    secondary={t(
                      'campaigns:campaign_form.discounted_price_description',
                    )}
                  >
                    {t('campaigns:campaign_form.discounted_price_label')}
                  </ListItemText>
                </ListItem>
              </Grid>
            </>
          )}
        </Grid>
      </FormFieldset>

      <ProductOptions />
    </>
  )
}
