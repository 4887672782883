import { useEffect, useMemo } from 'react'
import { useFormContext, useWatch } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { Form } from '../../..'
import { SettingsListItem } from '../../../../../../components/settings-list-item'
import { ThemesQuery } from '../../../../../../generated/graphql'
import { Option, SelectInput } from '../../../../../../inputs/select-input'

type Props = {
  themes: ThemesQuery['themes']
  loading?: boolean
}

export const ThemeField = ({ themes, loading }: Props) => {
  const { t } = useTranslation(['pos_profiles'])
  const { control, setValue } = useFormContext<Form>()

  const themeOptions = useMemo<Option[]>(() => {
    return (
      themes
        ?.map((theme) => ({
          name: `${theme.name} (${theme.id})`,
          value: theme.id,
        }))
        .sort((a, b) => a.name.localeCompare(b.name)) || []
    )
  }, [themes])

  const posThemeId = useWatch({
    control: control,
    name: 'pos_theme_id',
  })

  useEffect(() => {
    if (!posThemeId) {
      const firstThemeOption = themes?.find(
        (theme) => theme.id === themeOptions[0]?.value,
      )
      setValue('pos_theme_id', firstThemeOption?.id)
    }
  }, [themes, posThemeId, setValue, themeOptions])

  return (
    <SettingsListItem sx={{ mt: 2 }} loading={loading}>
      <SelectInput
        label={t('pos_profiles:pos_theme_section.theme_label')}
        helperText={t('pos_profiles:pos_theme_section.theme_description')}
        name={'pos_theme_id'}
        control={control}
        options={themeOptions}
      />
    </SettingsListItem>
  )
}
