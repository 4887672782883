import { DateRangePickerInput } from '../report-inputs/date-range-picker-input'

import { ReportSortInput } from '../report-inputs/report-sort-input'

export const CreditNoteReportFields = () => {
  return (
    <>
      <ReportSortInput />
      <DateRangePickerInput
        dateStartKey="creditNoteDateStart"
        dateEndKey="creditNoteDateEnd"
      />
    </>
  )
}
