import {
  Alert,
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  Link,
  List,
  ListItem,
  Typography,
} from '@mui/material'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { Form } from '.'
import { ReportType } from '../../../generated/graphql'
import { DateRangePickerInput } from './report-inputs/date-range-picker-input'
import { JobNameInput } from './report-inputs/job-name-input'
import { DateEndPickerInput } from './report-inputs/date-end-picker-input'
import { WarehouseInput } from './report-inputs/warehouse-input'
import { useAuthorization } from '../../../hooks/authorization'
import { useMatch } from 'react-router-dom'
import { RootRoute } from '../..'

type Props = {
  onClose: () => void
  onCreate: () => void
  onExpandDialog: () => void
}

export const CompactDialog = ({ onClose, onCreate, onExpandDialog }: Props) => {
  const { t } = useTranslation('reports')

  const {
    modules: { reportsFullPermission },
  } = useAuthorization()

  const { getValues } = useFormContext<Form>()
  const { reporttype: reportType, jobname: jobName } = getValues()
  const isOnReportJobNewRoute = !!useMatch(RootRoute.ReportJobNew)

  return (
    <Dialog open={true} fullWidth maxWidth={'sm'} onClose={onClose}>
      <DialogTitle sx={{ pb: 2 }}>
        {t('create_dialog.create_report')}
      </DialogTitle>
      {isOnReportJobNewRoute && (
        <Alert sx={{ mx: 3, my: 1 }} variant="outlined" severity="info">
          <Typography variant="body01">
            {t('create.clone_info', {
              name: jobName,
            })}
          </Typography>
        </Alert>
      )}
      <List sx={{ mx: 1, mt: 1, mb: 2 }}>
        <JobNameInput />
        {[ReportType.Order, ReportType.Product].includes(reportType) && (
          <>
            <WarehouseInput warehouseFilterKey="orderWarehouseId" />
            <DateRangePickerInput
              dateStartKey="orderDateStart"
              dateEndKey="orderDateEnd"
            />
          </>
        )}
        {reportType === ReportType.Creditnote && (
          <DateRangePickerInput
            dateStartKey="creditNoteDateStart"
            dateEndKey="creditNoteDateEnd"
          />
        )}
        {reportType === ReportType.GiftCard && (
          <DateRangePickerInput
            dateStartKey="giftCardDateStart"
            dateEndKey="giftCardDateEnd"
          />
        )}
        {reportType === ReportType.SalesTax && (
          <DateRangePickerInput
            dateStartKey="salesTaxDateStart"
            dateEndKey="salesTaxDateEnd"
          />
        )}
        {reportType === ReportType.Stock && (
          <>
            <WarehouseInput warehouseFilterKey="warehouseId" />
            <DateEndPickerInput dateEndKey="stockDateEnd" required />
          </>
        )}
        {reportType === ReportType.Warehouse && (
          <>
            <WarehouseInput warehouseFilterKey="warehouseId" />
            <DateRangePickerInput
              dateStartKey="warehouseDateStart"
              dateEndKey="warehouseDateEnd"
            />
          </>
        )}
        {reportType === ReportType.OmniFulfillment && (
          <DateRangePickerInput
            dateStartKey="omniFulfillmentDateStart"
            dateEndKey="omniFulfillmentDateEnd"
          />
        )}
        {reportsFullPermission && (
          <ListItem>
            <Link
              component="button"
              variant="body02"
              color="info"
              onClick={onExpandDialog}
              sx={{ textDecoration: 'underline' }}
            >
              {t('create_dialog.show_all_settings')}
            </Link>
          </ListItem>
        )}
      </List>
      <DialogActions sx={{ p: 3 }}>
        <Button size="medium" color="secondary" onClick={onClose}>
          {t('shared:action.cancel')}
        </Button>
        <Button size="medium" color="primary" onClick={onCreate}>
          {t('create_dialog.create')}
        </Button>
      </DialogActions>
    </Dialog>
  )
}
