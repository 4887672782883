import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { Form } from '../../..'
import { DatePickerInput } from '../../../../../../inputs/date-picker-input'
import dayjs from 'dayjs'
import { useEffect } from 'react'

export const EndDateField = () => {
  const { t } = useTranslation(['pos_profiles'])
  const { control, watch, formState, trigger } = useFormContext<Form>()

  const returnPolicy = watch('return_policy')
  const { start_date: startDate, last_date: lastDate } = returnPolicy || {}
  const hasAnyOtherDateField = Boolean(startDate || lastDate)

  useEffect(() => {
    const reValidate = async () => {
      if (!formState.isSubmitted) return
      await trigger('return_policy.end_date')
    }
    reValidate().catch(() => {})
  }, [startDate, lastDate, trigger, formState.isSubmitted])

  return (
    <DatePickerInput
      name="return_policy.end_date"
      control={control}
      label={t('pos_profiles:return_policy_section.end_date')}
      helperText={t('pos_profiles:return_policy_section.end_date_description')}
      minDate={dayjs(startDate)}
      maxDate={dayjs(lastDate)}
      errorMessages={{
        minDate: t(
          'pos_profiles:return_policy_section.end_date_min_date_error',
        ),
        maxDate: t(
          'pos_profiles:return_policy_section.end_date_max_date_error',
        ),
      }}
      rules={{
        required: hasAnyOtherDateField
          ? t('pos_profiles:return_policy_section.date_all_required')
          : false,
      }}
    />
  )
}
