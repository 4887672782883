import { SvgIcon, Stack, Button } from '@mui/material'
import { useTranslation } from 'react-i18next'
import MicrosoftLogo from '../../icons/microsoft-logo.svg?react'
import GoogleLogo from '../../icons/google-logo.svg?react'
import {
  LoginOptionTypeEnum,
  PublicLoginOptionsQuery,
} from '../../generated/graphql'
import { ArrayElement } from '../../utils/types'
import { getRedirectParam } from '../../utils/redirect'
import { REDIRECT_QUERY_PARAM } from '../../utils/constants'
import { useLocalStorage } from 'react-use'
import { LockIcon } from '@sitoo/mui-components'
import { useSearchParams } from 'react-router-dom'

type LoginOptionProps = {
  loginOption: ArrayElement<PublicLoginOptionsQuery['publicLoginOptions']>
  label: string
  Icon: React.ElementType
}

const OidcOption = (props: LoginOptionProps) => {
  const { loginOption, label, Icon } = props
  const [, setRedirectPage] = useLocalStorage(REDIRECT_QUERY_PARAM)

  const handleClick = () => {
    if (loginOption.oidc_auth_request_url) {
      const redirectParam = getRedirectParam(window.location.search)

      if (redirectParam) {
        setRedirectPage(redirectParam)
      }

      window.location.assign(loginOption.oidc_auth_request_url)
    }
  }

  return (
    <Button
      key={loginOption.id}
      color="secondary"
      size="large"
      fullWidth
      startIcon={<SvgIcon component={Icon} />}
      onClick={handleClick}
      data-testid={loginOption.type}
      sx={{ justifyContent: 'start' }}
    >
      {label}
    </Button>
  )
}

type LoginOption = PublicLoginOptionsQuery['publicLoginOptions'][number]

type LoginOptionsProps = {
  loginOptions?: PublicLoginOptionsQuery['publicLoginOptions']
}

export const OidcOptions = (props: LoginOptionsProps) => {
  const { t } = useTranslation(['login', 'shared'])
  const { loginOptions } = props
  const [searchParams] = useSearchParams()
  const hasSupport = searchParams.has('support')

  const SUPPORT_OPTION_ID = 'support-login'

  const oidcOptions = loginOptions
    ?.filter(({ type }) =>
      [
        LoginOptionTypeEnum.OidcPrivateAzureAd,
        LoginOptionTypeEnum.OidcPrivateGoogle,
      ].includes(type),
    )
    .filter(({ id }) => (id === SUPPORT_OPTION_ID ? hasSupport : true))

  const getIcon = (type: LoginOptionTypeEnum) => {
    switch (type) {
      case LoginOptionTypeEnum.OidcPrivateAzureAd:
        return MicrosoftLogo
      case LoginOptionTypeEnum.OidcPrivateGoogle:
        return GoogleLogo
      default:
        return LockIcon
    }
  }

  const getLabel = (option: LoginOption) => {
    return option.id === SUPPORT_OPTION_ID
      ? t('login:OidcSupportReadOnly')
      : t(`login:${option.type}`)
  }

  if (!oidcOptions?.length) return null

  return (
    <Stack
      sx={{ backgroundColor: (theme) => theme.palette.gray20, px: 6, py: 5 }}
      gap={2}
    >
      {oidcOptions.map((option) => (
        <OidcOption
          key={option.id}
          loginOption={option}
          label={getLabel(option)}
          Icon={getIcon(option.type)}
        />
      ))}
    </Stack>
  )
}
