import { SettingsListSection } from '../../../../components/settings-list-section'
import { TextFieldInput } from '../../../../inputs/text-field-input'
import { useFormContext } from 'react-hook-form'
import { GeneralSettingsFormContext } from '../general-settings-form'
import { useTranslation } from 'react-i18next'
import { SettingsUiStringsKeySpecifier } from '../../../../generated/apollo-helpers'
import { SettingsListItem } from '../../../../components/settings-list-item'

export const customProductFields = [
  'product_custom1',
  'product_custom2',
  'product_custom3',
  'product_custom4',
  'product_custom5',
] as const satisfies SettingsUiStringsKeySpecifier

export const PosCustomProductInfo = () => {
  const { control } = useFormContext<GeneralSettingsFormContext>()
  const { t } = useTranslation(['shared', 'settings'])

  return (
    <SettingsListSection
      title={t(`settings:general.pos_custom_attributes_title`)}
      description={t(`settings:general.pos_custom_attributes_description`)}
    >
      {customProductFields.map((fieldName, index) => (
        <SettingsListItem key={index}>
          <TextFieldInput
            control={control}
            label={t(`settings:general.pos_custom_attributes_custom`, {
              value: index + 1,
            })}
            name={`uistrings.${fieldName}`}
          />
        </SettingsListItem>
      ))}
    </SettingsListSection>
  )
}
