import { exists, t } from 'i18next'
import { extractGraphqlErrors } from '../extract-graphql-errors'
import { GraphQLFormattedErrorExtensions } from 'graphql'

const getInputLabel = (property: unknown) => {
  try {
    return typeof property === 'string'
      ? document.querySelector<HTMLInputElement>(`[name*="${property}"]`)
          ?.labels?.[0]?.textContent
      : undefined
  } catch {
    return undefined
  }
}

/**
 * @deprecated use getErrorMessages
 */
export const getErrorMessage = (
  error: unknown,
  messagePrefix: string,
  fallbackMessage?: string,
  opts?: {
    resolveProperty?: (property: string) => string
  },
): string => {
  const defaultError =
    typeof fallbackMessage === 'string'
      ? fallbackMessage
      : t('shared:error.default')

  try {
    const allErrors = extractGraphqlErrors(error)

    if (!allErrors.length) {
      return defaultError
    }

    const code = allErrors[0]?.extensions?.['code']
    const sizeLimit = allErrors[0]?.extensions?.['sizeLimit']
    const property =
      opts?.resolveProperty && allErrors[0]?.extensions?.['property']
        ? opts.resolveProperty(allErrors[0]?.extensions['property'] as string)
        : allErrors[0]?.extensions?.['property']
    const value = allErrors[0]?.extensions?.['value']

    if (typeof code !== 'string') {
      return defaultError
    }

    const lowerErrorCode = code.toLowerCase()

    const inputLabel = getInputLabel(property)

    if (exists(`${messagePrefix}:error.${lowerErrorCode}`)) {
      return t(`${messagePrefix}:error.${lowerErrorCode}`, {
        property: inputLabel || property,
        sizeLimit,
        value,
      })
    }

    if (exists(`shared:error.${lowerErrorCode}`)) {
      return t(`shared:error.${lowerErrorCode}`, {
        property: inputLabel || property,
        sizeLimit,
        value,
      })
    }

    return defaultError
  } catch {
    return defaultError
  }
}

type ErrorMessageProps = {
  format?: (
    errorCode: string,
    property: string | undefined,
    defaultValue: string,
  ) => string
}

const getErrorData = ({
  code,
  property,
}: GraphQLFormattedErrorExtensions = {}) => ({
  code: typeof code === 'string' ? code : 'default',
  property: typeof property === 'string' ? property : undefined,
})

/**
 * Extracts error messages from a GraphQL error
 * @param error - The GraphQL error
 * @param opts - Options for formatting the error message
 * @returns An array of error messages
 *
 * @example
 * Search for `error.extensions.code` in `shared:error`, otherwise fallback to
 * the server error message
 * ```
 * getErrorMessages(error)
 * ```
 *
 * @example
 * Format a custom error message
 * ```
 * getErrorMessages(error, {
 *  format: (errorCode, property, defaultValue) => t('namespace', { property }),
 * })
 * ```
 */
export const getErrorMessages = (
  error: unknown,
  opts?: ErrorMessageProps,
): string[] => {
  const defaultResolve = (errorCode: string) => `shared:error.${errorCode}`

  const allErrors = extractGraphqlErrors(error)
  // NOTE: It should be enough to return the first error message
  const errors: string[] = []

  for (const graphqlError of allErrors) {
    const { message: defaultValue, extensions } = graphqlError

    const { code, property } = getErrorData(extensions)
    const errorCode = code.toLowerCase()

    const error =
      opts?.format?.(errorCode, property, defaultValue) ||
      t(defaultResolve(errorCode), { property, defaultValue })

    errors.push(error)
  }

  return errors
}
