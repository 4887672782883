import { GridRowModel, useGridApiContext } from '@mui/x-data-grid-pro'
import { useSnackbar } from 'notistack'
import { useTranslation } from 'react-i18next'
import { SetCategoryDialog } from '../../set-category-dialog'
import { Row } from '..'

type SetCategoryProps = {
  isOpen: boolean
  onClose: () => void
  refetch(): unknown
}

export const SetCategory = (props: SetCategoryProps) => {
  const { t } = useTranslation(['products'])

  const { enqueueSnackbar } = useSnackbar()
  const apiRef = useGridApiContext()
  const selectedProducts = (
    Array.from(apiRef.current.getSelectedRows().values()) as GridRowModel<Row>[]
  ).map((category) => ({
    id: category.id,
    allcategories: category.allcategories,
    defaultcategoryid: category.defaultcategoryid,
  }))

  const onClose = () => {
    props.onClose()
  }

  const onSuccess = (
    successProductIds: number[],
    failedProductIds: number[],
  ) => {
    props.refetch()

    if (successProductIds.length) {
      enqueueSnackbar(
        t('products:success_update_category_snackbar', {
          count: successProductIds.length,
        }),
      )
    }

    if (failedProductIds.length) {
      enqueueSnackbar(
        t('products:failure_update_category_snackbar', {
          count: failedProductIds.length,
        }),
        { variant: 'error' },
      )
    }

    onClose()
  }

  const onError = () => {
    props.refetch()
    onClose()
  }

  return (
    <SetCategoryDialog
      open={props.isOpen}
      onClose={onClose}
      products={selectedProducts}
      onSuccess={onSuccess}
      onError={onError}
    />
  )
}
