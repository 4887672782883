import { ListItemIcon, ListItemText, SxProps, Theme } from '@mui/material'
import { ShipmentInboundIcon } from '@sitoo/mui-components'
import { forwardRef, ReactNode } from 'react'
import { useTranslation } from 'react-i18next'
import { ListItemSkeleton } from '../../../../components/list-item-skeleton'
import { ShipmentAddress } from '../../../../generated/graphql'

type Props = {
  fallbackTitle?: string
  address?: ShipmentAddress | null
  sx?: SxProps<Theme>
  disabled?: boolean
  error?: boolean

  isLoading?: boolean
  hide?: boolean
  divider?: boolean
  secondaryAction?: ReactNode
}

export const AddressListItem = forwardRef<HTMLLIElement, Props>(
  function AddressListItem(
    {
      fallbackTitle,
      address,
      sx,
      disabled,
      error,

      ...otherProps
    },
    ref,
  ) {
    const { t } = useTranslation(['countries'])

    return (
      <ListItemSkeleton
        ref={ref}
        childrenSkeleton
        sx={{
          whiteSpace: 'pre-line',
          minHeight: (theme) => theme.spacing(8),
          ...sx,
        }}
        {...otherProps}
      >
        <ListItemIcon
          sx={{
            ...(error
              ? {
                  color: (theme) => theme.palette.error.main,
                }
              : disabled
                ? {
                    color: (theme) => theme.palette.gray50,
                  }
                : {}),
          }}
        >
          <ShipmentInboundIcon />
        </ListItemIcon>
        <ListItemText
          primary={address?.name || fallbackTitle}
          sx={{
            ...(error
              ? {
                  color: (theme) => theme.palette.error.main,
                }
              : disabled
                ? {
                    color: (theme) => theme.palette.gray50,
                  }
                : {}),
          }}
          slotProps={{
            secondary: {
              sx: {
                '&.MuiListItemText-secondary': {
                  ...(error
                    ? {
                        color: (theme) => theme.palette.error.main,
                      }
                    : disabled
                      ? {
                          color: (theme) => theme.palette.gray50,
                        }
                      : {}),
                },
              },
            },
          }}
          secondary={[
            address?.address,
            address?.address2,
            address?.city,
            address?.zip,
            address?.state,
            address?.country_id
              ? t(`countries:${address?.country_id.toUpperCase()}`)
              : '',
          ]
            .filter(Boolean)
            .join('\n')}
        />
      </ListItemSkeleton>
    )
  },
)
