import { GridRowModel, useGridApiContext } from '@mui/x-data-grid-pro'
import { useSnackbar } from 'notistack'
import { useTranslation } from 'react-i18next'
import { SetPriceDialog } from '../../set-price-dialog'
import { Row } from '..'

type Props = {
  isOpen: boolean
  pricelistId: number
  productSiteId?: number
  onClose(): void
  refetch(): unknown
}

export const SetPrice = (props: Props) => {
  const { t } = useTranslation(['prices'])
  const { enqueueSnackbar } = useSnackbar()
  const apiRef = useGridApiContext()
  const selectedProducts = (
    Array.from(apiRef.current.getSelectedRows().values()) as GridRowModel<Row>[]
  ).map(({ id, sku }) => ({ id, sku }))

  const onClose = () => {
    props.onClose()
  }

  const onSuccess = (
    successProductIds: number[],
    failedProductIds: number[],
  ) => {
    props.refetch()

    if (successProductIds.length) {
      enqueueSnackbar(
        t('prices:success_update_price_snackbar', {
          count: successProductIds.length,
        }),
      )
    }

    if (failedProductIds.length) {
      enqueueSnackbar(
        t('prices:failure_update_price_snackbar', {
          count: failedProductIds.length,
        }),
        { variant: 'error' },
      )
    }

    onClose()
  }

  const onError = () => {
    props.refetch()
    onClose()
  }

  return (
    <SetPriceDialog
      open={props.isOpen}
      onClose={onClose}
      products={selectedProducts}
      pricelistId={props.pricelistId}
      productSiteId={props.productSiteId}
      onSuccess={onSuccess}
      onError={onError}
    />
  )
}
