import { JSXElementConstructor, useId, useState } from 'react'
import {
  Button,
  Chip,
  InputLabel,
  ListItemText,
  Stack,
  Typography,
} from '@mui/material'
import { useTranslation } from 'react-i18next'
import {
  Control,
  FieldValues,
  Path,
  PathValue,
  RegisterOptions,
  useController,
} from 'react-hook-form'
import { ListItemSkeleton } from '../../components/list-item-skeleton'
import {
  ListOption,
  PredefinedValuesInput,
} from '../../inputs/predefined-values-input'
import { DialogInputDialog } from '../dialog-input-dialog'
import { useTracking } from '../../hooks/tracking'

export type Props<
  TFieldValues extends FieldValues,
  TName extends Path<TFieldValues>,
  TValue,
  TInputProps,
> = {
  name: TName
  control: Control<TFieldValues>
  itemLabel: string
  addLabel?: string
  formatValue: (value: TValue) => Omit<ListOption<TValue>, 'value'>
  helperText?: React.ReactNode
  options?: TValue[]
  emptyMessage: string
  isLoading?: boolean
  isDraggable?: boolean
  onChange?: (value: TValue[]) => void
  rules?: Omit<
    RegisterOptions<DialogForm<TValue>, 'values'>,
    'valueAsNumber' | 'valueAsDate' | 'setValueAs'
  >
  transform?: {
    input?: (value: PathValue<TFieldValues, TName>) => TValue[]
    output?: (values: TValue[]) => PathValue<TFieldValues, TName>
  }

  slots?: {
    Input?: JSXElementConstructor<TInputProps> | null
  }
  slotProps?: {
    input?: Omit<TInputProps, 'name' | 'control'>
  }
}

export type DialogForm<TValue> = {
  values: TValue[]
}

export const DialogInput = <
  TFieldValues extends FieldValues,
  TName extends Path<TFieldValues>,
  TValue,
  TInputProps,
>(
  props: Props<TFieldValues, TName, TValue, TInputProps>,
) => {
  const {
    name,
    control,
    itemLabel,
    addLabel,
    formatValue,
    helperText,
    options,
    emptyMessage,
    isLoading,
    isDraggable = true,
    rules,
    transform,
    slots,
    slotProps,
  } = props
  const Input = slots?.Input || PredefinedValuesInput
  const slotInputProps = slotProps?.input as TInputProps

  const { t } = useTranslation(['shared'])
  const [dialogOpen, setDialogOpen] = useState(false)
  const helperTextId = useId()
  const { trackDialogOpen, trackDialogClose } = useTracking()

  const {
    field: { value, onChange },
  } = useController({ name, control })

  const transformedValue = transform?.input?.(value) ?? value

  const currentValues: TValue[] = Array.isArray(transformedValue)
    ? transformedValue.filter((v) => !options || options.includes(v))
    : transformedValue

  const handleEditClick = () => {
    setDialogOpen(true)
    trackDialogOpen({ name, category: 'dialog-input' })
  }

  const handleCloseDialog = () => {
    setDialogOpen(false)
  }

  const handleSaveDialog = (values: TValue[]) => {
    const transformedValues = transform?.output?.(values) ?? values

    onChange(transformedValues)
    props?.onChange?.(transformedValues)

    trackDialogClose({ name, category: 'dialog-input' })

    setDialogOpen(false)
  }

  const formattedValues =
    currentValues?.map((value: TValue) => ({
      value: value,
      ...formatValue(value),
    })) || []

  const formattedOptions =
    options?.map((value) => ({
      value: value,
      ...formatValue(value),
    })) || []

  const chipValues = formattedValues
    .filter((value) => value.label)
    .map(({ value }) => formatValue(value))

  const hasValues = chipValues.length > 0

  return (
    <>
      <ListItemSkeleton
        isLoading={isLoading}
        divider
        secondaryAction={
          <Button
            color="secondary"
            size="small"
            onClick={() => handleEditClick()}
            aria-label={`${t('shared:action.edit')} ${itemLabel}`}
            aria-describedby={helperTextId}
          >
            {t('shared:action.edit')}
          </Button>
        }
        childrenSkeleton
        hasSecondaryText
      >
        <ListItemText
          primary={<InputLabel sx={{ m: 0 }}>{itemLabel}</InputLabel>}
          secondary={
            <Stack component="span">
              {helperText && (
                <Typography
                  variant="caption"
                  sx={{ maxWidth: '90%' }}
                  id={helperTextId}
                >
                  {helperText}
                </Typography>
              )}
              <Stack
                component="span"
                alignItems="flex-start"
                flexDirection="row"
                gap={0.5}
                flexWrap="wrap"
                sx={{ mt: 0.5 }}
              >
                {hasValues ? (
                  chipValues
                    .filter(Boolean)
                    .map((value, index) => (
                      <Chip
                        key={index}
                        label={value.label}
                        size="small"
                        component="span"
                        variant="outlined"
                        color="black"
                      />
                    ))
                ) : (
                  <Chip
                    label={emptyMessage}
                    size="small"
                    component="span"
                    variant="outlined"
                    color="gray"
                  />
                )}
              </Stack>
            </Stack>
          }
        />
      </ListItemSkeleton>

      <DialogInputDialog
        open={dialogOpen}
        onClose={handleCloseDialog}
        onSave={handleSaveDialog}
        defaultValue={currentValues}
        label={itemLabel}
        renderInput={({ control }) => (
          <Input
            name="values"
            control={control}
            {...slotInputProps}
            options={formattedOptions}
            addLabel={addLabel}
            isDraggable={isDraggable}
            rules={rules}
          />
        )}
      />
    </>
  )
}
