import { useMutation, useQuery } from '@apollo/client'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from '@mui/material'
import { enqueueSnackbar } from 'notistack'
import { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import {
  RewardsSettingsDocument,
  UpdateRewardsSettingsDocument,
  UpdateRewardsSettingsMutationVariables,
} from '../../../../generated/graphql'
import { useServerValidation } from '../../../../hooks/server-validation'
import { getErrorMessage } from '../../../../utils/error-mapping'
import { extractGraphqlErrors } from '../../../../utils/extract-graphql-errors'

type DialogProps = {
  open: boolean
  onClose: () => void
}

export const RewardsDialog = (props: DialogProps) => {
  const { t } = useTranslation(['shared', 'settings'])

  const { data, refetch, error } = useQuery(RewardsSettingsDocument, {
    skip: !props.open,
  })

  useEffect(() => {
    if (error) {
      enqueueSnackbar(t('shared:error.default'), {
        variant: 'error',
      })
    }
  }, [error, t])

  useEffect(() => {
    if (props.open) void refetch()
  }, [props.open, refetch, t])

  const [updateRewardsSettings, { loading: updateLoading }] = useMutation(
    UpdateRewardsSettingsDocument,
  )

  const {
    formState: { errors },
    register,
    handleSubmit,
    setError,
    setValue,
  } = useForm<UpdateRewardsSettingsMutationVariables>({
    values: {
      apiUrl: data?.rewardsSetting?.api_url || '',
      apiToken: '',
    },
  })

  const setFormError =
    useServerValidation<UpdateRewardsSettingsMutationVariables>(
      'settings:custom_api.rewards',
      setError,
      {
        resolveFieldFromProperty: (property) => property,
      },
    )

  const onSubmit = async (data: UpdateRewardsSettingsMutationVariables) => {
    try {
      await updateRewardsSettings({
        variables: {
          apiUrl: data.apiUrl,
          apiToken: data.apiToken,
        },
      })
      enqueueSnackbar(
        t('settings:custom_api.rewards.dialog.update_successful'),
        { variant: 'success' },
      )
      props.onClose()
    } catch (error) {
      const errors = extractGraphqlErrors(error)

      errors.map(() => {
        const errorMessage = getErrorMessage(
          error,
          'settings:custom_api.rewards',
        )
        setFormError(error)
        enqueueSnackbar(errorMessage, {
          variant: 'error',
        })
      })
    }
  }

  return (
    <Dialog open={props.open} maxWidth="xs" fullWidth onClose={props.onClose}>
      <DialogTitle fontWeight={700}>
        {t('settings:custom_api.rewards.dialog.header')}
      </DialogTitle>
      <DialogContent>
        <TextField
          error={!!errors.apiUrl}
          fullWidth
          helperText={
            errors.apiUrl?.message ||
            t('settings:custom_api.rewards.dialog.api_url_helper')
          }
          placeholder={t('settings:custom_api.rewards.dialog.api_url')}
          label={t('settings:custom_api.rewards.dialog.api_url')}
          {...register('apiUrl', {
            onBlur: (event: React.FocusEvent<HTMLInputElement>) => {
              setValue('apiUrl', event.target.value.trim().replace(/\/+$/, ''))
            },
          })}
          sx={{ mb: 2 }}
          slotProps={{
            htmlInput: { autoComplete: 'off' },
          }}
        />

        <TextField
          error={!!errors.apiToken}
          fullWidth
          helperText={
            errors.apiToken?.message ||
            t('settings:custom_api.rewards.dialog.api_token_helper')
          }
          placeholder={t(
            'settings:custom_api.rewards.dialog.api_token_placeholder',
          )}
          label={t('settings:custom_api.rewards.dialog.api_token')}
          {...register('apiToken')}
          sx={{ mb: 2 }}
          slotProps={{
            htmlInput: { autoComplete: 'off' },
          }}
        />
      </DialogContent>
      <DialogActions>
        <Button color="secondary" onClick={props.onClose}>
          {t('shared:action.cancel')}
        </Button>
        <Button
          color="primary"
          onClick={handleSubmit(onSubmit)}
          loading={updateLoading}
        >
          {t('shared:action.save')}
        </Button>
      </DialogActions>
    </Dialog>
  )
}
