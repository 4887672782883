import { useReactiveVar } from '@apollo/client'
import { reportsGraphHeight } from './graph-section'
import {
  NAVBAR_HEIGHT,
  PAGE_BOTTOM_OFFSET,
  PAGE_HEADER_HEIGHT,
} from '../../utils/constants'

export const useCalculateGridHeight = () => {
  const REPORTS_HEADER_HEIGHT = 88
  const graphHeight = useReactiveVar(reportsGraphHeight)

  const gridHeight =
    PAGE_HEADER_HEIGHT +
    NAVBAR_HEIGHT +
    REPORTS_HEADER_HEIGHT +
    PAGE_BOTTOM_OFFSET * 2 +
    graphHeight

  const placeholderHeight =
    PAGE_HEADER_HEIGHT +
    NAVBAR_HEIGHT +
    REPORTS_HEADER_HEIGHT +
    PAGE_BOTTOM_OFFSET * 2

  return { gridHeight, placeholderHeight }
}
