import { GridColDef } from '@mui/x-data-grid-pro'
import { useTranslation } from 'react-i18next'
import { DataGrid } from '../../../../components/data-grid'
import { ReportFragment } from '../../../../generated/graphql'
import { Skeleton } from '@mui/material'
import { useCalculateGridHeight } from '../../calculate-grid-height'

type Props = {
  report: ReportFragment
}

export const ReportResultTablePreview = ({
  report: {
    reportdefinition: { groups, measures },
  },
}: Props) => {
  const { t } = useTranslation(['reports'])
  const columns = [...groups, ...measures]

  const { gridHeight } = useCalculateGridHeight()

  const columnTranslationPairs = [
    ['id', '#'],
    ...columns.map((column) => [column, t(`reports:report_keys.${column}`)]),
  ] as const

  const gridColumns: GridColDef[] = columnTranslationPairs.map(
    ([field, headerName], index) => ({
      field,
      headerName,
      flex: index === 0 ? 0.1 : 1,
      renderCell: () => <Skeleton variant="text" animation={false} />,
    }),
  )

  const gridRow = columns.reduce<Record<string, string>>(
    (accumulator, column) => ({
      ...accumulator,
      [column]: '',
    }),
    {},
  )

  const gridRows = Array.from({ length: 5 }, (_, index) => ({
    id: index,
    ...gridRow,
  }))

  return (
    <DataGrid
      name="preview"
      rows={gridRows}
      rowCount={gridRows.length}
      columns={gridColumns}
      disableAllFilters
      disableColumnReorder
      disableColumnSelector
      disableColumnMenu
      disableColumnFilter
      disableColumnPinning
      sx={{ height: `calc(100vh - ${gridHeight}px)` }}
    />
  )
}
