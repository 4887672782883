import { Autocomplete, TextField } from '@mui/material'
import { useContext, useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { FilterContext } from '../../../components/data-grid/context'
import { useStateParams } from '../../../hooks/state-params'
import { useTracking } from '../../../hooks/tracking'
import { MAX_ITEMS_ORDER_API } from '.'
import { addTagOnInputChange } from '../../../utils/autocomplete'

type QueryParamsState = {
  skus?: string | string[]
}

export const SkuFilter = () => {
  const { t } = useTranslation(['orders', 'shared'])
  const {
    hasApply,
    removeFilter,
    setFilter,
    subscribeOnResetFilter,
    registerFilter,
  } = useContext(FilterContext)
  const { trackInputBlur, trackInputFocus } = useTracking()
  const filterKey = 'skus'

  const [queryParams, setQueryParams] =
    useStateParams<QueryParamsState>(undefined)

  const selectedSkus = useMemo(
    () =>
      typeof queryParams[filterKey] === 'string'
        ? [queryParams[filterKey]]
        : (queryParams[filterKey] || []).slice(-MAX_ITEMS_ORDER_API),
    [queryParams],
  )

  useEffect(() => {
    registerFilter({
      key: filterKey,
    })
  }, [registerFilter])

  useEffect(() => {
    const unsubscribe = subscribeOnResetFilter((key) => {
      if (!key || key === filterKey) {
        setQueryParams({ [filterKey]: undefined })
      }
    })
    return () => {
      unsubscribe()
    }
  }, [setQueryParams, subscribeOnResetFilter])

  useEffect(() => {
    if (selectedSkus.length) {
      setFilter(filterKey, {
        label: t('filter:sku.label'),
        labelValues: selectedSkus,
        value: selectedSkus,
      })
    } else {
      removeFilter(filterKey)
    }
  }, [removeFilter, setFilter, selectedSkus, t])

  return (
    <Autocomplete
      autoHighlight
      multiple
      limitTags={3}
      fullWidth
      autoSelect
      freeSolo
      options={[]}
      onChange={(_event, value) => {
        setQueryParams(
          {
            [filterKey]: value.slice(-MAX_ITEMS_ORDER_API),
          },
          hasApply !== true,
        )
      }}
      onInputChange={addTagOnInputChange}
      value={selectedSkus}
      data-testid="sku-filter-field"
      renderInput={(params) => (
        <TextField
          label={t('filter:sku.label')}
          type="text"
          {...params}
          onFocus={trackInputFocus({ name: `sku-filter-${filterKey}` })}
          onBlur={trackInputBlur({ name: `sku-filter-${filterKey}` })}
          slotProps={{
            htmlInput: {
              ...params.inputProps,
              ['data-testid']: 'sku-filter-input',
            },
          }}
        />
      )}
    />
  )
}
