import { useQuery } from '@apollo/client'
import {
  CashRegisterFragment,
  GetManufacturerDocument,
  GetProductGroupDocument,
  ReportDefinitionFilter,
  WarehouseFragment,
} from '../../../generated/graphql'
import { useTranslation } from 'react-i18next'
import { useCallback, useMemo } from 'react'
import { useLocalizedDate } from '../../../hooks/localized-date'

export type UseFormattedFilterChips = (args: {
  allCashRegisters: CashRegisterFragment[]
  allWarehouses: WarehouseFragment[]
  filters: ReportDefinitionFilter | undefined
  loading?: boolean
}) => {
  formattedFilters: [string, string | string[]][]
}

const removeEmptyFilters = (
  item: [string, string | string[] | null],
): item is [string, string | string[]] => item.at(1) !== null

export const useFormattedFilterChips: UseFormattedFilterChips = ({
  filters,
  allCashRegisters,
  allWarehouses,
  loading,
}) => {
  const { t } = useTranslation(['reports'])

  const { formatDate } = useLocalizedDate()

  const { data: manufacturerData, loading: isLoadingManufacturers } = useQuery(
    GetManufacturerDocument,
    {
      fetchPolicy: 'cache-first',
      variables: { id: filters?.productManufacturerId ?? 0 },
      skip: !filters?.productManufacturerId,
    },
  )

  const { data: productGroupData, loading: isLoadingProductGroups } = useQuery(
    GetProductGroupDocument,
    {
      variables: { id: filters?.productVatId ?? 0 },
      fetchPolicy: 'cache-first',
      skip: !filters?.productVatId,
    },
  )
  const isLoading = loading || isLoadingManufacturers || isLoadingProductGroups

  const formatOrderPurchaseType = useCallback(
    (orderIsNegative: boolean | null | undefined) => {
      if (orderIsNegative === null) {
        return null
      }
      return orderIsNegative
        ? t('filter_chips.refund_only')
        : t('filter_chips.sales_only')
    },
    [t],
  )

  const formatOrderHasRegisterId = useCallback(
    (orderHasRegisterId: boolean | null | undefined) => {
      if (orderHasRegisterId === null) {
        return null
      }
      return orderHasRegisterId
        ? t('filter_chips.pos')
        : t('filter_chips.webshop')
    },
    [t],
  )
  const formatWarehouse = useCallback(
    (warehouseId: number | number[] | null | undefined) => {
      if (!warehouseId) return null

      const findWarehouse = (id: number) =>
        allWarehouses.find(({ id: wid }) => wid === id)

      const warehouses = (
        Array.isArray(warehouseId) ? warehouseId : [warehouseId]
      )
        .map(findWarehouse)
        .filter((warehouse) => warehouse !== undefined)

      if (warehouses.length === 0) return null

      return warehouses.map(
        ({ name, currencycode }) => `${name} [${currencycode}]`,
      )
    },
    [allWarehouses],
  )

  const formatOrderRegisterId = useCallback(
    (orderRegisterId: string | null | undefined) => {
      if (orderRegisterId === null) {
        return null
      }
      const cashRegister = allCashRegisters.find(
        ({ registerid }) => registerid === orderRegisterId,
      )
      return t('filter_chips.pos_list_item', {
        number: cashRegister?.registernumber ?? '',
        key: cashRegister?.registerkey ?? '',
      })
    },
    [allCashRegisters, t],
  )

  const formatFromTranslationKey = useCallback(
    (key: string | null | undefined, baseKey: string) => {
      if (!key) {
        return null
      }
      return t(`${baseKey}.${key}`)
    },
    [t],
  )

  const formatProductManufacturer = useCallback(() => {
    if (!manufacturerData?.manufacturer) {
      return null
    }
    return manufacturerData.manufacturer.name
  }, [manufacturerData])

  const formatProductGroup = useCallback(() => {
    if (!productGroupData?.productGroup) {
      return null
    }
    return `${productGroupData.productGroup.name} (${productGroupData.productGroup.value}%)`
  }, [productGroupData])

  const formatWarehouseCurrencyCode = useCallback(
    (warehouseCurrencyCode: string | null | undefined) => {
      if (!warehouseCurrencyCode) {
        return null
      }

      return t(`filter_chips.warehouse_currency_code`, {
        currency: warehouseCurrencyCode,
      })
    },
    [t],
  )

  const formattedFilters = useMemo(() => {
    if (!filters) {
      return []
    }

    const formattedValues: Partial<
      Record<keyof ReportDefinitionFilter, string | string[] | null>
    > = {
      orderSearchText: filters.orderSearchText ?? null,
      orderVoucherName: filters.orderVoucherName ?? null,
      orderVoucherCode: filters.orderVoucherCode ?? null,
      orderDateStart: filters.orderDateStart
        ? formatDate(filters.orderDateStart)
        : null,
      orderDateEnd: filters.orderDateEnd
        ? formatDate(filters.orderDateEnd)
        : null,
      orderIsNegative: formatOrderPurchaseType(filters.orderIsNegative),
      orderWarehouseId: formatWarehouse(filters.orderWarehouseId),
      orderRegisterId: formatOrderRegisterId(filters.orderRegisterId),
      warehouseDateStart: filters.warehouseDateStart
        ? formatDate(filters.warehouseDateStart)
        : null,
      warehouseDateEnd: filters.warehouseDateEnd
        ? formatDate(filters.warehouseDateEnd)
        : null,
      warehouseId: formatWarehouse(filters.warehouseId),
      orderState: formatFromTranslationKey(
        filters.orderState?.join('') ?? null,
        'order_state_keys',
      ),
      orderHasRegisterId: formatOrderHasRegisterId(filters.orderHasRegisterId),
      orderPaymentState: formatFromTranslationKey(
        filters.orderPaymentState,
        'payment_state_keys',
      ),
      productProductGroupType: formatFromTranslationKey(
        filters.productProductGroupType,
        'product_group_type_keys',
      ),
      warehouseTransactionType: formatFromTranslationKey(
        filters.warehouseTransactionType,
        'warehouse_transaction_type_keys',
      ),
      productManufacturerId: formatProductManufacturer(),
      productVatId: formatProductGroup(),
      stockDateEnd: filters.stockDateEnd
        ? formatDate(filters.stockDateEnd)
        : null,
      warehouseCurrencyCode: formatWarehouseCurrencyCode(
        filters.warehouseCurrencyCode,
      ),
      warehouseSearchText: filters.warehouseSearchText ?? null,
    }

    const formattedKeysAndValues: [string, string | string[] | null][] =
      Object.entries(formattedValues).map(([key, value]) => [
        `${t(`filter_keys.${key}`)}`,
        value,
      ])

    return formattedKeysAndValues.filter(removeEmptyFilters)
  }, [
    filters,
    formatDate,
    formatOrderPurchaseType,
    formatWarehouse,
    formatOrderRegisterId,
    formatFromTranslationKey,
    formatOrderHasRegisterId,
    formatProductManufacturer,
    formatProductGroup,
    formatWarehouseCurrencyCode,
    t,
  ])

  if (isLoading) return { formattedFilters: [] }

  return {
    formattedFilters,
  }
}
