export const ACCOUNT_ID_REGEX = /^([0-9]+)\./

export const ACCOUNT_ID_COOKIE = 'sitooAccountId'

/**
 * Remove accountId from a host
 * Example: 2001.sitooapps-test.com => sitooapps-test.com
 */
export const getHostName = (): string => {
  const host = location.hostname
  const accountId = host.match(ACCOUNT_ID_REGEX)?.[0]

  return accountId ? host.replace(accountId, '') : host
}
