import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Divider,
  List,
  ListItemIcon,
  ListItemText,
} from '@mui/material'
import { SectionHeader } from '@sitoo/mui-components'
import { Fragment } from 'react'
import { useTranslation } from 'react-i18next'
import { ListItemAccordion } from '../../../components/list-item-accordion'
import { ListItemSkeleton } from '../../../components/list-item-skeleton'
import { GetOrderQuery } from '../../../generated/graphql'
import { useMoney } from '../../../hooks/money'
import { ArrayElement } from '../../../utils/types'
import { PaymentIcon } from './payments'
import { getPaymentCardNumber } from '../../../utils/order'
import { formatAdditionalData } from '../../../utils/format/additional-data'
import { PaymentAdditionalData } from '../util'

type Props = {
  isLoading?: boolean
  order?: GetOrderQuery['order']
}

type ReservedPayment = ArrayElement<GetOrderQuery['order']['reservedpayments']>

export const ReservedPayments = (props: Props) => {
  const { t } = useTranslation(['shared', 'orders'])
  const { formatCurrency } = useMoney()
  const reservedPayments = props.order?.reservedpayments || []

  const getPaymentIcon = (
    payment: ReservedPayment,
    additionalData: PaymentAdditionalData,
  ) => {
    const paymentMethod = additionalData['payment-method'] || payment.reftype

    return <PaymentIcon paymentMethod={paymentMethod} />
  }

  if (!props.isLoading && reservedPayments.length === 0) {
    return null
  }

  return (
    <>
      <Accordion defaultExpanded className="MuiAccordionRoot">
        <AccordionSummary aria-controls="reserved-payments">
          <SectionHeader sx={{ p: 0 }}>
            {t('orders:reserved_payment')}
          </SectionHeader>
        </AccordionSummary>
        <AccordionDetails sx={{ p: 0 }}>
          {props.isLoading && (
            <ListItemSkeleton
              isLoading={props.isLoading}
              secondaryAction="-"
              childrenSkeleton
            />
          )}
          {reservedPayments.map((reservedPayment, i) => {
            const additionalData: PaymentAdditionalData | null | undefined =
              reservedPayment.additionaldata || {}

            const additionalDataList = formatAdditionalData(
              reservedPayment.additionaldata,
            )

            return (
              <Fragment key={reservedPayment.id}>
                <ListItemAccordion
                  aria-controls={`reserved-payment-${reservedPayment.id}`}
                  summary={
                    <ListItemSkeleton
                      secondaryAction={
                        <ListItemText
                          slotProps={{
                            secondary: {
                              sx: {
                                display: 'flex',
                                justifyContent: 'end',
                                color: (theme) =>
                                  `${theme.palette.orangeBase} !important`,
                              },
                            },
                          }}
                          secondary={t('orders:view_panel.reserved')}
                        >
                          {formatCurrency(reservedPayment.moneyreserved)}
                        </ListItemText>
                      }
                      isLoading={props.isLoading}
                      data-testid={`order-reserved-payment-${reservedPayment.id}`}
                      sx={{
                        '.MuiListItemSecondaryAction-root': {
                          flexShrink: 0,
                        },
                      }}
                    >
                      <ListItemIcon>
                        {getPaymentIcon(reservedPayment, additionalData)}
                      </ListItemIcon>
                      <ListItemText
                        primary={reservedPayment.name}
                        secondary={getPaymentCardNumber(additionalData)}
                      />
                    </ListItemSkeleton>
                  }
                >
                  <List>
                    <ListItemSkeleton
                      hide={!props.isLoading && !reservedPayment.id}
                      secondaryAction={
                        <ListItemText>{reservedPayment.id}</ListItemText>
                      }
                      isLoading={props.isLoading}
                      data-testid={`order-reserved-payment-id-${reservedPayment.id}`}
                      divider
                    >
                      <ListItemText primary={t('orders:view_panel.id')} />
                    </ListItemSkeleton>
                    <ListItemSkeleton
                      hide={!props.isLoading && !reservedPayment.externalid}
                      secondaryAction={
                        <ListItemText>
                          {reservedPayment.externalid}
                        </ListItemText>
                      }
                      isLoading={props.isLoading}
                      data-testid={`order-reserved-payment-external-id-${reservedPayment.id}`}
                      divider
                    >
                      <ListItemText
                        primary={t('orders:view_panel.external_id')}
                      />
                    </ListItemSkeleton>
                    <ListItemSkeleton
                      hide={!props.isLoading && !reservedPayment.refid}
                      secondaryAction={
                        <ListItemText>{reservedPayment.refid}</ListItemText>
                      }
                      isLoading={props.isLoading}
                      data-testid={`order-reserved-payment-reference-id-${reservedPayment.id}`}
                      divider
                    >
                      <ListItemText
                        primary={t('orders:view_panel.reference_id')}
                      />
                    </ListItemSkeleton>
                    <ListItemSkeleton
                      hide={!props.isLoading && !reservedPayment.cardissuer}
                      secondaryAction={
                        <ListItemText>
                          {reservedPayment.cardissuer}
                        </ListItemText>
                      }
                      isLoading={props.isLoading}
                      data-testid={`order-reserved-payment-card-issuer-${reservedPayment.id}`}
                      divider
                    >
                      <ListItemText
                        primary={t('orders:view_panel.card_issuer')}
                      />
                    </ListItemSkeleton>
                    <ListItemSkeleton
                      hide={!props.isLoading && additionalDataList.length === 0}
                      secondaryAction={additionalDataList.length ? null : '-'}
                      isLoading={props.isLoading}
                      data-testid={`order-reserved-payment-additional-data-${reservedPayment.id}`}
                      divider
                    >
                      <ListItemText
                        primary={t('orders:view_panel.additional_data_label')}
                        secondary={additionalDataList.map(([key, value]) => (
                          <Box
                            key={key}
                            component="span"
                            sx={{ display: 'block' }}
                          >
                            {key}: {value}
                          </Box>
                        ))}
                      />
                    </ListItemSkeleton>
                  </List>
                </ListItemAccordion>
                {i + 1 < reservedPayments.length && <Divider />}
              </Fragment>
            )
          })}
        </AccordionDetails>
      </Accordion>
    </>
  )
}
