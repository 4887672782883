import { MenuItem, TextField, Typography } from '@mui/material'
import { useContext, useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { FilterContext } from '../../../components/data-grid/context'
import { useStateParams } from '../../../hooks/state-params'
import { useTracking } from '../../../hooks/tracking'

type QueryParamsState = {
  status?: string
}

export enum Status {
  All = 'all',
  Reeddimable = 'redeemable',
  NonRedeemable = 'non-redeemable',
}

export const StatusFilter = () => {
  const { t } = useTranslation(['shared', 'influencer_codes'])
  const {
    hasApply,
    removeFilter,
    setFilter,
    subscribeOnResetFilter,
    registerFilter,
  } = useContext(FilterContext)
  const { trackButtonClickEvent } = useTracking()
  const filterKey = 'status'
  const options = useMemo(
    () =>
      Object.values(Status).map((x) => ({
        value: x,
        label: t(`influencer_codes:status_filter.${x}`),
      })),
    [t],
  )

  const [queryParams, setQueryParams] = useStateParams<QueryParamsState>()

  const selectedTransactionType = options.find(
    ({ value }) => value === queryParams.status,
  )
    ? queryParams.status
    : ''

  useEffect(() => {
    registerFilter({
      key: filterKey,
    })
  }, [registerFilter])

  useEffect(() => {
    const unsubscribe = subscribeOnResetFilter((key) => {
      if (!key || key === filterKey) {
        setQueryParams({ [filterKey]: undefined })
      }
    })
    return () => {
      unsubscribe()
    }
  }, [setQueryParams, subscribeOnResetFilter])

  useEffect(() => {
    const type = options?.find(({ value }) => value === selectedTransactionType)

    if (type) {
      setFilter(filterKey, {
        label: t('influencer_codes:status_filter_tag', {
          type: type.label,
        }),
        value: type.value,
      })
    } else {
      removeFilter(filterKey)
    }
  }, [removeFilter, setFilter, selectedTransactionType, options, t])

  return (
    <>
      <TextField
        select
        slotProps={{ select: { displayEmpty: true } }}
        onChange={(event) =>
          setQueryParams(
            { [filterKey]: event.target.value || undefined },
            hasApply !== true,
          )
        }
        value={selectedTransactionType || Status.All}
        label={t('influencer_codes:status_filter_tag')}
      >
        {options?.map((type) => (
          <MenuItem
            key={type.value}
            value={type.value || ''}
            divider
            onClick={trackButtonClickEvent({
              name: 'influencer-codes-filter-status',
              type: type.value,
            })}
          >
            <Typography variant="body02">{type.label}</Typography>
          </MenuItem>
        ))}
      </TextField>
    </>
  )
}
