import { makeVar, useQuery, useReactiveVar } from '@apollo/client'
import { Container, Grid } from '@mui/material'
import { WarehouseIcon } from '@sitoo/mui-components'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { makeVarPersisted } from '../../../cache/make-var-persisted'
import { FilterProvider } from '../../../components/data-grid/context'
import { CustomOverlay } from '../../../components/custom-overlay'
import { PageHeader } from '../../../components/page-header'
import { MeDocument } from '../../../generated/graphql'
import { useStateParams } from '../../../hooks/state-params'
import { usePageTitle } from '../../../hooks/title'
import { useTracking } from '../../../hooks/tracking'
import { StockLevelsFilterViewPanel } from './stock-levels-filter-view-panel'
import { StockLevelsList } from './stock-levels-list'
import { StoreFilter } from './store-filter'

type QueryParamsState = {
  storeId?: string
  warehouseId?: string
}

const prevStoreId = makeVarPersisted<number | undefined>(
  undefined,
  'apollo:stockLevelsStoreId',
)

const prevWarehouseId = makeVarPersisted<number | undefined>(
  undefined,
  'apollo:stockLevelsWarehouseId',
)

const stockLevelsFilterViewPanelVar = makeVar({
  isOpen: false,
})

export const StockLevelsPage = () => {
  const { t } = useTranslation(['stock'])
  usePageTitle(t('stock:stock_levels.title'))

  const { data: meData } = useQuery(MeDocument)
  const { trackButtonClick } = useTracking()
  const filterViewPanelState = useReactiveVar(stockLevelsFilterViewPanelVar)

  const [queryParams, setQueryParams] = useStateParams<QueryParamsState>({
    storeId: prevStoreId()?.toString(),
    warehouseId: prevWarehouseId()?.toString(),
  })

  const { warehouseId, storeId } = queryParams

  const hasWarehouses = !!meData?.me.warehouseIds.length

  const closeFilterViewPanel = () => {
    trackButtonClick({ name: 'stock-levels-filter-view-panel-close' })
    stockLevelsFilterViewPanelVar({ isOpen: false })
  }

  const openFilterViewPanel = () => {
    trackButtonClick({ name: 'stock-levels-filter-view-panel-open' })
    stockLevelsFilterViewPanelVar({ isOpen: true })
  }

  const toggleFilterViewPanel = () => {
    if (filterViewPanelState.isOpen) {
      closeFilterViewPanel()
    } else {
      openFilterViewPanel()
    }
  }

  useEffect(() => {
    prevStoreId(
      queryParams.storeId !== undefined
        ? Number(queryParams.storeId)
        : undefined,
    )
    prevWarehouseId(
      queryParams.warehouseId !== undefined
        ? Number(queryParams.warehouseId)
        : undefined,
    )
  }, [queryParams.storeId, queryParams.warehouseId])

  return (
    <FilterProvider>
      <Grid container flexWrap="nowrap">
        <Grid size="grow">
          <PageHeader
            title={t('stock:stock_levels.title')}
            isFlexible
            rightColumn={
              <StoreFilter
                storeId={storeId}
                warehouseId={warehouseId}
                setQueryParams={setQueryParams}
              />
            }
          />
          <Container maxWidth={false}>
            {!hasWarehouses ? (
              <CustomOverlay
                icon={<WarehouseIcon />}
                title={t('stock:empty_warehouses.title')}
                description={t('stock:empty_warehouses.description')}
                sx={{ position: 'relative' }}
              />
            ) : (
              <StockLevelsList
                warehouseId={warehouseId ? Number(warehouseId) : undefined}
                onShowFilter={toggleFilterViewPanel}
              />
            )}
          </Container>
        </Grid>
        <Grid>
          <StockLevelsFilterViewPanel
            isOpen={filterViewPanelState.isOpen}
            onClose={closeFilterViewPanel}
          />
        </Grid>
      </Grid>
    </FilterProvider>
  )
}
