import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { GeneralSettingsFormContext } from '../general-settings-form'
import { SettingsListSection } from '../../../../components/settings-list-section'
import { ListItemText } from '@mui/material'
import { WarehousesInput } from '../../../../inputs/warehouses-input'
import { SettingsListItem } from '../../../../components/settings-list-item'
import { TextFieldInput } from '../../../../inputs/text-field-input'

export const WarehouseSettings = () => {
  const { control } = useFormContext<GeneralSettingsFormContext>()
  const { t } = useTranslation(['shared', 'settings'])

  return (
    <SettingsListSection title={t('settings:general.warehouse_settings_title')}>
      <SettingsListItem>
        <ListItemText>
          <WarehousesInput
            name="warehouseid"
            control={control}
            label={t('settings:general.default_warehouse')}
            required
            textFieldProps={{
              helperText: t('settings:general.default_warehouse_helper_text'),
            }}
          />
        </ListItemText>
      </SettingsListItem>

      <SettingsListItem>
        <ListItemText>
          <TextFieldInput
            name="numdaysreserved"
            control={control}
            label={t('settings:general.reserve_products')}
            helperText={t('settings:general.reserve_products_helper_text')}
            type="number"
            rules={{ min: 1, max: 9999 }}
            transform={{
              output: (event) =>
                event.target.value ? Number(event.target.value) : null,
            }}
          />
        </ListItemText>
      </SettingsListItem>
    </SettingsListSection>
  )
}
