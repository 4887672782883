import { PinRange } from '../../../../generated/graphql'

/**
 * Format pin code ranges for the textarea
 */
export const formatBlockRanges = (ranges?: PinRange[]) => {
  if (!ranges) return ''

  return ranges?.map((range) => `${range.from}-${range.to}`).join('\n') || ''
}
