import { useMutation, useQuery } from '@apollo/client'
import { Button } from '@mui/material'
import { Divider, Container } from '@mui/material'
import { useSnackbar } from 'notistack'
import { useEffect } from 'react'
import { FormProvider, SubmitErrorHandler, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import { RootRoute } from '..'
import { PageHeader } from '../../components/page-header'
import {
  AddServiceOrderDocument,
  ServiceOrder,
  ServiceOrderDocument,
  ServiceOrderState,
  ServiceOrderUser,
  UpdateServiceOrderDocument,
} from '../../generated/graphql'
import { useAbsolutePath } from '../../hooks/absolute-path'
import { useMe } from '../../hooks/me'
import { usePageTitle } from '../../hooks/title'
import { useTracking } from '../../hooks/tracking'
import { serviceOrderViewPanelVar } from '../service-orders'
import { CustomerField } from './fields/customer-field'
import { InfoField } from './fields/info-field'
import { NoteField } from './fields/note-field'
import { ProductField } from './fields/product-field'
import { ServicesField } from './fields/services-field'
import { StoreField } from './fields/store-field'
import { BaseServiceOrderFormContext } from './shared'
import { FormRouteLeavingGuard } from '../../components/form-route-leaving-guard'
import { getErrorMessages } from '../../utils/error-mapping'

export const ServiceOrderPage = () => {
  const generatePath = useAbsolutePath()
  const { enqueueSnackbar } = useSnackbar()
  const { t } = useTranslation(['service_orders'])
  usePageTitle(t('page_title'))
  const navigate = useNavigate()
  const { trackButtonClick, trackFormError, trackFormSuccess } = useTracking()
  const { id: serviceOrderId } = useParams()
  const { me } = useMe()

  const defaultValues: BaseServiceOrderFormContext = {
    state: ServiceOrderState.New,
    currency: '',
    note: '',
    store: null,
    owner: {
      email: '',
      name: '',
      employee_id: '',
    },
    author: {
      email: '',
      name: '',
      employee_id: '',
    },
    customer: {
      first_name: '',
      last_name: '',
      email: '',
      mobile: '',
    },
    product: {
      sku: '',
      name: '',
      serial: '',
      services: [],
    },
  }

  const formContext = useForm<BaseServiceOrderFormContext>({
    defaultValues,
  })

  const { data, loading: serviceOrderLoading } = useQuery(
    ServiceOrderDocument,
    {
      variables: { serviceOrderId: String(serviceOrderId) },
      skip: !serviceOrderId,
    },
  )

  const [addServiceOrder, { loading: addLoading }] = useMutation(
    AddServiceOrderDocument,
  )
  const [updateServiceOrder, { loading: updateLoading }] = useMutation(
    UpdateServiceOrderDocument,
  )

  useEffect(() => {
    if (data) {
      formContext.reset(
        {
          ...data.serviceOrder,
          store: {
            ...data.serviceOrder.store,
            id: data.serviceOrder.store.id,
          },
        },
        { keepDefaultValues: false },
      )
    }
  }, [data, formContext])

  const generateNewServiceOrder = (
    submittedData: BaseServiceOrderFormContext,
  ): ServiceOrder => {
    const author: ServiceOrderUser = {
      email: me?.user?.email || '',
      employee_id: me?.user?.id,
      name: [me?.user?.namefirst, me?.user?.namelast].join(' '),
    }
    const currency = String(me?.currentSite.currencycode)

    return {
      ...submittedData,
      author,
      owner: author,
      currency,
      store: {
        id: submittedData.store?.id || null,
        name: submittedData.store?.name || null,
      },
    } as ServiceOrder
  }

  const generateUpdatedServiceOrder = (
    submittedData: BaseServiceOrderFormContext,
  ): ServiceOrder | undefined => {
    const {
      created,
      modified,
      customer: { id: customerId, ...customerFields },
      product: {
        id: productId,
        images,
        totalAfterShare,
        totalBeforeShare,
        ...productFields
      },
      ...restOfServiceOrder
    } = submittedData

    return {
      ...restOfServiceOrder,
      product: productFields,
      customer: customerFields,
      store: {
        id: submittedData.store?.id || null,
        name: submittedData.store?.name || null,
      },
    } as ServiceOrder
  }

  const onSubmit = async (submittedData: BaseServiceOrderFormContext) => {
    try {
      let serviceOrderIdToOpen = String(serviceOrderId)
      if (serviceOrderId) {
        trackButtonClick({ name: 'service-order-save' })
        const updatedServiceOrder = generateUpdatedServiceOrder(submittedData)

        if (updatedServiceOrder) {
          await updateServiceOrder({
            variables: {
              serviceOrder: {
                ...updatedServiceOrder,
                id: serviceOrderId,
              },
            },
          })
        }
      } else {
        trackButtonClick({ name: 'service-order-add' })
        const newServiceOrder = generateNewServiceOrder(submittedData)
        const { data: addedServiceOrderData } = await addServiceOrder({
          variables: {
            serviceOrder: { ...newServiceOrder },
          },
        })
        serviceOrderIdToOpen = String(addedServiceOrderData?.addServiceOrder.id)
      }

      trackFormSuccess({ name: 'service-order' })
      serviceOrderViewPanelVar({
        isOpen: true,
        serviceOrderId: serviceOrderIdToOpen,
      })
      enqueueSnackbar(
        t(
          serviceOrderId
            ? 'service_orders:service_order_message.success_update'
            : 'service_orders:service_order_message.success_add',
        ),
      )
      void navigate(generatePath(RootRoute.ServiceOrders))
    } catch (error) {
      const [firstErrorMessage] = getErrorMessages(error)

      trackFormError({
        name: 'service-orders',
        errorMessage: firstErrorMessage,
      })

      enqueueSnackbar(firstErrorMessage, { variant: 'error' })
    }
  }

  const onError: SubmitErrorHandler<BaseServiceOrderFormContext> = (
    _errors,
  ) => {
    enqueueSnackbar(t('service_orders:error.generic'), {
      variant: 'error',
    })
  }
  const isSubmitting = addLoading || updateLoading

  return (
    <>
      <PageHeader
        title={
          serviceOrderId
            ? t('edit_service_order_title')
            : t('add_service_order_title')
        }
        backTo={generatePath(RootRoute.ServiceOrders)}
        backText={t('shared:menu.service_orders')}
        showBackButton={true}
        isSticky
        rightColumn={
          <>
            <Button
              loading={isSubmitting}
              onClick={formContext.handleSubmit(onSubmit, onError)}
              data-testid="submit-service-order-form-button"
            >
              {t('shared:action.save')}
            </Button>
          </>
        }
      />

      <Container data-testid="service-order-page">
        <FormProvider {...formContext}>
          <FormRouteLeavingGuard />
          {data?.serviceOrder && (
            <InfoField
              serviceOrder={data.serviceOrder}
              isLoading={serviceOrderLoading}
            />
          )}
          <StoreField />
          <ProductField />
          <ServicesField />
          <Divider />
          <CustomerField />
          <Divider />
          <NoteField />
        </FormProvider>
      </Container>
    </>
  )
}
