import { useReactiveVar } from '@apollo/client'
import { Container, Grid } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { FilterProvider } from '../../components/data-grid/context'
import { PageHeader } from '../../components/page-header'
import { usePageTitle } from '../../hooks/title'
import { useTracking } from '../../hooks/tracking'
import { DEFAULT_ORDER_ID } from '../../utils/constants'
import { OrderSearchList } from './order-search-list'
import { OrderViewPanel } from '../orders/order-view-panel'
import { orderViewPanelVar, orderFilterViewPanelVar } from './util'
import { OrderFilterViewPanel } from './order-filter-view-panel'

export const OrderSearchPage = () => {
  const { t } = useTranslation(['orders', 'journals'])
  usePageTitle(t('orders:advanced_search_page_title'))
  const viewPanelState = useReactiveVar(orderViewPanelVar)
  const { isOpen } = useReactiveVar(orderFilterViewPanelVar)
  const { trackButtonClick } = useTracking()

  const openViewPanel = (orderId: number) => {
    if (isOpen) {
      closeFilterViewPanel()
    }

    trackButtonClick({ name: 'orders-view-panel-open', orderId })
    orderViewPanelVar({ isOpen: true, orderId: Number(orderId) })
  }

  const closeViewPanel = () => {
    trackButtonClick({ name: 'orders-view-panel-close' })
    orderViewPanelVar({ isOpen: false, orderId: DEFAULT_ORDER_ID })
  }

  const closeFilterViewPanel = () => {
    trackButtonClick({ name: 'orders-filter-view-panel-close' })
    orderFilterViewPanelVar({ isOpen: false })
  }

  const openFilterViewPanel = () => {
    trackButtonClick({ name: 'orders-filter-view-panel-open' })
    orderFilterViewPanelVar({ isOpen: true })
  }

  const toggleFilterViewPanel = () => {
    if (isOpen) {
      closeFilterViewPanel()
    } else {
      openFilterViewPanel()
    }
  }

  return (
    <FilterProvider hasApply>
      <Grid container flexWrap="nowrap">
        <Grid size="grow">
          <PageHeader
            title={t('orders:advanced_search_page_title')}
            isFlexible
          />

          <Container maxWidth={false}>
            <OrderSearchList
              onDetailOrder={openViewPanel}
              currentDetailedOrderId={viewPanelState.orderId}
              onShowFilter={toggleFilterViewPanel}
            />
          </Container>
        </Grid>

        <Grid>
          <OrderViewPanel
            viewPanelVar={orderViewPanelVar}
            onClose={closeViewPanel}
            readOnly
          />

          <OrderFilterViewPanel
            isOpen={isOpen}
            onClose={closeFilterViewPanel}
          />
        </Grid>
      </Grid>
    </FilterProvider>
  )
}
