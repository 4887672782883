import { MenuItem, TextField, Typography } from '@mui/material'
import { useContext, useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { FilterContext } from '../../../components/data-grid/context'
import { ArchivedFilterTypeV2 } from '../../../generated/graphql'
import { useStateParams } from '../../../hooks/state-params'
import { useTracking } from '../../../hooks/tracking'

type QueryParamsState = {
  showArchived?: ArchivedFilterTypeV2
}

export const ArchivedFilter = () => {
  const { t } = useTranslation('shipments_v2')
  const { setFilter, removeFilter, subscribeOnResetFilter, registerFilter } =
    useContext(FilterContext)

  const { trackButtonClickEvent } = useTracking()
  const filterKey = 'showArchived'
  const defaultValue = ArchivedFilterTypeV2.NUMBER_20

  const options = useMemo(
    () => [
      {
        name: t(
          `shipments_v2:archived_states.${ArchivedFilterTypeV2.NUMBER_20}`,
        ),
        value: defaultValue,
      },
      {
        name: t(
          `shipments_v2:archived_states.${ArchivedFilterTypeV2.NUMBER_30}`,
        ),
        value: ArchivedFilterTypeV2.NUMBER_30,
      },
    ],
    [t, defaultValue],
  )

  const [queryParams, setQueryParams] = useStateParams<QueryParamsState>()

  const archivedStatus = queryParams[filterKey]
    ? queryParams[filterKey]
    : undefined

  useEffect(() => {
    const unsubscribe = subscribeOnResetFilter((key) => {
      if (!key || key === filterKey) setQueryParams({ [filterKey]: undefined })
    })

    return () => unsubscribe()
  }, [setQueryParams, subscribeOnResetFilter])

  useEffect(() => {
    if (archivedStatus) {
      setFilter(filterKey, {
        isDefault: archivedStatus === defaultValue,
        label: t(`shipments_v2:archived_filter_tag_label`, {
          state: options.find((o) => String(o.value) === String(archivedStatus))
            ?.name,
        }),
        value: archivedStatus,
      })
    } else {
      setFilter(filterKey, {
        isDefault: true,
        label: t('shipments_v2:archived_filter_tag_label', {
          state: t(`shipments_v2:archived_states.${defaultValue}`),
        }),
        value: defaultValue,
      })
    }
  }, [archivedStatus, removeFilter, setFilter, options, t, defaultValue])

  useEffect(() => {
    registerFilter({
      key: filterKey,
    })
  }, [registerFilter])

  const selectedValue = useMemo(() => {
    return archivedStatus
      ? options.find((o) => String(o.value) === String(archivedStatus))?.value
      : defaultValue
  }, [options, archivedStatus, defaultValue])

  return (
    <TextField
      onChange={(event) => {
        setQueryParams({
          [filterKey as ArchivedFilterTypeV2]: event.target.value || undefined,
        })
      }}
      value={selectedValue}
      slotProps={{ select: { displayEmpty: true } }}
      select
      label={t('shipments_v2:archived_state')}
    >
      {options.map(({ name, value }) => (
        <MenuItem
          key={String(value)}
          value={value}
          divider
          onClick={trackButtonClickEvent({
            name: 'shipments-archived-filter',
            state: name,
          })}
        >
          <Typography variant="body02">{name}</Typography>
        </MenuItem>
      ))}
    </TextField>
  )
}
