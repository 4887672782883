import { SelectInput } from '../../../../inputs/select-input'
import { useTranslation } from 'react-i18next'
import { useFormContext } from 'react-hook-form'
import { Option } from '../../../../inputs/select-input'
import { ReportOrderState } from '../../../../generated/graphql'
import { Form } from '..'
import { useMemo } from 'react'
import { ListItem } from '@mui/material'

export const OrderStateInput = () => {
  const { t } = useTranslation(['reports', 'shared'])
  const { control } = useFormContext<Form>()

  const stringify = (option: string | string[]): string =>
    JSON.stringify(option)

  const orderStateOptions: Option[] = useMemo(
    () => [
      {
        name: t('order_state_keys.OpenClosed'),
        value: stringify([ReportOrderState.Open, ReportOrderState.Closed]),
      },
      {
        name: t('order_state_keys.Open'),
        value: stringify([ReportOrderState.Open]),
      },
      {
        name: t('order_state_keys.Closed'),
        value: stringify([ReportOrderState.Closed]),
      },
      {
        name: t('order_state_keys.Cancelled'),
        value: stringify([ReportOrderState.Cancelled]),
      },
    ],
    [t],
  )

  return (
    <ListItem>
      <SelectInput
        control={control}
        label={t('create_dialog.order_state')}
        name="reportdefinition.filters.orderState"
        options={orderStateOptions}
        transform={{
          input: (value) => {
            if (Array.isArray(value)) {
              return stringify(value)
            }

            return value
          },
          output: (event) => {
            return JSON.parse(event.target.value)
          },
        }}
      />
    </ListItem>
  )
}
