import { Autocomplete, TextField } from '@mui/material'
import { useContext, useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { FilterContext } from '../../../components/data-grid/context'
import { useStateParams } from '../../../hooks/state-params'
import { useTracking } from '../../../hooks/tracking'
import { AllPaymentTypesDocument } from '../../../generated/graphql'
import { addTagOnInputChange } from '../../../utils/autocomplete'
import { MAX_ITEMS_ORDER_API } from '.'
import { useQuery } from '@apollo/client'

type QueryParamsState = {
  paymentMethod?: string | string[]
}

export const PaymentMethodFilter = () => {
  const { t } = useTranslation(['shared', 'filter'])

  const {
    hasApply,
    setFilter,
    removeFilter,
    subscribeOnResetFilter,
    registerFilter,
  } = useContext(FilterContext)

  const { trackInputFocus, trackInputBlur } = useTracking()

  const filterKey = 'paymentMethod'

  const { data, loading: isLoading } = useQuery(AllPaymentTypesDocument)

  const options = useMemo(() => {
    return (
      data?.allPaymentTypes.map((paymentType) => ({
        label: paymentType.name,
        value: paymentType.paymenttypeid,
      })) ?? []
    )
  }, [data?.allPaymentTypes])

  const [queryParams, setQueryParams] = useStateParams<QueryParamsState>()

  const selectedPaymentMethods = useMemo(() => {
    if (Array.isArray(queryParams[filterKey])) {
      return options
        .filter((o) => queryParams[filterKey]?.includes(String(o.value)))
        .slice(-MAX_ITEMS_ORDER_API)
    }

    if (typeof queryParams[filterKey] === 'string') {
      return options.filter((o) => queryParams[filterKey] === String(o.value))
    }

    return []
  }, [options, queryParams])

  useEffect(() => {
    registerFilter({ key: filterKey, isReady: !isLoading })
  }, [isLoading, registerFilter])

  useEffect(() => {
    if (selectedPaymentMethods.length) {
      setFilter(filterKey, {
        label: t('filter:payment_method.label'),
        labelValues: selectedPaymentMethods.map(({ label }) => label),
        value: selectedPaymentMethods.map(({ value }) => value),
      })
    } else {
      removeFilter(filterKey)
    }
  }, [options, removeFilter, setFilter, t, selectedPaymentMethods])

  useEffect(() => {
    const unsubscribe = subscribeOnResetFilter((key) => {
      if (!key || key === filterKey) {
        setQueryParams({ [filterKey]: undefined })
      }
    })

    return () => unsubscribe()
  }, [setQueryParams, subscribeOnResetFilter])

  return (
    <Autocomplete
      autoHighlight
      multiple
      limitTags={3}
      fullWidth
      options={options}
      onChange={(_event, value) => {
        setQueryParams(
          { [filterKey]: value.map((v) => String(v?.value)) },
          hasApply !== true,
        )
      }}
      onInputChange={addTagOnInputChange}
      value={selectedPaymentMethods}
      data-testid="payment-method-field"
      getOptionDisabled={() =>
        selectedPaymentMethods.length >= MAX_ITEMS_ORDER_API
      }
      renderInput={(params) => (
        <TextField
          label={t('filter:payment_method.label')}
          type="text"
          {...params}
          onFocus={trackInputFocus({ name: `payment-method-${filterKey}` })}
          onBlur={trackInputBlur({ name: `payment-method-${filterKey}` })}
          slotProps={{
            htmlInput: {
              ...params.inputProps,
              ['data-testid']: 'payment-method-input',
            },
          }}
        />
      )}
    />
  )
}
