import { useFormContext } from 'react-hook-form'
import { Form } from '..'
import { TextFieldInput } from '../../../../inputs/text-field-input'
import { useTranslation } from 'react-i18next'
import { SettingsListSection } from '../../../../components/settings-list-section'
import { SettingsListItem } from '../../../../components/settings-list-item'

export const Name = () => {
  const { control } = useFormContext<Form>()
  const { t } = useTranslation(['pos_profiles'])

  return (
    <SettingsListSection>
      <SettingsListItem>
        <TextFieldInput
          control={control}
          label={t('pos_profiles:name_section.name_title')}
          name="name"
          required
        />
      </SettingsListItem>
    </SettingsListSection>
  )
}
