import { useQuery } from '@apollo/client'
import { SxProps, TextFieldProps, Theme } from '@mui/material'
import { useMemo } from 'react'
import { FieldPath, FieldValues, UseControllerProps } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { GetManufacturersDocument } from '../../generated/graphql'
import { AutocompleteInput } from '../autocomplete-input'

type Props<
  TFieldValues extends FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
> = UseControllerProps<TFieldValues, TName> & {
  multiple?: boolean
  label?: string
  helperText?: string
  sx?: SxProps<Theme>
  textFieldProps?: Omit<TextFieldProps, 'name' | 'required' | 'label'>
}

export const ManufacturersInput = <TFieldValues extends FieldValues>(
  props: Props<TFieldValues>,
) => {
  const { name, control, textFieldProps, ...autocompleteProps } = props
  const { t } = useTranslation('filter')

  const { data, loading } = useQuery(GetManufacturersDocument)

  const options = useMemo(
    () => data?.manufacturers.items?.map(({ id }) => id) || [],
    [data?.manufacturers.items],
  )

  return (
    <AutocompleteInput
      name={name}
      control={control}
      {...autocompleteProps}
      options={options}
      isLoading={loading}
      textFieldProps={textFieldProps}
      autocompleteProps={{
        noOptionsText: t('filter:manufacurers_input.no_options'),
        limitTags: 5,
        getOptionKey: (option) => option,
        getOptionLabel: (id) => {
          const option = data?.manufacturers.items?.find(
            (manufacturer) => manufacturer.id === id,
          )
          return option?.name || ''
        },
      }}
    />
  )
}
