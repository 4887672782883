import {
  FormControlLabel,
  FormControlLabelProps,
  Switch,
  SwitchProps,
  SxProps,
  Theme,
} from '@mui/material'
import { ChangeEvent } from 'react'
import {
  FieldPath,
  FieldValues,
  PathValue,
  useController,
  UseControllerProps,
} from 'react-hook-form'
import { useTracking } from '../../hooks/tracking'

type Props<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
> = SwitchProps & {
  label?: string
  labelPlacement?: FormControlLabelProps['labelPlacement']
  slotProps?: FormControlLabelProps['slotProps']
  sx?: SxProps<Theme>
  transform?: {
    input?: (value: PathValue<TFieldValues, TName>) => boolean
    output?: (
      event: ChangeEvent<HTMLInputElement>,
    ) => PathValue<TFieldValues, TName>
  }
}
export const SwitchInput = <TFieldValues extends FieldValues>(
  props: Props<TFieldValues> & UseControllerProps<TFieldValues>,
) => {
  const {
    control,
    name,
    disabled,
    label = '',
    labelPlacement = 'start',
    sx,
    slotProps,
    transform,
    ...switchProps
  } = props

  const { field, formState } = useController({ control, name })
  const { trackInputChange } = useTracking()

  const isDisabled = disabled || field.disabled || formState.isSubmitting

  const fieldValue = transform?.input?.(field.value) ?? field.value

  return (
    <FormControlLabel
      label={label}
      labelPlacement={labelPlacement}
      sx={{ mx: 0, ...sx }}
      slotProps={slotProps}
      disabled={isDisabled}
      control={
        <Switch
          {...switchProps}
          {...field}
          onChange={(event, value) => {
            const transformedValue = transform?.output?.(event) ?? value
            props.onChange?.(event, transformedValue)
            field.onChange(transformedValue)
            trackInputChange({ name, value: fieldValue })(event)
          }}
          checked={fieldValue}
          disabled={isDisabled}
        />
      }
    />
  )
}
