import { Button } from '@mui/material'
import { Paper, TextField, Typography, Box } from '@mui/material'
import { useCallback } from 'react'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useCookie } from 'react-use'
import { LoginForm } from '.'
import { useTracking } from '../../hooks/tracking'
import { ACCOUNT_ID_COOKIE } from './util'

type Props = {
  navigateToAccount(accountId?: number): void
  loading: boolean
}

export const AccountId = (props: Props) => {
  const { t } = useTranslation(['login'])
  const { register, getValues, formState } = useFormContext<LoginForm>()
  const [, updateCookie] = useCookie(ACCOUNT_ID_COOKIE)
  const { navigateToAccount, loading } = props

  const { trackButtonClickEvent, trackInputBlur, trackInputFocus } =
    useTracking()

  const onAccountIdSubmit = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      event.preventDefault()
      const accountId = getValues('login.accountId')

      updateCookie(String(accountId), {
        domain: `.${location.hostname}`,
        expires: 30,
      })

      navigateToAccount(accountId)
    },
    [getValues, navigateToAccount, updateCookie],
  )

  return (
    <Paper square elevation={0} sx={{ p: 5 }}>
      <Typography variant="displayMedium" sx={{ mb: 3 }}>
        {t('login:title')}
      </Typography>
      <TextField
        label={t('shared:label.account_id')}
        type="text"
        error={!!formState.errors.login?.accountId}
        helperText={formState.errors.login?.accountId?.message}
        {...register('login.accountId', {
          required: t('shared:validation.field_required', {
            field: t('shared:label.account_id'),
          }),
          onBlur: trackInputBlur({ name: 'login-account-id' }),
        })}
        slotProps={{ htmlInput: { 'data-testid': 'account-id-input' } }}
        autoComplete="on"
        onFocus={trackInputFocus({ name: 'login-account-id' })}
        sx={{ mb: 2 }}
      />
      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
        <Button
          onClick={trackButtonClickEvent(
            { name: 'login-submit-account-id' },
            onAccountIdSubmit,
          )}
          type="submit"
          disabled={loading}
          loading={loading}
          size="large"
          data-testid="login-button"
        >
          {t('shared:action.next')}
        </Button>
      </Box>
    </Paper>
  )
}
