export function validateEmail(value: unknown): boolean {
  const EMAIL_REGEX = /\S+@\S+\.\S+/

  if (typeof value !== 'string') {
    return false
  }

  return EMAIL_REGEX.test(value)
}

export function validateCoordinate(value: unknown): boolean {
  const COORDINATE_REGEX = /^[-+]?[0-9]{1,3}\.[0-9]+$/

  if (typeof value !== 'string') {
    return false
  }

  return COORDINATE_REGEX.test(value)
}
