import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { GeneralSettingsFormContext } from '../general-settings-form'
import { SettingsListSection } from '../../../../components/settings-list-section'
import { ListItemText } from '@mui/material'
import { SelectInput } from '../../../../inputs/select-input'
import {
  GetConfigVarsDocument,
  PosPermissionOptionLevel,
} from '../../../../generated/graphql'
import { SwitchInput } from '../../../../inputs/switch-input'
import { useQuery } from '@apollo/client'
import { SettingsListItem } from '../../../../components/settings-list-item'

type Props = {
  role: 'store_staff' | 'store_manager'
}

export const StoreStaffPermissions = (props: Props) => {
  const { role } = props
  const { control } = useFormContext<GeneralSettingsFormContext>()
  const { t } = useTranslation(['shared', 'settings'])
  const { data } = useQuery(GetConfigVarsDocument)

  const showSalesTaxOption = data?.configVars?.salesTax ?? false

  const permissionOptions = Object.values(PosPermissionOptionLevel).map(
    (level) => ({
      value: level,
      name: t(`settings:general.permissions.${level}`),
    }),
  )

  const permissionOptionsBoolean = permissionOptions.filter(
    (option) =>
      option.value === PosPermissionOptionLevel.On ||
      option.value === PosPermissionOptionLevel.Off,
  )

  return (
    <SettingsListSection
      title={t(`settings:general.permissions.${role}_permissions_title`)}
    >
      <SettingsListItem>
        <SelectInput
          name={`permissions.${role}.manual_price`}
          label={t('settings:general.permissions.manual_price_adjustments')}
          helperText={t(
            'settings:general.permissions.manual_price_adjustments_helper',
          )}
          control={control}
          options={permissionOptions}
        />
      </SettingsListItem>
      <SettingsListItem>
        <SelectInput
          label={t('settings:general.permissions.validated_returns')}
          helperText={t(
            'settings:general.permissions.validated_returns_helper',
          )}
          name={`permissions.${role}.validated_return`}
          control={control}
          options={permissionOptions}
        />
      </SettingsListItem>
      <SettingsListItem>
        <SelectInput
          label={t(
            'settings:general.permissions.validated_returns_all_payment_options',
          )}
          helperText={t(
            'settings:general.permissions.validated_returns_all_payment_options_helper',
          )}
          name={`permissions.${role}.validated_return_any_payment`}
          control={control}
          options={permissionOptionsBoolean}
        />
      </SettingsListItem>
      <SettingsListItem>
        <SelectInput
          label={t('settings:general.permissions.manual_returns')}
          helperText={t('settings:general.permissions.manual_returns_helper')}
          name={`permissions.${role}.manual_return`}
          control={control}
          options={permissionOptions}
        />
      </SettingsListItem>
      <SettingsListItem>
        <SelectInput
          label={t('settings:general.permissions.override_return_policy')}
          helperText={t(
            'settings:general.permissions.override_return_policy_helper',
          )}
          name={`permissions.${role}.override_return_policy`}
          control={control}
          options={permissionOptions}
        />
      </SettingsListItem>
      {showSalesTaxOption && (
        <SettingsListItem>
          <SelectInput
            label={t('settings:general.permissions.salestax_exemption')}
            helperText={t(
              'settings:general.permissions.salestax_exemption_helper',
            )}
            name={`permissions.${role}.salestax_exemption`}
            control={control}
            options={permissionOptionsBoolean}
          />
        </SettingsListItem>
      )}
      <SettingsListItem>
        <SelectInput
          label={t(
            'settings:general.permissions.explicit_opening_of_cash_drawer',
          )}
          helperText={t(
            'settings:general.permissions.explicit_opening_of_cash_drawer_helper',
          )}
          name={`permissions.${role}.explicit_open_cash_drawer`}
          control={control}
          options={permissionOptionsBoolean}
        />
      </SettingsListItem>
      <SettingsListItem>
        <SelectInput
          label={t('settings:general.permissions.cash_management')}
          helperText={t('settings:general.permissions.cash_management_helper')}
          name={`permissions.${role}.cash_management`}
          control={control}
          options={permissionOptions}
        />
      </SettingsListItem>
      <SettingsListItem divider>
        <SelectInput
          label={t('settings:general.permissions.inventory_management')}
          helperText={t(
            'settings:general.permissions.inventory_management_helper',
          )}
          name={`permissions.${role}.inventory_management`}
          control={control}
          options={permissionOptions}
        />
      </SettingsListItem>
      {role === 'store_manager' && (
        <SettingsListItem
          dense
          secondaryAction={
            <SwitchInput
              name="allow_store_manager_campaigns"
              control={control}
              aria-label={t('settings:general.permissions.local_campaigns')}
            />
          }
        >
          <ListItemText
            primary={t('settings:general.permissions.local_campaigns')}
            secondary={t('settings:general.permissions.local_campaigns_helper')}
          />
        </SettingsListItem>
      )}
    </SettingsListSection>
  )
}
