import { ListItem } from '@mui/material'
import { SelectInput, Option } from '../../../../inputs/select-input'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useMemo } from 'react'
import { Form } from '..'
import { WarehouseTransactionType } from '../../../../generated/graphql'

export const SalesTaxTransactionTypeInput = () => {
  const { control } = useFormContext<Form>()
  const { t } = useTranslation('reports')

  const salesTaxTransactionTypes = useMemo<Option[]>(
    () => [
      {
        name: t(
          `warehouse_transaction_type_keys.${WarehouseTransactionType.Refund}`,
        ),
        value: true,
      },
      {
        name: t(
          `warehouse_transaction_type_keys.${WarehouseTransactionType.Sales}`,
        ),
        value: false,
      },
    ],
    [t],
  )

  return (
    <ListItem>
      <SelectInput
        control={control}
        label={t('create_dialog.transaction_type')}
        name="reportdefinition.filters.salesTaxIsRefund"
        options={salesTaxTransactionTypes}
        slotProps={{ select: { displayEmpty: true } }}
      />
    </ListItem>
  )
}
