import { SxProps } from '@mui/material'
import {
  Control,
  FieldValues,
  Path,
  PathValue,
  RegisterOptions,
  useController,
} from 'react-hook-form'
import { YamlEditor } from '../../components/yaml-editor'
import yaml from 'js-yaml'
import { useTranslation } from 'react-i18next'

type YamlValidateFn = (parsedYaml: unknown) => boolean | string

interface YamlRules<
  TFieldValues extends FieldValues,
  TName extends Path<TFieldValues>,
> extends Omit<
    RegisterOptions<TFieldValues, TName>,
    'valueAsNumber' | 'valueAsDate' | 'setValueAs'
  > {
  validateParsed?: YamlValidateFn
}

type Props<
  TFieldValues extends FieldValues,
  TName extends Path<TFieldValues>,
> = {
  name: TName
  control: Control<TFieldValues>
  sx?: SxProps
  rules?: YamlRules<TFieldValues, TName>
  required?: boolean
  label?: string
  helperText?: string
}

export const YamlInput = <
  TFieldValues extends FieldValues,
  TName extends Path<TFieldValues>,
>({
  control,
  name,
  sx,
  rules,
  required,
  label,
  helperText,
}: Props<TFieldValues, TName>) => {
  const { t } = useTranslation(['filter'])

  const { validateParsed, ...restRules } = rules || {}
  const validationRules = {
    ...restRules,
    required: required
      ? t('shared:validation.field_required', {
          field: label ?? name,
        })
      : undefined,
    validate: (value: PathValue<TFieldValues, TName>) => {
      try {
        if (!value || value.trim() === '') {
          return true
        }

        const parsedYaml = yaml.load(value)

        if (validateParsed) {
          return (
            validateParsed(parsedYaml) ||
            t('filter:yaml_input.invalid_yaml_structure')
          )
        }

        return true
      } catch (error) {
        return t('filter:yaml_input.invalid_yaml_format')
      }
    },
  }
  const {
    field,
    fieldState: { error },
  } = useController({
    name,
    control,
    rules: validationRules,
  })

  return (
    <YamlEditor
      {...field}
      ref={(ref) => {
        if (ref) {
          field.ref({
            focus: () => {
              ref?.refEditor?.scrollIntoView({
                block: 'center',
              })
            },
          })
        }
      }}
      value={field.value}
      error={!!error}
      helperText={error?.message ?? helperText}
      sx={{
        width: '100%',
        my: 2,
        '.MuiFormHelperText-root': {
          whiteSpace: 'pre-line',
        },
        ...sx,
      }}
    />
  )
}
