import { useEffect } from 'react'
import { Control, FieldValues, useFormState } from 'react-hook-form'
import { useTracking } from '../tracking'

type Props<TFieldValues extends FieldValues = FieldValues> = {
  name: string
  control: Control<TFieldValues>
}

/**
 * Send analytics events when a form is submitted successfully or with errors.
 */
export function useFormAnalytics<
  TFieldValues extends FieldValues = FieldValues,
>(props: Props<TFieldValues>) {
  const { control, name } = props

  const { trackFormSuccess, trackFormError } = useTracking()

  const { isSubmitted, isSubmitSuccessful, errors } = useFormState({ control })

  useEffect(() => {
    if (isSubmitSuccessful) {
      trackFormSuccess({ name })
    }

    if (isSubmitted && Object.values(errors).length) {
      trackFormError({ name })
    }
  }, [
    errors,
    isSubmitSuccessful,
    isSubmitted,
    name,
    trackFormError,
    trackFormSuccess,
  ])
}
