import { makeVar, useReactiveVar } from '@apollo/client'
import { Container, Grid } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { FilterProvider } from '../../../components/data-grid/context'
import { PageHeader } from '../../../components/page-header'
import { usePageTitle } from '../../../hooks/title'
import { useTracking } from '../../../hooks/tracking'
import { PurchasePricesFilterViewPanel } from './purchase-prices-filter-view-panel'
import { PurchasePricesList } from './purchase-prices-list'

const purchasePricesFilterViewPanelVar = makeVar({
  isOpen: false,
})

export const PurchasePricesPage = () => {
  const { t } = useTranslation(['purchase_prices', 'prices'])
  usePageTitle(t('purchase_prices:page_title'))

  const filterViewPanelState = useReactiveVar(purchasePricesFilterViewPanelVar)
  const { trackButtonClick } = useTracking()

  const closeFilterViewPanel = () => {
    trackButtonClick({ name: 'purchase-prices-filter-view-panel-close' })
    purchasePricesFilterViewPanelVar({ isOpen: false })
  }

  const openFilterViewPanel = () => {
    trackButtonClick({ name: 'purchase-prices-filter-view-panel-open' })
    purchasePricesFilterViewPanelVar({ isOpen: true })
  }

  const toggleFilterViewPanel = () => {
    if (filterViewPanelState.isOpen) {
      closeFilterViewPanel()
    } else {
      openFilterViewPanel()
    }
  }

  return (
    <FilterProvider>
      <Grid container flexWrap="nowrap">
        <Grid size="grow">
          <PageHeader title={t('purchase_prices:page_title')} isFlexible />
          <Container maxWidth={false}>
            <PurchasePricesList onShowFilter={toggleFilterViewPanel} />
          </Container>
        </Grid>
        <Grid>
          <PurchasePricesFilterViewPanel
            isOpen={filterViewPanelState.isOpen}
            onClose={closeFilterViewPanel}
          />
        </Grid>
      </Grid>
    </FilterProvider>
  )
}
