import { SxProps, TextFieldProps, Theme } from '@mui/material'
import { useMemo } from 'react'
import {
  FieldPath,
  FieldValues,
  PathValue,
  UseControllerProps,
} from 'react-hook-form'
import { AutocompleteInput } from '../autocomplete-input'
import { getAllCurrencies, getCurrencyName } from '../../i18n/currencies'

type Props<
  TFieldValues extends FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
> = UseControllerProps<TFieldValues, TName> & {
  label?: string
  helperText?: string
  sx?: SxProps<Theme>
  textFieldProps?: Omit<TextFieldProps, 'name' | 'required' | 'label'>
  rules?: UseControllerProps<TFieldValues, TName>['rules']
  required?: boolean
  ref?: React.Ref<HTMLElement>
}

export const CurrencyInput = <TFieldValues extends FieldValues>(
  props: Props<TFieldValues>,
) => {
  const {
    name,
    control,
    textFieldProps,
    label,
    helperText,
    ...autocompleteProps
  } = props

  const options = useMemo(() => {
    return getAllCurrencies().map((currency) => ({
      label: `${currency} - ${getCurrencyName(currency)}`,
      value: currency,
    }))
  }, [])

  return (
    <AutocompleteInput
      name={name}
      control={control}
      {...autocompleteProps}
      options={options}
      label={label}
      textFieldProps={{
        inputRef: props.ref,
        helperText,
        ...textFieldProps,
      }}
      transform={{
        input: (value) => options.find((option) => option?.value === value),
        output: (option) => {
          return option?.value as PathValue<TFieldValues, typeof name>
        },
      }}
    />
  )
}
