import { useMutation } from '@apollo/client'
import {
  Button,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material'
import { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { usePrevious } from 'react-use'
import {
  GetProductsQuery,
  UpdatePricelistItemsDocument,
} from '../../../../generated/graphql'
import { useMoney } from '../../../../hooks/money'
import { useTracking } from '../../../../hooks/tracking'
import { ArrayElement } from '../../../../utils/types'

export type SetPriceDialogProps = {
  open: boolean
  products: Pick<
    ArrayElement<GetProductsQuery['products']['items']>,
    'id' | 'sku'
  >[]
  pricelistId: number
  productSiteId?: number
  dataTestid?: string
  onClose(): void
  onSuccess?(successfulProductIds: number[], failedProductIds: number[]): void
  onError?(successfulProductIds: number[], failedProductIds: number[]): void
}

// enum FieldType {
//   Price,
// }

type Form = {
  // action: FieldType
  value: string
}

export const SetPriceDialog = (props: SetPriceDialogProps) => {
  const { t } = useTranslation(['prices', 'shared'])
  const dialogName = 'set-price'
  const {
    trackButtonClickEvent,
    trackDialogClose,
    trackDialogOpen,
    trackFormError,
    trackFormSuccess,
  } = useTracking()
  const prevOpen = usePrevious(props.open)
  const [updatePricelistItems, { loading: isLoading }] = useMutation(
    UpdatePricelistItemsDocument,
  )
  const formContext = useForm<Form>({
    mode: 'onChange',
  })

  const { formatEditableCurrency, formatStringCurrencyToNumber } = useMoney()

  const onSubmit = async (formData: Form) => {
    const successfulProductIds: number[] = []
    const failedProductIds: number[] = []

    try {
      await updatePricelistItems({
        variables: {
          pricelistId: props.pricelistId,
          productSiteId: props.productSiteId,
          items: props.products.map(({ id, sku }) => ({
            productid: id,
            moneyprice: formatStringCurrencyToNumber(formData.value).toFixed(2),
            sku,
          })),
        },
      })

      trackFormSuccess({ name: `${dialogName}-dialog` })
      if (props.onSuccess) {
        props.onSuccess(successfulProductIds, failedProductIds)
      }
    } catch {
      trackFormError({ name: `${dialogName}-dialog` })
      if (props.onError) {
        props.onError(successfulProductIds, failedProductIds)
      }
    } finally {
      formContext.reset()
    }
  }

  const onClose = () => {
    if (!isLoading) {
      trackDialogClose({ name: dialogName })
      if (props.onClose) {
        props.onClose()
      }
    }
  }

  useEffect(() => {
    if (props.open && !prevOpen) {
      trackDialogOpen({ name: dialogName })
      formContext.reset()
    }
  }, [props.open, prevOpen, trackDialogOpen, formContext])

  return (
    <Dialog
      open={props.open}
      onClose={onClose}
      maxWidth="sm"
      fullWidth
      data-testid={props.dataTestid}
    >
      <form onSubmit={formContext.handleSubmit(onSubmit)}>
        <DialogTitle
          type="extended"
          sx={{
            paddingBottom: (theme) => theme.spacing(2),
          }}
        >
          {t('prices:set_price_dialog.dialog_title')}
        </DialogTitle>
        <DialogContent>
          {/* <Controller
            control={formContext.control}
            name="action"
            defaultValue={FieldType.Price}
            render={({ field }) => (
              <>
                <InputLabel>
                  {t('prices:set_price_dialog.field_label')}
                </InputLabel>
                <Select
                  value={field.value}
                  onChange={(event) => {
                    formContext.resetField('action', {
                      defaultValue: [],
                    })
                    field.onChange(event.target.value)
                  }}
                >
                  <MenuItem value={FieldType.Price}>
                    {t('prices:set_price_dialog.price')}
                  </MenuItem>
                </Select>
              </>
            )}
          /> */}

          <TextField
            {...formContext.register('value', {
              required: t('shared:validation.field_required', {
                field: t('prices:set_price_dialog.price'),
              }),
              validate: (v) =>
                Number.isNaN(formatStringCurrencyToNumber(v))
                  ? t('shared:validation.field_invalid', {
                      field: t('prices:set_price_dialog.price'),
                    })
                  : true,
            })}
            label={t('prices:set_price_dialog.price')}
            placeholder={formatEditableCurrency(0)}
            slotProps={{
              htmlInput: {
                ['data-testid']: 'price-field',
              },
            }}
            error={!!formContext.formState.errors.value?.message}
            helperText={formContext.formState.errors.value?.message}
          />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={trackButtonClickEvent(
              {
                name: `${dialogName}-dialog-cancel`,
              },
              onClose,
            )}
            color="secondary"
            size="small"
            disabled={isLoading}
            type="button"
          >
            {t('shared:action.cancel')}
          </Button>
          <Button
            type="submit"
            size="small"
            loading={isLoading}
            onClick={trackButtonClickEvent({
              name: `${dialogName}-dialog-set`,
            })}
            disabled={!formContext.formState.isValid}
            data-testid="set-price"
          >
            {t('shared:action.set')}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  )
}
