import { DateRangePickerInput } from '../report-inputs/date-range-picker-input'
import { ManufacturerInput } from '../report-inputs/manufacturer-input'
import { ProductGroupInput } from '../report-inputs/product-group-input'
import { ReportSortInput } from '../report-inputs/report-sort-input'
import { SearchTextInput } from '../report-inputs/search-text-input'
import { WarehouseInput } from '../report-inputs/warehouse-input'
import { WarehouseTransactionTypeInput } from '../report-inputs/warehouse-transaction-type-input'

export const WarehouseReportFields = () => {
  return (
    <>
      <ReportSortInput />
      <SearchTextInput searchTextFilterKey="warehouseSearchText" />
      <DateRangePickerInput
        dateStartKey="warehouseDateStart"
        dateEndKey="warehouseDateEnd"
      />
      <WarehouseInput warehouseFilterKey="warehouseId" />
      <WarehouseTransactionTypeInput />
      <ManufacturerInput />
      <ProductGroupInput />
    </>
  )
}
