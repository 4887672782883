import { GetUsersQuery } from '../../../generated/graphql'
import { GridRenderCellParams } from '@mui/x-data-grid-pro'
import { ArrayElement } from '../../../utils/types'
import { Indicator } from '../../../components/indicator'
import { useTranslation } from 'react-i18next'
import { useMe } from '../../../hooks/me'

export const MfaListRenderer = (
  params: GridRenderCellParams<ArrayElement<GetUsersQuery['users']['items']>>,
) => {
  const { me, isAdmin } = useMe()
  const { t } = useTranslation(['users'])
  const isSelf = me?.user?.id === params.row.id
  const hasMfa = !!params.row.hasmfa

  if (!params.row.role) {
    return null
  }

  if (!isAdmin && !isSelf) {
    return null
  }

  return (
    <Indicator active={hasMfa}>
      {hasMfa ? t('users:mfa_active') : t('users:mfa_not_active')}
    </Indicator>
  )
}
