import { useMutation, useQuery } from '@apollo/client'
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  List,
  Typography,
} from '@mui/material'
import { Trans, useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import {
  DeleteReportDocument,
  ReportsDocument,
} from '../../../generated/graphql'
import { useAbsolutePath } from '../../../hooks/absolute-path'

import { ConfirmationDialog, SearchIcon } from '@sitoo/mui-components'
import { enqueueSnackbar } from 'notistack'
import { useCallback, useState } from 'react'
import { RootRoute } from '../..'
import { useFormFilter } from '../../../hooks/form-filter'
import { TextFieldInput } from '../../../inputs/text-field-input'
import { getErrorMessages } from '../../../utils/error-mapping'
import { ReportTemplateListItem } from './report-template-list-item'
import { useTracking } from '../../../hooks/tracking'

const PAGE_SIZE = 100
type Form = {
  searchText: string
}
export const ManageReportTemplatesDialog = () => {
  const { t } = useTranslation(['reports', 'shared'])
  const navigate = useNavigate()
  const generatePath = useAbsolutePath()
  const [reportToDelete, setReportToDelete] = useState<number>()
  const { trackButtonClick } = useTracking()

  const { queryParams, formContext } = useFormFilter<Form>({
    persistState: false,
    formProps: {
      defaultValues: {
        searchText: '',
      },
    },
  })

  const { searchText } = queryParams

  const {
    data,
    loading: isLoadingReports,
    fetchMore,
  } = useQuery(ReportsDocument, {
    fetchPolicy: 'cache-and-network',
    notifyOnNetworkStatusChange: true,
    variables: {
      searchtext: searchText,
      num: PAGE_SIZE,
    },
  })

  const nextPageStart = (data?.reports?.start ?? 0) + PAGE_SIZE
  const totalCount = data?.reports?.totalcount ?? 0
  const hasMoreItems = nextPageStart <= totalCount

  const fetchMoreItems = useCallback(() => {
    fetchMore({
      variables: {
        start: nextPageStart,
        num: PAGE_SIZE,
      },
    }).catch(() => {
      enqueueSnackbar(t('shared:error.default'), { variant: 'error' })
    })
  }, [nextPageStart, fetchMore, t])

  const [deleteReport] = useMutation(DeleteReportDocument, {
    refetchQueries: [ReportsDocument],
  })

  const handleOnDelete = async () => {
    if (!reportToDelete) {
      setReportToDelete(undefined)
      return
    }

    try {
      await deleteReport({ variables: { reportId: reportToDelete } })
      enqueueSnackbar(t('reports:manage_templates.delete_success'), {
        variant: 'success',
      })
      trackButtonClick({ name: 'manage-report-templates-delete' })
    } catch (error) {
      enqueueSnackbar(getErrorMessages(error)[0], { variant: 'error' })
    }
    setReportToDelete(undefined)
  }

  const shouldShowTotalCount = data?.reports?.items?.length && totalCount
  return (
    <>
      <ConfirmationDialog
        open={!!reportToDelete}
        title={t('reports:manage_templates.delete_title')}
        text={t('reports:manage_templates.delete_text')}
        onClose={() => setReportToDelete(undefined)}
        confirmAction={handleOnDelete}
        variant="destructive"
      />
      <Dialog
        open={!reportToDelete}
        onClose={() => navigate(generatePath(RootRoute.ReportJobs))}
        fullWidth
        maxWidth="xs"
      >
        <DialogTitle>{t('reports:manage_templates.title')}</DialogTitle>
        <Box sx={{ mx: 3, my: 1 }}>
          <TextFieldInput
            name="searchText"
            control={formContext.control}
            placeholder={t('create.search_placeholder')}
            sx={{ background: 'paper' }}
            slotProps={{
              input: {
                startAdornment: <SearchIcon fontSize="medium" />,
                autoComplete: 'off',
              },
            }}
          />
        </Box>

        <Box sx={{ position: 'relative' }}>
          {isLoadingReports && (
            <Box
              sx={{
                width: '100%',
                height: '100%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                position: 'absolute',
                pointerEvents: 'none',
                top: 0,
                backgroundColor: 'white',
                opacity: 0.5,
                zIndex: 1,
              }}
            >
              <CircularProgress />
            </Box>
          )}
          <DialogContent sx={{ p: 0, height: '50vh' }}>
            <Box sx={{ px: 3 }}>
              <List sx={{ '.MuiListItem-root': { p: 0 } }}>
                {data?.reports?.items?.map((report) => (
                  <ReportTemplateListItem
                    key={report.reportid}
                    report={report}
                    setReportToDelete={setReportToDelete}
                  />
                ))}
              </List>
            </Box>
            {data?.reports?.items?.length === 0 && (
              <Box
                sx={{
                  width: '100%',
                  height: '100%',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <Typography
                  variant="body01"
                  sx={{ textAlign: 'center', color: 'text.secondary' }}
                >
                  {searchText
                    ? t('reports:manage_templates.no_results')
                    : t('reports:manage_templates.no_templates')}
                </Typography>
              </Box>
            )}
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                flex: 1,
                px: 3,
                mb: 1,
              }}
            >
              {hasMoreItems && (
                <Button
                  color="secondary"
                  loading={isLoadingReports}
                  onClick={fetchMoreItems}
                  disabled={!hasMoreItems || isLoadingReports}
                >
                  {t('create.load_more')}
                </Button>
              )}

              {shouldShowTotalCount ? (
                <Typography variant="caption" sx={{ mt: 0.5 }} color="gray70">
                  <Trans
                    i18nKey="shared:grid.showing_of_total"
                    values={{
                      rows: data?.reports?.items?.length,
                      totalRows: totalCount,
                    }}
                    components={{ b: <b /> }}
                  />
                </Typography>
              ) : null}
            </Box>
          </DialogContent>
        </Box>

        <DialogActions>
          <Button
            color="secondary"
            onClick={() => navigate(generatePath(RootRoute.ReportJobs))}
          >
            {t('shared:action.close')}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}
