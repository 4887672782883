import { Box, CircularProgress } from '@mui/material'
import { NAVBAR_HEIGHT, PAGE_BOTTOM_OFFSET } from '../../utils/constants'

export const PageLoader = () => {
  return (
    <Box
      sx={{
        height: `calc(100vh - ${NAVBAR_HEIGHT}px - ${PAGE_BOTTOM_OFFSET}px)`,
        width: '100%',
        display: 'grid',
        alignItems: 'center',
        justifyItems: 'center',
      }}
    >
      <CircularProgress />
    </Box>
  )
}
