import { useQuery } from '@apollo/client'

import { useMemo } from 'react'
import { SettingsListItem } from '../../../../../../components/settings-list-item'
import {
  AllReasonCodesDocument,
  ReasonCodeType,
} from '../../../../../../generated/graphql'
import { SelectInput, Option } from '../../../../../../inputs/select-input'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { Form } from '../../..'

export const ReasonCodeField = () => {
  const { t } = useTranslation(['pos_profiles'])
  const { control } = useFormContext<Form>()
  const { data } = useQuery(AllReasonCodesDocument, {
    variables: { type: ReasonCodeType.ItemRefund },
  })

  const shouldShowReasonCodeInput =
    data?.allReasonCodes && data?.allReasonCodes.length > 0

  const rebuyReasonCodeOptions: Option[] = useMemo(() => {
    const reasonCodes =
      data?.allReasonCodes
        .filter((reasonCode) => reasonCode?.name)
        .map((reasonCode) => ({
          name: reasonCode.name!,
          value: reasonCode.reasoncode,
        })) || []

    return [
      {
        name: t('pos_profiles:pos_options_section.rebuy_reason_code_default'),
        value: null,
      },
      ...reasonCodes,
    ]
  }, [data?.allReasonCodes, t])

  if (!shouldShowReasonCodeInput) return null

  return (
    <SettingsListItem>
      <SelectInput
        label={t('pos_profiles:pos_options_section.rebuy_reason_code_label')}
        helperText={t(
          'pos_profiles:pos_options_section.rebuy_reason_code_description',
        )}
        name={'rebuy_reasoncode'}
        control={control}
        options={rebuyReasonCodeOptions}
        slotProps={{ select: { displayEmpty: true } }}
      />
    </SettingsListItem>
  )
}
