import { useMutation } from '@apollo/client'
import { ConfirmationDialog } from '@sitoo/mui-components'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { usePrevious } from 'react-use'
import { DeleteCampaignDocument } from '../../../generated/graphql'
import { useTracking } from '../../../hooks/tracking'
import { reactiveCampaignViewPanelVar } from '..'

export type DeleteCampaignDialogProps = {
  open: boolean
  campaignIds: number[]
  dataTestid?: string
  onClose?(): void
  onSuccess?(successfulCampaignIds: number[], failedCampaignIds: number[]): void
  onError?(successfulCampaignIds: number[], failedCampaignIds: number[]): void
}

export const DeleteCampaignDialog = (props: DeleteCampaignDialogProps) => {
  const { t } = useTranslation('campaigns')
  const dialogName = 'delete-campaign'
  const [deleteMutation, { loading }] = useMutation(DeleteCampaignDocument)
  const {
    trackButtonClickEvent,
    trackDialogOpen,
    trackDialogClose,
    trackFormSuccess,
    trackFormError,
  } = useTracking()
  const prevOpen = usePrevious(props.open)

  const deleteCampaigns = async () => {
    const successfulCampaignIds: number[] = []
    const failedCampaignIds: number[] = []
    const batchSize = 10

    reactiveCampaignViewPanelVar({ isOpen: false })
    trackButtonClickEvent({ name: `${dialogName}-dialog-delete` })

    try {
      for (
        let i = 0;
        i < Math.ceil(props.campaignIds.length / batchSize);
        i++
      ) {
        const startBatch = i * batchSize
        const campaignIds = props.campaignIds.slice(
          startBatch,
          startBatch + batchSize,
        )

        await Promise.allSettled(
          campaignIds.map(async (id) => {
            const mutationResponse = await deleteMutation({
              variables: {
                data: {
                  voucherid: id,
                },
              },
            })

            if (mutationResponse.data?.deleteCampaign) {
              successfulCampaignIds.push(id)
            } else {
              failedCampaignIds.push(id)
            }
          }),
        )
      }

      trackFormSuccess({
        name: `${dialogName}-dialog`,
      })

      if (props.onSuccess) {
        props.onSuccess(successfulCampaignIds, failedCampaignIds)
      }
    } catch (ex) {
      trackFormError({
        name: `${dialogName}-dialog`,

        errorMessage: ex instanceof Error ? ex.message : undefined,
      })

      if (props.onError) {
        props.onError(successfulCampaignIds, failedCampaignIds)
      }
    }
  }

  const onClose = () => {
    if (!loading) {
      trackDialogClose({ name: dialogName })
      if (props.onClose) {
        props.onClose()
      }
    }
  }

  useEffect(() => {
    if (props.open && !prevOpen) {
      trackDialogOpen({ name: dialogName })
    }
  }, [props.open, prevOpen, trackDialogOpen])

  return (
    <ConfirmationDialog
      confirmAction={deleteCampaigns}
      title={t('delete_dialog.title', { count: props.campaignIds.length })}
      text={t('delete_dialog.description')}
      variant="destructive"
      open={props.open}
      dataTestid={props.dataTestid}
      onClose={onClose}
    />
  )
}
