import { useLazyQuery, useMutation } from '@apollo/client'
import { Button, Chip, Container, Divider } from '@mui/material'
import { useSnackbar } from 'notistack'
import { useCallback, useEffect, useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import { RootRoute } from '..'
import { FormPagePlaceholder } from '../../components/form-page-placeholder'
import { PageHeader } from '../../components/page-header'
import {
  AddCampaignDocument,
  CampaignInput,
  CampaignProductAttributesInput,
  CampaignType,
  GetCampaignDocument,
  GetCampaignQuery,
  UpdateCampaignDocument,
} from '../../generated/graphql'
import { useAbsolutePath } from '../../hooks/absolute-path'
import { useDayJs } from '../../hooks/day-js'
import { usePageTitle } from '../../hooks/title'
import { useTracking } from '../../hooks/tracking'
import {
  CAMPAIGN_TYPE_PACKAGE,
  CAMPAIGN_TYPE_PRICELIST,
  CAMPAIGN_TYPE_PRODUCT,
} from '../../utils/campaigns'
import { ALL_PRODUCTS_ATTR } from '../../utils/constants'
import { stripNullValues } from '../../utils/strip-null-values'
import { reactiveCampaignViewPanelVar } from '../campaigns'
import { ActiveField } from './fields/active'
import { AppliesToField } from './fields/applies-to'
import { BasicDetailsField } from './fields/basic-details'
import { CampaignRulesField } from './fields/campaign-rules'
import { CampaignTypeField } from './fields/campaign-type'
import { DeleteField } from './fields/delete'
import { DiscountCodeField } from './fields/discount-code'
import { StoreTagsField } from './fields/store-tags'
import { StoreTagsLocalCampaignField } from './fields/store-tags/store-tags-local-campaign'
import { ValidWhenField } from './fields/valid-when'
import {
  isLocalVoucherCode,
  parseVoucherCode,
  useLocalCampaignFlow,
} from './utils'
import { InternalNotesField } from './fields/internal-notes'
import { FormRouteLeavingGuard } from '../../components/form-route-leaving-guard'
import { getErrorMessages } from '../../utils/error-mapping'

export type CampaignFormContext = GetCampaignQuery & {
  isLocalCampaign: boolean
}

const sanitizeProductAttributes = (
  productAttributes?: CampaignProductAttributesInput | null,
) => {
  if (!productAttributes) return productAttributes

  const { exclude, include } = productAttributes
  const attributes: CampaignProductAttributesInput = {}

  if (
    exclude &&
    typeof exclude === 'object' &&
    Object.keys(exclude).length > 0
  ) {
    attributes.exclude = exclude
  }

  if (
    include &&
    typeof include === 'object' &&
    Object.keys(include).length > 0
  ) {
    attributes.include = include
  }

  return attributes
}

export const CampaignPage = () => {
  const { t } = useTranslation(['campaigns', 'shared'])
  const { id } = useParams()
  const isNewCampaign = id === undefined
  usePageTitle(
    isNewCampaign
      ? t('campaigns:page_title_add_campaign')
      : t('campaigns:page_title_edit_campaign'),
  )
  const { trackButtonClick, trackFormSuccess, trackFormError } = useTracking()
  const [searchParams] = useSearchParams()
  const campaignId = Number(id)
  const [isInitialized, setInitialized] = useState(false)
  const navigate = useNavigate()
  const generatePath = useAbsolutePath()
  const { enqueueSnackbar } = useSnackbar()
  const [fetchCampaign, { loading: fetchLoading }] =
    useLazyQuery(GetCampaignDocument)
  const [addCampaignMutation, { loading: addLoading }] =
    useMutation(AddCampaignDocument)
  const [updateCampaignMutation, { loading: updateLoading }] = useMutation(
    UpdateCampaignDocument,
  )
  const dayJs = useDayJs()
  const isLocalCampaignFlow = useLocalCampaignFlow()

  const campaignType = searchParams.get('campaignType') as CampaignType | null

  const isPriceListType =
    campaignType && CAMPAIGN_TYPE_PRICELIST.types.includes(campaignType)

  const formContext = useForm<CampaignFormContext>({
    defaultValues: {
      isLocalCampaign: isLocalCampaignFlow,
      campaign: {
        activepos: true,
        use_discounted_price: true,
        priority: 3,
        tags: [],
        pricelisttags: isPriceListType ? [] : undefined,
      },
    },
  })

  const isLocalCampaign = formContext.getValues('isLocalCampaign')

  const isLoading = fetchLoading || updateLoading || addLoading

  useEffect(() => {
    const types = Object.keys(CampaignType)
    if (!isNewCampaign) {
      void fetchCampaign({ variables: { id: campaignId || 0 } }).then(
        ({ data, error }) => {
          if (data?.campaign) {
            formContext.reset(
              stripNullValues({
                isLocalCampaign:
                  isLocalCampaignFlow ||
                  isLocalVoucherCode(data.campaign.vouchercode),
                campaign: {
                  ...data.campaign,
                  vouchercode: parseVoucherCode(data.campaign.vouchercode),
                },
              }),
              { keepDefaultValues: false },
            )
            setInitialized(true)
          }

          if (error) {
            const [firstErrorMessage] = getErrorMessages(error)
            enqueueSnackbar(firstErrorMessage, { variant: 'error' })
          }
        },
      )
    } else if (campaignType && types.includes(campaignType)) {
      formContext.setValue('campaign.vouchertype', campaignType)

      if (CAMPAIGN_TYPE_PRODUCT.types.includes(campaignType)) {
        formContext.setValue('campaign.productattributes.include', {
          [ALL_PRODUCTS_ATTR]: ['all'],
        })
      }

      setInitialized(true)
    } else {
      void navigate(generatePath(RootRoute.Campaigns))
    }
  }, [
    campaignId,
    campaignType,
    enqueueSnackbar,
    fetchCampaign,
    formContext,
    generatePath,
    isLocalCampaignFlow,
    isNewCampaign,
    navigate,
    trackFormError,
  ])

  const formatFormData = useCallback(
    (campaign: GetCampaignQuery['campaign']): CampaignInput => {
      const voucherCode = isLocalCampaign
        ? ['LOCAL', campaign.vouchercode].filter(Boolean).join(' ')
        : campaign.vouchercode

      const getDate = (date: string) =>
        dayJs(date).set('seconds', 0).set('milliseconds', 0).toJSON()

      const isProductCampaign = CAMPAIGN_TYPE_PRODUCT.types.includes(
        campaign.vouchertype,
      )

      const discountedPrice = isProductCampaign
        ? campaign.use_discounted_price
        : undefined

      return {
        vouchername: campaign.vouchername,
        vouchertype: campaign.vouchertype,
        activepos: campaign.activepos,
        priority: campaign.priority,
        vouchercode: voucherCode,
        datestart: campaign.datestart
          ? getDate(campaign.datestart)
          : campaign.datestart,
        dateend: campaign.dateend
          ? getDate(campaign.dateend)
          : campaign.dateend,
        value_x: campaign.value_x,
        value_y: campaign.value_y,
        value_z: campaign.value_z,
        money_m: campaign.money_m,
        money_n: campaign.money_n,
        vouchername1: campaign.vouchername1,
        vouchercode1: campaign.vouchercode1,
        vouchername2: campaign.vouchername2,
        vouchercode2: campaign.vouchercode2,
        maxuses: campaign.maxuses || undefined,
        products: !CAMPAIGN_TYPE_PACKAGE.types.includes(campaign.vouchertype)
          ? campaign.products
          : undefined,
        productattributes: sanitizeProductAttributes(
          campaign.productattributes,
        ),
        tags: campaign.tags,
        use_discounted_price: discountedPrice,
        voucherpassword: campaign.voucherpassword,
        vouchercomment: campaign.vouchercomment,
        pricelisttags: campaign.pricelisttags,
        productoptions:
          CAMPAIGN_TYPE_PACKAGE.types.includes(campaign.vouchertype) &&
          campaign.productoptions?.length
            ? campaign.productoptions
                ?.map((x) => ({ num: x.num, products: x.products }))
                ?.filter((x) => x.products?.length)
            : undefined,
        discountoptions: campaign.discountoptions,
      }
    },
    [isLocalCampaign, dayJs],
  )

  const onSubmit = async (data: GetCampaignQuery) => {
    trackButtonClick({
      name: 'campaign-save',
      campaignId: data.campaign.id,
      campaignType: data.campaign.vouchertype,
      campaignGroup: data.campaign.vouchergrouptype,
    })

    try {
      let newCampaignId = campaignId

      if (isNewCampaign) {
        const { data: response } = await addCampaignMutation({
          variables: {
            data: formatFormData(data.campaign),
          },
        })

        if (response?.addCampaign) {
          newCampaignId = response?.addCampaign
        }
      } else {
        await updateCampaignMutation({
          variables: {
            campaignId,
            data: formatFormData(data.campaign),
          },
        })
      }

      trackFormSuccess({ name: 'campaign' })

      reactiveCampaignViewPanelVar({
        isOpen: true,
        campaignId: newCampaignId,
      })
      void navigate(generatePath(RootRoute.Campaigns))

      enqueueSnackbar(
        isNewCampaign
          ? t('campaigns:campaign_message.success_add')
          : t('campaigns:campaign_message.success_update'),
      )
    } catch (error) {
      const [firstErrorMessage] = getErrorMessages(error)

      trackFormError({
        name: 'campaign',
        errorMessage: firstErrorMessage,
      })

      enqueueSnackbar(firstErrorMessage, { variant: 'error' })
    }
  }

  return (
    <>
      <PageHeader
        title={
          isNewCampaign
            ? t('campaigns:add_campaign_title')
            : t('campaigns:edit_campaign_title')
        }
        backTo={generatePath(RootRoute.Campaigns)}
        backText={t('shared:menu.campaigns')}
        isSticky
        showBackButton={true}
        subTitle={
          isLocalCampaign ? <Chip label={t('campaigns:local_label')} /> : null
        }
        rightColumn={
          <Button
            onClick={formContext.handleSubmit(onSubmit)}
            loading={isLoading}
            data-testid="campaign-submit-button"
          >
            {t('shared:action.save')}
          </Button>
        }
      />

      <Container>
        <FormProvider {...formContext}>
          <FormRouteLeavingGuard />
          {!isInitialized ? (
            <FormPagePlaceholder />
          ) : (
            <>
              <CampaignTypeField />
              <BasicDetailsField />
              <ActiveField />

              <input
                {...formContext.register('isLocalCampaign')}
                type="hidden"
              />

              <Divider />

              <CampaignRulesField />

              <AppliesToField />

              {isLocalCampaignFlow ? (
                <StoreTagsLocalCampaignField />
              ) : (
                <StoreTagsField />
              )}

              <Divider />

              <ValidWhenField />
              <DiscountCodeField />

              <Divider />
              <InternalNotesField />
              <DeleteField />
            </>
          )}
        </FormProvider>
      </Container>
    </>
  )
}
