import { useMutation, useQuery } from '@apollo/client'
import { Container } from '@mui/material'
import { ConfirmationDialog } from '@sitoo/mui-components'
import { enqueueSnackbar } from 'notistack'
import { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  Outlet,
  useNavigate,
  useParams,
  useSearchParams,
} from 'react-router-dom'
import { RootRoute } from '..'
import { PageHeader } from '../../components/page-header'
import {
  DeleteReportJobDocument,
  LatestReportJobsDocument,
  ReportJobDocument,
  ReportJobState,
  ReportResultItemsDocument,
  ReportResultsDocument,
} from '../../generated/graphql'
import { useAbsolutePath } from '../../hooks/absolute-path'
import { usePageTitle } from '../../hooks/title'
import { getErrorMessages } from '../../utils/error-mapping'
import { ExportResultsDialog } from './export-results-dialog'
import { FavoriteReportJobToggleButton } from './favorites/favorite-report-jobs-toggle-button'
import { GraphSection } from './graph-section'
import { HeaderChips } from './header-chips'
import { ReportJobDialogProvider } from './create-report-job-dialog/report-job-dialog-provider'
import { MoreMenu } from './more-menu'
import { ReportResultTable } from './report-result-table'
import { useTracking } from '../../hooks/tracking'
import { TopSection } from './top-section'
import { RenameDialog } from './rename-dialog'

export const PAGE_SIZE = 1000

export const ReportJobPage = () => {
  const params = useParams<{ id: string }>()
  const reportJobId = Number(params.id)
  const navigate = useNavigate()
  const generatePath = useAbsolutePath()
  const { trackFormSuccess, trackFormError } = useTracking()
  const { t } = useTranslation(['shared', 'reports'])
  usePageTitle(t('reports:report_job_page_title'))

  const [searchParams] = useSearchParams()
  const [showDeleteDialog, setShowDeleteDialog] = useState(false)
  const [showExportDialog, setShowExportDialog] = useState(false)
  const [showRenameDialog, setShowRenameDialog] = useState(false)

  const {
    data: reportJobData,
    loading: isLoadingReportJobData,
    startPolling,
    stopPolling,
  } = useQuery(ReportJobDocument, {
    variables: { jobId: reportJobId },
    skip: !reportJobId,
    onError: () => {
      void navigate(generatePath(RootRoute.ReportJobs), { replace: true })
    },
  })

  const skipFetchResults =
    !reportJobData?.reportJob.jobstate ||
    reportJobData?.reportJob.jobstate !== ReportJobState.Finished

  useEffect(() => {
    if (
      reportJobData?.reportJob.jobstate === ReportJobState.Pending ||
      reportJobData?.reportJob.jobstate === ReportJobState.InProgress
    ) {
      startPolling(3000)
    } else {
      stopPolling()
    }
  }, [reportJobData, startPolling, stopPolling])

  const { data: reportResultsData, loading: isLoadingReportResults } = useQuery(
    ReportResultsDocument,
    {
      variables: { jobId: reportJobId },
      skip: skipFetchResults,
      onError: (error) => {
        enqueueSnackbar({
          message: getErrorMessages(error)[0],
          variant: 'error',
        })
      },
    },
  )

  const {
    data: reportResultsItemsData,
    loading: isLoadingReportResultItems,
    fetchMore,
  } = useQuery(ReportResultItemsDocument, {
    variables: { jobId: reportJobId, num: PAGE_SIZE },
    skip: skipFetchResults,
    notifyOnNetworkStatusChange: true,
  })

  const reportResultItems = reportResultsItemsData?.reportResultItems

  const [deleteReportJob] = useMutation(DeleteReportJobDocument, {
    refetchQueries: [LatestReportJobsDocument],
  })

  const handleOnDelete = async () => {
    try {
      await deleteReportJob({ variables: { reportJobId } })
      enqueueSnackbar({
        message: t('reports:success_delete_report_jobs_snackbar', { count: 1 }),
        variant: 'success',
      })

      void navigate(
        searchParams.get('backTo') ?? generatePath(RootRoute.ReportJobs),
        { replace: true },
      )
      trackFormSuccess({ name: 'report-job-delete' })
    } catch (error) {
      enqueueSnackbar({ message: getErrorMessages(error)[0], variant: 'error' })
      trackFormError({ name: 'report-job-delete' })
    }
  }

  const fetchMoreResultItems = useCallback(() => {
    if (reportResultItems?.totalcount) {
      return fetchMore({
        variables: {
          start: (reportResultItems.start ?? 0) + PAGE_SIZE,
        },
      })
    }
  }, [fetchMore, reportResultItems?.start, reportResultItems?.totalcount])

  const isLoading =
    isLoadingReportJobData ||
    isLoadingReportResults ||
    isLoadingReportResultItems

  return (
    <>
      <ReportJobDialogProvider
        backTo={generatePath(RootRoute.ReportJob, { id: reportJobId })}
        dialogData={reportJobData?.reportJob}
        name={reportJobData?.reportJob?.jobname}
        defaultDialogMode={'compact'}
      >
        <Outlet />
        <ConfirmationDialog
          open={showDeleteDialog}
          title={t('reports:delete_report_jobs_label', { count: 1 })}
          text={t('reports:delete_report_jobs_description', { count: 1 })}
          onClose={() => setShowDeleteDialog(false)}
          confirmAction={handleOnDelete}
          variant="destructive"
        />
        <ExportResultsDialog
          open={showExportDialog}
          onClose={() => setShowExportDialog(false)}
          jobId={reportJobId}
        />
        <RenameDialog
          reportJob={reportJobData?.reportJob}
          onClose={() => setShowRenameDialog(false)}
          open={showRenameDialog}
        />
        <PageHeader
          title={reportJobData?.reportJob?.jobname || ''}
          isFlexible
          backTo={
            searchParams.get('backTo') ?? generatePath(RootRoute.ReportJobs)
          }
          showBackButton={true}
          subTitle={
            <HeaderChips
              dateExecuted={reportJobData?.reportJob?.dateexecuted}
              jobState={reportJobData?.reportJob?.jobstate}
            />
          }
          rightColumn={
            <>
              <FavoriteReportJobToggleButton reportJobId={reportJobId} />
              <MoreMenu
                showRenameDialog={() => setShowRenameDialog(true)}
                showExportDialog={() => setShowExportDialog(true)}
                showDeleteDialog={() => setShowDeleteDialog(true)}
              />
            </>
          }
        />

        <Container maxWidth={false}>
          <TopSection
            reportJob={reportJobData?.reportJob}
            reportResults={reportResultsData?.reportResults}
          />

          <GraphSection
            reportResults={reportResultsData?.reportResults}
            reportResultItems={reportResultItems}
          />
          <ReportResultTable
            reportJob={reportJobData?.reportJob}
            reportResults={reportResultsData?.reportResults}
            reportResultItems={reportResultItems}
            fetchMoreResultItems={fetchMoreResultItems}
            isLoading={isLoading}
            onDelete={() => setShowDeleteDialog(true)}
          />
        </Container>
      </ReportJobDialogProvider>
    </>
  )
}
