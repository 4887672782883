import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material'
import { useCallback, useState, useEffect } from 'react'
import { useFieldArray, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { SearchProductField } from '../../../components/search-product-field'
import { useTracking } from '../../../hooks/tracking'
import { ArrayElement } from '../../../utils/types'
import { ShipmentPackage } from '../fields/packages'
import { PackageItems } from './package-items'
import { useGridApiRef } from '@mui/x-data-grid-pro'
import { usePrevious } from 'react-use'
import { getErrorMessages } from '../../../utils/error-mapping'
import { ShipmentV2Package } from '../shared'
import { ShipmentStateV2 } from '../../../generated/graphql'

type Props = {
  open: boolean
  shipmentState?: ShipmentStateV2
  packageId: string
  package?: ShipmentV2Package
  packageItems: ShipmentPackage['items']
  onClose(): void
  onError?(errorMessage: string): void
  onSubmit?: (
    packageItems: ShipmentPackage['items'],
    packageId: string,
  ) => void | Promise<void>
  onSuccess?: () => void
  dataTestid?: string
  readOnly: boolean
  isNewShipment: boolean
}

export type ShipmentPackageItem = ArrayElement<ShipmentPackage['items']>

export const SetPackageItemsDialog = (props: Props) => {
  const { t } = useTranslation(['shipments_v2', 'shared'])
  const dialogName = 'set-package-items-v2'

  const {
    trackButtonClickEvent,
    trackDialogClose,
    trackDialogOpen,
    trackFormError,
    trackFormSuccess,
  } = useTracking()
  const [loading, setLoading] = useState(false)

  const { control, getValues } = useForm<Pick<ShipmentPackage, 'items'>>({
    defaultValues: { items: [] },
  })

  const prevOpen = usePrevious(props.open)

  const apiRef = useGridApiRef()

  const { fields, append, replace, remove, update } = useFieldArray({
    control,
    name: 'items',
    keyName: 'id',
  })

  const onSubmit = useCallback(async () => {
    try {
      setLoading(true)
      await props.onSubmit?.(fields, props.packageId)
      trackFormSuccess({ name: `${dialogName}-dialog` })
      props.onSuccess?.()
    } catch (error) {
      const errorMessage = getErrorMessages(error)[0]

      trackFormError({ name: `${dialogName}-dialog`, errorMessage })
      if (errorMessage) props.onError?.(errorMessage)
    } finally {
      setLoading(false)
    }
  }, [fields, props, trackFormError, trackFormSuccess])

  const addItem = (item: ShipmentPackageItem) => {
    const nextId = (fields.at(-1)?.shipmentitemid || 0) + 1

    append({
      shipmentpackageid: props.package?.shipmentpackageid,
      shipmentitemid: nextId,
      ...item,
    })

    setTimeout(() => {
      apiRef.current.scrollToIndexes({ rowIndex: fields.length })
    }, 100)
  }

  useEffect(() => {
    if (props.open === prevOpen) return

    if (props.open) {
      replace(props.packageItems || [])
      trackDialogOpen({ name: dialogName })
    } else {
      replace([])
      trackDialogClose({ name: dialogName })
    }
  }, [
    prevOpen,
    props.open,
    props.packageItems,
    replace,
    trackDialogClose,
    trackDialogOpen,
  ])

  const isReadonly = props.readOnly

  return (
    <Dialog
      open={props.open}
      onClose={props.onClose}
      maxWidth="lg"
      fullWidth
      data-testid={props.dataTestid}
    >
      <DialogTitle
        type="extended"
        sx={{ pb: 2, justifyContent: 'space-between' }}
      >
        {isReadonly
          ? t('shipments_v2:packages.view_items')
          : t('shipments_v2:packages.edit_items')}
      </DialogTitle>

      <DialogContent sx={{ p: 0 }}>
        {!isReadonly && (
          <SearchProductField
            sx={{ mb: 2, px: 3 }}
            onChange={(product) => {
              addItem({
                productname:
                  [product.title, product.variant?.at(0)?.value]
                    .filter(Boolean)
                    .join(', ') || '',
                sku: product.sku,
                quantity: 1,
                product: {
                  id: product.id,
                  sku: product.sku,
                  images: product.productImages?.map((image) => image.fileUrl),
                  allowdecimals: product.allowdecimals,
                  unitlabel: product.unitlabel,
                },
              })
            }}
          />
        )}

        <PackageItems
          items={getValues('items')}
          apiRef={apiRef}
          addItem={addItem}
          updateItem={update}
          removeItem={remove}
          readOnly={isReadonly}
        />
      </DialogContent>
      <DialogActions>
        <Button
          onClick={trackButtonClickEvent(
            { name: `${dialogName}-dialog-cancel` },
            props.onClose,
          )}
          color="secondary"
          size="small"
          type="button"
          disabled={loading}
        >
          {!isReadonly ? t('shared:action.cancel') : t('shared:action.close')}
        </Button>
        {!isReadonly && (
          <Button
            type="submit"
            size="small"
            onClick={trackButtonClickEvent(
              { name: `${dialogName}-dialog-save` },
              onSubmit,
            )}
            data-testid="dialog-submit"
            loading={loading}
          >
            {props.isNewShipment
              ? t('shared:action.ok')
              : t('shared:action.save')}
          </Button>
        )}
      </DialogActions>
    </Dialog>
  )
}
