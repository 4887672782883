import { ListItem } from '@mui/material'
import { SelectInput, Option } from '../../../../inputs/select-input'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useMemo } from 'react'
import { Form } from '..'

export const PurchaseTypeInput = () => {
  const { control } = useFormContext<Form>()
  const { t } = useTranslation('reports')

  const orderPurchaseTypes: Option[] = useMemo(
    () => [
      { name: t('create_dialog.include_all'), value: '' },
      { name: t('create_dialog.sales_only'), value: false },
      { name: t('create_dialog.refund_only'), value: true },
    ],
    [t],
  )
  return (
    <ListItem>
      <SelectInput
        control={control}
        label={t('create_dialog.purchase_type')}
        name="reportdefinition.filters.orderIsNegative"
        options={orderPurchaseTypes}
        slotProps={{ select: { displayEmpty: true } }}
      />
    </ListItem>
  )
}
