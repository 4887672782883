import {
  Box,
  Chip,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Stack,
} from '@mui/material'
import { CheckIcon } from '@sitoo/mui-components'
import { useTranslation } from 'react-i18next'
import { ReportSummaryFragment } from '../../../../generated/graphql'

type Props = {
  report: ReportSummaryFragment
  isSelected: boolean
  onSelect: (reportId: number) => void
}

export const ReportListItem = ({ report, isSelected, onSelect }: Props) => {
  const { t } = useTranslation('reports')
  return (
    <ListItemButton
      data-testid="report-list-item"
      divider
      selected={isSelected}
      sx={{
        cursor: 'pointer',
        paddingBottom: (theme) => theme.spacing(1),
      }}
      onClick={() => onSelect(report.reportid)}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          flex: 1,
        }}
      >
        <ListItemText
          primary={report.reportname}
          secondary={report.reportdescription}
          sx={{ flexGrow: 0 }}
        />
        <Stack
          direction="row"
          sx={{
            mt: 0.5,
            flexGrow: 0,
            columnGap: 0.5,
            width: '100%',
          }}
        >
          <Chip
            label={t(`reports:report_types.${report.reporttype}`)}
            color="gray"
            size="small"
          />
          {report.forcewarehousefilter && (
            <Chip
              label={t('reports:shared_with_store_managers')}
              color="gray"
              size="small"
            />
          )}
        </Stack>
      </Box>
      <ListItemIcon
        sx={{
          visibility: isSelected ? 'visible' : 'hidden',
        }}
      >
        <CheckIcon />
      </ListItemIcon>
    </ListItemButton>
  )
}
