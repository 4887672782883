import { ListItem } from '@mui/material'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { TextFieldInput } from '../../../../inputs/text-field-input'
import { Form } from '..'

export const JobNameInput = () => {
  const { control } = useFormContext<Form>()
  const { t } = useTranslation('reports')
  return (
    <ListItem>
      <TextFieldInput
        label={t('create_dialog.report_title')}
        name="jobname"
        control={control}
        required
      />
    </ListItem>
  )
}
