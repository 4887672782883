import {
  DatePicker,
  DatePickerProps as MuiDatePickerProps,
  PickerValidDate,
  validateDate,
} from '@mui/x-date-pickers-pro'
import { useLocalizationContext } from '@mui/x-date-pickers/internals'
import {
  FieldPath,
  FieldValues,
  PathValue,
  RegisterOptions,
  useController,
  UseControllerProps,
} from 'react-hook-form'
import { useLocalizedDate } from '../../hooks/localized-date'
import { useDayJs } from '../../hooks/day-js'
import { useTranslation } from 'react-i18next'
import { useMe } from '../../hooks/me'
import { Dayjs } from 'dayjs'
import { Messages, useErrorMessages } from './messages'

type DatePickerProps = Omit<
  MuiDatePickerProps<PickerValidDate>,
  'value' | 'onChange'
>

type Props<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
> = DatePickerProps &
  UseControllerProps<TFieldValues> & {
    label?: string
    helperText?: string
    onChange?: (value: PickerValidDate | null) => void
    transform?: {
      input?: (
        value: PathValue<TFieldValues, TName> | undefined,
      ) => PickerValidDate
      output?: (value: PickerValidDate | null) => PathValue<TFieldValues, TName>
    }
    required?: boolean
    errorMessages?: Partial<Messages>
    rules?: Omit<
      RegisterOptions<TFieldValues, TName>,
      'valueAsNumber' | 'valueAsDate' | 'setValueAs'
    >
    shouldValidate?: boolean
  }

export function readValueAsDate<TDate extends PickerValidDate>(
  adapter: ReturnType<typeof useLocalizationContext>,
  value: string | null | TDate,
): TDate | null {
  if (typeof value === 'string') {
    if (value === '') {
      return null
    }
    return adapter.utils.date(value) as TDate
  }
  return value
}

export const DatePickerInput = <
  TFieldValues extends FieldValues,
  TName extends FieldPath<TFieldValues>,
>(
  props: Props<TFieldValues, TName>,
) => {
  const {
    name,
    control,
    rules,
    errorMessages,
    shouldValidate = true,
    ...datePickerProps
  } = props
  const { getDateFormat } = useLocalizedDate()
  const { t } = useTranslation(['shared'])
  const { timeZone } = useMe()
  const dateFormat = getDateFormat()
  const dayJs = useDayJs()
  const adapter = useLocalizationContext()
  const defaultErrorMessages = useErrorMessages(errorMessages)

  const validationRules = {
    ...rules,
    required: rules?.required ?? {
      value: props.required ?? false,
      message: t('shared:validation.field_required', {
        field: props.label,
      }),
    },
    validate: {
      ...rules?.validate,
      internal: (value: Dayjs) => {
        if (!shouldValidate) return true
        const date = readValueAsDate(adapter, value)
        if (!date) {
          return true
        }

        const internalError = validateDate({
          props: {
            shouldDisableDate: datePickerProps.shouldDisableDate,
            shouldDisableMonth: datePickerProps.shouldDisableMonth,
            shouldDisableYear: datePickerProps.shouldDisableYear,
            disablePast: Boolean(datePickerProps.disablePast),
            disableFuture: Boolean(datePickerProps.disableFuture),
            minDate: datePickerProps.minDate,
            maxDate: datePickerProps.maxDate,
          },
          timezone: 'default',
          value: date,
          adapter,
        })
        return internalError == null || defaultErrorMessages[internalError]
      },
    },
  }

  const { field, fieldState, formState } = useController({
    name,
    control,
    rules: validationRules,
  })

  const formError = fieldState.error

  const { isSubmitting } = formState

  const inputValue = props.transform?.input?.(field.value) ?? field.value

  return (
    <DatePicker
      {...field}
      {...datePickerProps}
      label={props.label}
      disabled={isSubmitting}
      slotProps={{
        textField: {
          error: !!formError,
          helperText: formError?.message ?? props.helperText,
          required: !!(props.required || rules?.required),
        },
        ...datePickerProps.slotProps,
      }}
      timezone={timeZone}
      value={inputValue ? dayJs(inputValue) : null}
      onChange={(selectedValue) => {
        const value = props.transform?.output?.(selectedValue) ?? selectedValue
        field.onChange(value)
        props.onChange?.(value)
      }}
      format={dateFormat}
    />
  )
}
