import { ListItem, ListItemText, TextField } from '@mui/material'
import { FormFieldset } from '@sitoo/mui-components'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { InfluencerCodeContext } from '..'
import { SwitchInput } from '../../../inputs/switch-input'

export const Details = () => {
  const { register, control, formState } =
    useFormContext<InfluencerCodeContext>()
  const { t } = useTranslation(['influencer_codes', 'shared'])

  return (
    <>
      <FormFieldset label={t('influencer_codes:details_fieldset')}>
        <TextField
          error={!!formState?.errors?.name}
          fullWidth
          helperText={
            formState?.errors?.name?.message ||
            t('influencer_codes:influencer_name_description')
          }
          label={t('influencer_codes:influencer_name_label')}
          {...register('name', { required: true })}
        />
      </FormFieldset>

      <FormFieldset sx={{ '.MuiFormFieldset-Paper': { p: 0 } }}>
        <ListItem
          component="div"
          secondaryAction={
            <SwitchInput
              name="active"
              control={control}
              data-testid="influencer-code-active"
            />
          }
        >
          <ListItemText>
            {t('influencer_codes:influencer_active_label')}
          </ListItemText>
        </ListItem>
      </FormFieldset>
    </>
  )
}
