import { useMutation, useQuery } from '@apollo/client'
import { enqueueSnackbar } from 'notistack'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import { RootRoute } from '../..'
import { PageLoader } from '../../../components/page-loader'
import {
  AddPosProfileDocument,
  PosProfileDocument,
  PosProfileQuery,
  UpdatePosProfileDocument,
} from '../../../generated/graphql'
import { useAbsolutePath } from '../../../hooks/absolute-path'
import { usePageTitle } from '../../../hooks/title'
import { getErrorMessages } from '../../../utils/error-mapping'
import { PosProfileForm } from './pos-profile-form'

export type Form = PosProfileQuery['posProfile'] & {
  allowAllInventoryFeatures: boolean
  overrideDefaultPosTheme: boolean
  limitTaxFreeOptions: boolean
}

export const SettingsPosProfilePage = () => {
  const { id: posProfileId } = useParams()
  const isNewPosProfile = !posProfileId
  const formId = 'posProfileForm'

  const { t } = useTranslation(['shared', 'pos_profiles'])
  const generatePath = useAbsolutePath()
  const navigate = useNavigate()
  usePageTitle(t('pos_profiles:page_title'))

  const {
    data: posProfileData,
    loading: isPosProfileLoading,
    error: errorLoadingPosProfile,
  } = useQuery(PosProfileDocument, {
    variables: { posProfileId: Number(posProfileId) },
    skip: isNewPosProfile,
  })

  const [addPosProfile] = useMutation(AddPosProfileDocument)
  const [updatePosProfile] = useMutation(UpdatePosProfileDocument)

  const onSubmit = async (fieldsToUpdate: Partial<Form>) => {
    if (Object.keys(fieldsToUpdate).length === 0) return

    try {
      if (isNewPosProfile) {
        const { data } = await addPosProfile({
          variables: {
            posProfile: fieldsToUpdate,
          },
        })
        enqueueSnackbar(t('pos_profiles:success_saved'), {
          variant: 'success',
        })
        void navigate(
          generatePath(RootRoute.SettingsPosProfile, {
            id: data?.addPosProfile.id,
          }),
        )
      } else {
        await updatePosProfile({
          variables: {
            posProfile: {
              ...fieldsToUpdate,
              id: Number(posProfileId),
            },
          },
        })
        enqueueSnackbar(t('pos_profiles:success_saved'), {
          variant: 'success',
        })
        void navigate(generatePath(RootRoute.SettingsPosProfiles), {
          replace: true,
        })
      }
    } catch (error) {
      const errorMessages = getErrorMessages(error)
      enqueueSnackbar(errorMessages[0], {
        variant: 'error',
      })
      throw error
    }
  }

  useEffect(() => {
    if (!isNewPosProfile && !isPosProfileLoading && errorLoadingPosProfile) {
      void navigate(generatePath(RootRoute.SettingsPosProfiles), {
        replace: true,
      })
    }
  }, [
    errorLoadingPosProfile,
    generatePath,
    isNewPosProfile,
    isPosProfileLoading,
    navigate,
  ])

  if (isPosProfileLoading) {
    return <PageLoader />
  }

  return (
    <PosProfileForm
      formId={formId}
      onSubmit={onSubmit}
      posProfile={posProfileData?.posProfile}
    />
  )
}
