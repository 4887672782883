import { useTranslation } from 'react-i18next'
import { SettingsListSection } from '../../../../../components/settings-list-section'
import { NumberOfDaysField } from './fields/number-of-days-field'
import { StartDateField } from './fields/start-date-field'
import { LastDateField } from './fields/last-date-field'
import { EndDateField } from './fields/end-date-field'
import { SettingsListItem } from '../../../../../components/settings-list-item'

export const ReturnPolicy = () => {
  const { t } = useTranslation(['pos_profiles'])

  return (
    <SettingsListSection title={t('pos_profiles:return_policy_section.title')}>
      <NumberOfDaysField />
      <SettingsListItem sx={{ alignItems: 'flex-start' }}>
        <StartDateField />
        <EndDateField />
      </SettingsListItem>
      <LastDateField />
    </SettingsListSection>
  )
}
