import { Box, Chip, IconButton, ListItem, ListItemText } from '@mui/material'
import { DeleteIcon } from '@sitoo/mui-components'
import { Report } from '../../../generated/graphql'
import { useTranslation } from 'react-i18next'

type Props = {
  report: Report
  setReportToDelete: (id: number) => void
}

export const ReportTemplateListItem = (props: Props) => {
  const { t } = useTranslation('reports')
  return (
    <ListItem
      disablePadding
      disableGutters
      divider
      secondaryAction={
        <IconButton
          onClick={() => props.setReportToDelete(props.report.reportid)}
        >
          <DeleteIcon />
        </IconButton>
      }
    >
      <ListItemText
        primary={props.report.reportname}
        secondary={
          <Box
            component="span"
            sx={{ display: 'flex', flexDirection: 'column' }}
          >
            {props.report.reportdescription}
            {props.report.forcewarehousefilter && (
              <Chip
                component="span"
                label={t('reports:shared_with_store_managers')}
                color="gray"
                size="small"
                sx={{ width: 'max-content', mt: 0.5 }}
              />
            )}
          </Box>
        }
        sx={{
          textWrap: 'nowrap',
          textOverflow: 'ellipsis',
        }}
      />
    </ListItem>
  )
}
