import {
  Button,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material'
import { useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { usePrevious } from 'react-use'
import { useServerValidation } from '../../../hooks/server-validation'
import { useTracking } from '../../../hooks/tracking'
import { BaseShipmentFormContext, CarrierFields } from '../shared'
import { DateTimePicker } from '@mui/x-date-pickers-pro'
import { Dayjs } from 'dayjs'
import { useLocalizedDate } from '../../../hooks/localized-date'
import { useDayJs } from '../../../hooks/day-js'

type Form = BaseShipmentFormContext & CarrierFields

export type CarrierDialogProps = {
  open: boolean
  action: 'add' | 'edit'
  data?: Form | null
  onClose(): void
  onSubmit?(address: Form): Promise<void> | void
  onSuccess?(): void
  onError?(error: unknown): void
  dataTestid?: string
}

export const SetCarrierDialog = (props: CarrierDialogProps) => {
  const { t } = useTranslation(['shipments', 'shared'])
  const dialogName = 'set-carrier-v2'
  const {
    trackButtonClickEvent,
    trackDialogClose,
    trackDialogOpen,
    trackFormError,
    trackFormSuccess,
  } = useTracking()
  const prevOpen = usePrevious(props.open)
  const [loading, setLoading] = useState(false)
  const { formState, reset, handleSubmit, register, setError, control } =
    useForm<Form>({
      mode: 'onChange',
    })

  const { getDateTimeFormat } = useLocalizedDate()
  const dayJs = useDayJs()
  const dateFormat = getDateTimeFormat()
  const setFormError = useServerValidation<Form>('shipments', setError, {
    resolveFieldFromProperty: (property) =>
      property.split('.').pop() || property,
  })

  const onSubmit = async (formData: Form) => {
    try {
      setLoading(true)
      await props.onSubmit?.(formData)
      trackFormSuccess({ name: `${dialogName}-dialog` })
      props.onSuccess?.()
    } catch (error) {
      trackFormError({ name: `${dialogName}-dialog` })

      setFormError(error)
      props.onError?.(error)
    } finally {
      setLoading(false)
    }
  }

  const onClose = () => {
    trackDialogClose({ name: dialogName })
    if (props.onClose) {
      props.onClose()
    }
  }

  useEffect(() => {
    if (props.open && !prevOpen) {
      trackDialogOpen({ name: dialogName })
      reset({ ...props.data }, { keepDefaultValues: false })
    }
  }, [props.open, prevOpen, trackDialogOpen, props.data, reset])

  return (
    <Dialog open={props.open} onClose={onClose} maxWidth="sm" fullWidth>
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogTitle
          type="extended"
          sx={{
            paddingBottom: (theme) => theme.spacing(2),
          }}
        >
          {t(
            props.action === 'add'
              ? 'shipments:set_carrier_dialog.add_title'
              : 'shipments:set_carrier_dialog.edit_title',
          )}
        </DialogTitle>
        <DialogContent>
          <TextField
            error={!!formState?.errors?.carrier_name}
            fullWidth
            helperText={formState?.errors?.carrier_name?.message}
            label={t('shipments:shipment_form.name')}
            {...register('carrier_name')}
            sx={{ mb: 2 }}
          />

          <TextField
            error={!!formState?.errors?.carrier_reference}
            fullWidth
            helperText={formState?.errors?.carrier_reference?.message}
            label={t('shipments:shipment_form.reference')}
            {...register('carrier_reference')}
            sx={{ mb: 2 }}
          />
          <TextField
            error={!!formState?.errors?.barcode}
            fullWidth
            helperText={formState?.errors?.barcode?.message}
            label={t('shipments:shipment_form.barcode')}
            {...register('barcode')}
            sx={{ mb: 2 }}
          />
          <TextField
            error={!!formState?.errors?.carrier_trackingurl}
            fullWidth
            helperText={formState?.errors?.carrier_trackingurl?.message}
            placeholder="https://wwww.site.com"
            label={t('shipments:shipment_form.tracking_url')}
            {...register('carrier_trackingurl')}
            sx={{ mb: 2 }}
          />
          <TextField
            error={!!formState?.errors?.externalid}
            fullWidth
            helperText={formState?.errors?.externalid?.message}
            label={t('shipments_v2:shipment_form.externalid')}
            {...register('externalid')}
            sx={{ mb: 2 }}
          />
          <Controller
            control={control}
            name="dateestimatedpickup"
            render={({ field }) => (
              <DateTimePicker
                label={t('shipments_v2:shipment_form.estimated_pickup')}
                sx={{ pb: 2 }}
                value={dayJs(field.value)}
                onChange={(date: Dayjs | null) =>
                  field.onChange(date?.format())
                }
                format={dateFormat}
              />
            )}
          />

          <Controller
            control={control}
            name="dateestimateddelivery"
            render={({ field }) => (
              <DateTimePicker
                label={t('shipments_v2:shipment_form.estimated_delivery')}
                sx={{ pb: 2 }}
                value={dayJs(field.value) ?? null}
                onChange={(date: Dayjs | null) =>
                  field.onChange(date?.format())
                }
                format={dateFormat}
              />
            )}
          />

          <TextField
            error={!!formState?.errors.comment}
            fullWidth
            helperText={formState?.errors.comment?.message}
            label={t('shipments_v2:shipment_form.shipment_note')}
            {...register('comment')}
            multiline
            rows={4}
          />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={trackButtonClickEvent(
              {
                name: `${dialogName}-dialog-cancel`,
              },
              onClose,
            )}
            color="secondary"
            size="small"
            type="button"
          >
            {t('shared:action.cancel')}
          </Button>
          <Button
            type="submit"
            size="small"
            onClick={trackButtonClickEvent({
              name: `${dialogName}-dialog-set`,
            })}
            disabled={!formState.isValid}
            loading={loading}
          >
            {t('shared:action.save')}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  )
}
