import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  List,
  ListItemText,
} from '@mui/material'
import {
  PackageAddIcon,
  PackageArrowDownIcon,
  PackageIcon,
  PackageRemoveIcon,
  PackageWarningIcon,
  PickupIcon,
  ProgressDoneIcon,
  SectionHeader,
  ShipmentAddIcon,
  ShipmentIcon,
  ShipmentInboundIcon,
} from '@sitoo/mui-components'
import { Fragment } from 'react'
import { useTranslation } from 'react-i18next'
import { ListItemIconTimeline } from '../../../components/list-item-icon-timeline'
import { ListItemTimeline } from '../../../components/list-item-timeline'
import {
  ActionType,
  AllShipmentActionsQuery,
  GetShipmentQuery,
  ShipmentState,
} from '../../../generated/graphql'
import { useLocalizedDate } from '../../../hooks/localized-date'
import { ArrayElement } from '../../../utils/types'

type Props = {
  isLoading?: boolean
  shipmentActions?: AllShipmentActionsQuery['allShipmentActions']
  shipment?: GetShipmentQuery['shipment']
}

export const History = (props: Props) => {
  const { shipment } = props
  const { t, i18n } = useTranslation(['shared', 'shipments'])
  const { formatRelativeDate } = useLocalizedDate()

  const shipmentActions = (props.shipmentActions || []).slice().reverse()

  const getActionTitle = (
    shipmentAction: ArrayElement<AllShipmentActionsQuery['allShipmentActions']>,
  ) => {
    if (shipmentAction.action === ActionType.RemoveStock) {
      return t(`shipments:action_type.SetState`, {
        state: t(`shipments:shipment_state.Packed`),
      })
    }

    return i18n.exists(`shipments:action_type.${shipmentAction.action}`)
      ? t(`shipments:action_type.${shipmentAction.action}`, {
          state:
            shipmentAction.state &&
            i18n.exists(`shipments:shipment_state.${shipmentAction.state}`)
              ? t(`shipments:shipment_state.${shipmentAction.state}`)
              : '',
        })
      : '-'
  }

  const getShipmentActionIcon = (
    shipmentAction: ArrayElement<AllShipmentActionsQuery['allShipmentActions']>,
  ) => {
    switch (shipmentAction.action) {
      case ActionType.SetState: {
        switch (shipmentAction.state) {
          case ShipmentState.Packed:
            return PackageArrowDownIcon

          case ShipmentState.TransportOrdered:
            return ShipmentAddIcon

          case ShipmentState.ReadyForPickup:
            return PickupIcon

          case ShipmentState.InTransit:
          case ShipmentState.Arrived:
            return ShipmentIcon

          case ShipmentState.Received:
            return ShipmentInboundIcon

          case ShipmentState.ClosedIncomplete:
            return PackageWarningIcon

          default:
            return ProgressDoneIcon
        }
      }

      case ActionType.AddPackage:
        return PackageAddIcon

      case ActionType.RemoveStock:
        return PackageArrowDownIcon

      case ActionType.UpdatePackage:
      case ActionType.AddReceivedItems:
      case ActionType.AddReceivedItemsUndeclared:
      case ActionType.AddArrivedPackages:
        return PackageIcon

      case ActionType.DeletePackage:
        return PackageRemoveIcon

      default:
        return ProgressDoneIcon
    }
  }

  const getDetailsContent = (
    shipmentAction: ArrayElement<AllShipmentActionsQuery['allShipmentActions']>,
  ) => {
    const responsible = shipmentAction.created_by
      ? t('shipments:view_panel.responsible', {
          user: shipmentAction.created_by,
        })
      : ''

    const content: string[] = []
    switch (shipmentAction.action) {
      case ActionType.RemoveStock:
        content.push(
          t('shipments:view_panel.stock_removed', {
            warehouse: shipmentAction.warehouse
              ? `${shipmentAction.warehouse.name} (${shipmentAction.warehouse.id})`
              : shipmentAction.warehouse_id,
          }),
        )
        break

      case ActionType.AddArrivedPackages: {
        const packageIds = shipmentAction.package_id_list || []
        const packages = (shipment?.packages || [])
          .map((x, i) => ({ id: x.id, position: i }))
          .filter((x) => packageIds.includes(x.id))

        if (packages.length > 0)
          content.push(
            ...packages.map((x) =>
              t('shipments:view_panel.package', { number: x.position + 1 }),
            ),
          )
        break
      }

      case ActionType.AddReceivedItems: {
        if (!shipmentAction.received_items) break

        if (shipmentAction.received_items.reason_code)
          content.push(
            t('shipments:view_panel.reason_code', {
              reasonCode: shipmentAction.received_items.reason_code,
            }),
          )

        if (shipmentAction.received_items.note)
          content.push(shipmentAction.received_items.note)

        if (shipmentAction.received_items.warehouse?.name)
          content.push(shipmentAction.received_items.warehouse?.name)

        const totalReceivedItems = (
          shipmentAction.received_items.items || []
        ).reduce((p, c) => p + c.quantity, 0)
        content.push(
          t('shipments:view_panel.received_quantity', {
            quantity: totalReceivedItems,
          }),
        )

        break
      }

      case ActionType.AddReceivedItemsUndeclared: {
        if (!shipmentAction.received_items_undeclared) break

        if (shipmentAction.received_items_undeclared.reason_code)
          content.push(
            t('shipments:view_panel.reason_code', {
              reasonCode: shipmentAction.received_items_undeclared.reason_code,
            }),
          )

        if (shipmentAction.received_items_undeclared.note)
          content.push(shipmentAction.received_items_undeclared.note)

        if (shipmentAction.received_items_undeclared.warehouse?.name)
          content.push(shipmentAction.received_items_undeclared.warehouse?.name)

        const totalUndeclaredItems = (
          shipmentAction.received_items_undeclared.items || []
        ).reduce((p, c) => p + c.quantity, 0)
        content.push(
          t('shipments:view_panel.received_quantity', {
            quantity: totalUndeclaredItems,
          }),
        )
        break
      }

      case ActionType.UpdatePackage:
      case ActionType.AddPackage: {
        if (!shipmentAction.package) break

        if (shipmentAction.package.barcode)
          content.push(
            t('shipments:view_panel.package_barcode', {
              barcode: shipmentAction.package.barcode,
            }),
          )
        break
      }

      case ActionType.Create:
      case ActionType.SetState:
      case ActionType.SetInfo:
      case ActionType.DeletePackage:
      default:
        break
    }

    content.push(responsible)

    return content.filter(Boolean).join('\n')
  }

  const getTitleContent = (
    shipmentAction: ArrayElement<AllShipmentActionsQuery['allShipmentActions']>,
  ) => {
    return (
      <ListItemText
        secondary={
          shipmentAction.created_at
            ? formatRelativeDate(new Date(shipmentAction.created_at), {
                showSeconds: true,
              })
            : ''
        }
      >
        {getActionTitle(shipmentAction)}
      </ListItemText>
    )
  }

  return (
    <>
      <Accordion defaultExpanded className="MuiAccordionRoot">
        <AccordionSummary aria-controls="history">
          <SectionHeader sx={{ p: 0 }}>
            {t('shipments:view_panel.history')}
          </SectionHeader>
        </AccordionSummary>
        <AccordionDetails sx={{ p: 0 }}>
          <List>
            {shipmentActions.map((shipmentAction, i) => {
              const titleContent = getTitleContent(shipmentAction)
              const detailsContent = getDetailsContent(shipmentAction)

              return (
                <Fragment key={i}>
                  <ListItemTimeline
                    key={i}
                    divider={i !== shipmentActions.length - 1}
                    data-testid={`history-item-${i}`}
                  >
                    <ListItemIconTimeline
                      icon={getShipmentActionIcon(shipmentAction)}
                      first={i === 0}
                      last={i === shipmentActions.length - 1}
                    />
                    {!detailsContent ? (
                      titleContent
                    ) : (
                      <Accordion
                        sx={{
                          width: '100%',
                          my: 0,
                        }}
                      >
                        <AccordionSummary
                          sx={{
                            px: 0,
                            width: '100%',
                            '.MuiAccordionSummary-content': {
                              my: 0,
                            },
                          }}
                          aria-controls={`history-${i}`}
                        >
                          {titleContent}
                        </AccordionSummary>
                        <AccordionDetails
                          sx={{
                            p: 0,
                          }}
                        >
                          <ListItemText
                            secondary={detailsContent}
                            slotProps={{
                              secondary: {
                                sx: { whiteSpace: 'pre-line' },
                              },
                            }}
                          />
                        </AccordionDetails>
                      </Accordion>
                    )}
                  </ListItemTimeline>
                </Fragment>
              )
            })}
          </List>
        </AccordionDetails>
      </Accordion>
    </>
  )
}
