import { Divider, IconButton, Menu, Box } from '@mui/material'
import { MoreHorizontalIcon } from '@sitoo/mui-components'
import { useState } from 'react'
import { DataGridColumnOptions } from '../data-grid-column-options'
import { FilterPreset } from '../filter-preset'
import { FilterPresetSaveDialog } from '../filter-preset/filter-preset-save-dialog'
import { FilterPresetDeleteDialog } from '../filter-preset/filter-preset-delete-dialog'

type Props = {
  showFilterPresets?: boolean
}

export const DataGridMoreMenu = ({ showFilterPresets }: Props) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLButtonElement>(null)
  const [isMenuOpen, setMenuOpen] = useState(false)

  return (
    <>
      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
        <IconButton
          data-testid="custom-toolbar-button"
          sx={{
            width: (theme) => theme.spacing(6.25),
            backgroundColor: (theme) => theme.palette.white,
            borderRadius: 0,
            flexGrow: 1,
          }}
          onClick={(e) => {
            setMenuOpen(true)
            setAnchorEl(e.currentTarget)
          }}
        >
          <MoreHorizontalIcon />
        </IconButton>

        <Divider sx={{ borderWidth: '1px' }} />

        <FilterPresetSaveDialog />

        <FilterPresetDeleteDialog />

        <Menu
          data-testid="user-menu"
          variant="menu"
          open={isMenuOpen}
          anchorEl={anchorEl}
          onClose={() => {
            setMenuOpen(false)
            setAnchorEl(null)
          }}
          slotProps={{
            list: {
              sx: {
                minWidth: 300,
                maxHeight: '80vh',
                overflow: 'auto',
                '.MuiListItem-root, .MuiListItemButton-root': {
                  minHeight: 40,
                },
              },
            },
          }}
        >
          {showFilterPresets && <FilterPreset setMenuOpen={setMenuOpen} />}

          <DataGridColumnOptions setMenuOpen={setMenuOpen} />
        </Menu>
      </Box>
    </>
  )
}
