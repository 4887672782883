import { Tab, Tabs, Button } from '@mui/material'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import {
  NavigateOptions,
  useNavigate,
  useParams,
  useSearchParams,
} from 'react-router-dom'
import { RootRoute } from '..'
import { PageHeader } from '../../components/page-header'
import {
  AllCustomAttributesQuery,
  GetProductQuery,
  GetProductDocument,
} from '../../generated/graphql'
import { usePageTitle } from '../../hooks/title'
import { Information } from './information'
import { useProductFormSubmit } from './submit'
import { Variants } from './variants'
import { useInitializeSupportedCustomAttributes } from './fields/attributes'
import { Related } from './related'
import { useMoney } from '../../hooks/money'
import { useAuthorization } from '../../hooks/authorization'
import { useAbsolutePath } from '../../hooks/absolute-path'
import { useSnackbar } from 'notistack'
import { extractGraphqlErrors } from '../../utils/extract-graphql-errors'
import { useTracking } from '../../hooks/tracking'
import { getErrorMessage } from '../../utils/error-mapping'
import { FormPagePlaceholder } from '../../components/form-page-placeholder'
import { useLazyQuery } from '@apollo/client'
import { FormRouteLeavingGuard } from '../../components/form-route-leaving-guard'

export type ProductFormContext = GetProductQuery & {
  supportedCustomAttributes?: AllCustomAttributesQuery['allCustomAttributes']
}

enum TabLabel {
  Information = 1,
  Variants,
  RelatedProducts,
}

export const ProductPage = () => {
  const { id } = useParams()
  const [searchParams, setSearchParams] = useSearchParams()
  const tabParam = searchParams.get('tab')
  const { t } = useTranslation(['products', 'shared'])
  const isNewProduct = id === undefined
  const {
    modules: { writeProducts },
  } = useAuthorization()
  usePageTitle(
    !writeProducts
      ? t('products:page_title_product')
      : isNewProduct
        ? t('products:page_title_add_product')
        : t('products:page_title_edit_product'),
  )
  const productId = Number(id || 0)
  const [isInitialized, setInitialized] = useState(false)
  const formContext = useForm<ProductFormContext>()
  const { onSubmit, isSubmitting, onError } = useProductFormSubmit({
    productId,
    isNewProduct,
    formContext,
  })
  const { formatEditableCurrency } = useMoney()
  const navigate = useNavigate()
  const { enqueueSnackbar } = useSnackbar()
  const { track } = useTracking()
  const [fetchProduct, { loading: productLoading }] = useLazyQuery(
    GetProductDocument,
    {
      fetchPolicy: 'cache-and-network',
      onError: (error) => {
        void track('ProductFetchError', {
          category: 'Product',
          name: 'ProductFetchError',
          ...extractGraphqlErrors(error)
            .map((error) => ({
              code: (error.extensions?.['code'] ||
                error.extensions?.['code']) as string | undefined,
              value: error.extensions?.['value'] as string | undefined,
              path: error.path?.join(' - '),
            }))
            .reduce(
              (p, c, i) => ({
                ...p,
                [`error.${i}.code`]: c.code,
                [`error.${i}.value`]: c.value,
                [`error.${i}.path`]: c.path,
              }),
              {},
            ),
        })

        const errorMessage = getErrorMessage(
          error,
          'products',
          t('products:product_message.failure_fetch'),
        )
        enqueueSnackbar(errorMessage, {
          variant: 'error',
        })
        void navigate(generatePath(RootRoute.Products))
      },
    },
  )
  const [activeTab, setActiveTab] = useState(TabLabel.Information)
  const generatePath = useAbsolutePath()
  useInitializeSupportedCustomAttributes(
    isInitialized,
    formContext.watch,
    formContext.setValue,
  )

  const isLoading = productLoading || isSubmitting
  const isSingleProduct =
    formContext.watch('product.isSingleProduct') ||
    formContext.watch('product.isSingleProduct') === undefined

  const navigateToProduct = useCallback(
    (id: number, options?: NavigateOptions) =>
      navigate(generatePath(RootRoute.Product, { id: String(id) }), options),
    [generatePath, navigate],
  )

  useEffect(() => {
    if (TabLabel[Number(tabParam)]) {
      setActiveTab(Number(tabParam))
    }
  }, [tabParam])

  useEffect(() => {
    if (isNewProduct) {
      setInitialized(true)
    } else {
      void fetchProduct({ variables: { id: productId } }).then(({ data }) => {
        if (!data?.product?.isMainVariant && data?.product?.variantparentid) {
          void navigateToProduct(data?.product.variantparentid, {
            replace: true,
          })
        }

        const queryData = data?.product
          ? {
              ...data,
              product: {
                ...data?.product,

                moneyprice: data?.product.moneyprice
                  ? formatEditableCurrency(data?.product.moneyprice)
                  : undefined,
                moneypriceorg: data?.product.moneypriceorg
                  ? formatEditableCurrency(data?.product.moneypriceorg)
                  : undefined,

                childVariants: data?.product.childVariants?.map(
                  (childVariant) => ({
                    ...childVariant,
                    moneyprice: childVariant.moneyprice
                      ? formatEditableCurrency(childVariant.moneyprice)
                      : undefined,
                    moneypriceorg: childVariant.moneypriceorg
                      ? formatEditableCurrency(childVariant.moneypriceorg)
                      : undefined,
                  }),
                ),
              },
            }
          : undefined

        formContext.reset(queryData, { keepDefaultValues: false })

        setInitialized(true)
      })
    }
  }, [
    fetchProduct,
    formContext,
    formatEditableCurrency,
    isNewProduct,
    navigateToProduct,
    productId,
  ])

  const getTabContent = () => {
    switch (activeTab) {
      case TabLabel.RelatedProducts:
        return <Related isLoading={isLoading} />
      case TabLabel.Variants:
        return <Variants isLoading={isLoading} />
      case TabLabel.Information:
      default:
        return (
          <Information
            navigateToVariantsTab={() => setActiveTab(TabLabel.Variants)}
          />
        )
    }
  }

  const currentAccessories = formContext.watch(`product.accessories`)
  const currentRelated = formContext.watch(`product.related`)
  const currentSimilar = formContext.watch(`product.similar`)

  const showRelatedProducts = useMemo(() => {
    return (
      writeProducts ||
      (currentAccessories?.length || 0) > 0 ||
      (currentRelated?.length || 0) > 0 ||
      (currentSimilar?.length || 0) > 0
    )
  }, [currentAccessories, currentRelated, currentSimilar, writeProducts])

  return (
    <>
      <PageHeader
        title={
          !writeProducts
            ? t('products:page_title_product')
            : isNewProduct
              ? t('products:add_product_title')
              : t('products:edit_product_title')
        }
        backTo={generatePath(RootRoute.Products)}
        backText={t('shared:menu.products')}
        showBackButton={true}
        isSticky
        isFlexible={activeTab === TabLabel.Variants}
        rightColumn={
          <>
            {writeProducts && (
              <Button
                loading={isLoading}
                data-testid="product-submit-button"
                onClick={formContext.handleSubmit(onSubmit, onError)}
              >
                {t('shared:action.save')}
              </Button>
            )}
          </>
        }
      >
        <Tabs
          value={activeTab}
          onChange={(_e, value: TabLabel) => {
            setActiveTab(value)
            setSearchParams({ tab: String(value) })
          }}
          variant="standard"
          sx={{
            '.MuiButtonBase-root': {
              borderColor: 'transparent',
            },
          }}
        >
          <Tab
            label={t('products:product_tabs.information')}
            value={TabLabel.Information}
            data-testid="product-tabs-information"
          />
          <Tab
            label={t('products:product_tabs.variants')}
            value={TabLabel.Variants}
            disabled={isSingleProduct}
            data-testid="product-tabs-variants"
          />
          <Tab
            label={t('products:product_tabs.related_products')}
            value={TabLabel.RelatedProducts}
            disabled={!showRelatedProducts}
            data-testid="product-tabs-related-products"
          />
        </Tabs>
      </PageHeader>

      <FormProvider {...formContext}>
        <FormRouteLeavingGuard
          shouldBlockNavigation={({ pathname }) =>
            pathname !== location.pathname
          }
        />
        {!isInitialized ? <FormPagePlaceholder /> : getTabContent()}
      </FormProvider>
    </>
  )
}
