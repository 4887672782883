import { t } from 'i18next'
import { GetShipmentQuery } from '../../generated/graphql'
import { getErrorMessages } from '../../utils/error-mapping'

export type BaseShipmentFormContext = Pick<
  GetShipmentQuery['shipment'],
  | 'info'
  | 'packages'
  | 'state'
  | 'created_at'
  | 'modified_at'
  | 'all_packages_arrived'
  | 'all_items_received'
  | 'items_undeclared'
> & {
  id?: string
  version?: number
}

export const getShipmentsErrorMessage = (error: unknown) =>
  getErrorMessages(error, {
    format: (errorCode, property, defaultValue) =>
      t(`shipments:error.${errorCode}`, {
        property,
        defaultValue,
      }),
  })[0]
