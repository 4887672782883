import { TextField } from '@mui/material'
import { DateTimePicker } from '@mui/x-date-pickers-pro'
import { FormFieldset } from '@sitoo/mui-components'
import { Dayjs } from 'dayjs'
import { Controller, useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useLocalizedDate } from '../../../../hooks/localized-date'
import { BaseShipmentFormContext } from '../../shared'

export const GeneralInfoField = () => {
  const { control, register, formState } =
    useFormContext<BaseShipmentFormContext>()
  const { t } = useTranslation(['shipments_v2', 'shared'])

  const { getDateTimeFormat } = useLocalizedDate()

  const dateFormat = getDateTimeFormat()
  return (
    <FormFieldset label={t('shipments_v2:shipment_form.general_fieldset')}>
      <Controller
        control={control}
        name="carrier.dateestimatedpickup"
        render={({ field }) => (
          <DateTimePicker
            label={t('shipments_v2:shipment_form.estimated_pickup')}
            sx={{ pb: 2 }}
            onChange={(date: Dayjs | null) => field.onChange(date?.format())}
            format={dateFormat}
          />
        )}
      />

      <Controller
        control={control}
        name="carrier.dateestimateddelivery"
        render={({ field }) => (
          <DateTimePicker
            label={t('shipments_v2:shipment_form.estimated_delivery')}
            sx={{ pb: 2 }}
            onChange={(date: Dayjs | null) => field.onChange(date?.format())}
            format={dateFormat}
          />
        )}
      />

      <TextField
        error={!!formState?.errors.comment}
        fullWidth
        helperText={formState?.errors.comment?.message}
        label={t('shipments_v2:shipment_form.shipment_note')}
        {...register('comment')}
        multiline
        rows={4}
      />
    </FormFieldset>
  )
}
