import { Button } from '@mui/material'
import {
  GridColDef,
  GridRenderEditCellParams,
  useGridApiRef,
} from '@mui/x-data-grid-pro'
import { useTranslation } from 'react-i18next'
import { DataGrid } from '../../../../components/data-grid'
import { DataGridImageRenderer } from '../../../../components/data-grid-image'
import { SortItem } from '../../../../components/data-grid/filters/sort'
import {
  GetProductPurchasePricesQueryVariables,
  GetProductPurchasePricesQuery,
  GetProductsSort,
  GetProductPurchasePricesDocument,
  UpdatePurchasePriceListItemsDocument,
  DeletePurchasePriceListItemsDocument,
  GetPurchasePriceListsDocument,
} from '../../../../generated/graphql'
import { FormatCurrencyOptions, useMoney } from '../../../../hooks/money'
import { ArrayElement } from '../../../../utils/types'
import {
  StringRenderer,
  StringEditRenderer,
} from '../../../../components/data-grid/utils/string-renderer'
import { FolderIcon } from '@sitoo/mui-components'
import { useMemo, useContext, useCallback } from 'react'
import { useTracking } from '../../../../hooks/tracking'
import { useSnackbar } from 'notistack'
import { BulkActions } from './bulk-actions'
import { useNavigate } from 'react-router-dom'
import { RootRoute } from '../../..'
import { useMe } from '../../../../hooks/me'
import { useAuthorization } from '../../../../hooks/authorization'
import {
  DEFAULT_PRODUCT_STATE,
  FRANCHISE_SITE_ID_PARAM,
} from '../../../../utils/constants'
import { useAbsolutePath } from '../../../../hooks/absolute-path'
import { ProductStateRenderer } from '../../../products/product-list/product-state'
import {
  CaptionRenderer,
  CaptionRendererValue,
} from '../../../../components/data-grid/utils/caption-renderer'
import { ColumnProps } from '../../../../components/data-grid/utils/column-props'
import { useQuery, useMutation } from '@apollo/client'
import { FilterContext } from '../../../../components/data-grid/context'
import { CursorPagination } from '../../../../components/data-grid/cursor-pagination'
import { isPurchasePriceListFragment } from './is-purchase-price-list-fragment'
import { formatDecimal } from '../../../../utils/format/number'

export type Row = ArrayElement<
  GetProductPurchasePricesQuery['products']['items']
> & {
  purchasePriceValue: string | undefined
}

type PurchasePricesListProps = {
  onShowFilter(): void
}

const PAGE_SIZE = 100

export const PurchasePricesList = (props: PurchasePricesListProps) => {
  const { t } = useTranslation(['purchase_prices', 'shared', 'products'])
  const {
    modules: { writePurchasePrices },
  } = useAuthorization()
  const { trackButtonClickEvent, trackFormError, trackFormSuccess } =
    useTracking()
  const { enqueueSnackbar } = useSnackbar()
  const navigate = useNavigate()
  const generatePath = useAbsolutePath()

  const { data: priceListsData, loading: isPricelistLoading } = useQuery(
    GetPurchasePriceListsDocument,
    { variables: { num: 1000 } },
  )

  const { me } = useMe()

  const [updatePurchasePriceListItems] = useMutation(
    UpdatePurchasePriceListItemsDocument,
  )

  const [deletePurchasePriceListItems] = useMutation(
    DeletePurchasePriceListItemsDocument,
  )

  const apiRef = useGridApiRef()

  const { filter, isFilterReady } = useContext(FilterContext)

  const selectedPriceList = isPurchasePriceListFragment(
    filter?.['priceList']?.value,
  )
    ? filter?.['priceList']?.value
    : undefined

  const formatCurrencyOptions = useMemo<FormatCurrencyOptions>(
    () => ({ hideCurrency: true, forceDecimals: true }),
    [],
  )

  const { formatCurrency } = useMoney()

  const dataGridColumns = useMemo<GridColDef<Row>[]>(
    () => [
      {
        field: 'images',
        ...ColumnProps.image,
        headerName: '',
        valueGetter: (_value, row): string => row.images?.[0] || '',
        cellClassName: 'image-column',
        headerClassName: 'image-column',
        renderCell: DataGridImageRenderer,
      },
      {
        field: 'id',
        ...ColumnProps.productId,
        headerName: t('purchase_prices:product_id'),
      },
      {
        field: 'sku',
        ...ColumnProps.sku,
        headerName: t('purchase_prices:sku'),
      },
      {
        field: 'title',
        ...ColumnProps.productTitle,
        headerName: t('purchase_prices:product'),
        valueGetter: (_value, row): CaptionRendererValue => ({
          title: row.title,
          caption: row.variant?.[0]?.value,
        }),
        renderCell: CaptionRenderer,
      },
      {
        field: 'activepos',
        ...ColumnProps.productActive,
        headerName: t('products:status'),
        renderCell: ProductStateRenderer,
      },
      {
        field: 'custom-price',
        ...ColumnProps.price,
        headerName: `${t('products:price')} (${
          selectedPriceList?.currency ?? ''
        })`,
        valueGetter: (_value, row) => {
          const moneyPrice = row.pricelistitems?.find(
            (price) =>
              price.pricelist?.currencycode === me?.currentSite.currencycode,
          )?.moneyprice
          return moneyPrice || ''
        },
        valueFormatter: (value: string) =>
          formatCurrency(value, formatCurrencyOptions),
      },
      {
        field: 'moneypriceorg',
        ...ColumnProps.price,
        headerName: `${t('purchase_prices:suggested_retail_price')} (${
          selectedPriceList?.currency ?? ''
        })`,
        valueFormatter: (value: string) =>
          formatCurrency(value || '', formatCurrencyOptions),
      },
      {
        field: 'purchasePrice',
        ...ColumnProps.editablePrice,
        headerName: `${t('purchase_prices:purchase_price')} ${t(
          'purchase_prices:exclude',
        )} (${selectedPriceList?.currency ?? ''})`,
        editable:
          writePurchasePrices &&
          priceListsData?.purchasePriceLists.items?.find(
            (x) => x.id === selectedPriceList?.id,
          )?.owner_eshop_id === me?.siteId,

        valueGetter: (_, row) => row.purchasePriceValue,

        valueFormatter: (value: string): string | undefined => {
          return value
            ? formatCurrency(value, formatCurrencyOptions)
            : undefined
        },

        valueSetter: (value: string, row) => {
          const newPrice = formatDecimal(value, { locale: 'en' })

          return { ...row, purchasePriceValue: newPrice }
        },

        renderCell: (params: GridRenderEditCellParams<Row, string>) =>
          StringRenderer(params),

        renderEditCell: (props: GridRenderEditCellParams<Row>) => (
          <StringEditRenderer {...props} trackingPrefix="purchase_prices" />
        ),
      },
    ],
    [
      formatCurrency,
      formatCurrencyOptions,
      me?.currentSite.currencycode,
      me?.siteId,
      priceListsData?.purchasePriceLists.items,
      selectedPriceList?.currency,
      selectedPriceList?.id,
      t,
      writePurchasePrices,
    ],
  )

  const sortItems = useMemo<SortItem<GetProductsSort>[]>(
    () => [
      {
        field: 'id',
        sort: 'asc',
        title: t('purchase_prices:product_id'),
        type: 'number',
        value: GetProductsSort.productid_asc,
      },
      {
        field: 'id',
        sort: 'desc',
        title: t('purchase_prices:product_id'),
        type: 'number',
        value: GetProductsSort.productid_desc,
        isDefault: true,
      },
      {
        field: 'sku',
        sort: 'asc',
        title: t('purchase_prices:sku'),
        type: 'text',
        value: GetProductsSort.sku_asc,
      },
      {
        field: 'sku',
        sort: 'desc',
        title: t('purchase_prices:sku'),
        type: 'text',
        value: GetProductsSort.sku_desc,
      },
      {
        field: 'title',
        sort: 'asc',
        title: t('purchase_prices:product'),
        type: 'text',
        value: GetProductsSort.title_asc,
      },
      {
        field: 'title',
        sort: 'desc',
        title: t('purchase_prices:product'),
        type: 'text',
        value: GetProductsSort.title_desc,
      },
    ],
    [t],
  )

  const queryVariables = useMemo(() => {
    const config = {
      filter,
      pagination: {
        start: 0,
        page: 0,
        pageSize: PAGE_SIZE,
      },
      sorting: apiRef.current.state?.sorting.sortModel,
    }

    const variables: GetProductPurchasePricesQueryVariables = {
      num: config.pagination?.pageSize,
      start:
        (config.pagination?.page || 0) * (config.pagination?.pageSize || 0),
      priceListId: '',
      includeproductvariants: true,
      fallbackPriceToCommon: true,
    }

    if (selectedPriceList) {
      variables.priceListId = selectedPriceList.id
    } else {
      return
    }

    if (typeof config.filter?.['text']?.value === 'string') {
      variables.search = config.filter?.['text']?.value
    }

    const categoryIds = config.filter?.['categoryIds']?.value
    if (categoryIds) {
      variables.categoryIds = Array.isArray(categoryIds)
        ? categoryIds
        : [categoryIds]
    }

    if (config.sorting) {
      const sortItem = config.sorting[0]

      if (sortItem) {
        variables.sort = sortItems.find(
          (s) => s.field === sortItem.field && s.sort === sortItem.sort,
        )?.value
      }
    }

    if (config.filter?.['state']?.value) {
      variables.includeInactive = config.filter?.['state']?.value === 'all'
    } else {
      variables.includeInactive = DEFAULT_PRODUCT_STATE === 'all'
    }

    if (config.filter?.[FRANCHISE_SITE_ID_PARAM]?.value) {
      variables.productSiteId = Number(
        config.filter?.[FRANCHISE_SITE_ID_PARAM]?.value,
      )
    }

    return variables
  }, [filter, apiRef, selectedPriceList, sortItems])

  const {
    data,
    loading: fetchLoading,
    fetchMore,
    refetch: refetchProductPrices,
  } = useQuery(GetProductPurchasePricesDocument, {
    fetchPolicy: 'cache-and-network',
    variables: queryVariables,
    notifyOnNetworkStatusChange: true,
    skip: !isFilterReady,
  })

  const isLoading = fetchLoading || isPricelistLoading

  const rows = useMemo(() => {
    return data?.products.items?.map((item) => ({
      ...item,
      purchasePriceValue:
        typeof item.purchasePrice?.price === 'number'
          ? item.purchasePrice.price.toFixed(2)
          : undefined,
    }))
  }, [data])

  const fetchMoreItems = useCallback(() => {
    const { pageSize } = apiRef.current.state.pagination.paginationModel

    if (data?.products.totalcount) {
      return fetchMore({
        variables: {
          start: (data.products?.start || 0) + pageSize,
        },
      })
    }
  }, [apiRef, data, fetchMore])

  const processRowUpdate = async (newRow: Row, oldRow: Row) => {
    const priceListId =
      (newRow.purchasePrice?.priceListId || selectedPriceList?.id) ?? ''
    const newPrice = newRow.purchasePriceValue
    const oldPrice = oldRow.purchasePriceValue

    if (newPrice === oldPrice) return newRow

    try {
      if (newPrice) {
        await updatePurchasePriceListItems({
          variables: {
            priceListId,
            items: [
              {
                price: Number(newPrice),
                sku: newRow.sku,
                productId: newRow.id,
              },
            ],
          },
        })
      } else {
        await deletePurchasePriceListItems({
          variables: {
            priceListId: selectedPriceList?.id ?? '',
            skus: [newRow.sku],
          },
        })
      }

      trackFormSuccess({
        name: 'purchase-price-list-row',
      })
      enqueueSnackbar(
        t('purchase_prices:success_update_purchase_price_snackbar', {
          count: 1,
        }),
      )

      return newRow
    } catch (error) {
      trackFormError({
        name: 'purchase-price-list-row',
      })
      enqueueSnackbar(
        t('purchase_prices:failure_update_purchase_price_snackbar', {
          count: 1,
        }),
        { variant: 'error' },
      )

      return oldRow
    }
  }

  return (
    <DataGrid
      name="purchase-price-list"
      apiRef={apiRef}
      columns={dataGridColumns}
      rows={rows}
      rowCount={data?.products.totalcount || 0}
      loading={isLoading}
      fetchMore={fetchMoreItems}
      processRowUpdate={processRowUpdate}
      slots={{ pagination: CursorPagination }}
      noRowsOverlay={{
        icon: <FolderIcon />,
        title: t('purchase_prices:grid.empty_purchase_price_title'),
        description: t('purchase_prices:grid.empty_purchase_price_description'),
        action: (
          <Button
            onClick={trackButtonClickEvent(
              { name: 'purchase-price-list-add-product' },
              () =>
                navigate(generatePath(RootRoute.SettingsPurchasePriceLists)),
            )}
          >
            {t('purchase_prices:grid.empty_purchase_price_action')}
          </Button>
        ),
      }}
      bulkAction={
        <>
          {writePurchasePrices && (
            <BulkActions refetch={refetchProductPrices} />
          )}
        </>
      }
      hasTextFilter
      onShowFilter={props.onShowFilter}
      sx={{
        '.image-column': {
          padding: '0 !important',
        },
      }}
      rowHeight={50}
      disableColumnFilter
      checkboxSelection
      hasPageHeader
      paginationModel={{ page: 0, pageSize: PAGE_SIZE }}
      updateSearchParams
      sorting={sortItems}
      columnVisibilityModel={{
        id: false,
        activepos: false,
        moneypriceorg: false,
      }}
      showMore={
        Number(data?.products.items?.length) < Number(data?.products.totalcount)
      }
    />
  )
}
