import { ListItem } from '@mui/material'
import { SelectInput, Option } from '../../../../inputs/select-input'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useMemo } from 'react'
import { Form } from '..'
import { GiftCardType } from '../../../../generated/graphql'

export const GiftCardTypeInput = () => {
  const { control } = useFormContext<Form>()
  const { t } = useTranslation('reports')

  const giftCardTypes = useMemo<Option[]>(
    () => [
      { name: t('create_dialog.include_all'), value: '' },
      {
        name: t(`gift_card_type_keys.${GiftCardType.Giftcard}`),
        value: GiftCardType.Giftcard,
      },
      {
        name: t(`gift_card_type_keys.${GiftCardType.Creditnote}`),
        value: GiftCardType.Creditnote,
      },
    ],
    [t],
  )
  return (
    <ListItem>
      <SelectInput
        control={control}
        label={t('create_dialog.type')}
        name="reportdefinition.filters.giftCardType"
        options={giftCardTypes}
        slotProps={{ select: { displayEmpty: true } }}
      />
    </ListItem>
  )
}
