import { stripTypename } from '@apollo/client/utilities'
import { Button, Container } from '@mui/material'
import { FormProvider, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { Form } from '.'
import { RootRoute } from '../..'
import { FormRouteLeavingGuard } from '../../../components/form-route-leaving-guard'
import { PageHeader } from '../../../components/page-header'
import {
  PosProfile,
  PosProfilePrintReceiptEnum,
  PosProfileProductDisplayModeEnum,
  PosProfileSalesAssociateModeEnum,
  PosProfileStockStatusDisplayModeEnum,
} from '../../../generated/graphql'
import { useAbsolutePath } from '../../../hooks/absolute-path'
import { getDirtyValues } from '../../../utils/dirty-fields'
import { Name } from './sections/name'
import { OverridePaymentTypes } from './sections/override-payment-types'
import { PosOptions } from './sections/pos-options'
import { PosTheme } from './sections/pos-theme'
import { Receipt } from './sections/receipt'
import { ReturnPolicy } from './sections/return-policy'
import { TaxFree } from './sections/tax-free'
import { DeletePosProfile } from './sections/delete-profile'

type Props = {
  onSubmit: (fieldsToUpdate: Partial<Form>) => void
  formId: string
  posProfile?: PosProfile
}

export const PosProfileForm = ({ formId, onSubmit, posProfile }: Props) => {
  const { id: posProfileId } = useParams()
  const isNewPosProfile = !posProfileId

  const { t } = useTranslation(['shared', 'pos_profiles'])
  const generatePath = useAbsolutePath()

  const newPosProfileDefaultValues = {
    allowAllInventoryFeatures: true,
    overrideDefaultPosTheme: false,
    limitTaxFreeOptions: false,
    name: '',
    logotype: null,
    receipt_pre: '',
    receipt_post: '',
    receipt_addbarcodeid: false,
    receipt_showsku: false,
    receipt_item_rendering: null,
    receipt_salestax_rendering: null,
    autologouttimer: 0,
    auto_logout_after_commit: false,
    productdisplaymode: PosProfileProductDisplayModeEnum.Tiles,
    stockstatusdisplaymode: PosProfileStockStatusDisplayModeEnum.DoNotShow,
    printreceipt: PosProfilePrintReceiptEnum.Automatic,
    printgiftreceipt: null,
    return_policy: null,
    dashboardid: null,
    override_paymenttypes: null,
    custom_metrics: null,
    salesassociate_mode: PosProfileSalesAssociateModeEnum.NotActive,
    shipments_show_costprice: false,
    extra_sign_return_receipt: [],
    pos_inventory_features: null,
    pos_theme_id: null,
    pos_theme_data: null,
    tax_free_options: null,
  }

  const defaultValues = isNewPosProfile
    ? newPosProfileDefaultValues
    : {
        ...stripTypename(posProfile),
        allowAllInventoryFeatures: !posProfile?.pos_inventory_features,
        overrideDefaultPosTheme: posProfile?.pos_theme_id != null,
        limitTaxFreeOptions: !!posProfile?.tax_free_options,
      }

  const formContext = useForm<Form>({
    defaultValues,
    values: defaultValues as Form,
  })

  const handleSubmit = (formData: Form) => {
    try {
      formContext.clearErrors()
      const dirtyFieldsRecord = formContext.formState.dirtyFields
      const fieldsToUpdate = getDirtyValues(dirtyFieldsRecord, formData)

      const {
        allowAllInventoryFeatures,
        overrideDefaultPosTheme,
        limitTaxFreeOptions,
        ...rest
      } = fieldsToUpdate

      if (formData.allowAllInventoryFeatures) {
        rest.pos_inventory_features = null
      }
      if (!formData.overrideDefaultPosTheme) {
        rest.pos_theme_id = null
        rest.pos_theme_data = null
      }
      if (!formData.limitTaxFreeOptions) {
        rest.tax_free_options = null
      }

      onSubmit(rest)
    } catch (error) {
      // Do nothing, handled by onSubmit
    }
  }

  return (
    <FormProvider {...formContext}>
      <PageHeader
        title={t(
          isNewPosProfile
            ? 'pos_profiles:add_pos_profile'
            : 'pos_profiles:edit_pos_profile',
        )}
        backTo={generatePath(RootRoute.SettingsPosProfiles)}
        backText={t('pos_profiles:page_title')}
        showBackButton={true}
        isSticky
        rightColumn={
          <Button
            type="submit"
            disabled={!formContext.formState.isDirty}
            form={formId}
          >
            {t('shared:action.save')}
          </Button>
        }
      />
      <Container sx={{ mb: 10 }}>
        <FormRouteLeavingGuard />
        <form
          id={formId}
          noValidate
          onSubmit={formContext.handleSubmit(handleSubmit)}
        >
          <Name />
          <Receipt />
          <PosOptions />
          <ReturnPolicy />
          <OverridePaymentTypes />
          <PosTheme />
          <TaxFree />
        </form>
        {isNewPosProfile ? null : <DeletePosProfile />}
      </Container>
    </FormProvider>
  )
}
