import { StaticDatePicker } from '@mui/x-date-pickers-pro'
import {
  Button,
  DialogProps,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useCallback, useEffect, useState } from 'react'
import { useDayJs } from '../../../hooks/day-js'
import { usePrevious } from 'react-use'
import { nanoid } from 'nanoid'
import { useLazyQuery } from '@apollo/client'
import {
  GetCashRegisterDocument,
  GetJournalEntriesDocument,
} from '../../../generated/graphql'
import { RootRoute } from '../..'
import { useAbsolutePath } from '../../../hooks/absolute-path'

type Props = DialogProps & {
  registerId: string
  onClose(): void
}

export const PrintDatePickerDialog = (props: Props) => {
  const { t } = useTranslation(['cash_registers', 'shared'])
  const { registerId, onClose, ...dialogProps } = props
  const prevOpen = usePrevious(props.open)
  const dayJs = useDayJs()
  const maxDate = dayJs().subtract(1, 'day')
  const [date, setDate] = useState(maxDate)

  const [executeJournalsQuery, { loading: journalsLoading }] = useLazyQuery(
    GetJournalEntriesDocument,
    { fetchPolicy: 'cache-and-network' },
  )

  const [executeRegisterQuery, { loading: registerLoading }] = useLazyQuery(
    GetCashRegisterDocument,
    { fetchPolicy: 'cache-only' },
  )

  const isLoading = journalsLoading || registerLoading

  const generatePath = useAbsolutePath()

  useEffect(() => {
    if (props.open && !prevOpen) {
      setDate(maxDate)
    }
  }, [props.open, maxDate, prevOpen])

  const fetchJournals = useCallback(() => {
    const dateFrom = dayJs(date).startOf('day').toJSON()
    const dateTo = dayJs(date).endOf('day').toJSON()

    void (async () => {
      const { data: journalsResponse } = await executeJournalsQuery({
        variables: {
          dateFrom,
          dateTo,
          registerIds: [registerId],
          num: 1000,
        },
      })
      const { data: cashRegisterResponse } = await executeRegisterQuery({
        variables: {
          id: registerId,
        },
      })

      const journalData = journalsResponse?.journalEntries
      const cashRegistersData = cashRegisterResponse?.cashRegister

      if (!journalData || !cashRegistersData) return

      const printId = nanoid()

      sessionStorage.setItem(
        printId,
        JSON.stringify({
          journalsData: journalData,
          registerData: cashRegistersData,
          reportDate: date.toDate(),
        }),
      )

      window.open(
        generatePath(RootRoute.CashRegistersPrint, { id: printId }),
        '_blank',
      )

      onClose()
    })()
  }, [
    date,
    dayJs,
    executeJournalsQuery,
    executeRegisterQuery,
    generatePath,
    onClose,
    registerId,
  ])

  return (
    <Dialog onClose={onClose} maxWidth="xs" {...dialogProps}>
      <DialogTitle type="extended" sx={{ pb: 0 }}>
        {t('cash_registers:print_journal')}
      </DialogTitle>
      <DialogContent sx={{ p: 1 }}>
        <StaticDatePicker
          disableHighlightToday
          onChange={(value) => {
            if (value) {
              setDate(value)
            }
          }}
          slotProps={{
            toolbar: {
              hidden: true,
            },
            actionBar: {
              actions: [],
            },
          }}
          value={date}
          maxDate={maxDate}
        />
      </DialogContent>
      <DialogActions sx={{ pt: 0 }}>
        <Button color="secondary" onClick={onClose}>
          {t('shared:action.cancel')}
        </Button>

        <Button loading={isLoading} onClick={() => fetchJournals()}>
          {t('shared:action.print')}
        </Button>
      </DialogActions>
    </Dialog>
  )
}
