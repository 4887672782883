import { useFormContext, useWatch } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { Form } from '../../..'
import { SettingsListItem } from '../../../../../../components/settings-list-item'
import { SelectInput } from '../../../../../../inputs/select-input'
import useGetTimerString from '../../../../pos-profiles/use-get-timer-string'
import { useMemo } from 'react'

export const AutoLogoutTimerField = () => {
  const { t } = useTranslation(['pos_profiles'])
  const { control } = useFormContext<Form>()
  const getTimerString = useGetTimerString()

  const initialValue = useWatch({ control, name: 'autologouttimer' })

  const autoLogoutTimerOptions = useMemo(() => {
    const timeValues = [0, 30, 60, 180, 300, 600]

    if (initialValue && !timeValues.includes(initialValue)) {
      timeValues.push(initialValue)
    }

    return timeValues
      .sort((a, b) => a - b)
      .map((value) => ({
        name:
          value === 0
            ? t('pos_profiles:pos_options_section.auto_logout_timer_none')
            : getTimerString(value),
        value,
      }))
  }, [getTimerString, initialValue, t])

  return (
    <SettingsListItem sx={{ mt: 2 }}>
      <SelectInput
        label={t('pos_profiles:pos_options_section.auto_logout_timer_label')}
        helperText={t(
          'pos_profiles:pos_options_section.auto_logout_timer_description',
        )}
        name={'autologouttimer'}
        control={control}
        options={autoLogoutTimerOptions}
        slotProps={{ select: { displayEmpty: true } }}
      />
    </SettingsListItem>
  )
}
