import { DataGrid } from '../../../../components/data-grid'
import { GridColDef, useGridApiRef } from '@mui/x-data-grid-pro'
import { WarehouseIcon } from '@sitoo/mui-components'
import { useTranslation } from 'react-i18next'
import {
  AllOpenWarehouseBatchesDocument,
  WarehouseBatchTransaction,
  AllOpenWarehouseBatchesQuery,
} from '../../../../generated/graphql'
import { ArrayElement } from '../../../../utils/types'
import { useMemo } from 'react'
import { useLocalizedDate } from '../../../../hooks/localized-date'
import { useNavigate } from 'react-router-dom'
import { RootRoute } from '../../..'
import { useAbsolutePath } from '../../../../hooks/absolute-path'
import { ColumnProps } from '../../../../components/data-grid/utils/column-props'
import { RelativeDateRenderer } from '../../../../components/data-grid/utils/relative-date-renderer'
import { useQuery } from '@apollo/client'

type Row = ArrayElement<AllOpenWarehouseBatchesQuery['allOpenWarehouseBatches']>

export const DeliveryInList = () => {
  const { t } = useTranslation(['delivery_in'])
  const { formatRelativeDate, formatDate } = useLocalizedDate()
  const navigate = useNavigate()
  const generatePath = useAbsolutePath()

  const apiRef = useGridApiRef()

  const { data, loading } = useQuery(AllOpenWarehouseBatchesDocument, {
    variables: {
      transactiontype: WarehouseBatchTransaction.ManualIn,
    },
    fetchPolicy: 'cache-and-network',
  })

  const dataGridColumns = useMemo<GridColDef<Row>[]>(
    () => [
      {
        field: 'warehousebatchid',
        minWidth: 80,
        headerName: t('delivery_in:id'),
      },
      {
        field: 'datecreated',
        ...ColumnProps.date,
        headerName: t('delivery_in:date_created'),
        renderCell: (params) => (
          <RelativeDateRenderer
            {...params}
            formatDate={formatDate}
            formatRelativeDate={formatRelativeDate}
          />
        ),
      },
      {
        field: 'custom-user',
        headerName: t('delivery_in:user'),
        minWidth: 160,
        valueGetter: (_value, row): string => {
          if (row.user && (row.user.namefirst || row.user.namelast)) {
            return [row.user.namefirst, row.user.namelast]
              .filter(Boolean)
              .join(' ')
          }

          return row.emailowner
        },
      },
      {
        field: 'custom-store',
        minWidth: 200,
        headerName: t('delivery_in:store'),
        valueGetter: (_value, row): string => {
          return row.warehouse?.store?.name || ''
        },
      },
      {
        field: 'custom-warehouse',
        minWidth: 200,
        headerName: t('delivery_in:warehouse'),
        valueGetter: (_value, row): string => {
          return row.warehouse?.name || ''
        },
      },
    ],
    [formatDate, formatRelativeDate, t],
  )

  const warehouseBatches = useMemo(() => {
    return data?.allOpenWarehouseBatches?.map((x) => ({
      ...x,
      id: x.warehousebatchid,
    }))
  }, [data?.allOpenWarehouseBatches])

  return (
    <DataGrid
      name="delivery-in"
      apiRef={apiRef}
      columns={dataGridColumns}
      rows={warehouseBatches}
      rowCount={warehouseBatches?.length || 0}
      loading={loading}
      noRowsOverlay={{
        icon: <WarehouseIcon />,
        title: t('delivery_in:delivery_in.empty_title'),
        description: t('delivery_in:delivery_in.empty_description'),
      }}
      disableColumnFilter
      rowHeight={50}
      hasPageHeader
      paginationModel={{ page: 0, pageSize: 100 }}
      updateSearchParams
      columnVisibilityModel={{ warehousebatchid: false }}
      onRowClick={(params) =>
        void navigate(
          generatePath(RootRoute.DeliveryIn, {
            warehouseId: String((params.row as Row).warehouseid),
            warehouseBatchId: String((params.row as Row).warehousebatchid),
          }),
        )
      }
      showMore
    />
  )
}
