import { PropsWithChildren, useId } from 'react'
import { SubmitErrorHandler, useFormContext } from 'react-hook-form'
import { enqueueSnackbar } from 'notistack'
import { useTracking } from '../../../hooks/tracking'
import { getErrorMessages } from '../../../utils/error-mapping'
import { ListItem, Button, Divider, Stack } from '@mui/material'
import { RouteLeavingGuard } from '../../../components/route-leaving-guard'
import { useTranslation } from 'react-i18next'

type Props<T> = PropsWithChildren & {
  onSubmit(form: T): Promise<void>
}

export const PluginsForm = <T extends Record<string, unknown>>(
  props: Props<T>,
) => {
  const { t } = useTranslation(['plugins'])
  const formId = useId()

  const { formState, handleSubmit, reset } = useFormContext<T>()

  const { isDirty, isSubmitSuccessful } = formState

  const { trackButtonClick, trackFormSuccess, trackFormError } = useTracking()

  const onSubmit = async (data: T) => {
    trackButtonClick({ name: 'plugins-form-save' })

    try {
      await props.onSubmit(data)

      reset(data, { keepDefaultValues: false })

      trackFormSuccess({ name: 'plugins-form-success' })

      enqueueSnackbar(t('plugins:success_edit'))
    } catch (error) {
      const errorMessage = getErrorMessages(error, {
        format: (errorCode) =>
          t(`plugins:error.${errorCode}`, {
            defaultValue: t('plugins:error.generic'),
          }),
      }).join(', ')

      trackFormError({ name: 'plugins-form-error', errorMessage })

      enqueueSnackbar(errorMessage, { variant: 'error' })
    }
  }

  const onError: SubmitErrorHandler<T> = (_errors) => {
    enqueueSnackbar(t('plugins:failure_edit'), { variant: 'error' })
  }

  return (
    <form id={formId} onSubmit={handleSubmit(onSubmit, onError)}>
      <RouteLeavingGuard when={isDirty && !isSubmitSuccessful} />

      {props.children}

      <Divider />

      <ListItem sx={{ justifyContent: 'flex-end' }}>
        <Stack direction="row" spacing={1} sx={{ my: 2 }}>
          <Button
            size="small"
            color="secondary"
            disabled={!formState.isDirty}
            onClick={() => reset()}
          >
            {t('shared:action.cancel')}
          </Button>

          <Button
            form={formId}
            type="submit"
            disabled={!formState.isDirty}
            size="small"
          >
            {t('shared:action.save')}
          </Button>
        </Stack>
      </ListItem>
    </form>
  )
}
