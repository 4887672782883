import { useQuery } from '@apollo/client'
import { useGridApiRef, GridColDef, GridRowParams } from '@mui/x-data-grid-pro'
import { FolderIcon } from '@sitoo/mui-components'
import { useMemo, useContext, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { FilterContext } from '../../../../components/data-grid/context'
import { CursorPagination } from '../../../../components/data-grid/cursor-pagination'
import { ColumnProps } from '../../../../components/data-grid/utils/column-props'
import { RelativeDateRenderer } from '../../../../components/data-grid/utils/relative-date-renderer'
import {
  AccessLogsDocument,
  AccessLogsQuery,
  AccessLogsQueryVariables,
} from '../../../../generated/graphql'
import { useLocalizedDate } from '../../../../hooks/localized-date'
import { ArrayElement } from '../../../../utils/types'
import { DataGrid } from '../../../../components/data-grid'
import { EventResult } from '../event-result'
import { EventType } from '../event-type'
import { useDayJs } from '../../../../hooks/day-js'

type Props = {
  onRowClick: (id: string) => void
  selectedId?: string
  onShowFilter(): void
}

export type AccessEventData = {
  ip?: string
  email?: string
  result?: string
  session_type?: string
}

type Row = ArrayElement<AccessLogsQuery['accessLogs']['items']> & {
  data: AccessEventData
}

const PAGE_SIZE = 100

export const EventList = (props: Props) => {
  const { t } = useTranslation(['shared', 'log_events'])
  const apiRef = useGridApiRef()
  const { formatRelativeDate, formatDate } = useLocalizedDate()
  const { filter, isFilterReady } = useContext(FilterContext)
  const dayjs = useDayJs()

  const [dateTo, dateFrom] = useMemo(
    () => [dayjs().toJSON(), dayjs().subtract(1).toJSON()],
    [dayjs],
  )

  const queryVariables = useMemo(() => {
    const config = {
      filter,
      pagination: {
        start: 0,
        page: 0,
        pageSize: PAGE_SIZE,
      },
    }

    const variables: AccessLogsQueryVariables = {
      dateTo,
      dateFrom,
      num: config.pagination?.pageSize,
      start:
        (config.pagination?.page || 0) * (config.pagination?.pageSize || 0),
    }

    if (typeof config.filter?.['date']?.value === 'string') {
      variables.dateFrom = config.filter['date']?.value
    } else if (Array.isArray(config.filter?.['date']?.value)) {
      const value = config.filter?.['date']?.value

      if (typeof value?.[0] === 'string') {
        variables.dateFrom = value[0]
      }

      if (typeof value?.[1] === 'string') {
        variables.dateTo = value[1]
      }
    }

    return variables
  }, [dateTo, dateFrom, filter])

  const {
    data,
    loading: isLoading,
    fetchMore,
  } = useQuery(AccessLogsDocument, {
    fetchPolicy: 'cache-and-network',
    variables: queryVariables,
    notifyOnNetworkStatusChange: true,
    skip: !isFilterReady,
  })

  const fetchMoreItems = useCallback(() => {
    const { pageSize } = apiRef.current.state.pagination.paginationModel

    if (data?.accessLogs.next) {
      return fetchMore({
        variables: {
          start: (data.accessLogs?.start || 0) + pageSize,
          next: data.accessLogs.next,
        },
      })
    }
  }, [apiRef, data, fetchMore])

  const dataGridColumns = useMemo<GridColDef<Row>[]>(
    () => [
      {
        field: 'id',
        minWidth: 280,
        headerName: t('log_events:id'),
      },
      {
        field: 'timestamp',
        ...ColumnProps.date,
        headerName: t('log_events:date'),
        renderCell: (params) => (
          <RelativeDateRenderer
            {...params}
            formatDate={formatDate}
            formatRelativeDate={formatRelativeDate}
          />
        ),
      },
      {
        field: 'user',
        headerName: t('log_events:user'),
        minWidth: 280,
        valueGetter: (_value, row) => row.data['email'],
      },
      {
        field: 'session_type',
        minWidth: 120,
        headerName: t('log_events:session_type'),
        valueGetter: (_value, row) => row.data.session_type,
        renderCell: (params) => (
          <EventType type={params.row.data.session_type} />
        ),
      },
      {
        field: 'result',
        minWidth: 120,
        headerName: t('log_events:result'),
        valueGetter: (_value, row) => row.data['result'],
        renderCell: (params) => <EventResult result={params.row.data.result} />,
      },
      {
        field: 'ip',
        minWidth: 120,
        headerName: t('log_events:ip'),
        valueGetter: (_value, row) => row.data.ip,
      },
    ],
    [formatDate, formatRelativeDate, t],
  )

  return (
    <DataGrid
      apiRef={apiRef}
      name="access-logs-list"
      columns={dataGridColumns}
      rows={data?.accessLogs.items || []}
      rowCount={
        data?.accessLogs?.items?.length ? data.accessLogs.items.length + 1 : 0
      }
      loading={isLoading}
      fetchMore={fetchMoreItems}
      onRowClick={({ row }: GridRowParams<Row>) => {
        props.onRowClick(row.id)
      }}
      noRowsOverlay={{
        icon: <FolderIcon />,
        title: t('log_events:grid.empty_title'),
        description: t('log_events:grid.empty_description'),
      }}
      rowHeight={50}
      sx={{
        '.image-column': {
          padding: '0 !important',
        },
      }}
      slots={{ pagination: CursorPagination }}
      onShowFilter={props.onShowFilter}
      disableColumnFilter
      hasPageHeader
      paginationModel={{ page: 0, pageSize: PAGE_SIZE }}
      updateSearchParams
      hideTotalCount
      getRowClassName={(params) =>
        params.id === props.selectedId ? 'Mui-selected' : ''
      }
      columnVisibilityModel={{ id: false }}
      showMore={!!data?.accessLogs.next}
    />
  )
}
