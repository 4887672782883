import { useQuery } from '@apollo/client'
import { Chip, Container } from '@mui/material'
import { GridColDef, useGridApiRef } from '@mui/x-data-grid-pro'
import { useCallback, useContext, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { createSearchParams, useNavigate } from 'react-router-dom'
import { reportListFilterPanelVar } from '..'
import { RootRoute } from '../..'
import { DataGrid } from '../../../components/data-grid'
import { FilterContext } from '../../../components/data-grid/context'
import { CursorPagination } from '../../../components/data-grid/cursor-pagination'
import { TextFilter } from '../../../components/data-grid/filters/text-filter'
import {
  ReportJobsDocument,
  ReportJobsQuery,
  ReportJobsQueryVariables,
  ReportJobState,
} from '../../../generated/graphql'
import { useAbsolutePath } from '../../../hooks/absolute-path'
import { useLocalizedDate } from '../../../hooks/localized-date'
import { useTracking } from '../../../hooks/tracking'
import { ArrayElement } from '../../../utils/types'
import { DateRangeRenderer } from '../date-range-renderer'
import { DeleteReportJobsBulkAction } from '../delete-report-jobs-bulk-action'
import { ReportJobDetailsRenderer } from '../report-job-details-renderer'
import { ReportJobOwnerFilter } from '../list-filter-panel/owner-filter'
import { ReportJobStateChip } from '../report-job-state-chip'

type Row = ArrayElement<ReportJobsQuery['reportJobs']['items']>
type Filter = ReportJobOwnerFilter & TextFilter
const PAGE_SIZE = 100

export const ReportJobsAllPage = () => {
  const { t } = useTranslation(['shared', 'reports'])
  const navigate = useNavigate()
  const generatePath = useAbsolutePath()
  const { formatRelativeDate } = useLocalizedDate()
  const apiRef = useGridApiRef()

  const { filter, isFilterReady } =
    useContext<FilterContext<Filter>>(FilterContext)
  const { trackButtonClick } = useTracking()

  const onShowFilter = () => {
    trackButtonClick({ name: 'reports-filter-panel-open' })
    reportListFilterPanelVar({ isOpen: !reportListFilterPanelVar().isOpen })
  }

  const queryVariables = useMemo(() => {
    const config = {
      pagination: {
        start: 0,
        pageSize: PAGE_SIZE,
      },
      filter,
    }
    const variables: ReportJobsQueryVariables = {
      num: config.pagination?.pageSize,
      start: 0,
      searchtext: filter.text?.value,
    }

    variables.owner = config.filter.owner?.value

    return variables
  }, [filter])

  const {
    data: reportJobsData,
    loading: isLoadingReportJobs,
    fetchMore,
    refetch,
  } = useQuery(ReportJobsDocument, {
    variables: queryVariables,
    skip: !isFilterReady,
    fetchPolicy: 'cache-and-network',
    notifyOnNetworkStatusChange: true,
  })

  const jobsToPoll = useMemo(
    () =>
      reportJobsData?.reportJobs?.items
        ?.filter((job) =>
          [ReportJobState.InProgress, ReportJobState.Pending].includes(
            job.jobstate,
          ),
        )
        .map((job) => job.jobid),
    [reportJobsData?.reportJobs?.items],
  )

  useQuery(ReportJobsDocument, {
    variables: {
      ...queryVariables,
      jobIds: jobsToPoll,
    },
    skip: !jobsToPoll?.length,
    fetchPolicy: 'cache-and-network',
    pollInterval: 3000,
  })

  const dataGridColumns = useMemo<GridColDef<Row>[]>(() => {
    const columns: GridColDef<Row>[] = [
      {
        field: 'jobname',
        minWidth: 200,
        headerName: t('reports:columns.name'),
      },
      {
        field: 'dateexectued',
        minWidth: 120,
        headerName: t('reports:columns.generated'),
        valueGetter: (_value, row) => {
          if (row.dateexecuted)
            return formatRelativeDate(row.dateexecuted ?? '')
        },
      },
      {
        field: 'jobstate',
        minWidth: 80,
        headerName: t('reports:columns.state'),
        renderCell: (value) => (
          <ReportJobStateChip jobState={value.row.jobstate} />
        ),
      },
      {
        field: 'reporttype',
        minWidth: 80,
        headerName: t('reports:columns.type'),
        renderCell: (value) => (
          <Chip
            color="gray"
            label={t(`reports:report_types.${value.row.reporttype}`)}
            size="small"
          />
        ),
      },
      { field: 'owner', minWidth: 160, headerName: t('reports:columns.owner') },
      {
        field: 'daterange',
        minWidth: 180,
        headerName: t('reports:columns.date_range'),
        renderCell: DateRangeRenderer,
      },
      {
        field: 'reportdefinition',
        minWidth: 300,
        flex: 1,
        headerName: t('reports:columns.details'),
        renderCell: ReportJobDetailsRenderer,
      },
    ]

    return columns
  }, [formatRelativeDate, t])

  const fetchMoreItems = useCallback(() => {
    const { pageSize } = apiRef.current.state.pagination.paginationModel
    if (reportJobsData?.reportJobs.totalcount) {
      return fetchMore({
        variables: {
          start: (reportJobsData?.reportJobs?.start ?? 0) + pageSize,
          num: pageSize,
        },
      })
    }
  }, [apiRef, fetchMore, reportJobsData])

  const rows = useMemo(
    () => reportJobsData?.reportJobs.items || [],
    [reportJobsData?.reportJobs.items],
  )

  return (
    <Container maxWidth={false}>
      <DataGrid
        name="all-report-jobs-list"
        apiRef={apiRef}
        loading={isLoadingReportJobs}
        rowHeight={51}
        columns={dataGridColumns}
        getRowId={(row) => row.jobid}
        rows={rows}
        rowCount={reportJobsData?.reportJobs.totalcount || 0}
        fetchMore={fetchMoreItems}
        onRowClick={(row) =>
          void navigate({
            pathname: generatePath(RootRoute.ReportJob, { id: row.id }),
            search: createSearchParams({
              backTo: location.pathname,
            }).toString(),
          })
        }
        slots={{ pagination: CursorPagination }}
        paginationModel={{ page: 0, pageSize: PAGE_SIZE }}
        onShowFilter={onShowFilter}
        bulkAction={<DeleteReportJobsBulkAction refetch={refetch} />}
        checkboxSelection
        hasPageHeader
        hasTextFilter
        updateSearchParams
        columnVisibilityModel={{
          jobname: true,
          dateexecuted: true,
          type: true,
          details: true,
          daterange: true,
          owner: true,
        }}
        showMore={
          Number(reportJobsData?.reportJobs.items?.length) <
          Number(reportJobsData?.reportJobs.totalcount)
        }
      />
    </Container>
  )
}
