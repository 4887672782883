import {
  FieldValues,
  UseControllerProps,
  FieldPath,
  PathValue,
} from 'react-hook-form'
import { AutocompleteInput } from '../autocomplete-input'
import { useQuery } from '@apollo/client'
import { useMemo } from 'react'
import { AllPriceListTagsDocument } from '../../generated/graphql'
import { useTranslation } from 'react-i18next'
import { Theme, SxProps } from '@mui/material'

type Props = {
  sx?: SxProps<Theme>
  label?: string
  required?: boolean
}

export const PriceListTagsInput = <
  TFieldValues extends FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
>(
  props: Props & UseControllerProps<TFieldValues, TName>,
) => {
  const { t } = useTranslation(['settings'])
  const { control, name, required } = props

  const { data: tagsData } = useQuery(AllPriceListTagsDocument)

  const tags = useMemo(
    () => tagsData?.allPriceListTags || [],
    [tagsData?.allPriceListTags],
  )

  const validate = (value: string[]) => {
    const TAG_REGEX = /^[\w-]{1,16}$/

    if (Array.isArray(value)) {
      const isValid = value.every((v) => TAG_REGEX.test(v))

      if (!isValid) {
        return t('settings:price_lists.tag_validation')
      }
    }

    return true
  }

  return (
    <AutocompleteInput
      name={name}
      sx={props.sx}
      control={control}
      multiple
      freeSolo
      transform={{
        output: (options) => {
          const option = options.map((option) => option.toUpperCase())
          return option as PathValue<TFieldValues, TName>
        },
      }}
      rules={{ validate, required }}
      options={tags}
      label={props.label}
    />
  )
}
