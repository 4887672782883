import { Box, Typography } from '@mui/material'
import { ReportIcon } from '@sitoo/mui-components'
import { useTranslation } from 'react-i18next'
import {
  NAVBAR_HEIGHT,
  PAGE_BOTTOM_OFFSET,
  PAGE_HEADER_HEIGHT,
} from '../../../utils/constants'

export const NoReportSelected = () => {
  const { t } = useTranslation(['reports'])

  return (
    <Box
      sx={{
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        minHeight: `calc(100vh - ${NAVBAR_HEIGHT + PAGE_HEADER_HEIGHT + PAGE_BOTTOM_OFFSET * 2}px)`,
      }}
    >
      <ReportIcon
        fontSize="large"
        sx={{
          marginBottom: (theme) => theme.spacing(1),
        }}
      />
      <Typography variant="body02">{t('create.instruction_title')}</Typography>
      <Typography variant="caption">
        {t('create.instruction_description')}
      </Typography>
    </Box>
  )
}
