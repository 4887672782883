import { Chip, Stack, Tooltip, Typography } from '@mui/material'
import { useMemo } from 'react'

type Props = {
  filterKey: string
  values: string[]
}

export const PlusMoreChip = (props: Props) => {
  const { filterKey, values } = props

  const filterLabel = useMemo(() => {
    const keyPart = `${filterKey}: `

    if (values.length <= 2) {
      return keyPart + values.join(', ')
    }
    return keyPart + `${values.slice(0, 2).join(', ')}, +${values.length - 2}`
  }, [filterKey, values])

  return (
    <Tooltip
      key={`${filterKey}-tooltip`}
      enterTouchDelay={0} // Default is 700ms which requires a long press on mobile
      title={
        <Stack>
          {values.map((value, index) => (
            <Typography key={`${value}-${index}`} variant="caption">
              {value}
            </Typography>
          ))}
        </Stack>
      }
    >
      <Chip key={filterKey} size="small" label={filterLabel} color="gray" />
    </Tooltip>
  )
}
