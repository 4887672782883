import { FormFieldset } from '@sitoo/mui-components'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { BaseUserContext } from '../shared'
import { TimeZonesInput } from '../../../inputs/time-zones-input'

export const TimeZoneField = () => {
  const { t } = useTranslation(['users'])
  const { control } = useFormContext<BaseUserContext>()

  return (
    <FormFieldset label={t('user_form.settings_fieldset')}>
      <TimeZonesInput
        control={control}
        name="timezone"
        label={t('shared:time_zones.override_time_zone')}
        textFieldProps={{
          placeholder: t('shared:time_zones.use_system_time_zone'),
        }}
      />
    </FormFieldset>
  )
}
