import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  List,
  ListItemText,
} from '@mui/material'
import {
  PrimitiveCircleSmallIcon,
  PrintIcon,
  ReceiptIcon,
} from '@sitoo/mui-components'
import { Fragment } from 'react'
import { useTranslation } from 'react-i18next'
import { ListItemIconTimeline } from '../../../../components/list-item-icon-timeline'
import { ListItemTimeline } from '../../../../components/list-item-timeline'
import {
  GetOrderHistoryQuery,
  JournalEntryType,
} from '../../../../generated/graphql'
import { useLocalizedDate } from '../../../../hooks/localized-date'
import { ArrayElement } from '../../../../utils/types'
import { useMoney } from '../../../../hooks/money'

type Order = Exclude<GetOrderHistoryQuery['order'], undefined>

type Props = {
  isLoading?: boolean
  order?: Order
}

export const PurchaseHistory = (props: Props) => {
  const { t } = useTranslation(['shared', 'orders', 'journals'])
  const { formatRelativeDate, formatDate } = useLocalizedDate()
  const { formatCurrency } = useMoney()

  const logs = (props.order?.purchaseJournal || []).slice().reverse()

  const getLogTitle = (
    logType: JournalEntryType,
    data: ArrayElement<Order['purchaseJournal']>['data'],
  ) => {
    if (
      logType === JournalEntryType.Printout &&
      data.__typename === 'JournalPrintout'
    ) {
      return t(`journals:printout_printed`, {
        printout: t(`journals:printout_type_enum.${data.type}`),
      })
    }

    return t(`journals:journal_entry_type_enum.${logType}`)
  }

  const getLogIcon = (logType: JournalEntryType) => {
    switch (logType) {
      case JournalEntryType.Receipt:
        return ReceiptIcon

      case JournalEntryType.Printout:
        return PrintIcon

      default:
        return PrimitiveCircleSmallIcon
    }
  }

  const getDetailsContent = (log: ArrayElement<Order['purchaseJournal']>) => {
    const details: string[] = []

    if (log?.dateadded) {
      details.push(`${t('journals:added_at')}: ${formatDate(log.dateadded)}`)
    }

    details.push(`${t('journals:created_at')}: ${formatDate(log.datecreated)}`)
    details.push(`${t('journals:register_key')}: ${log.registerkey}`)
    details.push(`${t('journals:register_id')}: ${log.registerid}`)

    if ('staff' in log.data) {
      details.push(`${t('journals:staff')}: ${log.data.staff}`)
      details.push(`${t('journals:staff_id')}: ${log.data.staffuserid}`)
    }

    if (log?.ispractice)
      details.push(`${t('journals:is_practice')}: ${t('shared:label.yes')}`)

    switch (log.data.__typename) {
      case 'JournalCart':
        details.push(`${t('journals:cart_id')}: ${log.data.id}`)
        details.push(
          `${t('journals:quantity_total')}: ${log.data.quantitytotal}`,
        )
        details.push(
          `${t('journals:money_discount')}: ${formatCurrency(
            log.data.moneydiscount,
          )}`,
        )
        details.push(
          `${t('journals:money_total')}: ${formatCurrency(
            log.data.moneytotal,
          )}`,
        )
        break
      case 'JournalCartItem':
        details.push(`${t('journals:cart_id')}: ${log.data.cart_id}`)
        details.push(`${t('journals:sku')}: ${log.data.sku}`)

        if (log.data.JournalCartItemMoneyDiscount)
          details.push(
            `${t('journals:money_discount')}: ${formatCurrency(
              log.data.JournalCartItemMoneyDiscount,
            )}`,
          )

        if (log.data.JournalCartItemMoneyTotal)
          details.push(
            `${t('journals:money_total')}: ${formatCurrency(
              log.data.JournalCartItemMoneyTotal,
            )}`,
          )

        details.push(
          `${t('journals:cart_money_discount')}: ${formatCurrency(
            log.data.cart_moneydiscount,
          )}`,
        )
        details.push(
          `${t('journals:cart_money_total')}: ${formatCurrency(
            log.data.cart_moneytotal,
          )}`,
        )

        if (log.data.quantity)
          details.push(`${t('journals:quantity')}: ${log.data.quantity}`)

        if (log.data.decimalunitquantity)
          details.push(
            `${t('journals:decimal_unit_quantity')}: ${
              log.data.decimalunitquantity
            }`,
          )

        details.push(
          `${t('journals:cart_quantity_total')}: ${
            log.data.cart_quantitytotal
          }`,
        )

        if (log.data.note)
          details.push(`${t('journals:note')}: ${log.data.note}`)

        break
      case 'JournalCartPayment':
        details.push(`${t('journals:cart_id')}: ${log.data.cart_id}`)
        details.push(`${t('journals:payment_name')}: ${log.data.paymentname}`)
        details.push(
          `${t('journals:payment_method')}: ${t(
            `journals:payment_method_enum.${log.data.paymentmethod}`,
          )}`,
        )
        details.push(
          `${t('journals:money_total')}: ${formatCurrency(
            log.data.moneytotal,
          )}`,
        )
        details.push(
          `${t('journals:money_captured')}: ${formatCurrency(
            log.data.moneycaptured,
          )}`,
        )
        break
      case 'JournalCash':
        details.push(`${t('journals:currency_code')}: ${log.data.currencycode}`)
        details.push(
          `${t('journals:money_cash')}: ${formatCurrency(log.data.moneycash)}`,
        )

        if (log.data.comment)
          details.push(`${t('journals:comment')}: ${log.data.comment}`)
        break
      case 'JournalCtuError':
        details.push(`${t('journals:ctu_error')}: ${log.data.errortext}`)
        break
      case 'JournalPrintout':
        details.push(
          `${t('journals:type')}: ${t(
            `journals:printout_type_enum.${log.data.type}`,
          )}`,
        )
        if (log.data.receiptid)
          details.push(`${t('journals:receipt_id')}: ${log.data.receiptid}`)
        if (log.data.orderid)
          details.push(`${t('journals:order_id')}: ${log.data.orderid}`)
        if (log.data.receiptid)
          details.push(`${t('journals:zreport_id')}: ${log.data.receiptid}`)
        break

      case 'JournalReceipt':
        details.push(
          `${t('journals:receipt_number')}: ${log.data.receiptnumber}`,
        )
        break
      case 'JournalReport':
        if (log.data.zreportid)
          details.push(`${t('journals:zreport_id')}: ${log.data.zreportid}`)

        details.push(
          `${t('journals:manufacturer_id')}: ${log.data.manufacturerid}`,
        )
        details.push(`${t('journals:eshop_id')}: ${log.data.eshopid}`)
        if (log.data.store_id)
          details.push(`${t('journals:store_id')}: ${log.data.store_id}`)
        if (log.data.store_name)
          details.push(`${t('journals:store_name')}: ${log.data.store_name}`)
        details.push(`${t('journals:company_id')}: ${log.data.companyid}`)
        details.push(`${t('journals:company_name')}: ${log.data.company}`)
        break
    }

    return details.join('\n')
  }

  const getTitleContent = (log: ArrayElement<Order['purchaseJournal']>) => {
    const secondaryText = [
      log.datecreated
        ? formatRelativeDate(new Date(log.datecreated), {
            showSeconds: true,
            showTime: true,
          })
        : '',
    ]

    if ('staff' in log.data) {
      secondaryText.push(log.data.staff || log.data.staffuserid)
    }

    return (
      <ListItemText secondary={secondaryText.join(', ')}>
        {getLogTitle(log.type, log.data)}
      </ListItemText>
    )
  }

  return (
    <List>
      {logs.map((log, i) => {
        const titleContent = getTitleContent(log)
        const detailsContent = getDetailsContent(log)

        return (
          <Fragment key={i}>
            <ListItemTimeline
              key={i}
              divider={i !== logs.length - 1}
              data-testid={`history-item-${i}`}
            >
              <ListItemIconTimeline
                icon={getLogIcon(log.type)}
                first={i === 0}
                last={i === logs.length - 1}
              />
              {!detailsContent ? (
                titleContent
              ) : (
                <Accordion
                  sx={{
                    width: '100%',
                    my: 0,
                  }}
                >
                  <AccordionSummary
                    sx={{
                      px: 0,
                      width: '100%',
                      '.MuiAccordionSummary-content': {
                        my: 0,
                      },
                    }}
                    aria-controls={`history-${i}`}
                  >
                    {titleContent}
                  </AccordionSummary>
                  <AccordionDetails
                    sx={{
                      p: 0,
                    }}
                  >
                    <ListItemText
                      secondary={detailsContent}
                      slotProps={{
                        secondary: {
                          sx: { whiteSpace: 'pre-line' },
                        },
                      }}
                    />
                  </AccordionDetails>
                </Accordion>
              )}
            </ListItemTimeline>
          </Fragment>
        )
      })}
    </List>
  )
}
