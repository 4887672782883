import { IconButton, Alert } from '@mui/material'
import { GridColDef } from '@mui/x-data-grid-pro'
import { GridApiPro } from '@mui/x-data-grid-pro/models/gridApiPro'
import { DeleteIcon } from '@sitoo/mui-components'
import { MutableRefObject, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { CampaignProduct, MAX_CAMPAIGN_PRODUCTS } from '.'
import { DataGrid } from '../../../../components/data-grid'
import { DataGridImageRenderer } from '../../../../components/data-grid-image'
import {
  CaptionRendererValue,
  CaptionRenderer,
} from '../../../../components/data-grid/utils/caption-renderer'
import { ColumnProps } from '../../../../components/data-grid/utils/column-props'
import { useTracking } from '../../../../hooks/tracking'
import { getProductDetails } from '../../../../utils/format/product'

type Props = {
  items: CampaignProduct[]
  rowCount: number
  removeItem: (id: number) => void
  apiRef: MutableRefObject<GridApiPro>
  loading?: boolean
}

type Row = CampaignProduct

type ToolbarProps = {
  count: number
  limit: number
}

const LimitToolbar = (props: ToolbarProps) => {
  const { count, limit } = props
  const { t } = useTranslation(['campaigns'])

  if (count < limit) return null

  return (
    <Alert color="error" sx={{ mb: 1 }}>
      {t('campaigns:campaign_form.add_products_limit_label', {
        count: count,
        limit,
      })}
    </Alert>
  )
}

export const CampaignProducts = (props: Props) => {
  const { t } = useTranslation(['shared', 'campaigns'])
  const { items } = props
  const { trackButtonClick } = useTracking()

  const dataGridColumns = useMemo<GridColDef<Row>[]>(
    () => [
      {
        field: 'product_image',
        headerName: '',
        ...ColumnProps.image,
        valueGetter: (_value, row) => row.productImages?.at(0)?.fileUrl,
        renderCell: DataGridImageRenderer,
      },
      {
        field: 'product_name',
        ...ColumnProps.productTitle,
        headerName: t('campaigns:campaign_form.products_title'),
        flex: 3,
        valueGetter: (_value, row): CaptionRendererValue => ({
          title: row.title,
          caption: getProductDetails(row),
        }),
        renderCell: CaptionRenderer,
      },
      {
        field: 'remove',
        headerName: '',
        flex: 0.4,
        renderCell: (params) => (
          <IconButton
            onClick={() => {
              const item = items?.find(({ id }) => id === params.id)

              if (item?.sku) {
                props.removeItem(item.id)
              }
              trackButtonClick({ name: 'delete-campaign-product' })
            }}
          >
            <DeleteIcon />
          </IconButton>
        ),
      },
    ],
    [items, props, t, trackButtonClick],
  )

  return (
    <DataGrid
      apiRef={props.apiRef}
      name="set-campaign-products-list"
      columns={dataGridColumns}
      rows={items}
      rowCount={items.length}
      loading={props.loading}
      rowHeight={50}
      ignoreRowHover
      sx={{ height: '300px', mx: 3 }}
      noRowsOverlay={{
        title: t('campaigns:campaign_form.no_rows_title'),
        description: t('campaigns:campaign_form.no_rows_description'),
      }}
      disableColumnVisibilityControl
      disableColumnMenu={true}
      disableColumnResize={true}
      disableAllFilters
      slots={{
        footer: () => null,
        toolbar: () => (
          <LimitToolbar count={props.rowCount} limit={MAX_CAMPAIGN_PRODUCTS} />
        ),
      }}
    />
  )
}
