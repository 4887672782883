import { DateValidationError } from '@mui/x-date-pickers-pro'
import { useTranslation } from 'react-i18next'

export type Messages = { [v in NonNullable<DateValidationError>]: string }

export const useErrorMessages = (overrides?: Partial<Messages>) => {
  const { t } = useTranslation('shared')

  const defaultMessages = {
    maxDate: t('shared:validation.date_max_date'),
    minDate: t('shared:validation.date_min_date'),
    invalidDate: t('shared:validation.date_invalid_date'),
    disableFuture: t('shared:validation.date_disable_future'),
    disablePast: t('shared:validation.date_disable_past'),
    shouldDisableDate: t('shared:validation.date_should_disable_date'),
    shouldDisableMonth: t('shared:validation.date_should_disable_month'),
    shouldDisableYear: t('shared:validation.date_should_disable_year'),
  } satisfies Messages

  return {
    ...defaultMessages,
    ...overrides,
  }
}
