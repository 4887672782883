import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { Form } from '../../..'
import { DatePickerInput } from '../../../../../../inputs/date-picker-input'
import dayjs from 'dayjs'

export const StartDateField = () => {
  const { t } = useTranslation(['pos_profiles'])
  const { control, getValues } = useFormContext<Form>()

  const returnPolicy = getValues('return_policy')
  const { end_date: endDate, last_date: lastDate } = returnPolicy || {}
  const hasAnyOtherDateField = Boolean(endDate || lastDate)

  return (
    <DatePickerInput
      name="return_policy.start_date"
      control={control}
      label={t('pos_profiles:return_policy_section.start_date')}
      helperText={t(
        'pos_profiles:return_policy_section.start_date_description',
      )}
      maxDate={dayjs(endDate)}
      shouldValidate={false}
      rules={{
        required: hasAnyOtherDateField
          ? t('pos_profiles:return_policy_section.date_all_required')
          : false,
      }}
    />
  )
}
