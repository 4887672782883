import { FormFieldset } from '@sitoo/mui-components'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { CampaignFormContext } from '../..'
import { ListItem, ListItemText } from '@mui/material'
import { SwitchInput } from '../../../../inputs/switch-input'

export const ActiveField = () => {
  const { control } = useFormContext<CampaignFormContext>()
  const { t } = useTranslation(['campaigns', 'shared'])

  return (
    <FormFieldset
      sx={{
        '.MuiFormFieldset-Paper': {
          p: 0,
        },
      }}
    >
      <ListItem
        component="div"
        secondaryAction={
          <SwitchInput
            name="campaign.activepos"
            control={control}
            data-testid="campaign-activepos-field"
          />
        }
      >
        <ListItemText
          secondary={t('campaigns:campaign_form.activepos_description')}
        >
          {t('campaigns:campaign_form.activepos_label')}
        </ListItemText>
      </ListItem>
    </FormFieldset>
  )
}
