import { ListItem } from '@mui/material'
import { useFormContext, useWatch } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { Form } from '..'
import { ReportDefinitionFilter } from '../../../../generated/graphql'
import { useAuthorization } from '../../../../hooks/authorization'
import { WarehousesInput } from '../../../../inputs/warehouses-input'

type Props = {
  warehouseFilterKey: keyof Pick<
    ReportDefinitionFilter,
    'warehouseId' | 'orderWarehouseId'
  >
}
export const WarehouseInput = (props: Props) => {
  const { control } = useFormContext<Form>()
  const { t } = useTranslation('reports')

  const {
    modules: { reportsFullPermission },
  } = useAuthorization()

  const selectedWarehouses = useWatch({
    control,
    name: `reportdefinition.filters.${props.warehouseFilterKey}`,
  })

  const showPlaceholder = Array.isArray(selectedWarehouses)
    ? selectedWarehouses.length === 0
    : selectedWarehouses == null

  return (
    <ListItem>
      <WarehousesInput
        name={`reportdefinition.filters.${props.warehouseFilterKey}`}
        control={control}
        multiple={props.warehouseFilterKey === 'warehouseId'}
        label={t('create_dialog.warehouse')}
        required={!reportsFullPermission}
        textFieldProps={{
          placeholder: showPlaceholder ? t('create_dialog.include_all') : '',
        }}
      />
    </ListItem>
  )
}
