import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { Form } from '../../..'
import { SettingsListItem } from '../../../../../../components/settings-list-item'
import { PosProfileStockStatusDisplayModeEnum } from '../../../../../../generated/graphql'
import { SelectInput } from '../../../../../../inputs/select-input'

export const StockStatusDisplayModeField = () => {
  const { t } = useTranslation(['pos_profiles'])
  const { control } = useFormContext<Form>()

  const stockStatusDisplayOptions = [
    ...Object.values(PosProfileStockStatusDisplayModeEnum).map((mode) => ({
      value: mode,
      name: t(
        `pos_profiles:pos_options_section.stock_status_display_options.${mode}`,
      ),
    })),
  ]
  return (
    <SettingsListItem>
      <SelectInput
        label={t(
          'pos_profiles:pos_options_section.stock_status_display_mode_label',
        )}
        helperText={t(
          'pos_profiles:pos_options_section.stock_status_display_mode_description',
        )}
        name={'stockstatusdisplaymode'}
        control={control}
        options={stockStatusDisplayOptions}
      />
    </SettingsListItem>
  )
}
