import { useTranslation } from 'react-i18next'
import { useMutation } from '@apollo/client'
import { enqueueSnackbar } from 'notistack'
import { ExportDialog } from '../../../components/export-dialog'
import {
  ExportReportResultItemsDocument,
  FileType,
} from '../../../generated/graphql'
import { useTracking } from '../../../hooks/tracking'

interface Props {
  jobId: number
  open: boolean
  onClose: () => void
}

export const ExportResultsDialog = (props: Props) => {
  const { jobId, open, onClose } = props
  const { t } = useTranslation(['reports'])
  const [exportReportResultItems, { loading: isExportingReportResultItems }] =
    useMutation(ExportReportResultItemsDocument)
  const { trackFormSuccess, trackFormError } = useTracking()

  const handleExport = async (fileType: FileType) => {
    try {
      const exportResult = await exportReportResultItems({
        variables: {
          fileType,
          jobId,
        },
      })

      const fileLink = exportResult.data?.exportReportResultItems

      if (fileLink) {
        window.location.href = fileLink
      }

      enqueueSnackbar(t('export_dialog.export_success'), { variant: 'success' })
      trackFormSuccess({ name: 'report-export-dialog' })
    } catch (error) {
      enqueueSnackbar(t('export_dialog.export_error'), { variant: 'error' })
      trackFormError({ name: 'report-export-dialog' })
    } finally {
      onClose()
    }
  }

  return (
    <ExportDialog
      label={t('export_dialog.export_dialog_title')}
      open={open}
      onClose={onClose}
      onSuccess={handleExport}
      isLoading={isExportingReportResultItems}
    />
  )
}
