import { useQuery } from '@apollo/client'
import { Container } from '@mui/material'
import { ClockIcon, StarIcon } from '@sitoo/mui-components'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { Outlet } from 'react-router-dom'
import {
  LatestReportJobsDocument,
  ReportJobFavoritesDocument,
  ReportJobState,
} from '../../../generated/graphql'
import { useFavoriteReportJobs } from '../../report/favorites/use-favorite-report-jobs'
import { EmptySection } from './empty-section'
import { ReportJobCard } from './report-job-card'
import { ReportJobCardGroup } from './report-job-card-group'
import { useMe } from '../../../hooks/me'

export const ReportsOverviewPage = () => {
  const { t } = useTranslation(['reports'])
  const { favorites } = useFavoriteReportJobs()
  const { me } = useMe()

  const { data: favoritesData, loading: isLoadingFavorites } = useQuery(
    ReportJobFavoritesDocument,
    {
      variables: {
        jobIds: favorites,
      },
      skip: !favorites.length,
      fetchPolicy: 'cache-and-network',
    },
  )

  const {
    data: latestData,
    loading: isLoadingLatest,
    startPolling,
    stopPolling,
  } = useQuery(LatestReportJobsDocument, {
    fetchPolicy: 'cache-and-network',
    variables: {
      owner: [me!.user!.email],
    },
    skip: !me?.user?.email,
  })

  useEffect(() => {
    if (
      latestData?.reportJobs.items?.some((job) =>
        [ReportJobState.InProgress, ReportJobState.Pending].includes(
          job.jobstate,
        ),
      )
    ) {
      startPolling(3000)
    } else {
      stopPolling()
    }
  }, [latestData, startPolling, stopPolling])

  return (
    <Container maxWidth={false}>
      <Outlet />
      <ReportJobCardGroup title={t('reports:favorites.favorite_label')}>
        {!favoritesData?.favorites.items?.length && (
          <EmptySection
            icon={<StarIcon fontSize="large" />}
            description={t('reports:no_favorites')}
            isLoading={isLoadingFavorites}
          />
        )}

        {favoritesData?.favorites.items?.map((reportJob) => (
          <ReportJobCard key={reportJob.jobid} reportJob={reportJob} />
        ))}
      </ReportJobCardGroup>

      <ReportJobCardGroup title={t('reports:latest.latest_label')}>
        {!latestData?.reportJobs.items?.length && (
          <EmptySection
            icon={<ClockIcon fontSize="large" />}
            description={t('reports:latest.latest_empty')}
            isLoading={isLoadingLatest}
          />
        )}

        {latestData?.reportJobs.items?.map((reportJob) => (
          <ReportJobCard key={reportJob.jobid} reportJob={reportJob} />
        ))}
      </ReportJobCardGroup>
    </Container>
  )
}
