import { useQuery } from '@apollo/client'
import { Button, Dialog, DialogActions, DialogTitle, List } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { Form } from '.'
import {
  ReportGroupsAndMeasuresDocument,
  ReportType,
} from '../../../generated/graphql'
import { GroupsInput } from './report-inputs/groups-input'
import { JobNameInput } from './report-inputs/job-name-input'
import { MeasuresInput } from './report-inputs/measures-input'
import { CreditNoteReportFields } from './report-type-fields/credit-note-report-fields'
import { GiftCardReportFields } from './report-type-fields/gift-card-report-fields'
import { OmniFulfillmentReportFields } from './report-type-fields/omnifulfillment-report-fields'
import { OrderReportFields } from './report-type-fields/order-report-fields'
import { ProductReportFields } from './report-type-fields/product-report-fields'
import { SalesTaxReportFields } from './report-type-fields/sales-tax-report-fields'
import { StockReportFields } from './report-type-fields/stock-report-fields'
import { WarehouseReportFields } from './report-type-fields/warehouse-report-fields'
import { useFormContext } from 'react-hook-form'

type Props = {
  onClose: () => void
  onCreate: () => void
}

export const ExtendedDialog = ({ onCreate, onClose }: Props) => {
  const { t } = useTranslation('reports')
  const { getValues } = useFormContext<Form>()
  const formValues = getValues()

  const { data } = useQuery(ReportGroupsAndMeasuresDocument, {
    variables: {
      reportType: formValues?.reporttype,
    },
    skip: !formValues?.reporttype,
    fetchPolicy: 'cache-and-network',
  })
  const { measures = [], groups = [] } = data?.reportGroupsAndMeasures ?? {}
  const reportType = formValues.reporttype

  return (
    <Dialog open={true} fullWidth onClose={onClose}>
      <DialogTitle sx={{ pb: 2 }}>
        {t('create_dialog.create_report')}
      </DialogTitle>
      <List sx={{ mx: 1, mt: 1, mb: 2 }}>
        <JobNameInput />
        {groups.length > 0 && <GroupsInput groupKeys={groups} />}
        <MeasuresInput measureKeys={measures} />
        {reportType === ReportType.Order && <OrderReportFields />}
        {reportType === ReportType.Product && <ProductReportFields />}
        {reportType === ReportType.Creditnote && <CreditNoteReportFields />}
        {reportType === ReportType.GiftCard && <GiftCardReportFields />}
        {reportType === ReportType.SalesTax && <SalesTaxReportFields />}
        {reportType === ReportType.Stock && <StockReportFields />}
        {reportType === ReportType.Warehouse && <WarehouseReportFields />}
        {reportType === ReportType.OmniFulfillment && (
          <OmniFulfillmentReportFields />
        )}
      </List>
      <DialogActions sx={{ p: 3 }}>
        <Button size="medium" color="secondary" onClick={onClose}>
          {t('shared:action.cancel')}
        </Button>

        <Button
          size="medium"
          color="primary"
          onClick={onCreate}
          data-testid="create-report-button"
        >
          {t('create_dialog.create')}
        </Button>
      </DialogActions>
    </Dialog>
  )
}
