import { useQuery } from '@apollo/client'
import { ListItemText } from '@mui/material'
import { useMemo } from 'react'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { SettingsListItem } from '../../../../components/settings-list-item'
import { SettingsListSection } from '../../../../components/settings-list-section'
import {
  AllPaymentTypesDocument,
  GetConfigVarsDocument,
  PosPaymentMethod,
  PosUiColor,
  SettingsBankBagIdModeEnum,
  SettingsCartItemDeliveryinfoPopupEnum,
  SettingsCartItemPriceDisplayModeEnum,
  SettingsCartItemSalesMarginDisplayModeEnum,
  SettingsCartPrintPickListModeEnum,
  SettingsCashCountingModeEnum,
  SettingsCopyDiscountsToExchangeSaleEnum,
  SettingsPickupPrintoutBarcodeEnum,
  SettingsReceiptStaffDisplayEnum,
  SettingsTransferGiftReceiptCustomerEnum,
} from '../../../../generated/graphql'
import { SelectInput } from '../../../../inputs/select-input'
import { SwitchInput } from '../../../../inputs/switch-input'
import { TextFieldInput } from '../../../../inputs/text-field-input'
import { GeneralSettingsFormContext } from '../general-settings-form'

export const PosSettings = () => {
  const { control } = useFormContext<GeneralSettingsFormContext>()
  const { t } = useTranslation(['shared', 'settings'])
  const { data } = useQuery(AllPaymentTypesDocument)
  const { data: configVarsData } = useQuery(GetConfigVarsDocument)

  const shouldShowPickUpPrintoutBarcode =
    configVarsData?.configVars.pickupdeliveryv2

  const paymentTypeOptions = useMemo(() => {
    if (!data?.allPaymentTypes) return []
    const options = data.allPaymentTypes
      ?.filter((paymentType) => {
        return [
          PosPaymentMethod.CASH,
          PosPaymentMethod.CUSTOM_METHOD_1,
          PosPaymentMethod.CUSTOM_METHOD_2,
          PosPaymentMethod.CUSTOM_METHOD_3,
          PosPaymentMethod.CUSTOM_METHOD_4,
          PosPaymentMethod.CUSTOM_METHOD_5,
        ].includes(paymentType.paymentmethodid)
      })
      .map((paymentType) => ({
        name: paymentType.name,
        value: paymentType.paymenttypeid ?? '',
      }))

    return [
      {
        name: t('settings:general.pos_settings.choose_payment_type'),
        value: null,
      },
      ...options,
    ]
  }, [data?.allPaymentTypes, t])

  const cashCountingOptions = useMemo(
    () =>
      Object.values(SettingsCashCountingModeEnum).map((value) => {
        return {
          name: t(`settings:general.pos_settings.cash_counting_mode_${value}`),
          value: value,
        }
      }),
    [t],
  )

  const bankBagIdentifiersOptions = useMemo(
    () =>
      Object.values(SettingsBankBagIdModeEnum).map((value) => {
        return {
          name: t(`settings:general.pos_settings.bank_bag_id_mode_${value}`),
          value: value,
        }
      }),
    [t],
  )

  const receiptStaffDisplayOptions = useMemo(
    () => [
      {
        name: t(`settings:general.pos_settings.receipt_staff_display_Default`),
        value: null,
      },
      ...Object.values(SettingsReceiptStaffDisplayEnum).map((value) => {
        return {
          name: t(
            `settings:general.pos_settings.receipt_staff_display_${value}`,
          ),
          value: value,
        }
      }),
    ],
    [t],
  )

  const pickupPrintoutBarcodeOptions = useMemo(
    () =>
      Object.values(SettingsPickupPrintoutBarcodeEnum).map((value) => {
        return {
          name: t(
            `settings:general.pos_settings.pickup_printout_barcode_${value}`,
          ),
          value: value,
        }
      }),
    [t],
  )

  const productCampaignTagColors = useMemo(
    () =>
      Object.values(PosUiColor).map((value) => {
        return {
          name: t(
            `settings:general.pos_settings.product_campaign_tag_color_${value.toLowerCase()}`,
          ),
          value: value,
        }
      }),
    [t],
  )

  return (
    <SettingsListSection title={t('settings:general.pos_settings.title')}>
      <SettingsListItem>
        <SelectInput
          label={t(
            'settings:general.pos_settings.payment_type_sale_refund_label',
          )}
          helperText={t(
            'settings:general.pos_settings.payment_type_sale_refund_helper',
          )}
          name="paymenttypeid_split"
          control={control}
          options={paymentTypeOptions}
          slotProps={{ select: { displayEmpty: true } }}
        />
      </SettingsListItem>

      <SettingsListItem>
        <SelectInput
          name="cash_counting_mode"
          control={control}
          options={cashCountingOptions}
          label={t('settings:general.pos_settings.cash_counting_mode_label')}
          helperText={t(
            'settings:general.pos_settings.cash_counting_mode_helper',
          )}
        />
      </SettingsListItem>

      <SettingsListItem divider>
        <SelectInput
          name="bank_bag_id_mode"
          control={control}
          options={bankBagIdentifiersOptions}
          label={t('settings:general.pos_settings.bank_bag_identifiers_label')}
          helperText={t(
            'settings:general.pos_settings.bank_bag_identifiers_helper',
          )}
        />
      </SettingsListItem>

      <SettingsListItem
        divider
        dense
        secondaryAction={
          <SwitchInput
            aria-label={t(
              'settings:general.pos_settings.copy_discounts_to_exchange_sale',
            )}
            name="copy_discounts_to_exchange_sale"
            control={control}
            transform={{
              output: (event: React.ChangeEvent<HTMLInputElement>) => {
                if (event.target.checked) {
                  return SettingsCopyDiscountsToExchangeSaleEnum.Enabled
                }
                return SettingsCopyDiscountsToExchangeSaleEnum.Disabled
              },
              input: (value) => {
                if (value === SettingsCopyDiscountsToExchangeSaleEnum.Enabled) {
                  return true
                }
                return false
              },
            }}
          />
        }
      >
        <ListItemText
          primary={t(
            'settings:general.pos_settings.copy_discounts_to_exchange_sale',
          )}
          secondary={t(
            'settings:general.pos_settings.copy_discounts_to_exchange_sale_helper',
          )}
        />
      </SettingsListItem>

      <SettingsListItem
        divider
        dense
        secondaryAction={
          <SwitchInput
            aria-label={t(
              'settings:general.pos_settings.transfer_gift_receipt_customer',
            )}
            name="transfer_gift_receipt_customer"
            control={control}
            transform={{
              output: (event: React.ChangeEvent<HTMLInputElement>) => {
                if (event.target.checked) {
                  return SettingsTransferGiftReceiptCustomerEnum.Enabled
                }
                return SettingsTransferGiftReceiptCustomerEnum.Disabled
              },
              input: (value) => {
                if (value === SettingsTransferGiftReceiptCustomerEnum.Enabled) {
                  return true
                }
                return false
              },
            }}
          />
        }
      >
        <ListItemText
          primary={t(
            'settings:general.pos_settings.transfer_gift_receipt_customer',
          )}
          secondary={t(
            'settings:general.pos_settings.transfer_gift_receipt_customer_helper',
          )}
        />
      </SettingsListItem>

      <SettingsListItem sx={{ mt: 2 }}>
        <SelectInput
          name="receipt_staff_display"
          control={control}
          options={receiptStaffDisplayOptions}
          label={t('settings:general.pos_settings.receipt_staff_display_label')}
          helperText={t(
            'settings:general.pos_settings.receipt_staff_display_helper',
          )}
          slotProps={{ select: { displayEmpty: true } }}
        />
      </SettingsListItem>

      {shouldShowPickUpPrintoutBarcode && (
        <SettingsListItem>
          <SelectInput
            name="pickup_printout_barcode"
            control={control}
            options={pickupPrintoutBarcodeOptions}
            label={t(
              'settings:general.pos_settings.pickup_printout_barcode_label',
            )}
            helperText={t(
              'settings:general.pos_settings.pickup_printout_barcode_helper',
            )}
          />
        </SettingsListItem>
      )}

      <SettingsListItem>
        <TextFieldInput
          name="num_days_until_pickup_expires"
          control={control}
          type="number"
          label={t('settings:general.pos_settings.pickup_overdue_days_label')}
          helperText={t(
            'settings:general.pos_settings.pickup_overdue_days_helper',
          )}
          required
          rules={{ min: 1, max: 9999 }}
          transform={{
            output: (event) =>
              event.target.value ? Number(event.target.value) : null,
          }}
        />
      </SettingsListItem>

      <SettingsListItem divider>
        <SelectInput
          name="product_campaign_tag_color"
          control={control}
          options={productCampaignTagColors}
          label={t('settings:general.pos_settings.product_campaign_tag_color')}
          helperText={t(
            'settings:general.pos_settings.product_campaign_tag_color_helper',
          )}
        />
      </SettingsListItem>

      <SettingsListItem
        divider
        dense
        secondaryAction={
          <SwitchInput
            name="cart_print_pick_list_mode"
            aria-label={t('settings:general.pos_settings.cart_pick_list_label')}
            control={control}
            transform={{
              output: (event: React.ChangeEvent<HTMLInputElement>) => {
                if (event.target.checked) {
                  return SettingsCartPrintPickListModeEnum.Active
                }
                return SettingsCartPrintPickListModeEnum.Disabled
              },
              input: (value) => {
                if (value === SettingsCartPrintPickListModeEnum.Active) {
                  return true
                }
                return false
              },
            }}
          />
        }
      >
        <ListItemText
          primary={t('settings:general.pos_settings.cart_pick_list_label')}
          secondary={t(
            'settings:general.pos_settings.cart_pick_list_description',
          )}
        />
      </SettingsListItem>

      <SettingsListItem
        divider
        dense
        secondaryAction={
          <SwitchInput
            aria-label={t(
              'settings:general.pos_settings.cart_item_price_label',
            )}
            name="cart_item_price_display_mode"
            control={control}
            transform={{
              output: (event: React.ChangeEvent<HTMLInputElement>) => {
                if (event.target.checked) {
                  return SettingsCartItemPriceDisplayModeEnum.PriceToPay
                }
                return SettingsCartItemPriceDisplayModeEnum.Default
              },
              input: (value) => {
                return value === SettingsCartItemPriceDisplayModeEnum.PriceToPay
              },
            }}
          />
        }
      >
        <ListItemText
          primary={t('settings:general.pos_settings.cart_item_price_label')}
          secondary={t('settings:general.pos_settings.cart_item_price_helper')}
        />
      </SettingsListItem>

      <SettingsListItem
        divider
        dense
        secondaryAction={
          <SwitchInput
            aria-label={t(
              'settings:general.pos_settings.cart_item_sales_margin_display_mode',
            )}
            name="cart_item_sales_margin_display_mode"
            control={control}
            transform={{
              output: (event: React.ChangeEvent<HTMLInputElement>) => {
                if (event.target.checked) {
                  return SettingsCartItemSalesMarginDisplayModeEnum.SalesMargin
                }
                return SettingsCartItemSalesMarginDisplayModeEnum.Disabled
              },
              input: (value) => {
                if (
                  value ===
                  SettingsCartItemSalesMarginDisplayModeEnum.SalesMargin
                ) {
                  return true
                }
                return false
              },
            }}
          />
        }
      >
        <ListItemText
          primary={t(
            'settings:general.pos_settings.cart_item_sales_margin_display_mode',
          )}
          secondary={t(
            'settings:general.pos_settings.cart_item_sales_margin_display_mode_helper',
          )}
        />
      </SettingsListItem>

      <SettingsListItem
        divider
        dense
        secondaryAction={
          <SwitchInput
            aria-label={t(
              'settings:general.pos_settings.cart_item_deliveryinfo_popup',
            )}
            name="cart_item_deliveryinfo_popup"
            control={control}
            transform={{
              output: (event: React.ChangeEvent<HTMLInputElement>) => {
                if (event.target.checked) {
                  return SettingsCartItemDeliveryinfoPopupEnum.Active
                }
                return SettingsCartItemDeliveryinfoPopupEnum.Disabled
              },
              input: (value) => {
                if (value === SettingsCartItemDeliveryinfoPopupEnum.Active) {
                  return true
                }
                return false
              },
            }}
          />
        }
      >
        <ListItemText
          primary={t(
            'settings:general.pos_settings.cart_item_deliveryinfo_popup',
          )}
          secondary={t(
            'settings:general.pos_settings.cart_item_deliveryinfo_popup_helper',
          )}
        />
      </SettingsListItem>

      <SettingsListItem
        divider
        dense
        secondaryAction={
          <SwitchInput
            name="compress_variant_info"
            control={control}
            aria-label={t(
              'settings:general.pos_settings.compress_variant_info_label',
            )}
          />
        }
      >
        <ListItemText
          primary={t(
            'settings:general.pos_settings.compress_variant_info_label',
          )}
          secondary={t(
            'settings:general.pos_settings.compress_variant_info_description',
          )}
        />
      </SettingsListItem>

      <SettingsListItem
        divider
        dense
        secondaryAction={
          <SwitchInput
            name="limit_open_cash_drawer"
            control={control}
            aria-label={t(
              'settings:general.pos_settings.limit_opening_cash_drawer_label',
            )}
          />
        }
      >
        <ListItemText
          primary={t(
            'settings:general.pos_settings.limit_opening_cash_drawer_label',
          )}
          secondary={t(
            'settings:general.pos_settings.limit_opening_cash_drawer_description',
          )}
        />
      </SettingsListItem>

      <SettingsListItem
        divider
        dense
        secondaryAction={
          <SwitchInput
            name="mixed_delivery_disabled"
            control={control}
            aria-label={t(
              'settings:general.pos_settings.disable_mixed_delivery_label',
            )}
          />
        }
      >
        <ListItemText
          primary={t(
            'settings:general.pos_settings.disable_mixed_delivery_label',
          )}
          secondary={t(
            'settings:general.pos_settings.disable_mixed_delivery_description',
          )}
        />
      </SettingsListItem>
      <SettingsListItem
        divider
        dense
        secondaryAction={
          <SwitchInput
            name="warn_close_with_open_orders"
            control={control}
            aria-label={t(
              'settings:general.pos_settings.warn_close_with_open_orders_label',
            )}
          />
        }
      >
        <ListItemText
          primary={t(
            'settings:general.pos_settings.warn_close_with_open_orders_label',
          )}
          secondary={t(
            'settings:general.pos_settings.warn_close_with_open_orders_description',
          )}
        />
      </SettingsListItem>

      <SettingsListItem
        divider
        dense
        secondaryAction={
          <SwitchInput
            name="scanner_ean13_remove_leading_zero"
            control={control}
            aria-label={t(
              'settings:general.pos_settings.convert_ean13_leading_zero_label',
            )}
          />
        }
      >
        <ListItemText
          primary={t(
            'settings:general.pos_settings.convert_ean13_leading_zero_label',
          )}
          secondary={t(
            'settings:general.pos_settings.convert_ean13_leading_zero_description',
          )}
        />
      </SettingsListItem>

      {/*  
     // TODO: Disabled until we have a generic component in place for this. 
     <DialogInput
        name="pos_inventory_features"
        control={control}
        options={Object.values(SettingsPosInventoryFeaturesEnum)}
        formatValue={(value) => ({
          label: t(
            `settings:general.pos_settings.pos_inventory_features_${value}`,
          ),
          value,
        })}
        itemLabel={t(
          'settings:general.pos_settings.limit_pos_inventory_features_label',
        )}
        addLabel={t(
          'settings:general.pos_settings.limit_pos_inventory_features_add_label',
        )}
        helperText={t(
          'settings:general.pos_settings.limit_pos_inventory_features_helper',
        )}
        emptyMessage={t(
          'settings:general.pos_settings.limit_pos_inventory_features_not_limited',
        )}
      /> */}
    </SettingsListSection>
  )
}
