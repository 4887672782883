import {
  Button,
  Divider,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
} from '@mui/material'
import { FormFieldset } from '@sitoo/mui-components'
import { useCallback, useState, useRef } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import {
  ActionType,
  AllShipmentActionsDocument,
  GetShipmentDocument,
  ShipmentAddress,
  ShipmentContact,
  ShipmentState,
  AddShipmentActionDocument,
  GetWarehousesDocument,
} from '../../../../generated/graphql'
import { SetAddressDialog } from '../../set-address-dialog'
import { SetContactDialog } from '../../set-contact-dialog'
import { AddressListItem } from '../origin/address-list-item'
import { ContactListItem } from '../origin/contact-list-item'
import { useParams } from 'react-router-dom'
import { BaseShipmentFormContext, getShipmentsErrorMessage } from '../../shared'
import { useApolloClient, useMutation, useQuery } from '@apollo/client'
import { stripNullValues } from '../../../../utils/strip-null-values'
import { stripEmptyProperties } from '../../../../utils/strip-empty-properties'
import { useSnackbar } from 'notistack'
import { useScrollToError } from '../../../../hooks/scroll-to-error'

export const DestinationField = () => {
  const { id: shipmentId } = useParams()
  const isNewShipment = shipmentId === undefined
  const { formState, control, setValue, watch, clearErrors } =
    useFormContext<BaseShipmentFormContext>()
  const { t } = useTranslation(['shipments', 'shared', 'countries'])

  const { enqueueSnackbar } = useSnackbar()
  const client = useApolloClient()
  const [addShipmentAction] = useMutation(AddShipmentActionDocument)

  const { data } = useQuery(GetWarehousesDocument)
  const warehouses = data?.allWarehouses

  const [showSetAddressDialog, setShowSetAddressDialog] = useState(false)
  const [showSetContactDialog, setShowSetContactDialog] = useState(false)

  const shipment = watch()
  const address = shipment.info?.destination?.address
  const contact = shipment.info?.destination?.contact
  const state = formState.defaultValues?.state || ShipmentState.New

  const canEdit = [ShipmentState.New, ShipmentState.Packed].includes(state)

  const fillWarehouseInfo = useCallback(
    (warehouseId: number | string) => {
      const warehouse = warehouses?.find(({ id }) => id === warehouseId)

      setValue('info.destination_warehouse_id', warehouse?.id, {
        shouldDirty: true,
      })
      setValue('info.destination.address.name', warehouse?.name, {
        shouldDirty: true,
      })
      setValue('info.destination.address.address', warehouse?.address || '', {
        shouldDirty: true,
      })
      setValue('info.destination.address.address2', warehouse?.address2 || '', {
        shouldDirty: true,
      })
      setValue('info.destination.address.city', warehouse?.city || '', {
        shouldDirty: true,
      })
      setValue('info.destination.address.zip', warehouse?.zip || '', {
        shouldDirty: true,
      })
      setValue('info.destination.address.state', warehouse?.state || '', {
        shouldDirty: true,
      })
      setValue(
        'info.destination.address.country_id',
        (warehouse?.countryid || '').toUpperCase(),
        { shouldDirty: true },
      )
    },
    [setValue, warehouses],
  )

  const hasAddress = (address?: ShipmentAddress | null) => {
    return (
      [
        address?.name,
        address?.address,
        address?.address2,
        address?.city,
        address?.zip,
        address?.state,
        address?.country_id,
      ].filter(Boolean).length > 0
    )
  }

  const hasContact = (contact?: ShipmentContact | null) => {
    return (
      [
        contact?.title,
        contact?.first_name,
        contact?.last_name,
        contact?.email,
        contact?.mobile,
        contact?.note,
      ].filter(Boolean).length > 0
    )
  }

  const contactRef = useRef<HTMLLIElement>(null)
  const addressRef = useRef<HTMLLIElement>(null)

  useScrollToError({
    ref: contactRef,
    name: 'info.destination.address',
    control,
  })
  useScrollToError({
    ref: addressRef,
    name: 'info.destination.contact',
    control,
  })

  const submitAddressNewShipment = (newAddress: ShipmentAddress) => {
    setValue('info.destination.address', newAddress, {
      shouldDirty: true,
    })
    clearErrors('info.destination.address')
    setShowSetAddressDialog(false)
  }

  const submitAddressExistingShipment = async (newAddress: ShipmentAddress) => {
    await addShipmentAction({
      variables: {
        shipmentId: shipment.id || '',
        data: {
          shipment_version: shipment.version || 0,
          action: ActionType.SetInfo,
          info: stripNullValues(
            stripEmptyProperties({
              ...shipment.info,
              destination: {
                ...shipment.info?.destination,
                address: newAddress,
              },
            }),
          ),
        },
      },
    })

    await client.refetchQueries({
      include: [GetShipmentDocument, AllShipmentActionsDocument],
    })
    enqueueSnackbar(t('shipments:shipment_message.success_update'))
    setShowSetAddressDialog(false)
  }

  const submitContactNewShipment = (newContact: ShipmentContact) => {
    setValue('info.destination.contact', newContact, {
      shouldDirty: true,
    })
    clearErrors('info.destination.contact')
    setShowSetContactDialog(false)
  }

  const submitContactExistingShipment = async (newContact: ShipmentContact) => {
    await addShipmentAction({
      variables: {
        shipmentId: shipment.id || '',
        data: {
          shipment_version: shipment.version || 0,
          action: ActionType.SetInfo,
          info: stripNullValues(
            stripEmptyProperties({
              ...shipment.info,
              destination: {
                ...shipment.info?.origin,
                contact: newContact,
              },
            }),
          ),
        },
      },
    })

    await client.refetchQueries({
      include: [GetShipmentDocument, AllShipmentActionsDocument],
    })

    enqueueSnackbar(t('shipments:shipment_message.success_update'))
    setShowSetContactDialog(false)
  }

  return (
    <FormFieldset label={t('shipments:shipment_form.destination_fieldset')}>
      {isNewShipment && (
        <Controller
          control={control}
          name="info.destination_warehouse_id"
          render={({ field }) => (
            <>
              <InputLabel>{t('shipments:shipment_form.warehouse')}</InputLabel>
              <Select<number | string>
                value={field.value || ''}
                onChange={(event) => {
                  fillWarehouseInfo(event.target.value)
                }}
                displayEmpty
                data-testid="destination-warehouse"
                inputProps={{
                  ['data-testid']: 'destination-warehouse-input',
                }}
              >
                <MenuItem value={''}>{t('shared:label.not_selected')}</MenuItem>
                {warehouses?.map(({ id, name }) => (
                  <MenuItem
                    value={id}
                    key={id}
                    data-testid={`destination-warehouse-button-${id}`}
                  >
                    {name}
                  </MenuItem>
                ))}
              </Select>
              <FormHelperText sx={{ mb: 2 }}>
                {formState?.errors.info?.destination_warehouse_id?.message}
              </FormHelperText>
            </>
          )}
        />
      )}

      <Grid container sx={{ mb: -2, mt: isNewShipment ? 0 : -2 }}>
        <Grid size={{ xs: 12 }}>
          <SetAddressDialog
            action={hasAddress(address) ? 'edit' : 'add'}
            address={address}
            open={showSetAddressDialog}
            onClose={() => setShowSetAddressDialog(false)}
            onSubmit={
              isNewShipment
                ? submitAddressNewShipment
                : submitAddressExistingShipment
            }
            onError={
              isNewShipment
                ? undefined
                : (error) => {
                    const errorMessage = getShipmentsErrorMessage(error)

                    enqueueSnackbar(errorMessage, { variant: 'error' })
                  }
            }
            isNewShipment={isNewShipment}
          />
          <AddressListItem
            data-testid="address-box-destination"
            ref={addressRef}
            address={address}
            sx={{
              px: 0,
            }}
            disabled={!hasAddress(address)}
            error={!!formState.errors.info?.destination?.address}
            fallbackTitle={
              hasAddress(address)
                ? t('shipments:shipment_form.address')
                : t('shipments:shipment_form.no_address_added')
            }
            secondaryAction={
              <>
                {canEdit && (
                  <Button
                    data-testid="add-address-destination"
                    size="small"
                    color="secondary"
                    onClick={() => {
                      setShowSetAddressDialog(true)
                    }}
                  >
                    {hasAddress(address)
                      ? t('shared:action.edit')
                      : t('shipments:shipment_form.add_address')}
                  </Button>
                )}
              </>
            }
          />
        </Grid>
        <Grid size={{ xs: 12 }}>
          <Divider sx={{ ml: -2, mr: -2 }} />
        </Grid>
        <Grid container spacing={2}>
          <Grid size={{ xs: 12 }}>
            <SetContactDialog
              action={hasContact(contact) ? 'edit' : 'add'}
              contact={contact}
              open={showSetContactDialog}
              onClose={() => setShowSetContactDialog(false)}
              onSubmit={
                isNewShipment
                  ? submitContactNewShipment
                  : submitContactExistingShipment
              }
              onError={
                isNewShipment
                  ? undefined
                  : (error) => {
                      const errorMessage = getShipmentsErrorMessage(error)

                      enqueueSnackbar(errorMessage, { variant: 'error' })
                    }
              }
              isNewShipment={isNewShipment}
            />
            <ContactListItem
              data-testid="contact-box-destination"
              ref={contactRef}
              sx={{
                px: 0,
              }}
              contact={contact}
              disabled={!hasContact(contact)}
              error={!!formState.errors.info?.destination?.contact}
              fallbackTitle={
                hasContact(contact)
                  ? t('shipments:shipment_form.contact')
                  : t('shipments:shipment_form.no_contact_added')
              }
              secondaryAction={
                <>
                  {canEdit && (
                    <Button
                      data-testid="add-contact-destination"
                      size="small"
                      color="secondary"
                      onClick={() => {
                        setShowSetContactDialog(true)
                      }}
                    >
                      {hasContact(contact)
                        ? t('shared:action.edit')
                        : t('shipments:shipment_form.add_contact')}
                    </Button>
                  )}
                </>
              }
            />
          </Grid>
        </Grid>
      </Grid>
    </FormFieldset>
  )
}
