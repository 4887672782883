import { useMutation, useQuery } from '@apollo/client'
import { useSnackbar } from 'notistack'
import { useTranslation } from 'react-i18next'
import {
  SettingsDocument,
  SettingsQuery,
  UpdateSettingsDocument,
} from '../../../generated/graphql'
import { usePageTitle } from '../../../hooks/title'
import { GeneralSettingsForm } from './general-settings-form'
import { getErrorMessages } from '../../../utils/error-mapping'
import { PageLoader } from '../../../components/page-loader'

export type Settings = NonNullable<SettingsQuery['settings']>

export const SettingsGeneralPage = () => {
  const { t } = useTranslation(['shared', 'settings'])
  usePageTitle(t('shared:menu.general'))
  const { data, loading } = useQuery(SettingsDocument, {
    fetchPolicy: 'cache-and-network',
  })
  const [updateSettings] = useMutation(UpdateSettingsDocument)

  const settings = data?.settings

  const { enqueueSnackbar } = useSnackbar()
  const formId = 'generalForm'

  const onSubmit = async (fieldsToUpdate: Partial<Settings>) => {
    try {
      if (Object.keys(fieldsToUpdate).length > 0) {
        const { data } = await updateSettings({
          variables: {
            settings: fieldsToUpdate,
          },
        })

        enqueueSnackbar(t('settings:general.success_general_saved'), {
          variant: 'success',
        })

        return data?.updateSettings
      }
    } catch (error) {
      const errorMessages = getErrorMessages(error)
      enqueueSnackbar(
        errorMessages[0] || t('settings:general.error_general_saved'),
        {
          variant: 'error',
        },
      )
      throw error
    }
  }

  if (loading) {
    return <PageLoader />
  }

  return (
    <GeneralSettingsForm
      onSubmit={onSubmit}
      settings={settings}
      formId={formId}
    />
  )
}
