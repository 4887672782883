import { useQuery } from '@apollo/client'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Chip,
  List,
  ListItemText,
  Stack,
} from '@mui/material'
import { SectionHeader } from '@sitoo/mui-components'
import { useTranslation } from 'react-i18next'
import { ListItemSkeleton } from '../../../components/list-item-skeleton'
import {
  GetCampaignQuery,
  AllCustomAttributesDocument,
} from '../../../generated/graphql'
import { ALL_PRODUCTS_ATTR } from '../../../utils/constants'
import { CAMPAIGN_TYPE_PACKAGE } from '../../../utils/campaigns'

type CampaignAppliesToProps = {
  campaign?: GetCampaignQuery['campaign']
  isLoading: boolean
}

type TagListProps = {
  items: string[]
  maxItems?: number
}

const TagList = (props: TagListProps) => {
  const { t } = useTranslation(['campaigns'])
  const { items, maxItems = 10 } = props
  const numHiddenItems = items.length - maxItems

  return (
    <Stack direction="row" gap={0.5} marginTop={0.5} flexWrap="wrap">
      {items.slice(0, maxItems).map((sku) => (
        <Chip key={sku} size="small" label={sku} data-testid="tag" />
      ))}

      {numHiddenItems > 0 && (
        <Chip
          size="small"
          label={t('campaigns:campaign_form.more_text', {
            count: numHiddenItems,
          })}
        />
      )}
    </Stack>
  )
}

export const CampaignAppliesTo = ({
  campaign,
  isLoading: loading,
}: CampaignAppliesToProps) => {
  const { t } = useTranslation(['shared', 'campaigns'])
  const { data, loading: loadingCustomAttributes } = useQuery(
    AllCustomAttributesDocument,
  )
  const allCustomAttributes = [
    ...(data?.allCustomAttributes || []),
    {
      id: ALL_PRODUCTS_ATTR,
      title: t('shared:label.all_products_attribute').toUpperCase(),
    },
  ]
  const isLoading = loading || loadingCustomAttributes

  const include = Object.entries(
    (campaign?.productattributes?.include as {
      [key: string]: string[]
    } | null) || {},
  )

  const exclude = Object.entries(
    (campaign?.productattributes?.exclude as {
      [key: string]: string[]
    } | null) || {},
  )

  const products = campaign?.products || []
  const tags = campaign?.tags || []

  return (
    <Accordion defaultExpanded className="MuiAccordionRoot">
      <AccordionSummary
        aria-controls="details"
        data-testid="applies-to-accordion"
      >
        <SectionHeader sx={{ p: 0 }}>
          {t('campaigns:campaign_form.applies_to')}
        </SectionHeader>
      </AccordionSummary>
      <AccordionDetails sx={{ p: 0 }}>
        <List>
          <ListItemSkeleton
            hide={
              products.length === 0 ||
              (campaign?.vouchertype &&
                CAMPAIGN_TYPE_PACKAGE.types.includes(campaign?.vouchertype))
            }
            isLoading={isLoading}
            divider
            childrenSkeleton
          >
            <ListItemText
              data-testid="include-products-section"
              primary={t('campaigns:campaign_form.include_products')}
              slotProps={{ secondary: { component: 'div' } }}
              secondary={
                <Chip
                  label={t('campaigns:campaign_form.include_proucts_num', {
                    count: products.length,
                  })}
                />
              }
            />
          </ListItemSkeleton>

          <ListItemSkeleton
            hide={include.length === 0}
            isLoading={isLoading}
            divider
            childrenSkeleton
          >
            <ListItemText
              data-testid="include-attributes-section"
              primary={t('campaigns:campaign_form.include_attributes')}
              slotProps={{ secondary: { component: 'div' } }}
              secondary={include.map(([customAttribute, values]) => (
                <Box sx={{ mb: 0.5 }} key={customAttribute}>
                  <span>
                    {allCustomAttributes.find((x) => x.id === customAttribute)
                      ?.title || customAttribute}
                  </span>

                  {values.length ? (
                    <TagList items={values} />
                  ) : (
                    <Stack direction="row">
                      <Chip label={t('shared:label.all')} size="small" />
                    </Stack>
                  )}
                </Box>
              ))}
            />
          </ListItemSkeleton>

          <ListItemSkeleton
            hide={exclude.length === 0}
            isLoading={isLoading}
            divider
            childrenSkeleton
          >
            <ListItemText
              data-testid="exclude-attributes-section"
              primary={t('campaigns:campaign_form.exclude_attributes')}
              slotProps={{ secondary: { component: 'div' } }}
              secondary={exclude.map(([customAttribute, values]) => (
                <Box sx={{ mb: 0.5 }} key={customAttribute}>
                  <span>
                    {allCustomAttributes.find((x) => x.id === customAttribute)
                      ?.title || customAttribute}
                  </span>

                  {values.length ? (
                    <TagList items={values} />
                  ) : (
                    <Stack direction="row">
                      <Chip label={t('shared:label.all')} size="small" />
                    </Stack>
                  )}
                </Box>
              ))}
            />
          </ListItemSkeleton>

          <ListItemSkeleton isLoading={isLoading} divider childrenSkeleton>
            <ListItemText
              data-testid="store-tags-section"
              primary={t('campaigns:campaign_form.store_tags')}
              slotProps={{ secondary: { component: 'div' } }}
              secondary={
                <Box>
                  {tags.length ? (
                    <TagList items={tags} />
                  ) : (
                    <Stack direction="row">
                      <Chip
                        label={t(
                          'campaigns:campaign_form.tags_selection_all_label',
                        )}
                        size="small"
                      />
                    </Stack>
                  )}
                </Box>
              }
            />
          </ListItemSkeleton>
        </List>
      </AccordionDetails>
    </Accordion>
  )
}
