import { SxProps, Theme, TextFieldProps } from '@mui/material'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import {
  FieldPath,
  FieldValues,
  PathValue,
  UseControllerProps,
} from 'react-hook-form'
import { FALLBACK_LANGUAGE } from '../../i18n'
import { AutocompleteInput } from '../autocomplete-input'

type Props<
  TFieldValues extends FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
> = UseControllerProps<TFieldValues, TName> & {
  label?: string
  helperText?: string
  sx?: SxProps<Theme>
  textFieldProps?: Omit<TextFieldProps, 'name' | 'required' | 'label'>
  rules?: UseControllerProps<TFieldValues, TName>['rules']
  required?: boolean
  ref?: React.Ref<HTMLElement>

  // The API is not consistent when it comes to null value for country_id
  // we need to pass string or null depending on the API
  emptyValue?: string | null
}

type Resources = Record<string, string>

export const CountriesInput = <TFieldValues extends FieldValues>(
  props: Props<TFieldValues>,
) => {
  const {
    name,
    control,
    textFieldProps,
    emptyValue = '',
    label,
    helperText,
    ...autocompleteProps
  } = props
  const { i18n } = useTranslation(['shared', 'countries'])

  const options = useMemo(() => {
    const resources =
      (i18n.getResourceBundle(i18n.language, 'countries') as Resources) ||
      (i18n.getResourceBundle(FALLBACK_LANGUAGE, 'countries') as Resources)

    return Object.entries(resources).map(([id, label]) => ({ id, label }))
  }, [i18n])

  return (
    <AutocompleteInput
      name={name}
      control={control}
      {...autocompleteProps}
      options={options}
      label={label}
      textFieldProps={{
        helperText,
        ...textFieldProps,
      }}
      transform={{
        input: (value) => {
          return options.find(({ id }) => id === value?.toUpperCase())
        },
        output: (option) => {
          return (option?.id ?? emptyValue) as PathValue<
            TFieldValues,
            typeof name
          >
        },
      }}
    />
  )
}
